import {
  CONCUBIN,
  MARIED,
  PACTE,
  familySituations,
  genders,
} from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import Adresse from '../commons/adress';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';
import InfoWeddingDG from './InfoWeddingDG';
import InfoPacteDG from './InfoPacseDG';
import InfoCohabitantDG from './InfoCohabitantDG';

function InfoDgPM({ eurl_sasu }) {
  const classes = useStyles();
  const {
    values: { dg_title, dg_family_situation },
  } = useFormikContext();
  return (
    <>
      <h2 className={classes.subTitle}>Informations du Directeur Général</h2>
      <SmallRadio
        classes={classes}
        options={genders}
        value={dg_title}
        name="dg_title"
        style={{ marginBottom: '10%' }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom *"
        name="dg_name"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom *"
        name="dg_firstname"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Email *"
        name="dg_email"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="N° sécurité sociale *"
        name="dg_secu_number"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <h2 className={classes.subTitle}>
        Informations de naissance du Directeur Général
      </h2>
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de naissance *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="dg_date_of_birth"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        address={null}
        city="dg_place_of_birth"
        zipCode="dg_place_of_birth_zipCode"
        department="dg_state_of_birth"
        country="dg_country_of_birth"
        cityLabel="Ville de naissance *"
        countryLabel="Pays de naissance *"
        nationality="dg_nationality"
        additionalAddress="additional_address"
      />
      <Adresse
        title="Adresse du Directeur général"
        classes={classes}
        address="dg_place_of_living"
        city="dg_city"
        zipCode="dg_zipcode"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        additionalAddress="dg_additional_place_of_living"
      />
      <h2 className={classes.subTitle}>Filiation du Directeur Général</h2>
      <Field
        component={TextField}
        type="string"
        label="Nom du père *"
        name="dg_father_name"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom du père *"
        name="dg_father_firstname"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Nom de jeune fille de la mère *"
        name="dg_mother_name"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom de la mère *"
        name="dg_mother_firstname"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />

      {/* TO DO: info "Situation familiale" */}
      <SmallRadio
        classes={classes}
        title="Situation familiale *"
        options={familySituations}
        value={dg_family_situation}
        name="dg_family_situation"
      />

      {dg_family_situation === MARIED && (
        <InfoWeddingDG classes={classes} eurl_sasu={eurl_sasu} />
      )}
      {dg_family_situation === PACTE && (
        <InfoPacteDG classes={classes} eurl_sasu={eurl_sasu} />
      )}
      {dg_family_situation === CONCUBIN && (
        <InfoCohabitantDG classes={classes} eurl_sasu={eurl_sasu} />
      )}
    </>
  );
}

export default InfoDgPM;
