import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  // MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Formik, Field, Form } from 'formik';
import { RadioGroup, CheckboxWithLabel, TextField } from 'formik-material-ui';
import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Radio,
  Grid,
} from '@material-ui/core';
import DebugFormik from '../DebugFormik';
import ButtonBox from '../entreprise/ButtonBox';
import { DispatchContext } from '../../contexts/autoentrepreneur.context';
import { EDIT_COMPANY } from '../../constants/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    justifyContent: 'center',
    width: '100%',
  },
}));

const MesVoletsSocialEtFiscal = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formValues}
      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        setActiveStep(activeStep + 1);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Box
            p={1}
            bgcolor="background.paper"
            size="fullwidth"
            className={classes.root}
          >
            <Typography variant="h3">3. Mes volets social et fiscal</Typography>

            <Typography variant="h5">Ma couverture sociale</Typography>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={4}>
                <Typography variant="h6">Numéro de sécurité sociale</Typography>
              </Grid>
              <Grid item xs={4}>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="numéro_de_sécurité_sociale"
                  type="text"
                  label="Numéro de sécurité sociale"
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox2"
                  name="ce_numéro_ne_m_a_jamais_été_attribué1"
                  Label={{ label: "Ce numéro ne m'a jamais été attribué" }}
                />
              </Grid>
            </Grid>
            <br />

            <Typography variant="h5">Ma situation matrimoniale</Typography>
            <br />

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={8}>
                <Typography variant="h6">
                  Avez-vous un conjoint marié ou partenaire lié par un pacs
                  exercant une activité régulière dans l&apos;entreprise
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={RadioGroup}
                  name="ma_situation_matrimoniale"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            {/* If oui */}
            {values.ma_situation_matrimoniale === 'true' && (
              <div>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={12} sm={8}>
                    <Typography variant="h6">Quel est son statut ?</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={RadioGroup}
                      name="quel_est_son_statut"
                      className={classes.radio}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="collaborateur"
                            control={<Radio />}
                            label="Collaborateur"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="salarié"
                            control={<Radio />}
                            label="Salarié"
                          />
                        </Grid>
                      </Grid>
                    </Field>
                  </Grid>
                </Grid>
                <br />

                <Typography variant="h5">Identité de mon conjoint</Typography>
                <br />

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6">Nom de naissance</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          className={classes.field}
                          component={TextField}
                          fullWidth
                          name="nom_de_naissance2"
                          type="text"
                          label="Nom de naissance"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6">Nom d&apos;usage</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          className={classes.field}
                          component={TextField}
                          fullWidth
                          name="nom_d_usage2"
                          type="text"
                          label="Nom d'usage"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6">Prénom(s)</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          className={classes.field}
                          component={TextField}
                          fullWidth
                          name="prénom"
                          type="text"
                          label="Prénom(s)"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" color="primary">
                          ✚ Ajouter un prénom
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6">Date de naissance</Typography>
                      </Grid>
                      <Grid item xs={6} maxwidth>
                        <Field
                          className={classes.field}
                          component={KeyboardDatePicker}
                          clearable={true}
                          name="date_de_naissance2"
                          format="dd/MM/yyyy"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Lieu de naissance</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={RadioGroup}
                          name="lieu_de_naissance2"
                          className={classes.radio}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={5}>
                              <FormControlLabel
                                value="france"
                                control={<Radio />}
                                label="France"
                              />
                            </Grid>
                            <Grid item xs={7}>
                              <FormControlLabel
                                value="autre pays"
                                control={<Radio />}
                                label="Autre pays"
                              />
                            </Grid>
                          </Grid>
                        </Field>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography variant="h6">
                          Commune ou code postal de naissance
                        </Typography>
                      </Grid>
                      <Grid item xs={6} maxwidth>
                        <Field
                          className={classes.field}
                          component={TextField}
                          fullWidth
                          name="commune_ou_code_postal_de_naissance2"
                          type="text"
                          label="Example"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="h6">
                      Votre conjoint marié ou pacsé est-il couvert à titre
                      personnel par un régime d&apos;assurance maladie ?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={RadioGroup}
                      name="radio4"
                      className={classes.radio}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="oui"
                            control={<Radio />}
                            label="Oui"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="non"
                            control={<Radio />}
                            label="Non"
                          />
                        </Grid>
                      </Grid>
                    </Field>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={4}>
                    <Typography variant="h6">
                      Numéro de sécurité sociale de votre conjoint
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="numéro_de_sécurité_sociale_de_votre_conjoint"
                      type="text"
                      label="Example"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="ce_numéro_ne_m_a_jamais_été_attribué2"
                      Label={{
                        label: "Ce numéro ne m'a jamais été attribué",
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            <br />
            {/* End */}

            <Typography variant="h5">Ma périodicité</Typography>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={8}>
                <Typography variant="h6">
                  Versement de mes cotisations
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={RadioGroup}
                  name="versement_de_mes_cotisations"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="mensuel"
                        control={<Radio />}
                        label="Mensuel"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="trimestriel"
                        control={<Radio />}
                        label="Trimestriel"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>
            <br />

            <Typography variant="h5">Questions complémentaires</Typography>
            <br />

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={8}>
                <Typography variant="h6">
                  Souhaitez-vous opter pour le versement libératoire de
                  l&apos;impôt sur le revenu ?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={RadioGroup}
                  name="radio6"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="oui"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="non"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={8}>
                <Typography variant="h6">
                  Souhaitez-vous opter pour le statut d&apos;Entrepreneur
                  Individuel à Responsabilité Limitée (EIRL) ?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={RadioGroup}
                  name="radio7"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="oui"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="non"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            {/* If Oui */}
            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={12}>
                <Typography variant="h5">
                  Déclaration d&apos;affectation de patrimoine professionnel
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={RadioGroup}
                  name="radio8"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        value="Déclarationinitiale"
                        control={<Radio />}
                        label="Déclaration initiale"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        value="Reprised'unpatrimoine"
                        control={<Radio />}
                        label="Reprise d'un patrimoine"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="checkbox2"
              Label={{
                label:
                  "Option pour le versement libératoire de l'impôt sur le revenu calculé sur le chiffre d'affaires ou les recettes",
              }}
            />

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={6}>
                <Typography variant="h6">Dénomination</Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="dénomination"
                  type="text"
                  label="Dénomination"
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={6}>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    Objet de l&apos;activité professionnelle
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="objet_de_l_activité_professionnelle"
                  type="text"
                  label="Objet de l'activité professionnelle"
                />
              </Grid>
            </Grid>

            {/* If Reprise d'un patrimoine */}
            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={6}>
                <Typography variant="h6">
                  Dénomination du précédent entrepreneur
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="Dénomination du précédent entrepreneur"
                  type="text"
                  label="Dénomination du précédent entrepreneur"
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={6}>
                <Typography variant="h6">Lieu du registre du dépôt</Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="lieu_du_registre_du_dépôt"
                  type="text"
                  label="Lieu du registre du dépôt"
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={6}>
                <Typography variant="h6">
                  SIREN du précédent entrepreneur
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="SIREN_du_précédent_entrepreneur"
                  type="text"
                  label="SIREN du précédent entrepreneur"
                />
              </Grid>
            </Grid>

            {/* End */}
          </Box>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

export default MesVoletsSocialEtFiscal;
