import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import {
  Popover,
  IconButton,
  FormControlLabel,
  MenuItem,
  Radio,
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { TextField, Select, RadioGroup } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';

import { makeStyles } from '@material-ui/core/styles';

// import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

const ChoixCommissaireAuxComptes = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const [cityList, setCityList] = useState([]);
  const [cityValue, setcityValue] = useState(null);
  const [cityInput, setCityInput] = useState(null);
  const [loadingCityList, setLoadingCityList] = useState(false);

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const cityInputDebounce = useRef(
    _.debounce((newValue) => setCityInput(newValue), 500)
  );

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (cityInput) {
      setLoadingCityList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: cityInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setCityList(data.features);
          } else {
            setCityList([]);
          }
          setLoadingCityList(false);
        });
    } else {
      setCityList([]);
      setLoadingCityList(false);
    }
  }, [cityInput]);

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchCity = async (newInputValue) => {
    setLoadingCityList(true);
    setCityInput(null);
    cityInputDebounce.current(newInputValue);
  };

  const handleSelectCity = async (newValue, setFieldValue, fieldName) => {
    if (newValue) {
      setcityValue(newValue);
      setFieldValue(fieldName, newValue.properties.label);
    } else {
      setFieldValue(fieldName, '');
      setcityValue(newValue);
    }
  };

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue, fieldName) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue(fieldName, newValue.properties.label);
    } else {
      setFieldValue(fieldName, '');
      setAddressValue(newValue);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let popoverTextCAC = '';

  if (formValues.eurl_sasu === 'eurl') {
    popoverTextCAC = (
      <div className={classes.popOverDiv}>
        <Typography>
          Lors de la création d&apos;une société commerciale, la nomination
          d&apos;un commissaire aux comptes (CAC) est elle obligatoire?
          <h4>Qu’est ce qu’un commissaire aux comptes ?</h4>
          Le commissaire aux comptes (CAC) réalise une mission d’audit externe
          indépendant qui le conduit à contrôler les comptes de son client, le
          respect des obligations légales, sociales et fiscales et la sécurité
          financière pour les tiers et les actionnaires et ce notamment au
          travers d’une approche par les risques et le contrôle interne.
          <br />
          <br />
          Il s’agit d’une mission d’intérêt général.
          <br />
          <br />
          La désignation d’un commissaire aux comptes par une société peut-être
          obligatoire ou facultative elle dépend du total du bilan, du chiffre
          d’affaires hors taxes et du nombre de salariés au cours de l’exercice.
          <h4>
            Quels sont les seuils de nomination obligatoire d’un commissaire aux
            comptes ?
          </h4>
          Quelle que soit la forme juridique, toutes les sociétés commerciales
          doivent obligatoirement nommer au moins 1 commissaire aux comptes si
          la société dépasse 2 des seuils suivants :
          <br />· 4 M€ bilan
          <br />· 8 M€ de chiffre d’affaires HT
          <br />· 50 salariés <br />
          En dessous de ces seuils la nomination d’un commissaire aux comptes
          reste facultative.
          <h4>
            Quelles sont les modalités de désignation d’un commissaire aux
            comptes ?
          </h4>
          En cas de franchissement des seuils au cours d&apos;un exercice, il
          n’est pas obligatoire de nommer un commissaire aux comptes pour
          l’exercice en cours mais pour l’exercice suivant.
          <br />
          <br />
          La désignation d’un commissaire aux comptes peut se faire
          <br />
          <br />
          lors d’une assemblée générale ordinaire (AGO) de fin d’exercice pour
          les SARL/EURL
          <br />
          par décision collective sur proposition du président en SAS/SASU
          <br />
          lors d’une assemblée générale ordinaire (AGO) sur proposition du CA ou
          du CS pour les SA
          <h4>Qu’est-ce qu’un suppléant ?</h4>
          En cas d’empêchement, le commissaire aux comptes titulaire peut être
          remplacé par un suppléant (son remplaçnat en quelque sorte). Si le
          commissaire aux comptes titulaire est une société avec plusieurs
          associés commissaires aux comptes, la nomination d’un suppléant n’est
          pas obligatoire. Si le commissaire aux comptes titulaire est une
          société mon associé ou un indépendant, la nomination d’un suppléant
          est obligatoire.
          <h4>Nomination en dehors des seuils ?</h4>
          Toute société peut nommer volontairement un commissaire aux comptes,
          parfois à la demande de certains financeurs ou de certains
          actionnaires Par ailleurs, la désignation d’un commissaire aux comptes
          est obligatoire, même si les seuils légaux ne sont pas atteints en cas
          de demande en justice :
          <br />
          <br />
          des associés minoritaires représentant au moins 10% du capital dans
          les SA, SCA, SAS, SARL;
          <br />
          par un associé d’une SNC
          <h4>
            Que se passe-t-il si la société passe sous les seuils obligatoires
            de nomination ?
          </h4>
          Le commissaire est en fonction durant toute la durée de son mandat
          lorsqu’il est nommé (6 ans ou 3 ans en cas de désignation volontaire
          en dessous des seuils, mission ALPE) et ce même si la société passe
          sous les seuils de nomination.
          <h4>
            Quelle sanction encouru en cas de non désignation d’un commissaire
            aux comptes après dépassement des seuils ?
          </h4>
          La non nomination d’un commissaire aux comptes entraîne la nullité des
          délibérations prises en Assemblée générale. Les dirigeants de la
          société encourent une sanction allant jusqu’à 2 ans d’emprisonnement
          et une amende de 30 000 €.
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sasu') {
    popoverTextCAC = (
      <div className={classes.popOverDiv}>
        <Typography>
          Lors de la création d&apos;une société commerciale, la nomination
          d&apos;un commissaire aux comptes (CAC) est elle obligatoire?
          <h4>Qu’est ce qu’un commissaire aux comptes ?</h4>
          Le commissaire aux comptes (CAC) réalise une mission d’audit externe
          indépendant qui le conduit à contrôler les comptes de son client, le
          respect des obligations légales, sociales et fiscales et la sécurité
          financière pour les tiers et les actionnaires et ce notamment au
          travers d’une approche par les risques et le contrôle interne.
          <br />
          <br />
          Il s’agit d’une mission d’intérêt général.
          <br />
          <br />
          La désignation d’un commissaire aux comptes par une société peut-être
          obligatoire ou facultative elle dépend du total du bilan, du chiffre
          d’affaires hors taxes et du nombre de salariés au cours de l’exercice.
          <h4>
            Quels sont les seuils de nomination obligatoire d’un commissaire aux
            comptes ?
          </h4>
          Quelle que soit la forme juridique, toutes les sociétés commerciales
          doivent obligatoirement nommer au moins 1 commissaire aux comptes si
          la société dépasse 2 des seuils suivants : <br />
          · 4 M€ bilan <br />
          · 8 M€ de chiffre d’affaires HT
          <br />
          · 50 salariés <br />
          En dessous de ces seuils la nomination d’un commissaire aux comptes
          reste facultative.
          <h4>
            Quelles sont les modalités de désignation d’un commissaire aux
            comptes ?
          </h4>
          En cas de franchissement des seuils au cours d&apos;un exercice, il
          n’est pas obligatoire de nommer un commissaire aux comptes pour
          l’exercice en cours mais pour l’exercice suivant.
          <br />
          <br />
          La désignation d’un commissaire aux comptes peut se faire
          <br />
          <br />
          lors d’une assemblée générale ordinaire (AGO) de fin d’exercice pour
          les SARL/EURL
          <br />
          par décision collective sur proposition du président en SAS/SASU
          <br />
          lors d’une assemblée générale ordinaire (AGO) sur proposition du CA ou
          du CS pour les SA
          <h4>Qu’est-ce qu’un suppléant ?</h4>
          En cas d’empêchement, le commissaire aux comptes titulaire peut être
          remplacé par un suppléant (son remplaçnat en quelque sorte). Si le
          commissaire aux comptes titulaire est une société avec plusieurs
          associés commissaires aux comptes, la nomination d’un suppléant n’est
          pas obligatoire. Si le commissaire aux comptes titulaire est une
          société mon associé ou un indépendant, la nomination d’un suppléant
          est obligatoire.
          <h4>Nomination en dehors des seuils ?</h4>
          Toute société peut nommer volontairement un commissaire aux comptes,
          parfois à la demande de certains financeurs ou de certains
          actionnaires Par ailleurs, la désignation d’un commissaire aux comptes
          est obligatoire, même si les seuils légaux ne sont pas atteints en cas
          de demande en justice :<br />
          <br />
          des associés minoritaires représentant au moins 10% du capital dans
          les SA, SCA, SAS, SARL; <br />
          par un associé d’une SNC
          <h4>
            Que se passe-t-il si la société passe sous les seuils obligatoires
            de nomination ?
          </h4>
          Le commissaire est en fonction durant toute la durée de son mandat
          lorsqu’il est nommé (6 ans ou 3 ans en cas de désignation volontaire
          en dessous des seuils, mission ALPE) et ce même si la société passe
          sous les seuils de nomination.
          <h4>
            Quelle sanction encouru en cas de non désignation d’un commissaire
            aux comptes après dépassement des seuils ?
          </h4>
          La non nomination d’un commissaire aux comptes entraîne la nullité des
          délibérations prises en Assemblée générale. Les dirigeants de la
          société encourent une sanction allant jusqu’à 2 ans d’emprisonnement
          et une amende de 30 000 €.
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sas') {
    popoverTextCAC = (
      <div className={classes.popOverDiv}>
        <Typography>
          Lors de la création d&apos;une société commerciale, la nomination
          d&apos;un commissaire aux comptes (CAC) est elle obligatoire?
          <h4>Qu’est ce qu’un commissaire aux comptes ?</h4>
          Le commissaire aux comptes (CAC) réalise une mission d’audit externe
          indépendant qui le conduit à contrôler les comptes de son client, le
          respect des obligations légales, sociales et fiscales et la sécurité
          financière pour les tiers et les actionnaires et ce notamment au
          travers d’une approche par les risques et le contrôle interne.
          <br />
          <br />
          Il s’agit d’une mission d’intérêt général.
          <br />
          <br />
          La désignation d’un commissaire aux comptes par une société peut-être
          obligatoire ou facultative elle dépend du total du bilan, du chiffre
          d’affaires hors taxes et du nombre de salariés au cours de l’exercice.
          <h4>
            Quels sont les seuils de nomination obligatoire d’un commissaire aux
            comptes ?
          </h4>
          Quelle que soit la forme juridique, toutes les sociétés commerciales
          doivent obligatoirement nommer au moins 1 commissaire aux comptes si
          la société dépasse 2 des seuils suivants : <br />
          · 4 M€ bilan <br />
          · 8 M€ de chiffre d’affaires HT
          <br />
          · 50 salariés <br />
          En dessous de ces seuils la nomination d’un commissaire aux comptes
          reste facultative.
          <h4>
            Quelles sont les modalités de désignation d’un commissaire aux
            comptes ?
          </h4>
          En cas de franchissement des seuils au cours d&apos;un exercice, il
          n’est pas obligatoire de nommer un commissaire aux comptes pour
          l’exercice en cours mais pour l’exercice suivant.
          <br />
          <br />
          La désignation d’un commissaire aux comptes peut se faire
          <br />
          <br />
          lors d’une assemblée générale ordinaire (AGO) de fin d’exercice pour
          les SARL/EURL
          <br />
          par décision collective sur proposition du président en SAS/SASU
          <br />
          lors d’une assemblée générale ordinaire (AGO) sur proposition du CA ou
          du CS pour les SA
          <h4>Qu’est-ce qu’un suppléant ?</h4>
          En cas d’empêchement, le commissaire aux comptes titulaire peut être
          remplacé par un suppléant (son remplaçnat en quelque sorte). Si le
          commissaire aux comptes titulaire est une société avec plusieurs
          associés commissaires aux comptes, la nomination d’un suppléant n’est
          pas obligatoire. Si le commissaire aux comptes titulaire est une
          société mon associé ou un indépendant, la nomination d’un suppléant
          est obligatoire.
          <h4>Nomination en dehors des seuils ?</h4>
          Toute société peut nommer volontairement un commissaire aux comptes,
          parfois à la demande de certains financeurs ou de certains
          actionnaires Par ailleurs, la désignation d’un commissaire aux comptes
          est obligatoire, même si les seuils légaux ne sont pas atteints en cas
          de demande en justice :<br />
          <br />
          des associés minoritaires représentant au moins 10% du capital dans
          les SA, SCA, SAS, SARL; <br />
          par un associé d’une SNC
          <h4>
            Que se passe-t-il si la société passe sous les seuils obligatoires
            de nomination ?
          </h4>
          Le commissaire est en fonction durant toute la durée de son mandat
          lorsqu’il est nommé (6 ans ou 3 ans en cas de désignation volontaire
          en dessous des seuils, mission ALPE) et ce même si la société passe
          sous les seuils de nomination.
          <h4>
            Quelle sanction encouru en cas de non désignation d’un commissaire
            aux comptes après dépassement des seuils ?
          </h4>
          La non nomination d’un commissaire aux comptes entraîne la nullité des
          délibérations prises en Assemblée générale. Les dirigeants de la
          société encourent une sanction allant jusqu’à 2 ans d’emprisonnement
          et une amende de 30 000 €.
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sarl') {
    popoverTextCAC = (
      <div className={classes.popOverDiv}>
        <Typography>
          Lors de la création d&apos;une société commerciale, la nomination
          d&apos;un commissaire aux comptes (CAC) est elle obligatoire?
          <h4>Qu’est ce qu’un commissaire aux comptes ?</h4>
          Le commissaire aux comptes (CAC) réalise une mission d’audit externe
          indépendant qui le conduit à contrôler les comptes de son client, le
          respect des obligations légales, sociales et fiscales et la sécurité
          financière pour les tiers et les actionnaires et ce notamment au
          travers d’une approche par les risques et le contrôle interne.
          <br />
          <br />
          Il s’agit d’une mission d’intérêt général.
          <br />
          <br />
          La désignation d’un commissaire aux comptes par une société peut-être
          obligatoire ou facultative elle dépend du total du bilan, du chiffre
          d’affaires hors taxes et du nombre de salariés au cours de l’exercice.
          <h4>
            Quels sont les seuils de nomination obligatoire d’un commissaire aux
            comptes ?
          </h4>
          Quelle que soit la forme juridique, toutes les sociétés commerciales
          doivent obligatoirement nommer au moins 1 commissaire aux comptes si
          la société dépasse 2 des seuils suivants : <br />
          · 4 M€ bilan <br />
          · 8 M€ de chiffre d’affaires HT
          <br />
          · 50 salariés <br />
          En dessous de ces seuils la nomination d’un commissaire aux comptes
          reste facultative.
          <h4>
            Quelles sont les modalités de désignation d’un commissaire aux
            comptes ?
          </h4>
          En cas de franchissement des seuils au cours d&apos;un exercice, il
          n’est pas obligatoire de nommer un commissaire aux comptes pour
          l’exercice en cours mais pour l’exercice suivant.
          <br />
          <br />
          La désignation d’un commissaire aux comptes peut se faire
          <br />
          <br />
          lors d’une assemblée générale ordinaire (AGO) de fin d’exercice pour
          les SARL/EURL
          <br />
          par décision collective sur proposition du président en SAS/SASU
          <br />
          lors d’une assemblée générale ordinaire (AGO) sur proposition du CA ou
          du CS pour les SA
          <h4>Qu’est-ce qu’un suppléant ?</h4>
          En cas d’empêchement, le commissaire aux comptes titulaire peut être
          remplacé par un suppléant (son remplaçnat en quelque sorte). Si le
          commissaire aux comptes titulaire est une société avec plusieurs
          associés commissaires aux comptes, la nomination d’un suppléant n’est
          pas obligatoire. Si le commissaire aux comptes titulaire est une
          société mon associé ou un indépendant, la nomination d’un suppléant
          est obligatoire.
          <h4>Nomination en dehors des seuils ?</h4>
          Toute société peut nommer volontairement un commissaire aux comptes,
          parfois à la demande de certains financeurs ou de certains
          actionnaires Par ailleurs, la désignation d’un commissaire aux comptes
          est obligatoire, même si les seuils légaux ne sont pas atteints en cas
          de demande en justice :<br />
          <br />
          des associés minoritaires représentant au moins 10% du capital dans
          les SA, SCA, SAS, SARL; <br />
          par un associé d’une SNC
          <h4>
            Que se passe-t-il si la société passe sous les seuils obligatoires
            de nomination ?
          </h4>
          Le commissaire est en fonction durant toute la durée de son mandat
          lorsqu’il est nommé (6 ans ou 3 ans en cas de désignation volontaire
          en dessous des seuils, mission ALPE) et ce même si la société passe
          sous les seuils de nomination.
          <h4>
            Quelle sanction encouru en cas de non désignation d’un commissaire
            aux comptes après dépassement des seuils ?
          </h4>
          La non nomination d’un commissaire aux comptes entraîne la nullité des
          délibérations prises en Assemblée générale. Les dirigeants de la
          société encourent une sanction allant jusqu’à 2 ans d’emprisonnement
          et une amende de 30 000 €.
        </Typography>
      </div>
    );
  }

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     presence_commissaire_aux_comptes: Yup.string().required(
      //       'un choix est necessaire'
      //     ),

      //     duree_exercice_commissaire_aux_comptes: Yup.number().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.number().required('Required');
      //         }
      //         return Yup.number();
      //       }
      //     ),

      //     nom_commissaire_aux_comptes: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     prenom_commissaire_aux_comptes: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     nom_cabinet_commissaire_aux_comptes: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     adresse_cabinet_commissaire_aux_comptes: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     nom_suppleant: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     prenom_suppleant: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     adresse_cabinet_suppleant: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });

        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }

        if (formValues.kit === 'basiquesoc') {
          Automate.nextChoixCommissaireAuxComptesVersListeFin();
          setAutomateState(Automate.state);
        }
        if (formValues.kit === 'kit1soc') {
          Automate.nextChoixCommissaireAuxComptesVersInfoCommissaireAuxComptes();
          setAutomateState(Automate.state);
        }
        if (formValues.kit === 'kit2soc') {
          Automate.nextChoixCommissaireAuxComptesVersChoixProcurationBbf();
          setAutomateState(Automate.state);
        }
        if (formValues.kit === 'kit3soc') {
          Automate.nextChoixCommissaireAuxComptesVersChoixProcurationBbf();
          setAutomateState(Automate.state);
        }
        if (formValues.kit === 'kit4soc') {
          Automate.nextChoixCommissaireAuxComptesVersChoixProcurationBbf();
          setAutomateState(Automate.state);
        }

        // Automate.nextChoixCommissaireAuxComptesVersInfoCommissaireAuxComptes();
        // setAutomateState(Automate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => {
        return (
          <Form fullwidth="true">
            <Paper>
              <Box
                display="flex"
                p={1}
                bgcolor="background.paper"
                className={classes.root}
              >
                <Typography variant="h6">Commissaire aux comptes</Typography>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClick}
                >
                  <HelpOutlineOutlined />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  // marginThreshold="55"
                  onClose={handleClose}
                  // anchorOrigin={{
                  //   vertical: 'center',
                  //   horizontal: 'center',
                  // }}
                  anchorReference={'none'}
                  className={classes.popOver}
                >
                  {popoverTextCAC}
                </Popover>

                <Typography variant="subtitle1">
                  {' '}
                  Souhaitez-vous nommer un commissaire aux comptes pour votre
                  société en création?{' '}
                </Typography>
                <Field
                  component={RadioGroup}
                  name="presence_commissaire_aux_comptes"
                >
                  <FormControlLabel
                    // onChange={() => {
                    //   ctx[0].preexisting_contract = 'oui';
                    // }}
                    value="oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    // onChange={() => {
                    //   ctx[0].preexisting_contract = 'non';
                    // }}
                    value="non"
                    control={<Radio />}
                    label="Non"
                  />
                </Field>

                {values.presence_commissaire_aux_comptes === 'oui' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Typography variant="h6">
                        Informations sur le commissaire aux comptes titulaire
                      </Typography>

                      {/* demande de la durée d'exercice du commissaire aux comptes en année */}
                      <Box>
                        <Typography variant="subtitle2">
                          Veuillez indiquer ci-dessous, le nombre
                          d&apos;exercices du commissaire aux comptes{' '}
                        </Typography>
                      </Box>
                      <Box alignItems="center">
                        <FormControl>
                          <Field
                            component={Select}
                            name="duree_exercice_commissaire_aux_comptes"
                            inputProps={{
                              id: 'duree_exercice_commissaire_aux_comptes',
                            }}
                          >
                            <MenuItem value={3}>3 exercices</MenuItem>
                            <MenuItem value={6}>6 exercices</MenuItem>
                          </Field>
                        </FormControl>
                      </Box>

                      {/* MBM */}

                      <Box textAlign="center">
                        <Typography variant="subtitle1">
                          Le Commissaire aux Comptes est une personne physique
                          ou morale ?
                        </Typography>
                        <Field component={RadioGroup} name="type_CAC">
                          <FormControlLabel
                            value="physique"
                            control={<Radio />}
                            label="Personne Physique"
                          />
                          <FormControlLabel
                            value="morale"
                            control={<Radio />}
                            label="Personne Morale"
                          />
                        </Field>
                      </Box>

                      {/* /MBM */}

                      {/* MBM */}

                      {values.type_CAC === 'morale' && (
                        <Fragment>
                          <Box className={classes.root}>
                            <FormControl>
                              <Typography variant="subtitle2">
                                Forme de la société du commissaire aux comptes
                              </Typography>
                              <Field
                                component={Select}
                                name="forme_juridique_cabinet_CAC"
                                inputProps={{
                                  id: 'forme_juridique_cabinet_CAC',
                                }}
                              >
                                <MenuItem value={'EURL'}>EURL</MenuItem>
                                <MenuItem value={'SA'}>SA</MenuItem>
                                <MenuItem value={'SARL'}>SARL</MenuItem>
                                <MenuItem value={'SAS'}>SAS</MenuItem>
                                <MenuItem value={'SASU'}>SASU</MenuItem>
                                <MenuItem value={'SC'}>SC</MenuItem>
                                <MenuItem value={'SCA'}>SCA</MenuItem>
                                <MenuItem value={'SCS'}>SCS</MenuItem>
                              </Field>
                            </FormControl>
                            <Field
                              component={TextField}
                              type="string"
                              label="Nom du cabinet du commissaire aux comptes"
                              name="nom_cabinet_commissaire_aux_comptes"
                              className={classes.textField}
                              autoComplete="off"
                            />
                            <Field
                              component={Autocomplete}
                              // type="string"
                              // label="Adresse du cabinet du commissaire aux comptes"
                              // placeholder="123 rue Bidon 57560 Paris"
                              name="adresse_cabinet_commissaire_aux_comptes"
                              className={classes.textField}
                              value={addressValue}
                              loading={loadingAddressList}
                              options={addressList}
                              filterOptions={(x) => x}
                              autoComplete
                              includeInputInList
                              filterSelectedOptions
                              getOptionLabel={(option) =>
                                option &&
                                option.properties &&
                                option.properties.label
                              }
                              onInputChange={(event, newInputValue) =>
                                handleFetchAddress(newInputValue)
                              }
                              onChange={(event, newValue) =>
                                handleSelectAddress(
                                  newValue,
                                  setFieldValue,
                                  'adresse_cabinet_commissaire_aux_comptes'
                                )
                              }
                              renderInput={(params) => (
                                <MUITextField
                                  {...params}
                                  error={
                                    touched.adresse_cabinet_commissaire_aux_comptes &&
                                    !!errors.adresse_cabinet_commissaire_aux_comptes
                                  }
                                  helperText={
                                    touched.adresse_cabinet_commissaire_aux_comptes &&
                                    errors.adresse_cabinet_commissaire_aux_comptes
                                  }
                                  label="Adresse du cabinet du commissaire aux comptes"
                                  placeholder={
                                    formValues.adresse_cabinet_commissaire_aux_comptes
                                  }
                                  size="small"
                                />
                              )}
                              renderOption={(option) => {
                                return (
                                  <Grid container alignItems="center">
                                    <Grid item>
                                      <LocationOnIcon />
                                    </Grid>
                                    <Grid item xs>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {option.properties.label}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                );
                              }}
                            />
                          </Box>
                        </Fragment>
                      )}

                      {/* /MBM */}

                      {/* MBM */}

                      {values.type_CAC === 'physique' && (
                        <Fragment>
                          <Box>
                            {/* <Box textAlign="center">
                            <Typography variant="subtitle1">
                              Le Commissaire aux Comptes exerce t-il en nom
                              propre ?
                            </Typography>
                            <Field component={RadioGroup} name="nom_propre_CAC">
                              <FormControlLabel
                                value="nom_propre"
                                control={<Radio />}
                                label="En nom propre"
                              />
                              <FormControlLabel
                                value="societe"
                                control={<Radio />}
                                label="En société"
                              />
                            </Field>
                          </Box> */}

                            <Field
                              component={RadioGroup}
                              defaultValue="M."
                              name="titre_commissaire_aux_comptes"
                            >
                              <Typography variant="subtitle2">
                                informations commissaire aux comptes
                              </Typography>
                              <FormControlLabel
                                value="M."
                                control={<Radio />}
                                label="M."
                              />
                              <FormControlLabel
                                value="Mme"
                                control={<Radio />}
                                label="Mme"
                              />
                            </Field>

                            {/* /MBM */}

                            <Field
                              component={TextField}
                              type="string"
                              label="Nom du commissaire aux comptes"
                              name="nom_commissaire_aux_comptes"
                              className={classes.textField}
                              autoComplete="off"
                            />
                            <Field
                              component={TextField}
                              type="string"
                              label="Prénom du commissaire aux comptes"
                              name="prenom_commissaire_aux_comptes"
                              className={classes.textField}
                              autoComplete="off"
                            />
                          </Box>

                          {/* MBM */}
                          {/* {values.nom_propre_CAC === 'societe' && ( */}
                          {values.type_CAC === 'morale' && (
                            <Fragment>
                              <Box>
                                <Box textAlign="center">
                                  <FormControl>
                                    <Typography variant="subtitle2">
                                      Forme du cabinet du commissaire aux
                                      comptes
                                    </Typography>
                                    <Field
                                      component={Select}
                                      name="forme_juridique_cabinet_CAC"
                                      inputProps={{
                                        id: 'forme_juridique_cabinet_CAC',
                                      }}
                                    >
                                      <MenuItem value={'EURL'}>EURL</MenuItem>
                                      <MenuItem value={'SA'}>SA</MenuItem>
                                      <MenuItem value={'SARL'}>SARL</MenuItem>
                                      <MenuItem value={'SAS'}>SAS</MenuItem>
                                      <MenuItem value={'SASU'}>SASU</MenuItem>
                                      <MenuItem value={'SC'}>SC</MenuItem>
                                      <MenuItem value={'SCA'}>SCA</MenuItem>
                                      <MenuItem value={'SCS'}>SCS</MenuItem>
                                    </Field>
                                  </FormControl>
                                </Box>

                                {/* /MBM */}

                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Nom du cabinet du commissaire aux comptes"
                                  name="nom_cabinet_commissaire_aux_comptes"
                                  className={classes.textField}
                                  autoComplete="off"
                                />
                                <Field
                                  component={Autocomplete}
                                  // type="string"
                                  // label="Adresse du cabinet du commissaire aux comptes"
                                  // placeholder="123 rue Bidon 57560 Paris"
                                  name="adresse_cabinet_commissaire_aux_comptes"
                                  className={classes.textField}
                                  value={addressValue}
                                  loading={loadingAddressList}
                                  options={addressList}
                                  filterOptions={(x) => x}
                                  autoComplete
                                  includeInputInList
                                  filterSelectedOptions
                                  getOptionLabel={(option) =>
                                    option &&
                                    option.properties &&
                                    option.properties.label
                                  }
                                  onInputChange={(event, newInputValue) =>
                                    handleFetchAddress(newInputValue)
                                  }
                                  onChange={(event, newValue) =>
                                    handleSelectAddress(
                                      newValue,
                                      setFieldValue,
                                      'adresse_cabinet_commissaire_aux_comptes'
                                    )
                                  }
                                  renderInput={(params) => (
                                    <MUITextField
                                      {...params}
                                      error={
                                        touched.adresse_cabinet_commissaire_aux_comptes &&
                                        !!errors.adresse_cabinet_commissaire_aux_comptes
                                      }
                                      helperText={
                                        touched.adresse_cabinet_commissaire_aux_comptes &&
                                        errors.adresse_cabinet_commissaire_aux_comptes
                                      }
                                      label="Adresse du cabinet du commissaire aux comptes"
                                      placeholder={
                                        formValues.adresse_cabinet_commissaire_aux_comptes
                                      }
                                      size="small"
                                    />
                                  )}
                                  renderOption={(option) => {
                                    return (
                                      <Grid container alignItems="center">
                                        <Grid item>
                                          <LocationOnIcon />
                                        </Grid>
                                        <Grid item xs>
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                          >
                                            {option.properties.label}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    );
                                  }}
                                />
                              </Box>
                            </Fragment>
                          )}

                          {/* MBM */}

                          {/* {values.nom_propre_CAC === 'nom_propre' && ( */}
                          {values.type_CAC === 'physique' && (
                            <Fragment>
                              <Box>
                                <Field
                                  component={Autocomplete}
                                  name="adresse_cabinet_commissaire_aux_comptes"
                                  className={classes.textField}
                                  value={addressValue}
                                  loading={loadingAddressList}
                                  options={addressList}
                                  filterOptions={(x) => x}
                                  autoComplete
                                  includeInputInList
                                  filterSelectedOptions
                                  getOptionLabel={(option) =>
                                    option &&
                                    option.properties &&
                                    option.properties.label
                                  }
                                  onInputChange={(event, newInputValue) =>
                                    handleFetchAddress(newInputValue)
                                  }
                                  onChange={(event, newValue) =>
                                    handleSelectAddress(
                                      newValue,
                                      setFieldValue,
                                      'adresse_cabinet_commissaire_aux_comptes'
                                    )
                                  }
                                  renderInput={(params) => (
                                    <MUITextField
                                      {...params}
                                      error={
                                        touched.adresse_cabinet_commissaire_aux_comptes &&
                                        !!errors.adresse_cabinet_commissaire_aux_comptes
                                      }
                                      helperText={
                                        touched.adresse_cabinet_commissaire_aux_comptes &&
                                        errors.adresse_cabinet_commissaire_aux_comptes
                                      }
                                      label="Adresse du Commissaire aux comptes"
                                      placeholder={
                                        formValues.adresse_cabinet_commissaire_aux_comptes
                                      }
                                      size="small"
                                    />
                                  )}
                                  renderOption={(option) => {
                                    return (
                                      <Grid container alignItems="center">
                                        <Grid item>
                                          <LocationOnIcon />
                                        </Grid>
                                        <Grid item xs>
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                          >
                                            {option.properties.label}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    );
                                  }}
                                />
                              </Box>
                            </Fragment>
                          )}
                        </Fragment>
                      )}

                      {values.type_CAC === 'morale' && (
                        <Box textAlign="center">
                          <Typography variant="subtitle1">
                            Y a t-il un Commissaire aux Comptes suppléant ?
                          </Typography>
                          <Field component={RadioGroup} name="cac_suppleant">
                            <FormControlLabel
                              value="oui"
                              control={<Radio />}
                              label="Oui"
                            />
                            <FormControlLabel
                              value="non"
                              control={<Radio />}
                              label="non"
                            />
                          </Field>
                        </Box>
                      )}

                      {values.cac_suppleant === 'oui' && (
                        <Fragment>
                          <Box textAlign="center">
                            <Typography variant="subtitle1">
                              Le Commissaire aux Comptes suppléant est une
                              personne physique ou morale ?
                            </Typography>
                            <Field
                              component={RadioGroup}
                              name="type_CAC_suppleant"
                            >
                              <FormControlLabel
                                value="physique"
                                control={<Radio />}
                                label="Personne Physique"
                              />
                              <FormControlLabel
                                value="morale"
                                control={<Radio />}
                                label="Personne Morale"
                              />
                            </Field>
                          </Box>

                          {values.type_CAC_suppleant === 'physique' && (
                            <Fragment>
                              <Field
                                component={RadioGroup}
                                defaultValue="M."
                                name="titre_suppleant"
                              >
                                <Typography variant="subtitle2">
                                  informations du suppléant
                                </Typography>
                                <FormControlLabel
                                  value="M."
                                  control={<Radio />}
                                  label="M."
                                />
                                <FormControlLabel
                                  value="Mme"
                                  control={<Radio />}
                                  label="Mme"
                                />
                              </Field>
                              <Box textAlign="center">
                                {/* /MBM */}

                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Nom du suppléant"
                                  name="nom_suppleant"
                                  className={classes.textField}
                                  autoComplete="off"
                                />
                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Prénom du suppléant"
                                  name="prenom_suppleant"
                                  className={classes.textField}
                                  autoComplete="off"
                                />
                              </Box>
                            </Fragment>
                          )}

                          {values.type_CAC_suppleant === 'morale' && (
                            <Fragment>
                              <Typography variant="subtitle2">
                                informations du suppléant
                              </Typography>

                              <Box textAlign="center">
                                {/* /MBM */}

                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Raison sociale du suppléant"
                                  name="nom_suppleant"
                                  className={classes.textField}
                                  autoComplete="off"
                                />
                              </Box>
                            </Fragment>
                          )}

                          <Box textAlign="center">
                            <Field
                              component={Autocomplete}
                              // type="string"
                              // label="Adresse du cabinet du suppléant "
                              // placeholder="123 rue Bidon 57560 Paris"
                              name="adresse_cabinet_suppleant"
                              className={classes.textField}
                              value={cityValue}
                              loading={loadingCityList}
                              options={cityList}
                              filterOptions={(x) => x}
                              autoComplete
                              includeInputInList
                              filterSelectedOptions
                              getOptionLabel={(option) =>
                                option &&
                                option.properties &&
                                option.properties.label
                              }
                              onInputChange={(event, newInputValue) =>
                                handleFetchCity(newInputValue)
                              }
                              onChange={(event, newValue) =>
                                handleSelectCity(
                                  newValue,
                                  setFieldValue,
                                  'adresse_cabinet_suppleant'
                                )
                              }
                              renderInput={(params) => (
                                <MUITextField
                                  {...params}
                                  error={
                                    touched.adresse_cabinet_suppleant &&
                                    !!errors.adresse_cabinet_suppleant
                                  }
                                  helperText={
                                    touched.adresse_cabinet_suppleant &&
                                    errors.adresse_cabinet_suppleant
                                  }
                                  label="Adresse du cabinet du suppléant "
                                  placeholder={
                                    formValues.adresse_cabinet_suppleant
                                  }
                                  size="small"
                                />
                              )}
                              renderOption={(option) => {
                                return (
                                  <Grid container alignItems="center">
                                    <Grid item>
                                      <LocationOnIcon />
                                    </Grid>
                                    <Grid item xs>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {option.properties.label}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                );
                              }}
                            />
                          </Box>
                        </Fragment>
                      )}
                    </Box>
                  </Fragment>
                )}
              </Box>
            </Paper>
            <ButtonBox
              activeStep={activeStep}
              isLastStep={isLastStep}
              handleBack={handleBack}
              isSubmitting={isSubmitting}
              submitForm={submitForm}
            />{' '}
            <DebugFormik values={values} touched={touched} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};

ChoixCommissaireAuxComptes.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixCommissaireAuxComptes;
