import { Delete } from '@material-ui/icons';
import { TIERS, YES, yesNo } from 'constants/usersContants';
import { FormContext } from 'contexts/partners.context';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { SAS } from 'constants/companies';
import CardButton from '../commons/cardButton';
import PartnerCard from '../commons/partnerCard';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';
import InfoDgPM from './infoDgPM';
import cleanInfoPM from './selectDgHelper';

function SelectDG() {
  const classes = useStyles();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [directeurGenerals, setDirecteurGenerals] = useState([]);
  const {
    values: { presence_dg, dg_type, dgPartnerIDs },
    setFieldValue,
  } = useFormikContext();
  const formulaireComplet = useContext(FormContext);
  const { partners, eurl_sasu, associe_gerant_multiassocie } =
    formulaireComplet;

  const addDG = (idPartner) => {
    if (eurl_sasu === 'sasu' && associe_gerant_multiassocie.length > 0) {
      setShowErrorMessage(true);
      return;
    }
    if (!dgPartnerIDs.includes(idPartner)) {
      setFieldValue('dgPartnerIDs', [...dgPartnerIDs, idPartner]);
      cleanInfoPM(setFieldValue);
    }
  };

  const removeDG = (partnerId) => {
    const newDGs = dgPartnerIDs.filter(
      (dgPartnerID) => dgPartnerID !== partnerId
    );
    setFieldValue('dgPartnerIDs', newDGs);
  };
  const removeAllDG = () => {
    setFieldValue('dgPartnerIDs', []);
  };

  const checkCEOForCompanySAS = (partnerId) => {
    return (
      eurl_sasu === 'sas' && associe_gerant_multiassocie.includes(partnerId)
    );
  };

  const getTitle = () => {
    if (eurl_sasu === 'sas') {
      return `Veuillez sélectionner un associé qui n'est pas déjà nommé président. 
      Si le directeur général est un tiers, veuillez sélectionner - Personne tierce - et remplir les informations demandées`;
    }
    return `Veuillez sélectionner le directeur général parmi le(s) associé(s,
      es), si le directeur général est un tiers veuillez sélectionner –
      Personne tierce – et remplir les informations demandées`;
  };

  const getDirecteurGenerals = () => {
    const newDirecteurGenerals = [];
    partners.forEach((partner) => {
      if (
        eurl_sasu === SAS &&
        dgPartnerIDs.indexOf(partner.id) !== -1 &&
        associe_gerant_multiassocie.includes(partner.id)
      ) {
        removeDG(partner.id);
      } else if (dgPartnerIDs.indexOf(partner.id) !== -1) {
        newDirecteurGenerals.push(partner);
      }
    });
    return newDirecteurGenerals;
  };
  const renderDirecteurGeneral = () => {
    if (!directeurGenerals.length) return null;
    return (
      <div>
        <h2 className={classes.subTitle}>Directeur Général</h2>
        {directeurGenerals.map((partner) => {
          return (
            <div key={partner.id} style={{ display: 'flex', width: '100%' }}>
              <div style={{ flex: 1 }}>
                <PartnerCard
                  partner={partner}
                  canEdit={false}
                  handleClick={() => removeDG(partner.id)}
                />
              </div>
              <div
                style={{
                  flex: 0.2,
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'center',
                  color: 'red',
                }}
              >
                <Delete
                  onClick={() => removeDG(partner.id)}
                  style={{ fontSize: '2.2em', cursor: 'pointer' }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    const newDirecteurGenerals = getDirecteurGenerals();
    setDirecteurGenerals(newDirecteurGenerals);
  }, [associe_gerant_multiassocie, dgPartnerIDs]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Directeur général</h1>
      <div className={classes.root}>
        <SmallRadio
          classes={classes}
          title="Souhaitez-vous nommer dans les statuts un directeur général (DG) ?"
          options={yesNo}
          value={presence_dg}
          name="presence_dg"
          style={{ marginBottom: '10%' }}
        />
        <ErrorMessage name="dgPartnerIDs">
          {(msg) => (
            <div style={{ color: 'red', textAlign: 'left' }}>{msg}</div>
          )}
        </ErrorMessage>

        {YES === presence_dg && (
          <>
            {renderDirecteurGeneral()}
            {showErrorMessage && (
              <div
                style={{ color: 'red' }}
              >{`En SASU l'associé(e) ne peut pas être président et directeur général.`}</div>
            )}
            <h2 className={classes.subTitle}>{getTitle()}</h2>
            {partners.map((partner) => (
              <PartnerCard
                key={partner.id}
                partner={partner}
                handleClick={addDG}
                canEdit={false}
                disabled={checkCEOForCompanySAS(partner.id)}
              />
            ))}
            <hr
              style={{
                width: '100%',
                border: '1px solid #3D319D',
                marginTop: '10%',
              }}
            />
            <CardButton
              handleClick={() => {
                removeAllDG();
                setFieldValue('dg_type', TIERS);
                setShowErrorMessage(false);
              }}
              title="Personne tierce"
            />
            {dg_type === TIERS && <InfoDgPM eurl_sasu={eurl_sasu} />}
          </>
        )}
      </div>
    </div>
  );
}

export default SelectDG;
