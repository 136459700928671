import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import KeyboardDatePicker from 'components/KeyboardDatePicker';

import useStyles from '../style';
import Adresse from '../commons/adress';

function InfoBailleur() {
  const classes = useStyles();
  return (
    <>
      <Field
        component={TextField}
        type="string"
        label="Email du bailleur *"
        name="email_bailleur"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom ou forme de la société *"
        name="prenom_bailleur"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom ou raison sociale de la société *"
        name="nom_bailleur"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Adresse
        title={`Adresse du bailleur`}
        classes={classes}
        address="adresse_bailleur"
        city="bailleur_city"
        zipCode="bailleur_zipcode"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        additionalAddress="additional_address_bailleur"
      />
      <h2 className={classes.subTitle}>Information sur le Bail</h2>
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date du bail *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        name="date_bail"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
    </>
  );
}

export default InfoBailleur;
