import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { RESET_FORM } from 'constants/actions';
import React from 'react';

function ResetDialog({ openAlert, handleCloseAlert, defaultForm, dispatch }) {
  const handleClickSave = () => {
    localStorage.removeItem('persist:root');
    localStorage.removeItem('form');
    localStorage.removeItem('sci-form');
    dispatch({
      type: RESET_FORM,
      payload: defaultForm(),
    });
    window.location.reload();
  };
  return (
    <Dialog
      open={openAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Voulez vous faire une nouvelle saisie ? <br />
          Attention toutes les informations saisies seront effacées.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickSave} variant="outlined" color="danger">
          Oui
        </Button>
        <Button onClick={handleCloseAlert} variant="contained" color="primary">
          Non
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetDialog;
