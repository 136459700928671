import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import listOfKit from 'assets/data/kitMatrix';

// HERE You can change the data you want to test
// import sarlData from 'assets/__data__/eurl_gerant_tiers';
import sarlData from 'assets/__data__/SARL1';
// import sarlData from 'assets/__data__/sarl';

import { getListOfDocs, getListOfDocsByKit } from 'helpers/kitHelpers';

const TestKit = () => {
  const { partners } = sarlData;
  const initData = [];
  const [kit, setKit] = useState('');
  const [data, setData] = useState(initData);
  const [generatedDocsWithNames, setGeneratedDocsWithNames] = useState([]);
  const [uploadByBBFDocsWithNames, setUploadByBBFDocsWithNames] = useState([]);
  const [uploadByClientDocsWithNames, setUploadByClientDocsWithNames] =
    useState([]);

  const setDocuments = () => {
    if (kit !== '') {
      const docsWithNames = getListOfDocsByKit(sarlData, kit);
      setGeneratedDocsWithNames(
        docsWithNames.filter((doc) => doc.source === null)
      );
      setUploadByBBFDocsWithNames(
        docsWithNames.filter((doc) => doc.source === 'BBF')
      );
      setUploadByClientDocsWithNames(
        docsWithNames.filter((doc) => doc.source === 'CLIENT')
      );
    }
  };

  const setDocumentsAuto = () => {
    const docsWithNames = getListOfDocs(sarlData);

    setGeneratedDocsWithNames(
      docsWithNames.filter((doc) => doc.source === null)
    );
    setUploadByBBFDocsWithNames(
      docsWithNames.filter((doc) => doc.source === 'BBF')
    );
    setUploadByClientDocsWithNames(
      docsWithNames.filter((doc) => doc.source === 'CLIENT')
    );
  };

  const onChangeAssociate = (event) => {
    const { name, value, id } = event.target;
    const d = { ...data };
    if (name === 'isGerant') {
      d[id][name] = !d[id][name];
    } else {
      d[id][name] = value;
    }
    setData(d);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setKit(value);
  };

  useEffect(() => {
    setDocuments();
  }, [kit]);

  return (
    <div style={{ padding: '5% 0' }}>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Kit</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={kit}
          label="Kit"
          onChange={handleChange}
        >
          {listOfKit.map((k) => (
            <MenuItem value={k.name} key={k.kit} name={k.kit}>
              {k.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ display: 'flex', marginTop: '2%' }}>
        <div style={{ flex: 1, marginRight: '1%' }}>
          {data.map((associate, idx) => (
            <div key={idx}>
              <TextField
                label={`associate ${idx + 1}`}
                variant="outlined"
                value={associate.partner_name}
                id={idx}
                name="partner_name"
                onChange={onChangeAssociate}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={associate.isGerant}
                      id={idx}
                      name="isGerant"
                      onChange={onChangeAssociate}
                    />
                  }
                  label="isGerant"
                />
              </FormGroup>
            </div>
          ))}
          <Button
            variant="contained"
            onClick={() => {
              setDocumentsAuto();
              const d = partners;
              setData(d);
            }}
          >
            Use SARL data from Xavier app
          </Button>
        </div>
        <div style={{ flex: 2, marginleft: '1%' }}>
          {generatedDocsWithNames.length > 0 && (
            <Paper elevation={3} style={{ marginTop: '2em', padding: '5%' }}>
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                style={{ color: '#3D319D' }}
              >
                DOCUMENTS GENERES
              </Typography>
              {generatedDocsWithNames.map((doc, idx) => {
                if (doc.title.includes('%s')) {
                  return null;
                }
                return (
                  <div key={idx}>
                    {doc.title}
                    {doc.recipients.map((recipients, idx2) => (
                      <span
                        key={idx2}
                        style={{
                          color: '#3D319D',
                          fontWeight: 'bold',
                          float: 'right',
                        }}
                      >
                        {`- ${recipients.partner_name}`}
                      </span>
                    ))}
                  </div>
                );
              })}
            </Paper>
          )}
          {uploadByClientDocsWithNames.length > 0 && (
            <Paper elevation={3} style={{ marginTop: '2em', padding: '5%' }}>
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                style={{ color: '#3D319D' }}
              >
                DOCUMENTS provide by CLIENT
              </Typography>
              {uploadByClientDocsWithNames.map((doc, idx) => {
                if (doc.title.includes('%s')) {
                  return null;
                }
                return (
                  <div key={idx}>
                    {doc.title}
                    {doc.recipients.map((recipients, idx2) => (
                      <span
                        key={idx2}
                        style={{
                          color: '#3D319D',
                          fontWeight: 'bold',
                          float: 'right',
                        }}
                      >
                        {`- ${recipients.partner_name} `}
                      </span>
                    ))}
                  </div>
                );
              })}
            </Paper>
          )}
          {uploadByBBFDocsWithNames.length > 0 && (
            <Paper elevation={3} style={{ marginTop: '2em', padding: '5%' }}>
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                style={{ color: '#3D319D' }}
              >
                DOCUMENTS provide by BBF
              </Typography>
              {uploadByBBFDocsWithNames.map((doc, idx) => (
                <div key={idx}>
                  {doc.title}
                  {doc.recipients.map((recipients, idx2) => (
                    <span
                      key={idx2}
                      style={{
                        color: '#3D319D',
                        fontWeight: 'bold',
                        float: 'right',
                      }}
                    >
                      {`- ${recipients.partner_name} `}
                    </span>
                  ))}
                </div>
              ))}
            </Paper>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestKit;
