import { FormControlLabel, Radio } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';

function RadioWithIcon({ icon, title, option, classes, value, name }) {
  const { touched, errors } = useFormikContext();
  return (
    <FormControlLabel
      // className={classes.radioLabel}
      className={`${
        value === option
          ? `${classes.radioLabel} ${classes.selected}`
          : classes.radioLabel
      }
          ${touched[name] && errors[name] ? classes.radioError : ''}`}
      value={option}
      control={<Radio className={classes.radio} />}
      label={
        <>
          {icon}
          {title}
        </>
      }
    />
  );
}

export default RadioWithIcon;
