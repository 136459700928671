import React, { useState } from 'react';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { juridicForms } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import SearchIcon from '@material-ui/icons/Search';
import { fetchCompanyDataFromGreffe, fetchRCS } from 'helpers/rcsHelper';
import Adresse from '../commons/adress';
import SmallRadio from '../commons/smallRadio';
import CompanyList from '../commons/companyList';

function InfoMoralPerson({ classes, values }) {
  const {
    setFieldValue,
    setFieldError,
    values: { raison_sociale_actionnaire_personne_morale_sasu: companyName },
  } = useFormikContext();
  const { forme_juridique_personne_morale } = values;
  const [companyList, setCompanyList] = useState(null);
  const [loadingCompany, setLoadingCompany] = useState(false);

  const handleFetchCompanyInfo = async () => {
    if (companyName) {
      setLoadingCompany(true);
      const data = await fetchCompanyDataFromGreffe(companyName);
      if (!data.length && companyName.includes(' ')) {
        setFieldError(
          'raison_sociale_actionnaire_personne_morale_sasu',
          'Si votre raison sociale contient des caractères spéciaux (@,&,! etc), il vous faut réaliser votre recherche RCS avec votre N° SIREN en y retirant les espaces.'
        );
        setCompanyList(null);
      } else {
        setFieldError(
          'raison_sociale_actionnaire_personne_morale_sasu',
          'Veuillez saisir un numéro SIREN ou une raison sociale valide'
        );
        setCompanyList(null);
      }
      if (data && data.length) {
        setFieldError('raison_sociale_actionnaire_personne_morale_sasu', null);
        setCompanyList(data);
      }
    } else {
      setFieldError('raison_sociale_actionnaire_personne_morale_sasu', null);
      setCompanyList(null);
    }
    setLoadingCompany(false);
  };

  const handleSelectCompany = async (company) => {
    const { code_postal, ville } = company;
    const resRCS = await fetchRCS(code_postal, ville);

    setFieldValue(
      'raison_sociale_actionnaire_personne_morale_sasu',
      company.denomination
    );
    setFieldValue(
      'code_postal_actionnaire_personne_morale_sasu',
      company.code_postal
    );
    setFieldValue('adresse_actionnaire_personne_morale_sasu', company.adresse);
    setFieldValue('ville_actionnaire_personne_morale_sasu', company.ville);

    if (resRCS) {
      setFieldValue('rcs_actionnaire_personne_morale_sasu', company.siren);
      setFieldValue('ville_rcs_actionnaire_personne_morale_sasu', resRCS);
    }

    setCompanyList(null);
  };

  return (
    <>
      <h2 className={classes.subTitle}>Informations sur la personne morale.</h2>
      <SmallRadio
        classes={classes}
        title="Forme juridique"
        options={juridicForms}
        value={forme_juridique_personne_morale}
        name="forme_juridique_personne_morale"
        style={{ marginBottom: '2%' }}
      />
      <h3 className={classes.helpUserInfo} style={{ marginBottom: '1%' }}>
        Saisissez les 9 chiffres de votre numero siren ou la raison sociale puis
        cliquer sur la loupe pour sélectionner la société.
      </h3>
      <Field
        component={TextField}
        type="string"
        label="Numéro siren (9 chiffres) / raison sociale *"
        name="raison_sociale_actionnaire_personne_morale_sasu"
        className={classes.nameTextField}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loadingCompany ? (
                <CircularProgress size="1.5rem" />
              ) : (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleFetchCompanyInfo}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        variant="outlined"
        size="small"
      />
      {companyList && (
        <CompanyList
          companyList={companyList}
          handleSelectCompany={handleSelectCompany}
        />
      )}
      <Field
        component={TextField}
        // type="number"
        inputmode="numeric"
        label="Capital *"
        name="capital_personne_morale"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        title="Adresse de la personne morale *"
        address="adresse_actionnaire_personne_morale_sasu"
        city="ville_actionnaire_personne_morale_sasu"
        zipCode="code_postal_actionnaire_personne_morale_sasu"
        disabled={true}
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        additionalAddress="additional_address_actionnaire_personne_morale_sasu"
      />
      <h2 className={classes.subTitle}>Représentée par</h2>
    </>
  );
}

export default InfoMoralPerson;
