import React, { useEffect, useContext } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import BusinessIcon from '@material-ui/icons/Business';
import DoneIcon from '@material-ui/icons/Done';

import PersonIcon from '@material-ui/icons/Person';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
// import service from 'services/createCompanyService';

// import {
//   EDIT_COMPANY,
//   CREATE_PROJECT_STATUS_FAILED,
//   EDIT_COMPANY_PROCESSING,
// } from 'constants/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  createForm,
  createStatutsProject,
  singUp,
} from 'actions/monCompteActions';
import { setCompanyData } from 'reducers/monCompteReducer';
import _ from 'lodash';
import { FormContext } from 'contexts/sci-partners.context';
import SnackBar from 'components/commons/SnackBar';
import ButtonBox from './ButtonBox';
import theme from '../../assets/jss/theme';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  group: {
    flexWrap: 'nowrap',
    width: '100%',
  },
  /* table style pour le tableau */
  table: {
    minWidth: 650,
  },
}));

const ListeFin = ({
  // partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  handleReset,
  // automateState,
  setAutomateState,
  // partnerIndex,
  setPartnerIndex,
  // isRecap,
  setIsRecap,
  checkInfosBase,
  setCheckInfosBase,
  checkInfosPartners,
  setCheckInfosPartners,
  checkInfoFinancieres,
  setCheckInfoFinancieres,
  checkInfosAdministratives,
  setCheckInfosAdministratives,
}) => {
  const classes = useStyles();

  const { partners } = formValues;
  // const dispatch = useContext(DispatchContext);
  // const { loading, error } = useContext(FormContext);

  const { error } = useContext(FormContext);
  const { company, loading } = useSelector((state) => state.monCompte);
  const dispatch = useDispatch();
  const history = useHistory();

  setIsRecap(true);
  useEffect(() => {
    setActiveStep(4);
  }, []);

  useEffect(() => {
    if (_.isEmpty(company)) {
      const values = JSON.parse(localStorage.getItem('sci-form'));
      dispatch(
        setCompanyData({
          ...values,
          companyId: values.companyId,
          alternativeId: values.alternativeId,
        })
      );
    }
  }, [company]);

  const {
    companyId: exsistingId,
    alternativeId: exsistingAlternatId,
    creaformOID: exsistingCreaformOID,
  } = company;

  const signUpAndPay = async () => {
    let companyId = null;
    let alternativeId = null;
    // SING UP
    if (!exsistingId && !exsistingAlternatId) {
      const result = await dispatch(singUp(company));
      companyId = result && result.companyId;
      alternativeId = result && result.alternativeId;
    } else {
      companyId = exsistingId;
      alternativeId = exsistingAlternatId;
    }

    // GENERATION DE DOC
    const res = await dispatch(createStatutsProject(formValues));
    const { documents } = res.data;

    // SAUVEGARDE DES DONNEE EN BASE
    if (companyId && alternativeId && documents) {
      const newCompany = { ...company, documents };
      if (!exsistingCreaformOID) {
        await dispatch(createForm(newCompany, companyId, alternativeId));
        history.push('/kit');
      }
    }
  };

  // const createProjectStatus = async () => {
  //   try {
  //     dispatch({
  //       type: EDIT_COMPANY_PROCESSING,
  //     });
  //     const res = await service.createStatus(formValues);
  //     const { documents } = res.data;
  //     dispatch({
  //       type: EDIT_COMPANY,
  //       payload: { ...formValues, documents },
  //     });
  //     history.push('/kit');
  //   } catch (err) {
  //     dispatch({
  //       type: CREATE_PROJECT_STATUS_FAILED,
  //     });
  //   }
  // };

  return (
    <div>
      <Paper>
        <Box
          display="flex"
          p={1}
          bgcolor="background.paper"
          className={classes.root}
        >
          <Typography variant="subtitle1">
            Veuillez vérifier les informations concernant{' '}
          </Typography>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <BusinessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Les informations de base " />
              <ListItemIcon>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => {
                    setCheckInfosBase(true);
                    SciAutomate.retourInfoSociete();
                    setAutomateState(SciAutomate.state);
                  }}
                >
                  <EditIcon />
                </IconButton>
                {checkInfosBase && <DoneIcon style={{ fill: 'green' }} />}
              </ListItemIcon>
            </ListItem>
          </List>

          <List>
            {partners.map((partnerr, id) => (
              <ListItem key={id}>
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                {partnerr.raison_sociale_actionnaire_personne_morale_sasu && (
                  <ListItemText
                    primary={`  La ${partnerr.forme_juridique_personne_morale} ${partnerr.raison_sociale_actionnaire_personne_morale_sasu}`}
                  />
                )}

                {!partnerr.raison_sociale_actionnaire_personne_morale_sasu && (
                  <ListItemText
                    primary={`  ${partnerr.partner_title} ${partnerr.partner_firstname} ${partnerr.partner_name}`}
                  />
                )}
                <ListItemIcon>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => {
                      setPartnerIndex(id);
                      if (
                        partnerr.raison_sociale_actionnaire_personne_morale_sasu
                      ) {
                        setCheckInfosPartners(checkInfosPartners + 1);
                        SciAutomate.retourInfoPersonneMorale();
                        setAutomateState(SciAutomate.state);
                      } else {
                        setCheckInfosPartners(checkInfosPartners + 1);
                        SciAutomate.retourInfoPartenaire();
                        setAutomateState(SciAutomate.state);
                      }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  {checkInfosPartners >= partners.length && (
                    <DoneIcon style={{ fill: 'green' }} />
                  )}
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <BusinessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Les informations financières" />
              <ListItemIcon>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => {
                    setCheckInfoFinancieres(true);
                    SciAutomate.retourInfoCapitalDeSociete();
                    setAutomateState(SciAutomate.state);
                  }}
                >
                  <EditIcon />
                </IconButton>
                {checkInfoFinancieres && <DoneIcon style={{ fill: 'green' }} />}
              </ListItemIcon>
            </ListItem>
          </List>

          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <BusinessIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Les informations administratives" />
              <ListItemIcon>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => {
                    setCheckInfosAdministratives(true);
                    SciAutomate.retourChoixDuGerant();
                    setAutomateState(SciAutomate.state);
                  }}
                >
                  <EditIcon />
                </IconButton>
                {checkInfosAdministratives && (
                  <DoneIcon style={{ fill: 'green' }} />
                )}
              </ListItemIcon>
            </ListItem>
          </List>
        </Box>
      </Paper>
      <ButtonBox
        activeStep={activeStep}
        isLastStep={isLastStep}
        handleBack={handleBack}
        handleReset={handleReset}
        isSubmitting={loading}
        submitForm={signUpAndPay}
      />
      <SnackBar show={error} message={error} />
    </div>
  );
};

ListeFin.propTypes = {
  nextForm: PropTypes.func,
};
export default ListeFin;
