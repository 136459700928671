import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

import * as Yup from 'yup';

import { RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '../surface/Paper';

import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles({
  root: {
    justifyContent: 'space-around',
    flexWrap: 'no-wrap',
    flexdirection: 'row',
  },
  title: {
    justifyContent: 'center',
  },
  radio: {
    flexdirection: 'row',
  },
});

const SignupSchema = Yup.object().shape({
  nom_propre: Yup.string().required('Choix obligatoire'),
});
const ChoixNomPropreOuSociete = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(0);
  }, []);
  return (
    <Formik
      initialValues={formValues}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        if (values.nom_propre === 'nom_propre') {
          Automate.nomPropreVersTypeSociete();
          setAutomateState(Automate.state);
        }

        Automate.next();
        setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              size="fullwidth"
              className={classes.title}
            >
              <Typography variant="h6">Je crée mon entreprise</Typography>
            </Box>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              size="fullwidth"
              className={classes.root}
            >
              <Field
                component={RadioGroup}
                name="nom_propre"
                className={classes.radio}
              >
                <FormControlLabel
                  value="nom_propre"
                  control={<Radio />}
                  label="En nom propre"
                />

                <FormControlLabel
                  value="societe"
                  control={<Radio />}
                  label="En société"
                />
              </Field>
            </Box>
          </Paper>

          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixNomPropreOuSociete.propTypes = {
  nextForm: PropTypes.func,
  handleNext: PropTypes.func,
};
export default ChoixNomPropreOuSociete;
