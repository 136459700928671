/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Box, FormControlLabel, Radio, Typography } from '@material-ui/core';

import { RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const SignupSchema = Yup.object().shape({
  pacs_type: Yup.string().required('un choix est necessaire'),
});

const ChoixTypeDePacs = ({
  partner,
  // formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_PARTNER,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        // if (formValues.alone === 'a_plusieurs') {
        // SciAutomate.demandeInfoApportsPartenaire();
        // setAutomateState(SciAutomate.state);

        SciAutomate.nextChoixTypePacsVersInfoApportsPartenaire();
        setAutomateState(SciAutomate.state);

        setActiveStep(activeStep + 1);

        // return;
        // }

        // setActiveStep(activeStep + 1);
        // SciAutomate.next();
        // setAutomateState(SciAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                {' '}
                Pacsé(e) sous quel régime ?{' '}
              </Typography>

              <Field
                component={RadioGroup}
                defaultValue="de_la_separation_des_patrimoines"
                // name={`partners[${partnerIndex}].pacs_type`}
                name="pacs_type"
              >
                <FormControlLabel
                  value="de_la_separation_des_patrimoines"
                  control={<Radio />}
                  label="De la séparation des patrimoines"
                />
                <FormControlLabel
                  value="de_l'indivision"
                  control={<Radio />}
                  label="De l'indivision"
                />
              </Field>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixTypeDePacs.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixTypeDePacs;
