import { BBF_NOTARIE, MULTI_PARTNER, NO, YES } from 'constants/usersContants';

const initialValues = (values) => {
  const { alone, partners } = values;
  let apportsNumeraire = 0;
  let apportsNature = 0;
  let capitalChiffres = 0;
  // eslint-disable-next-line
  let depot_capital_chez = values.depot_capital_chez || '';
  let liberationPartielle = values.liberationPartielle || NO;
  let connait_depot_des_fonds = values.connait_depot_des_fonds || '';

  if (alone === MULTI_PARTNER) {
    partners.forEach((partner) => {
      apportsNumeraire =
        Math.abs(apportsNumeraire) +
        Math.abs(partner.partner_cash_funds_numbers);
      apportsNature =
        Math.abs(apportsNature) +
        Math.abs(partner.partner_supplies_funds_numbers);
    });
    capitalChiffres = apportsNumeraire + apportsNature;
  } else {
    capitalChiffres = values.cash_funds_numbers + values.supplies_funds_numbers;
  }
  if (values.kit === 'kit4soc' || values.kit === 'kit4sci') {
    depot_capital_chez = BBF_NOTARIE;
    connait_depot_des_fonds = YES;
    liberationPartielle = YES;
  }

  return {
    capitalChiffres,
    depot_capital_chez,
    share_value: values.share_value || 1,
    connait_depot_des_fonds,
    banking_corporation_name: values.banking_corporation_name || '',
    adresse_agence_bancaire: values.adresse_agence_bancaire || '',
    ville_agence_bancaire: values.ville_agence_bancaire || '',
    code_postal_agence_bancaire: values.code_postal_agence_bancaire || '',
    eurl_sasu: values.eurl_sasu,
    choix_is: values.choix_is || null,
    liberationPartielle,
    pourcentOfRelease: values.pourcentOfRelease || 0,
    partners: values.partners,
    partner_cash_funds_numbers: values.partner_cash_funds_numbers || 0,
    partner_montant_liberation_partielle:
      values.partner_montant_liberation_partielle || 0,
    cash_funds_onetime_payment: values.cash_funds_onetime_payment || YES,
    cash_funds_numbers: values.cash_funds_numbers,
    payments_amount: values.payments_amount || 0,
    kit: values.kit,
    alone: values.alone,
  };
};

// eslint-disable-next-line
export { initialValues };
