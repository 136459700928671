import React, { Fragment, useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Box, Typography, FormControlLabel, Radio } from '@material-ui/core';

import { TextField, RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
import KeyboardDatePicker from 'components/KeyboardDatePicker';

// import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import Paper from '../surface/Paper';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '80ch',
  },
  radio: {},
}));

// const SignupSchema = Yup.object().shape({

//   siege_social_chez: Yup.string().required('un choix est necessaire'),
//   proprietaire: Yup.string().required('un choix est necessaire'),
//   maison_individuelle: Yup.string().required('un choix est necessaire'),

//   prenom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   nom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   adresse_syndic: Yup.string().required('Required'),
//   prenom_bailleur: Yup.string().required('Required'),
//   nom_bailleur: Yup.string().required('Required'),
//   adresse_bailleur: Yup.string().required('Required'),
//   date_bail: Yup.date().required('Required'),
// });
// const { values, setFormValue } = useFormikContext();

const DureeAssociation = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  {' '}
                  Durée de l&apos;association{' '}
                </Typography>
              </Box>

              <Box className={classes.root}>
                <Field component={RadioGroup} name="duree_limitee">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      La durée est-elle limitée :
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>

              {values.duree_limitee === 'oui' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Field
                      component={RadioGroup}
                      name="duree_limitee_par_evenement"
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          {' '}
                          La durée est-elle limitée à la réalisation d&apos;un
                          événement :
                        </Typography>{' '}
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="non"
                          control={<Radio />}
                          label="Non"
                        />
                      </Box>
                    </Field>
                  </Box>

                  {values.duree_limitee_par_evenement === 'oui' && (
                    <Box alignSelf="center">
                      <Field
                        component={TextField}
                        type="string"
                        label="Nature de l'evenement"
                        name="evenement_limite"
                        className={classes.nameTextField}
                        autoComplete="off"
                      />
                    </Box>
                  )}
                  <Box className={classes.root}>
                    <Field component={RadioGroup} name="duree_limitee_par_date">
                      <Box>
                        <Typography variant="subtitle1">
                          {' '}
                          La durée est-elle limitée dans le temps :
                        </Typography>{' '}
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="non"
                          control={<Radio />}
                          label="Non"
                        />
                      </Box>
                    </Field>
                  </Box>
                  {values.duree_limitee_par_date === 'oui' && (
                    <Box className={classes.root}>
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date limite de l'association"
                        placeholder="01/01/1990"
                        className={classes.birthdayField}
                        format="dd/MM/yyyy"
                        name="date_limite"
                        autoComplete="off"
                      />
                    </Box>
                  )}
                </Fragment>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

DureeAssociation.propTypes = {
  nextForm: PropTypes.func,
};
export default DureeAssociation;
