export const TYPE_OF_COMPANY = 'TYPE_OF_COMPANY';
export const BASIC_INFO_OF_COMPANY = 'BASIC_INFO_OF_COMPANY';
export const COMPANY_DATE = 'COMPANY_DATE';
export const USER_INFO = 'USER_INFO';
export const HEAD_OFFICE_INFO = 'HEAD_OFFICE_INFO';
export const SELECT_CEO = 'SELECT_CEO';
export const SELECT_DG = 'SELECT_DG';
export const PARTNER_CONTRIBUTION = 'PARTNER_CONTRIBUTION';
export const COMPANY_CONTRIBUTION = 'COMPANY_CONTRIBUTION';
export const RELEASE_OF_CASH_BY_PARTNER = 'RELEASE_OF_CASH_BY_PARTNER';
export const PARTNER_ADD_AND_REVIEW = 'PARTNER_ADD_AND_REVIEW';
export const CAPITAL_AND_FOUND_DEPOSIT = 'CAPITAL_AND_FOUND_DEPOSIT';
export const LEGAL_ACTS = 'LEGAL_ACTS';
export const CAC = 'CAC';
export const SELECT_REGIME_VAT = 'SELECT_REGIME_VAT';
export const RECAP = 'RECAP';
