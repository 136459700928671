// eslint-disable-next-line
export const helpContents = [
  // EURL
  {
    name: ['company_name-eurl'],
    content: `
      Toute société doit avoir une dénomination sociale. 
        Celle-ci est fixée dans les statuts. 
        Il s’agit du nom de la société qui figurera sur son kbis et sur tous les documents 
        qu’elle sera amenée à émettre directement après la forme juridique. 
        La dénomination sociale permet d’identifier et d’individualiser la société en tant que personne 
        morale et de la distinguer des autres entités existantes.
      <br><br>
      Attention la dénomination sociale est différente :
      <br>
      <ul>
        <li>
          du nom commercial qui correspond au nom que l’on donne au fonds de commerce de la société, 
            et avec lequel la communication est réalisée ;
        </li>
        <br>
        <li>
          du sigle qui correspond à l’acronyme ou à un élément distinctif permettant d’identifier la société.
        </li>
      </ul>       
      <h4>Choisir la dénomination</h4>
      La dénomination sociale d’une société doit être licite, et est librement déterminée à la discrétion du/des associé·s. Attention : certaines activités reglementées nécessite que la dénomination sociale soit validée par les organismes de surveillance ou de régulation de ces professions. La seule vraie contrainte est de s’assurer que la dénomination sociale est disponible et qu’elle ne fait pas l’objet d’une protection juridique auprès de l’INPI.
      <h4>Modifier la dénomination sociale d’une société</h4>
      La dénomination sociale initialement choisie lors de la constitution peut être complétée, 
        modifiée ou changée en cours de vie sociale. 
        La procédure d’approbation de cette modification est fixée par les statuts. 
        Ensuite d’un point de vue administratif il s’agira d’une procédure de modification statutaire à 
        réaliser auprès du guichet unique de l'INPI.
    `,
  },
  {
    name: ['code_ape-eurl'],
    content: `
    L’objet social est la nature de l’activité que de la société va exercer.
    L’objet social tel qu’il figure dans les statuts d’une société est
    essentiel car : <br />
    <br />
    &nbsp; &nbsp; • il conditionne la conformité des activités exercées
    par la société, et engage laresponsabilité du gérant en cas de non
    respect du champ d’activités ; <br />
    &nbsp; &nbsp; • détermine le code APE attribué par l’INSEE à de la société
    ;<br />
    &nbsp; &nbsp; • détermine la convention collective appliquée en
    matière sociale ;
    <br />
    &nbsp; &nbsp; • son extinction ou la non réalisation de l’objet
    social est une cause de dissolution de la société.
    <h4>Choisir l’objet social d’une société</h4>
    Le choix de l’objet social d’une société est fait par son associé
    unique, et est libre, tant qu’il est licite, et qu’il peut être
    réalisé. L’objet social est doit être décrit précisément dans les
    statuts lors de la constitution de la société. En général, au-delà de
    l’activité principale dite, coeur de métier, des précisions sur les
    activités connexes sont mentionnées dans les statuts. Cela permet de
    prévoir la réalisation de toutes les opérations qui se rattachent
    directement ou indirectement à ou aux activités principales.
    <b>
      Attention: certaines activités ne peuvent être exercées en société.
      C’est le cas notamment des activités d’assurance, d’épargne et de
      prévoyance, de débit de tabac ou de perception et de répartition
      de droits d’auteurs.
    </b>
    <h4>Déclarer l’objet social d’une société au RCS</h4>
    L’objet social de la société figure dans les statuts, il est mentionné
    sur le MO lors de l’immatriculation, et figurera sur le kbis de la
    société.
    <h4>Modifier l’objet social d’une société</h4>
    L’associé unique peut, à tout moment, modifier l’objet social de
    de la société. Il s’agira d’une modification statutaire à effectuer en
    respectant les règles qui y sont relatives et de procéder aux
    chnagements auprès du greffe du tribunal de commerce. Material-UI:`,
  },
  {
    name: ['siege_social_chez-eurl'],
    content: `
      Toute société est tenue d’avoir un siège social qui sera son adresse administrative et qui sera ou pas l’adresse 
        d’exercice de son activité économique. La société peut être propriétaire, locataire (titulaire d’un bail commercial, 
        d’un bail de courte durée ou occupante à titre gratuit. La domiciliation chez le Président est possible ainsi 
        qu’auprès d’une entreprise de domiciliation ou dans une pépinière ou ruche d’entreprises. 
        Une société ne peut fixer son siège social que dans un local qu’elle utilise. 
        Le siège social d’une société est unique. 
        Les adresses des établissements secondaires constituent des lieux d’exercice professionnel. 
        Le siège social figure dans les statuts , il sera mentionné sur le Kbis et figurera sur tous les documents légaux 
        émis par la société.

      <h4>Domicilier le siège social de la société au domicile du Président</h4>
      
      La domiciliation chez le représentant légal de la société est autorisée en société. 
        Celle-ci ne peut cependant se faire qu’au domicile du Président. 
        Attention : dans certains cas, notamment lorsque le Président est locataire, des dispositions du contrat de location 
        peuvent limiter dans le temps voire même interdire la domiciliation. Lorsque la domiciliation est temporaire, 
        elle ne peut excéder une durée de cinq ans.
      
      <h4>Domicilier le siège social de la société chez une entreprise de domiciliation</h4>
      
      La société peut recourir aux services d’une entreprise de domiciliation disposant d’un agrément pour y établir son siège social. 
        Un contrat de domiciliation sera signé et prévoira les conditions et les prestations de location.
      
      <h4>Domicilier le siège social de la société au sein d’une pépinière d’entreprises – ruches – incubateurs…</h4>
      
      Les société peuvent développer leur activité au sein de structures qui mettent à leur disposition un éco-système 
        et de nombreuses infrastructures (secrétariat, accueil, salle de réunion, bureaux…). 
        Elles bénéficient alors de toutes les facilités pour se développer en se concentrant sur leur business. 
        Cette forme de domiciliation n’est autorisée que de façon temporaire, généralement le temps de lancer l’activité.
      
      <h4>Changement de siège social d’une société</h4>
      
      À tout moment, une société peut être amenée à transférer son siège social. 
        Cette opération nécessite de suivre la procédure prévue dans les statuts. 
        Ensuite une procédure administrative de modification statutaire devra être réalisée auprès du guichet unique de l'INPI.
    `,
  },
  {
    name: ['partner-eurl'],
    content: `
    Bien qu’il existe certaines règles, toute personne peut devenir
          associé unique d’une entreprise unipersonnelle à responsabilité
          limitée (société).
          <h4>
            L’associé unique d’une société peut être une personne physique ou
            morale
          </h4>
          Toute personne au sens juridique du terme peut avoir la qualité
          d’associé unique de la société. Il peut donc s’agir aussi bien d’une personne
          physique, que d’une personne morale, qu’il s’agisse d’une autre
          société ou d’une association.
          <h4>
            L’associé unique de la société peut avoir la nationalité française ou
            étrangère
          </h4>
          Tout personne de nationalité française ou étrangère peut devenir
          associée d’une société. Pour les personnes ressortissantes d’un pays
          membre de l’Union Européenne, elles peuvent être associées d’une société
          comme toute personne de nationalité française.
          <h4>
            Une personne peut être associée unique de plusieurs sociétés différentes
          </h4>
          Les personnes physiques comme les personnes morales peuvent être
          associées d’une ou de plusieurs sociétés sans limitation. Cela permet
          souvent de cloisonner des activités dans des entités juridiques
          spécifiques et autonomes.
          <h4>
            Aucune incapacité particulière n’interdit de devenir associé d’une
            société
          </h4>
          Aucune incompatibilité ou interdiction n’interdit d’être associé
          unique d’une société, dès lors que l’associé n’est pas le gérant. Dans ce
          cadre, peuvent donc être associés uniques de la société : <br />
          &nbsp;&nbsp;• les majeurs capables ou incapables (sous curatelle,
          tutelle ou sauvegarde de justice) ; <br />
          &nbsp;&nbsp;• les mineurs émancipés (assimilés à des majeurs) ; <br />
          &nbsp;&nbsp;• les mineurs non émancipés dès lors qu’ils agissent au
          travers d’un représentant légal, et avec des autorisations préalables
          pour la souscription de contrats.`,
  },
  {
    name: ['wedding-eurl'],
    content: `
    Le conjoint de l’associé, qui a acquis des parts sociales en utilisant
    des biens de la communauté, peut revendiquer la qualité d’associé de
    de la société et ce pour la moitié des parts qui ont été souscrites. Pour
    exercer ce droit, le conjoint doit être informé de l’opération
    préalablement sous peine de nullité de l’apport. Au regard du droit de
    revendication, trois situations sont possibles : <br /> <br />
    1. si le conjoint renonce, par écrit, à revendiquer la qualité
    d’associé au sein de la société, cette renonciation sera définitive ;
    <br />
    <br />
    2. si le conjoint revendique tout de suite la qualité d’associé, les
    autres associés peuvent accepter les deux ou les refuser ;<br />
    <br />
    3. si le conjoint ne se prononce pas, il pourra quand même revendiquer
    la qualité d’associé ultérieurement. <br />
    <br />
    Les statuts peuvent prévoir une clause d’agrément en fonction de leur
    rédaction. Dans ce cas le conjoint déjà associé ne participe pas au
    vote. Si un associé souhaite effectuer un apport en nature de parts
    sociales, d’un bien immobilier ou d’un fonds de commerce faisant
    partie de la communauté, le consentement des deux époux est nécessaire
    sous peine de nullité de l’opération d’apport.`,
  },
  {
    name: ['gerant-eurl'],
    content: `
    <h4>Conditions de nomination du gérant d’une société</h4>
          Le gérant d’une société est nommé par l’associé unique de la société.
          L’associé unique peut donc s’attribuer les fonctions de gérant ou bien
          nommer une personne étrangère à la société. Lorsque l’associé unique
          est une personne morale, elle doit désigner une personne physique qui
          va la représenter, et exercer les fonctions de gérant en son nom. La
          nomination du gérant d’une société doit obligatoirement avoir lieu lors
          de la constitution de la société. Le gérant peut être nommé dans les
          statuts ou par décision de l’associé unique de la société. Nommer le
          gérant dans les statuts conduit à devoir modifier les statuts en cas
          de changement de gérant, ce qui est plus contraignant que par décision
          extra-statutaire.
          <h4>Nomination du premier gérant lors de la création de la société</h4>
          Nomination du premier gérant lors de la création de la société : <br />
          &nbsp;&nbsp;• directement dans les statuts de la société. Le gérant doit
          alors signer les statuts, et accepter ses fonctions au moyen de la
          mention suivante « bon pour acceptation des fonctions de gérant ». En
          cas de changement de gérant, il faudra modifier les statuts ; <br />
          <br />
          &nbsp;&nbsp;• dans un acte séparé par lequel l’associé unique désigne
          l’identité du gérant. Un procès-verbal de décisions de l’associé
          unique sera établi dans un registre spécial. Un simple procés-verbal
          de décision de l’associé unique permettra de changer de gérant en
          cours de vie sociale.
          <h4>Changement de gérant en cours de vie sociale</h4>
          En cours de vie sociale, le gérant peut être remplacé par une autre
          personne, au choix de l’associé unique, sous réserve toutefois de
          respecter les règles statutaires en vigueur. La procédure à suivre
          dépendra donc de la méthode de nomination du premier gérant de la société
          : <br />
          &nbsp;&nbsp;• s’il a été nommé dans les statuts : la démarche prévue
          pour les modifications de statuts de la société doit être suivie ; <br />
          &nbsp;&nbsp;• s’il a été nommé dans un acte séparé : l’associé unique
          doit prendre une décision actant le changement de gérant, et établir
          le procés-verbal de décision de l’associé unique. <br />
          <br />
          Dès lors qu’un changement de mandataire social est opéré, l’associé
          unique doit respecter les formalités administratives et juridiques
          tant à l’égard des statuts qu’auprès du greffe du tribunal de
          commerce.
    `,
  },
  {
    name: ['contribution-eurl'],
    content: `
    L’associé unique d’une société est autorisé à effectuer trois formes
    d’apport au capital de sa société : <br />
    <br />
    &nbsp;&nbsp;• des apports en numéraire ;<br />
    &nbsp;&nbsp;• des apports en nature ;<br />
    &nbsp;&nbsp;• des apports en industrie.
    `,
  },
  {
    name: ['numeraire-eurl'],
    content: `
    <h4> Caractéristiques d’un apport en numéraire</h4>
          L’apport en numéraire représente une somme d’argent que l’associé
          unique dépose lors de la constitution, sur le compte bancaire de son
          société. Ces fonds contribueront à former le capital de la société, et
          ils donneront droit, en échange, à l’attribution de parts sociales.
          <h4>
            Dépôt des fonds représentatifs des apports en numéraire en société
          </h4>
          Dans les 8 jours de leur réception, et, avant la signature des
          statuts, les sommes doivent être déposées pour le compte de la société
          en formation : <br />
          &nbsp;&nbsp;• soit auprès d’une banque, sur un compte de la société en
          formation ;
          <br />
          &nbsp;&nbsp;• soit à la Caisse des dépôts et consignations ;<br />
          &nbsp;&nbsp;• soit chez un notaire.
          <br /> <br />
          La mention des conditions du dépôt des fonds doit figurer dans les
          statuts.
          <h4>Retrait des fonds apportés en numéraire</h4>
          Les fonds sont indisponibles jusqu’à l’immatriculation de la société au
          RCS. Ils sont alors libérés sur présentation par le gérant de
          l’extrait K-bis, auprès du dépositaire (banque, notaire, CDC). Si
          de la société n’est pas constituée dans un délai de 6 mois, à compter du
          dépôt des fonds, l’apporteur associé unique peut demander en justice
          l’autorisation de retirer le montant de ses apports ou choisir un
          mandataire, qui demandera directement au dépositaire le retrait des
          fonds.
          <h4>Règles à respecter en cas d’apport en numéraire en société</h4>
          En cas d’apport en numéraire, le capital souscrit par l’associé unique
          doit être OBLIGATOIREMENT libéré d’au moins 20%, lors de la
          constitution de la société. Les 80% restants doivent être libérés en
          une ou plusieurs fois dans le délai de 5 ans à compter de
          l’immatriculation de la société au RCS. Les fonds doivent être déposés
          dans les 8 jours de leur réception, sur un compte spécial ouvert au
          nom de la société en formation (dans une banque, chez un notaire ou à
          la caisse des dépôts et consignation). Leur retrait ne sera possible
          que sur présentation de l’extrait K-bis de la société, une fois
          celle-ci immatriculée.
          <h4>
            Conséquences de la non libération des apports en numéraire en société
          </h4>
          La non libération du capital à la clôture de l’exercice a pour effet
          de ne pas permettre : <br />
          &nbsp;&nbsp;• la réalisation d’augmentation de capital ;<br />
          &nbsp;&nbsp;• la déductibilité des intérêts rémunérant des sommes
          mises à disposition par l’associé unique à de la société (apport en compte
          courant).
          <br />
          <br />
          Lorsque de la société est soumise à l’impôt sur les sociétés, la non
          libération du capital à la clôture de l’exercice, ne permet pas à la
          société de bénéficier du taux réduit d’IS de 15%.
          <h4>
            Avantages fiscaux conférés par les apports en numéraire en société
          </h4>
          Sous certaines conditions, un associé unique de la société, qui effectue des
          apports en numéraire, peut être éligible à une réduction d’impôts sur
          le revenu.
    `,
  },
  {
    name: ['nature-eurl'],
    content: `<h4>Caractéristiques d’un apport en nature</h4>
    Comme l’apport en numéraire, l’apport en nature forme le capital
    social de la société, et il ouvre droit à l’attribution de parts sociales.
    Les apports en nature sont divers. Ils peuvent être constitués de
    biens meubles (matériel informatique, industriel, de transport) ou
    immeubles (bâtiments, terrains, etc.), corporels ou incorporels (fonds
    de commerce, dépôt de marques, brevets, logiciels,...).
    <h4>Libération des apports en nature en société</h4>
    Les apports en nature doivent être intégralement libérés dès la
    constitution de la société. L’société deviendra propriétaire des biens
    apportés lors de son immatriculation au registre du commerce et des
    sociétés (RCS). Durant le processus d’immatriculation, l’associé
    unique doit assurer la conservation des biens apportés, tels qu’ils
    figurent au contrat d’apport et aux statuts.
    <h4>Règles à respecter en cas d’apport en nature en société</h4>
    L’associé unique doit évaluer individuellement chacun de ses apports
    en nature. Cette évaluation doit être vérifiée par un commissaire aux
    apports, sauf si l’une des deux situations suivantes se présente :
    <br />
    <br />
    &nbsp;&nbsp;• si aucun apport en nature n’a une valeur supérieure à 30
    000 €, et la valeur totale de l’ensemble des apports en nature
    n’excède pas la moitié du capital social ; <br />
    <br />
    Ou <br />
    <br />
    &nbsp;&nbsp;• si l’associé unique exerçait son activité en nom propre
    (EIRL ou entreprise individuelle) avant la création de son société, et
    que l’élément apporté figurait au bilan de son dernier exercice
    comptable (cette dispense s’applique depuis le 11 décembre 2016).
    <br />
    <br />
    En cas de dispense d’intervention d’un commissaire aux apports ou si
    l’associé unique surévalue son apport, il demeure responsable pendant
    5 ans à l’égard des tiers de la valeur attribuée.
    <h4>
      Garanties données par l’associé unique sur ses apports en nature
    </h4>
    L’associé unique est tenu d’apporter les garanties suivantes à son
    société en ce qui concerne les apports en nature qu’il réalise : <br />
    <br />
    &nbsp;&nbsp;• garantis contre vices cachés : de la société doit pouvoir
    utiliser les biens apportés de façon normale ;<br />
    &nbsp;&nbsp;• garantis contre l’éviction : de la société doit pouvoir
    disposer de manière paisible des biens apportés.
    `,
  },
  {
    name: ['industrie-eurl'],
    content: `<h4>Caractéristiques d’un apport en industrie</h4>
    L’apport en industrie se traduit pas par l’apport d’une somme d’argent
    (apport en numéraire) ou d’un bien (apport en nature), mais par
    l’apport de connaissances techniques, de travail réalisés ou de
    services. L’apport en industrie ne concourt pas à la formation du
    capital social, mais l’associé unique recevra tout de même des parts
    sociales en contrepartie de cet apport. Les parts sociales reçues en
    contrepartie d’un apport en industrie ne peuvent jamais être cédées ou
    transmises. Elles lui permettront d’avoir droit aux dividendes, au
    partage de l’actif net, et de prendre des décisions.
    <h4>Evaluation de l’apport en industrie en société</h4>
    Un apport en industrie doit faire l’objet d’une évaluation selon les
    règles généralement utilisés pour valoriser des apports immatériels,
    intellectuels sans substance physique. En général, il s’agit d’estimer
    le coût qu’aurait eu à décaisser de la société si elle avait eu recours à un
    prestataire extérieur pour fournir le bien ou le service apporté.
    <h4>Obligations de l’apporteur en industrie de la société</h4>
    L’associé unique qui aurait effectué un apport en industrie au capital
    de son société doit : <br />
    <br />
    exercer son activité pendant toute la durée prévue (qui peut être
    moindre que la durée de la société) ;<br />
    s’abstenir d’exercer une activité concurrente ;<br />
    effectuer les prestations promises et apporter les connaissances
    techniques prévues ;<br />
    ne pas percevoir personnellement les gains générés par l’exercice de
    l’activité apportée en industrie ;<br />
    <br />
    Lorsque l’associé n’est plus en mesure d’assurer son activité, son
    apport en industrie devient caduc.
    `,
  },
  {
    name: [
      'regimeImpot-eurl',
      'regimeImpot-sasu',
      'regimeImpot-sas',
      'regimeImpot-sarl',
    ],
    content: ` Les bénéfices de la société sont imposables de 2 manières différentes :
    <br />
    <br />
    &nbsp;&nbsp;• soit directement entre les mains de l’associé unique à
    l’impôt sur le revenu (IR) ; <br />
    &nbsp;&nbsp;• soit au niveau de la société à l’impôt sur les sociétés
    (IS). <br />
    <br />
    Lorsque l’associé unique de la société est une personne physique, le
    régime de l’impôt sur le revenu est le régime fiscal de droit de
    de la société. Toutefois, il est possible d’opter à l’impôt sur les sociétés.
    Lorsque l’associé unique de la société est une personne morale, le régime
    de l’impôt sur les sociétés est le régime de droit.
    <h4> La fiscalisation des bénéfices de la société à l’IR </h4>
    Dès lors que de la société est redevable au régime de l’impôt sur les revenus
    (IR), les revenus de la société se confondent avec ceux du gérant
    associé et : <br />
    <br />
    &nbsp;&nbsp;• sont déterminés selon le régime des BNC (bénéfices non
    commerciaux) si vous exercez une activité libérale (en générale de
    services) et celui des BIC (bénéfices industriels et commerciaux) si
    vous exercez une activité artisanale ou commerciale ; <br />
    &nbsp;&nbsp;• la totalité du résultat est donc cotisable au régime des
    TNS (travailleurs non-salariés), et sera également fiscalisé au barème
    progressif de l’IRPP. <br />
    <br />
    Si la société est administrée par un gérant salarié non associé:
    <br /> <br />
    &nbsp;&nbsp;• son salaire sera déductible du résultat de la société.
    <br />
    &nbsp;&nbsp;• le bénéfice résiduel sera attribué en totalité à
    l’associé unique et sera imposé à l’IRPP. <br /> <br />
    Attention : la notion de dividendes n’existent pas en cas de
    fiscalisation des résultats de la société à l’IRPP.
    `,
  },
  {
    name: ['-eurl'],
    content: `
    `,
  },

  // END EURL

  // SASU
  {
    name: ['company_name-sasu'],
    content: `
      Toute société doit avoir une dénomination sociale. 
        Celle-ci est fixée dans les statuts. 
        Il s’agit du nom de la société qui figurera sur son kbis et sur tous les documents 
        qu’elle sera amenée à émettre directement après la forme juridique. 
        La dénomination sociale permet d’identifier et d’individualiser la société en tant que personne 
        morale et de la distinguer des autres entités existantes.
      <br><br>
      Attention la dénomination sociale est différente :
      <br>
      <ul>
        <li>
          du nom commercial qui correspond au nom que l’on donne au fonds de commerce de la société, 
            et avec lequel la communication est réalisée ;
        </li>
        <br>
        <li>
          du sigle qui correspond à l’acronyme ou à un élément distinctif permettant d’identifier la société.
        </li>
      </ul>       
      <h4>Choisir la dénomination</h4>
      La dénomination sociale d’une société doit être licite, et est librement déterminée à la discrétion du/des associé·s. Attention : certaines activités reglementées nécessite que la dénomination sociale soit validée par les organismes de surveillance ou de régulation de ces professions. La seule vraie contrainte est de s’assurer que la dénomination sociale est disponible et qu’elle ne fait pas l’objet d’une protection juridique auprès de l’INPI.
      <h4>Modifier la dénomination sociale d’une société</h4>
      La dénomination sociale initialement choisie lors de la constitution peut être complétée, 
        modifiée ou changée en cours de vie sociale. 
        La procédure d’approbation de cette modification est fixée par les statuts. 
        Ensuite d’un point de vue administratif il s’agira d’une procédure de modification statutaire à 
        réaliser auprès du guichet unique de l'INPI.
          `,
  },
  {
    name: ['code_ape-sasu'],
    content: `
          Lors de la constitution d’une société, l’associé unique doit déterminer
          l’activité qu’il souhaite exercer. Il s’agit de l’objet social qui
          doit respecter certaines règles et figurer dans les statuts de la
          société. Il doit être suffisamment détaillé pour ne pas être limitatif
          tout en étant suffisamment explicite pour refléter l’activité de
          l’entreprise.
          <br />
          <br />
          L’objet social est essentiel car il : <br />
          &nbsp;&nbsp;• Encadre les pouvoirs du Président et peut conduite à
          mettre en jeu sa responsabilité lorsque ses actes dépassent l’objet
          social ;<br />
          &nbsp;&nbsp;• Peut conduire à la dissolution de la société en cas de
          non réalisation lorsque les statuts le prévoient ;<br />
          &nbsp;&nbsp;• Détermine le code NAF de la société ainsi que la
          convention collective applicable aux salariés.
          <h4>Comment choisir l’objet social d’une société ?</h4>
          L’objet social d’une société est librement fixé dans les statuts.
          L’associé peut donc le choisir à sa guise, tout en veillant à ce qu’il
          soit : <br />
          &nbsp;&nbsp;• autorisé car non interdit d’exercice en société (comme les
          activités d’assurance, <br />
          &nbsp;&nbsp;• de prévoyance) ;<br />
          &nbsp;&nbsp;• possible à réaliser ;<br />
          &nbsp;&nbsp;• licite c’est-à-dire conforme à l’ordre public et aux
          bonnes moeurs.
          <br />
          <br />
          Pour des raisons de développement et de non limitation de l’activité,
          les statuts comportent une clause d’activités connexes prévoyant la
          possibilité pour la société d’effectuer toutes les opérations se
          rattachant directement ou indirectement à l’objet social.
          <h4>Peut-on modifier l’objet social d’une société ?</h4>
          L’objet social d’une société peut évoluer ou être modifié totalement,
          étendu ou réduit s’il s’agit d’une décison de l’associé unique. La
          procédure de modification de l’objet social est fixée par les statuts.
          D’un point de vue administratif, une fois la décision approuvée, il
          s’agira de suivre la procédure de modification statutaire auprès du
          greffe du tribunal de commerce.
          <br />
          <br />
          Pour des raisons de développement et de non limitation de l’activité,
          les statuts comportent une clause d’activités connexes prévoyant la
          possibilité pour la société d’effectuer toutes les opérations se
          rattachant directement ou indirectement à l’objet social.`,
  },
  {
    name: ['siege_social_chez-sasu'],
    content: `
      Toute société est tenue d’avoir un siège social qui sera son adresse administrative et qui sera ou pas l’adresse 
        d’exercice de son activité économique. La société peut être propriétaire, locataire (titulaire d’un bail commercial, 
        d’un bail de courte durée ou occupante à titre gratuit. La domiciliation chez le Président est possible ainsi 
        qu’auprès d’une entreprise de domiciliation ou dans une pépinière ou ruche d’entreprises. 
        Une société ne peut fixer son siège social que dans un local qu’elle utilise. 
        Le siège social d’une société est unique. 
        Les adresses des établissements secondaires constituent des lieux d’exercice professionnel. 
        Le siège social figure dans les statuts , il sera mentionné sur le Kbis et figurera sur tous les documents légaux 
        émis par la société.

      <h4>Domicilier le siège social de la société au domicile du Président</h4>
      
      La domiciliation chez le représentant légal de la société est autorisée en société. 
        Celle-ci ne peut cependant se faire qu’au domicile du Président. 
        Attention : dans certains cas, notamment lorsque le Président est locataire, des dispositions du contrat de location 
        peuvent limiter dans le temps voire même interdire la domiciliation. Lorsque la domiciliation est temporaire, 
        elle ne peut excéder une durée de cinq ans.
      
      <h4>Domicilier le siège social de la société chez une entreprise de domiciliation</h4>
      
      La société peut recourir aux services d’une entreprise de domiciliation disposant d’un agrément pour y établir son siège social. 
        Un contrat de domiciliation sera signé et prévoira les conditions et les prestations de location.
      
      <h4>Domicilier le siège social de la société au sein d’une pépinière d’entreprises – ruches – incubateurs…</h4>
      
      Les société peuvent développer leur activité au sein de structures qui mettent à leur disposition un éco-système 
        et de nombreuses infrastructures (secrétariat, accueil, salle de réunion, bureaux…). 
        Elles bénéficient alors de toutes les facilités pour se développer en se concentrant sur leur business. 
        Cette forme de domiciliation n’est autorisée que de façon temporaire, généralement le temps de lancer l’activité.
      
      <h4>Changement de siège social d’une société</h4>
      
      À tout moment, une société peut être amenée à transférer son siège social. 
        Cette opération nécessite de suivre la procédure prévue dans les statuts. 
        Ensuite une procédure administrative de modification statutaire devra être réalisée auprès du guichet unique de l'INPI.
    `,
  },
  {
    name: ['partner-sasu'],
    content: `
    Même si quelques règles doivent être respectées, devenir associé
          unique d’une société par actions simplifiée unipersonnelle (société) ne
          requiert, en principe, aucune capacité particulière ni condition de
          nationalité.
          <h4>L’associé d’une société est une personne physique ou morale</h4>
          Toute personne physique ou morales peut être à l’origine de la
          création et devenir associée unique d’une société.
          <h4>L’associé d’une société n’est soumis à aucune limite de cumul</h4>
          Vous pouvez être associé d’un nombre illimité de société. Aucune règle ne
          vient limiter le nombre de société dans lesquelles vous pouvez être
          associé.
          <h4>L’associé d’une société est Français, Européen ou Étranger</h4>
          Les associés de société peuvent être : <br />
          &nbsp;&nbsp;• toutes les personnes de nationalité Française ; <br />
          &nbsp;&nbsp;• toutes les personnes ressortisantes de l’Union
          Européenne ; <br />
          &nbsp;&nbsp;• toutes les autres personnes titulaires d’une carte de
          résident ou d’une carte de séjour temporaire. <br />
          <h4> La capacité de l’associé et du dirigeant d’une société</h4>
          Ni la loi ni les règlements n’imposent d’être titulaire d’une capacité
          pour devenir associé de société. Ce n’est pas le cas du dirigeant qui ne
          doit pas être frappé d’une interdiction ou d’une incompatibilité à
          exercer. Il n’existe pas d’interdictions ou d’incompatibilités
          empêchant d’être associé d’une société. La qualité d’associé peut donc
          être reconnue à un majeur déclaré « incapable » c’est-à-dire placé
          sous tutelle, sous curatelle ou sous sauvegarde de justice ou même à
          un mineur non émancipé dès lors que leurs engagements sont pris par
          leur représentant légal et que la procédure d’autorisation est
          respectée.
          <h4>
            La qualité d’associé ne peut être reconnue au conjoint de l’associé
            de la société
          </h4>
          Le conjoint de l’associé apporteur ne peut pas revendiquer la qualité
          d’associé pour la moitié des actions souscrites au moyen de biens
          communs et même si les biens apportés sont des biens communs. Seul
          l’apporteur se verra accorder la qualité d’associé. Il n’a pas à en
          informer son conjoint ni même à obtenir son autorisation, sauf si leur
          contrat de mariage le prévoit. Néanmoins, même si un seul des deux
          époux a la qualité d’associé, les actions de la société souscrites au
          moyen de biens communs tombent dans la communauté et devront être
          partagées entre les deux époux en cas de liquidation de la communauté
          (séparation, décès).`,
  },
  {
    name: ['wedding-sasu'],
    content: `
    Le conjoint de l’associé apporteur ne peut pas revendiquer la qualité
          d’associé pour la moitié des actions souscrites au moyen de biens
          communs et même si les biens apportés sont des biens communs. Seul
          l’apporteur se verra accorder la qualité d’associé. Il n’a pas à en
          informer son conjoint ni même à obtenir son autorisation, sauf si leur
          contrat de mariage le prévoit. <br />
          Néanmoins, même si un seul des deux époux a la qualité d’associé, les
          actions de la société souscrites au moyen de biens communs tombent dans
          la communauté et devront être partagées entre les deux époux en cas de
          liquidation de la communauté (séparation, décès).`,
  },
  {
    name: ['gerant-sasu'],
    content: `
    La nomination d’un Président est obligatoire dans les société. Le
          Président de société peut être une personne physique ou une personne
          morale qui sera représentée par une personne physique. Le Président
          figurera au kbis de la société.
          <h4>Incompatibilités et interdictions à être Président de société</h4>
          Contrairement aux associés, certaines incompatibilités interdisent
          d’être nommé Président de société. Ne peuvent être Président de société, les
          personnes : <br />
          &nbsp;&nbsp;• frappées d’une interdiction de gérer une société ;
          <br />
          &nbsp;&nbsp;• incapables (majeur sous tutelle ou sous curatelle, sauf
          rares exceptions) ;<br />
          &nbsp;&nbsp;• qui ont des fonctions incompatibles avec l’exercice des
          fonctions de Président.
          <h4>Désigner un mineur au poste de Président de société</h4>
          Un mineur émancipé peut être nommé Président de société s’il obtient une
          autorisation : <br />
          &nbsp;&nbsp;• du juge des tutelles (si la demande est faite au moment
          de l’émancipation) ;<br />
          &nbsp;&nbsp;• du Président du tribunal de grande instance (si la
          demande est faite ultérieurement).
          <br />
          <br />
          Un mineur non émancipé qui a atteint l’âge de 16 ans peut être nommé
          Président de société.
          <h4>Comment désigner le Président d’une société ?</h4>
          Le premier Président de la société doit être désigné dans les statuts.
          Ensuite, les statuts prévoient librement les modalités de nomination
          des prochains Présidents de la société.
          <br />
          <br />
          Plusieurs options sont possibles pour nommer le Président, soit :
          <br />
          &nbsp;&nbsp;• prévoir que la nomination du Président est une décision
          de l’associé unique ; <br />
          &nbsp;&nbsp;• prévoir que l’associé unique est le Président ;
    `,
  },
  {
    name: ['contribution-sasu'],
    content: `
    Les associés de la société ont la possibilité d’effectuer les trois types
    d’apports : <br />
    &nbsp;&nbsp;• des apports en numéraire ;<br />
    &nbsp;&nbsp;• des apports en nature ;<br />
    &nbsp;&nbsp;• des apports en industrie. <br />
    <br />
    Toutefois, seuls les apports en numéraire et en nature concourent à la
    formation du capital social. Aucun capital social minimum n’est exigé
    pour constituer une société. Il doit être au moins représenté par deux
    actions au moins pour une société, une pour chacun des deux actionnaires.
    `,
  },
  {
    name: ['numeraire-sasu'],
    content: ` <h4>Les apports en numéraire en société</h4>
    L’apport en numéraire représente une somme d’argent que l’associé
    unique dépose lors de la constitution sur le compte bancaire de la
    société en création. Ces fonds contribueront à la constitution du capital
    de la société et donneront droit en échange à l’attribution d’actions.
    <h4>Libération des apports en numéraire en société</h4>
    La moitié au moins des apports en numéraire doit être libérée à la
    constitution, le reste pouvant être libéré en une ou plusieurs fois
    durant les 5 années suivantes.
    <br />
    Lorsqu’une libération partielle est envisagée, il faut avoir à
    l’esprit deux règles importantes
    <br />
    &nbsp;&nbsp;• La société ne pourra pas bénéficier du taux réduit de
    l’impôt sur les sociétés de 15% ;
    <br />
    &nbsp;&nbsp;• Les intérêts qui résultent de la rémunération d’apports
    en comptes courants consentis par les associés ne seront pas
    déductibles fiscalement.
    <h4>
      Faut-il déposer les apports en numéraire sur un compte bloqué au nom
      de la société ?
    </h4>
    Dans les 8 jours de leur réception, et, avant la signature des
    statuts, les sommes doivent être déposées pour le compte de la société
    en formation :<br />
    &nbsp;&nbsp;• soit sur un compte bancaire bloqué ouvert au nom de la
    société ;
    <br />
    &nbsp;&nbsp;• soit à la caisse des dépôts et consignation ;<br />
    &nbsp;&nbsp;• soit chez un notaire.
    <h4>
      Comment débloquer les fonds apportés en numéraire au capital de la
      société ?
    </h4>
    Les fonds sont indisponibles jusqu’à l’immatriculation de la société au
    RCS. Ils sont alors libérés sur présentation par le Président de
    l’extrait K-bis auprès du dépositaire (banque, notaire, CDC). Si la
    société n’est pas constituée dans un délai de 6 mois à compter du dépôt
    des fonds, l’apporteur associé unique peut demander en justice
    l’autorisation de retirer le montant de ses apports ou choisir un
    mandataire qui demandera directement au dépositaire le retrait des
    fonds.
    <h4>
      Existe-t-il des avantages fiscaux profitant aux associés apporteurs
      en numéraire ?
    </h4>
    Sous certaines conditions, un associé unique de la société qui effectue
    des apports en numéraire peut être éligible à une réduction d’impôts
    sur le revenu à hauteur de 25% des montants apportés.
    `,
  },
  {
    name: ['nature-sasu'],
    content: `
    Comme l’apport en numéraire, l’apport en nature forme le capital
          social de la société et ouvre droit à l’attribution d’actions. Le capital
          social de la société pourrait être uniquement constitué par des apports
          en nature.
          <h4>Caractéritiques d’un apport en nature en société ?</h4>
          Les apports en nature sont divers. Ils peuvent être constitués de
          biens meubles (matériel informatique, industriel, de transport) ou
          immeubles (bâtiments, terrains, etc.), corporelles ou incorporels
          (fonds de commerce, dépôt de marques, brevets, logiciels,...).
          <h4>
            De quelles garanties une société bénéficie-t-elle quant aux apports en
            nature ?
          </h4>
          L’associé unique s’engage à l’égard de sa société à ce que les apports en
          nature soient : <br />
          &nbsp;&nbsp;• garantis contre vices cachés : La société doit pouvoir
          utiliser les biens apportés de façon normale ;<br />
          &nbsp;&nbsp;• garantis contre l’éviction : la société doit pouvoir
          disposer de manière paisible des biens apportés.
          <h4> Règles à respecter en cas d’apport en nature en société ?</h4>
          L’associé unique doit évaluer individuellement chacun de ses apports
          en nature. Cette évaluation doit être vérifiée par un commissaire aux
          apports sauf : <br />
          &nbsp;&nbsp;• si aucun apport en nature n’a une valeur supérieure à 30
          000 € et la valeur totale de l’ensemble des apports en nature n’excède
          pas la moitié du capital social ;
          <br />
          &nbsp;&nbsp;• si l’associé unique exerçait son activité en nom propre
          (EIRL ou entreprise individuelle) avant la création de son société et que
          l’élément apporté figurait au bilan de son dernier exercice comptable.
          <br />
          <br />
          En cas de dispense d’intervention d’un commissaire aux apports ou si
          l’associé unique surévalue son apport, il est responsable pendant 5
          ans à l’égard des tiers de la valeur attribuée.
          <h4>
            Quelles sont les conditions de libération des apports en nature en
            société ?
          </h4>
          Les apports en nature doivent faire l’objet d’une libération intégrale
          des leur souscription.
          <h4>Réalisation de l’apport en nature en société </h4>
          L’associé apporteur en nature doit conserver en l’état les apports en
          nature et ce jusqu’à la création de la société. A compter de son
          immatriculation au registre du commerce et des sociétés, la société
          devient propriétaire des actifs apportés.
    `,
  },
  {
    name: ['industrie-sasu'],
    content: `Un apport en industrie constitue un apport immatériel non financier.
    <h4>Qu’est-ce qu’un apport en industrie en société ?</h4>
    L’apport en industrie se traduit par l’apport de connaissances
    techniques, de travaux réalisés ou de services rendus. L’apport en
    industrie ne concourt pas à la formation du capital social mais
    l’associé unique recevra tout de même des actions en contrepartie de
    cet apport. Les actions reçues en contrepartie d’un apport en
    industrie ne peuvent jamais être cédées ou transmises. Elles lui
    permettront d’avoir droit aux dividendes, au partage de l’actif net et
    de prendre certaines décisions.
    <h4>
      Quelles sont les règles à respecter pour évaluer un apport en
      industrie en société ?
    </h4>
    Un apport en industrie doit faire l’objet d’une évaluation selon les
    règles généralement utilisés pour valoriser des apports immatériels,
    intellectuels sans substance physique. En général, il s’agit d’estimer
    le coût potentiel du décaissement si la société avait eu recours à un
    prestataire extérieure pour réaliser la prestation apportée.
    <h4>
      Quelles sont les obligations de l’apporteur en industrie en société
    </h4>
    L’associé qui a réalisé des apports en industrie doit : <br />
    &nbsp;&nbsp;• assurer les prestations prévues sur la durée convenue ;
    <br />
    &nbsp;&nbsp;• ne doit pas porter préjudice à la société et ont
    généralement pour obligation de ne pas exercer une activité
    concurrente ;<br />
    &nbsp;&nbsp;• il s’engage à ne pas encaisser à titre personnel les
    revenus que pourrait générer l’exercice de l’activité apportée en
    industrie à la société.
    <h4>
      Quelles sont les clauses statutaires relatives à l’apport en
      industrie ?
    </h4>
    Les statuts de la société doivent préciser : <br />
    &nbsp;&nbsp;• les conditions de souscription et de répartition des
    actions résultant d’apports en industrie ;<br />
    &nbsp;&nbsp;• la description de cet apport (nature, durée…) ;<br />
    &nbsp;&nbsp;• le nombre de titres reçus en échange ;<br />
    &nbsp;&nbsp;• les droits attachés aux actions ;<br />
    &nbsp;&nbsp;• les obligations en matière de contribution aux pertes et
    d’exclusivité (non-concurrence).
    <br />
    <br />
    Si les statuts ne prévoient rien de particulier, l’associé apporteur
    en industrie en société dispose des mêmes droits et obligations aux
    pertes que celui qui a effectué le plus petit apport en numéraire (ou
    en nature).
    `,
  },
  // END SASU

  // SAS
  {
    name: ['company_name-sas'],
    content: `
    Toute société doit avoir une dénomination sociale. 
      Celle-ci est fixée dans les statuts. 
      Il s’agit du nom de la société qui figurera sur son kbis et sur tous les documents 
      qu’elle sera amenée à émettre directement après la forme juridique. 
      La dénomination sociale permet d’identifier et d’individualiser la société en tant que personne 
      morale et de la distinguer des autres entités existantes.
    <br><br>
    Attention la dénomination sociale est différente :
    <br>
    <ul>
      <li>
        du nom commercial qui correspond au nom que l’on donne au fonds de commerce de la société, 
          et avec lequel la communication est réalisée ;
      </li>
      <br>
      <li>
        du sigle qui correspond à l’acronyme ou à un élément distinctif permettant d’identifier la société.
      </li>
    </ul>       
    <h4>Choisir la dénomination</h4>
    La dénomination sociale d’une société doit être licite, et est librement déterminée à la discrétion du/des associé·s. Attention : certaines activités reglementées nécessite que la dénomination sociale soit validée par les organismes de surveillance ou de régulation de ces professions. La seule vraie contrainte est de s’assurer que la dénomination sociale est disponible et qu’elle ne fait pas l’objet d’une protection juridique auprès de l’INPI.
    <h4>Modifier la dénomination sociale d’une société</h4>
    La dénomination sociale initialement choisie lors de la constitution peut être complétée, 
      modifiée ou changée en cours de vie sociale. 
      La procédure d’approbation de cette modification est fixée par les statuts. 
      Ensuite d’un point de vue administratif il s’agira d’une procédure de modification statutaire à 
      réaliser auprès du guichet unique de l'INPI.
    `,
  },
  {
    name: ['code_ape-sas'],
    content: `
    Lors de la constitution d’une société, les associés fondateurs doivent
    déterminer l’activité qu’ils souhaitent exercer. Il s’agit de l’objet
    social qui doit respecter certaines règles, et figurer dans les
    statuts de la société. L’objet social figure dans les statuts, et doit
    être suffisamment détaillé pour ne pas être limitatif. L’objet doit
    être suffisamment explicite pour refléter l’activité de l’entreprise.
    L’objet social est essentiel car il : <br />
    &nbsp;&nbsp;• encadre les pouvoirs du Président, et peut conduire à
    mettre en jeu sa responsabilité, lorsque ses actes dépassent l’objet
    social ;<br />
    &nbsp;&nbsp;• peut conduire à la dissolution de la société en cas de
    non réalisation lorsque les statuts le prévoient ;<br />
    &nbsp;&nbsp;• détermine le code NAF de la société, ainsi que la
    convention collective applicable aux salariés.
    <h4>Comment choisir l’objet social d’une société ?</h4>
    L’objet social d’une société est librement fixé dans les statuts. Les
    associés peuvent donc choisir l’objet social à leur guise. Toutefois,
    la loi impose que ce dernier soit :<br />
    &nbsp;&nbsp;• autorisé car non-interdit d’exercice en société (assurance,
    prévoyance…) ;<br />
    &nbsp;&nbsp;• réalisable ;<br />
    &nbsp;&nbsp;• licite, c’est-à-dire conforme à l’ordre public et aux
    bonnes moeurs.
    <br />
    <br />
    Pour des raisons de développement, les statuts comportent une clause
    d’activités connexes. Elles prévoient la possibilité d’effectuer
    toutes les opérations se rattachant directement ou indirectement à
    l’objet social.
    <h4>Peut-on modifier l’objet social d’une société ?</h4>
    L’objet social d’une société peut évoluer au cours de la vie sociale. Les
    associés ont la possibilité de le modifier tout au long de la vie
    sociale. Ils peuvent le modifier totalement, l’étendre ou au contraire
    le réduire. La procédure de modification de l’objet social est fixée
    par les statuts. D’un point de vue administratif, une fois la décision
    approuvée, il s’agira de suivre la procédure de modification
    statutaire.`,
  },
  {
    name: ['siege_social_chez-sas'],
    content: `
      Toute société est tenue d’avoir un siège social qui sera son adresse administrative et qui sera ou pas l’adresse 
        d’exercice de son activité économique. La société peut être propriétaire, locataire (titulaire d’un bail commercial, 
        d’un bail de courte durée ou occupante à titre gratuit. La domiciliation chez le Président est possible ainsi 
        qu’auprès d’une entreprise de domiciliation ou dans une pépinière ou ruche d’entreprises. 
        Une société ne peut fixer son siège social que dans un local qu’elle utilise. 
        Le siège social d’une société est unique. 
        Les adresses des établissements secondaires constituent des lieux d’exercice professionnel. 
        Le siège social figure dans les statuts , il sera mentionné sur le Kbis et figurera sur tous les documents légaux 
        émis par la société.

      <h4>Domicilier le siège social de la société au domicile du Président</h4>
      
      La domiciliation chez le représentant légal de la société est autorisée en société. 
        Celle-ci ne peut cependant se faire qu’au domicile du Président. 
        Attention : dans certains cas, notamment lorsque le Président est locataire, des dispositions du contrat de location 
        peuvent limiter dans le temps voire même interdire la domiciliation. Lorsque la domiciliation est temporaire, 
        elle ne peut excéder une durée de cinq ans.
      
      <h4>Domicilier le siège social de la société chez une entreprise de domiciliation</h4>
      
      La société peut recourir aux services d’une entreprise de domiciliation disposant d’un agrément pour y établir son siège social. 
        Un contrat de domiciliation sera signé et prévoira les conditions et les prestations de location.
      
      <h4>Domicilier le siège social de la société au sein d’une pépinière d’entreprises – ruches – incubateurs…</h4>
      
      Les société peuvent développer leur activité au sein de structures qui mettent à leur disposition un éco-système 
        et de nombreuses infrastructures (secrétariat, accueil, salle de réunion, bureaux…). 
        Elles bénéficient alors de toutes les facilités pour se développer en se concentrant sur leur business. 
        Cette forme de domiciliation n’est autorisée que de façon temporaire, généralement le temps de lancer l’activité.
      
      <h4>Changement de siège social d’une société</h4>
      
      À tout moment, une société peut être amenée à transférer son siège social. 
        Cette opération nécessite de suivre la procédure prévue dans les statuts. 
        Ensuite une procédure administrative de modification statutaire devra être réalisée auprès du guichet unique de l'INPI.
    `,
  },
  {
    name: ['partner-sas'],
    content: `
    <h4>L’associé d’une société est une personne physique ou morale</h4>
    Toutes les personnes physiques ou les personnes morales (dont les
    associations) peuvent devenir associées d’une société par actions
    simplifiée.
    <h4>L’associé d’une société est français, européen ou étranger</h4>
    Les associés de société peuvent être : <br />
    &nbsp;&nbsp;• toutes les personnes de nationalité Française ; <br />
    &nbsp;&nbsp;• toutes les personnes ressortisantes de l’Union
    Européenne ; <br />
    &nbsp;&nbsp;• toutes les autres personnes titulaires d’une carte de
    résident ou d’une carte de séjour temporaire.
    <h4>L’associé d’une société n’est soumis à aucune limite de cumul</h4>
    Aucune règle limitative ne fixe de limite en la matière. Vous pouvez
    donc librement être associé sans limite au sein de multiples sociétés.
    <h4>L’associé d’une société ne peut être frappé d’une interdiction</h4>
    Ni la loi ni les règlements n’imposent d’être titulaire d’une capacité
    pour devenir associé de société, ce qui n’est pas le cas pour en être le
    dirigeant. Il n’existe pas d’interdictions ou d’incompatibilités
    empêchant d’être associé d’une société. La qualité d’associé peut donc
    être reconnue à un majeur déclaré « incapable » c’est-à-dire placé
    sous tutelle, sous curatelle ou sous sauvegarde de justice ou à un
    mineur non émancipé dès lors que leurs engagements sont pris par leur
    représentant légal et que la procédure d’autorisation est respectée.
    <h4>
      La qualité d’associé ne peut être reconnue au conjoint de l’associé
      de la société
    </h4>
    Le conjoint de l’apporteur ne peut pas revendiquer la qualité
    d’associé pour la moitié des actions souscrites, même si les biens
    apportés sont des biens communs. Seul l’apporteur se verra accorder la
    qualité d’associé. Il n’a pas à en informer son conjoint ni même à
    obtenir son autorisation, sauf si leur contrat de mariage le prévoit.
    Néanmoins, même si un seul des deux époux a la qualité d’associé, les
    actions de la société souscrites au moyen de biens communs tombent dans la
    communauté et devront être partagées entre les deux époux en cas de
    liquidation de la communauté (séparation, décès).`,
  },
  {
    name: ['wedding-sas'],
    content: `
    Le conjoint de l’associé apporteur ne peut pas revendiquer la qualité
    d’associé pour la moitié des actions souscrites au moyen de biens
    communs et même si les biens apportés sont des biens communs. Seul
    l’apporteur se verra accorder la qualité d’associé. Il n’a pas à en
    informer son conjoint ni même à obtenir son autorisation, sauf si leur
    contrat de mariage le prévoit. Néanmoins, même si un seul des deux
    époux a la qualité d’associé, les actions de la société souscrites au
    moyen de biens communs tombent dans la communauté et devront être
    partagées entre les deux époux en cas de liquidation de la communauté
    (séparation, décès).`,
  },
  {
    name: ['contribution-sas'],
    content: `
    L’associé de la société ont la possibilité d’effectuer les trois types
          d’apports : <br />
          &nbsp;&nbsp;• des apports en numéraire ;<br />
          &nbsp;&nbsp;• des apports en nature ;<br />
          &nbsp;&nbsp;• des apports en industrie. <br />
          <br />
          Toutefois, seuls les apports en numéraire et en nature concourent à la
          formation du capital social. Aucun capital social minimum n’est exigé
          pour constituer une société. Il doit être au moins représenté par deux
    `,
  },
  {
    name: ['numeraire-sas'],
    content: `L’apport en numéraire représente une somme d’argent que les associés
    déposent lors de la constitution sur le compte bancaire de la société en
    création. Ces fonds contribueront à la constitution du capital de la
    société et donneront droit en échange à l’attribution d’actions aux
    associées proportionnellement au montant de leurs apports. La
    contrepartie comptable d’un apport en numéraire sera donc un actif
    financier (généralement un compte bancaire ou un compte postal).
    <h4> Libération des apports en numéraire en société </h4>
    Les associés d’une société disposent de plusieurs options pour à la
    libération des apports en numéraire qu’ils ont consentis. Ils peuvent
    : <br />
    &nbsp;&nbsp;• verser immédiatement (libérer intégralement) la totalité
    de l’apport en numéraire ;<br />
    &nbsp;&nbsp;• ne verser qu’une partie (1/2) dans un premier temps puis
    verser le solde (1/2) dans les 5 ans suivant l’immatriculation de la
    société (libération partielle). Lorsqu’une libération partielle est
    envisagée:
    <br />
    &nbsp;&nbsp;• La société ne pourra pas bénéficier du taux réduit de
    l’impôt sur les sociétés de 15% ;<br />
    &nbsp;&nbsp;• Les intérêts qui résultent de la rémunération d’apports
    en comptes courants consentis par les associés ne seront pas
    déductibles fiscalement.
    <h4>
      Faut-il déposer les apports en numéraire sur un compte bloqué au
    </h4>
    nom de la société ? Dans les 8 jours de leur versement, les associés
    doivent déposer leurs apports en numéraire pour le compte de la
    société en formation : <br />
    &nbsp;&nbsp;• soit sur un compte bancaire bloqué ouvert au nom de la
    société ;
    <br />
    &nbsp;&nbsp;• soit à la caisse des dépôts et consignation ;<br />
    &nbsp;&nbsp;• soit chez un notaire.
    <br /> <br />
    Une liste des souscripteurs doit également être communiquée en même
    temps que le dépôt des fonds. Cette liste indique les nom, prénom et
    domicile des apporteurs en numéraire, et les sommes versées par chacun
    d’eux. Le dépositaire des fonds est tenu de communiquer la liste des
    souscripteurs à chaque apporteur en numéraire.
    <h4>
      Comment débloquer les fonds apportés en numéraire au capital de la
      société ?
    </h4>
    Les fonds seront ensuite libérés sur présentation, par le Président de
    la société, de l’extrait Kbis, afin de prouver que la société est
    immatriculée au registre du commerce et des sociétés. Au cas où la
    société ne serait pas constituée dans un délai de 6 mois suivant le
    dépôt des fonds, les associés ont la possibilité de demander en
    justice la récupération des apports qu’ils ont effectués.
    <h4>
      Existe-t-il des avantages fiscaux profitant aux associés apporteurs
      en numéraire ?
    </h4>
    Sous certaines conditions, les associés de société qui effectuent des
    apports en numéraire peuvent être éligibles à une réduction d’impôts
    sur le revenu à hauteur de 25% des montants apportés.
    `,
  },
  {
    name: ['nature-sas'],
    content: ` L’apport en nature est une contribution à la formation le capital
    social de la société et ouvre droit à l’attribution d’actions. Le capital
    social de la société pourrait être unique-ment constitué par des apports
    en nature.
    <h4>Qu’est-ce qu’un apport en nature en société ?</h4>
    Un apport en nature constitue un bien, un actif non financier.
    L’apport en nature correspond à un bien mobilier matériel (matériel,
    mobilier…) ou immatériel (logiciel, brevet, marque…) ou ou mobilier ou
    à des titres financiers. Il concourt, tout comme les apports en
    numéraire, à la formation du capital social. En contrepartie, et en
    proportion des apports effectués chaque associé, recevra donc des
    actions. Tous les apports en nature réalisés sont mentionnés dans les
    statuts de la société, avec la description, l’évaluation de chacun de ces
    biens, l’identité des associés
    <h4>
      De quelles garanties une société bénéficie-t-elle quant aux apports en
      nature ?
    </h4>
    L’associé unique s’engage à l’égard de sa société à ce que les apports en
    nature soient : <br />
    &nbsp;&nbsp;• garantis contre vices cachés : La société doit pouvoir
    utiliser les biens apportés de façon normale ;<br />
    &nbsp;&nbsp;• garantis contre l’éviction : la société doit pouvoir
    disposer de manière paisible des biens apportés.
    <h4>
      Le recours à un commissaire aux apports est-il obligatoire pour les
      apports en nature en société ?
    </h4>
    En principe, lorsque le capital d’une société se compose d’apports en
    nature, l’intervention d’un commissaire aux apports est éxigée. Sa
    mission consiste à vérifier les valeurs attribuées aux apports. Il est
    nommé par les associés via une décision unanime. A défaut, c’est le
    Président du tribunal de commerce qui s’en charge, à la demande d’un
    ou plusieurs associés. Par exception, les associés peuvent prendre la
    décision, à l’unanimité, de se dispenser du recours au commissaire aux
    apports si : <br />
    &nbsp;&nbsp;• aucun apport en nature ne vaut plus de 30 000 € ;<br />
    &nbsp;&nbsp;• la valeur de tous les apports en nature n’excède pas 50%
    du capital.
    <br />
    <br />
    Si les associés de la société décident, malgré l’évaluation faite par le
    commissaire aux apports, de retenir un montant différent, ils en
    demeurent solidairement responsables durant 5 ans. Attention, la
    surévaluation d’un apport en nature est un délit passible de 5 ans
    d’emprisonnement et de 9 000 € d’amende.
    <h4>
      Quelles sont les conditions de libération des apports en nature en
      société ?
    </h4>
    Contrairement aux apports en numéraire, les apports en nature doivent
    faire l’objet d’une libération intégrale dès leur souscription.
    <h4>Réalisation de l’apport en nature en société</h4>
    L’associé apporteur en nature doit conserver, en l’état, de l’apport
    et ce jusqu’à création de la société. A compter de son immatriculation
    au registre du commerce et des sociétés, la société devient
    propriétaire des actifs en nature apportés.
    `,
  },
  {
    name: ['industrie-sas'],
    content: `   Un apport en industrie constitue un apport immatériel non financier.
    <h4>Qu’est-ce qu’un apport en industrie en société ?</h4>
    L’apport en industrie se traduit par l’apport de connaissances
    techniques, de travaux réalisés ou de services rendus. L’apport en
    industrie ne concourt pas à la formation du capital social mais
    l’associé unique recevra tout de même des actions en contrepartie de
    cet apport. Les actions reçues en contrepartie d’un apport en
    industrie ne peuvent jamais être cédées ou transmises. Elles lui
    permettront d’avoir droit aux dividendes, au partage de l’actif net et
    de prendre certaines décisions.
    <h4>
      Quelles sont les règles à respecter pour évaluer un apport en
      industrie en société ?
    </h4>
    Un apport en industrie doit faire l’objet d’une évaluation selon les
    règles généralement utilisés pour valoriser des apports immatériels,
    intellectuels sans substance physique. En général, il s’agit d’estimer
    le coût potentiel du décaissement si la société avait eu recours à un
    prestataire extérieure pour réaliser la prestation apportée.
    <h4>
      Quelles sont les obligations de l’apporteur en industrie en société ?
    </h4>
    L’associé qui a réalisé des apports en industrie doit : <br />
    &nbsp;&nbsp;• assurer les prestations prévues sur la durée convenue ;
    <br />
    &nbsp;&nbsp;• ne doit pas porter préjudice à la société et ont
    généralement pour obligation de ne pas exercer une activité
    concurrente ;<br />
    &nbsp;&nbsp;• il s’engage à ne pas encaisser à titre personnel les
    revenus que pourrait générer l’exercice de l’activité apportée en
    industrie à la société.
    <h4>
      Quelles sont les clauses statutaires relatives à l’apport en
      industrie ?
    </h4>
    Les statuts de la société doivent préciser : <br />
    &nbsp;&nbsp;• les conditions de souscription et de répartition des
    actions résultant d’apports en industrie ;<br />
    &nbsp;&nbsp;• la description de cet apport (nature, durée…) ;<br />
    &nbsp;&nbsp;• le nombre de titres reçus en échange ;<br />
    &nbsp;&nbsp;• les droits attachés aux actions ;<br />
    &nbsp;&nbsp;• les obligations en matière de contribution aux pertes et
    d’exclusivité (non-concurrence).
    <br />
    <br />
    Si les statuts ne prévoient rien de particulier, l’associé apporteur
    en industrie en société dispose des mêmes droits et obligations aux pertes
    que celui qui a effectué le plus petit apport en numéraire (ou en
    nature).
    `,
  },
  {
    name: ['-sas'],
    content: `
    `,
  },
  // END SAS

  // SARL
  {
    name: ['company_name-sarl'],
    content: `
    Toute société doit avoir une dénomination sociale. 
      Celle-ci est fixée dans les statuts. 
      Il s’agit du nom de la société qui figurera sur son kbis et sur tous les documents 
      qu’elle sera amenée à émettre directement après la forme juridique. 
      La dénomination sociale permet d’identifier et d’individualiser la société en tant que personne 
      morale et de la distinguer des autres entités existantes.
    <br><br>
    Attention la dénomination sociale est différente :
    <br>
    <ul>
      <li>
        du nom commercial qui correspond au nom que l’on donne au fonds de commerce de la société, 
          et avec lequel la communication est réalisée ;
      </li>
      <br>
      <li>
        du sigle qui correspond à l’acronyme ou à un élément distinctif permettant d’identifier la société.
      </li>
    </ul>       
    <h4>Choisir la dénomination</h4>
    La dénomination sociale d’une société doit être licite, et est librement déterminée à la discrétion du/des associé·s. Attention : certaines activités reglementées nécessite que la dénomination sociale soit validée par les organismes de surveillance ou de régulation de ces professions. La seule vraie contrainte est de s’assurer que la dénomination sociale est disponible et qu’elle ne fait pas l’objet d’une protection juridique auprès de l’INPI.
    <h4>Modifier la dénomination sociale d’une société</h4>
    La dénomination sociale initialement choisie lors de la constitution peut être complétée, 
      modifiée ou changée en cours de vie sociale. 
      La procédure d’approbation de cette modification est fixée par les statuts. 
      Ensuite d’un point de vue administratif il s’agira d’une procédure de modification statutaire à 
      réaliser auprès du guichet unique de l'INPI.
    `,
  },
  {
    name: ['code_ape-sarl'],
    content: `
    L’objet social est la nature des activités que la société va exercer.
          L’objet social figure dans les statuts de la société. Il est essentiel
          car : <br />
          &nbsp;&nbsp;• il conditionne la conformité des activités exercées par
          la société et engage la responsabilité du gérant en cas de non respect
          du champ d’activités ; <br />
          &nbsp;&nbsp;• il détermine le code APE attribué par l’INSEE à la société
          ;<br />
          &nbsp;&nbsp;• il détermine la convention collective appliquable en
          matière sociale ;<br />
          <br />
          La non réalisation de l’objet social est une cause de dissolution de
          la société.
          <h4>Choisir l’objet social d’une société</h4>
          Le choix de l’objet social d’une société est libre tant qu’il est licite
          et qu’il peut être réalisé. L’objet social doit être décrit
          précisément dans les statuts lors de la constitution de la société. En
          général, au-delà de l’activité coeur de métier, des précisions sur les
          activités connexes sont mentionnées dans les statuts afin de prévoir
          la réalisation de toutes les opérations qui se rattachent directement
          ou indirectement à ou aux activités principales. Attention : certaines
          activités ne peuvent être exercées en société. C’est le cas notamment des
          activités d’assurance, d’épargne et de prévoyance, de débit de tabac
          ou de perception et de répartition de droits d’auteurs.
          <h4>Déclarer l’objet social d’une société au RCS</h4>
          L’objet social de la société figure dans les statuts, il est mentionné
          sur le MO lors de l’immatriculation, et figurera sur le kbis de la
          société.
          <h4>Modifier l’objet social d’une société</h4>
          Les associés, à tout moment, peuvent modifier l’objet social de la
          société. Il s’agira d’une modification statutaire à effectuer en
          respectant les règles qui y sont relatives.`,
  },
  {
    name: ['siege_social_chez-sarl'],
    content: `
      Toute société est tenue d’avoir un siège social qui sera son adresse administrative et qui sera ou pas l’adresse 
        d’exercice de son activité économique. La société peut être propriétaire, locataire (titulaire d’un bail commercial, 
        d’un bail de courte durée ou occupante à titre gratuit. La domiciliation chez le Président est possible ainsi 
        qu’auprès d’une entreprise de domiciliation ou dans une pépinière ou ruche d’entreprises. 
        Une société ne peut fixer son siège social que dans un local qu’elle utilise. 
        Le siège social d’une société est unique. 
        Les adresses des établissements secondaires constituent des lieux d’exercice professionnel. 
        Le siège social figure dans les statuts , il sera mentionné sur le Kbis et figurera sur tous les documents légaux 
        émis par la société.

      <h4>Domicilier le siège social de la société au domicile du Président</h4>
      
      La domiciliation chez le représentant légal de la société est autorisée en société. 
        Celle-ci ne peut cependant se faire qu’au domicile du Président. 
        Attention : dans certains cas, notamment lorsque le Président est locataire, des dispositions du contrat de location 
        peuvent limiter dans le temps voire même interdire la domiciliation. Lorsque la domiciliation est temporaire, 
        elle ne peut excéder une durée de cinq ans.
      
      <h4>Domicilier le siège social de la société chez une entreprise de domiciliation</h4>
      
      La société peut recourir aux services d’une entreprise de domiciliation disposant d’un agrément pour y établir son siège social. 
        Un contrat de domiciliation sera signé et prévoira les conditions et les prestations de location.
      
      <h4>Domicilier le siège social de la société au sein d’une pépinière d’entreprises – ruches – incubateurs…</h4>
      
      Les société peuvent développer leur activité au sein de structures qui mettent à leur disposition un éco-système 
        et de nombreuses infrastructures (secrétariat, accueil, salle de réunion, bureaux…). 
        Elles bénéficient alors de toutes les facilités pour se développer en se concentrant sur leur business. 
        Cette forme de domiciliation n’est autorisée que de façon temporaire, généralement le temps de lancer l’activité.
      
      <h4>Changement de siège social d’une société</h4>
      
      À tout moment, une société peut être amenée à transférer son siège social. 
        Cette opération nécessite de suivre la procédure prévue dans les statuts. 
        Ensuite une procédure administrative de modification statutaire devra être réalisée auprès du guichet unique de l'INPI.
    `,
  },
  {
    name: ['partner-sarl'],
    content: `
    <h4>
    Les associés d’une société peuvent être une personne physique ou morale
  </h4>
  Toute personne au sens juridique du terme peut avoir la qualité
  d’associé de société. Il peut donc s’agir aussi bien d’une personne
  physique que d’une personne morale qu’il s’agisse d’une autre société
  ou d’une association.
  <h4>Une personne peut être associée de plusieurs sociétés différentes</h4>
  Les personnes physiques comme les personnes morales peuvent être
  associées d’une ou de plusieurs sociétés sans limitation. Cela permet
  souvent de cloisonner des activités dans des entités juridiques
  spécifiques autonomes.
  <h4>
    L’associé de société peut avoir la nationalité Française ou étrangère
  </h4>
  Tout personne de nationalité française, européenne, non européenne
  peut devenir associée d’une société.
  <h4>
    Aucune incapacité particulière n’interdit de devenir associé de société
  </h4>
  Aucune incompatibilité ou interdiction n’interdit d’être associé d’une
  société, dès lors que l’associé n’est pas le gérant. Peuvent donc être
  associés de société : <br />
  &nbsp;&nbsp;• les majeurs capables ou incapables (sous curatelle,
  tutelle ou sauvegarde de justice) ;<br />
  &nbsp;&nbsp;• les mineurs émancipés (assimilés à des majeurs) ;<br />
  &nbsp;&nbsp;• les mineurs non émancipés dès lors qu’ils agissent dans
  les conditions légales à respecter.`,
  },
  {
    name: ['wedding-sarl'],
    content: `
    Le conjoint de l’associé, qui a acquis des parts sociales en utilisant
          des biens de la communauté, peut revendiquer la qualité d’associé de
          la société et ce pour la moitié des parts qui ont été souscrites. Pour
          exercer ce droit, le conjoint doit être informé de l’opération
          préalablement sous peine de nullité de l’apport. <br /> <br />
          Au regard du droit de revendication, trois situations sont possibles :
          <br />
          <br />
          1. si le conjoint renonce, par écrit, à revendiquer la qualité
          d’associé au sein de la société, cette renonciation sera définitive ;
          <br />
          2. si le conjoint revendique tout de suite la qualité d’associé, les
          autres associés peuvent accepter les deux ou les refuser ; <br />
          3. si le conjoint ne se prononce pas, il pourra quand même revendiquer
          la qualité d’associé ultérieurement. <br />
          <br />
          Les statuts peuvent prévoir une clause d’agrément en fonction de leur
          rédaction. Dans ce cas le conjoint déjà associé ne participe pas au
          vote. Si un associé souhaite effectuer un apport en nature de parts
          sociales, d’un bien immobilier ou d’un fonds de commerce faisant
          partie de la communauté, le consentement des deux époux est nécessaire
          sous peine de nullité de l’opération d’apport. <br />
          <br />
          S’agissant des partenaires liés par un PACS, ils peuvent en principe
          souscrire librement des parts sociales de société en toute indépendance,
          sauf clause contraire prévue dans la convention de PACS. Aucun
          formalisme n’est nécessaire lorsque les parts sont financées par des
          apports personnels ne faisant pas partie de la communauté, et quel que
          soit le régime matrimonial`,
  },
  {
    name: ['contribution-sarl'],
    content: `Les associés de la société ont la possibilité d’effectuer les trois types
    d’apports : <br />
    &nbsp;&nbsp;• des apports en numéraire ;<br />
    &nbsp;&nbsp;• des apports en nature ;<br />
    &nbsp;&nbsp;• des apports en industrie.
    <br />
    <br />
    Toutefois, seuls les apports en numéraire et en nature concourent à la
    formation du capital social. Aucun capital social minimum n’est exigé
    pour constituer une société. Il doit être au moins représenté par deux
    actions au moins pour une société, une pour chacun des deux actionnaires.
    `,
  },
  {
    name: ['numeraire-sarl'],
    content: `
    L’apport en numéraire représente une somme d’argent que les associés
          déposent lors de la constitution sur le compte bancaire de la société en
          création. Ces fonds contribueront à la constitution du capital de la
          société et donneront droit en échange à l’attribution de parts
          sociales aux associés proportionnellement au montant de leurs apports.
          La contrepartie comptable d’un apport en numéraire sera donc un actif
          financier (généralement un compte bancaire).
          <h4>Libération des apports en numéraire en société</h4>
          Les associés d’une société disposent donc de plusieurs options pour la
          libération des apports en numéraire qu’ils ont consentis. Ils peuvent
          : <br />
          &nbsp;&nbsp;• verser immédiatement (libérer intégralement) la totalité
          de l’apport en numéraire ;<br />
          &nbsp;&nbsp;• ne verser qu’une partie (20%) dans un premier temps puis
          verser le solde (80%) dans les 5 ans suivant l’immatriculation de la
          société (libération partielle).
          <br />
          <br />
          Attention : Lorsqu’une libération partielle est réalisée : <br />
          1. La société ne pourra pas bénéficier du taux réduit de l’impôt sur
          les sociétés de 15% ;<br />
          2. Les intérêts qui résultent de la rémunération d’apports en comptes
          courants consentis par les associés ne seront pas déductibles
          fiscalement.
          <h4>
            Où faut-il déposer les apports en numéraire de la société en création ?
          </h4>
          Les associés doivent déposer leurs apports en numéraire pour le compte
          de la société en formation : <br />
          &nbsp;&nbsp;• soit sur un compte bancaire bloqué ouvert au nom de la
          société ;
          <br />
          &nbsp;&nbsp;• soit à la caisse des dépôts et consignation ;<br />
          &nbsp;&nbsp;• soit chez un notaire.
          <h4>
            Comment débloquer les fonds apportés en numéraire au capital de la
            société ?
          </h4>
          Les fonds seront ensuite libérés sur présentation, par le gérant de la
          société, de l’extrait Kbis, afin de prouver que la société est
          immatriculée au registre du commerce et des sociétés. Si la société
          n’est pas constituée dans un délai de 6 mois suivant le dépôt des
          fonds, les associés ont la possibilité de demander en justice la
          récupération des apports qu’ils ont effectués.
          <h4>
            Existe-t-il des avantages fiscaux profitant aux associés apporteurs
            en numéraire ?
          </h4>
          Sous certaines conditions, les associés de société qui effectuent des
          apports en numéraire, peuvent être éligible une réduction d’impôts sur
          le revenu à hauteur de 25% des montants apportés.
    `,
  },
  {
    name: ['nature-sarl'],
    content: `Les associés peuvent faire des apports en nature et le capital social
    de la société pourrait être uniquement constitué par des apports en
    nature.
    <h4>Qu’est-ce qu’un apport en nature en société ?</h4>
    L’apport en nature correspond à un bien mobilier matériel (matériel,
    mobilier…) ou immatériel (logiciel, brevet, marque…) ou mobilier ou à
    des titres financiers. Il concourt, tout comme les apports en
    numéraire, à la formation du capital social. En contrepartie, et en
    proportion des apports effectués chaque associé, recevra donc des
    parts sociales. Tous les apports en nature réalisés sont mentionnés
    dans les statuts de la société, avec la description, l’évaluation de
    chacun de ces biens, l’identité des associés concernés par ces
    apports, et le nombre d’actions qui a été remis en échange.
    <h4>
      De quelles garanties une société bénéficie-t-elle quant aux apports en
      nature ?
    </h4>
    Les associés s’engagent à l’égard de la société à ce que les apports en
    nature soient : <br />
    &nbsp;&nbsp;• garantis contre vices cachés : La société doit pouvoir
    utiliser les biens apportés de façon normale ;<br />
    &nbsp;&nbsp;• garantis contre l’éviction : la société doit pouvoir
    disposer de manière paisible des biens apportés.
    <br />
    <br />
    En principe, lorsque le capital d’une société se compose d’apports en
    nature, l’intervention d’un commissaire aux apports est éxigée. Sa
    mission consiste à vérifier les valeurs attribuées aux apports. Il est
    nommé par les associés via une décision unanime. <br />
    A défaut, c’est le président du tribunal de commerce qui se charge de
    le désigner, à la demande d’un ou plusieurs associés. Par exception,
    les associés peuvent prendre la décision, à l’unanimité, de se
    dispenser du recours au commissaire aux apports si : <br />
    &nbsp;&nbsp;• aucun apport en nature ne vaut plus de 30 000 € ;<br />
    &nbsp;&nbsp;• la valeur de tous les apports en nature n’excède pas 50%
    du capital.
    <br />
    <br />
    Si les associés de la société décident, malgré l’évaluation faite par le
    commissaire aux apports, de retenir un montant différent, ils en
    demeurent solidairement responsables durant 5 ans. Attention, la
    surévaluation d’un apport en nature est un délit passible de 5 ans
    d’emprisonnement et de 9 000 € d’amende.
    <h4>
      Quelles sont les conditions de libération des apports en nature en
      société ?
    </h4>
    Contrairement aux apports en numéraire, les apports en nature doivent
    faire l’objet d’une libération intégrale dès leur souscription.
    <h4>Réalisation de l’apport en nature en société</h4>
    L’associé apporteur en nature doit conserver, en l’état, de l’apport
    et ce jusqu’à création de la société. A compter de son immatriculation
    au registre du commerce et des sociétés, la société dispose de la
    personnalité juridique, et devient donc propriétaire des actifs en
    nature apportés.
    `,
  },
  {
    name: ['industrie-sarl'],
    content: `Un apport en industrie constitue un apport immatériel non financier.
    <h4>Qu’est-ce qu’un apport en industrie en société ?</h4>
    L’apport en industrie se traduit par l’apport de connaissances
    techniques, de travail réalisés ou de services. L’apport en industrie
    ne concourt pas à la formation du capital social mais les associés
    recevront tout de même des actions en contrepartie de leur apport. Les
    actions reçues en contrepartie d’un apport en industrie ne peuvent
    jamais être cédées ou transmises. Elles lui permettront d’avoir droit
    aux dividendes, au partage de l’actif net et de participer aux
    décisions prises en assemblée générale.
    `,
  },
  {
    name: ['-sarl'],
    content: `
    `,
  },
  // END SARL

  // COMMON
  {
    name: ['date-sarl', 'date-eurl', 'date-sasu', 'date-sas'],
    content: `
      <h4>La date de début d’activité</h4>

      La date à retenir pour fixer le début d'activité la plus communément admise est celle qui va figurer sur le kbis suite au dépôt du dossier de constitution auprès du guichet unique de l'INPI.
      <br> <br>
      Cette date doit être postérieure à la date de signature des statuts.
      <br> <br>
      À noter : selon la jurisprudence du Conseil d'État (CE, arrêt du 12 janvier 1987, n° 46227) le redevable ne peut être réputé avoir commencé son activité qu'à la double condition d'avoir disposé d'immobilisations et d'avoir versé des salaires ou réalisé des recettes au cours de cette année.

      <h4>La date de clôture du premier exercice comptable</h4>
    
      Lors de la création de votre entreprise, il est nécessaire de fixer la date de clôture du premier exercice comptable. Cette date ne peut excéder le 31 décembre de l’année suivant celle de votre création. Par exemple si vous créez le 15 janvier N, la date limite de clôture du 1er exercice comptable sera le 31 décembre N+1.
      <br> <br>
      Ensuite, l’exercice sera clôturé par période de 12 mois à compter du lendemain de la date de clôture du premier exercice comptable.
      <br> <br>
      Même si de nombreuses entreprises choisissent le 31 décembre comme date de clôture, cette date est libre et peut être choisie en fonction de la saisonnalité de votre activité. En général, la date est fixée au 31 décembre, 31 mars, 30 juin, 30 septembre…
    `,
  },
  {
    name: ['capital-sarl', 'capital-eurl', 'capital-sasu', 'capital-sas'],
    content: `<h4>Quels apports contribue à la formation du capital ? </h4>
    Les fonds en numéraire contribueront à former le capital de la
    société, et ils donneront droit, en échange, à l’attribution de parts
    sociales. <br />
    L’apport en nature forme également le capital social de la société, et il
    ouvre droit à l’attribution de parts sociales.
    <br />
    <br />
    Attention :
    <br />
    <br />
    L’apport en industrie ne concourt pas à la formation du capital
    social, mais l’associé unique recevra tout de même des parts sociales
    en contrepartie de cet apport.
    `,
  },
  {
    name: [
      'legalActe-sarl',
      'legalActe-eurl',
      'legalActe-sasu',
      'legalActe-sas',
    ],
    content: ` Dès lors que vous avez accompli des actes préalablement à la
    constitution de votre société vous pouvez les faire reprendre à la
    création de façon à en informer les autres associés et les créanciers
    de l’entreprise. Cela permettra également de vous les faire rembourser
    et de les comptabiliser dans les comptes de votre société.
    `,
  },
  {
    name: ['cac-sarl', 'cac-eurl', 'cac-sasu', 'cac-sas'],
    content: `
    Lors de la création d&apos;une société commerciale, la nomination
    d&apos;un commissaire aux comptes (CAC) est elle obligatoire?
    <h4>Qu’est ce qu’un commissaire aux comptes ?</h4>
    Le commissaire aux comptes (CAC) réalise une mission d’audit externe
    indépendant qui le conduit à contrôler les comptes de son client, le
    respect des obligations légales, sociales et fiscales et la sécurité
    financière pour les tiers et les actionnaires et ce notamment au
    travers d’une approche par les risques et le contrôle interne.
    <br />
    <br />
    Il s’agit d’une mission d’intérêt général.
    <br />
    <br />
    La désignation d’un commissaire aux comptes par une société peut-être
    obligatoire ou facultative elle dépend du total du bilan, du chiffre
    d’affaires hors taxes et du nombre de salariés au cours de l’exercice.
    <h4>
      Quels sont les seuils de nomination obligatoire d’un commissaire aux
      comptes ?
    </h4>
    Quelle que soit la forme juridique, la nomination d'un commissaire aux comptes devient obligatoire 
    dès lors que 2 des 3 seuils suivants sont franchis : <br />
    <ul>
      <li>
        5 000 000 € de total bilan (somme de tous les actifs dans un bilan comptable)
      </li>
      <li>
        10 000 000 € de chiffre d'affaires HT
      </li>
      <li>
        50 salariés
      </li>
    </ul>
   
    En dessous de ces seuils la nomination d’un commissaire aux comptes
    reste facultative.
    <h4>
      Quelles sont les modalités de désignation d’un commissaire aux
      comptes ?
    </h4>
    En cas de franchissement des seuils au cours d&apos;un exercice, il
    n’est pas obligatoire de nommer un commissaire aux comptes pour
    l’exercice en cours mais pour l’exercice suivant.
    <br />
    <br />
    La désignation d’un commissaire aux comptes peut se faire
    <br />
    <br />
    lors d’une assemblée générale ordinaire (AGO) de fin d’exercice pour
    les SARL/EURL
    <br />
    par décision collective sur proposition du président en SAS/SASU
    <br />
    lors d’une assemblée générale ordinaire (AGO) sur proposition du CA ou
    du CS pour les SA
    <h4>Qu’est-ce qu’un suppléant ?</h4>
    En cas d’empêchement, le commissaire aux comptes titulaire peut être
    remplacé par un suppléant (son remplaçnat en quelque sorte). Si le
    commissaire aux comptes titulaire est une société avec plusieurs
    associés commissaires aux comptes, la nomination d’un suppléant n’est
    pas obligatoire. Si le commissaire aux comptes titulaire est une
    société mon associé ou un indépendant, la nomination d’un suppléant
    est obligatoire.
    <h4>Nomination en dehors des seuils ?</h4>
    Toute société peut nommer volontairement un commissaire aux comptes,
    parfois à la demande de certains financeurs ou de certains
    actionnaires Par ailleurs, la désignation d’un commissaire aux comptes
    est obligatoire, même si les seuils légaux ne sont pas atteints en cas
    de demande en justice :<br />
    <br />
    des associés minoritaires représentant au moins 10% du capital dans
    les SA, SCA, SAS, SARL; <br />
    par un associé d’une SNC
    <h4>
      Que se passe-t-il si la société passe sous les seuils obligatoires
      de nomination ?
    </h4>
    Le commissaire est en fonction durant toute la durée de son mandat
    lorsqu’il est nommé (6 ans ou 3 ans en cas de désignation volontaire
    en dessous des seuils, mission ALPE) et ce même si la société passe
    sous les seuils de nomination.
    <h4>
      Quelle sanction encouru en cas de non désignation d’un commissaire
      aux comptes après dépassement des seuils ?
    </h4>
    La non nomination d’un commissaire aux comptes entraîne la nullité des
    délibérations prises en Assemblée générale. Les dirigeants de la
    société encourent une sanction allant jusqu’à 2 ans d’emprisonnement
    et une amende de 30 000 €.
    `,
  },
  {
    name: ['regime-vat'],
    content: `
    Le régime réel normal est obligatoire dans le cas d’achat intracommunautaire (déclaration mensuelle).
    
    <h4>Le régime simplifié s’applique (déclaration annuelle) :</h4>
    <ul>
      <li>
        Entre 91 900 € et 840 000 € pour les activités de livraisons de biens, ventes à consommer sur place et prestations d'hébergement.
      </li>
      <li>
        Entre 36 800 € et 254 000 € pour les activités de prestations de service,
        vous devez aussi respecter un montant annuel de TVA inférieur à 15 000 €.
      </li>
    </ul>
    
    Le régime mini réel s’applique (déclaration trimestrielle) 80 000 et 763 000 euros pour les activités de ventes de biens, 
    32 000 et 230 000 euros pour les activités de prestations de services.
    `,
  },
  {
    name: [
      'connaitDepotDesFonds-sarl',
      'connaitDepotDesFonds-eurl',
      'connaitDepotDesFonds-sasu',
      'connaitDepotDesFonds-sas',
    ],
    content: `
    Le dépôt de capital pour la création d'une société est une étape obligatoire où les associés bloquent leurs apports 
    en numéraire respectifs, en amont de l’immatriculation. Ces fonds constituent le capital social de l'entreprise et 
    garantissent sa solvabilité initiale.
    <br><br>
    Pour effectuer ce dépôt, les associés peuvent utiliser leurs fonds propres ou 
    faire appel à des financements externes, comme un prêt bancaire par exemple. Le délai moyen pour cette opération 
    dépend principalement de la difficulté à obtenir votre premier rendez-vous avec un conseiller professionnel.
    <br><br> 
    De plus, la politique et les procédures de la banque retenue influencent également le délai d’obtention du 
    certificat de dépôt des fonds. Généralement, cela peut prendre entre quelques jours ouvrés et plusieurs quelques semaines.
    <br><br>
    Vous pouvez également faire appel à un notaire directement, 
    mais les frais sont plus élevés. Be Business Focus propose une offre incluant un dépôt des fonds avec un 
    notaire partenaire via un tarif préférentiel.
    `,
  },
  {
    name: ['-sarl', '-eurl', '-sasu', '-sas'],
    content: `
    `,
  },
];
