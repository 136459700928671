import { BASIC_INFO_OF_COMPANY } from 'constants/pages';
import { NO } from 'constants/usersContants';

const defaultForm = () => {
  return {
    gerantOuPresident: 'gérant',
    formStack: [],
    currentForm: BASIC_INFO_OF_COMPANY,
    dgPartnerIDs: [],
    partnerId: null,
    isReview: false,
    nom_propre: 'societe',
    alone: 'a_plusieurs',
    personne_physique_morale: 'personne_physique',
    nombre_de_personnes_physiques: '',
    nombre_de_personnes_morales: '',
    eurl_sasu: 'sci',
    choix_is: 'ir',
    sci: '',
    company_name: '',
    business_name: '',
    code_ape: '',
    address: '',
    additional_address: '',
    zipcode: '',
    city: '',
    siege_social_chez: 'chez_gerant',
    proprietaire: 'Proprietaire',
    maison_individuelle: 'oui',
    prenom_syndic: '',
    nom_syndic: '',
    adresse_syndic: '',
    prenom_bailleur: '',
    nom_bailleur: '',
    adresse_bailleur: '',
    date_bail: null,
    option_is: 'non',
    date_debut_activite: null,
    closing_date_with_year: null,
    companyType: 'sci',
    //   forme_juridique_personne_morale: '',
    //   capital_personne_morale: '',
    //   raison_sociale_actionnaire_personne_morale_sasu: '',
    //   adresse_actionnaire_personne_morale_sasu: '',
    //   code_postal_actionnaire_personne_morale_sasu: '',
    //   ville_actionnaire_personne_morale_sasu: '',
    //   rcs_actionnaire_personne_morale_sasu: '',
    //   ville_rcs_actionnaire_personne_morale_sasu: '',

    partners: [],

    //    infos sur les partenaires
    // partner_title: 'M.',
    // partner_name: '',
    // partner_firstname: '',
    // partner_father_name: '',
    // partner_father_firstname: '',
    // partner_mother_name: '',
    // partner_mother_firstname: '',
    // partner_date_of_birth: null,
    // partner_place_of_birth: '',
    // partner_state_of_birth: '',
    // partner_country_of_birth: '',
    // partner_nationality: '',
    // partner_place_of_living: '',
    // partner_city: '',
    // partner_zipcode: '',
    // partner_family_situation: '',
    // partner_wedding_prenup_type: '',
    // partner_spouse_name: '',
    // partner_spouse_firstname: '',
    // partner_spouse_date_naissance: '',
    // partner_spouse_lieu_naissance: '',
    // partner_spouse_departement_naissance: '',
    // partner_wedding_date: null,
    // partner_wedding_place: '',
    // partner_wedding_prenup: '',
    // partner_wedding_prenup_date: '',
    // partner_wedding_prenup_place: '',
    // partner_wedding_prenup_officer_office_name: '',
    // partner_wedding_prenup_officer_office_place: '',
    // pacs_spouse_name: '',
    // pacs_spouse_firstname: '',
    // pacs_spouse_date_naissance: null,
    // pacs_spouse_lieu_naissance: '',
    // pacs_spouse_departement_naissance: '',
    // pacs_date: null,
    // pacs_place: '',
    // pacs_type: '',

    partenaire_supplementaire: 'oui',
    capital_in_numbers: '',
    share_value: '',
    connait_depot_des_fonds: NO,
    depot_capital_chez: 'unknown',
    // depot_capital_chez: 'be_business_focus',
    date_depot_des_fonds: null,
    banking_corporation_name: '',
    adresse_agence_bancaire: '',
    ville_agence_bancaire: '',
    code_postal_agence_bancaire: '',
    cash_funds_numbers: '',
    apport_nature: 'non',
    supplies_funds_numbers: 0,
    apport_fonds_de_commerce: 'non',
    date_apport_fonds_de_commerce: null,
    valeur_apport_fonds_de_commerce: 0,
    apport_titres_societe: 'non',
    date_apport_titres_societe: null,
    valeur_apport_titres_societe: 0,
    raison_sociale_societe_apport_titres_societe: '',
    nombre_titres_apportes_apport_titres_societe: '',
    nombre_titres_total_apport_titres_societe: '',
    apport_nature_divers: 'non',
    date_apport_nature_divers: null,
    valeur_apport_nature_divers: 0,
    description_biens_apport_nature_divers: '',
    apport_industrie: 'non',
    expertise_funds_numbers: '',
    cash_funds_onetime_payment: 'oui',
    payments_amount: '',
    director_type: "l_associe_de_l'EURL",
    director_title: 'M.',
    director_name: '',
    director_firstname: '',
    director_father_name: '',
    director_father_firstname: '',
    director_mother_name: '',
    director_mother_firstname: '',
    director_date_of_birth: null,
    director_place_of_birth: '',
    director_state_of_birth: '',
    director_country_of_birth: '',
    director_nationality: '',
    director_place_of_living: '',

    associe_gerant_multiassocie: [],

    duree_gerance_determinee: 'non',
    duree_de_gerance: '0',
    preexisting_contract: 'non',
    number_of_preexisting_contract: 0,
    contract1_date: null,
    contract1_type: '',
    contract1_amount: '',
    contract1_person_who_paid: '',
    contract2_date: null,
    contract2_type: '',
    contract2_amount: '',
    contract2_person_who_paid: '',
    presence_commissaire_aux_comptes: 'non',
    duree_exercice_commissaire_aux_comptes: 3,
    titre_commissaire_aux_comptes: 'M.',
    nom_commissaire_aux_comptes: '',
    prenom_commissaire_aux_comptes: '',
    nom_cabinet_commissaire_aux_comptes: '',
    adresse_cabinet_commissaire_aux_comptes: '',
    cac_suppleant: 'oui',
    titre_suppleant: 'M.',
    nom_suppleant: '',
    prenom_suppleant: '',
    adresse_cabinet_suppleant: '',
    procuration: 'non',
    nom_cabinet_procuration: '',
    qualite_cabinet_procuration: '',
    adresse_cabinet_procuration: '',
    ville_cabinet_procuration: '',
    code_postal_cabinet_procuration: '',
    procu_bbf_dossier_constitution: 'oui',
    procu_bbf_attestation_beneficiaires: 'oui',
    procu_bbf_annonce_legale: 'oui',
    procu_bbf_attestation_depot_fonds: 'oui',
    procu_bbf_depot_greffe_dossier_constitution: 'oui',
    rcs_ville: '',
    presence_date_debut_activité: 'oui',
    type_depart_activite: 'immediatement',
    kit: '',
    liberationPartielle: NO,
    pourcentOfRelease: null,
    email_bailleur: '',
    bailleur_city: '',
    bailleur_zipcode: '',
    idGerantForDomiciliation: null,
  };
};

export default defaultForm;
