import { FormControlLabel, Radio } from '@material-ui/core';
import HelpIcon from 'components/commons/HelpIcon';
import { Field, useFormikContext } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import React from 'react';

function SmallRadio({ classes, title, options, value, name, style, helpName }) {
  const { touched, errors } = useFormikContext();

  return (
    <>
      <div className={classes.inputWithHelpIconContainer}>
        <h2 className={classes.radioTitle}>{title}</h2>
        {helpName && <HelpIcon helpName={helpName} />}
      </div>
      <Field
        component={RadioGroup}
        name={name}
        className={classes.smallRadioContainer}
        style={style}
      >
        {options.map((option) => (
          <FormControlLabel
            className={`${classes.smallRadioLabel} 
              ${value === option.value ? classes.selected : ''}
              ${option.disabled ? classes.radioDisabled : ''}
              ${touched[name] && errors[name] ? classes.radioError : ''}`}
            key={option.value}
            value={option.value}
            control={<Radio className={classes.radio} />}
            label={option.label.toUpperCase()}
            disabled={option.disabled}
          />
        ))}
      </Field>
    </>
  );
}

export default SmallRadio;
