import { NO } from 'constants/usersContants';

const prepareData = (values) => {
  const form = { ...values };

  if (form.presence_dg === NO) {
    form.dgPartnerIDs = [];
    form.dg_type = null;
    form.dg_title = '';
    form.dg_name = '';
    form.dg_firstname = '';
    form.dg_email = '';
    form.dg_date_of_birth = null;
    form.dg_place_of_birth = '';
    form.dg_state_of_birth = '';
    form.dg_country_of_birth = '';
    form.dg_nationality = '';
    form.dg_place_of_living = '';
    form.dg_city = '';
    form.dg_zipcode = '';
    form.dg_father_name = '';
    form.dg_father_firstname = '';
    form.dg_mother_name = '';
    form.dg_mother_firstname = '';
    form.dg_secu_number = '';
  }
  form.dg_address = form.dg_place_of_living;
  return form;
};

export default prepareData;
