import { NAMED, PARTNERS, BBF, CLIENT, DIRECTORS } from './kitConstants';

const basiquesocDocs = [
  { title: 'Statuts', source: null, signBy: null, recipients: [] },
  {
    title: 'Attestation de non condamnation de %s',
    source: null,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Courrier avis de constitution',
    source: null,
    signBy: null,
    recipients: [],
  },
  { title: 'MO', source: BBF, signBy: null, recipients: [] },
  {
    title: "Pièce d'identité de %s",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: "Attestation d'assurance professionnelle",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Capacité à exercer la profession ',
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
];

const kit1socDocs = [
  {
    title: 'Procuration donnée à BBF',
    source: null,
    signBy: DIRECTORS,
    recipients: [],
  },
  { title: 'Statuts', source: null, signBy: PARTNERS, recipients: [] },
  {
    title: 'Attestation de non condamnation de %s',
    source: null,
    signBy: NAMED,
    recipients: [],
  },
  {
    title: 'Attestation de domiciliation',
    source: null,
    signBy: DIRECTORS,
    recipients: [],
  },
  {
    title: 'Courrier avis de constitution',
    source: null,
    signBy: null,
    recipients: [],
  },
  { title: 'MO', source: BBF, signBy: DIRECTORS, recipients: [] },
  {
    title: "Pièce d'identité de %s",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: "Attestation d'assurance professionnelle",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Capacité à exercer la profession ',
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: "Avis d'annonce légale",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Attestation de dépôt des fonds',
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
];

const kit2socDocs = [
  { title: 'Statuts', source: null, signBy: PARTNERS, recipients: [] },
  {
    title: 'Procuration donnée à BBF',
    source: null,
    signBy: DIRECTORS,
    recipients: [],
  },
  {
    title: 'Attestation de domiciliation',
    source: null,
    signBy: DIRECTORS,
    recipients: [],
  },
  {
    title: 'Procuration au notaire',
    source: null,
    signBy: null,
    recipients: [],
  },
  {
    title: "Avis d'annonce légale",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Attestation de dépôt des fonds',
    source: BBF,
    signBy: null,
    recipients: [],
  },
  {
    title: "Modèle d'attestation de dépôt des fonds",
    source: null,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Attestation de non condamnation de %s',
    source: null,
    signBy: NAMED,
    recipients: [],
  },
  {
    title: 'Courrier avis de constitution',
    source: null,
    signBy: null,
    recipients: [],
  },
  { title: 'MO', source: BBF, signBy: DIRECTORS, recipients: [] },
  {
    title: "Pièce d'identité de %s",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: "Pièce d'identité du dirigeant si non associe",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: "Attestation d'assurance professionnelle",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Capacité à exercer la profession ',
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
];

const kit3socDocs = [
  { title: 'Statuts', source: null, signBy: PARTNERS, recipients: [] },
  {
    title: 'Procuration donnée à BBF',
    source: null,
    signBy: DIRECTORS,
    recipients: [],
  },
  {
    title: 'Attestation de domiciliation',
    source: null,
    signBy: DIRECTORS,
    recipients: [],
  },
  {
    title: 'Procuration au notaire',
    source: null,
    signBy: null,
    recipients: [],
  },
  {
    title: "Avis d'annonce légale",
    source: BBF,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Attestation de dépôt des fonds',
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Attestation de non condamnation de %s',
    source: null,
    signBy: NAMED,
    recipients: [],
  },
  {
    title: 'Courrier avis de constitution',
    source: null,
    signBy: null,
    recipients: [],
  },
  { title: 'MO', source: BBF, signBy: DIRECTORS, recipients: [] },
  {
    title: "Pièce d'identité de %s",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: "Pièce d'identité du dirigeant si non associe",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: "Attestation d'assurance professionnelle",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Capacité à exercer la profession ',
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
];

const kit4socDocs = [
  { title: 'Statuts', source: null, signBy: PARTNERS, recipients: [] },
  {
    title: 'Procuration donnée à BBF',
    source: null,
    signBy: DIRECTORS,
    recipients: [],
  },
  {
    title: 'Attestation de domiciliation',
    source: null,
    signBy: DIRECTORS,
    recipients: [],
  },
  {
    title: 'Procuration au notaire',
    source: null,
    signBy: null,
    recipients: [],
  },
  {
    title: "Avis d'annonce légale",
    source: BBF,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Attestation de dépôt des fonds',
    source: BBF,
    signBy: null,
    recipients: [],
  },
  {
    title: "Modèle d'attestation de dépôt des fonds",
    source: null,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Attestation de non condamnation de %s',
    source: null,
    signBy: NAMED,
    recipients: [],
  },
  {
    title: 'Courrier avis de constitution',
    source: null,
    signBy: null,
    recipients: [],
  },
  { title: 'MO', source: BBF, signBy: DIRECTORS, recipients: [] },
  {
    title: "Pièce d'identité de %s",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: "Pièce d'identité du dirigeant si non associe",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: "Attestation d'assurance professionnelle",
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
  {
    title: 'Capacité à exercer la profession ',
    source: CLIENT,
    signBy: null,
    recipients: [],
  },
];

export { basiquesocDocs, kit1socDocs, kit2socDocs, kit3socDocs, kit4socDocs };
