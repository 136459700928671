import { NO } from 'constants/usersContants';

const initialValues = (values) => {
  console.log('values.type_CAC', values.type_CAC);
  return {
    presence_commissaire_aux_comptes:
      values.presence_commissaire_aux_comptes || NO,
    duree_exercice_commissaire_aux_comptes:
      values.duree_exercice_commissaire_aux_comptes.toString() || '3',
    type_CAC: values.type_CAC || '',

    // IF has CAC and is PP
    titre_commissaire_aux_comptes: values.titre_commissaire_aux_comptes || '',
    nom_commissaire_aux_comptes: values.nom_commissaire_aux_comptes || '',
    prenom_commissaire_aux_comptes: values.prenom_commissaire_aux_comptes || '',

    // IF has CAC and is PM
    forme_juridique_cabinet_CAC: values.forme_juridique_cabinet_CAC || '',

    // IF has CAC
    adresse_cabinet_commissaire_aux_comptes:
      values.adresse_cabinet_commissaire_aux_comptes || '',
    city_cabinet_commissaire_aux_comptes:
      values.city_cabinet_commissaire_aux_comptes || '',
    zipCode_cabinet_commissaire_aux_comptes:
      values.zipCode_cabinet_commissaire_aux_comptes || '',
    type_CAC_suppleant: values.type_CAC_suppleant || '',

    // IF suppleant is PP
    titre_suppleant: values.titre_suppleant || '',
    nom_suppleant: values.nom_suppleant || '',
    prenom_suppleant: values.prenom_suppleant || '',
    adresse_cabinet_suppleant: values.adresse_cabinet_suppleant || '',
    city_cabinet_suppleant: values.city_cabinet_suppleant || '',
    zipCode_cabinet_suppleant: values.zipCode_cabinet_suppleant || '',

    // IF suppleant is PM
    cac_suppleant: values.cac_suppleant || '',

    nom_cabinet_commissaire_aux_comptes:
      values.nom_cabinet_commissaire_aux_comptes || '',
  };
};

// eslint-disable-next-line
export { initialValues };
