import React, { useContext } from 'react';
import { DispatchContext, FormContext } from 'contexts/sci-partners.context';
import CreateCompanySCIContainer from './CreateCompanySCIContainer';

function CreateSCIContainer() {
  const formulaireComplet = useContext(FormContext);
  const dispatch = useContext(DispatchContext);
  return (
    <CreateCompanySCIContainer
      formulaireComplet={formulaireComplet}
      dispatch={dispatch}
    />
  );
}

export default CreateSCIContainer;
