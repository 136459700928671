import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

import { RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';

// import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const ChoixCommissaireAuxComptes = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const mention = `Je soussigné – ${formValues.president.present_titre} ${formValues.president.present_prenom} ${formValues.president.present_nom} président
   de l’association ${formValues.company_name} sise  ${formValues.address} ${formValues.zipcode} ${formValues.city} donne pouvoir à Be Business Focus pour réaliser : `;

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">Procuration BBF</Typography>

              <Typography variant="subtitle1">
                {/* {' '}
                {formValues.director_title} {formValues.director_firstname}{' '}
                {formValues.name} donne pouvoir à BBF pour : */}
                {mention}
              </Typography>

              <Typography variant="subtitle1">
                Le dossier de constitution de l&apos;association
              </Typography>
              <Field
                component={RadioGroup}
                name="procu_bbf_dossier_constitution"
              >
                {/* FAIRE LES DIFFERENTS RADIO POUR CHAQUE POUVOIR DONNE A BBF */}
                <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="non" control={<Radio />} label="Non" />
              </Field>

              <Typography variant="subtitle1">
                Le PV d&apos;assemblée générale constitutive
              </Typography>
              <Field
                component={RadioGroup}
                name="procu_bbf_pv_assemblee_generale_constitutive"
              >
                {/* FAIRE LES DIFFERENTS RADIO POUR CHAQUE POUVOIR DONNE A BBF */}
                <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="non" control={<Radio />} label="Non" />
              </Field>

              <Typography variant="subtitle1">L&apos;annonce légale</Typography>
              <Field component={RadioGroup} name="procu_bbf_annonce_legale">
                {/* FAIRE LES DIFFERENTS RADIO POUR CHAQUE POUVOIR DONNE A BBF */}
                <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="non" control={<Radio />} label="Non" />
              </Field>

              <Typography variant="subtitle1">
                Le dépôt à la préfecture du dossier de constitution
              </Typography>
              <Field
                component={RadioGroup}
                name="procu_bbf_depot_prefecture_dossier_constitution"
              >
                {/* FAIRE LES DIFFERENTS RADIO POUR CHAQUE POUVOIR DONNE A BBF */}
                <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="non" control={<Radio />} label="Non" />
              </Field>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixCommissaireAuxComptes.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixCommissaireAuxComptes;
