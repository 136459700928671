import { Button, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Backup } from '@material-ui/icons';
import { saveCompanyDataAndPassToNextState } from 'actions/monCompteActions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DocUploadedActions = ({
  validation = true,
  validationMessage = null,
}) => {
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();

  const [validationSnackbarVisible, setValidationSnackbarVisible] =
    useState(false);

  const { company } = useSelector((state) => state.monCompte);
  const { companyId, stateOfClientFolder } = company;

  const checkFilesUploaded = () => {
    return true;
  };

  const validateUploadDocs = () => {
    if (!validation && validationMessage) {
      return setValidationSnackbarVisible(true);
    }

    if (!checkFilesUploaded) {
      return setShowError(true);
    }
    const newState = {
      ...stateOfClientFolder,
      generateDocsValidate: true,
      stepper: 2,
    };
    return dispatch(
      saveCompanyDataAndPassToNextState(
        { ...company, stateOfClientFolder: newState },
        companyId,
        true
      )
    );
  };

  const renderValidationSnackbar = () => (
    <Snackbar
      open={validationSnackbarVisible}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      onClose={() => setValidationSnackbarVisible(false)}
    >
      <Alert
        onClose={() => setValidationSnackbarVisible(false)}
        severity="error"
      >
        {validationMessage}
      </Alert>
    </Snackbar>
  );

  return (
    <>
      {stateOfClientFolder.stepper === 1 && (
        <>
          {showError && (
            <span style={{ color: 'red' }}>
              Tous les documents obligatoires ne sont pas uploadés
            </span>
          )}
          <div style={{ marginLeft: 18 }}>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              style={{ fontStyle: 'italic' }}
              color={'error'}
            >
              <Backup style={{ fontSize: 10 }} />
              <span style={{ marginLeft: 10 }}>
                Taille maximale des fichiers 1 Mo
              </span>
            </Typography>
          </div>
          {!validation && validationMessage && renderValidationSnackbar()}
          <Button
            variant="contained"
            color="primary"
            onClick={validateUploadDocs}
            style={{ width: '100%' }}
          >
            Valider le téléchargement des documents
          </Button>
        </>
      )}
    </>
  );
};

export default DocUploadedActions;
