import FormActions from 'components/entreprise/V2/FormActions';
import { Formik } from 'formik';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import UserInfo from '.';
import { initialValues } from './initValues';
import prepareData from './prepareData';
import { validationSchema } from './validation';

function UserInfoContainer({ goBack, saveAndNext, formulaireComplet }) {
  const form = { ...formulaireComplet };
  const { currentForm, partnerId, partners, eurl_sasu } = form;
  let currentPartner = {};
  if (partnerId) {
    currentPartner = partners.find((partner) => partner.id === partnerId);
  }

  return (
    <Formik
      initialValues={initialValues(currentPartner, eurl_sasu)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const partnerData = prepareData(values);
        if (partnerId) {
          const partnerIndex = partners.findIndex(
            (partner) => partner.id === partnerId
          );
          const updatedPartner = { ...currentPartner, ...partnerData };
          form.partners[partnerIndex] = updatedPartner;
        } else {
          const id = uuidv4();
          partnerData.id = id;
          form.partnerId = id;
          form.partners.push(partnerData);
        }
        setSubmitting(false);
        saveAndNext(form);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <UserInfo />
          <FormActions goBack={goBack} currentForm={currentForm} />
        </form>
      )}
    </Formik>
  );
}

export default UserInfoContainer;
