import * as Yup from 'yup';
// import { format } from 'date-fns';

const validationSchema = () => {
  return Yup.object().shape({
    eurl_sasu: Yup.string().required('Champs requis'),
  });
};

//  eslint-disable-next-line
export { validationSchema };
