import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import {
  EURL,
  SARL,
  SARL_EURL,
  SAS,
  SASU,
  SAS_SASU,
} from 'constants/companies';
import { getTitle } from 'helpers/generalHelpers';
import { BBF_NOTARIE, YES } from 'constants/usersContants';
import CustomRadio from '../commons/typeCompanyRadio';
import SwitchKit from '../switchKit/SwitchKit';
import prepareData from './prepareData';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
  },
  title: {
    color: '#3D319D',
    marginBottom: '10%',
    textTransform: 'uppercase',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
  },
  radioContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '1rem',
  },
  radioWrapper: {
    flex: 1,
    padding: '2%',
    display: 'flex',
    height: '200px',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '15px',
    border: '1px solid #8E8FA1',
    position: 'relative',
  },
  radioWrapperTitle: {
    position: 'absolute',
    fontSize: '1em',
    textTransform: 'uppercase',
    top: -20,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: 'white',
    color: '#8E8FA1',
    zIndex: 10,
  },
  radioLabel: {
    margin: 0,
    padding: '5%',
    height: '100px',
    width: '100px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    color: '#3D319D',
    fontWeight: 'bold',
    border: '2px solid #3D319D',
    '&:hover': {
      backgroundColor: '#3D319D',
      color: 'white',
    },
  },
  radioError: {
    borderColor: 'red !important',
    color: 'red',
  },
  selected: {
    backgroundColor: '#3D319D',
    color: 'white',
  },
  radio: {
    display: 'none',
  },
});

const TypeOfCompany = ({ values, formulaireComplet, saveAndNext }) => {
  const { setFieldValue, submitForm } = useFormikContext();
  const [showSwitchKit, setShowSwitchKit] = useState(true);
  const classes = useStyles();
  const { eurl_sasu } = values;
  const query = new URLSearchParams(window.location.search);
  const companyType = query.get('type') || query.get('companyType') || '';
  const planId = query.get('planId') || '';
  const [companiesAlone, setCompaniesAlone] = useState([EURL, SASU]);
  const [companiesMulti, setCompaniesMulti] = useState([SAS, SARL]);

  const setCompangy = () => {
    switch (companyType) {
      case SAS:
        setFieldValue('eurl_sasu', SAS);
        break;
      case SASU:
        setFieldValue('eurl_sasu', SASU);
        break;
      case SARL:
        setFieldValue('eurl_sasu', SARL);
        break;
      case EURL:
        setFieldValue('eurl_sasu', EURL);
        break;
      case SAS_SASU:
        setCompaniesAlone([SASU]);
        setCompaniesMulti([SAS]);
        break;
      case SARL_EURL:
        setCompaniesAlone([EURL]);
        setCompaniesMulti([SARL]);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    setCompangy();
  }, []);

  const confirOrSwitchKit = (updatedKit) => {
    if (updatedKit.includes('kit4')) {
      setFieldValue('depot_capital_chez', BBF_NOTARIE);
      setFieldValue('connait_depot_des_fonds', YES);
    }
    setFieldValue('kit', updatedKit);
    if (eurl_sasu !== '') submitForm();
  };

  useEffect(() => {
    if (!companyType && !planId) return;
    setFieldValue('eurl_sasu', companyType);
    confirOrSwitchKit(planId);
    const data = prepareData(values);
    saveAndNext(data);
  }, [companyType, planId, values]);

  return (
    <div className={classes.container}>
      {!planId && (
        <>
          <h1 className={classes.title}>{getTitle()}</h1>
          <div className={classes.root}>
            <Field
              component={RadioGroup}
              name="eurl_sasu"
              className={classes.radioContainer}
            >
              <div className={classes.radioWrapper}>
                <CustomRadio
                  classes={classes}
                  title="Seul"
                  options={companiesAlone}
                  value={eurl_sasu}
                  name="eurl_sasu"
                />
              </div>
              <div className={classes.radioWrapper}>
                <CustomRadio
                  classes={classes}
                  title="à plusieurs"
                  options={companiesMulti}
                  value={eurl_sasu}
                  name="eurl_sasu"
                />
              </div>
            </Field>
          </div>
          <SwitchKit
            open={showSwitchKit}
            handleClose={() => {
              setShowSwitchKit(false);
            }}
            confirmKit={confirOrSwitchKit}
            isRecap={false}
            formulaireComplet={formulaireComplet}
          />
        </>
      )}
    </div>
  );
};

export default TypeOfCompany;
