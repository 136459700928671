import { EURL, SARL, SAS, SASU, SCI } from 'constants/companies';
import { NO, YES, yesNo } from 'constants/usersContants';
import { FormContext } from 'contexts/partners.context';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useContext } from 'react';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';

function PartnerReleaseOfCash({ currentPartner }) {
  const classes = useStyles();
  const formulaireComplet = useContext(FormContext);
  const { eurl_sasu, cash_funds_numbers } = formulaireComplet;
  const { values } = useFormikContext();
  const { partner_liberation_partielle, cash_funds_onetime_payment } = values;

  const calculateMinAmount = () => {
    if (eurl_sasu === SAS) {
      return currentPartner.partner_cash_funds_numbers / 2;
    }
    if (eurl_sasu === SARL || eurl_sasu === SCI) {
      return currentPartner.partner_cash_funds_numbers / 5;
    }
    if (eurl_sasu === SASU) {
      return cash_funds_numbers / 2;
    }
    return cash_funds_numbers / 5;
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        {eurl_sasu === EURL || eurl_sasu === SASU
          ? 'Libération intégrale ou partielle'
          : `Libération de l'apport en numéraire par
        ${currentPartner.partner_title} ${currentPartner.partner_name} ${currentPartner.partner_firstname}`}
      </h1>
      <div className={classes.root}>
        {(eurl_sasu === EURL || eurl_sasu === SASU) && (
          <>
            <SmallRadio
              classes={classes}
              title="Voulez-vous libérer la totalité de vos apports en numéraire ?"
              options={yesNo}
              value={cash_funds_onetime_payment}
              name="cash_funds_onetime_payment"
              style={{ marginBottom: '4%' }}
            />
            {NO === cash_funds_onetime_payment && (
              <Field
                width="100%"
                component={TextField}
                type="number"
                label={`Montant à libérer en chiffre (le montant minimum à libérer est de ${calculateMinAmount()}€ )`}
                name="payments_amount"
                className={classes.nameTextField}
                autoComplete="off"
                variant="outlined"
                size="small"
              />
            )}
          </>
        )}
        {(eurl_sasu === SARL || eurl_sasu === SAS || eurl_sasu === SCI) && (
          <>
            <SmallRadio
              classes={classes}
              title="Y a-t-il une libération partielle de l’apport en numéraire ?"
              options={yesNo}
              value={partner_liberation_partielle}
              name="partner_liberation_partielle"
              style={{ marginBottom: '4%' }}
            />
            {YES === partner_liberation_partielle && (
              <Field
                width="100%"
                component={TextField}
                type="number"
                label={`Montant à libérer en chiffre (le montant minimum à libérer est de ${calculateMinAmount()}€ )`}
                name="partner_montant_liberation_partielle"
                className={classes.nameTextField}
                autoComplete="off"
                variant="outlined"
                size="small"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default PartnerReleaseOfCash;
