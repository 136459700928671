/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import {
  FormControlLabel,
  Radio,
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { Autocomplete as AutocompleteMUI } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField, RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardDatePicker from 'components/KeyboardDatePicker';

import { format } from 'date-fns';
import * as Yup from 'yup';
import { Autocomplete } from 'formik-material-ui-lab';

import theme from '../../assets/jss/theme';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import {
  ADD_PARTNER,
  EDIT_PARTNER,
  DELETE_PM_VALUES,
  DELETE_PP_VALUES,
} from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const countryList = require('../../assets/data/countryList.json');

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const SignupSchema = Yup.object().shape({
  partner_title: Yup.string().required('champs requis'),
  partner_name: Yup.string().required('champs requis'),
  partner_firstname: Yup.string().required('champs requis'),
  partner_father_name: Yup.string().required('champs requis'),
  partner_father_firstname: Yup.string().required('champs requis'),
  partner_mother_name: Yup.string().required('champs requis'),
  partner_mother_firstname: Yup.string().required('champs requis'),
  partner_date_of_birth: Yup.date()
    .max(
      format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      "la date de naissance doit être antérieure a la date d'aujourd'hui"
    )
    .required('un choix est necessaire'),
  partner_place_of_birth: Yup.string().required('champs requis'),
  partner_state_of_birth: Yup.string().required('champs requis'),
  partner_country_of_birth: Yup.string().required('champs requis'),
  partner_nationality: Yup.string().required('champs requis'),
  partner_place_of_living: Yup.string().required('champs requis'),
  partner_city: Yup.string().required('champs requis'),
  partner_zipcode: Yup.string().required('champs requis'),
  companyType: Yup.string(),
  partner_email: Yup.string().when('companyType', {
    is: (companyType) => companyType === 'eurl' || companyType === 'sasu',
    then: Yup.string().required('champs requis'),
  }),
});

const InfoPartenaire = ({
  partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  setAutomateState,
  isRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(1);
  }, []);

  let partnerOrShareholderLabel = "sur l'associé de l'EURL";
  let partnerOrShareholderCourtLabel = "de l'associé";

  if (
    formValues.eurl_sasu === 'eurl' &&
    partner.personne_physique_morale === 'personne_morale'
  ) {
    partnerOrShareholderLabel =
      "sur le dirigeant de la personne morale associée de l'EURL";
    partnerOrShareholderCourtLabel = 'du dirigeant ';
  } else if (
    formValues.eurl_sasu === 'sasu' &&
    partner.personne_physique_morale === 'personne_morale'
  ) {
    partnerOrShareholderLabel =
      'sur le dirigeant de la personne morale actionnaire de la SASU';
    partnerOrShareholderCourtLabel = 'du dirigeant';
  } else if (formValues.eurl_sasu === 'sasu') {
    partnerOrShareholderLabel =
      "sur l'actionnaire personne physique de la SASU";
    partnerOrShareholderCourtLabel = "de l'actionnaire";
  } else if (
    formValues.eurl_sasu === 'sas' &&
    partner.personne_physique_morale === 'personne_morale'
  ) {
    partnerOrShareholderLabel =
      'sur le dirigeant de la personne morale actionnaire de la SAS';
    partnerOrShareholderCourtLabel = 'du dirigeant';
  } else if (formValues.eurl_sasu === 'sas') {
    partnerOrShareholderLabel = "sur l'actionnaire personne physique de la SAS";
    partnerOrShareholderCourtLabel = "de l'actionnaire";
  } else if (
    formValues.eurl_sasu === 'sarl' &&
    partner.personne_physique_morale === 'personne_morale'
  ) {
    partnerOrShareholderLabel =
      'sur le dirigeant de la personne morale associée de la SARL';
    partnerOrShareholderCourtLabel = 'du dirigeant ';
  } else if (formValues.eurl_sasu === 'sarl') {
    partnerOrShareholderLabel = "sur l'associé personne physique de la SARL";
    partnerOrShareholderCourtLabel = "de l'associé ";
  }

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [cityValue, setcityValue] = useState(null);
  const [cityInput, setCityInput] = useState(null);
  const [loadingCityList, setLoadingCityList] = useState(false);

  const addressInputDebounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  const cityInputDebounce = useRef(
    _.debounce((newValue) => setCityInput(newValue), 500)
  );

  useEffect(() => {
    if (cityInput) {
      setLoadingCityList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: cityInput, type: 'municipality', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setCityList(data.features);
          } else {
            setCityList([]);
          }
          setLoadingCityList(false);
        });
    } else {
      setCityList([]);
      setLoadingCityList(false);
    }
  }, [cityInput]);

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    addressInputDebounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue('partner_place_of_living', newValue.properties.name);
      if (newValue.properties.postcode && newValue.properties.city) {
        setFieldValue('partner_zipcode', newValue.properties.postcode);
        setFieldValue('partner_city', newValue.properties.city);
      }
    } else {
      setFieldValue('partner_place_of_living', '');
      setFieldValue('partner_zipcode', '');
      setFieldValue('partner_city', '');
      setAddressValue(newValue);
    }
  };

  const handleFetchCity = async (newInputValue) => {
    setLoadingCityList(true);
    setCityInput(null);
    cityInputDebounce.current(newInputValue);
  };

  const handleSelectCity = async (newValue, setFieldValue) => {
    if (newValue) {
      setcityValue(newValue);
      setFieldValue('partner_place_of_birth', newValue.properties.name);
      if (newValue.properties.context) {
        const { context } = newValue.properties;
        const contextArr = context.split(', ');
        const state = contextArr[contextArr.length - 2];
        setFieldValue('partner_state_of_birth', state);
      }
    } else {
      setFieldValue('partner_place_of_birth', '');
      setFieldValue('partner_state_of_birth', '');
      setcityValue(newValue);
    }
  };

  let associeActionnaire = "l'associé";
  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    associeActionnaire = "l'actionnaire";
  }

  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        const type = values.id ? EDIT_PARTNER : ADD_PARTNER;

        dispatch({
          type,
          payload: values,
        });
        setSubmitting(false);

        if (partner.personne_physique_morale === 'personne_physique') {
          dispatch({
            type: DELETE_PM_VALUES,
            payload: values,
          });
        }
        if (partner.personne_physique_morale === 'personne_morale') {
          dispatch({
            type: DELETE_PP_VALUES,
            payload: values,
          });
        }

        if (
          isRecap &&
          partner.personne_physique_morale === 'personne_morale' &&
          formValues.alone === 'a_plusieurs'
        ) {
          Automate.nextInfoPartenaireVersInfoApportsPartenaire();
          setAutomateState(Automate.state);
        } else if (
          isRecap &&
          partner.personne_physique_morale === 'personne_physique' &&
          formValues.alone === 'a_plusieurs'
        ) {
          Automate.nextInfoPartenaireVersChoixSituationFamiliale();
          setAutomateState(Automate.state);
        } else if (
          isRecap &&
          partner.personne_physique_morale === 'personne_morale' &&
          formValues.alone === 'seul'
        ) {
          Automate.recapInfoPartenaireVersListeFin();
          setAutomateState(Automate.state);
        } else if (
          partner.personne_physique_morale === 'personne_morale' &&
          formValues.alone === 'a_plusieurs'
        ) {
          Automate.nextInfoPartenaireVersInfoApportsPartenaire();
        } else if (
          partner.personne_physique_morale === 'personne_morale' &&
          formValues.alone === 'seul'
        ) {
          Automate.nextInfoPartenaireVersChoixGerant();
        } else if (partner.personne_physique_morale === 'personne_physique') {
          Automate.nextInfoPartenaireVersChoixSituationFamiliale();
        }

        setActiveStep(activeStep + 1);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => {
        if (!values.companyType) {
          setFieldValue('companyType', formValues.eurl_sasu);
        }
        return (
          <Form fullwidth="true">
            <Paper>
              <Box
                display="flex"
                p={1}
                bgcolor="background.paper"
                className={classes.root}
                fullwidth="true"
              >
                <Box>
                  <Field
                    component={RadioGroup}
                    defaultValue="M."
                    name="partner_title"
                  >
                    <Typography variant="h5">
                      informations {partnerOrShareholderLabel}
                    </Typography>
                    <FormControlLabel
                      value="M."
                      control={<Radio />}
                      label="M."
                    />
                    <FormControlLabel
                      value="Mme"
                      control={<Radio />}
                      label="Mme"
                    />
                  </Field>
                </Box>

                <Box marginTop={theme.spacing(0.25)}>
                  <Box>
                    <Field
                      component={TextField}
                      type="string"
                      label={`Nom  ${partnerOrShareholderCourtLabel}`}
                      name="partner_name"
                      className={classes.textField}
                      autoComplete="off"
                    />
                  </Box>

                  <Field
                    component={TextField}
                    type="string"
                    label={`Prénom  ${partnerOrShareholderCourtLabel}`}
                    name="partner_firstname"
                    className={classes.textField}
                    autoComplete="off"
                  />
                </Box>
                <Box marginTop={theme.spacing(0.25)}>
                  <Field
                    component={TextField}
                    type="string"
                    label={`Nom du père  ${partnerOrShareholderCourtLabel}`}
                    name="partner_father_name"
                    className={classes.textField}
                    autoComplete="off"
                  />

                  <Field
                    component={TextField}
                    type="string"
                    label={`Prénom du père  ${partnerOrShareholderCourtLabel}`}
                    name="partner_father_firstname"
                    className={classes.textField}
                    autoComplete="off"
                  />
                </Box>

                <Box marginTop={theme.spacing(0.25)}>
                  <Field
                    component={TextField}
                    fullwidth="true"
                    type="string"
                    label={`Nom de naissance de la mère  ${partnerOrShareholderCourtLabel}`}
                    name="partner_mother_name"
                    className={classes.textField}
                    autoComplete="off"
                  />

                  <Field
                    component={TextField}
                    type="string"
                    label={`Prénom de la mère  ${partnerOrShareholderCourtLabel}`}
                    name="partner_mother_firstname"
                    className={classes.textField}
                    autoComplete="off"
                  />
                </Box>

                <Box marginTop={theme.spacing(0.25)} className={classes.root}>
                  <Field
                    component={KeyboardDatePicker}
                    label={`Date de naissance  ${partnerOrShareholderCourtLabel}`}
                    maxDate={new Date()}
                    name="partner_date_of_birth"
                    clearable={true}
                    placeholder="24/09/1988"
                    className={classes.birthdayField}
                    format="dd/MM/yyyy"
                    autoComplete="off"
                  />

                  <Field
                    component={AutocompleteMUI}
                    name="partner_place_of_birth"
                    className={classes.textField}
                    value={cityValue}
                    loading={loadingCityList}
                    options={cityList}
                    filterOptions={(x) => x}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    getOptionLabel={(option) =>
                      option && option.properties && option.properties.name
                    }
                    onInputChange={(event, newInputValue) =>
                      handleFetchCity(newInputValue)
                    }
                    onChange={(event, newValue) =>
                      handleSelectCity(newValue, setFieldValue)
                    }
                    renderInput={(params) => (
                      <MUITextField
                        {...params}
                        error={
                          touched.partner_place_of_birth &&
                          !!errors.partner_place_of_birth
                        }
                        helperText={
                          touched.partner_place_of_birth &&
                          errors.partner_place_of_birth
                        }
                        label={`Ville de naissance  ${partnerOrShareholderCourtLabel}`}
                        placeholder={partner.partner_place_of_birth}
                        size="small"
                      />
                    )}
                    renderOption={(option) => {
                      return (
                        <Grid container alignItems="center">
                          <Grid item>
                            <LocationOnIcon />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body2" color="textSecondary">
                              {option.properties.label}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                </Box>
                <Box>
                  <Field
                    component={TextField}
                    type="string"
                    label={`Département de naissance  ${partnerOrShareholderCourtLabel}`}
                    name="partner_state_of_birth"
                    placeholder="ex: Paris 11eme arrondissement"
                    className={classes.textField}
                    autoComplete="off"
                    value={
                      !values.partner_state_of_birth
                        ? ''
                        : values.partner_state_of_birth
                    }
                  />
                </Box>

                <Box marginTop={theme.spacing(0.25)}>
                  <Field
                    component={TextField}
                    type="string"
                    label={`Pays de naissance  ${partnerOrShareholderCourtLabel}`}
                    name="partner_country_of_birth"
                    className={classes.textField}
                    autoComplete="off"
                  />

                  <Field
                    component={TextField}
                    type="string"
                    label={`Nationalité  ${partnerOrShareholderCourtLabel}`}
                    name="partner_nationality"
                    className={classes.textField}
                    autoComplete="off"
                  />
                </Box>
                <Box marginTop={theme.spacing(0.25)}>
                  <Field
                    component={AutocompleteMUI}
                    name="partner_place_of_living"
                    className={classes.nameTextField}
                    value={addressValue}
                    loading={loadingAddressList}
                    options={addressList}
                    filterOptions={(x) => x}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    getOptionLabel={(option) =>
                      option && option.properties && option.properties.name
                    }
                    onInputChange={(event, newInputValue) =>
                      handleFetchAddress(newInputValue)
                    }
                    onChange={(event, newValue) =>
                      handleSelectAddress(newValue, setFieldValue)
                    }
                    renderInput={(params) => (
                      <MUITextField
                        {...params}
                        error={
                          touched.partner_place_of_living &&
                          !!errors.partner_place_of_living
                        }
                        helperText={
                          touched.partner_place_of_living &&
                          errors.partner_place_of_living
                        }
                        label={`Adresse  ${partnerOrShareholderCourtLabel}`}
                        placeholder={partner.partner_place_of_living}
                        size="small"
                      />
                    )}
                    renderOption={(option) => {
                      return (
                        <Grid container alignItems="center">
                          <Grid item>
                            <LocationOnIcon />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body2" color="textSecondary">
                              {option.properties.label}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                </Box>

                <Box marginTop={theme.spacing(0.25)}>
                  <Field
                    component={TextField}
                    type="string"
                    label={`Ville ${partnerOrShareholderCourtLabel}`}
                    name="partner_city"
                    className={classes.textField}
                    autoComplete="off"
                  />
                  <Field
                    component={TextField}
                    type="string"
                    label={`Code postal ${partnerOrShareholderCourtLabel}`}
                    name="partner_zipcode"
                    className={classes.textField}
                    autoComplete="off"
                  />
                </Box>
                {formValues.siege_social_chez !== 'chez_gerant' && (
                  <Box>
                    <Field
                      className={classes.field}
                      name="partner_country"
                      component={Autocomplete}
                      options={countryList}
                      getOptionLabel={(option) => option.name_fr}
                      required
                      style={{ width: 300 }}
                      // valueSelected={
                      //   find(
                      //     countryList,
                      //     (elem) => elem.alpha2 === values.country
                      //   ) || find(countryList, (elem) => elem.alpha2 === 'FR')
                      // }
                      renderInput={(params) => (
                        <MUITextField
                          {...params}
                          required
                          error={
                            touched.partner_country && !!errors.partner_country
                          }
                          helperText={
                            touched.partner_country && errors.partner_country
                          }
                          label={`Pays ${partnerOrShareholderCourtLabel}`}
                          placeholder={partner.partner_country}
                        />
                      )}
                    />
                  </Box>
                )}

                {formValues.siege_social_chez === 'chez_gerant' && (
                  <Box>
                    <Field
                      component={TextField}
                      type="string"
                      name="partner_state"
                      label={`Pays ${partnerOrShareholderCourtLabel}`}
                      defaultValue="France"
                      className={classes.textField}
                      disabled
                    />
                  </Box>
                )}
                {formValues.eurl_sasu === 'eurl' && (
                  <Box className={classes.root} width="100%">
                    <Field
                      width="100%"
                      component={TextField}
                      type="string"
                      placeholder="xyz@gmail.com"
                      label={`Email de ${associeActionnaire}`}
                      name="partner_email"
                      className={classes.textField}
                    />
                  </Box>
                )}
                {formValues.eurl_sasu === 'sasu' && (
                  <Box className={classes.root} width="100%">
                    {/* <Field
                  component={TextField}
                  type="string"
                  name="companyType"
                  value={}
                /> */}
                    <Field
                      width="100%"
                      component={TextField}
                      type="string"
                      placeholder="xyz@gmail.com"
                      label={`Email de ${associeActionnaire}`}
                      name="partner_email"
                      className={classes.textField}
                    />
                  </Box>
                )}
              </Box>
            </Paper>
            <ButtonBox
              activeStep={activeStep}
              isLastStep={isLastStep}
              handleBack={handleBack}
              isSubmitting={isSubmitting}
              submitForm={submitForm}
            />{' '}
            <DebugFormik values={values} touched={touched} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
InfoPartenaire.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoPartenaire;
