import { Avatar } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react';
import useStyles from '../style';

function GenericCard({ icon, title, subTitle, handleClick }) {
  const classes = useStyles();

  return (
    <div className={classes.partnerCard} onClick={handleClick}>
      <Avatar className={classes.avatar}>{icon}</Avatar>
      <div>
        <div className={classes.parnterName}>{title}</div>
        {subTitle}
      </div>
      <div className={classes.editIconContainer}>
        <Edit className={classes.editIcon} />
      </div>
    </div>
  );
}

export default GenericCard;
