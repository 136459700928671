import { capitalize } from 'lodash';

const prepareData = async (values) => {
  const form = { ...values };
  const { business_name } = form;
  form.business_name = capitalize(business_name);
  return form;
};

export default prepareData;
