const present = {
  present_nom: '',
  present_prenom: '',
  present_adresse: '',
  present_code_postal: '',
  present_ville: '',
  present_date_naissance: '',
  present_nationalite: '',
  present_profession: '',
  present_vote: 'pour',
};

export default present;
