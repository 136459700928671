/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import {
  FormControlLabel,
  Radio,
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField, RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import * as Yup from 'yup';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

// import { PartnersContext } from '../contexts/partners.context';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '60ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const SignupSchema = Yup.object().shape({
  partner_wedding_prenup_type: Yup.string().required('un choix est necessaire'),
  partner_spouse_name: Yup.string().required('un choix est necessaire'),
  partner_spouse_firstname: Yup.string().required('un choix est necessaire'),
  partner_spouse_date_naissance: Yup.date().required('un choix est necessaire'),
  partner_spouse_lieu_naissance: Yup.string().required(
    'un choix est necessaire'
  ),
  partner_spouse_departement_naissance: Yup.string().required(
    'un choix est necessaire'
  ),
  partner_wedding_date: Yup.date().required('un choix est necessaire'),
  partner_wedding_place: Yup.string().required('un choix est necessaire'),
});

const InfoMariage = ({
  partner,
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  let partnerOrShareholderLabel = "de l'associé";

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    partnerOrShareholderLabel = "de l'actionnaire";
  }

  const [cityList, setCityList] = useState([]);
  const [cityValue, setcityValue] = useState(null);
  const [cityInput, setCityInput] = useState(null);
  const [loadingCityList, setLoadingCityList] = useState(false);

  const [cityUnionList, setCityUnionList] = useState([]);
  const [cityUnionValue, setcityUnionValue] = useState(null);
  const [cityUnionInput, setCityUnionInput] = useState(null);
  const [loadingCityUnionList, setLoadingCityUnionList] = useState(false);

  const cityInputDebounce = useRef(
    _.debounce((newValue) => setCityInput(newValue), 500)
  );

  const cityUnionInputDebounce = useRef(
    _.debounce((newValue) => setCityUnionInput(newValue), 500)
  );

  useEffect(() => {
    if (cityInput) {
      setLoadingCityList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: cityInput, type: 'municipality', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setCityList(data.features);
          } else {
            setCityList([]);
          }
          setLoadingCityList(false);
        });
    } else {
      setCityList([]);
      setLoadingCityList(false);
    }
  }, [cityInput]);

  useEffect(() => {
    if (cityUnionInput) {
      setLoadingCityUnionList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: cityUnionInput, type: 'municipality', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setCityUnionList(data.features);
          } else {
            setCityUnionList([]);
          }
          setLoadingCityUnionList(false);
        });
    } else {
      setCityUnionList([]);
      setLoadingCityUnionList(false);
    }
  }, [cityUnionInput]);

  const handleFetchCity = async (newInputValue) => {
    setLoadingCityList(true);
    setCityInput(null);
    cityInputDebounce.current(newInputValue);
  };

  const handleSelectCity = async (newValue, setFieldValue) => {
    if (newValue) {
      setcityValue(newValue);
      setFieldValue('partner_spouse_lieu_naissance', newValue.properties.name);
      if (newValue.properties.context) {
        const { context } = newValue.properties;
        const contextArr = context.split(', ');
        const state = contextArr[contextArr.length - 2];
        setFieldValue('partner_spouse_departement_naissance', state);
      }
    } else {
      setFieldValue('partner_spouse_lieu_naissance', '');
      setFieldValue('partner_spouse_departement_naissance', '');
      setcityValue(newValue);
    }
  };

  const handleFetchCityUnion = async (newInputValue) => {
    setLoadingCityUnionList(true);
    setCityUnionInput(null);
    cityUnionInputDebounce.current(newInputValue);
  };

  const handleSelectCityUnion = async (newValue, setFieldValue) => {
    if (newValue) {
      setcityUnionValue(newValue);
      setFieldValue('partner_wedding_place', newValue.properties.name);
      if (newValue.properties.context) {
        // const { context } = newValue.properties;
        // const contextArr = context.split(', ');
        // const state = contextArr[contextArr.length - 1];
        // setFieldValue('partner_wedding_place', state);
      }
    } else {
      setFieldValue('partner_wedding_place', '');
      // setFieldValue('partner_spouse_departement_naissance', '');
      setcityValue(newValue);
    }
  };

  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_PARTNER,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        SciAutomate.nextInfoMariageVersChoixContratMariage();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">Marié(e)</Typography>

              <Field
                component={RadioGroup}
                defaultValue="separation_de_biens"
                // name={`partners[${partnerIndex}].partner_wedding_prenup_type`}
                name="partner_wedding_prenup_type"
              >
                <FormControlLabel
                  value="separation_de_biens"
                  control={<Radio />}
                  label="Séparation de biens"
                />
                <FormControlLabel
                  value="communaute_universelle"
                  control={<Radio />}
                  label="Communauté universelle"
                />
                <FormControlLabel
                  value="communaute_de_biens"
                  control={<Radio />}
                  label="Communauté de biens"
                />
              </Field>
              {/* MBM */}
              <Typography variant="subtitle1">
                informations sur le conjoint {partnerOrShareholderLabel}
              </Typography>
              <Box>
                <Field
                  component={RadioGroup}
                  defaultValue="M."
                  name="partner_spouse_title"
                >
                  <FormControlLabel value="M." control={<Radio />} label="M." />
                  <FormControlLabel
                    value="Mme"
                    control={<Radio />}
                    label="Mme"
                  />
                </Field>
              </Box>
              {/* /MBM */}

              <Field
                component={TextField}
                type="string"
                label={`Nom du conjoint ${partnerOrShareholderLabel}`}
                name="partner_spouse_name"
                // className={classes.textField}
                autoComplete="off"
                inputProps={{ style: { textTransform: 'capitalize' } }}
              />
              <Field
                component={TextField}
                type="string"
                label={`Prénom du conjoint ${partnerOrShareholderLabel}`}
                // name={`partners[${partnerIndex}].partner_spouse_firstname`}
                name="partner_spouse_firstname"
                // className={classes.textField}
                autoComplete="off"
                inputProps={{ style: { textTransform: 'capitalize' } }}
              />

              <Field
                component={KeyboardDatePicker}
                clearable
                label={`date de naissance du conjoint ${partnerOrShareholderLabel}`}
                placeholder="01/01/1990"
                format="dd/MM/yyyy"
                maxDate={new Date()}
                // name={`partners[${partnerIndex}].partner_spouse_date_naissance`}
                name="partner_spouse_date_naissance"
                className={classes.textField}
                autoComplete="off"
              />

              <Field
                component={Autocomplete}
                // type="string"
                // label={`Lieu de naissance du conjoint ${partnerOrShareholderLabel}`}
                // name={`partners[${partnerIndex}].partner_spouse_lieu_naissance`}
                name="partner_spouse_lieu_naissance"
                className={classes.textField}
                value={cityValue}
                loading={loadingCityList}
                options={cityList}
                filterOptions={(x) => x}
                autoComplete
                includeInputInList
                filterSelectedOptions
                getOptionLabel={(option) =>
                  option && option.properties && option.properties.name
                }
                onInputChange={(event, newInputValue) =>
                  handleFetchCity(newInputValue)
                }
                onChange={(event, newValue) =>
                  handleSelectCity(newValue, setFieldValue)
                }
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    error={
                      touched.partner_spouse_lieu_naissance &&
                      !!errors.partner_spouse_lieu_naissance
                    }
                    helperText={
                      touched.partner_spouse_lieu_naissance &&
                      errors.partner_spouse_lieu_naissance
                    }
                    label={`Lieu de naissance du conjoint ${partnerOrShareholderLabel}`}
                    placeholder={formValues.partner_spouse_lieu_naissance}
                    size="small"
                  />
                )}
                renderOption={(option) => {
                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" color="textSecondary">
                          {option.properties.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
              />
              <Field
                component={TextField}
                type="string"
                label={`Département de naissance du conjoint`}
                // name={`partners[${partnerIndex}].partner_spouse_departement_naissance`}
                name="partner_spouse_departement_naissance"
                className={classes.textField}
                placeholder="ex: Paris 11eme arrondissement"
                autoComplete="off"
              />

              <Field
                component={KeyboardDatePicker}
                clearable
                label="Date de l'union"
                placeholder="01/01/1990"
                format="dd/MM/yyyy"
                // name={`partners[${partnerIndex}].partner_wedding_date`}
                name="partner_wedding_date"
                className={classes.textField}
                autoComplete="off"
              />

              <Field
                // component={TextField}
                // type="string"
                // label="Lieu de l'union"
                // name="partner_wedding_place"
                // className={classes.textField}
                // autoComplete="off"

                component={Autocomplete}
                name="partner_wedding_place"
                className={classes.textField}
                value={cityUnionValue}
                loading={loadingCityUnionList}
                options={cityUnionList}
                filterOptions={(x) => x}
                autoComplete
                includeInputInList
                filterSelectedOptions
                getOptionLabel={(option) =>
                  option && option.properties && option.properties.name
                }
                onInputChange={(event, newInputValue) =>
                  handleFetchCityUnion(newInputValue)
                }
                onChange={(event, newValue) =>
                  handleSelectCityUnion(newValue, setFieldValue)
                }
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    error={
                      touched.partner_wedding_place &&
                      !!errors.partner_wedding_place
                    }
                    helperText={
                      touched.partner_wedding_place &&
                      errors.partner_wedding_place
                    }
                    label="Lieu de l'union"
                    placeholder={formValues.partner_wedding_place}
                    size="small"
                  />
                )}
                renderOption={(option) => {
                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" color="textSecondary">
                          {option.properties.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoMariage.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoMariage;
