import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import {
  FormControlLabel,
  MenuItem,
  Radio,
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField, Select, RadioGroup } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';

import { makeStyles } from '@material-ui/core/styles';

// import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const ChoixCommissaireAuxComptes = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  const [addressList1, setAddressList1] = useState([]);
  const [addressValue1, setAddressValue1] = useState(null);
  const [addressInput1, setAddressInput1] = useState(null);
  const [loadingAddressList1, setLoadingAddressList1] = useState(false);

  const debounce1 = useRef(
    _.debounce((newValue) => setAddressInput1(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  useEffect(() => {
    if (addressInput1) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput1, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList1(data.features);
          } else {
            setAddressList1([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList1([]);
      setLoadingAddressList(false);
    }
  }, [addressInput1]);

  const handleFetchAddress1 = async (newInputValue) => {
    setLoadingAddressList1(true);
    setAddressInput1(null);
    debounce1.current(newInputValue);
  };

  const handleSelectAddress1 = async (newValue, setFieldValue, fieldName) => {
    if (newValue) {
      setAddressValue1(newValue);
      setFieldValue(fieldName, newValue.properties.label);
    } else {
      setFieldValue(fieldName, '');
      setAddressValue1(newValue);
    }
  };

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue, fieldName) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue(fieldName, newValue.properties.label);
    } else {
      setFieldValue(fieldName, '');
      setAddressValue(newValue);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     presence_commissaire_aux_comptes: Yup.string().required(
      //       'un choix est necessaire'
      //     ),

      //     duree_exercice_commissaire_aux_comptes: Yup.number().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.number().required('Required');
      //         }
      //         return Yup.number();
      //       }
      //     ),

      //     nom_commissaire_aux_comptes: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     prenom_commissaire_aux_comptes: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     nom_cabinet_commissaire_aux_comptes: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     adresse_cabinet_commissaire_aux_comptes: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     nom_suppleant: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     prenom_suppleant: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     adresse_cabinet_suppleant: Yup.string().when(
      //       'presence_commissaire_aux_comptes',
      //       (presence_commissaire_aux_comptes) => {
      //         if (presence_commissaire_aux_comptes === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        // SciAutomate.next();
        // setAutomateState(SciAutomate.state);

        if (formValues.kit === 'basiquesci') {
          SciAutomate.nextChoixCommissaireAuxComptesVersListeFin();
          setAutomateState(SciAutomate.state);
        }
        if (formValues.kit === 'kit1sci') {
          SciAutomate.nextChoixCommissaireAuxComptesVersInfoCommissaireAuxComptes();
          setAutomateState(SciAutomate.state);
        }
        if (formValues.kit === 'kit2sci') {
          SciAutomate.nextChoixCommissaireAuxComptesVersChoixProcurationBbf();
          setAutomateState(SciAutomate.state);
        }
        if (formValues.kit === 'kit3sci') {
          SciAutomate.nextChoixCommissaireAuxComptesVersChoixProcurationBbf();
          setAutomateState(SciAutomate.state);
        }
        if (formValues.kit === 'kit4sci') {
          SciAutomate.nextChoixCommissaireAuxComptesVersChoixProcurationBbf();
          setAutomateState(SciAutomate.state);
        }

        // SciAutomate.nextChoixCommissaireAuxComptesVersInfoCommissaireAuxComptes();
        // setAutomateState(SciAutomate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">Commissaire aux comptes</Typography>

              <Typography variant="subtitle1">
                {' '}
                Souhaitez-vous nommer un commissaire aux comptes pour votre
                société en création?{' '}
              </Typography>
              <Field
                component={RadioGroup}
                name="presence_commissaire_aux_comptes"
              >
                <FormControlLabel
                  // onChange={() => {
                  //   ctx[0].preexisting_contract = 'oui';
                  // }}
                  value="oui"
                  control={<Radio />}
                  label="Oui"
                />
                <FormControlLabel
                  // onChange={() => {
                  //   ctx[0].preexisting_contract = 'non';
                  // }}
                  value="non"
                  control={<Radio />}
                  label="Non"
                />
              </Field>

              {values.presence_commissaire_aux_comptes === 'oui' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Typography variant="h6">
                      Informations sur le commissaire aux comptes titulaire
                    </Typography>

                    {/* demande de la durée d'exercice du commissaire aux comptes en année */}
                    <Box>
                      <Typography variant="subtitle2">
                        Veuillez indiquer ci-dessous, le nombre d&apos;exercices
                        du commissaire aux comptes{' '}
                      </Typography>
                    </Box>
                    <Box alignItems="center">
                      <FormControl>
                        <Field
                          component={Select}
                          name="duree_exercice_commissaire_aux_comptes"
                          inputProps={{
                            id: 'duree_exercice_commissaire_aux_comptes',
                          }}
                        >
                          <MenuItem value={3}>3 exercices</MenuItem>
                          <MenuItem value={6}>6 exercices</MenuItem>
                        </Field>
                      </FormControl>
                    </Box>

                    {/* MBM */}

                    <Box textAlign="center">
                      <Typography variant="subtitle1">
                        Le Commissaire aux Comptes est une personne physique ou
                        morale ?
                      </Typography>
                      <Field component={RadioGroup} name="type_CAC">
                        <FormControlLabel
                          value="physique"
                          control={<Radio />}
                          label="Personne Physique"
                        />
                        <FormControlLabel
                          value="morale"
                          control={<Radio />}
                          label="Personne Morale"
                        />
                      </Field>
                    </Box>

                    {/* /MBM */}

                    {/* MBM */}

                    {values.type_CAC === 'morale' && (
                      <Fragment>
                        <Box className={classes.root}>
                          <FormControl>
                            <Typography variant="subtitle2">
                              Forme de la société du commissaire aux comptes
                            </Typography>
                            <Field
                              component={Select}
                              name="forme_juridique_cabinet_CAC"
                              inputProps={{
                                id: 'forme_juridique_cabinet_CAC',
                              }}
                            >
                              <MenuItem value={'EURL'}>EURL</MenuItem>
                              <MenuItem value={'SA'}>SA</MenuItem>
                              <MenuItem value={'SARL'}>SARL</MenuItem>
                              <MenuItem value={'SAS'}>SAS</MenuItem>
                              <MenuItem value={'SASU'}>SASU</MenuItem>
                              <MenuItem value={'SC'}>SC</MenuItem>
                              <MenuItem value={'SCA'}>SCA</MenuItem>
                              <MenuItem value={'SCS'}>SCS</MenuItem>
                            </Field>
                          </FormControl>
                          <Field
                            component={TextField}
                            type="string"
                            label="Nom du cabinet du commissaire aux comptes"
                            name="nom_cabinet_commissaire_aux_comptes"
                            className={classes.textField}
                            autoComplete="off"
                          />
                          <Field
                            component={Autocomplete}
                            // type="string"
                            // label="Adresse du cabinet du commissaire aux comptes"
                            // placeholder="123 rue Bidon 57560 Paris"
                            name="adresse_cabinet_commissaire_aux_comptes"
                            className={classes.textField}
                            value={addressValue}
                            loading={loadingAddressList}
                            options={addressList}
                            filterOptions={(x) => x}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            getOptionLabel={(option) =>
                              option &&
                              option.properties &&
                              option.properties.label
                            }
                            onInputChange={(event, newInputValue) =>
                              handleFetchAddress(newInputValue)
                            }
                            onChange={(event, newValue) =>
                              handleSelectAddress(
                                newValue,
                                setFieldValue,
                                'adresse_cabinet_commissaire_aux_comptes'
                              )
                            }
                            renderInput={(params) => (
                              <MUITextField
                                {...params}
                                error={
                                  touched.adresse_cabinet_commissaire_aux_comptes &&
                                  !!errors.adresse_cabinet_commissaire_aux_comptes
                                }
                                helperText={
                                  touched.adresse_cabinet_commissaire_aux_comptes &&
                                  errors.adresse_cabinet_commissaire_aux_comptes
                                }
                                label="Adresse du cabinet du commissaire aux comptes"
                                placeholder={
                                  formValues.adresse_cabinet_commissaire_aux_comptes
                                }
                                size="small"
                              />
                            )}
                            renderOption={(option) => {
                              return (
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <LocationOnIcon />
                                  </Grid>
                                  <Grid item xs>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {option.properties.label}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            }}
                          />
                        </Box>
                      </Fragment>
                    )}

                    {/* /MBM */}

                    {/* MBM */}

                    {values.type_CAC === 'physique' && (
                      <Fragment>
                        <Box className={classes.root}>
                          {/* <Box textAlign="center">
                            <Typography variant="subtitle1">
                              Le Commissaire aux Comptes exerce t-il en nom
                              propre ?
                            </Typography>
                            <Field component={RadioGroup} name="nom_propre_CAC">
                              <FormControlLabel
                                value="nom_propre"
                                control={<Radio />}
                                label="En nom propre"
                              />
                              <FormControlLabel
                                value="societe"
                                control={<Radio />}
                                label="En société"
                              />
                            </Field>
                          </Box> */}

                          <Field
                            component={RadioGroup}
                            defaultValue="M."
                            name="titre_commissaire_aux_comptes"
                          >
                            <Typography variant="subtitle2">
                              informations commissaire aux comptes
                            </Typography>
                            <FormControlLabel
                              value="M."
                              control={<Radio />}
                              label="M."
                            />
                            <FormControlLabel
                              value="Mme"
                              control={<Radio />}
                              label="Mme"
                            />
                          </Field>

                          {/* /MBM */}

                          <Field
                            component={TextField}
                            type="string"
                            label="Nom du commissaire aux comptes"
                            name="nom_commissaire_aux_comptes"
                            className={classes.textField}
                            autoComplete="off"
                          />
                          <Field
                            component={TextField}
                            type="string"
                            label="Prénom du commissaire aux comptes"
                            name="prenom_commissaire_aux_comptes"
                            className={classes.textField}
                            autoComplete="off"
                          />
                        </Box>

                        {/* MBM */}
                        {values.type_CAC === 'morale' && (
                          <Fragment>
                            <Box className={classes.root}>
                              <Box className={classes.root}>
                                <FormControl>
                                  <Typography variant="subtitle2">
                                    Forme du cabinet du commissaire aux comptes
                                  </Typography>
                                  <Field
                                    component={Select}
                                    name="forme_juridique_cabinet_CAC"
                                    inputProps={{
                                      id: 'forme_juridique_cabinet_CAC',
                                    }}
                                  >
                                    <MenuItem value={'EURL'}>EURL</MenuItem>
                                    <MenuItem value={'SA'}>SA</MenuItem>
                                    <MenuItem value={'SARL'}>SARL</MenuItem>
                                    <MenuItem value={'SAS'}>SAS</MenuItem>
                                    <MenuItem value={'SASU'}>SASU</MenuItem>
                                    <MenuItem value={'SC'}>SC</MenuItem>
                                    <MenuItem value={'SCA'}>SCA</MenuItem>
                                    <MenuItem value={'SCS'}>SCS</MenuItem>
                                  </Field>
                                </FormControl>
                              </Box>

                              {/* /MBM */}

                              <Field
                                component={TextField}
                                type="string"
                                label="Nom du cabinet du commissaire aux comptes"
                                name="nom_cabinet_commissaire_aux_comptes"
                                className={classes.textField}
                                autoComplete="off"
                              />
                              <Field
                                component={Autocomplete}
                                // type="string"
                                // label="Adresse du cabinet du commissaire aux comptes"
                                // placeholder="123 rue Bidon 57560 Paris"
                                name="adresse_cabinet_commissaire_aux_comptes"
                                className={classes.textField}
                                value={addressValue}
                                loading={loadingAddressList}
                                options={addressList}
                                filterOptions={(x) => x}
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                getOptionLabel={(option) =>
                                  option &&
                                  option.properties &&
                                  option.properties.label
                                }
                                onInputChange={(event, newInputValue) =>
                                  handleFetchAddress(newInputValue)
                                }
                                onChange={(event, newValue) =>
                                  handleSelectAddress(
                                    newValue,
                                    setFieldValue,
                                    'adresse_cabinet_commissaire_aux_comptes'
                                  )
                                }
                                renderInput={(params) => (
                                  <MUITextField
                                    {...params}
                                    error={
                                      touched.adresse_cabinet_commissaire_aux_comptes &&
                                      !!errors.adresse_cabinet_commissaire_aux_comptes
                                    }
                                    helperText={
                                      touched.adresse_cabinet_commissaire_aux_comptes &&
                                      errors.adresse_cabinet_commissaire_aux_comptes
                                    }
                                    label="Adresse du cabinet du commissaire aux comptes"
                                    placeholder={
                                      formValues.adresse_cabinet_commissaire_aux_comptes
                                    }
                                    size="small"
                                  />
                                )}
                                renderOption={(option) => {
                                  return (
                                    <Grid container alignItems="center">
                                      <Grid item>
                                        <LocationOnIcon />
                                      </Grid>
                                      <Grid item xs>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          {option.properties.label}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  );
                                }}
                              />
                            </Box>
                          </Fragment>
                        )}

                        {/* MBM */}

                        {values.type_CAC === 'physique' && (
                          <Fragment>
                            <Box>
                              <Field
                                component={Autocomplete}
                                name="adresse_cabinet_commissaire_aux_comptes"
                                className={classes.textField}
                                value={addressValue}
                                loading={loadingAddressList}
                                options={addressList}
                                filterOptions={(x) => x}
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                getOptionLabel={(option) =>
                                  option &&
                                  option.properties &&
                                  option.properties.label
                                }
                                onInputChange={(event, newInputValue) =>
                                  handleFetchAddress(newInputValue)
                                }
                                onChange={(event, newValue) =>
                                  handleSelectAddress(
                                    newValue,
                                    setFieldValue,
                                    'adresse_cabinet_commissaire_aux_comptes'
                                  )
                                }
                                renderInput={(params) => (
                                  <MUITextField
                                    {...params}
                                    error={
                                      touched.adresse_cabinet_commissaire_aux_comptes &&
                                      !!errors.adresse_cabinet_commissaire_aux_comptes
                                    }
                                    helperText={
                                      touched.adresse_cabinet_commissaire_aux_comptes &&
                                      errors.adresse_cabinet_commissaire_aux_comptes
                                    }
                                    label="Adresse du Commissaire aux comptes"
                                    placeholder={
                                      formValues.adresse_cabinet_commissaire_aux_comptes
                                    }
                                    size="small"
                                  />
                                )}
                                renderOption={(option) => {
                                  return (
                                    <Grid container alignItems="center">
                                      <Grid item>
                                        <LocationOnIcon />
                                      </Grid>
                                      <Grid item xs>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          {option.properties.label}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  );
                                }}
                              />
                            </Box>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                    {values.type_CAC === 'morale' && (
                      <Box textAlign="center">
                        <Typography variant="subtitle1">
                          Y a t-il un Commissaire aux Comptes suppléant ?
                        </Typography>
                        <Field component={RadioGroup} name="cac_suppleant">
                          <FormControlLabel
                            value="oui"
                            control={<Radio />}
                            label="Oui"
                          />
                          <FormControlLabel
                            value="non"
                            control={<Radio />}
                            label="non"
                          />
                        </Field>
                      </Box>
                    )}

                    {values.cac_suppleant === 'oui' && (
                      <Fragment>
                        <Box textAlign="center">
                          <Typography variant="subtitle1">
                            Le Commissaire aux Comptes suppléant est une
                            personne physique ou morale ?
                          </Typography>
                          <Field
                            component={RadioGroup}
                            name="type_CAC_suppleant"
                          >
                            <FormControlLabel
                              value="physique"
                              control={<Radio />}
                              label="Personne Physique"
                            />
                            <FormControlLabel
                              value="morale"
                              control={<Radio />}
                              label="Personne Morale"
                            />
                          </Field>
                        </Box>

                        <Box className={classes.root}>
                          {values.type_CAC_suppleant === 'physique' && (
                            <Fragment>
                              <Field
                                component={RadioGroup}
                                defaultValue="M."
                                name="titre_suppleant"
                              >
                                <Typography variant="subtitle2">
                                  informations du suppléant
                                </Typography>
                                <FormControlLabel
                                  value="M."
                                  control={<Radio />}
                                  label="M."
                                />
                                <FormControlLabel
                                  value="Mme"
                                  control={<Radio />}
                                  label="Mme"
                                />
                              </Field>
                              <Box textAlign="center">
                                {/* /MBM */}

                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Nom du suppléant"
                                  name="nom_suppleant"
                                  className={classes.textField}
                                  autoComplete="off"
                                />
                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Prénom du suppléant"
                                  name="prenom_suppleant"
                                  className={classes.textField}
                                  autoComplete="off"
                                />
                              </Box>
                            </Fragment>
                          )}

                          {values.type_CAC_suppleant === 'morale' && (
                            <Fragment>
                              <Typography variant="subtitle2">
                                informations du suppléant
                              </Typography>

                              <Box textAlign="center">
                                {/* /MBM */}

                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Raison sociale du suppléant"
                                  name="nom_suppleant"
                                  className={classes.textField}
                                  autoComplete="off"
                                />
                              </Box>
                            </Fragment>
                          )}
                          <Field
                            component={Autocomplete}
                            // type="string"
                            // label="Adresse du cabinet du suppléant "
                            // placeholder="123 rue Bidon 57560 Paris"
                            name="adresse_cabinet_suppleant"
                            className={classes.textField}
                            value={addressValue1}
                            loading={loadingAddressList1}
                            options={addressList1}
                            filterOptions={(x) => x}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            getOptionLabel={(option) =>
                              option &&
                              option.properties &&
                              option.properties.label
                            }
                            onInputChange={(event, newInputValue) =>
                              handleFetchAddress1(newInputValue)
                            }
                            onChange={(event, newValue) =>
                              handleSelectAddress1(
                                newValue,
                                setFieldValue,
                                'adresse_cabinet_suppleant'
                              )
                            }
                            renderInput={(params) => (
                              <MUITextField
                                {...params}
                                error={
                                  touched.adresse_cabinet_suppleant &&
                                  !!errors.adresse_cabinet_suppleant
                                }
                                helperText={
                                  touched.adresse_cabinet_suppleant &&
                                  errors.adresse_cabinet_suppleant
                                }
                                label="Adresse du cabinet du suppléant "
                                placeholder={
                                  formValues.adresse_cabinet_suppleant
                                }
                                size="small"
                              />
                            )}
                            renderOption={(option) => {
                              return (
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <LocationOnIcon />
                                  </Grid>
                                  <Grid item xs>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {option.properties.label}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            }}
                          />
                        </Box>
                      </Fragment>
                    )}
                  </Box>
                </Fragment>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixCommissaireAuxComptes.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixCommissaireAuxComptes;
