import { Formik } from 'formik';
import React from 'react';
import FormActions from '../FormActions';
import PartnerContribution from './PartnerContribution';
import { initialValues } from './initValues';
import prepareData from './prepareData';
import { validationSchema } from './validation';

function PartnerContributionContainer({
  goBack,
  saveAndNext,
  formulaireComplet,
}) {
  const form = { ...formulaireComplet };
  const { currentForm, partnerId, partners } = form;
  const currentPartner = partners.find((partner) => partner.id === partnerId);
  const currentPartnerIndex = partners.findIndex(
    (partner) => partner.id === partnerId
  );

  return (
    <Formik
      initialValues={initialValues(currentPartner)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const data = prepareData(values);
        const updatedPartner = { ...currentPartner, ...data };
        form.partners[currentPartnerIndex] = updatedPartner;
        setSubmitting(false);
        saveAndNext(form);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <PartnerContribution />
          <FormActions goBack={goBack} currentForm={currentForm} />
        </form>
      )}
    </Formik>
  );
}

export default PartnerContributionContainer;
