import { createSlice } from '@reduxjs/toolkit';
import { SUCCESS } from 'assets/data/severity';

export const monCompteReducer = createSlice({
  name: 'company',
  initialState: {
    loading: false,
    company: {},
    error: null,
    snackbarMessage: {
      message: null,
      severity: SUCCESS,
    },
  },
  reducers: {
    setCompanyData: (state, action) => {
      // eslint-disable-next-line
      state.company = action.payload;
    },
    singUpCompany: (state) => {
      return { ...state, loading: true };
    },
    singUpCompanySuccess: (state, action) => {
      const { company } = state;
      return {
        ...state,
        company: { ...company, ...action.payload },
        loading: false,
      };
    },
    singUpCompanyFailed: (state, action) => {
      return { ...state, loading: false, error: action.payload };
    },
    createFormProcessing: (state) => {
      return { ...state, loading: true };
    },
    createFormSuccess: (state, action) => {
      const { company, creaformOID } = action.payload;
      return {
        ...state,
        company: { ...company, creaformOID },
        loading: false,
      };
    },
    createStatutsProjectProcessing: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    createStatutsProjectSuccess: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    createStatutsProjectFailed: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    createFormFailed: (state, action) => {
      return { ...state, loading: false, error: action.payload };
    },
    generateDocsProcessing: (state) => {
      return { ...state, loading: true };
    },
    generateDocsSuccess: (state, action) => {
      const { company } = state;
      return {
        ...state,
        company: { ...company, ...action.payload },
        loading: false,
      };
    },
    generateDocsFailed: (state, action) => {
      return { ...state, loading: false, error: action.payload };
    },
    setSnackBarMessage: (state, action) => {
      return { ...state, loading: false, snackbarMessage: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCompanyData,
  singUpCompany,
  singUpCompanySuccess,
  singUpCompanyFailed,
  createFormProcessing,
  createFormSuccess,
  createFormFailed,
  generateDocsProcessing,
  generateDocsSuccess,
  generateDocsFailed,
  setSnackBarMessage,
  createStatutsProjectSuccess,
  createStatutsProjectProcessing,
  createStatutsProjectFailed,
} = monCompteReducer.actions;

export default monCompteReducer.reducer;
