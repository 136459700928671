import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  Typography,
  Popover,
  IconButton,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { makeStyles } from '@material-ui/core/styles';

import { RadioGroup } from 'formik-material-ui';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { format, addYears, endOfYear } from 'date-fns';
import * as Yup from 'yup';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '20px',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '20ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  radio: {},
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

const InfoSociete3 = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(0);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? 'simple-popover3' : undefined;

  const popoverTextDate = (
    <div className={classes.popOverDiv}>
      <Typography>
        Si vous ne démarrez pas votre activité immédiatement ou dans le mois de
        la signature des statuts, votre société est immatriculée sans activité.
        La création de votre société engendrera un surcout de l’ordre 120 €
        (frais de greffe) et la mise en activité entrainera un traitement
        administratif et des frais supplémentaires ultérieur de l’ordre de 350 €
        (frais de greffe et nos honoraires).
      </Typography>
    </div>
  );

  let popoverTextDatesSociete = '';
  if (formValues.eurl_sasu === 'eurl') {
    popoverTextDatesSociete = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>La date de début d’activité</h4>
          La date à retenir pour fixer le début d&apos;activité la plus
          communément admise est celle qui va figurer sur le document de
          création d&apos;activité (imprimé P0 ou M0) que sera déposé auprès de
          votre centre de formalités des entreprises (INPI avec le guichet
          unique).
          <br /> <br />
          <u>
            Cette date doit être postérieure à la date de signature des statuts
          </u>
          <br />
          <br />À noter : selon la jurisprudence du Conseil d&apos;État (CE,
          arrêt du 12 janvier 1987, n° 46227) le redevable ne peut être réputé
          avoir commencé son activité qu&apos;à la double condition d&apos;avoir
          disposé d&apos;immobilisations et d&apos;avoir versé des salaires ou
          réalisé des recettes au cours de cette année.
          <h4>La date de clôture du premier exercice comptable</h4>
          Lors de la création de votre entreprise, il est nécessaire de fixer la
          date de clôture du premier exercice comptable. Cette date ne peut
          excéder le 31 décembre de l’année suivant celle de votre création. Par
          exemple si vous créez le 15 janvier N, la date limite de clôture du
          1er exercice comptable sera le 31 décembre N+1.
          <br /> <br />
          Ensuite, l’exercice sera clôturé par période de 12 mois à compter du
          lendemain de la date de clôture du premier exercice comptable.
          <br /> <br />
          Même si de nombreuses entreprises choisissent le 31 décembre comme
          date de clôture, cette date est libre et peut être choisie en fonction
          de la saisonnalité de votre activité. En général, la date est fixée au
          31 décembre, 31 mars, 30 juin, 30 septembre…
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sasu') {
    popoverTextDatesSociete = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>La date de début d’activité </h4>
          La date à retenir pour fixer le début d&apos;activité la plus
          communément admise est celle qui va figurer sur le document de
          création d&apos;activité (imprimé P0 ou M0) que sera déposé auprès de
          votre centre de formalités des entreprises (INPI avec le guichet
          unique).
          <br /> <br />
          <u>
            Cette date doit être postérieure à la date de signature des statuts
          </u>
          <br /> <br />À noter : selon la jurisprudence du Conseil d&apos;État
          (CE, arrêt du 12 janvier 1987, n° 46227) le redevable ne peut être
          réputé avoir commencé son activité qu&apos;à la double condition
          d&apos;avoir disposé d&apos;immobilisations et d&apos;avoir versé des
          salaires ou réalisé des recettes au cours de cette année.
          <h4>La date de clôture du premier exercice comptable </h4>
          Lors de la création de votre entreprise, il est nécessaire de fixer la
          date de clôture du premier exercice comptable. Cette date ne peut
          excéder le 31 décembre de l’année suivant celle de votre création. Par
          exemple si vous créez le 15 janvier N, la date limite de clôture du
          1er exercice comptable sera le 31 décembre N+1.
          <br /> <br />
          Ensuite, l’exercice sera clôturé par période de 12 mois à compter du
          lendemain de la date de clôture du premier exercice comptable.
          <br /> <br />
          Même si de nombreuses entreprises choisissent le 31 décembre comme
          date de clôture, cette date est libre et peut être choisie en fonction
          de la saisonnalité de votre activité. En général, la date est fixée au
          31 décembre, 31 mars, 30 juin, 30 septembre…
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sas') {
    popoverTextDatesSociete = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>La date de début d’activité </h4>
          La date à retenir pour fixer le début d&apos;activité la plus
          communément admise est celle qui va figurer sur le document de
          création d&apos;activité (imprimé P0 ou M0) que sera déposé auprès de
          votre centre de formalités des entreprises (INPI avec le guichet
          unique).
          <br /> <br />
          <u>
            Cette date doit être postérieure à la date de signature des statuts
          </u>
          <br /> <br />À noter : selon la jurisprudence du Conseil d&apos;État
          (CE, arrêt du 12 janvier 1987, n° 46227) le redevable ne peut être
          réputé avoir commencé son activité qu&apos;à la double condition
          d&apos;avoir disposé d&apos;immobilisations et d&apos;avoir versé des
          salaires ou réalisé des recettes au cours de cette année.
          <h4>La date de clôture du premier exercice comptable</h4>
          Lors de la création de votre entreprise, il est nécessaire de fixer la
          date de clôture du premier exercice comptable. Cette date ne peut
          excéder le 31 décembre de l’année suivant celle de votre création. Par
          exemple si vous créez le 15 janvier N, la date limite de clôture du
          1er exercice comptable sera le 31 décembre N+1.
          <br /> <br />
          Ensuite, l’exercice sera clôturé par période de 12 mois à compter du
          lendemain de la date de clôture du premier exercice comptable.
          <br /> <br />
          Même si de nombreuses entreprises choisissent le 31 décembre comme
          date de clôture, cette date est libre et peut être choisie en fonction
          de la saisonnalité de votre activité. En général, la date est fixée au
          31 décembre, 31 mars, 30 juin, 30 septembre…
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sarl') {
    popoverTextDatesSociete = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>La date de début d’activité</h4>
          La date à retenir pour fixer le début d&apos;activité la plus
          communément admise est celle qui va figurer sur le document de
          création d&apos;activité (imprimé P0 ou M0) que sera déposé auprès de
          votre centre de formalités des entreprises (INPI avec le guichet
          unique).
          <br /> <br />
          <u>
            Cette date doit être postérieure à la date de signature des statuts
          </u>
          <br /> <br />À noter : selon la jurisprudence du Conseil d&apos;État
          (CE, arrêt du 12 janvier 1987, n° 46227) le redevable ne peut être
          réputé avoir commencé son activité qu&apos;à la double condition
          d&apos;avoir disposé d&apos;immobilisations et d&apos;avoir versé des
          salaires ou réalisé des recettes au cours de cette année.
          <h4>La date de clôture du premier exercice comptable</h4>
          Lors de la création de votre entreprise, il est nécessaire de fixer la
          date de clôture du premier exercice comptable. Cette date ne peut
          excéder le 31 décembre de l’année suivant celle de votre création. Par
          exemple si vous créez le 15 janvier N, la date limite de clôture du
          1er exercice comptable sera le 31 décembre N+1.
          <br /> <br />
          Ensuite, l’exercice sera clôturé par période de 12 mois à compter du
          lendemain de la date de clôture du premier exercice comptable.
          <br /> <br />
          Même si de nombreuses entreprises choisissent le 31 décembre comme
          date de clôture, cette date est libre et peut être choisie en fonction
          de la saisonnalité de votre activité. En général, la date est fixée au
          31 décembre, 31 mars, 30 juin, 30 septembre…
        </Typography>
      </div>
    );
  }

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          // date_debut_activite: Yup.date()

          //   .min(
          //     format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
          //     "la date de début d'activité doit être postérieure à la date de signature des statuts"
          //   )
          //   .required('un choix est necessaire'),

          closing_date_with_year: Yup.date().when(
            'date_debut_activite',
            (date_debut_activite) => {
              if (date_debut_activite !== '') {
                return Yup.date()
                  .required('Required')
                  .min(
                    format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
                    'la date de cloture du premier exercice doit être postérieure à la date de signature des statuts'
                  )
                  .max(
                    format(
                      new Date(endOfYear(new Date(addYears(new Date(), 1)))),
                      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                    ),

                    "la date de cloture doit être avant le 31/12 de l'année prochaine"
                  );
              }
              return Yup.date();
              // Yup.date().required('tsttttt');
            }
          ),

          // closing_date_with_year: Yup.date()
          // .when(
          //   'date_debut_activite',
          //   (date_debut_activite) => {
          //     if (date_debut_activite) {

          //       return Yup.date()
          //       .max(
          //         Moment(
          //           "la cloture doit avoir lieu avant le 31/12 de l'année suivante"
          //         )
          //       .required('une date est requise')      ERREUR DE PARSING AVEC UN } ou )
          //         }
          //   }
          // ),

          // .max(
          //   Moment(
          //     values.date_debut_activite.add(1, 'y').endOf('year'),
          //     "la cloture doit avoir lieu avant le 31/12 de l'année suivante"
          //   )
          //   .required('une date est requise')
          // ),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        setActiveStep(1);

        if (isRecap) {
          Automate.recapInfoSociete3VersListeFin();
          setAutomateState(Automate.state);
        }
        if (!isRecap) {
          Automate.nextInfoSociete3VersTypePartenaire();
          setAutomateState(Automate.state);
        }
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6"> Les dates de la société</Typography>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClick}
                >
                  <HelpOutlineOutlined />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  // marginThreshold="55"
                  onClose={handleClose}
                  // anchorOrigin={{
                  //   vertical: 'center',
                  //   horizontal: 'center',
                  // }}
                  anchorReference={'none'}
                  className={classes.popOver}
                >
                  {popoverTextDatesSociete}
                </Popover>
              </Box>

              {/* <Box className={classes.root}>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de début d'activité"
                  placeholder="01/01/1990"
                  className={classes.birthdayField}
                  format="dd/MM/yyyy"
                  name="date_debut_activite"
                  autoComplete="off"
                />
              </Box> */}

              <Box className={classes.root}>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de clôture du 1er exercice"
                  placeholder="01/01/1990"
                  className={classes.birthdayField}
                  format="dd/MM/yyyy"
                  name="closing_date_with_year"
                  autoComplete="off"
                />
              </Box>
              <Box className={classes.root}>
                <Field
                  component={RadioGroup}
                  name="presence_date_debut_activité"
                >
                  <Typography variant="subtitle1">
                    {' '}
                    Démarrez-vous votre activité dans le mois qui va suivre la
                    signature des statuts ?
                  </Typography>{' '}
                  <FormControlLabel
                    value="oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  {values.presence_date_debut_activité === 'oui' && (
                    <Box marginLeft="5%">
                      <Field component={RadioGroup} name="type_depart_activite">
                        {/* <Typography variant="subtitle1">
                          {' '}
                          Démarrez-vous votre activité dans le mois qui va
                          suivre la signature des statuts ?
                        </Typography>{' '} */}
                        <FormControlLabel
                          value="immediatement"
                          control={<Radio />}
                          label="Immédiatement en date de signature des statuts"
                        />
                        <FormControlLabel
                          value="date_externe"
                          control={<Radio />}
                          label="Choix d’une date (Maximum 30 jours après la signature des statuts) "
                        />
                        {values.type_depart_activite === 'date_externe' && (
                          <Box className={classes.root}>
                            <Field
                              component={KeyboardDatePicker}
                              clearable
                              label="Date de début d'activité"
                              placeholder="01/01/1990"
                              className={classes.birthdayField}
                              format="dd/MM/yyyy"
                              name="date_debut_activite"
                              autoComplete="off"
                            />
                          </Box>
                        )}
                      </Field>
                    </Box>
                  )}
                  <Box>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                    {values.presence_date_debut_activité === 'non' && (
                      <>
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={handleClick3}
                        >
                          <HelpOutlineOutlined />
                        </IconButton>
                        <Popover
                          id={id3}
                          open={open3}
                          anchorEl={anchorEl3}
                          onClose={handleClose3}
                          anchorReference={'none'}
                          className={classes.popOver}
                        >
                          {popoverTextDate}
                        </Popover>
                      </>
                    )}
                  </Box>
                </Field>
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoSociete3.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoSociete3;
