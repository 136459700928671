import React, { useContext, Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import {
  Popover,
  IconButton,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  MenuItem,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

// TST

import { Select, RadioGroup } from 'formik-material-ui';

import FormControl from '@material-ui/core/FormControl';

// /TST

import { makeStyles } from '@material-ui/core/styles';

import theme from 'assets/jss/theme';

import * as Yup from 'yup';
import Paper from 'components/surface/Paper';
import { findIndex } from 'lodash';

import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

const ChoixDuGerant = ({
  partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
  // partnerGerant,
  // setPartnerGerant,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(3);
  }, []);

  const { partners } = formValues;
  // const { partnersDeep } = formValues.partners;

  let gerantOupresidentLabel = 'gérant';
  // let associeOuactionnairePhraseLabel = ", associé de l'EURL en création";

  let associeOuMultiAssocie = "Un associé de l'EURL en création";

  // let associeOuActionnaireLabel = 'associée';

  if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sasu' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel =      ", dirigeant de l'actionnaire de la SASU en création";
    associeOuMultiAssocie = 'Un actionnaire de la SASU en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'eurl' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =      ", dirigeant de l'associé de l'EURL en création";
    associeOuMultiAssocie = "Un associé de l'EURL en création";
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sasu'
  ) {
    gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel = ', actionnaire de la SASU en création';
    associeOuMultiAssocie = 'Un actionnaire de la SASU en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sarl' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =      ", dirigeant de l'associée de la SARL en création";
    associeOuMultiAssocie = 'Un associé de la SARL en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sarl'
  ) {
    gerantOupresidentLabel = 'gérant';
    // associeOuactionnairePhraseLabel = ', associé de la SARL en création';
    associeOuMultiAssocie = 'Un associé de la SARL en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sas' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    gerantOupresidentLabel = 'Président';

    // associeOuactionnairePhraseLabel =      ", dirigeant de l'actionnaire de la SAS en création";
    associeOuMultiAssocie = 'Un actionnaire de la SAS en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sas'
  ) {
    gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel = ', actionnaire de la SAS';
    associeOuMultiAssocie = 'Un actionnaire de la SAS en création';
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'sasu' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    gerantOupresidentLabel = 'Président';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'actionnaire de la SASU en création";
    associeOuMultiAssocie = `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'actionnaire de la SASU en création`;

    // ", dirigeant de l'actionnaire de la SASU en création";

    // associeOuactionnairePhraseLabel =   {` ${partner.partner_title}   ${partner.partner_firstname} ${partner.partner_name}${associeOuactionnairePhraseLabelV}  `}
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'eurl' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'associé de l'EURL en création";

    associeOuMultiAssocie =
      //  ", dirigeant de l'associé de l'EURL en création";
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'associé de l'EURL en création`;
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'eurl' &&
    formValues.personne_physique_morale === 'personne_physique'
  ) {
    gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'associé de l'EURL en création";

    associeOuMultiAssocie =
      //  ", dirigeant de l'associé de l'EURL en création";
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name}, associé de l'EURL en création`;
  } else if (formValues.alone === 'seul' && formValues.eurl_sasu === 'sasu') {
    gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel = ', actionnaire de la SASU en création';

    associeOuMultiAssocie =
      // {` ${partner.partner_title}   ${partner.partner_firstname} ${partner.partner_name}', actionnaire de la SASU en création'`};
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , actionnaire de la SASU en création`;
    // ', actionnaire de la SASU en création';
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'sarl' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'associée de la SARL en création";

    associeOuMultiAssocie =
      //  ", dirigeant de l'associée de la SARL en création";
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'associée de la SARL en création`;
  } else if (formValues.alone === 'seul' && formValues.eurl_sasu === 'sarl') {
    gerantOupresidentLabel = 'gérant';
    // associeOuactionnairePhraseLabel = ', associé de la SARL en création';

    associeOuMultiAssocie =
      //  ', associé de la SARL en création';
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , associé de la SARL en création`;
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'sas' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    gerantOupresidentLabel = 'Président';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'actionnaire de la SAS en création";

    associeOuMultiAssocie =
      // ", dirigeant de l'actionnaire de la SAS en création";
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'actionnaire de la SAS en création`;
  } else if (formValues.alone === 'seul' && formValues.eurl_sasu === 'sas') {
    gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel = ', actionnaire de la SAS';

    associeOuMultiAssocie =
      // ', actionnaire de la SAS';
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , actionnaire de la SAS`;
  }
  // console.log('state automate dans choix_du_gerant = ', Automate.state);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let popoverTextChoixGerant = '';

  if (formValues.eurl_sasu === 'eurl') {
    popoverTextChoixGerant = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>Conditions de nomination du gérant d’une EURL</h4>
          Le gérant d’une EURL est nommé par l’associé unique de la société.
          L’associé unique peut donc s’attribuer les fonctions de gérant ou bien
          nommer une personne étrangère à la société. Lorsque l’associé unique
          est une personne morale, elle doit désigner une personne physique qui
          va la représenter, et exercer les fonctions de gérant en son nom. La
          nomination du gérant d’une EURL doit obligatoirement avoir lieu lors
          de la constitution de la société. Le gérant peut être nommé dans les
          statuts ou par décision de l’associé unique de l’EURL. Nommer le
          gérant dans les statuts conduit à devoir modifier les statuts en cas
          de changement de gérant, ce qui est plus contraignant que par décision
          extra-statutaire.
          <h4>Nomination du premier gérant lors de la création de l’EURL</h4>
          Nomination du premier gérant lors de la création de l’EURL : <br />
          &nbsp;&nbsp;• directement dans les statuts de l’EURL. Le gérant doit
          alors signer les statuts, et accepter ses fonctions au moyen de la
          mention suivante « bon pour acceptation des fonctions de gérant ». En
          cas de changement de gérant, il faudra modifier les statuts ; <br />
          <br />
          &nbsp;&nbsp;• dans un acte séparé par lequel l’associé unique désigne
          l’identité du gérant. Un procès-verbal de décisions de l’associé
          unique sera établi dans un registre spécial. Un simple procés-verbal
          de décision de l’associé unique permettra de changer de gérant en
          cours de vie sociale.
          <h4>Changement de gérant en cours de vie sociale</h4>
          En cours de vie sociale, le gérant peut être remplacé par une autre
          personne, au choix de l’associé unique, sous réserve toutefois de
          respecter les règles statutaires en vigueur. La procédure à suivre
          dépendra donc de la méthode de nomination du premier gérant de l’EURL
          : <br />
          &nbsp;&nbsp;• s’il a été nommé dans les statuts : la démarche prévue
          pour les modifications de statuts d’EURL doit être suivie ; <br />
          &nbsp;&nbsp;• s’il a été nommé dans un acte séparé : l’associé unique
          doit prendre une décision actant le changement de gérant, et établir
          le procés-verbal de décision de l’associé unique. <br />
          <br />
          Dès lors qu’un changement de mandataire social est opéré, l’associé
          unique doit respecter les formalités administratives et juridiques
          tant à l’égard des statuts qu’auprès du greffe du tribunal de
          commerce.
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sasu') {
    popoverTextChoixGerant = (
      <div className={classes.popOverDiv}>
        <Typography>
          La nomination d’un Président est obligatoire dans les SASU. Le
          Président de SASU peut être une personne physique ou une personne
          morale qui sera représentée par une personne physique. Le Président
          figurera au kbis de la société.
          <h4>Incompatibilités et interdictions à être Président de SASU</h4>
          Contrairement aux associés, certaines incompatibilités interdisent
          d’être nommé Président de SASU. Ne peuvent être Président de SASU, les
          personnes : <br />
          &nbsp;&nbsp;• frappées d’une interdiction de gérer une société ;{' '}
          <br />
          &nbsp;&nbsp;• incapables (majeur sous tutelle ou sous curatelle, sauf
          rares exceptions) ;<br />
          &nbsp;&nbsp;• qui ont des fonctions incompatibles avec l’exercice des
          fonctions de Président.
          <h4>Désigner un mineur au poste de Président de SASU</h4>
          Un mineur émancipé peut être nommé Président de SASU s’il obtient une
          autorisation : <br />
          &nbsp;&nbsp;• du juge des tutelles (si la demande est faite au moment
          de l’émancipation) ;<br />
          &nbsp;&nbsp;• du Président du tribunal de grande instance (si la
          demande est faite ultérieurement).
          <br />
          <br />
          Un mineur non émancipé qui a atteint l’âge de 16 ans peut être nommé
          Président de SASU.
          <h4>Comment désigner le Président d’une SASU ?</h4>
          Le premier Président de la SASU doit être désigné dans les statuts.
          Ensuite, les statuts prévoient librement les modalités de nomination
          des prochains Présidents de la SASU.
          <br />
          <br />
          Plusieurs options sont possibles pour nommer le Président, soit :{' '}
          <br />
          &nbsp;&nbsp;• prévoir que la nomination du Président est une décision
          de l’associé unique ; <br />
          &nbsp;&nbsp;• prévoir que l’associé unique est le Président ;
        </Typography>
      </div>
    );
  }
  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          director_type: Yup.string().required('un choix est necessaire'),
          // director_email: Yup.string().required('un choix est necessaire'),

          // director_email: Yup.string().when(
          //   ['director_type', 'eurl_sasu'],
          //   (director_type, eurl_sasu) => {
          //     if (
          //       director_type === "l_associe_de_l'EURL" &&
          //       eurl_sasu === 'eurl'
          //     ) {
          //       return Yup.string().required('Required');
          //     }
          //     if (
          //       director_type === "l_associe_de_l'EURL" &&
          //       eurl_sasu === 'sasu'
          //     ) {
          //       return Yup.string().required('Required');
          //     }
          //     return Yup.string();
          //   }
          // ),
          // director_email: Yup.string().required('un mail est requis'),
          // director_email: Yup.string().when(
          //   'director_type',
          //   (director_type) => {
          //     if (director_type === "l_associe_de_l'EURL") {
          //       return Yup.string().required('un mail est requis');
          //     }
          //     return Yup.string();
          //   }
          // ),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        // // console.log('partnerGerant :', partnerGerant);
        // // console.log('values.asso :', values.associe_gerant_multiassocie);
        if (
          values.director_type === "l_associe_de_l'EURL" &&
          formValues.alone === 'seul'
        ) {
          // eslint-disable-next-line
          values.director_email = formValues.partners[0].partner_email;
          // eslint-disable-next-line
          values.director_firstname = formValues.partners[0].partner_firstname;
          // eslint-disable-next-line
          values.director_name = formValues.partners[0].partner_name;
        }
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // if (values.director_type === "l_associe_de_l'EURL") {
        //   dispatch({
        //     type: EDIT_PARTNER,
        //     payload: values.director_email,
        //   });
        // }

        if (values.director_type === 'un_tiers') {
          Automate.nextChoixGerantVersInfoGerant();
          setAutomateState(Automate.state);
          return;
        }
        if (
          values.director_type === "l_associe_de_l'EURL" &&
          formValues.eurl_sasu === 'eurl'
        ) {
          Automate.nextChoixGerantVersInfoApportsSociete();
          setAutomateState(Automate.state);
        }
        if (
          values.director_type === "l_associe_de_l'EURL" &&
          formValues.eurl_sasu === 'sasu'
        ) {
          Automate.nextChoixGerantVersChoixDg();
          setAutomateState(Automate.state);
        }

        if (
          values.director_type === "l_associe_de_l'EURL" &&
          formValues.eurl_sasu === 'sas'
        ) {
          // console.log('avant gerant vers dg', Automate.state);
          Automate.nextChoixGerantVersChoixDg();
          setAutomateState(Automate.state);
          // console.log('apres gerant vers dg', Automate.state);
        }

        if (
          values.director_type === "l_associe_de_l'EURL" &&
          formValues.eurl_sasu === 'sarl'
        ) {
          Automate.nextChoixGerantVersInfoCapitalSociete();
          setAutomateState(Automate.state);
        }

        // Automate.next();
        // setAutomateState(Automate.state);
        // console.log('fin de choixgerant', Automate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        handleChange,
        setFieldValue,
      }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                Le choix du {gerantOupresidentLabel}
              </Typography>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={handleClick}
              >
                <HelpOutlineOutlined />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                // marginThreshold="55"
                onClose={handleClose}
                // anchorOrigin={{
                //   vertical: 'center',
                //   horizontal: 'center',
                // }}
                anchorReference={'none'}
                className={classes.popOver}
              >
                {popoverTextChoixGerant}
              </Popover>

              <Box marginTop={theme.spacing(0.25)}>
                <Field component={RadioGroup} name="director_type">
                  <FormControlLabel
                    value="l_associe_de_l'EURL"
                    control={<Radio />}
                    // label={` ${partner.partner_title}   ${partner.partner_firstname} ${partner.partner_name}${associeOuactionnairePhraseLabel}  `}
                    label={associeOuMultiAssocie}
                  />

                  {/* TST */}

                  {formValues.alone === 'a_plusieurs' &&
                    values.director_type === "l_associe_de_l'EURL" &&
                    formValues.eurl_sasu === 'sarl' && (
                      <Fragment>
                        <Box className={classes.root}>
                          <FormControl>
                            <Field
                              component={Select}
                              name="associe_gerant_multiassocie"
                              // value={[]}
                              inputProps={{
                                id: 'associe_gerant_multiassocie',
                              }}
                              multiple={true}
                            >
                              {partners.map((associe) => (
                                // {associe.raison_sociale_actionnaire_personne_morale_sasu && (

                                // )}

                                <MenuItem key={associe.id} value={associe}>
                                  {
                                    associe.raison_sociale_actionnaire_personne_morale_sasu
                                  }{' '}
                                  {associe.forme_juridique_personne_morale}{' '}
                                  {associe.partner_title}{' '}
                                  {associe.partner_firstname}{' '}
                                  {associe.partner_name}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Box>
                      </Fragment>
                    )}

                  {formValues.alone === 'a_plusieurs' &&
                    values.director_type === "l_associe_de_l'EURL" &&
                    formValues.eurl_sasu === 'sas' && (
                      <Fragment>
                        <Box className={classes.root}>
                          <FormControl>
                            <Field
                              component={Select}
                              name="associe_gerant_multiassocie"
                              // value={[]}
                              inputProps={{
                                id: 'associe_gerant_multiassocie',
                              }}
                              onChange={(e) => {
                                handleChange(e);
                                const index = findIndex(
                                  partners,
                                  e.target.value
                                );

                                setFieldValue(
                                  'email',
                                  partners[index].director_email
                                );

                                // // console.log('onCLOSEEEEE');
                                // setPartnerGerant(
                                //   values.associe_gerant_multiassocie
                                // );
                              }}
                            >
                              {partners.map((associe) => (
                                // {associe.raison_sociale_actionnaire_personne_morale_sasu && (

                                // )}

                                <MenuItem key={associe.id} value={associe}>
                                  {
                                    associe.raison_sociale_actionnaire_personne_morale_sasu
                                  }{' '}
                                  {associe.forme_juridique_personne_morale}{' '}
                                  {associe.partner_title}{' '}
                                  {associe.partner_firstname}{' '}
                                  {associe.partner_name}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Box>
                      </Fragment>
                    )}

                  {/* /TST */}

                  <FormControlLabel
                    value="un_tiers"
                    control={<Radio />}
                    label="Une personne physique tierce"
                  />
                </Field>
              </Box>

              {/* {values.director_type === "l_associe_de_l'EURL" &&
                formValues.alone === 'seul' && (
                  <Box>
                    <Field
                      component={TextField}
                      type="string"
                      placeholder="xyz@gmail.com"
                      label={`Adresse mail du ${gerantOupresidentLabel}`}
                      name="director_email"
               
                      className={classes.nameTextField}
                      autoComplete="off"
                    />
                  </Box>
                )} */}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixDuGerant.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixDuGerant;
