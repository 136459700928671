import { REQUIRED, YES } from 'constants/usersContants';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object().shape({
    preexisting_contract: Yup.string().required('Champs requis'),
    number_of_preexisting_contract: Yup.number().when('preexisting_contract', {
      is: (preexisting_contract) => preexisting_contract === YES,
      then: Yup.number().min(1).required('Champs requis'),
    }),
    contract1_date: Yup.date()
      .when('number_of_preexisting_contract', {
        is: (number_of_preexisting_contract) =>
          parseInt(number_of_preexisting_contract, 10) > 0,
        then: Yup.date()
          .required('Champs requis')
          .typeError(REQUIRED)
          .nullable(),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    contract1_type: Yup.string().when('number_of_preexisting_contract', {
      is: (number_of_preexisting_contract) =>
        parseInt(number_of_preexisting_contract, 10) > 0,
      then: Yup.string().required(REQUIRED),
    }),
    contract1_amount: Yup.string().when('number_of_preexisting_contract', {
      is: (number_of_preexisting_contract) =>
        parseInt(number_of_preexisting_contract, 10) > 0,
      then: Yup.string().required(REQUIRED),
    }),
    contract1_person_who_paid: Yup.string().when(
      'number_of_preexisting_contract',
      {
        is: (number_of_preexisting_contract) =>
          parseInt(number_of_preexisting_contract, 10) > 0,
        then: Yup.string().required(REQUIRED),
      }
    ),
    contract2_date: Yup.date()
      .when('number_of_preexisting_contract', {
        is: (number_of_preexisting_contract) =>
          parseInt(number_of_preexisting_contract, 10) > 1,
        then: Yup.date()
          .required('Champs requis')
          .typeError(REQUIRED)
          .nullable(),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    contract2_type: Yup.string().when('number_of_preexisting_contract', {
      is: (number_of_preexisting_contract) =>
        parseInt(number_of_preexisting_contract, 10) > 1,
      then: Yup.string().required(REQUIRED),
    }),
    contract2_amount: Yup.string().when('number_of_preexisting_contract', {
      is: (number_of_preexisting_contract) =>
        parseInt(number_of_preexisting_contract, 10) > 1,
      then: Yup.string().required(REQUIRED),
    }),
    contract2_person_who_paid: Yup.string().when(
      'number_of_preexisting_contract',
      {
        is: (number_of_preexisting_contract) =>
          parseInt(number_of_preexisting_contract, 10) > 1,
        then: Yup.string().required(REQUIRED),
      }
    ),
  });
};

//  eslint-disable-next-line
export { validationSchema };
