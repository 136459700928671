import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

// import SciAutomate from '../../helpers/SciAutomate';

// import Automate from '../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  fields: {},
  field: {
    margin: '5px',
  },
  // ActionButtons
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const ButtonBox = ({
  // activeStep,
  isLastStep,
  handleBack,
  handleReset,
  isSubmitting,
  submitForm,
  // automateState,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.buttons}>
      {/* {activeStep !== 0 && ( */}
      <Button onClick={handleBack} className={classes.button}>
        Back
      </Button>
      {/* )} */}
      <div className={classes.wrapper}>
        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={submitForm}
        >
          {isLastStep ? 'Créer les documents' : 'Next'}
        </Button>
        {isLastStep && (
          <Button
            onClick={handleReset}
            className={classes.button}
            color="secondary"
            variant="outlined"
          >
            Reset
          </Button>
        )}
        {isSubmitting && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};

export default ButtonBox;
