import React, { useRef, useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { uploadDocument } from 'helpers/AwsDocumentsHelper';

const ListOfDocs = ({
  documents,
  companyId,
  setDialogVisible,
  setOpeningDocument,
  downloadFile,
  getUrlOfDocument,
  alternativeId,
  saveKeyInDocument,
  removeKeyInDocument,
  fileValidation = false,
  onFileValidationReturn = () => false,
}) => {
  const inputFile = useRef([]);
  const [totalUploadedRequiredFiles, setTotalUploadedRequiredFiles] =
    useState(0);
  const [totalRequiredFiles, setTotalRequiredFiles] = useState(0);
  const [validationSnackbarVisible, setValidationSnackbarVisible] =
    useState(false);
  const [errMessage, setErrMessage] = useState(false);

  const docIsNotRequired = (doc) => {
    return !doc.title.includes('(si nécessaire)');
  };

  // Set total required files (initial)
  useEffect(() => {
    if (documents && documents.length > 0 && fileValidation) {
      setTotalRequiredFiles(
        documents.filter((doc) => docIsNotRequired(doc)).length
      );
      setTotalUploadedRequiredFiles(
        documents.filter((doc) => docIsNotRequired(doc) && doc.key).length
      );

      const isValid = totalUploadedRequiredFiles === totalRequiredFiles;
      onFileValidationReturn(isValid);
    }
  }, [documents]);

  // Set total required files
  useEffect(() => {
    if (
      totalUploadedRequiredFiles &&
      totalUploadedRequiredFiles.length > 0 &&
      fileValidation
    ) {
      const isValid = totalUploadedRequiredFiles === totalRequiredFiles;
      onFileValidationReturn(isValid);
    }
  }, [totalUploadedRequiredFiles]);

  const onChangeFile = async (event, selectedFileField) => {
    event.stopPropagation();
    event.preventDefault();
    const { files, name } = event.target;
    const file = files[0];
    const { key, errCode } = await uploadDocument(alternativeId, file);
    if (key) {
      saveKeyInDocument(key, name);

      console.log('selectedFileField', selectedFileField);
      console.log(
        'docIsNotRequired(selectedFileField)',
        docIsNotRequired(selectedFileField)
      );

      if (docIsNotRequired(selectedFileField)) {
        setTotalUploadedRequiredFiles(totalUploadedRequiredFiles + 1);
      }
    }

    if (errCode) {
      if (errCode === 'EntityTooLarge') {
        setErrMessage(
          'Le fichier est trop volumineux. Sa taille ne doit pas dépasser 1Mo'
        );
        setValidationSnackbarVisible(true);
      }
    }
  };

  const onRemoveFile = async (selectedFileField) => {
    await removeKeyInDocument(selectedFileField.key);

    if (docIsNotRequired(selectedFileField)) {
      setTotalUploadedRequiredFiles(totalUploadedRequiredFiles - 1);
    }
  };

  useEffect(() => {
    if (!validationSnackbarVisible) {
      setErrMessage(null);
    }
  }, [validationSnackbarVisible]);

  const renderErrSnackbar = () => (
    <Snackbar
      open={validationSnackbarVisible}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      onClose={() => setValidationSnackbarVisible(false)}
    >
      <Alert
        onClose={() => setValidationSnackbarVisible(false)}
        severity="error"
      >
        {errMessage}
      </Alert>
    </Snackbar>
  );

  return (
    <>
      <List>
        {documents.map((file, idx) => {
          return (
            <ListItem key={idx}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={file.title} />

              <ListItemSecondaryAction>
                {file.key && (
                  <Tooltip title="Visualiser le document">
                    <IconButton
                      aria-label="view"
                      onClick={async () => {
                        const f = await (
                          await getUrlOfDocument(file.key, companyId)
                        ).data.url;
                        setDialogVisible(true);
                        setOpeningDocument(f);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {file.key && file.source !== 'CLIENT' && (
                  <Tooltip title="Télécharger le document">
                    <IconButton edge="end" aria-label="download">
                      {/* <a href={file.key} download> */}
                      <span onClick={() => downloadFile(file)} download>
                        <GetAppIcon />
                      </span>
                      {/* </a> */}
                    </IconButton>
                  </Tooltip>
                )}
                {file.source === 'CLIENT' && !file.key && (
                  <Tooltip title="Uploader un document">
                    <IconButton edge="end" aria-label="upload">
                      {/* <a href={file.key} download> */}
                      <span
                        onClick={() => {
                          inputFile.current[idx].click();
                        }}
                      >
                        <input
                          type="file"
                          accept="application/pdf"
                          style={{ display: 'none' }}
                          ref={(el) => {
                            // eslint-disable-next-line
                            return (inputFile.current[idx] = el);
                          }}
                          name={file.title}
                          onChange={(event) => onChangeFile(event, file)}
                        />
                        <PublishIcon />
                      </span>
                      {/* </a> */}
                    </IconButton>
                  </Tooltip>
                )}
                {file.source === 'CLIENT' && file.key && (
                  <IconButton edge="end" aria-label="valid">
                    <CheckIcon style={{ color: 'green' }} />
                  </IconButton>
                )}
                {file.source === 'CLIENT' && file.key && (
                  <Tooltip title="Supprimer">
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon
                        style={{ color: 'red' }}
                        onClick={() => onRemoveFile(file)}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      {renderErrSnackbar()}
    </>
  );
};

export default ListOfDocs;
