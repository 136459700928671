import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  pdfViewContaier: {
    height: '100%',
    width: '100%',
    backgroundColor: '#e6e6e6',
    borderRadius: '4px',
    position: 'relative',
  },
  pdfPreview: {
    border: 'none',
    borderRadius: 'inherit',
    width: '100%',
    height: '100%',
    zIndex: 20,
    position: 'inherit',
  },
  pdfLoading: {
    position: 'absolute',
    left: '50%',
    top: '20%',
    transform: 'translateX(-50%)',
    zIndex: 1,
  },
}));

export default useStyles;
