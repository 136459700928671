import { YES } from 'constants/usersContants';

const initialValues = (values) => {
  return {
    eurl_sasu: values.eurl_sasu,
    partner_cash_funds_numbers: values.partner_cash_funds_numbers || 0,
    partner_montant_liberation_partielle:
      values.partner_montant_liberation_partielle || 0,
    cash_funds_onetime_payment: values.cash_funds_onetime_payment || YES,
    cash_funds_numbers: values.cash_funds_numbers,
    payments_amount: values.payments_amount || 0,
  };
};

// eslint-disable-next-line
export { initialValues };
