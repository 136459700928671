import React, { useState, useEffect } from 'react';

import { Box } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';
import KitParams from 'constants/kit';
import _ from 'lodash';
import {
  SOCIÉTE,
  AUTOENTREPRENEUR,
  SCI,
  ASSOCIATION,
  ENTREPRISEINDIVIDUELLE,
} from 'constants/companies';

const useStyles = makeStyles({
  btn_primary: {
    color: '#fff',
    backgroundColor: '#3d319d',
  },
});

const ChoixDemarrage = ({ kit }) => {
  const history = useHistory();
  const classes = useStyles();
  const [openReInitCompany, setOpenReInitCompany] = useState(false);
  const [openReInitSCI, setOpenReInitSCI] = useState(false);
  localStorage.removeItem('persist:root');
  const navigate = (link) => {
    history.push(link);
  };

  const item = localStorage.getItem('form');
  const monLocalStorageEntreprise = item ? JSON.parse(item) : {};
  const item2 = localStorage.getItem('sci-form');
  const monLocalStorageSci = item2 ? JSON.parse(item2) : {};
  // const item3 = localStorage.getItem('autoentrepreneur local storage name');
  // const monLocalStorageAutoentrepreneur = item3 ? JSON.parse(item) : {};

  // This part for handle re-init company popup
  const handleReInitCompany = () => {
    localStorage.removeItem('form');
    localStorage.removeItem('sci-form');
    setOpenReInitCompany(false);
  };

  const handleCloseReInitCompany = () => {
    setOpenReInitCompany(false);
    localStorage.removeItem('sci-form');
  };

  const messageReInitCompany =
    'Reinitialiser le formulaire de création de société ?';

  useEffect(() => {
    if (monLocalStorageEntreprise.company_name) {
      setOpenReInitCompany(true);
    }
  }, []);

  // This part for handle re-init SCI popup
  const handleReInitSCI = () => {
    localStorage.removeItem('sci-form');
    localStorage.removeItem('form');
    setOpenReInitSCI(false);
  };
  const handleCloseReInitSCI = () => {
    localStorage.removeItem('form');
    setOpenReInitSCI(false);
  };
  const messageReInitSCI = 'Reinitialiser le formulaire de création de SCI?';

  useEffect(() => {
    if (monLocalStorageSci.company_name) {
      setOpenReInitSCI(true);
    }
  }, []);

  const renderAlertPopup = (open, message, handleClickFn, handleCloseFn) => {
    return (
      <div>
        {/* Alert popup */}
        <Dialog
          open={open}
          // onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`${message}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickFn} className={classes.btn_primary}>
              Oui
            </Button>
            <Button onClick={handleCloseFn}>Non</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const getKeyByValue = (object, value) => {
    const values = _.flatten(Object.values(object));
    const keys = Object.keys(object);
    let result = null;

    keys.forEach((key) => {
      for (let i = 0; i < values.length; i += 1) {
        if (!object[key][i]) break;

        if (object[key][i] === value) {
          result = key;
          return result;
        }
      }
      return result;
    });
    return result;
  };

  const navigateScreenByKit = () => {
    const screen = getKeyByValue(KitParams, kit);
    switch (screen) {
      case SOCIÉTE: {
        return navigate(`/entreprise?kit=${kit}`);
      }
      case AUTOENTREPRENEUR: {
        return navigate(`/autoentrepreneur?kit=${kit}`);
      }
      case ENTREPRISEINDIVIDUELLE: {
        return navigate(`/nom-propre?kit=${kit}`);
      }
      case SCI: {
        return navigate(`/sci?kit=${kit}`);
      }
      case ASSOCIATION: {
        return navigate(`/association?kit=${kit}`);
      }
      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    if (kit) {
      navigateScreenByKit();
    }
  }, []);

  return (
    <Box>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem
          button
          onClick={() => navigate(`/entreprise${kit && `?kit=${kit}`}`)}
        >
          <ListItemText primary="Je crée ma société" />
        </ListItem>

        <ListItem
          button
          onClick={() => navigate(`/autoentrepreneur${kit && `?kit=${kit}`}`)}
          disabled
        >
          <ListItemText primary="Je crée mon autoentreprise" />
        </ListItem>

        <ListItem
          button
          onClick={() => navigate(`/nom-propre${kit && `?kit=${kit}`}`)}
          disabled
        >
          <ListItemText primary="Je crée mon entreprise en nom propre" />
        </ListItem>

        <ListItem
          button
          onClick={() => navigate(`/sci${kit && `?kit=${kit}`}`)}
        >
          <ListItemText primary="Je crée ma SCI" />
        </ListItem>

        <ListItem
          button
          onClick={() => navigate(`/association${kit && `?kit=${kit}`}`)}
        >
          <ListItemText primary="Je crée mon association" />
        </ListItem>

        <ListItem
          button
          onClick={() => navigate(`/modification${kit && `?kit=${kit}`}`)}
          disabled
        >
          <ListItemText primary="Je modifie ma situation ou mes statuts" />
        </ListItem>
      </List>
      {renderAlertPopup(
        openReInitCompany,
        messageReInitCompany,
        handleReInitCompany,
        handleCloseReInitCompany
      )}

      {renderAlertPopup(
        openReInitSCI,
        messageReInitSCI,
        handleReInitSCI,
        handleCloseReInitSCI
      )}
    </Box>
  );
};

export default ChoixDemarrage;
