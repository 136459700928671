/* eslint-disable import/extensions */
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';

import {
  // MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { RadioGroup, CheckboxWithLabel, TextField } from 'formik-material-ui';
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  MenuItem,
  Grid,
} from '@material-ui/core';

import ButtonBox from '../entreprise/ButtonBox';
import DebugFormik from '../DebugFormik';
import { DispatchContext } from '../../contexts/autoentrepreneur.context';
import { EDIT_COMPANY } from '../../constants/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignItems: 'baseline',
    padding: '20px',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    justifyContent: 'center',
    width: '100%',
  },
}));

const MonProjetAutoEntreprise = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formValues}
      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        setActiveStep(activeStep + 1);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Box
            display="flex"
            p={1}
            bgcolor="background.paper"
            size="fullwidth"
            className={classes.root}
          >
            <Typography variant="h3">
              1. Mon projet d&apos;auto-entreprise
            </Typography>

            <Typography variant="h6">
              <b>Mon activité principale</b>
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Serez-vous auto-entrepreneur à titre exclusif ?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={RadioGroup}
                  name="auto_entrepreneur_à_titre_exclusif"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            {/* If Non */}
            {values.auto_entrepreneur_à_titre_exclusif === 'false' && (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">
                    Serez-vous simultanément ?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    className={classes.field}
                    fullWidth
                    component={TextField}
                    select
                    margin="normal"
                    name="serez_vous_simultanément"
                    label="Serez-vous simultanément ?"
                  >
                    <MenuItem value={1}>Salarié</MenuItem>
                    <MenuItem value={2}>Salarié agricole</MenuItem>
                    <MenuItem value={2}>Non salarié non agricole</MenuItem>
                    <MenuItem value={3}>Retraité</MenuItem>
                    <MenuItem value={4}>Retraité pensionné</MenuItem>
                    <MenuItem value={5}>Pensionné invalidité</MenuItem>
                    <MenuItem value={6}>Pensionné invalidité</MenuItem>
                  </Field>
                </Grid>
              </Grid>
            )}

            {/* End */}
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Je souhaite exercer l&apos;activité
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  className={classes.field}
                  fullWidth
                  component={TextField}
                  select
                  margin="normal"
                  name="activity"
                  label="Je souhaite exercer l'activité"
                  defaultValue=""
                >
                  <MenuItem value="Accompagnateur de groupe">
                    Accompagnateur de groupe
                  </MenuItem>
                  <MenuItem value="Accompagnateur de moyenne montagne">
                    Accompagnateur de moyenne montagne
                  </MenuItem>
                  <MenuItem value="Achat/revente d’objets d’occasion (CD, vinyles, etc.)">
                    Achat/revente d’objets d’occasion (CD, vinyles, etc.)
                  </MenuItem>
                  <MenuItem value="Achat/revente d’objets neufs">
                    Achat/revente d’objets neufs
                  </MenuItem>
                  <MenuItem value="Achat/revente de jeux et jouets">
                    Achat/revente de jeux et jouets
                  </MenuItem>
                  <MenuItem value="Achat/revente de meubles">
                    Achat/revente de meubles
                  </MenuItem>
                  <MenuItem value="Achat/revente de papeterie">
                    Achat/revente de papeterie
                  </MenuItem>
                  <MenuItem value="Achat/revente de pièces détachées pour véhicules">
                    Achat/revente de pièces détachées pour véhicules
                  </MenuItem>
                  <MenuItem value="Achat/revente de produits alimentaires comme la vente de fruits et légumes">
                    Achat/revente de produits alimentaires comme la vente de
                    fruits et légumes
                  </MenuItem>
                  <MenuItem value="Achat/revente de produits de beautés">
                    Achat/revente de produits de beautés
                  </MenuItem>
                  <MenuItem value="Achat/revente de vêtements d’occasion (friperie)">
                    Achat/revente de vêtements d’occasion (friperie)
                  </MenuItem>
                  <MenuItem value="Achat/revente de vêtements neufs">
                    Achat/revente de vêtements neufs
                  </MenuItem>
                  <MenuItem value="Achat/revente de voiture et de véhicules légers">
                    Achat/revente de voiture et de véhicules légers
                  </MenuItem>
                  <MenuItem value="Agent de sécurité">
                    Agent de sécurité
                  </MenuItem>
                  <MenuItem value="Aménagement, agencement et finition">
                    Aménagement, agencement et finition
                  </MenuItem>
                  <MenuItem value="Analyste programmeur">
                    Analyste programmeur
                  </MenuItem>
                  <MenuItem value="Animateur">Animateur</MenuItem>
                  <MenuItem value="Archéologue">Archéologue</MenuItem>
                  <MenuItem value="Art-thérapeute">Art-thérapeute</MenuItem>
                  <MenuItem value="Artiste non créateur d'oeuvres originales">
                    Artiste non créateur d&apos;oeuvres originales
                  </MenuItem>
                  <MenuItem value="Assistant informatique">
                    Assistant informatique
                  </MenuItem>
                  <MenuItem value="Astrologue">Astrologue</MenuItem>
                  <MenuItem value="Attaché de presse">
                    Attaché de presse
                  </MenuItem>
                  <MenuItem value="Auteur de mots croisés">
                    Auteur de mots croisés
                  </MenuItem>
                  <MenuItem value="Attaché de presse">
                    Attaché de presse
                  </MenuItem>
                  <MenuItem value="Auto-école (si exploitant et moniteur)">
                    Auto-école (si exploitant et moniteur)
                  </MenuItem>
                  <MenuItem value="Cartographe">Cartographe</MenuItem>
                  <MenuItem value="Cartomancienne">Cartomancienne</MenuItem>
                  <MenuItem value="Chambre d’hôte">Chambre d’hôte</MenuItem>
                  <MenuItem value="Chiromancien">Chiromancien</MenuItem>
                  <MenuItem value="Coach">Coach</MenuItem>
                  <MenuItem value="Coach sportif">Coach sportif</MenuItem>
                  <MenuItem value="Coiffeur à domicile">
                    Coiffeur à domicile
                  </MenuItem>
                  <MenuItem value="Coloriste conseil">
                    Coloriste conseil
                  </MenuItem>
                  <MenuItem value="Concepteur de logiciel">
                    Concepteur de logiciel
                  </MenuItem>
                  <MenuItem value="Concepteur rédacteur">
                    Concepteur rédacteur
                  </MenuItem>
                  <MenuItem value="Conférencier">Conférencier</MenuItem>
                  <MenuItem value="Conseil (artistique, en gestion, en communication, d'entreprise, conjugal, etc.)">
                    Conseil (artistique, en gestion, en communication,
                    d&apos;entreprise, conjugal, etc.)
                  </MenuItem>
                  <MenuItem value="Consultant">Consultant</MenuItem>
                  <MenuItem value="Contrôleur d'ascenseurs">
                    Contrôleur d&apos;ascenseurs
                  </MenuItem>
                  <MenuItem value="Contrôleur technique à la construction">
                    Contrôleur technique à la construction
                  </MenuItem>
                  <MenuItem value="Coordinateur de travaux">
                    Coordinateur de travaux
                  </MenuItem>
                  <MenuItem value="Copiste">Copiste</MenuItem>
                  <MenuItem value="Cordonnier">Cordonnier</MenuItem>
                  <MenuItem value="Correcteur lecteur">
                    Correcteur lecteur
                  </MenuItem>
                  <MenuItem value="Correspondant local de presse">
                    Correspondant local de presse
                  </MenuItem>
                  <MenuItem value="Correspondant sportif">
                    Correspondant sportif
                  </MenuItem>
                  <MenuItem value="Coureur automobile">
                    Coureur automobile
                  </MenuItem>
                  <MenuItem value="Coursier / Livreur">
                    Coursier / Livreur
                  </MenuItem>
                  <MenuItem value="Créateur de sites internet">
                    Créateur de sites internet
                  </MenuItem>
                  <MenuItem value="Déménagement">Déménagement</MenuItem>
                  <MenuItem value="Designer">Designer</MenuItem>
                  <MenuItem value="Dessinateur">Dessinateur</MenuItem>
                  <MenuItem value="Dessinateur en dessin industriel">
                    Dessinateur en dessin industriel
                  </MenuItem>
                  <MenuItem value="Développeur de logiciels">
                    Développeur de logiciels
                  </MenuItem>
                  <MenuItem value="Digital nomad ">Digital nomad</MenuItem>
                  <MenuItem value="Documentaliste">Documentaliste</MenuItem>
                  <MenuItem value="Ecrivain public">Ecrivain public</MenuItem>
                  <MenuItem value="Educateur sportif">
                    Educateur sportif
                  </MenuItem>
                  <MenuItem value="Enseignant">Enseignant</MenuItem>
                  <MenuItem value="Entretien et réparation d’objets d’art">
                    Entretien et réparation d’objets d’art
                  </MenuItem>
                  <MenuItem value="Entretien et réparation de bicyclette et motocyclette">
                    Entretien et réparation de bicyclette et motocyclette
                  </MenuItem>
                  <MenuItem value="Entretien et réparation de machines de bureau et de matériel informatique">
                    Entretien et réparation de machines de bureau et de matériel
                    informatique
                  </MenuItem>
                  <MenuItem value="Esthéticienne">Esthéticienne</MenuItem>
                  <MenuItem value="Fabricant d'instruments de musique">
                    Fabricant d&apos;instruments de musique
                  </MenuItem>
                  <MenuItem value="Fabricant de bijoux et d’horlogerie">
                    Fabricant de bijoux et d’horlogerie
                  </MenuItem>
                  <MenuItem value="Fabricant de jeux et de jouets">
                    Fabricant de jeux et de jouets
                  </MenuItem>
                  <MenuItem value="Fabricant de textiles">
                    Fabricant de textiles
                  </MenuItem>
                  <MenuItem value="Fabricant de vêtements">
                    Fabricant de vêtements
                  </MenuItem>
                  <MenuItem value="Fabrication de meubles">
                    Fabrication de meubles
                  </MenuItem>
                  <MenuItem value="Fleuriste">Fleuriste</MenuItem>
                  <MenuItem value="Formateur">Formateur</MenuItem>
                  <MenuItem value="Généalogiste">Généalogiste</MenuItem>
                  <MenuItem value="Géophysicien">Géophysicien</MenuItem>
                  <MenuItem value="Graphiste">Graphiste</MenuItem>
                  <MenuItem value="Graphologue">Graphologue</MenuItem>
                  <MenuItem value="Guide conférencier">
                    Guide conférencier
                  </MenuItem>
                  <MenuItem value="Guide haute montagne">
                    Guide haute montagne
                  </MenuItem>
                  <MenuItem value="Guide interprète">Guide interprète</MenuItem>
                  <MenuItem value="Historien">Historien</MenuItem>
                  <MenuItem value="Hydrogéologue">Hydrogéologue</MenuItem>
                  <MenuItem value="Iconographe">Iconographe</MenuItem>
                  <MenuItem value="Illustrateur">Illustrateur</MenuItem>
                  <MenuItem value="Infographiste">Infographiste</MenuItem>
                  <MenuItem value="Informaticien">Informaticien</MenuItem>
                  <MenuItem value="Ingénieur conseil">
                    Ingénieur conseil
                  </MenuItem>
                  <MenuItem value="Ingénieur d'affaires">
                    Ingénieur d&apos;affaires
                  </MenuItem>
                  <MenuItem value="Ingénieur du son">Ingénieur du son</MenuItem>
                  <MenuItem value="Intermédiaire de commerce">
                    Intermédiaire de commerce
                  </MenuItem>
                  <MenuItem value="Interprète (conférencier, guide, traducteur)">
                    Interprète (conférencier, guide, traducteur)
                  </MenuItem>
                  <MenuItem value="Inventeur">Inventeur</MenuItem>
                  <MenuItem value="Joueur professionnel (sport individuel, bridge, etc.)">
                    Joueur professionnel (sport individuel, bridge, etc.)
                  </MenuItem>
                  <MenuItem value="Location meublée">Location meublée</MenuItem>
                  <MenuItem value="Maçon">Maçon</MenuItem>
                  <MenuItem value="Magnétiseur">Magnétiseur</MenuItem>
                  <MenuItem value="Médiéviste">Médiéviste</MenuItem>
                  <MenuItem value="Médium">Médium</MenuItem>
                  <MenuItem value="Menuisier">Menuisier</MenuItem>
                  <MenuItem value="Moniteur de ski">Moniteur de ski</MenuItem>
                  <MenuItem value="Moniteur de sports">
                    Moniteur de sports
                  </MenuItem>
                  <MenuItem value="Musicothérapeute">Musicothérapeute</MenuItem>
                  <MenuItem value="Naturaliste">Naturaliste</MenuItem>
                  <MenuItem value="Naturopathe">Naturopathe</MenuItem>
                  <MenuItem value="Numérologue">Numérologue</MenuItem>
                  <MenuItem value="Océanographe">Océanographe</MenuItem>
                  <MenuItem value="Oenologue">Oenologue</MenuItem>
                  <MenuItem value="Organisateur de foires et salons">
                    Organisateur de foires et salons
                  </MenuItem>
                  <MenuItem value="Ouvrir son cabinet de sophrologie ">
                    Ouvrir son cabinet de sophrologie
                  </MenuItem>
                  <MenuItem value="Plasticien conseil">
                    Plasticien conseil
                  </MenuItem>
                  <MenuItem value="Plombier">Plombier</MenuItem>
                  <MenuItem value="Préparateur de site et terrassement">
                    Préparateur de site et terrassement
                  </MenuItem>
                  <MenuItem value="Préparateur physique à domicile">
                    Préparateur physique à domicile
                  </MenuItem>
                  <MenuItem value="Professeur (de musique, de sports, de langues, etc.)">
                    Professeur (de musique, de sports, de langues, etc.)
                  </MenuItem>
                  <MenuItem value="Psychanalyste">Psychanalyste</MenuItem>
                  <MenuItem value="Radiesthésiste">Radiesthésiste</MenuItem>
                  <MenuItem value="Ramoneur">Ramoneur</MenuItem>
                  <MenuItem value="Rédacteur">Rédacteur</MenuItem>
                  <MenuItem value="Rédacteur documentaliste">
                    Rédacteur documentaliste
                  </MenuItem>
                  <MenuItem value="Rédacteur scientifique">
                    Rédacteur scientifique
                  </MenuItem>
                  <MenuItem value="Relations presse">Relations presse</MenuItem>
                  <MenuItem value="Relations publiques">
                    Relations publiques
                  </MenuItem>
                  <MenuItem value="Répétiteur">Répétiteur</MenuItem>
                  <MenuItem value="Restauration de meubles">
                    Restauration de meubles
                  </MenuItem>
                  <MenuItem value="Serrurier">Serrurier</MenuItem>
                  <MenuItem value="Service à la personne (garde d'enfants, auto-entrepreneur jardinage et petit bricolage, etc)">
                    Service à la personne (garde d&apos;enfants,
                    auto-entrepreneur jardinage et petit bricolage, etc)
                  </MenuItem>
                  <MenuItem value="Skipper">Skipper</MenuItem>
                  <MenuItem value="Sociologue">Sociologue</MenuItem>
                  <MenuItem value="Spéléologue">Spéléologue</MenuItem>
                  <MenuItem value="Sportif professionnel">
                    Sportif professionnel
                  </MenuItem>
                  <MenuItem value="Sténotypiste de conférences">
                    Sténotypiste de conférences
                  </MenuItem>
                  <MenuItem value="Styliste">Styliste</MenuItem>
                  <MenuItem value="Taxi">Taxi</MenuItem>
                  <MenuItem value="Toilettage d'animaux de compagnie">
                    Toilettage d&apos;animaux de compagnie
                  </MenuItem>
                  <MenuItem value="Traducteur">Traducteur</MenuItem>
                  <MenuItem value="Transcripteur">Transcripteur</MenuItem>
                  <MenuItem value="Travaux d'installation électrique et d'isolation">
                    Travaux d&apos;installation électrique et d&apos;isolation
                  </MenuItem>
                  <MenuItem value="Travaux sous-marins de forage">
                    Travaux sous-marins de forage
                  </MenuItem>
                  <MenuItem value="Vente à distance">Vente à distance</MenuItem>
                  <MenuItem value="Voyante">Voyante</MenuItem>
                  <MenuItem value="VTC">VTC</MenuItem>
                </Field>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={0} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="je_ne_trouve_pas_mon_activité_dans_cette_liste"
                  Label={{
                    label: 'Je ne trouve pas mon activité dans cette liste',
                  }}
                />
              </Grid>
            </Grid>

            {/* If checked */}
            {values.je_ne_trouve_pas_mon_activité_dans_cette_liste === true && (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
              >
                <Grid item xs={0} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">
                    Décrivez votre activité le plus précisément possible puis
                    indiquez sa nature
                  </Typography>
                  <Field
                    className={classes.field}
                    component={TextField}
                    fullWidth
                    name="décrivez_votre_activité_le_plus_précisément_possible_puis_indiquez_sa_nature"
                    type="text"
                    label=""
                  />
                </Grid>
              </Grid>
            )}
            {/* End */}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Date de début d&apos;activité
                </Typography>
              </Grid>
              <Grid item xs={12} maxwidth>
                <Field
                  className={classes.field}
                  component={KeyboardDatePicker}
                  clearable={true}
                  name="date_of_Birth"
                  format="dd/MM/yyyy"
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Avez-vous déjà exercé une activité non salariée en France ?
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  component={RadioGroup}
                  name="vous_déjà_exercé_une_activité_non_salariée_en_France"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            {/* If Oui */}
            {values.vous_déjà_exercé_une_activité_non_salariée_en_France ===
              'true' && (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">
                    Rappelez votre numéro unique d’identification (Siren)
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6}>
                  <Field
                    className={classes.field}
                    component={TextField}
                    fullWidth
                    name="rappelez_votre_numéro_unique"
                    type="text"
                    label="Rappelez votre numéro unique d’identification (Siren)"
                  />
                </Grid>
              </Grid>
            )}
            <br />
            {/* End */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Votre activité est exercée à titre
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  component={RadioGroup}
                  name="votre_activité_est_exercée_à_titre"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="permanente"
                        control={<Radio />}
                        label="Permanente"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="saisonnière"
                        control={<Radio />}
                        label="Saisonnière"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Souhaitez-vous exercer une ou plusieurs activités secondaires
                  ?
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  component={RadioGroup}
                  name="souhaitez_vous_exercer_une_ou_plusieurs_activités_secondaires"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            {/* If Oui */}

            {values.souhaitez_vous_exercer_une_ou_plusieurs_activités_secondaires ===
              'true' && (
              <div>
                <Typography variant="h6">
                  Veuillez indiquer ci-dessous l&apos;ensemble des activités
                  secondaires que vous souhaitez exercer
                </Typography>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="activités"
                  type="text"
                  label=""
                />
              </div>
            )}
            {/* End */}
            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Nom commercial</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="nom_commercial"
                  type="text"
                  label="Nom commercial"
                />
              </Grid>
            </Grid>
          </Box>
          {/* <button type="submit">Submit</button> */}
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

export default MonProjetAutoEntreprise;
