import { FormControlLabel, Radio } from '@material-ui/core';
import QuestionMark from 'components/commons/QuestionMark';
import { Field, useFormikContext } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { getHelpContent } from 'helpers/generalHelpers';
import React from 'react';

function RadioButton({
  classes,
  title,
  options,
  value,
  name,
  style,
  helpName,
  errorText,
}) {
  const { touched, errors, values } = useFormikContext();

  return (
    <>
      {touched[name] && errors[name] && (
        <div className={classes.radioButtonError}>{errorText}</div>
      )}
      <div className={classes.radioButtonWithHelpIconContainer}>
        <h2 className={classes.radioTitle}>{title}</h2>
        {helpName && (
          <QuestionMark
            classes={classes}
            formulaireComplet={values}
            content={getHelpContent(helpName)}
          />
        )}
      </div>
      <Field component={RadioGroup} name={name} style={style} value={value}>
        {options.map((option) => (
          <FormControlLabel
            className={classes.radioButtonLabel}
            key={option.value}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
            disabled={option.disabled}
          />
        ))}
      </Field>
    </>
  );
}

export default RadioButton;
