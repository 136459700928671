/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

import { RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const SignupSchema = Yup.object().shape({
  partner_wedding_prenup: Yup.string().required('un choix est necessaire'),
});

const ChoixContratDeMariage = ({
  partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_PARTNER,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        // if (
        //   values.partner_wedding_prenup === 'non' &&
        //   formValues.alone === 'a_plusieurs'
        // ) {
        //   Automate.demandeInfoApportsPartenaire();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        // if (values.partner_wedding_prenup === 'oui') {
        //   Automate.demandeInfoDuContratDeMariage();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        if (values.partner_wedding_prenup === 'oui') {
          Automate.nextChoixContratMariageVersInfoContratMariage();
          setAutomateState(Automate.state);
          return;
        }
        if (
          isRecap &&
          values.partner_wedding_prenup === 'non' &&
          formValues.alone === 'a_plusieurs'
        ) {
          Automate.nextChoixContratMariageVersInfoApportsPartenaire();
          setAutomateState(Automate.state);
          return;
        }
        if (
          isRecap &&
          values.partner_wedding_prenup === 'non' &&
          formValues.alone === 'seul'
        ) {
          Automate.recapChoixContratMariageVersListeFin();
          setAutomateState(Automate.state);
          return;
        }

        if (!isRecap) {
          if (
            values.partner_wedding_prenup === 'non' &&
            formValues.alone === 'seul'
          ) {
            Automate.nextChoixContratMariageVersChoixGerant();
            setAutomateState(Automate.state);
            return;
          }
          if (
            values.partner_wedding_prenup === 'non' &&
            formValues.alone === 'a_plusieurs'
          ) {
            Automate.nextChoixContratMariageVersInfoApportsPartenaire();
            setAutomateState(Automate.state);
            return;
          }

          setActiveStep(activeStep + 1);
        }
        // Automate.next();
        // setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                Avez-vous conclu un contrat de mariage ?
              </Typography>

              <Field
                component={RadioGroup}
                defaultValue="oui"
                // name={`partners[${partnerIndex}].partner_wedding_prenup`}
                name="partner_wedding_prenup"
              >
                <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="non" control={<Radio />} label="Non" />
              </Field>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixContratDeMariage.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixContratDeMariage;
