import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  // MenuItem,
} from '@material-ui/core';

import { TextField, RadioGroup } from 'formik-material-ui';
// import { Select } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
import KeyboardDatePicker from 'components/KeyboardDatePicker';

// import FormControl from '@material-ui/core/FormControl';

// import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import Paper from '../surface/Paper';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '90ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '80ch',
  },
  radio: {},
}));

const DureeAssociation = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  {' '}
                  Cotisations et droit d’entrée{' '}
                </Typography>
              </Box>

              <Box className={classes.root}>
                <Field
                  component={RadioGroup}
                  name="montant_cotisation_revu_chaque_année"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Le montant de la cotisation annuelle est-il revu chaque
                      année par l&apos;organe de direction
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>

              {/* {formValues.fondateurs === 'oui' && ( */}
              {formValues.fondateurs === 'oui' && (
                <Box alignSelf="center">
                  <Field
                    component={TextField}
                    type="string"
                    label="Quel est le montant de la cotisation pour les fondateurs?"
                    name="cotisation_fondateurs"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />
                </Box>
              )}

              {formValues.adherents === 'oui' && (
                <Box alignSelf="center">
                  <Field
                    component={TextField}
                    type="string"
                    label="Quel est le montant de la cotisation pour les adherents?"
                    name="cotisation_adherents"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />
                </Box>
              )}

              {formValues.bienfaiteurs === 'oui' && (
                <Box alignSelf="center">
                  <Field
                    component={TextField}
                    type="string"
                    label="Quel est le montant de la cotisation pour les bienfaiteurs?"
                    name="cotisation_bienfaiteurs"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />
                </Box>
              )}

              {formValues.membres_de_droit === 'oui' && (
                <Box alignSelf="center">
                  <Field
                    component={TextField}
                    type="string"
                    label="Quel est le montant de la cotisation pour les membres de droit?"
                    name="cotisation_membres_de_droit"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />
                </Box>
              )}

              <Box className={classes.root}>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date d’appel de la cotisation annuelle :"
                  placeholder="01/01/1990"
                  className={classes.birthdayField}
                  format="dd/MM/yyyy"
                  name="date_appel_cotisation_annuelle"
                  autoComplete="off"
                />
              </Box>

              <Box className={classes.root}>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date limite de paiement de la cotisation annuelle :"
                  placeholder="01/01/1990"
                  className={classes.birthdayField}
                  format="dd/MM/yyyy"
                  name="date_limite_paiement_cotisation_annuelle"
                  autoComplete="off"
                />
              </Box>

              <Box className={classes.root}>
                <Field component={RadioGroup} name="droit_entree">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Existe-t-il un droit d&apos;entrée ?
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>

              {/* {values.droit_entree === 'oui' && formValues.adherents === 'oui' && ( */}
              {values.droit_entree === 'oui' && formValues.adherents === 'oui' && (
                <Box alignSelf="center">
                  <Field
                    component={TextField}
                    type="string"
                    label="Quel est le montant du droit d'entrée pour les adhèrents?"
                    name="droit_entree_adherents"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />
                </Box>
              )}

              {values.droit_entree === 'oui' &&
                formValues.bienfaiteurs === 'oui' && (
                  <Box alignSelf="center">
                    <Field
                      component={TextField}
                      type="string"
                      label="Quel est le montant du droit d'entrée pour les bienfaiteurs?"
                      name="droit_entree_bienfaiteurs"
                      className={classes.nameTextField}
                      autoComplete="off"
                    />
                  </Box>
                )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

DureeAssociation.propTypes = {
  nextForm: PropTypes.func,
};
export default DureeAssociation;
