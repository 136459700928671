import {
  dureeExercieCAC,
  cacTypes,
  YES,
  yesNo,
  CAC_PP,
  CAC_PM,
} from 'constants/usersContants';
import { useFormikContext } from 'formik';
import React from 'react';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';
import InfoPM from './InfoPM';
import InfoPP from './InfoPP';

function CAC() {
  const classes = useStyles();
  const {
    values: {
      presence_commissaire_aux_comptes,
      duree_exercice_commissaire_aux_comptes,
      type_CAC,
    },
  } = useFormikContext();

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Commissaire aux comptes</h1>
      <div className={classes.root}>
        <SmallRadio
          classes={classes}
          title="Souhaitez-vous nommer un commissaire aux comptes pour votre société en création?"
          options={yesNo}
          value={presence_commissaire_aux_comptes}
          name="presence_commissaire_aux_comptes"
          style={{ marginBottom: '5%' }}
          helpName="cac"
        />
        {YES === presence_commissaire_aux_comptes && (
          <>
            <SmallRadio
              classes={classes}
              title="Nombre d'exercices du commissaire aux comptes"
              options={dureeExercieCAC}
              value={duree_exercice_commissaire_aux_comptes}
              name="duree_exercice_commissaire_aux_comptes"
            />
            <h2 className={classes.subTitle}>
              Informations sur le commissaire aux comptes titulaire.
            </h2>
            <SmallRadio
              classes={classes}
              title="Type du commissaire aux comptes."
              options={cacTypes}
              value={type_CAC}
              name="type_CAC"
              style={{ marginBottom: '5%' }}
            />
            {type_CAC === CAC_PP && <InfoPP />}
            {type_CAC === CAC_PM && <InfoPM />}
          </>
        )}
      </div>
    </div>
  );
}

export default CAC;
