import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  FormControlLabel,
  Radio,
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField, RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

// import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import Paper from '../surface/Paper';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '80ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '80ch',
  },
  radio: {},
}));

// const SignupSchema = Yup.object().shape({

//   siege_social_chez: Yup.string().required('un choix est necessaire'),
//   proprietaire: Yup.string().required('un choix est necessaire'),
//   maison_individuelle: Yup.string().required('un choix est necessaire'),

//   prenom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   nom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   adresse_syndic: Yup.string().required('Required'),
//   prenom_bailleur: Yup.string().required('Required'),
//   nom_bailleur: Yup.string().required('Required'),
//   adresse_bailleur: Yup.string().required('Required'),
//   date_bail: Yup.date().required('Required'),
// });
// const { values, setFormValue } = useFormikContext();

const InfoSociete2 = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue, fieldName) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue(fieldName, newValue.properties.label);
    } else {
      setFieldValue(fieldName, '');
      setAddressValue(newValue);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     siege_social_chez: Yup.string().required('un choix est necessaire'),
      //     proprietaire: Yup.string().required('un choix est necessaire'),
      //     maison_individuelle: Yup.string().required('un choix est necessaire'),

      //     prenom_syndic: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
      //       (siege_social_chez, proprietaire, maison_individuelle) => {
      //         if (siege_social_chez === 'chez_gerant') {
      //           if (proprietaire === 'Proprietaire') {
      //             if (maison_individuelle === 'non') {
      //               return Yup.string().required('Required');
      //             }
      //           }
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     nom_syndic: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
      //       (siege_social_chez, proprietaire, maison_individuelle) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Proprietaire' &&
      //           maison_individuelle === 'non'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     adresse_syndic: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
      //       (siege_social_chez, proprietaire, maison_individuelle) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Proprietaire' &&
      //           maison_individuelle === 'non'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     prenom_bailleur: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     nom_bailleur: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     adresse_bailleur: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //         return Yup.string();
      //       }
      //     ),

      //     date_bail: Yup.date().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.date().required('Required');
      //         }
      //         return Yup.date();
      //       }
      //     ),
      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        SciAutomate.nextInfoSociete2VersInfoSociete3();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  {' '}
                  Le siège de l&apos;entreprise{' '}
                </Typography>
              </Box>

              <Box className={classes.root} marginTop="20px" size="fullwidth">
                <Field component={RadioGroup} name="siege_social_chez">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Le siège social se situe :
                    </Typography>{' '}
                    <FormControlLabel
                      value="chez_gerant"
                      control={<Radio />}
                      label="Chez le dirigeant"
                    />
                    <FormControlLabel
                      value="local"
                      control={<Radio />}
                      label="Dans un local"
                    />
                    <FormControlLabel
                      value="en_domiciliation"
                      control={<Radio />}
                      label="En domiciliation"
                    />
                  </Box>
                </Field>
              </Box>

              {/* demande si liege social en locataire ou est proprietaire si il n'y apas de contrat de domiciliation  */}

              {values.siege_social_chez !== 'en_domiciliation' && (
                <Fragment>
                  <Box
                    className={classes.root}
                    marginTop="20px"
                    size="fullwidth"
                  >
                    <Field component={RadioGroup} name="proprietaire">
                      <Typography variant="subtitle1">
                        {' '}
                        Pour votre siège social êtes-vous ?
                      </Typography>{' '}
                      <FormControlLabel
                        value="Proprietaire"
                        control={<Radio />}
                        label="Propriétaire"
                      />
                      <FormControlLabel
                        value="Locataire"
                        control={<Radio />}
                        label="Locataire"
                      />
                    </Field>
                  </Box>
                </Fragment>
              )}

              {/* demande si siege dans une maison individuelle dans cas ou siege chez dirigeant qui est proprietaire  */}

              {values.siege_social_chez === 'chez_gerant' &&
                values.proprietaire === 'Proprietaire' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field component={RadioGroup} name="maison_individuelle">
                        <Typography variant="subtitle1">
                          {' '}
                          Le siège social est-il dans une maison individuelle ?
                        </Typography>{' '}
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="non"
                          control={<Radio />}
                          label="Non"
                        />
                      </Field>
                    </Box>
                  </Fragment>
                )}

              {/* demande les informations sur le syndic si siege pas dans une maison individuelle */}

              {values.siege_social_chez === 'chez_gerant' &&
                values.proprietaire === 'Proprietaire' &&
                values.maison_individuelle === 'non' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Box>
                        <Field
                          component={TextField}
                          type="string"
                          label="Prénom ou forme de la société du syndic de copropriété"
                          name="prenom_syndic"
                          className={classes.nameTextField}
                          autoComplete="off"
                        />
                      </Box>
                      <Box>
                        <Field
                          component={TextField}
                          type="string"
                          label="Nom ou raison sociale de la société du syndic de copropriété"
                          name="nom_syndic"
                          className={classes.nameTextField}
                          autoComplete="off"
                        />
                      </Box>
                      <Field
                        component={Autocomplete}
                        // type="string"
                        // label="Adresse du syndic de copropriété"
                        // placeholder="123 rue Bidon 57560 Paris"
                        name="adresse_syndic"
                        className={classes.nameTextField}
                        value={addressValue}
                        loading={loadingAddressList}
                        options={addressList}
                        filterOptions={(x) => x}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option && option.properties && option.properties.label
                        }
                        onInputChange={(event, newInputValue) =>
                          handleFetchAddress(newInputValue)
                        }
                        onChange={(event, newValue) =>
                          handleSelectAddress(
                            newValue,
                            setFieldValue,
                            'adresse_syndic'
                          )
                        }
                        renderInput={(params) => (
                          <MUITextField
                            {...params}
                            error={
                              touched.adresse_syndic && !!errors.adresse_syndic
                            }
                            helperText={
                              touched.adresse_syndic && errors.adresse_syndic
                            }
                            label="Adresse du syndic de copropriété"
                            placeholder={formValues.adresse_syndic}
                            size="small"
                          />
                        )}
                        renderOption={(option) => {
                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.properties.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                    </Box>
                  </Fragment>
                )}

              {/* demande les informations du bailleur si le siege est au domicile du dirigeant qui est locataire  */}

              {values.siege_social_chez === 'chez_gerant' &&
                values.proprietaire === 'Locataire' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field
                        component={TextField}
                        type="string"
                        label="Prénom ou forme de la société du bailleur "
                        name="prenom_bailleur"
                        className={classes.nameTextField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Nom ou raison sociale de la société du bailleur"
                        name="nom_bailleur"
                        className={classes.nameTextField}
                        autoComplete="off"
                      />
                      <Field
                        component={Autocomplete}
                        // type="string"
                        // label="Adresse du bailleur"
                        // placeholder="123 rue Bidon 57560 Paris"
                        name="adresse_bailleur"
                        className={classes.nameTextField}
                        value={addressValue}
                        loading={loadingAddressList}
                        options={addressList}
                        filterOptions={(x) => x}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option && option.properties && option.properties.label
                        }
                        onInputChange={(event, newInputValue) =>
                          handleFetchAddress(newInputValue)
                        }
                        onChange={(event, newValue) =>
                          handleSelectAddress(
                            newValue,
                            setFieldValue,
                            'adresse_bailleur'
                          )
                        }
                        renderInput={(params) => (
                          <MUITextField
                            {...params}
                            error={
                              touched.adresse_bailleur &&
                              !!errors.adresse_bailleur
                            }
                            helperText={
                              touched.adresse_bailleur &&
                              errors.adresse_bailleur
                            }
                            label="Adresse du bailleur"
                            placeholder={formValues.adresse_bailleur}
                            size="small"
                          />
                        )}
                        renderOption={(option) => {
                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.properties.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date du bail"
                        placeholder="01/01/1990"
                        className={classes.birthdayField}
                        format="dd/MM/yyyy"
                        name="date_bail"
                        autoComplete="off"
                      />
                    </Box>
                  </Fragment>
                )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoSociete2.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoSociete2;
