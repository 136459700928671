import React, { Fragment } from 'react';
import {
  Typography,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import FilePreview from 'components/filePreview/index';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import PdfPreviewInBrowser from 'components/filePreview/PdfPreviewInBrowser';
import useStyles from './styles';

const PreviewFileDialog = ({
  openingDocument,
  dialogVisible,
  setDialogVisible,
  setOpeningDocument,
  isViewInBrowser,
}) => {
  const classes = useStyles();
  const handleCloseDialog = () => {
    setDialogVisible(false);
    setOpeningDocument(null);
  };

  console.log('openingDocument', openingDocument);
  if (openingDocument) {
    console.log('openingDocument.fileType', openingDocument.fileType);
  }

  return (
    <Fragment>
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="file-preview-dialog-title"
        open={dialogVisible}
        maxWidth="md"
        fullScreen
      >
        {isViewInBrowser ? (
          <div className={classes.pdfWapper}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
            {openingDocument && <PdfPreviewInBrowser file={openingDocument} />}
          </div>
        ) : (
          <>
            <AppBar style={{ position: 'relative' }}>
              <Toolbar
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography variant="h6" component="div">
                  File Preview
                </Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCloseDialog}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            {openingDocument && (
              <FilePreview
                content={{
                  type:
                    openingDocument.fileType === 'application/pdf'
                      ? 'application/pdf'
                      : 'application/pdf',
                  value: openingDocument,
                }}
              />
            )}
          </>
        )}
      </Dialog>
    </Fragment>
  );
};

PreviewFileDialog.propTypes = {
  isViewInBrowser: PropTypes.bool,
};

export default PreviewFileDialog;
