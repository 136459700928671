export const SOCIÉTE = 'société';
export const AUTOENTREPRENEUR = 'autoEntrepreneur';
export const ASSOCIATION = 'association';
export const ENTREPRISEINDIVIDUELLE = 'entrepriseIndividuelle';

export const EURL = 'eurl';
export const SARL = 'sarl';
export const SAS = 'sas';
export const SASU = 'sasu';
export const SCI = 'sci';

export const SAS_SASU = 'sas-sasu';
export const SARL_EURL = 'sarl-eurl';

export const COMPANY_TYPES = new Map([
  [EURL, 'eurl'],
  [SARL, 'sarl'],
  [SAS, 'sas'],
  [SASU, 'sasu'],
  [SCI, 'sci'],
  [SAS_SASU, 'sas / sasu'],
  [SARL_EURL, 'sarl / eurl'],
]);
