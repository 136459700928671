import React, { useContext, useState, useRef, useEffect } from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  IconButton,
  Popover,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';
import axios from 'axios';

import dataSci from 'assets/__data__/sci';
import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';
import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '50ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '80ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  radio: {},
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

const SignupSchema = Yup.object().shape({
  company_name: Yup.string().required('Required'),

  // business_name: Yup.string().required('Required'),

  code_ape: Yup.string().required('Required'),

  address: Yup.string().required('Required'),

  rcs_ville: Yup.string().required('Required'),

  zipcode: Yup.string().required('Required'),

  city: Yup.string().required('Required'),
});

const InfoSociete = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();
  useEffect(() => {
    setActiveStep(0);
  }, []);

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchRCS = async (values, setFieldValue) => {
    const { zipcode, city } = values;
    const { data: res } = await axios.get(
      `${process.env.REACT_APP_URL_RCS}?zip=${zipcode}&city=${city}`
    );
    if (res && res.rcs) {
      setFieldValue('rcs_ville', res.rcs);
    } else {
      setFieldValue('rcs_ville', '');
    }
  };

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue('address', newValue.properties.name);
      setFieldValue('zipcode', newValue.properties.postcode);
      setFieldValue('city', newValue.properties.city);
      handleFetchRCS(
        {
          city: newValue.properties.city,
          zipcode: newValue.properties.postcode,
        },
        setFieldValue
      );
    } else {
      setFieldValue('address', '');
      setFieldValue('zipcode', '');
      setFieldValue('city', '');
      setAddressValue(newValue);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const redirect = query.get('mario') || '';

    if (redirect) {
      SciAutomate.xcv();
      setAutomateState(SciAutomate.state);
    }
  }, []);

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? 'simple-popover3' : undefined;

  const popoverTextAdresse = (
    <div className={classes.popOverDiv}>
      <Typography>
        Si votre adresse n’apparait pas dans le menu déroulant merci de la
        préciser avec le nom de la ville
      </Typography>
    </div>
  );

  return (
    <Formik
      initialValues={formValues}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        // SciAutomate.next();
        SciAutomate.nextInfoSocieteVersInfoSociete2();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        submitForm,
      }) => (
        <Form>
          <Paper className={classes.root}>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6"> La société en création </Typography>
              </Box>

              {/* <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Je crée une: "
                  defaultValue="sci"
                  name="sci"
                  className={classes.nameTextField}
                  autoComplete="off"
                  disabled
                />
              </Box> */}

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Dénomination sociale"
                  name="company_name"
                  className={classes.nameTextField}
                  autoComplete="off"
                />

                <Field
                  component={TextField}
                  type="string"
                  label="Nom commercial"
                  name="business_name"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box alignSelf="center" marginTop="10px">
                <Field
                  component={TextField}
                  type="string"
                  label="Objet social"
                  name="code_ape"
                  className={classes.textField}
                  placeholder=" La Société a pour objet l&#39;acquisition, la prise à bail, la gestion, la location et l&#39;administration de tous
                  biens mobiliers et immobiliers."
                  multiline
                  rows="4"
                  autoComplete="off"
                />
              </Box>

              <Box className={classes.root}>
                <Field
                  name="address"
                  component={Autocomplete}
                  className={classes.nameTextField}
                  value={addressValue}
                  loading={loadingAddressList}
                  options={addressList}
                  filterOptions={(x) => x}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option && option.properties && option.properties.name
                  }
                  onChange={(event, newValue) =>
                    handleSelectAddress(newValue, setFieldValue)
                  }
                  onInputChange={(event, newInputValue) =>
                    handleFetchAddress(newInputValue)
                  }
                  renderInput={(params) => (
                    <MUITextField
                      {...params}
                      error={touched.address && !!errors.address}
                      helperText={touched.address && errors.address}
                      label="Adresse du siège social"
                      placeholder={formValues.address}
                      size="small"
                    />
                  )}
                  renderOption={(option) => {
                    return (
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2" color="textSecondary">
                            {option.properties.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClick3}
                >
                  <HelpOutlineOutlined />
                </IconButton>
                <Popover
                  id={id3}
                  open={open3}
                  anchorEl={anchorEl3}
                  // marginThreshold="55"
                  onClose={handleClose3}
                  // anchorOrigin={{
                  //   vertical: 'center',
                  //   horizontal: 'center',
                  // }}
                  anchorReference={'none'}
                  className={classes.popOver}
                >
                  {popoverTextAdresse}
                </Popover>
              </Box>

              <Box className={classes.root}>
                <Field
                  component={TextField}
                  type="string"
                  label="Rue du siège social"
                  name="address"
                  className={classes.nameTextField}
                  autoComplete="off"
                  disabled
                />
              </Box>

              {/* <Box className={classes.root}>
                <Field
                  component={TextField}
                  type="string"
                  label="Adresse du siège social"
                  name="address"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box> */}

              <Box className={classes.root}>
                <Field
                  component={TextField}
                  type="int"
                  label="Code postal du siège social"
                  name="zipcode"
                  className={classes.nameTextField}
                  autoComplete="off"
                  onBlur={() => {
                    handleFetchRCS(values, setFieldValue);
                  }}
                  disabled
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Ville du siège social"
                  name="city"
                  className={classes.nameTextField}
                  autoComplete="off"
                  onBlur={() => handleFetchRCS(values, setFieldValue)}
                  disabled
                />
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
          <span
            style={{
              color: 'white',
              fontSize: '5px',
              margin: '0 50%',
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch({
                type: EDIT_COMPANY,
                payload: dataSci,
              });
              SciAutomate.xcv();
              setAutomateState(SciAutomate.state);
            }}
          >
            go to the end SCI
          </span>
        </Form>
      )}
    </Formik>
  );
};

InfoSociete.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoSociete;
