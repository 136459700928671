/* eslint-disable no-unused-vars */
import { Delete } from '@material-ui/icons';
import HelpWithTitle from 'components/commons/HelpWithTitle';
import { SAS, SARL, SCI, SASU } from 'constants/companies';
import {
  CONCUBIN,
  familySituations,
  FIVE_FIRST_NUMBER_LIST,
  genders,
  MARIED,
  PACTE,
  PARTNER,
  PP,
  TIERS,
  YES,
  yesNo,
} from 'constants/usersContants';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React, { useEffect, useState } from 'react';
import Adresse from '../commons/adress';
import CardButton from '../commons/cardButton';
import PartnerCard from '../commons/partnerCard';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';
import cleanInfoTiers from './selectCEOHelper';
import InfoWeddingCEO from './InfoWeddingCEO';
import InfoPacteCEO from './InfoPacseCEO';
import InfoCohabitantCEO from './InfoCohabitantCEO';

function SelectCEO({ formulaireComplet }) {
  // constants
  const classes = useStyles();
  const { partners, eurl_sasu } = formulaireComplet;

  // state
  const [gerants, setGerants] = useState([]);
  const [showMessageOnlyOneGerant, setShowMessageOnlyOneGerant] =
    useState(false);
  const {
    setFieldValue,
    values: {
      associe_gerant_multiassocie,
      director_title,
      gerantOuPresident,
      geranceoupresidence,
      duree_gerance_determinee,
      duree_de_gerance,
      director_type,
      director_family_situation,
    },
  } = useFormikContext();

  const addAsCEO = (idPartner, oldGerants) => {
    if (director_type === TIERS) {
      setFieldValue('director_type', PARTNER);
      cleanInfoTiers(setFieldValue);
    }
    if (eurl_sasu === SAS && oldGerants.length > 0) {
      setShowMessageOnlyOneGerant(true);
    } else {
      setShowMessageOnlyOneGerant(false);
      const updatedGerant = [...oldGerants];
      if (!updatedGerant.includes(idPartner)) {
        updatedGerant.push(idPartner);
        setFieldValue('associe_gerant_multiassocie', updatedGerant);
      }
    }
  };

  const removeCEO = (idGerant, oldGerants) => {
    setShowMessageOnlyOneGerant(false);
    let updatedGerant = [...oldGerants];
    updatedGerant = updatedGerant.filter((gerant) => gerant !== idGerant);
    if (updatedGerant) {
      setFieldValue('associe_gerant_multiassocie', updatedGerant);
    }
  };

  const getGerants = () => {
    const newGerants = [];
    partners.forEach((partner) => {
      const partnerIsPhysicalPerson = partner.personne_physique_morale === PP;
      if (
        associe_gerant_multiassocie.includes(partner.id) &&
        partnerIsPhysicalPerson
      ) {
        newGerants.push(partner);
      }
      if (
        associe_gerant_multiassocie.includes(partner.id) &&
        !partnerIsPhysicalPerson
      ) {
        const companyTypesInclude = [SASU, SAS];
        if (companyTypesInclude.includes(eurl_sasu)) {
          newGerants.push(partner);
          setFieldValue(
            'associe_gerant_multiassocie',
            associe_gerant_multiassocie
          );
        } else {
          const newAssocies = associe_gerant_multiassocie.filter(
            (itemAssocie) => itemAssocie !== partner.id
          );
          setFieldValue('associe_gerant_multiassocie', newAssocies);
        }
      }
    });
    setGerants(newGerants);
  };

  const addInfoTiers = () => {
    setShowMessageOnlyOneGerant(false);
    setFieldValue('director_type', TIERS);
    setFieldValue('associe_gerant_multiassocie', []);
  };

  const getTitle = () => {
    if (eurl_sasu === SARL || eurl_sasu === SCI) {
      return `Veuillez sélectionner le(s) ${gerantOuPresident}(e)s parmi les associé(e)s, si le(s) ${gerantOuPresident}(e)s est
        un tiers veuillez selectionner – Personne physique tierce – et remplir les
        informations demandées`;
    }
    return `Veuillez sélectionner le(a) ${gerantOuPresident}(e) parmi les associé(e)s, si le(a) ${gerantOuPresident}(e) est
      un tiers veuillez selectionner – Personne physique tierce – et remplir les
      informations demandées`;
  };

  const checkDisablePartnerCard = (partnerType, companyType) => {
    const companyTypesExclude = [SASU, SAS];
    return partnerType !== PP && !companyTypesExclude.includes(companyType);
  };

  useEffect(() => {
    if (associe_gerant_multiassocie.length) {
      getGerants();
    } else {
      setGerants([]);
    }
  }, [associe_gerant_multiassocie]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{`Choix du ${gerantOuPresident}`}</h1>
      <div className={classes.root}>
        <HelpWithTitle
          title={`Info concernant le choix du ${gerantOuPresident}`}
          helpName="gerant"
        />
        <ErrorMessage name="associe_gerant_multiassocie">
          {(msg) => (
            <div style={{ color: 'red', textAlign: 'left' }}>{msg}</div>
          )}
        </ErrorMessage>
        {showMessageOnlyOneGerant && (
          <div style={{ color: 'red', textAlign: 'left' }}>
            {`En SAS il n'y a qu'un(e) seul(e) président(e).`}
          </div>
        )}
        {gerants && gerants.length > 0 && (
          <>
            <h2 className={classes.subTitle}>{`${gerantOuPresident}(e)s`}</h2>
            <div>
              {gerants.map((gerant) => (
                <div key={gerant.id} style={{ display: 'flex', width: '100%' }}>
                  <div style={{ flex: 1 }}>
                    <PartnerCard
                      partner={gerant}
                      canEdit={false}
                      handleClick={() =>
                        removeCEO(gerant.id, associe_gerant_multiassocie)
                      }
                    />
                  </div>
                  <div
                    style={{
                      flex: 0.2,
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'center',
                      color: 'red',
                    }}
                  >
                    <Delete
                      onClick={() =>
                        removeCEO(gerant.id, associe_gerant_multiassocie)
                      }
                      style={{ fontSize: '2.2em', cursor: 'pointer' }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <h2 className={classes.subTitle}>{getTitle()}</h2>
        {partners.map((partner) => (
          <PartnerCard
            key={partner.id}
            partner={partner}
            handleClick={() =>
              addAsCEO(partner.id, associe_gerant_multiassocie)
            }
            canEdit={false}
            disabled={checkDisablePartnerCard(
              partner.personne_physique_morale,
              eurl_sasu
            )}
          />
        ))}
        <hr
          style={{
            width: '100%',
            border: '1px solid #3D319D',
            marginTop: '10%',
          }}
        />
        <CardButton
          handleClick={addInfoTiers}
          title="Personne physique tierce"
        />
        {TIERS === director_type && (
          <>
            <SmallRadio
              classes={classes}
              options={genders}
              value={director_title}
              name="director_title"
              style={{ marginBottom: '4%' }}
            />
            <Field
              component={TextField}
              type="string"
              label={`Nom du ${gerantOuPresident} *`}
              name="director_name"
              className={classes.nameTextField}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
            <Field
              component={TextField}
              type="string"
              label={`Prénom du ${gerantOuPresident} *`}
              name="director_firstname"
              className={classes.nameTextField}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
            <Field
              component={TextField}
              type="string"
              label={`Email du ${gerantOuPresident} *`}
              name="director_email"
              className={classes.nameTextField}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
            <h2 className={classes.subTitle}>Protection sociale</h2>
            <Field
              component={TextField}
              type="string"
              label="N° sécurité sociale *"
              name="director_secu_number"
              className={classes.nameTextField}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
            <h2 className={classes.subTitle}>
              {`Informations de naissance du ${gerantOuPresident}`}
            </h2>
            <Field
              component={KeyboardDatePicker}
              clearable
              label="Date de naissance *"
              placeholder="01/01/1990"
              format="dd/MM/yyyy"
              maxDate={new Date()}
              name="director_date_of_birth"
              className={classes.nameTextField}
              inputVariant="outlined"
              size="small"
            />
            <Adresse
              classes={classes}
              address={null}
              city="director_place_of_birth"
              zipCode="director_place_of_birth_zipCode"
              department="director_state_of_birth"
              country="director_country_of_birth"
              cityLabel="Ville de naissance *"
              countryLabel="Pays de naissance *"
              nationality="director_nationality"
            />
            <Adresse
              title={`Adresse du ${gerantOuPresident}`}
              classes={classes}
              address="director_place_of_living"
              city="director_city"
              zipCode="director_zipcode"
              department={null}
              country={null}
              cityLabel="Ville *"
              nationality={null}
              additionalAddress="director_additional_place_of_living"
            />
            <h2
              className={classes.subTitle}
            >{`Filiation du ${gerantOuPresident}`}</h2>
            <Field
              component={TextField}
              type="string"
              label="Nom du père *"
              name="director_father_name"
              className={classes.nameTextField}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
            <Field
              component={TextField}
              type="string"
              label="Prénom du père *"
              name="director_father_firstname"
              className={classes.nameTextField}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
            <Field
              component={TextField}
              type="string"
              label="Nom de mère *"
              name="director_mother_name"
              className={classes.nameTextField}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
            <Field
              component={TextField}
              type="string"
              label="Prénom de mère *"
              name="director_mother_firstname"
              className={classes.nameTextField}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
            <SmallRadio
              classes={classes}
              title={`La ${geranceoupresidence} est-elle à durée déterminée :`}
              options={yesNo}
              value={duree_gerance_determinee}
              name="duree_gerance_determinee"
              style={{ marginBottom: '4%' }}
            />
            {YES === duree_gerance_determinee && (
              <SmallRadio
                classes={classes}
                title={`Veuillez indiquer ci-dessous, le nombre d'années de
                ${geranceoupresidence} prévu:`}
                options={FIVE_FIRST_NUMBER_LIST}
                value={duree_de_gerance}
                name="duree_de_gerance"
                style={{ marginBottom: '4%' }}
              />
            )}

            {/* TO DO: info "Situation familiale" */}
            <SmallRadio
              classes={classes}
              title="Situation familiale *"
              options={familySituations}
              value={director_family_situation}
              name="director_family_situation"
            />
            {director_family_situation === MARIED && (
              <InfoWeddingCEO classes={classes} eurl_sasu={eurl_sasu} />
            )}
            {director_family_situation === PACTE && (
              <InfoPacteCEO classes={classes} eurl_sasu={eurl_sasu} />
            )}
            {director_family_situation === CONCUBIN && (
              <InfoCohabitantCEO classes={classes} eurl_sasu={eurl_sasu} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SelectCEO;
