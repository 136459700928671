/* eslint-disable no-console */
const StateMachine = require('javascript-state-machine');

// trying to instantiate a state machine with a non existing initial state across transitions will throw error
const AssociationAutomate = new StateMachine({
  init: 'info_societe',
  // init: 'info_societe2',
  // init: 'info_societe3',
  // init: 'duree_association',
  // init: 'organe_direction',
  // init: 'decisions_organe_direction',
  // init: 'membres_association',
  // init: 'cotisations',
  // init: 'decisions_assemblee_generale',
  // init: 'choix_commissaire_aux_comptes',
  // init: 'choix_nombre_actes_prealables',
  // init: 'info_actes_prealable',
  // init: 'assemblee_generale_constitutive',
  // init: 'presence_assemblee_generale_constitutive',
  // init: 'presence_assemblee_generale_constitutive1',
  // init: 'choix_present_supplementaire',
  // init: 'presence_assemblee_generale_constitutive2',
  // init: 'choix_represente_supplementaire',
  // init: 'presence_assemblee_generale_constitutive3',
  // init: 'premiers_membres_organe_direction',
  // init: 'choix_procuration_bbf',
  // init: 'recap',

  transitions: [
    {
      name: 'next',
      from: 'info_societe',
      to: 'info_societe2',
    },

    {
      name: 'next',
      from: 'info_societe2',
      to: 'info_societe3',
    },

    {
      name: 'next',
      from: 'info_societe3',
      to: 'duree_association',
    },

    {
      name: 'next',
      from: 'duree_association',
      to: 'organe_direction',
    },

    {
      name: 'next',
      from: 'organe_direction',
      to: 'decisions_organe_direction',
    },

    {
      name: 'next',
      from: 'decisions_organe_direction',
      to: 'membres_association',
    },

    {
      name: 'next',
      from: 'membres_association',
      to: 'cotisations',
    },

    {
      name: 'next',
      from: 'cotisations',
      to: 'decisions_assemblee_generale',
    },

    {
      name: 'next',
      from: 'decisions_assemblee_generale',
      to: 'choix_commissaire_aux_comptes',
    },

    {
      name: 'next',
      from: 'choix_commissaire_aux_comptes',
      to: 'choix_nombre_actes_prealables',
    },

    {
      name: 'next',
      from: 'choix_nombre_actes_prealables',
      to: 'info_actes_prealable',
    },

    {
      name: 'next',
      from: 'info_actes_prealable',
      to: 'assemblee_generale_constitutive',
    },
    {
      name: 'next',
      from: 'assemblee_generale_constitutive',
      to: 'presence_assemblee_generale_constitutive1',
    },

    // ---------------------------------

    {
      name: 'demandeSiPresentSupplementaire',
      from: 'presence_assemblee_generale_constitutive1',
      to: 'choix_present_supplementaire',
    },

    {
      name: 'bouclePresence1',
      from: 'choix_present_supplementaire',
      to: 'presence_assemblee_generale_constitutive1',
    },
    {
      name: 'nextPresence2',
      from: 'choix_present_supplementaire',
      to: 'presence_assemblee_generale_constitutive2',
    },

    {
      name: 'demandeSiRepresenteSupplementaire',
      from: 'presence_assemblee_generale_constitutive2',
      to: 'choix_represente_supplementaire',
    },

    {
      name: 'bouclePresence2',
      from: 'choix_represente_supplementaire',
      to: 'presence_assemblee_generale_constitutive2',
    },
    {
      name: 'nextPresence3',
      from: 'choix_represente_supplementaire',
      to: 'presence_assemblee_generale_constitutive3',
    },

    {
      name: 'next',
      from: 'presence_assemblee_generale_constitutive3',
      to: 'premiers_membres_organe_direction',
    },
    {
      name: 'next',
      from: 'premiers_membres_organe_direction',
      to: 'choix_procuration_bbf',
    },
    {
      name: 'next',
      from: 'choix_procuration_bbf',
      to: 'recap',
    },

    // -----------------------

    {
      name: 'prev',
      from: 'info_societe2',
      to: 'info_societe',
    },

    {
      name: 'prev',
      from: 'info_societe3',
      to: 'info_societe2',
    },

    {
      name: 'prev',
      from: 'duree_association',
      to: 'info_societe3',
    },

    {
      name: 'prev',
      from: 'organe_direction',
      to: 'duree_association',
    },

    {
      name: 'prev',
      from: 'decisions_organe_direction',
      to: 'organe_direction',
    },

    {
      name: 'prev',
      from: 'membres_association',
      to: 'decisions_organe_direction',
    },

    {
      name: 'prev',
      from: 'cotisations',
      to: 'membres_association',
    },

    {
      name: 'prev',
      from: 'decisions_assemblee_generale',
      to: 'cotisations',
    },

    {
      name: 'prev',
      from: 'choix_commissaire_aux_comptes',
      to: 'decisions_assemblee_generale',
    },

    {
      name: 'prev',
      from: 'choix_nombre_actes_prealables',
      to: 'choix_commissaire_aux_comptes',
    },

    {
      name: 'prev',
      from: 'info_actes_prealable',
      to: 'choix_nombre_actes_prealables',
    },

    {
      name: 'prev',
      from: 'assemblee_generale_constitutive1',
      to: 'info_actes_prealable',
    },

    // {
    //   name: 'next',
    //   from: 'presence_assemblee_generale_constitutive1',
    //   to: 'choix_partenaire_supplementaire',
    // },
    // {
    //   name: 'boucle1',
    //   from: 'choix_partenaire_supplementaire',
    //   to: 'presence_assemblee_generale_constitutive1',
    // },
    // {
    //   name: 'next',
    //   from: 'choix_partenaire_supplementaire',
    //   to: 'presence_assemblee_generale_constitutive2',
    // },

    // {
    //   name: 'prev',
    //   from: 'choix_partenaire_supplementaire',
    //   to: 'presence_assemblee_generale_constitutive2',
    // },
    // {
    //   name: 'boucle2',
    //   from: 'presence_assemblee_generale_constitutive2',
    //   to: 'choix_partenaire_supplementaire',
    // },
    {
      name: 'prev',
      from: 'presence_assemblee_generale_constitutive3',
      to: 'presence_assemblee_generale_constitutive1',
    },

    {
      name: 'prev',
      from: 'premiers_membres_organe_direction',
      to: 'presence_assemblee_generale_constitutive3',
    },
    {
      name: 'prev',
      from: 'choix_procuration_bbf',
      to: 'premiers_membres_organe_direction',
    },
    {
      name: 'prev',
      from: 'recap',
      to: 'choix_procuration_bbf',
    },
  ],
  methods: {
    onDemandeSiSeulOuAPlusieurs: () => {
      // return Choix_seul_ou_a_plusieurs;
    },
    onDemandeChoisirNombreDePartenairesOuTypeDePartenaire: () => {
      // return Choix_nombre_partenaires_ou_type_partenaire;
    },
    onDemandeChoisirTypeDeSociete: () => {
      // return Choix_type_societe;
    },
    onDemandeInfoDeLaSociete: () => {
      // return Info_societe;
    },
    onDemandeInfoDeLaPersonneMorale: () => {
      // return Info_personne_morale;
    },
    onDemandeInfoDuPartenaire: () => {
      // return Info_partenaire;
    },
    onDemandeChoisirSituationFamiliale: () => {
      // return Choix_situation_familiale;
    },
    onDemandeInfoDuMariage: () => {
      // return Info_mariage;
    },
    onDemandeChoisirSiContratDeMariage: () => {
      // return Choix_contrat_de_mariage;
    },
    onDemandeInfoDuContratDeMariage: () => {
      // return Info_contrat_de_mariage;
    },
    onDemandeInfoDuPacs: () => {
      // return Info_pacs;
    },
    onDemandeChoisirLeTypeDePacs: () => {
      // return Choix_type_de_pacs;
    },

    onDemandeInfoApportsPartenaire: () => {
      // return ;
    },

    onDemandeChoisirSiPartenaireSupplementaire: () => {
      // return ChoixPartenaireSupplementaire;
    },

    onDemandeInfoSurLeCapitalDeLaSociete: () => {
      // return Info_capital_de_societe;
    },
    onDemandeInfoSurLesApportsDeLaSociete: () => {
      // return Info_apports_de_societe;
    },
    onDemandeChoisirLiberationIntegraleOuPartielle: () => {
      // return Choix_liberation_integrale_ou_partielle;
    },
    onDemandeInfoSurLeMontantDesApports: () => {
      // return Info_montant_des_apports;
    },
    onDemandeChoisirLeGerant: () => {
      // return Choix_du_gerant;
    },
    onDemandeInfoSurLeGerant: () => {
      // return Info_gerant;
    },
    onDemandeChoisirLeNombreActesPrealable: () => {
      // return Choix_nombre_actes_prealables;
    },
    onDemandeInfoSurLesActesPrealables: () => {
      // return Info_actes_prealable;
    },
    onDemandeChoisirSiCommissaireAuxComptes: () => {
      // return Choix_commissaire_aux_comptes;
    },
    onDemandeInfoSurLeCommissaireAuxComptes: () => {
      // console.log('demande infos sur le commissaire aux comptes');
      // return Info_commissaire_aux_comptes;
    },
    onDemandeListeFin: () => {
      // console.log('montre la liste de fin');
    },
    // les retour depuis le listing de fin

    // onDemandeRetour: () => {
    //   // console.log('montre la premiere page');
    // },
  },
});

export default AssociationAutomate;
