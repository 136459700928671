const eurl1 = {
  nom_propre: 'societe',
  alone: 'seul',
  personne_physique_morale: 'personne_physique',
  choix_is: 'non',
  nombre_de_personnes_physiques: '',
  nombre_de_personnes_morales: '',
  eurl_sasu: 'eurl',
  sci: '',
  company_name: 'formDenoSociale',
  business_name: 'formNomCommercial',
  code_ape: 'formObjetSocial',
  address: 'Traverse du T',
  zipcode: '06600',
  city: 'Antibes',
  siege_social_chez: 'chez_gerant',
  proprietaire: 'Proprietaire',
  maison_individuelle: 'oui',
  prenom_syndic: '',
  nom_syndic: '',
  adresse_syndic: '',
  prenom_bailleur: '',
  nom_bailleur: '',
  adresse_bailleur: '',
  date_bail: null,
  date_debut_activite: '2021-11-25',
  closing_date_with_year: '2021-11-26',
  partners: [
    {
      id: '93f38e7b-25b4-4fdd-935c-cd3b07643f93',
      partner_title: 'M.',
      partner_name: 'formNomAsso',
      partner_firstname: 'formPrenomAsso',
      partner_father_name: 'formNomPereAsso',
      partner_father_firstname: 'formPrenomPereAsso',
      partner_mother_name: 'formNomMereAsso',
      partner_mother_firstname: 'formPrenomMereAsso',
      partner_date_of_birth: '2021-11-01',
      partner_place_of_birth: 'Hyds',
      partner_place_of_living: 'U Centru',
      partner_city: 'Porto-Vecchio',
      partner_zipcode: '20137',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'formPaysNaissanceAsso',
      partner_nationality: 'formNationalite9Asso',
      partner_family_situation: 'marie9(e)',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: '2021-11-01',
      partner_wedding_prenup_place: 'Os-Marsillon',
      partner_wedding_prenup_officer_office_name: 'formNomOfficeNotarial',
      partner_wedding_prenup_officer_office_place:
        'Guerloc\u2019H 29390 Scaebr',
      partner_spouse_name: 'formNomConjointAsso',
      partner_spouse_firstname: 'formPrenomConjointAsso',
      partner_wedding_date: '2021-10-06',
      partner_wedding_place: 'Salaise-sur-Sanne',
      partner_spouse_date_naissance: '2021-11-01',
      partner_spouse_lieu_naissance: 'Us',
      partner_spouse_departement_naissance: 'cele-de-France',
      pacs_spouse_date_naissance: null,
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: '',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Auvergne-Rhf4ne-Alpes',
    },
  ],
  capital_in_numbers: '15000',
  share_value: '100',
  depot_capital_chez: 'be_business_focus',
  date_depot_des_fonds: null,
  banking_corporation_name: '',
  adresse_agence_bancaire: '',
  ville_agence_bancaire: '',
  code_postal_agence_bancaire: '',
  cash_funds_numbers: '10000',
  apport_nature: 'oui',
  supplies_funds_numbers: '5000',
  apport_fonds_de_commerce: 'oui',
  date_apport_fonds_de_commerce: '2021-11-01',
  valeur_apport_fonds_de_commerce: '1500',
  apport_titres_societe: 'oui',
  date_apport_titres_societe: '2021-10-12',
  valeur_apport_titres_societe: '1000',
  raison_sociale_societe_apport_titres_societe:
    'formRaisonSocSocieeteApporte9e',
  nombre_titres_apportes_apport_titres_societe: '100',
  nombre_titres_total_apport_titres_societe: '10000',
  apport_nature_divers: 'oui',
  date_apport_nature_divers: '2021-10-13',
  valeur_apport_nature_divers: '2500',
  description_biens_apport_nature_divers: 'formDescriptionBiensApporte9s',
  apport_industrie: 'non',
  expertise_funds_numbers: '',
  cash_funds_onetime_payment: 'non',
  payments_amount: '4000',
  director_type: "l_associe_de_l'EURL",
  director_title: 'M.',
  director_name: '',
  director_firstname: '',
  director_father_name: '',
  director_father_firstname: '',
  director_mother_name: '',
  director_mother_firstname: '',
  director_date_of_birth: null,
  director_place_of_birth: '',
  director_state_of_birth: '',
  director_country_of_birth: '',
  director_nationality: '',
  director_place_of_living: '',
  director_email: 'shooter.xav@hotmail.fr',
  associe_gerant_multiassocie: [],
  presence_dg: '',
  dg: [],
  dg_type: "l_associe_de_l'EURL",
  dg_title: 'M.',
  dg_name: '',
  dg_firstname: '',
  dg_address: '',
  dg_zipcode: '',
  dg_city: '',
  dg_email: '',
  duree_gerance_determinee: 'non',
  duree_de_gerance: '0',
  preexisting_contract: 'oui',
  number_of_preexisting_contract: 2,
  contract1_date: '2021-11-01',
  contract1_type: 'formNatureActePrealable',
  contract1_amount: '450',
  contract1_person_who_paid: 'formPayeurActePrealable1',
  contract2_date: '2021-10-22',
  contract2_type: 'formNatureActePrealable',
  contract2_amount: '800',
  contract2_person_who_paid: 'formPayeurActePrealable1',
  presence_commissaire_aux_comptes: 'oui',
  duree_exercice_commissaire_aux_comptes: 6,
  titre_commissaire_aux_comptes: 'M.',
  nom_commissaire_aux_comptes: 'formNomCAC',
  prenom_commissaire_aux_comptes: 'formPrenomCAC',
  nom_cabinet_commissaire_aux_comptes: '',
  adresse_cabinet_commissaire_aux_comptes:
    'J Y Vois Clair 33220 Saint-Avit-de-Soule8ge',
  nom_suppleant: 'formNomSuppleant',
  prenom_suppleant: 'FormPrneomSuppleant',
  adresse_cabinet_suppleant: 'N Rte Nationale 66200 Elne',
  procuration: 'oui',
  nom_cabinet_procuration: 'formNomCabinet',
  qualite_cabinet_procuration: 'formQualite9CabinetProcu',
  adresse_cabinet_procuration: 'Voie X/13',
  ville_cabinet_procuration: 'Paris',
  code_postal_cabinet_procuration: '75013',
  procu_bbf_dossier_constitution: 'non',
  procu_bbf_attestation_beneficiaires: 'non',
  procu_bbf_annonce_legale: 'oui',
  procu_bbf_attestation_depot_fonds: 'non',
  procu_bbf_depot_greffe_dossier_constitution: 'non',
  rcs_ville: 'Antibes',
  kit: 'kit4soc',
  companyAlreadyCreated: null,
  email: '',

  cac_suppleant: 'oui',
  partenaire_supplementaire: 'non',
  email_dg: '',
  type_CAC: 'physique',
  nom_propre_CAC: 'nom_propre',
  type_CAC_suppleant: 'physique',
  alternativeId: 'y3705hi',
};
export default eurl1;
