import { IconButton } from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import React from 'react';
import { showHelpPopup } from 'reducers/appReducer';

function HelpIcon({ helpName }) {
  const dispatch = useDispatch();
  const displayHelpPopup = () => {
    dispatch(showHelpPopup(helpName));
  };

  return (
    <IconButton
      color="primary"
      aria-label="upload picture"
      component="span"
      onClick={displayHelpPopup}
    >
      <HelpOutlineOutlined />
    </IconButton>
  );
}

export default HelpIcon;
