import { NO } from 'constants/usersContants';

const initialValues = (values) => {
  return {
    cash_funds_numbers: values.cash_funds_numbers || 0,

    // apport en nature
    apport_nature: values.apport_nature || NO,

    apport_fonds_de_commerce: values.apport_fonds_de_commerce || NO,
    date_apport_fonds_de_commerce: values.date_apport_fonds_de_commerce || null,
    partner_date_apport_fonds_de_commerce:
      values.partner_date_apport_fonds_de_commerce || null,
    valeur_apport_fonds_de_commerce:
      values.valeur_apport_fonds_de_commerce || 0,

    // apport de titres de société
    apport_titres_societe: values.apport_titres_societe || NO,
    date_apport_titres_societe: values.date_apport_titres_societe || null,
    valeur_apport_titres_societe: values.valeur_apport_titres_societe || 0,
    raison_sociale_societe_apport_titres_societe:
      values.raison_sociale_societe_apport_titres_societe || '',
    nombre_titres_apportes_apport_titres_societe:
      values.nombre_titres_apportes_apport_titres_societe || 0,
    nombre_titres_total_apport_titres_societe:
      values.nombre_titres_total_apport_titres_societe || 0,

    // apport en nature
    apport_nature_divers: values.apport_nature_divers || NO,
    date_apport_nature_divers: values.date_apport_nature_divers || null,
    valeur_apport_nature_divers: values.valeur_apport_nature_divers || 0,
    description_biens_apport_nature_divers:
      values.description_biens_apport_nature_divers || '',

    // Industry contribution
    apport_industrie: values.apport_industrie || NO,
    expertise_funds_numbers: values.expertise_funds_numbers || 0,
  };
};

// eslint-disable-next-line
export { initialValues };
