import { EURL, SARL, SAS, SASU, SCI } from './companies';
import {
  BASIC_INFO_OF_COMPANY,
  COMPANY_DATE,
  HEAD_OFFICE_INFO,
  PARTNER_ADD_AND_REVIEW,
  PARTNER_CONTRIBUTION,
  COMPANY_CONTRIBUTION,
  // RELEASE_OF_CASH_BY_PARTNER,
  SELECT_CEO,
  TYPE_OF_COMPANY,
  USER_INFO,
  SELECT_DG,
  CAPITAL_AND_FOUND_DEPOSIT,
  LEGAL_ACTS,
  RECAP,
  CAC,
  SELECT_REGIME_VAT,
} from './pages';

export default [
  {
    companyType: EURL,
    routes: [
      {
        current: TYPE_OF_COMPANY,
        next: BASIC_INFO_OF_COMPANY,
      },
      {
        current: BASIC_INFO_OF_COMPANY,
        next: COMPANY_DATE,
      },
      {
        current: COMPANY_DATE,
        next: USER_INFO,
        endSection: true,
      },
      {
        current: USER_INFO,
        next: SELECT_CEO,
        // endSection: true, // TO TO: always redirect to screen select CEO after edit parter
      },
      {
        current: SELECT_CEO,
        next: HEAD_OFFICE_INFO,
      },
      {
        current: HEAD_OFFICE_INFO,
        next: COMPANY_CONTRIBUTION,
      },
      {
        current: COMPANY_CONTRIBUTION,
        next: CAPITAL_AND_FOUND_DEPOSIT,
      },
      {
        current: CAPITAL_AND_FOUND_DEPOSIT,
        next: LEGAL_ACTS,
      },
      // {
      //   current: CAPITAL_AND_FOUND_DEPOSIT,
      //   next: RELEASE_OF_CASH_BY_PARTNER,
      // },
      // {
      //   current: RELEASE_OF_CASH_BY_PARTNER,
      //   next: LEGAL_ACTS,
      // },
      {
        current: LEGAL_ACTS,
        next: CAC,
      },
      {
        current: CAC,
        next: SELECT_REGIME_VAT,
      },
      {
        current: SELECT_REGIME_VAT,
        next: RECAP,
      },
    ],
  },
  {
    companyType: SAS,
    routes: [
      {
        current: TYPE_OF_COMPANY,
        next: BASIC_INFO_OF_COMPANY,
      },
      {
        current: BASIC_INFO_OF_COMPANY,
        next: COMPANY_DATE,
      },
      {
        current: COMPANY_DATE,
        next: USER_INFO,
        endSection: true,
      },
      {
        current: USER_INFO,
        next: PARTNER_CONTRIBUTION,
      },
      {
        current: PARTNER_CONTRIBUTION,
        next: PARTNER_ADD_AND_REVIEW,
      },
      // {
      //   current: PARTNER_CONTRIBUTION,
      //   next: RELEASE_OF_CASH_BY_PARTNER,
      // },
      // {
      //   current: RELEASE_OF_CASH_BY_PARTNER,
      //   next: PARTNER_ADD_AND_REVIEW,
      // },
      {
        current: PARTNER_ADD_AND_REVIEW,
        next: SELECT_CEO,
        // endSection: true, // TO TO: always redirect to screen select CEO after edit parter
      },
      {
        current: SELECT_CEO,
        next: SELECT_DG,
      },
      {
        current: SELECT_DG,
        next: HEAD_OFFICE_INFO,
      },
      {
        current: HEAD_OFFICE_INFO,
        next: CAPITAL_AND_FOUND_DEPOSIT,
      },
      {
        current: CAPITAL_AND_FOUND_DEPOSIT,
        next: LEGAL_ACTS,
      },
      {
        current: LEGAL_ACTS,
        next: CAC,
      },
      {
        current: CAC,
        next: SELECT_REGIME_VAT,
      },
      {
        current: SELECT_REGIME_VAT,
        next: RECAP,
      },
    ],
  },
  {
    companyType: SARL,
    routes: [
      {
        current: TYPE_OF_COMPANY,
        next: BASIC_INFO_OF_COMPANY,
      },
      {
        current: BASIC_INFO_OF_COMPANY,
        next: COMPANY_DATE,
      },
      {
        current: COMPANY_DATE,
        next: USER_INFO,
        endSection: true,
      },
      {
        current: USER_INFO,
        next: PARTNER_CONTRIBUTION,
      },
      {
        current: PARTNER_CONTRIBUTION,
        next: PARTNER_ADD_AND_REVIEW,
      },
      {
        current: PARTNER_ADD_AND_REVIEW,
        next: SELECT_CEO,
        // endSection: true,// TO TO: always redirect to screen select CEO after edit parter
      },
      {
        current: SELECT_CEO,
        next: HEAD_OFFICE_INFO,
      },
      {
        current: HEAD_OFFICE_INFO,
        next: CAPITAL_AND_FOUND_DEPOSIT,
      },
      {
        current: CAPITAL_AND_FOUND_DEPOSIT,
        next: LEGAL_ACTS,
      },
      {
        current: LEGAL_ACTS,
        next: CAC,
      },
      {
        current: CAC,
        next: SELECT_REGIME_VAT,
      },
      {
        current: SELECT_REGIME_VAT,
        next: RECAP,
      },
    ],
  },
  {
    companyType: SASU,
    routes: [
      {
        current: TYPE_OF_COMPANY,
        next: BASIC_INFO_OF_COMPANY,
      },
      {
        current: BASIC_INFO_OF_COMPANY,
        next: COMPANY_DATE,
      },
      {
        current: COMPANY_DATE,
        next: USER_INFO,
        endSection: true,
      },
      {
        current: USER_INFO,
        next: SELECT_CEO,
        // endSection: true,// TO TO: always redirect to screen select CEO after edit parter
      },
      {
        current: SELECT_CEO,
        next: SELECT_DG,
      },
      {
        current: SELECT_DG,
        next: HEAD_OFFICE_INFO,
      },
      {
        current: HEAD_OFFICE_INFO,
        next: COMPANY_CONTRIBUTION,
      },
      {
        current: COMPANY_CONTRIBUTION,
        next: CAPITAL_AND_FOUND_DEPOSIT,
      },
      {
        current: CAPITAL_AND_FOUND_DEPOSIT,
        next: LEGAL_ACTS,
      },
      // {
      //   current: CAPITAL_AND_FOUND_DEPOSIT,
      //   next: RELEASE_OF_CASH_BY_PARTNER,
      // },
      // {
      //   current: RELEASE_OF_CASH_BY_PARTNER,
      //   next: LEGAL_ACTS,
      // },
      {
        current: LEGAL_ACTS,
        next: CAC,
      },
      {
        current: CAC,
        next: SELECT_REGIME_VAT,
      },
      {
        current: SELECT_REGIME_VAT,
        next: RECAP,
      },
    ],
  },
  {
    companyType: SCI,
    routes: [
      {
        current: BASIC_INFO_OF_COMPANY,
        next: COMPANY_DATE,
      },
      {
        current: COMPANY_DATE,
        next: USER_INFO,
        endSection: true,
      },
      {
        current: USER_INFO,
        next: PARTNER_CONTRIBUTION,
      },
      {
        current: PARTNER_CONTRIBUTION,
        next: PARTNER_ADD_AND_REVIEW,
      },
      {
        current: PARTNER_ADD_AND_REVIEW,
        next: SELECT_CEO,
        endSection: true,
      },
      {
        current: SELECT_CEO,
        next: HEAD_OFFICE_INFO,
      },
      {
        current: HEAD_OFFICE_INFO,
        next: CAPITAL_AND_FOUND_DEPOSIT,
      },
      {
        current: CAPITAL_AND_FOUND_DEPOSIT,
        next: LEGAL_ACTS,
      },
      {
        current: LEGAL_ACTS,
        next: RECAP,
      },
    ],
  },
];
