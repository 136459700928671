import automateSkeleton from 'constants/automateSkeleton';
import { SARL, SAS, SCI } from 'constants/companies';
import {
  PARTNER_ADD_AND_REVIEW,
  PARTNER_CONTRIBUTION,
  RECAP,
  RELEASE_OF_CASH_BY_PARTNER,
  TYPE_OF_COMPANY,
  USER_INFO,
} from 'constants/pages';

const isCompanyWithMultiPartners = (typeOfCompany) => {
  if (SARL === typeOfCompany || SAS === typeOfCompany || SCI === typeOfCompany)
    return true;
  return false;
};

const goBack = (state) => {
  const { formStack, currentForm, partners, eurl_sasu } = state;
  if (formStack.length === 0) return { currentForm, formStack };
  if (
    isCompanyWithMultiPartners(eurl_sasu) &&
    (currentForm === USER_INFO ||
      currentForm === PARTNER_CONTRIBUTION ||
      currentForm === RELEASE_OF_CASH_BY_PARTNER) &&
    partners.length > 0
  ) {
    return { currentForm: PARTNER_ADD_AND_REVIEW, formStack };
  }
  const newStack = [...formStack];
  const lastForm = newStack.pop();
  return { currentForm: lastForm, formStack: newStack };
};

const canAddInHistory = (values) => {
  const { eurl_sasu, currentForm, isReview } = values;
  if (isReview) return false;
  if (
    (currentForm === USER_INFO ||
      currentForm === PARTNER_CONTRIBUTION ||
      currentForm === RELEASE_OF_CASH_BY_PARTNER) &&
    (eurl_sasu === SARL || eurl_sasu === SAS || eurl_sasu === SCI)
  ) {
    return false;
  }
  return true;
};

const getNextForm = (values) => {
  const { eurl_sasu, currentForm, partners, isReview } = values;
  try {
    const curentAutomate = automateSkeleton.find(
      (automate) => automate.companyType === eurl_sasu
    );
    const { next, endSection } = curentAutomate.routes.find(
      (route) => route.current === currentForm
    );
    if (isReview && endSection) return RECAP;
    if (
      next === USER_INFO &&
      partners.length > 0 &&
      isCompanyWithMultiPartners(eurl_sasu)
    )
      return PARTNER_ADD_AND_REVIEW;
    return next;
  } catch (e) {
    console.error('ERROR :', e);
    return TYPE_OF_COMPANY;
  }
};

export { goBack, getNextForm, canAddInHistory };
