import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  sm_divider: {
    height: '10px',
  },
  md_divider: {
    height: '30px',
  },
  lg_divider: {
    height: '50px',
  },
}));

const SmDivider = () => {
  const classes = useStyles();
  return <div className={classes.sm_divider}></div>;
};

const MdDivider = () => {
  const classes = useStyles();
  return <div className={classes.md_divider}></div>;
};

const LgDivider = () => {
  const classes = useStyles();
  return <div className={classes.lg_divider}></div>;
};

export { SmDivider, MdDivider, LgDivider };
