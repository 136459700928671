import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Container, Grid, Link, Typography } from '@material-ui/core';
import { MdDivider, SmDivider } from './Dividers';

// images
import Logo from '../assets/images/BBF-header-logo-blueberry.svg';
import LogoAE from '../assets/images/logo_AE.svg';
import AppleStore from '../assets/images/Apple-store.png';
import PlayStore from '../assets/images/Google-store.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  footer: {
    backgroundColor: theme.palette.common.white,
    borderTop: '1px solid rgba(43, 41, 45,0.1)',
    paddingTop: 20,
  },
  borderTopLightGrey: {
    borderTop: '1px solid rgba(43, 41, 45,0.1)',
  },
  avoidChat: {
    marginRight: '70px',
  },
}));

const Footer = () => {
  const classes = useStyles();

  const currYear = () => {
    const year = new Date().getFullYear();
    return year;
  };

  return (
    <footer className={classes.footer}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={6}>
            <a href="https://bebusinessfocus.com">
              <img src={Logo} height="100px" alt="mon amour de comptable" />
            </a>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <Grid item container direction="column" alignItems={'flex-end'}>
              <Grid item>
                <Typography variant="caption">
                  Solution digitale développée par les experts comptables du
                  cabinet Alliance experts
                </Typography>
              </Grid>
              <Grid container justify="flex-end">
                <a
                  href="http://alliance-experts.fr/"
                  id="clickTrack-LogoAEFooter"
                >
                  <img src={LogoAE} height="100px" alt="Alliance-Experts" />
                </a>
              </Grid>
            </Grid>
          </Grid>

          {/* Second Row */}
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="body2">
                  Retrouvez-nous aussi sur les stores :
                </Typography>
              </Grid>
              <Grid item>
                <a
                  href="https://apps.apple.com/fr/app/id1485252693"
                  id="click-TrackLogoAppStore"
                >
                  <img src={AppleStore} alt="Apple Store" height="35" />
                </a>
              </Grid>
              <Grid item>
                <a
                  href="https://play.google.com/store/apps/details?id=com.aeweb.monamourdecomptable"
                  id="click-TrackLogoPlayStore"
                >
                  <img src={PlayStore} alt="Play Store" height="35" />
                </a>
              </Grid>
            </Grid>
            <SmDivider />
          </Grid>

          {/* Empty Separator */}
          <Grid item xs={12} className={classes.borderTopLightGrey}>
            <SmDivider />
          </Grid>

          {/* Third Row */}
          <Grid item xs={6}>
            <Typography variant="caption">
              © Be Business Focus 2019-{currYear()}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify={'flex-end'}>
            <Typography variant="caption" className={classes.avoidChat}>
              <Link
                href="https://allianceexperts.fr/a-propos-de-nous"
                color="inherit"
              >
                A propos
              </Link>{' '}
              | {/* <Link href="./terms" color="inherit"> */}
              Mention légales
              {/* </Link>{' '} */}|{' '}
              {/* <Link href="./privacy" color="inherit"> */}
              Protection des données personnelle
              {/* </Link>{' '} */}|{' '}
              <Link href="https://bebusinessfocus.com/contact" color="inherit">
                Contactez-nous
              </Link>
            </Typography>
          </Grid>

          {/* Empty Separator */}
          <Grid item xs={12}>
            <MdDivider />
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
