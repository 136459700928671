import { capitalize } from 'lodash';

import {
  HEAD_OFFICE_AT_SEO,
  HEAD_OFFICE_IN_LOCAL,
  OWNER,
} from 'constants/usersContants';

const prepareData = (values) => {
  const form = { ...values };
  const { partners, idGerantForDomiciliation } = form;
  const headOfficeIsSEOOrLocal =
    form.siege_social_chez === HEAD_OFFICE_AT_SEO ||
    form.siege_social_chez === HEAD_OFFICE_IN_LOCAL;
  const partner = partners.find(
    (item) => item.id === form.associe_gerant_multiassocie[0]
  );
  if (
    !idGerantForDomiciliation &&
    headOfficeIsSEOOrLocal &&
    form.proprietaire === OWNER &&
    partner
  ) {
    form.email_bailleur = partner.partner_email;
    form.prenom_bailleur = capitalize(partner.partner_firstname);
    form.nom_bailleur = capitalize(partner.partner_name);

    form.adresse_bailleur = `${partner.partner_place_of_living} ${partner.partner_zipcode} ${partner.partner_city}`;
    form.adresse_bailleur_updated = true;

    form.bailleur_zipcode = partner.partner_zipcode;
    form.bailleur_city = partner.partner_city;
    form.bailleur_address = partner.partner_place_of_living;
    form.idGerantForDomiciliation = partner.id;
  }
  return form;
};

export default prepareData;
