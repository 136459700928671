import { Avatar } from '@material-ui/core';
import React from 'react';
import { personTypes } from 'constants/usersContants';
import useStyles from '../style';

function DGCard({ dg, handleClick, index }) {
  const classes = useStyles();

  const handleClickDG = () => {
    if (handleClick) {
      handleClick(index);
    }
  };

  const renderDG = () => {
    const name = dg.partner_name || '';
    const firstname = dg.partner_firstname || '';
    return `${name} ${firstname}`;
  };
  const renderDGAvatar = () => {
    const name = dg.partner_name || '';
    const firstname = dg.partner_firstname || '';
    return `${name.charAt(0)}${firstname.charAt(0)}`;
  };

  return (
    <div className={classes.partnerCard} onClick={handleClickDG}>
      <Avatar className={classes.avatar}>{renderDGAvatar()}</Avatar>
      <div>
        <div className={classes.parnterName}>{renderDG()}</div>
        {
          personTypes.find(
            (personType) => personType.value === dg.personne_physique_morale
          ).label
        }
      </div>
    </div>
  );
}

export default DGCard;
