/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState, useRef } from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import axios from 'axios';

import { Formik, Field, Form } from 'formik';

import {
  MenuItem,
  FormControl,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper as MUIPaper,
  Button,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField, Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
// import { NumberToLetter } from 'convertir-nombre-lettre';

import * as Yup from 'yup';
import theme from '../../assets/jss/theme';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { ADD_PARTNER, EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const SignupSchema = Yup.object().shape({
  forme_juridique_personne_morale: Yup.string().required(
    'un choix est necessaire'
  ),
  capital_personne_morale: Yup.string().required('un choix est necessaire'),
  raison_sociale_actionnaire_personne_morale_sasu: Yup.string().required(
    'un choix est necessaire'
  ),
  adresse_actionnaire_personne_morale_sasu: Yup.string().required(
    'un choix est necessaire'
  ),
  code_postal_actionnaire_personne_morale_sasu: Yup.string().required(
    'un choix est necessaire'
  ),
  ville_actionnaire_personne_morale_sasu: Yup.string().required(
    'un choix est necessaire'
  ),
  rcs_actionnaire_personne_morale_sasu: Yup.string().required(
    'un choix est necessaire'
  ),
  ville_rcs_actionnaire_personne_morale_sasu: Yup.string().required(
    'un choix est necessaire'
  ),
});

const InfoPersonneMorale = ({
  partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();
  useEffect(() => {
    setActiveStep(1);
  }, []);

  // let associeOuActionnaire = "la personne morale associée de l'EURL";
  const associeOuActionnaire = 'la personne morale associée de la SCI';
  const associeOuActionnaireCourt = 'la personne morale ';

  // if (
  //   formValues.eurl_sasu === 'sasu' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   associeOuActionnaire = 'la personne morale actionnaire de la SASU';
  //   associeOuActionnaireCourt = 'la personne morale ';
  // } else if (
  //   formValues.eurl_sasu === 'sas' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   associeOuActionnaire = 'la personne morale actionnaire de la SAS';
  //   associeOuActionnaireCourt = 'la personne morale ';
  // }
  // if (
  //   formValues.eurl_sasu === 'sarl' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   associeOuActionnaire = 'la personne morale associée de la SARL';
  //   associeOuActionnaireCourt = 'la personne morale ';
  // }

  const [companyList, setCompanyList] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchCompanyInfo = async (values) => {
    const {
      raison_sociale_actionnaire_personne_morale_sasu: companyInputData,
    } = values;
    if (companyInputData) {
      const { data: res } = await axios.get(
        `${process.env.REACT_APP_URL_INFOGREFFE}?q=${companyInputData}`
      );

      if (res && res.length > 0) {
        setCompanyList(res);
      } else {
        setCompanyList(null);
      }
    } else {
      setCompanyList(null);
    }
  };

  const handleSelectAddress = async (newValue, setFieldValue) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue(
        'adresse_actionnaire_personne_morale_sasu',
        newValue.properties.name
      );
      if (newValue.properties.postcode && newValue.properties.city) {
        setFieldValue(
          'code_postal_actionnaire_personne_morale_sasu',
          newValue.properties.postcode
        );
        setFieldValue(
          'ville_actionnaire_personne_morale_sasu',
          newValue.properties.city
        );
        const { data: res } = await axios.get(
          `${process.env.REACT_APP_URL_RCS}?zip=${newValue.properties.postcode}&city=${newValue.properties.city}`
        );
        if (res && res.rcs) {
          setFieldValue('ville_rcs_actionnaire_personne_morale_sasu', res.rcs);
        }
      }
    } else {
      setFieldValue('adresse_actionnaire_personne_morale_sasu', '');
      setFieldValue('code_postal_actionnaire_personne_morale_sasu', '');
      setFieldValue('ville_actionnaire_personne_morale_sasu', '');
      setAddressValue(newValue);
    }
  };

  const handleSelectCompany = async (company, setFieldValue) => {
    // Fetch RCS
    const { code_postal: zipcode, ville: city } = company;
    let resRCS;

    if (zipcode && city) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_RCS}?zip=${zipcode}&city=${city}`
      );
      if (data) {
        resRCS = data;
      }
    }

    setAddressList([{ properties: { name: company.adresse } }]);

    setFieldValue(
      'raison_sociale_actionnaire_personne_morale_sasu',
      company.denomination
    );
    setFieldValue(
      'code_postal_actionnaire_personne_morale_sasu',
      company.code_postal
    );
    setFieldValue('ville_actionnaire_personne_morale_sasu', company.ville);

    if (resRCS) {
      setFieldValue('rcs_actionnaire_personne_morale_sasu', company.siren);
      setFieldValue('ville_rcs_actionnaire_personne_morale_sasu', resRCS.rcs);
    }

    handleSelectAddress(
      { properties: { name: company.adresse } },
      setFieldValue
    );

    setCompanyList(null);
  };

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const renderCompanyList = (setFieldValue) => {
    return (
      <Box>
        <TableContainer component={MUIPaper}>
          <Table aria-label="custom pagination table">
            <TableBody>
              {companyList.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row">
                    {`Company Name: ${row.denomination}`}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {`Address: ${row.adresse}`}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {`SIREN: ${row.siren}`}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSelectCompany(row, setFieldValue)}
                    >
                      Select
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        const type = values.id ? EDIT_PARTNER : ADD_PARTNER;

        dispatch({
          type,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }

        SciAutomate.nextInfoPersonneMoraleVersInfoPartenaire();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              fullwidth="true"
            >
              {/* Pour "Civilité de l'associé ou de l'actionnaire" 
                Si personne_physique_morale == personne_physique
                et eurl_sasu == sasu  alors afficher "actionnaire" a la 
                place de "associé"  
                
                OK ca a l'air de marcher pour ce champs la */}

              <Box>
                <Typography variant="h5">
                  {' '}
                  Informations sur {associeOuActionnaire}
                </Typography>{' '}
              </Box>

              {/* Come pour le titre si box4 il a choisi persone_physique et 
sasu alors dans les label tous les "associé" vont devenir 
"actionnaire" */}

              {/* {values.eurl_sasu === 'sasu' && (
          <Fragment> */}
              <Box>
                <Typography variant="subtitle2">
                  veuillez indiquer ci-dessous, la forme juridique de{' '}
                  {associeOuActionnaire}{' '}
                </Typography>
              </Box>
              <Box alignItems="center" marginBottom={theme.spacing(0.25)}>
                <FormControl>
                  <Field
                    component={Select}
                    // name={`partners[${partnerIndex}].forme_juridique_personne_morale`}
                    name="forme_juridique_personne_morale"
                    defaultValue=""
                    inputProps={{
                      id: 'forme_juridique_personne_morale',
                    }}
                  >
                    <MenuItem value={'EURL'}>EURL</MenuItem>
                    <MenuItem value={'SA'}>SA</MenuItem>
                    <MenuItem value={'SARL'}>SARL</MenuItem>
                    <MenuItem value={'SAS'}>SAS</MenuItem>
                    <MenuItem value={'SASU'}>SASU</MenuItem>
                    <MenuItem value={'SC'}>SC</MenuItem>
                    <MenuItem value={'SCA'}>SCA</MenuItem>
                    <MenuItem value={'SCS'}>SCS</MenuItem>
                  </Field>
                </FormControl>
              </Box>
              <Box>
                <Field
                  component={TextField}
                  id="capital_in_numbers"
                  placeholder="2000"
                  type="number"
                  label={`capital de ${associeOuActionnaire}`}
                  // name={`partners[${partnerIndex}].capital_personne_morale`}
                  name="capital_personne_morale"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              {/* </Fragment> */}
              {/* )} */}

              <Box>
                <Field
                  component={TextField}
                  type="string"
                  label={`Raison sociale de ${associeOuActionnaireCourt}`}
                  // label={Nom de l{values.personne_physique_morale === 'personne_physique' && values.eurl_sasu=== 'sasu' ? 'actionnaire' : 'associé'}}
                  // name={`partners[${partnerIndex}].raison_sociale_actionnaire_personne_morale_sasu`}
                  name="raison_sociale_actionnaire_personne_morale_sasu"
                  className={classes.textField}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleFetchCompanyInfo(values)}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {companyList && renderCompanyList(setFieldValue)}

              <Box>
                <Field
                  component={Autocomplete}
                  // label={`Adresse de ${associeOuActionnaireCourt}`}
                  // name={`partners[${partnerIndex}].adresse_actionnaire_personne_morale_sasu`}
                  name="adresse_actionnaire_personne_morale_sasu"
                  className={classes.nameTextField}
                  value={addressValue}
                  loading={loadingAddressList}
                  options={addressList}
                  filterOptions={(x) => x}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option && option.properties && option.properties.name
                  }
                  onInputChange={(event, newInputValue) =>
                    handleFetchAddress(newInputValue)
                  }
                  onChange={(event, newValue) =>
                    handleSelectAddress(newValue, setFieldValue)
                  }
                  renderInput={(params) => (
                    <MUITextField
                      {...params}
                      error={
                        touched.adresse_actionnaire_personne_morale_sasu &&
                        !!errors.adresse_actionnaire_personne_morale_sasu
                      }
                      helperText={
                        touched.adresse_actionnaire_personne_morale_sasu &&
                        errors.adresse_actionnaire_personne_morale_sasu
                      }
                      label={`Adresse de ${associeOuActionnaireCourt}`}
                      placeholder={
                        formValues.adresse_actionnaire_personne_morale_sasu
                      }
                      size="small"
                    />
                  )}
                  renderOption={(option) => {
                    return (
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2" color="textSecondary">
                            {option.properties.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              </Box>
              <Box>
                <Field
                  component={TextField}
                  type="string"
                  label={`Code postal de ${associeOuActionnaireCourt}`}
                  // name={`partners[${partnerIndex}].code_postal_actionnaire_personne_morale_sasu`}
                  name="code_postal_actionnaire_personne_morale_sasu"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label={`Ville de ${associeOuActionnaireCourt}`}
                  // name={`partners[${partnerIndex}].ville_actionnaire_personne_morale_sasu`}
                  name="ville_actionnaire_personne_morale_sasu"
                  className={classes.textField}
                  autoComplete="off"
                />
              </Box>

              <Box>
                <Field
                  component={TextField}
                  fullwidth="true"
                  type="string"
                  label="RCS"
                  // name={`partners[${partnerIndex}].rcs_actionnaire_personne_morale_sasu`}
                  name="rcs_actionnaire_personne_morale_sasu"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Ville du RCS"
                  // name={`partners[${partnerIndex}].ville_rcs_actionnaire_personne_morale_sasu`}
                  name="ville_rcs_actionnaire_personne_morale_sasu"
                  className={classes.textField}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};
InfoPersonneMorale.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoPersonneMorale;
