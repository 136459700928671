import { SAS, SASU, SCI } from 'constants/companies';
import {
  CONCUBIN,
  MARIED,
  OTHER,
  PACTE,
  PM,
  PP,
  REQUIRED,
  YES,
} from 'constants/usersContants';
import { format } from 'date-fns';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object().shape({
    personne_physique_morale: Yup.string().required('Champs requis'),
    partner_title: Yup.string().required('Champs requis'),
    partner_name: Yup.string().required('Champs requis'),
    partner_firstname: Yup.string().required('Champs requis'),
    partner_email: Yup.string()
      .email('Saisissez une adresse e-mail valide.')
      .required('Champs requis'),
    partner_date_of_birth: Yup.date()
      .max(
        format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        "La date de naissance doit être antérieure a la date d'aujourd'hui"
      )
      .required('La date de naissance est necessaire')
      .nullable()
      .typeError('La date de naissance est necessaire'),
    partner_place_of_birth: Yup.string().required('Champs requis'),
    partner_country_of_birth: Yup.string().required('Champs requis'),
    partner_state_of_birth: Yup.string().required('champs requis'),
    partner_nationality: Yup.string().required('Champs requis'),
    partner_place_of_living: Yup.string().required('Champs requis'),
    partner_city: Yup.string().required('Champs requis'),
    partner_zipcode: Yup.string().required('Champs requis'),
    partner_father_name: Yup.string().required('Champs requis'),
    partner_father_firstname: Yup.string().required('Champs requis'),
    partner_mother_name: Yup.string().required('Champs requis'),
    partner_mother_firstname: Yup.string().required('Champs requis'),
    partner_family_situation: Yup.string().when('personne_physique_morale', {
      is: (personne_physique_morale) => personne_physique_morale === PP,
      then: Yup.string().required(REQUIRED),
    }),

    partnerSecuNumber: Yup.string()
      .when(['personne_physique_morale', 'eurl_sasu'], {
        is: (personne_physique_morale, eurl_sasu) =>
          personne_physique_morale === PP && eurl_sasu !== SCI,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      })
      .nullable(true),
    partnerProtectionSocial: Yup.string().when(
      ['personne_physique_morale', 'eurl_sasu'],
      {
        is: (personne_physique_morale, eurl_sasu) =>
          personne_physique_morale === PP &&
          eurl_sasu !== SAS &&
          eurl_sasu !== SCI &&
          eurl_sasu !== SASU,
        then: Yup.string().required(REQUIRED),
      }
    ),
    partnerProtectionSocialDetail: Yup.string().when(
      'partnerProtectionSocial',
      {
        is: (partnerProtectionSocial) => partnerProtectionSocial === OTHER,
        then: Yup.string().required('Champs requis'),
      }
    ),

    // IF Partner is maried
    partner_spouse_title: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_wedding_prenup_type: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_wedding_date: Yup.date()
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.date().required('Champs requis'),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    partner_spouse_name: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_spouse_firstname: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_spouse_date_naissance: Yup.date()
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.date().required('Champs requis'),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    partner_spouse_lieu_naissance: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_wedding_place: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),

    partner_wedding_prenup: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_spouse_adress: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: (schema) => {
          return schema.required('Champs requis');
        },
      }
    ),
    partner_spouse_city: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_spouse_zipcode: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_spouse_email: Yup.string()
      .email(`Saisissez une adresse e-mail valide.`)
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }),
    spouse_effective_date: Yup.date()
      .nullable()
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.date().nullable().required('Champs requis'),
      }),
    spouse_position: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string().required('Champs requis'),
      }
    ),
    spouse_secu_number: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === MARIED,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      }
    ),
    // IF has wedding prenup
    partner_wedding_prenup_officer_office_address: Yup.string().when(
      'partner_wedding_prenup',
      {
        is: (partner_wedding_prenup) => partner_wedding_prenup === YES,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_wedding_prenup_officer_office_city: Yup.string().when(
      'partner_wedding_prenup',
      {
        is: (partner_wedding_prenup) => partner_wedding_prenup === YES,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_wedding_prenup_officer_office_zipcode: Yup.string().when(
      'partner_wedding_prenup',
      {
        is: (partner_wedding_prenup) => partner_wedding_prenup === YES,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_wedding_prenup_date: Yup.date()
      .when('partner_wedding_prenup', {
        is: (partner_wedding_prenup) => partner_wedding_prenup === YES,
        then: Yup.date().required('Champs requis'),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    partner_wedding_prenup_place: Yup.string().when('partner_wedding_prenup', {
      is: (partner_wedding_prenup) => partner_wedding_prenup === YES,
      then: Yup.string().required('Champs requis'),
    }),
    partner_wedding_prenup_officer_office_name: Yup.string().when(
      'partner_wedding_prenup',
      {
        is: (partner_wedding_prenup) => partner_wedding_prenup === YES,
        then: Yup.string().required('Champs requis'),
      }
    ),

    // IF has Pacte
    pacs_type: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_spouse_title: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_date: Yup.date()
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.date().required('Champs requis'),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    pacs_place: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_spouse_name: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_spouse_firstname: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_spouse_lieu_naissance: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_spouse_date_naissance: Yup.date()
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.date().required('Champs requis'),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    pacs_partner_adress: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_partner_city: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_partner_zipcode: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_partner_email: Yup.string()
      .email(`Saisissez une adresse e-mail valide.`)
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }),
    pacs_effective_date: Yup.date()
      .nullable()
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.date().nullable().required('Champs requis'),
      }),
    pacs_position: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string().required('Champs requis'),
      }
    ),
    pacs_secu_number: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP && partner_family_situation === PACTE,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      }
    ),

    // IF PM
    forme_juridique_personne_morale: Yup.string().when(
      'personne_physique_morale',
      {
        is: (personne_physique_morale) => personne_physique_morale === PM,
        then: Yup.string().required('Champs requis'),
      }
    ),
    capital_personne_morale: Yup.number().when('personne_physique_morale', {
      is: (personne_physique_morale) => personne_physique_morale === PM,
      then: Yup.number()
        .min(1, 'Le capital doit être au minimum de 1 euro.')
        .typeError('Veuillez saisir un nombre.')
        .required('Champs requis'),
    }),
    raison_sociale_actionnaire_personne_morale_sasu: Yup.string().when(
      'personne_physique_morale',
      {
        is: (personne_physique_morale) => personne_physique_morale === PM,
        then: Yup.string().required('Champs requis'),
      }
    ),
    adresse_actionnaire_personne_morale_sasu: Yup.string().when(
      'personne_physique_morale',
      {
        is: (personne_physique_morale) => personne_physique_morale === PM,
        then: Yup.string().required('Champs requis'),
      }
    ),
    ville_actionnaire_personne_morale_sasu: Yup.string().when(
      'personne_physique_morale',
      {
        is: (personne_physique_morale) => personne_physique_morale === PM,
        then: Yup.string().required('Champs requis'),
      }
    ),
    code_postal_actionnaire_personne_morale_sasu: Yup.string().when(
      'personne_physique_morale',
      {
        is: (personne_physique_morale) => personne_physique_morale === PM,
        then: Yup.string().required('Champs requis'),
      }
    ),

    // if has concubin
    partner_cohabitant_title: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_cohabitant_name: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_cohabitant_firstname: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_cohabitant_email: Yup.string()
      .email(`Saisissez une adresse e-mail valide.`)
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string().required('Champs requis'),
      }),
    partner_cohabitant_birthday: Yup.date()
      .nullable()
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.date().nullable().required('Champs requis'),
      }),

    partner_cohabitant_place_of_birth: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_cohabitant_adress: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_cohabitant_city: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string().required('Champs requis'),
      }
    ),
    partner_cohabitant_zipcode: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string().required('Champs requis'),
      }
    ),
    cohabitant_effective_date: Yup.date()
      .nullable()
      .when(['personne_physique_morale', 'partner_family_situation'], {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.date().nullable().required('Champs requis'),
      })
      .typeError('Date est obligatoire'),
    cohabitant_position: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string().required('Champs requis'),
      }
    ),
    cohabitant_secu_number: Yup.string().when(
      ['personne_physique_morale', 'partner_family_situation'],
      {
        is: (personne_physique_morale, partner_family_situation) =>
          personne_physique_morale === PP &&
          partner_family_situation === CONCUBIN,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      }
    ),
  });
};

//  eslint-disable-next-line
export { validationSchema };
