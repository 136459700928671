import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from 'auth/react-auth0-spa';
import logo from 'assets/images/octopus.svg';

const Login = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const history = useHistory();

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid itemxs={12}>
        <img src={logo} alt="BBF" />
      </Grid>
      <Grid item xs={12} align="center">
        <Typography variant="h4">Welcome to Be Business Focus</Typography>
        <Typography variant="h5">Créer mon entreprise</Typography>
      </Grid>
      {!isAuthenticated && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => loginWithRedirect({})}
        >
          Log In
        </Button>
      )}
      {isAuthenticated && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('my-companies')}
        >
          Mon compte
        </Button>
      )}
    </Grid>
  );
};

Login.propTypes = {
  login: PropTypes.func,
  onClose: PropTypes.func,
  accessDenied: PropTypes.bool,
};

Login.defaultProps = {
  accessDenied: false,
};

export default Login;
