import { Formik } from 'formik';
import React from 'react';
import FormActions from '../FormActions';
import prepareData from './prepareData';
import { validationSchema } from './validation';
import CAC from './CAC';
import { initialValues } from './initValues';

function CACContainer({ goBack, saveAndNext, formulaireComplet }) {
  const form = { ...formulaireComplet };
  const { currentForm } = form;

  return (
    <Formik
      initialValues={initialValues(form)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const data = prepareData(values);
        const updatedForm = { ...form, ...data };
        setSubmitting(false);
        saveAndNext(updatedForm);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CAC />
          <FormActions goBack={goBack} currentForm={currentForm} />
        </form>
      )}
    </Formik>
  );
}

export default CACContainer;
