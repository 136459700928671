import { Avatar } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { personTypes, PM } from 'constants/usersContants';
import React from 'react';
import useStyles from '../style';

function PartnerCard({ partner, handleClick, canEdit, isSelected, disabled }) {
  const classes = useStyles();

  const getName = () => {
    if (partner.personne_physique_morale === PM) {
      return partner.raison_sociale_actionnaire_personne_morale_sasu;
    }
    return `${partner.partner_name} ${partner.partner_firstname}`;
  };

  return (
    <div
      className={`${classes.partnerCard} ${
        isSelected ? classes.partnerCardSelected : ''
      }${disabled ? classes.partnerCardDisable : ''}`}
      onClick={() => (!disabled ? handleClick(partner.id) : null)}
    >
      <Avatar
        className={`${classes.avatar} ${disabled ? classes.avatarDisable : ''}`}
      >
        {`${partner.partner_name
          .toUpperCase()
          .charAt(0)}${partner.partner_firstname.toUpperCase().charAt(0)}`}
      </Avatar>
      <div>
        <div className={classes.parnterName}>{getName()}</div>
        {
          personTypes.find(
            (personType) =>
              personType.value === partner.personne_physique_morale
          ).label
        }
      </div>
      {canEdit && (
        <div className={classes.editIconContainer}>
          <Edit className={classes.editIcon} />
        </div>
      )}
    </div>
  );
}

export default PartnerCard;
