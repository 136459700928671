const { PARTNER } = require('constants/usersContants');

const cleanInfoPM = (setFieldValue) => {
  setFieldValue('dg_type', PARTNER);
  setFieldValue('dg_title', '');
  setFieldValue('dg_name', '');
  setFieldValue('dg_firstname', '');
  setFieldValue('dg_email', '');
  setFieldValue('dg_date_of_birth', null);
  setFieldValue('dg_place_of_birth', '');
  setFieldValue('dg_state_of_birth', '');
  setFieldValue('dg_country_of_birth', '');
  setFieldValue('dg_nationality', '');
  setFieldValue('dg_place_of_living', '');
  setFieldValue('dg_city', '');
  setFieldValue('dg_zipcode', '');
  setFieldValue('dg_father_name', '');
  setFieldValue('dg_father_firstname', '');
  setFieldValue('dg_mother_name', '');
  setFieldValue('dg_mother_firstname', '');
};

export default cleanInfoPM;
