const lettre_bailleur = {
  title: 'lettre_bailleur',
  source: null,
  signBy: null,
  recipients: [],
};
const lettre_syndic = {
  title: 'lettre_syndic',
  source: null,
  signBy: null,
  recipients: [],
};

export { lettre_bailleur, lettre_syndic };
