/* eslint-disable react/prop-types */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

import { RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  title: {},
  radio: {},
});

const SignupSchema = Yup.object().shape({
  partner_family_situation: Yup.string().required('un choix est necessaire'),
});

const ChoixSituationFamiliale = ({
  partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  let partnerOrShareholderLabel = "de l'associé"; // ca c'est ce qu'on affiche de base      let partnerNameLabel= "Nom de l'associé";

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    partnerOrShareholderLabel = "de l'actionnaire";
  }

  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_PARTNER,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        // if (
        //   values.partner_family_situation !== 'marié(e)' &&
        //   values.partner_family_situation !== 'pacsé(e)' &&
        //   formValues.alone === 'a_plusieurs'
        // ) {
        //   Automate.demandeInfoApportsPartenaire();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        // if (values.partner_family_situation === 'marié(e)') {
        //   Automate.demandeInfoDuMariage();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        // if (values.partner_family_situation === 'pacsé(e)') {
        //   Automate.demandeInfoDuPacs();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        if (
          isRecap &&
          values.partner_family_situation !== 'marié(e)' &&
          values.partner_family_situation !== 'pacsé(e)' &&
          formValues.alone === 'seul'
        ) {
          Automate.recapChoixSituationFamilialeVersListeFin();
          setAutomateState(Automate.state);
        }

        if (
          isRecap &&
          values.partner_family_situation !== 'marié(e)' &&
          values.partner_family_situation !== 'pacsé(e)' &&
          formValues.alone === 'a_plusieurs'
        ) {
          Automate.nextChoixSituationFamilialeVersInfoApportsPartenaire();
          setAutomateState(Automate.state);
        }

        // if (!isRecap)
        else {
          if (values.partner_family_situation === 'marié(e)') {
            Automate.nextChoixSituationFamilialeVersInfoMariage();
            setAutomateState(Automate.state);
            return;
          }

          if (values.partner_family_situation === 'pacsé(e)') {
            Automate.nextChoixSituationFamilialeVersInfoPacs();
            setAutomateState(Automate.state);
            return;
          }

          if (
            values.partner_family_situation !== 'marié(e)' &&
            values.partner_family_situation !== 'pacsé(e)' &&
            formValues.alone === 'seul'
          ) {
            Automate.nextChoixSituationFamilialeVersChoixGerant();
            setAutomateState(Automate.state);
            return;
          }
          if (
            values.partner_family_situation !== 'marié(e)' &&
            values.partner_family_situation !== 'pacsé(e)' &&
            formValues.alone === 'a_plusieurs'
          ) {
            Automate.nextChoixSituationFamilialeVersInfoApportsPartenaire();
            setAutomateState(Automate.state);
            return;
          }

          setActiveStep(activeStep + 1);
        }
        // Automate.next();
        // setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Box className={classes.title}>
                <Typography variant="h6">
                  La situation de famille {partnerOrShareholderLabel}
                </Typography>
              </Box>

              <Box>
                <Typography variant="h6">
                  {' '}
                  Votre situation matrimoniale{' '}
                </Typography>
              </Box>

              <Box>
                <Field component={RadioGroup} name="partner_family_situation">
                  <FormControlLabel
                    value="marié(e)"
                    control={<Radio />}
                    label="Marié(e)"
                  />
                  <FormControlLabel
                    value="divorcé(e)"
                    control={<Radio />}
                    label="Divorcé(e)"
                  />
                  <FormControlLabel
                    value="pacsé(e)"
                    control={<Radio />}
                    label="Pacsé(e)"
                  />
                  <FormControlLabel
                    value="célibataire"
                    control={<Radio />}
                    label="Célibataire"
                  />
                  <FormControlLabel
                    value="veuf(ve)"
                    control={<Radio />}
                    label="Veuf(ve)"
                  />
                </Field>
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixSituationFamiliale.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixSituationFamiliale;
