// import dataTest from './dataTest';

const sarl = {
  // _id: '61a0a997bd991b15f9693c51', // comment if you want to create company
  // documents: dataTest,
  nom_propre: 'societe',
  alone: 'a_plusieurs',
  personne_physique_morale: 'personne_morale',
  choix_is: 'non',
  nombre_de_personnes_physiques: '',
  nombre_de_personnes_morales: '',
  eurl_sasu: 'sarl',
  sci: '',
  company_name: 'JC FER',
  business_name: 'JC FER',
  code_ape: 'Rachat metaux',
  address: '406 Rue Jean Jaurès',
  zipcode: '59860',
  city: "Bruay-sur-l'Escaut",
  siege_social_chez: 'chez_gerant',
  proprietaire: 'Proprietaire',
  maison_individuelle: 'oui',
  prenom_syndic: '',
  nom_syndic: '',
  adresse_syndic: '',
  prenom_bailleur: '',
  nom_bailleur: '',
  adresse_bailleur: '',
  date_bail: null,
  date_debut_activite: '2021-11-10',
  closing_date_with_year: '2021-11-30',
  partners: [
    {
      id: 'deae56f0-5063-44ab-88a7-c18598aadd58',
      personne_physique_morale: 'personne_physique',

      partner_title: 'M.',
      partner_name: 'wandolski',
      partner_firstname: 'matthieu',
      partner_father_name: 'fsjkl',
      partner_father_firstname: 'lkj',
      partner_mother_name: 'lkj',
      partner_mother_firstname: 'lkj',
      partner_date_of_birth: '2021-11-01',
      partner_place_of_birth: "Condé-sur-l'Escaut",
      partner_place_of_living: '569 Rue Léon Gambetta',
      partner_city: 'Fresnes-sur-Escaut',
      partner_zipcode: '59970',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'fr',
      partner_nationality: 'fr',
      partner_family_situation: 'célibataire',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: null,
      pacs_spouse_title: 'M.',
      pacs_spouse_name: '',
      pacs_spouse_firstname: '',
      pacs_spouse_departement_naissance: '',
      pacs_spouse_lieu_naissance: '',
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: 'matthieu.wandolski@gmail.com',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Hauts-de-France',
    },
    {
      id: 'e7fafd75-9c89-4bbf-8ccf-3d9843866e85',
      personne_physique_morale: 'personne_physique',

      partner_title: 'M.',
      partner_name: 'Ferrand',
      partner_firstname: 'Arnaud',
      partner_father_name: 'flkj',
      partner_father_firstname: 'lkj',
      partner_mother_name: 'lkj',
      partner_mother_firstname: 'lj',
      partner_date_of_birth: '1990-09-24',
      partner_place_of_birth: "Condé-sur-l'Escaut",
      partner_place_of_living: '472 Chemin Mathias',
      partner_city: 'Fresnes-sur-Escaut',
      partner_zipcode: '59970',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'fr',
      partner_nationality: 'fr',
      partner_family_situation: 'célibataire',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: null,
      pacs_spouse_title: 'M.',
      pacs_spouse_name: '',
      pacs_spouse_firstname: '',
      pacs_spouse_departement_naissance: '',
      pacs_spouse_lieu_naissance: '',
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      // partner_email: 'arnaud@bebusinessfocus.com',
      partner_email: 'matthieu@cheeeseboxfrance.fr',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Hauts-de-France',
    },
    {
      id: 'd69c968e-2df8-4c4f-a9bb-46376c6ad93b',
      forme_juridique_personne_morale: 'EURL',
      capital_personne_morale: 2000,
      raison_sociale_actionnaire_personne_morale_sasu: 'VRT',
      adresse_actionnaire_personne_morale_sasu: '55 Avenue Thiers',
      code_postal_actionnaire_personne_morale_sasu: '33100',
      ville_actionnaire_personne_morale_sasu: 'Bordeaux',
      rcs_actionnaire_personne_morale_sasu: 'VRT',
      ville_rcs_actionnaire_personne_morale_sasu: 'Bordeaux',
      titre_dirigeant_de_personne_morale_sasu: '',
      nom_dirigeant_de_personne_morale_sasu: '',
      prenom_dirigeant_de_personne_morale_sasu: '',
      adresse_dirigeant_de_personne_morale_sasu: '',
      code_postal_dirigeant_de_personne_morale_sasu: '',
      ville_dirigeant_de_personne_morale_sasu: '',
      personne_physique_morale: 'personne_morale',

      partner_title: 'M.',
      partner_name: 'julien',
      partner_firstname: 'declerc',
      partner_father_name: 'lkj',
      partner_father_firstname: 'lkj',
      partner_mother_name: 'jhjkh',
      partner_mother_firstname: 'kjhjkh',
      partner_date_of_birth: '2001-12-12',
      partner_place_of_birth: 'Fresnes-sur-Escaut',
      partner_place_of_living: 'La Place',
      partner_city: 'Derval',
      partner_zipcode: '44590',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'fr',
      partner_nationality: 'fr',
      partner_family_situation: 'marié(e)',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: null,
      pacs_spouse_title: 'M.',
      pacs_spouse_name: '',
      pacs_spouse_firstname: '',
      pacs_spouse_departement_naissance: '',
      pacs_spouse_lieu_naissance: '',
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: 'matthieu@bebusinessfocus.com',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Hauts-de-France',
    },
  ],
  capital_in_numbers: '300',
  share_value: '1',
  depot_capital_chez: 'be_business_focus',
  date_depot_des_fonds: null,
  banking_corporation_name: '',
  adresse_agence_bancaire: '',
  ville_agence_bancaire: '',
  code_postal_agence_bancaire: '',
  cash_funds_numbers: '',
  apport_nature: 'non',
  supplies_funds_numbers: '0',
  apport_fonds_de_commerce: 'non',
  date_apport_fonds_de_commerce: null,
  valeur_apport_fonds_de_commerce: '',
  apport_titres_societe: 'non',
  date_apport_titres_societe: null,
  valeur_apport_titres_societe: '',
  raison_sociale_societe_apport_titres_societe: '',
  nombre_titres_apportes_apport_titres_societe: '',
  nombre_titres_total_apport_titres_societe: '',
  apport_nature_divers: 'non',
  date_apport_nature_divers: null,
  valeur_apport_nature_divers: '',
  description_biens_apport_nature_divers: '',
  apport_industrie: 'non',
  expertise_funds_numbers: '',
  cash_funds_onetime_payment: 'oui',
  payments_amount: '',
  director_type: "l_associe_de_l'EURL",
  director_title: 'M.',
  director_name: '',
  director_firstname: '',
  director_father_name: '',
  director_father_firstname: '',
  director_mother_name: '',
  director_mother_firstname: '',
  director_date_of_birth: null,
  director_place_of_birth: '',
  director_state_of_birth: '',
  director_country_of_birth: '',
  director_nationality: '',
  director_place_of_living: '',
  director_email: '',
  associe_gerant_multiassocie: [
    {
      id: 'e7fafd75-9c89-4bbf-8ccf-3d9843866e85',
      personne_physique_morale: 'personne_physique',

      partner_title: 'M.',
      partner_name: 'tuche',
      partner_firstname: 'jeff',
      partner_father_name: 'flkj',
      partner_father_firstname: 'lkj',
      partner_mother_name: 'lkj',
      partner_mother_firstname: 'lj',
      partner_date_of_birth: '1990-09-24',
      partner_place_of_birth: "Condé-sur-l'Escaut",
      partner_place_of_living: '472 Chemin Mathias',
      partner_city: 'Fresnes-sur-Escaut',
      partner_zipcode: '59970',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'fr',
      partner_nationality: 'fr',
      partner_family_situation: 'célibataire',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: null,
      pacs_spouse_title: 'M.',
      pacs_spouse_name: '',
      pacs_spouse_firstname: '',
      pacs_spouse_departement_naissance: '',
      pacs_spouse_lieu_naissance: '',
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: 'matthieu.wandolski@gmail.com',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Hauts-de-France',
    },
    {
      id: 'deae56f0-5063-44ab-88a7-c18598aadd58',
      personne_physique_morale: 'personne_physique',

      partner_title: 'M.',
      partner_name: 'wandolski',
      partner_firstname: 'matthieu',
      partner_father_name: 'fsjkl',
      partner_father_firstname: 'lkj',
      partner_mother_name: 'lkj',
      partner_mother_firstname: 'lkj',
      partner_date_of_birth: '2021-11-01',
      partner_place_of_birth: "Condé-sur-l'Escaut",
      partner_place_of_living: '569 Rue Léon Gambetta',
      partner_city: 'Fresnes-sur-Escaut',
      partner_zipcode: '59970',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'fr',
      partner_nationality: 'fr',
      partner_family_situation: 'célibataire',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: null,
      pacs_spouse_title: 'M.',
      pacs_spouse_name: '',
      pacs_spouse_firstname: '',
      pacs_spouse_departement_naissance: '',
      pacs_spouse_lieu_naissance: '',
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: 'matthieu.wandolski@gmail.com',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Hauts-de-France',
    },
  ],
  presence_dg: '',
  dg: [],
  dg_type: "l_associe_de_l'EURL",
  dg_title: 'M.',
  dg_name: '',
  dg_firstname: '',
  dg_address: '',
  dg_zipcode: '',
  dg_city: '',
  dg_email: '',
  duree_gerance_determinee: 'non',
  duree_de_gerance: '0',
  preexisting_contract: 'non',
  number_of_preexisting_contract: 0,
  contract1_date: null,
  contract1_type: '',
  contract1_amount: '',
  contract1_person_who_paid: '',
  contract2_date: null,
  contract2_type: '',
  contract2_amount: '',
  contract2_person_who_paid: '',
  presence_commissaire_aux_comptes: 'non',
  duree_exercice_commissaire_aux_comptes: '',
  titre_commissaire_aux_comptes: 'M.',
  nom_commissaire_aux_comptes: '',
  prenom_commissaire_aux_comptes: '',
  nom_cabinet_commissaire_aux_comptes: '',
  adresse_cabinet_commissaire_aux_comptes: '',
  nom_suppleant: '',
  prenom_suppleant: '',
  adresse_cabinet_suppleant: '',
  procuration: 'non',
  nom_cabinet_procuration: '',
  qualite_cabinet_procuration: '',
  adresse_cabinet_procuration: '',
  ville_cabinet_procuration: '',
  code_postal_cabinet_procuration: '',
  procu_bbf_dossier_constitution: 'non',
  procu_bbf_attestation_beneficiaires: 'non',
  procu_bbf_annonce_legale: 'non',
  procu_bbf_attestation_depot_fonds: 'non',
  procu_bbf_depot_greffe_dossier_constitution: 'non',
  kit: 'kit4soc',
  companyAlreadyCreated: null,
  partenaire_supplementaire: 'non',
  // alternativeId: 'w1hho8t', // comment if you want to create company
  // companyId: '61a0a995c7b93d0009435f62', // comment if you want to create company
};

export default sarl;
