const sasu1 = {
  nom_propre: 'societe',
  alone: 'seul',
  personne_physique_morale: 'personne_physique',
  choix_is: 'non',
  nombre_de_personnes_physiques: '',
  nombre_de_personnes_morales: '',
  eurl_sasu: 'sasu',
  sci: '',
  company_name: 'formDenoSociale',
  business_name: 'formNomCommercial',
  code_ape: 'formObjetSocial',
  address: 'Traverse du T',
  zipcode: '06600',
  city: 'Antibes',
  siege_social_chez: 'chez_gerant',
  proprietaire: 'Proprietaire',
  maison_individuelle: 'oui',
  prenom_syndic: '',
  nom_syndic: '',
  adresse_syndic: '',
  prenom_bailleur: '',
  nom_bailleur: '',
  adresse_bailleur: '',
  date_bail: null,
  date_debut_activite: '2021-11-25',
  closing_date_with_year: '2021-11-26',
  partners: [
    {
      id: '84eed80d-28f8-4edb-be89-7377d83c4ff7',
      partner_title: 'M.',
      partner_name: 'formNomAsso',
      partner_firstname: 'formPrenomAsso',
      partner_father_name: 'formNomPereAsso',
      partner_father_firstname: 'formPrenomPereAsso',
      partner_mother_name: 'formNomMereAsso',
      partner_mother_firstname: 'formPrenomMereAsso',
      partner_date_of_birth: '2021-11-01',
      partner_place_of_birth: 'Hyds',
      partner_place_of_living: 'U Centru',
      partner_city: 'Porto-Vecchio',
      partner_zipcode: '20137',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'formPaysNaissanceDirigeant',
      partner_nationality: 'formNationaliteDirigeant',
      partner_family_situation: 'marie9(e)',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: '2021-11-02',
      partner_wedding_prenup_place: 'Giremoutiers',
      partner_wedding_prenup_officer_office_name: 'greger',
      partner_wedding_prenup_officer_office_place:
        'Z.i.petite Savate Rue Mair 59600 Maubeuge',
      partner_spouse_name: 'iu',
      partner_spouse_firstname: 'hi',
      partner_wedding_date: '2021-11-02',
      partner_wedding_place: 'Villers-Bocage',
      partner_spouse_date_naissance: '2021-11-02',
      partner_spouse_lieu_naissance: 'Gatey',
      partner_spouse_departement_naissance: 'Bourgogne-Franche-Comte9',
      pacs_spouse_date_naissance: null,
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: '',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Normandie',
    },
  ],
  capital_in_numbers: '15000',
  share_value: '10',
  depot_capital_chez: 'be_business_focus',
  date_depot_des_fonds: null,
  banking_corporation_name: '',
  adresse_agence_bancaire: '',
  ville_agence_bancaire: '',
  code_postal_agence_bancaire: '',
  cash_funds_numbers: '10000',
  apport_nature: 'oui',
  supplies_funds_numbers: '5000',
  apport_fonds_de_commerce: 'oui',
  date_apport_fonds_de_commerce: '2021-11-02',
  valeur_apport_fonds_de_commerce: '1400',
  apport_titres_societe: 'oui',
  date_apport_titres_societe: '2021-11-01',
  valeur_apport_titres_societe: '1600',
  raison_sociale_societe_apport_titres_societe:
    'formRaisonSocialesSocieteApporte',
  nombre_titres_apportes_apport_titres_societe: '10',
  nombre_titres_total_apport_titres_societe: '30000',
  apport_nature_divers: 'oui',
  date_apport_nature_divers: '2021-10-23',
  valeur_apport_nature_divers: '2000',
  description_biens_apport_nature_divers: 'formDescriptionApportsNatureDivers',
  apport_industrie: 'non',
  expertise_funds_numbers: '',
  cash_funds_onetime_payment: 'non',
  payments_amount: '6000',
  director_type: "l_associe_de_l'EURL",
  director_title: 'M.',
  director_name: '',
  director_firstname: '',
  director_father_name: '',
  director_father_firstname: '',
  director_mother_name: '',
  director_mother_firstname: '',
  director_date_of_birth: null,
  director_place_of_birth: '',
  director_state_of_birth: '',
  director_country_of_birth: '',
  director_nationality: '',
  director_place_of_living: '',
  director_email: 'shooter.xav@hotmail.fr',
  associe_gerant_multiassocie: [],
  presence_dg: 'oui',
  dg: [],
  dg_type: 'un_tiers',
  dg_title: 'M.',
  dg_name: 'formNomDG',
  dg_firstname: 'formPrenomDG',
  dg_address: 'Place des F.f.i.',
  dg_zipcode: '29200',
  dg_city: 'Brest',
  dg_email: 'shooter.xav@hotmail.fr',
  duree_gerance_determinee: 'non',
  duree_de_gerance: '0',
  preexisting_contract: 'oui',
  number_of_preexisting_contract: 2,
  contract1_date: '2021-11-02',
  contract1_type: 'formNatureActePrealable1',
  contract1_amount: '650',
  contract1_person_who_paid: 'formPayeurACtePrealable1',
  contract2_date: '2021-11-01',
  contract2_type: 'formNatureActePrealable2',
  contract2_amount: '350',
  contract2_person_who_paid: 'formPayeurActePrealable2',
  presence_commissaire_aux_comptes: 'oui',
  duree_exercice_commissaire_aux_comptes: 6,
  titre_commissaire_aux_comptes: 'M.',
  nom_commissaire_aux_comptes: 'formNomCAC',
  prenom_commissaire_aux_comptes: 'formPrenomCAC',
  nom_cabinet_commissaire_aux_comptes: '',
  adresse_cabinet_commissaire_aux_comptes: 'Voie V/20 75020 Paris',
  nom_suppleant: 'formNomSuppleantCAC',
  prenom_suppleant: 'formPrenomSuppleantCAC',
  adresse_cabinet_suppleant: 'Cite Belleforriere All K 59286 Roost-Warendin',
  procuration: 'oui',
  nom_cabinet_procuration: 'formNomCabinetProcuration',
  qualite_cabinet_procuration: 'formQualiteCabinetProcuration',
  adresse_cabinet_procuration: 'M Forestiere Heille',
  ville_cabinet_procuration: 'Me9tairies-Saint-Quirin',
  code_postal_cabinet_procuration: '57560',
  procu_bbf_dossier_constitution: 'non',
  procu_bbf_attestation_beneficiaires: 'non',
  procu_bbf_annonce_legale: 'non',
  procu_bbf_attestation_depot_fonds: 'non',
  procu_bbf_depot_greffe_dossier_constitution: 'non',
  rcs_ville: '',
  kit: 'kit4soc',
  companyAlreadyCreated: null,
  email: '',

  cac_suppleant: 'oui',
  partenaire_supplementaire: 'non',
  email_dg: '',
  type_CAC: 'physique',
  nom_propre_CAC: 'nom_propre',
  type_CAC_suppleant: 'physique',
  director_zipcode: '97232',
  director_city: 'Le Lamentin',
  dg_father_name: 'formNomPereDG',
  dg_father_firstname: 'formPRenomPereDG',
  dg_mother_name: 'fonrNomMEreDG',
  dg_mother_firstname: 'formPrenomMereDG',
  dg_date_of_birth: '2021-11-02',
  dg_place_of_birth: 'formVilleNaissanceDG',
  dg_state_of_birth: 'formDepartementNaissanceDG',
  dg_country_of_birth: 'formPaysNaissanceDG',
  dg_nationality: 'formNationaliteDG',
  alternativeId: 'qcp843c',
};
export default sasu1;
