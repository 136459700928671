import React, {
  useState,
  Fragment,
  useContext,
  useRef,
  useEffect,
} from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import {
  MenuItem,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { Autocomplete as AutocompleteMUI } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import FormControl from '@material-ui/core/FormControl';

import { TextField, RadioGroup, Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';
import * as Yup from 'yup';
import { Autocomplete } from 'formik-material-ui-lab';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const InfoGerant = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  const [selectedDate] = useState(null);

  let civiliteGerantOuPresidentLabel = 'Civilité du gérant non associé ';
  let gerantOuPresident = 'gérant';
  let geranceoupresidence = 'gérance';

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    civiliteGerantOuPresidentLabel = 'Civilité du Président non actionnaire';
    gerantOuPresident = 'Président';
    geranceoupresidence = 'présidence';
  }

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue('director_place_of_living', newValue.properties.name);
      if (newValue.properties.postcode && newValue.properties.city) {
        setFieldValue('director_zipcode', newValue.properties.postcode);
        setFieldValue('director_city', newValue.properties.city);
      }
    } else {
      setFieldValue('director_place_of_living', '');
      setFieldValue('director_zipcode', '');
      setFieldValue('director_city', '');
      setAddressValue(newValue);
    }
  };

  //-------------

  const [cityList, setCityList] = useState([]);
  const [cityValue, setcityValue] = useState(null);
  const [cityInput, setCityInput] = useState(null);
  const [loadingCityList, setLoadingCityList] = useState(false);

  const cityInputDebounce = useRef(
    _.debounce((newValue) => setCityInput(newValue), 500)
  );

  useEffect(() => {
    if (cityInput) {
      setLoadingCityList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: cityInput, type: 'municipality', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setCityList(data.features);
          } else {
            setCityList([]);
          }
          setLoadingCityList(false);
        });
    } else {
      setCityList([]);
      setLoadingCityList(false);
    }
  }, [cityInput]);

  const handleFetchCity = async (newInputValue) => {
    setLoadingCityList(true);
    setCityInput(null);
    cityInputDebounce.current(newInputValue);
  };

  const handleSelectCity = async (newValue, setFieldValue) => {
    if (newValue) {
      setcityValue(newValue);
      setFieldValue('director_place_of_birth', newValue.properties.name);
      if (newValue.properties.context) {
        const { context } = newValue.properties;
        const contextArr = context.split(', ');
        const state = contextArr[contextArr.length - 2];
        setFieldValue('director_state_of_birth', state);
      }
    } else {
      setFieldValue('director_place_of_birth', '');
      setFieldValue('director_state_of_birth', '');
      setcityValue(newValue);
    }
  };
  //------------

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          director_title: Yup.string().required('un choix est necessaire'),
          director_name: Yup.string().required('un choix est necessaire'),
          director_firstname: Yup.string().required('un choix est necessaire'),
          director_father_name: Yup.string().required(
            'un choix est necessaire'
          ),
          director_father_firstname: Yup.string().required(
            'un choix est necessaire'
          ),
          director_mother_name: Yup.string().required(
            'un choix est necessaire'
          ),
          director_mother_firstname: Yup.string().required(
            'un choix est necessaire'
          ),
          director_date_of_birth: Yup.date().required(
            'un choix est necessaire'
          ),
          director_place_of_birth: Yup.string().required(
            'un choix est necessaire'
          ),
          director_state_of_birth: Yup.string().required(
            'un choix est necessaire'
          ),
          director_country_of_birth: Yup.string().required(
            'un choix est necessaire'
          ),
          director_nationality: Yup.string().required(
            'un choix est necessaire'
          ),
          director_place_of_living: Yup.string().required(
            'un choix est necessaire'
          ),
          duree_gerance_determinee: Yup.string().required(
            'un choix est necessaire'
          ),

          duree_de_gerance: Yup.number().when(
            'duree_gerance_determinee',
            (duree_gerance_determinee) => {
              if (duree_gerance_determinee === 'oui') {
                return Yup.number().required('Required');
              }
              return Yup.number();
            }
          ),

          // duree_de_gerance: Yup.number().required('un choix est necessaire'),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        // SciAutomate.next();
        // setAutomateState(SciAutomate.state);

        SciAutomate.nextInfoGerantVersInfoCapitalSociete();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        submitForm,
      }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              fullwidth="true"
            >
              <Typography variant="h5">
                {civiliteGerantOuPresidentLabel}{' '}
              </Typography>
              <Field component={RadioGroup} name="director_title">
                <FormControlLabel value="M." control={<Radio />} label="M." />
                <FormControlLabel value="Mme" control={<Radio />} label="Mme" />
              </Field>

              <Box>
                <Field
                  component={TextField}
                  type="string"
                  label={`Nom du ${gerantOuPresident}`}
                  name="director_name"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label={`Prénom du ${gerantOuPresident}`}
                  name="director_firstname"
                  className={classes.textField}
                  autoComplete="off"
                />
              </Box>

              <Box>
                <Field
                  component={TextField}
                  type="string"
                  label={`Nom du père du ${gerantOuPresident}`}
                  name="director_father_name"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label={`Prénom du père du ${gerantOuPresident}`}
                  name="director_father_firstname"
                  className={classes.textField}
                  autoComplete="off"
                />
              </Box>

              <Box>
                <Field
                  component={TextField}
                  type="string"
                  label={`Nom de naissance de la mère du ${gerantOuPresident}`}
                  name="director_mother_name"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label={`Prénom de la mère du ${gerantOuPresident}`}
                  name="director_mother_firstname"
                  className={classes.textField}
                  autoComplete="off"
                />
              </Box>

              <Box>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label={`Date de naissance du ${gerantOuPresident}`}
                  value={selectedDate}
                  maxDate={new Date()}
                  placeholder="01/01/1990"
                  format="dd/MM/yyyy"
                  name="director_date_of_birth"
                  className={classes.textField}
                  autoComplete="off"
                />

                <Field
                  // component={TextField}
                  // type="string"
                  // label={`Ville de naissance du ${gerantOuPresident}`}
                  // name="director_place_of_birth"
                  // className={classes.textField}
                  // autoComplete="off"

                  component={AutocompleteMUI}
                  // type="string"
                  label={`Ville de naissance du ${gerantOuPresident}`}
                  // name={`partners[${partnerIndex}].partner_place_of_birth`}
                  name="director_place_of_birth"
                  className={classes.textField}
                  value={cityValue}
                  loading={loadingCityList}
                  options={cityList}
                  filterOptions={(x) => x}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option && option.properties && option.properties.name
                  }
                  onInputChange={(event, newInputValue) =>
                    handleFetchCity(newInputValue)
                  }
                  onChange={(event, newValue) =>
                    handleSelectCity(newValue, setFieldValue)
                  }
                  renderInput={(params) => (
                    <MUITextField
                      {...params}
                      error={
                        touched.partner_place_of_birth &&
                        !!errors.partner_place_of_birth
                      }
                      helperText={
                        touched.partner_place_of_birth &&
                        errors.partner_place_of_birth
                      }
                      label={`Ville de naissance  du ${gerantOuPresident}`}
                      placeholder={formValues.director_place_of_birth}
                      size="small"
                    />
                  )}
                  renderOption={(option) => {
                    return (
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2" color="textSecondary">
                            {option.properties.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              </Box>
              <Box>
                <Field
                  component={TextField}
                  type="string"
                  label={`Département de naissance du ${gerantOuPresident}`}
                  placeholder="ex: Paris 11eme arrondissement"
                  name="director_state_of_birth"
                  className={classes.textField}
                  autoComplete="off"
                />
              </Box>

              <Box>
                <Field
                  component={TextField}
                  type="string"
                  label={`Pays de naissance du ${gerantOuPresident}`}
                  name="director_country_of_birth"
                  className={classes.textField}
                  apport_fonds_de_commerce
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label={`Nationalité du ${gerantOuPresident}`}
                  name="director_nationality"
                  className={classes.textField}
                  autoComplete="off"
                />
              </Box>
              <Box>
                <Field
                  component={Autocomplete}
                  placeholder="123 rue Bidon 57560 Paris"
                  // label={`Adresse du ${gerantOuPresident}`}
                  name="director_place_of_living"
                  className={classes.nameTextField}
                  value={addressValue}
                  loading={loadingAddressList}
                  options={addressList}
                  filterOptions={(x) => x}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option && option.properties && option.properties.name
                  }
                  onInputChange={(event, newInputValue) =>
                    handleFetchAddress(newInputValue)
                  }
                  onChange={(event, newValue) =>
                    handleSelectAddress(newValue, setFieldValue)
                  }
                  renderInput={(params) => (
                    <MUITextField
                      {...params}
                      error={
                        touched.director_place_of_living &&
                        !!errors.director_place_of_living
                      }
                      helperText={
                        touched.director_place_of_living &&
                        errors.director_place_of_living
                      }
                      label={`Adresse du ${gerantOuPresident}`}
                      placeholder={formValues.director_place_of_living}
                      size="small"
                    />
                  )}
                  renderOption={(option) => {
                    return (
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2" color="textSecondary">
                            {option.properties.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              </Box>

              {/* MBM */}

              <Box display="flex">
                <Box>
                  <Field
                    component={TextField}
                    type="string"
                    label={`Code postal du ${gerantOuPresident}`}
                    name="director_zipcode"
                    className={classes.textField}
                    autoComplete="off"
                    value={
                      !values.director_zipcode ? '' : values.director_zipcode
                    }
                  />
                </Box>
                <Box>
                  <Field
                    component={TextField}
                    type="string"
                    label={`Ville du ${gerantOuPresident}`}
                    name="director_city"
                    className={classes.textField}
                    autoComplete="off"
                    value={!values.director_city ? '' : values.director_city}
                  />
                </Box>
              </Box>
              <Box>
                <Field
                  component={TextField}
                  type="string"
                  placeholder="xyz@gmail.com"
                  label={`Adresse mail du ${gerantOuPresident}`}
                  name="director_email"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              {/* /MBM */}
              <Box>
                <Typography variant="h6">
                  La {geranceoupresidence} est-elle à durée déterminée :{' '}
                </Typography>
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="duree_gerance_determinee"
                >
                  <FormControlLabel
                    value="non"
                    // onChange={() => {
                    //   formValues.duree_gerance_determinee = 'non';
                    // }}
                    // onClick={() => {
                    //   setFieldValue('duree_de_gerance', 0);
                    // }}
                    onClick={() => {
                      // values.duree_de_gerance = 0;
                      setFieldValue('duree_de_gerance', 0);
                    }}
                    control={<Radio />}
                    label="non"
                  />
                  <FormControlLabel
                    // onChange={() => {
                    //   formValues.duree_gerance_determinee = 'oui';
                    // }}
                    value="oui"
                    control={<Radio />}
                    label="oui"
                  />
                </Field>
              </Box>

              {values.duree_gerance_determinee === 'oui' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Typography variant="subtitle2">
                      veuillez indiquer ci-dessous, le nombre d&apos;années de{' '}
                      {geranceoupresidence} prévu{' '}
                    </Typography>

                    <FormControl>
                      <Field
                        component={Select}
                        name="duree_de_gerance"
                        inputProps={{
                          id: 'duree_de_gerance',
                        }}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                      </Field>
                    </FormControl>
                  </Box>
                </Fragment>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoGerant.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoGerant;
