const initialValues = (values) => {
  return {
    eurl_sasu: values.eurl_sasu,
    siege_social_chez: values.siege_social_chez || '',
    proprietaire: values.proprietaire || '',
    maison_individuelle: values.maison_individuelle || '',
    nom_syndic: values.nom_syndic || '',
    prenom_syndic: values.prenom_syndic || '',
    adresse_syndic: values.adresse_syndic || '',
    syndic_zipcode: values.syndic_zipcode || '',
    syndic_city: values.syndic_city || '',
    nom_bailleur: values.nom_bailleur || '',
    prenom_bailleur: values.prenom_bailleur || '',
    adresse_bailleur: values.adresse_bailleur || '',
    bailleur_zipcode: values.bailleur_zipcode || '',
    bailleur_city: values.bailleur_city || '',
    bailleur_address: values.bailleur_address || '',
    email_bailleur: values.email_bailleur || '',
    date_bail: values.date_bail || null,
    partners: values.partners,
    associe_gerant_multiassocie: values.associe_gerant_multiassocie,
    idGerantForDomiciliation: values.idGerantForDomiciliation || null,
  };
};

// eslint-disable-next-line
export { initialValues };
