const prepareData = (values) => {
  const form = { ...values };
  const { eurl_sasu } = form;

  if (eurl_sasu === 'eurl' || eurl_sasu === 'sasu') {
    form.alone = 'seul';
  }
  if (eurl_sasu === 'sarl' || eurl_sasu === 'sas') {
    form.alone = 'a_plusieurs';
  }

  if (eurl_sasu === 'sasu' || eurl_sasu === 'sas') {
    form.civiliteGerantOuPresidentLabel =
      'Civilité du Président non actionnaire';
    form.gerantOuPresident = 'Président';
    form.geranceoupresidence = 'présidence';
  } else {
    form.civiliteGerantOuPresidentLabel = 'Civilité du gérant non associé ';
    form.gerantOuPresident = 'gérant';
    form.geranceoupresidence = 'gérance';
  }

  return form;
};

export default prepareData;
