import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import _ from 'lodash';
import axios from 'axios';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import {
  FormControlLabel,
  Radio,
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField, RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '60ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const InfoCommissaireAuxComptes = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(3);
  }, []);

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue('adresse_cabinet_procuration', newValue.properties.name);
      if (newValue.properties.postcode && newValue.properties.city) {
        setFieldValue(
          'code_postal_cabinet_procuration',
          newValue.properties.postcode
        );
        setFieldValue('ville_cabinet_procuration', newValue.properties.city);
      }
    } else {
      setFieldValue('adresse_cabinet_procuration', '');
      setFieldValue('code_postal_cabinet_procuration', '');
      setFieldValue('ville_cabinet_procuration', '');
      setAddressValue(newValue);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          procuration: Yup.string().required('un choix est necessaire'),

          nom_cabinet_procuration: Yup.string().when(
            'procuration',
            (procuration) => {
              if (procuration === 'oui') {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          // nom_cabinet_procuration: Yup.string().required('un choix est necessaire'),

          qualite_cabinet_procuration: Yup.string().when(
            'procuration',
            (procuration) => {
              if (procuration === 'oui') {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          // qualite_cabinet_procuration: Yup.string().required('un choix est necessaire'),

          adresse_cabinet_procuration: Yup.string().when(
            'procuration',
            (procuration) => {
              if (procuration === 'oui') {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          // adresse_cabinet_procuration: Yup.string().required('un choix est necessaire'),

          ville_cabinet_procuration: Yup.string().when(
            'procuration',
            (procuration) => {
              if (procuration === 'oui') {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          // ville_cabinet_procuration: Yup.string().required('un choix est necessaire'),

          code_postal_cabinet_procuration: Yup.string().when(
            'procuration',
            (procuration) => {
              if (procuration === 'oui') {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          // code_postal_cabinet_procuration: Yup.string().required(
          //   'un choix est necessaire'
          // ),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });

        setSubmitting(false);

        // if (isRecap) {
        //   SciAutomate.goRecap();
        //   setAutomateState(SciAutomate.state);
        // }
        // setActiveStep(activeStep + 1);
        // SciAutomate.next();
        // setAutomateState(SciAutomate.state);

        if (isRecap) {
          SciAutomate.goRecap();
          setAutomateState(SciAutomate.state);
        }
        setActiveStep(activeStep + 1);
        SciAutomate.nextInfoCommissaireAuxComptesVersChoixProcurationBbf();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Box className={classes.root}>
                <Typography variant="subtitle1">
                  {' '}
                  Je donne une procuration pour réaliser les formalités de
                  création ?
                </Typography>{' '}
                <Field component={RadioGroup} name="procuration">
                  <FormControlLabel
                    value="oui"
                    // onChange={() => {
                    //   ctx[0].procuration = 'oui';
                    // }}
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="non"
                    // onChange={() => {
                    //   ctx[0].procuration = 'non';
                    // }}
                    control={<Radio />}
                    label="Non"
                  />
                </Field>
              </Box>

              {values.procuration === 'oui' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Field
                      component={TextField}
                      type="string"
                      label="Nom du cabinet ou de la personne"
                      name="nom_cabinet_procuration"
                      className={classes.nameTextField}
                      autoComplete="off"
                    />
                    <Field
                      component={TextField}
                      type="string"
                      label="Qualité du cabinet ou de la personne"
                      placeholder="Société d'Expertise Comptable"
                      name="qualite_cabinet_procuration"
                      className={classes.nameTextField}
                      autoComplete="off"
                    />
                    <Field
                      component={Autocomplete}
                      // type="string"
                      // label="Adresse du cabinet ou de la personne"
                      // placeholder="123 rue Bidon 57560 Paris"
                      name="adresse_cabinet_procuration"
                      className={classes.nameTextField}
                      value={addressValue}
                      loading={loadingAddressList}
                      options={addressList}
                      filterOptions={(x) => x}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      getOptionLabel={(option) =>
                        option && option.properties && option.properties.name
                      }
                      onInputChange={(event, newInputValue) =>
                        handleFetchAddress(newInputValue)
                      }
                      onChange={(event, newValue) =>
                        handleSelectAddress(newValue, setFieldValue)
                      }
                      renderInput={(params) => (
                        <MUITextField
                          {...params}
                          error={
                            touched.adresse_cabinet_procuration &&
                            !!errors.adresse_cabinet_procuration
                          }
                          helperText={
                            touched.adresse_cabinet_procuration &&
                            errors.adresse_cabinet_procuration
                          }
                          label="Adresse du cabinet ou de la personne"
                          placeholder={formValues.adresse_cabinet_procuration}
                          size="small"
                        />
                      )}
                      renderOption={(option) => {
                        return (
                          <Grid container alignItems="center">
                            <Grid item>
                              <LocationOnIcon />
                            </Grid>
                            <Grid item xs>
                              <Typography variant="body2" color="textSecondary">
                                {option.properties.label}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      }}
                    />
                  </Box>

                  <Box>
                    <Field
                      component={TextField}
                      type="string"
                      label="Ville du cabinet ou de la personne"
                      name="ville_cabinet_procuration"
                      className={classes.textField}
                      autoComplete="off"
                    />
                    <Field
                      component={TextField}
                      type="string"
                      label="Code postal du cabinet ou de la personne"
                      name="code_postal_cabinet_procuration"
                      className={classes.textField}
                      autoComplete="off"
                    />
                  </Box>
                </Fragment>
              )}

              {/* fin de gestion de la procuration  */}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoCommissaireAuxComptes.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoCommissaireAuxComptes;
