const sciData = {
  nom_propre: 'societe',
  alone: 'a_plusieurs',
  personne_physique_morale: 'personne_physique',
  nombre_de_personnes_physiques: '',
  nombre_de_personnes_morales: '',
  eurl_sasu: 'sci',
  choix_is: 'non',
  sci: '',
  company_name: 'dsqd',
  business_name: 'dsqdsqd',
  code_ape: 'dsdqsdqs',
  address: 'Rue Nationale',
  zipcode: '59000',
  city: 'Lille',
  siege_social_chez: 'chez_gerant',
  proprietaire: 'Proprietaire',
  maison_individuelle: 'oui',
  prenom_syndic: '',
  nom_syndic: '',
  adresse_syndic: '',
  prenom_bailleur: '',
  nom_bailleur: '',
  adresse_bailleur: '',
  date_bail: null,
  option_is: 'non',
  date_debut_activite: '2021-11-04',
  closing_date_with_year: '2021-11-28',
  partners: [
    {
      id: 'eeb44a8a-6cda-46d7-bcce-245972766a45',
      partner_title: 'M.',
      partner_name: 'ezeaeaz',
      partner_firstname: 'zaezae',
      partner_father_name: 'ezaez',
      partner_father_firstname: 'ze',
      partner_mother_name: 'zaezae',
      partner_mother_firstname: 'eazez',
      partner_date_of_birth: '2021-11-01',
      partner_place_of_birth: 'La Marre',
      partner_place_of_living: 'Residence de Menez C Hreg',
      partner_city: 'Plomodiern',
      partner_zipcode: '29550',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'fdsfsd',
      partner_nationality: 'fds',
      partner_family_situation: 'célibataire',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: null,
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: 'matthieu.wandolski@gmail.com',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Bourgogne-Franche-Comté',
    },
    {
      id: 'f45c7a6d-5a3f-4c3c-a080-4f2ddc43bbac',
      partner_title: 'M.',
      partner_name: 'dfdfds',
      partner_firstname: 'fdssf',
      partner_father_name: 'fdsdf',
      partner_father_firstname: 'dsf',
      partner_mother_name: 'fsdfd',
      partner_mother_firstname: 'fds',
      partner_date_of_birth: '2021-11-03',
      partner_place_of_birth: 'Maurepas',
      partner_place_of_living: 'Rue Judaique',
      partner_city: 'Bordeaux',
      partner_zipcode: '33000',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'fddsf',
      partner_nationality: 'ddfsfdf',
      partner_family_situation: 'célibataire',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: null,
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: 'matthieu.wandolski+1@gmail.com',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Hauts-de-France',
    },
  ],
  partenaire_supplementaire: 'non',
  capital_in_numbers: '1000',
  share_value: '1',
  depot_capital_chez: 'be_business_focus',
  date_depot_des_fonds: '2021-11-01',
  banking_corporation_name: '',
  adresse_agence_bancaire: '',
  ville_agence_bancaire: '',
  code_postal_agence_bancaire: '',
  cash_funds_numbers: '',
  apport_nature: 'non',
  supplies_funds_numbers: '0',
  apport_fonds_de_commerce: 'non',
  date_apport_fonds_de_commerce: null,
  valeur_apport_fonds_de_commerce: '',
  apport_titres_societe: 'non',
  date_apport_titres_societe: null,
  valeur_apport_titres_societe: '',
  raison_sociale_societe_apport_titres_societe: '',
  nombre_titres_apportes_apport_titres_societe: '',
  nombre_titres_total_apport_titres_societe: '',
  apport_nature_divers: 'non',
  date_apport_nature_divers: null,
  valeur_apport_nature_divers: '',
  description_biens_apport_nature_divers: '',
  apport_industrie: 'non',
  expertise_funds_numbers: '',
  cash_funds_onetime_payment: 'oui',
  payments_amount: '',
  director_type: "l_associe_de_l'EURL",
  director_title: 'M.',
  director_name: '',
  director_firstname: '',
  director_father_name: '',
  director_father_firstname: '',
  director_mother_name: '',
  director_mother_firstname: '',
  director_date_of_birth: null,
  director_place_of_birth: '',
  director_state_of_birth: '',
  director_country_of_birth: '',
  director_nationality: '',
  director_place_of_living: '',
  associe_gerant_multiassocie: [
    {
      id: 'f45c7a6d-5a3f-4c3c-a080-4f2ddc43bbac',
      partner_title: 'M.',
      partner_name: 'dfdfds',
      partner_firstname: 'fdssf',
      partner_father_name: 'fdsdf',
      partner_father_firstname: 'dsf',
      partner_mother_name: 'fsdfd',
      partner_mother_firstname: 'fds',
      partner_date_of_birth: '2021-11-03',
      partner_place_of_birth: 'Maurepas',
      partner_place_of_living: 'Rue Judaique',
      partner_city: 'Bordeaux',
      partner_zipcode: '33000',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'fddsf',
      partner_nationality: 'ddfsfdf',
      partner_family_situation: 'célibataire',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: null,
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: '',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: 'matthieu.wandolski+1@gmail.com',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Hauts-de-France',
    },
  ],
  duree_gerance_determinee: 'non',
  duree_de_gerance: '0',
  preexisting_contract: 'non',
  number_of_preexisting_contract: 0,
  contract1_date: null,
  contract1_type: '',
  contract1_amount: '',
  contract1_person_who_paid: '',
  contract2_date: null,
  contract2_type: '',
  contract2_amount: '',
  contract2_person_who_paid: '',
  presence_commissaire_aux_comptes: 'non',
  duree_exercice_commissaire_aux_comptes: '',
  nom_commissaire_aux_comptes: '',
  prenom_commissaire_aux_comptes: '',
  nom_cabinet_commissaire_aux_comptes: '',
  adresse_cabinet_commissaire_aux_comptes: '',
  nom_suppleant: '',
  prenom_suppleant: '',
  adresse_cabinet_suppleant: '',
  procuration: 'non',
  nom_cabinet_procuration: '',
  qualite_cabinet_procuration: '',
  adresse_cabinet_procuration: '',
  ville_cabinet_procuration: '',
  code_postal_cabinet_procuration: '',
  procu_bbf_dossier_constitution: 'non',
  procu_bbf_attestation_beneficiaires: 'non',
  procu_bbf_annonce_legale: 'non',
  procu_bbf_attestation_depot_fonds: 'non',
  procu_bbf_depot_greffe_dossier_constitution: 'non',
  kit: 'kit1sci',
};

export default sciData;
