import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Popover,
  IconButton,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField, RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import Paper from '../surface/Paper';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '30ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  radio: {},
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

// const SignupSchema = Yup.object().shape({

//   siege_social_chez: Yup.string().required('un choix est necessaire'),
//   proprietaire: Yup.string().required('un choix est necessaire'),
//   maison_individuelle: Yup.string().required('un choix est necessaire'),

//   prenom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   nom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   adresse_syndic: Yup.string().required('Required'),
//   prenom_bailleur: Yup.string().required('Required'),
//   nom_bailleur: Yup.string().required('Required'),
//   adresse_bailleur: Yup.string().required('Required'),
//   date_bail: Yup.date().required('Required'),
// });
// const { values, setFormValue } = useFormikContext();

const InfoSociete2 = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  // function validateUsername(value, values) {
  //   let error;
  //   if (
  //     values.siege_social_chez === 'chez_gerant' &&
  //     values.proprietaire === 'locataire'
  //   ) {
  //     if (value === 'test') {
  //       error = 'Nice try!';
  //     }
  //   }

  //   return error;
  // }

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue, fieldName) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue(fieldName, newValue.properties.label);
    } else {
      setFieldValue(fieldName, '');
      setAddressValue(newValue);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let popoverTextSiegeSocial = '';

  if (formValues.eurl_sasu === 'eurl') {
    popoverTextSiegeSocial = (
      <div className={classes.popOverDiv}>
        <Typography>
          Une EURL est tenue d’avoir un siège social. Il s’agira de l’unique
          adresse administrative de la société. Il peut s’agir du lieu
          d’exercice de l’activité ou de tout autre endroit. Toutefois, il doit
          s’agir d’un local qu’elle utilise.
          <br />
          <br /> L’EURL peut être : <br></br>&nbsp;&nbsp;• propriétaire de ses
          locaux ; <br></br> &nbsp;&nbsp;• locataire (elle doit être titulaire
          d’un bail commercial ou d’un bail de courte durée) ; <br></br>{' '}
          &nbsp;&nbsp;• occupante à titre gratuit (domiciliation chez le
          président) ; <br></br> &nbsp;&nbsp;• ocupante de locaux payants
          (domiciliation chez une entreprise spécialisée ou dans une pépinière).{' '}
          <br></br>
          <br></br>
          Il s’agit de celui de son établissement principal. Les établissements
          secondaires constituent des lieux d’exercice professionnel. Le siège
          social figure dans les statuts de l’EURL, il sera mentionné sur le
          Kbis et figurera sur tous les documents légaux émis par la société.
          <h4> Domicilier le siège social de l’EURL au domicile du Gérant </h4>
          La domiciliation chez le représentant légal de la société est
          autorisée pour le cas de l’EURL. Celle-ci ne peut cependant se faire
          qu’au domicile du gérant. Attention : dans certains cas, lorsque le
          gérant est locataire, les dispositions du contrat de location peuvent
          limiter dans le temps voire même interdire la domiciliation. Lorsque
          la domiciliation est temporaire, elle ne peut excéder une durée de
          cinq ans.
          <h4>
            Domicilier le siège social de l’EURL chez une entreprise de
            domiciliation{' '}
          </h4>
          L’EURL peut recourir aux services d’une entreprise de domiciliation
          disposant d’un agrément pour y établir son siège social. Un contrat de
          domiciliation sera signé et prévoira les conditions et les prestations
          de location.
          <h4>
            {' '}
            Domicilier le siège social de l’EURL au sein d’une pépinière
            d’entreprises – ruches – incubateurs…{' '}
          </h4>{' '}
          Les EURL peuvent développer leur activité au sein de structures qui
          mettent à leur disposition un éco-système, et de nombreuses
          infrastructures (secrétariat, accueil, salles de réunion, bureaux…).
          Cela permet aux sociétés récentes de bénéficier de toutes les
          facilités pour se développer en se concentrant sur leur business.
          Cette forme de domiciliation n’est autorisée que de manière
          temporaire.
          <h4> Changement de siège social d’une EURL </h4> À tout moment, une
          EURL peut être amenée à transférer son siège social. Cette opération
          nécessite de suivre la procédure prévue dans les statuts. Ensuite une
          procédure administrative de modification statutaire devra être
          réalisée auprès du RCS.
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sasu') {
    popoverTextSiegeSocial = (
      <div className={classes.popOverDiv}>
        <Typography>
          Comme toute société, la SASU est tenue d’avoir un siège social qui
          sera son adresse administrative et qui sera ou pas l’adresse
          d’exercice de son activité économique. La société peut être
          propriétaire, locataire (titulaire d’un bail commercial, d’un bail de
          courte durée ou occupante à titre gratuit. La domiciliation chez le
          Président est possible ainsi qu’auprès d’une entreprise de
          domiciliation ou dans une pépinière ou ruche d’entreprises. Une SASU
          ne peut fixer son siège social que dans un local qu’elle utilise. Le
          siège social d’une SASU est unique. Les adresses des établissements
          secondaires constituent des lieux d’exercice professionnel. Le siège
          social figure dans les statuts de la SAS, il sera mentionné sur le
          Kbis et figurera sur tous les documents légaux émis par la société.
          <h4>
            {' '}
            Domicilier le siège social de la SASU au domicile du Président{' '}
          </h4>
          La domiciliation chez le représentant légal de la société est
          autorisée pour la SASU. Celle-ci ne peut cependant se faire qu’au
          domicile du Président. Attention : dans certains cas, notamment
          lorsque le Président est locataire, des dispositions du contrat de
          location peuvent limiter dans le temps voire même interdire la
          domiciliation. Lorsque la domiciliation est temporaire, elle ne peut
          excéder une durée de cinq ans.
          <h4>
            Domicilier le siège social de la SASU chez une entreprise de
            domiciliation{' '}
          </h4>
          La SASU peut recourir aux services d’une entreprise de domiciliation
          disposant d’un agrément pour y établir son siège social. Un contrat de
          domiciliation sera signé et prévoira les conditions et les prestations
          de location.
          <h4>
            Domicilier le siège social de la SASU au sein d’une pépinière
            d’entreprises – ruches – incubateurs…{' '}
          </h4>
          Les SASU peuvent développer leur activité au sein de structures qui
          mettent à leur disposition un éco-système et de nombreuses
          infrastructures (secrétariat, accueil, salle de réunion, bureaux…).
          Elles bénéficient alors de toutes les facilités pour se développer en
          se concentrant sur leur business. Cette forme de domiciliation n’est
          autorisée que de façon temporaire, généralement le temps de lancer
          l’activité.
          <h4> Changement de siège social d’une SASU </h4>À tout moment, une
          SASU peut être amenée à transférer son siège social. Cette opération
          nécessite de suivre la procédure prévue dans les statuts. Ensuite une
          procédure administrative de modification statutaire devra être
          réalisée auprès du greffe du tribunal de commerce ou du INPI avec le
          guichet unique.
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sas') {
    popoverTextSiegeSocial = (
      <div className={classes.popOverDiv}>
        <Typography>
          Une SAS est tenue d’avoir un siège social. Le siège social sera
          l’adresse administrative de la SAS. Il peut s’agir du lieu d’exercice
          de l’activité ou de tout autre endroit et nécessairement un local
          qu’elle utilise. <br /> <br />
          La SAS peut en être : <br />
          &nbsp;&nbsp;• propriétaire de ses locaux ;<br /> &nbsp;&nbsp;•
          locataire (elle doit être titulaire d’un bail commercial ou d’un bail
          de courte durée)
          <br />
          &nbsp;&nbsp;• occupante à titre gratuit (domiciliation chez le
          Président)
          <br /> &nbsp;&nbsp;• ocupante de locaux payants (domiciliation chez
          une entreprise spécialisée ou dans une pépinière). <br />
          <br />
          Le siège social d’une SAS est unique. Il s’agit de celui de son
          établissement principal. Les lieux des établissements secondaires
          constituent des lieux d’exercice professionnel. Le siège social figure
          dans les statuts de la SAS, il sera mentionné sur le Kbis et figurera
          sur tous les documents légaux émis par la société.
          <h4>
            Domicilier le siège social de la SAS au domicile du Président{' '}
          </h4>
          En SAS, la domiciliation chez le représentant légal de la société est
          autorisée. Celle-ci ne peut cependant se faire qu’au domicile du
          Président. La domiciliation chez l’un ou l’autre des associés n’est
          pas autorisée. Attention : dans certains cas, lorsque le Président est
          locataire, des dispositions du contrat de location peuvent limiter
          dans le temps voire même interdire la domiciliation. Lorsque la
          domiciliation est temporaire, elle ne peut excéder une durée de cinq
          ans.
          <h4>
            Domicilier le siège social de la SAS chez une entreprise de
            domiciliation
          </h4>
          La SAS peut recourir aux services d’une entreprise de domiciliation
          disposant d’un agrément pour y établir son siège social. Un contrat de
          domiciliation sera signé et prévoira les conditions et les prestations
          de location.
          <h4>
            Domicilier le siège social de la SAS au sein d’une pépinière
            d’entreprises – ruches – incubateurs…{' '}
          </h4>
          Les SAS peuvent développer leur activité au sein de structures qui
          mettent à leur disposition un éco-système, et de nombreuses
          infrastructures (secrétariat, accueil, salle de réunion, bureaux…).
          Cela permet aux sociétés récentes de bénéficier de toutes les
          facilités pour se développer en se concentrant sur leur business.
          Cette forme de domiciliation n’est autorisée que de manière
          temporaire.
          <h4>Changement de siège social d’une SAS</h4>A tout moment, une SAS
          peut être amenée à transférer son siège social. Cette opération
          nécessite de suivre la procédure prévue dans les statuts. Ensuite une
          procédure administrative de modification statutaire devra être
          réalisée auprès du RCS.
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sarl') {
    popoverTextSiegeSocial = (
      <div className={classes.popOverDiv}>
        <Typography>
          Une SARL est tenue d’avoir un siège social. Le siège social sera
          l’adresse administrative de la SARL. Il peut s’agir du lieu d’exercice
          de l’activité ou de tout autre endroit et nécessairement un local
          qu’elle utilise. <br /> <br />
          La SARL peut en être : <br />
          &nbsp;&nbsp;• propriétaire de ses locaux ; <br />
          &nbsp;&nbsp;• locataire (elle doit être titulaire d’un bail commercial
          ou d’un bail de courte durée) <br />
          &nbsp;&nbsp;• occupante à titre gratuit (domiciliation chez le gérant){' '}
          <br />
          &nbsp;&nbsp;• ocupante de locaux payants (domiciliation chez une
          entreprise spécialisée ou dans une pépinière). <br />
          <br />
          Le siège social d’une SARL est unique. Il s’agit de celui de son
          établissement principal. Les lieux des établissements secondaires
          constituent des lieux d’exercice professionnel. Le siège social figure
          dans les statuts de la SARL, il sera mentionné sur le Kbis et figurera
          sur tous les documents légaux émis par la société.
          <h4>Domicilier le siège social de la SARL au domicile du gérant</h4>
          En SARL, la domiciliation chez le gérant de la société est autorisée.
          Celle-ci ne peut cependant se faire qu’au domicile du gérant. La
          domiciliation chez l’un ou l’autre des associés n’est pas autorisée.
          Attention : dans certains cas, lorsque le gérant est locataire, des
          dispositions du contrat de location peuvent limiter dans le temps
          voire même interdire la domiciliation. Lorsque la domiciliation est
          temporaire, elle ne peut excéder une durée de cinq ans.
          <h4>
            Domicilier le siège social de la SARL chez une entreprise de
            domiciliation
          </h4>
          La SARL peut recourir aux services d’une entreprise de domiciliation
          disposant d’un agrément pour y établir son siège social. Un contrat de
          domiciliation sera signé et prévoira les conditions et les prestations
          de location.
          <h4>
            Domicilier le siège social de la SARL au sein d’une pépinière
            d’entreprises – ruches – incubateurs…
          </h4>
          Les SARL peuvent développer leur activité au sein de structures qui
          mettent à leur disposition un éco-système, et de nombreuses
          infrastructures (secrétariat, accueil, salle de réunion, bureaux…).
          Cela permet aux sociétés récentes de bénéficier de toutes les
          facilités pour se développer en se concentrant sur leur business.
          Cette forme de domiciliation n’est autorisée que de manière
          temporaire.
          <h4>Changement de siège social d’une SARL</h4>A tout moment, une SARL
          peut être amenée à transférer son siège social. Cette opération
          nécessite de suivre la procédure prévue dans les statuts. Ensuite une
          procédure administrative de modification statutaire devra être
          réalisée auprès du RCS.
        </Typography>
      </div>
    );
  }

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          siege_social_chez: Yup.string().required('un choix est necessaire'),
          proprietaire: Yup.string().required('un choix est necessaire'),
          maison_individuelle: Yup.string().required('un choix est necessaire'),

          prenom_syndic: Yup.string().when(
            ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
            (siege_social_chez, proprietaire, maison_individuelle) => {
              if (siege_social_chez === 'chez_gerant') {
                if (proprietaire === 'Proprietaire') {
                  if (maison_individuelle === 'non') {
                    return Yup.string().required('requis');
                  }
                }
              }

              return Yup.string();
            }
          ),

          nom_syndic: Yup.string().when(
            ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
            (siege_social_chez, proprietaire, maison_individuelle) => {
              if (
                siege_social_chez === 'chez_gerant' &&
                proprietaire === 'Proprietaire' &&
                maison_individuelle === 'non'
              ) {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          adresse_syndic: Yup.string().when(
            ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
            (siege_social_chez, proprietaire, maison_individuelle) => {
              if (
                siege_social_chez === 'chez_gerant' &&
                proprietaire === 'Proprietaire' &&
                maison_individuelle === 'non'
              ) {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          prenom_bailleur: Yup.string().when(
            ['siege_social_chez', 'proprietaire'],
            (siege_social_chez, proprietaire) => {
              if (
                siege_social_chez === 'chez_gerant' &&
                proprietaire === 'Locataire'
              ) {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          nom_bailleur: Yup.string().when(
            ['siege_social_chez', 'proprietaire'],
            (siege_social_chez, proprietaire) => {
              if (
                siege_social_chez === 'chez_gerant' &&
                proprietaire === 'Locataire'
              ) {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          adresse_bailleur: Yup.string().when(
            ['siege_social_chez', 'proprietaire'],
            (siege_social_chez, proprietaire) => {
              if (
                siege_social_chez === 'chez_gerant' &&
                proprietaire === 'Locataire'
              ) {
                return Yup.string().required('Required');
              }
              return Yup.string();
            }
          ),

          // date_bail: Yup.date().when(
          //   ['siege_social_chez', 'proprietaire'],
          //   (siege_social_chez, proprietaire) => {
          //     if (
          //       siege_social_chez === 'chez_gerant' &&
          //       proprietaire === 'Locataire'
          //     ) {
          //       return Yup.date().required('Required');
          //     }
          //     return Yup.date();
          //   }
          // ),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        Automate.nextInfoSociete2VersInfoSociete3();
        setAutomateState(Automate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  {' '}
                  Le siège de l&apos;entreprise{' '}
                </Typography>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClick}
                >
                  <HelpOutlineOutlined />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  // marginThreshold="55"
                  onClose={handleClose}
                  // anchorOrigin={{
                  //   vertical: 'center',
                  //   horizontal: 'center',
                  // }}
                  anchorReference={'none'}
                  className={classes.popOver}
                >
                  {popoverTextSiegeSocial}
                </Popover>
              </Box>

              <Box className={classes.root} marginTop="20px" size="fullwidth">
                <Field component={RadioGroup} name="siege_social_chez">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Le siège social se situe :
                    </Typography>{' '}
                    <FormControlLabel
                      value="chez_gerant"
                      control={<Radio />}
                      label="Chez le dirigeant"
                    />
                    <FormControlLabel
                      value="local"
                      control={<Radio />}
                      label="Dans un local"
                    />
                    <FormControlLabel
                      value="en_domiciliation"
                      control={<Radio />}
                      label="En domiciliation"
                    />
                  </Box>
                </Field>
              </Box>

              {/* demande si liege social en locataire ou est proprietaire si il n'y apas de contrat de domiciliation  */}

              {values.siege_social_chez !== 'en_domiciliation' && (
                <Fragment>
                  <Box
                    className={classes.root}
                    marginTop="20px"
                    size="fullwidth"
                  >
                    <Field component={RadioGroup} name="proprietaire">
                      <Typography variant="subtitle1">
                        {' '}
                        Pour votre siège social êtes-vous ?
                      </Typography>{' '}
                      <FormControlLabel
                        value="Proprietaire"
                        control={<Radio />}
                        label="Propriétaire"
                      />
                      <FormControlLabel
                        value="Locataire"
                        control={<Radio />}
                        label="Locataire"
                      />
                    </Field>
                  </Box>
                </Fragment>
              )}

              {/* demande si siege dans une maison individuelle dans cas ou siege chez dirigeant qui est proprietaire  */}

              {values.siege_social_chez === 'chez_gerant' &&
                values.proprietaire === 'Proprietaire' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field component={RadioGroup} name="maison_individuelle">
                        <Typography variant="subtitle1">
                          {' '}
                          Le siège social est-il dans une maison individuelle ?
                        </Typography>{' '}
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="non"
                          control={<Radio />}
                          label="Non"
                        />
                      </Field>
                    </Box>
                  </Fragment>
                )}

              {/* demande les informations sur le syndic si siege pas dans une maison individuelle */}

              {values.siege_social_chez === 'chez_gerant' &&
                values.proprietaire === 'Proprietaire' &&
                values.maison_individuelle === 'non' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Box>
                        <Field
                          component={TextField}
                          type="string"
                          label="Prénom ou forme de la société du syndic de copropriété"
                          name="prenom_syndic"
                          className={classes.nameTextField}
                          autoComplete="off"
                        />
                      </Box>
                      <Box>
                        <Field
                          component={TextField}
                          type="string"
                          label="Nom ou raison sociale de la société du syndic de copropriété"
                          name="nom_syndic"
                          className={classes.nameTextField}
                          autoComplete="off"
                        />
                      </Box>
                      <Field
                        component={Autocomplete}
                        // type="string"
                        // label="Adresse du syndic de copropriété"
                        // placeholder="123 rue Bidon 57560 Paris"
                        name="adresse_syndic"
                        className={classes.nameTextField}
                        value={addressValue}
                        loading={loadingAddressList}
                        options={addressList}
                        filterOptions={(x) => x}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option && option.properties && option.properties.label
                        }
                        onInputChange={(event, newInputValue) =>
                          handleFetchAddress(newInputValue)
                        }
                        onChange={(event, newValue) =>
                          handleSelectAddress(
                            newValue,
                            setFieldValue,
                            'adresse_syndic'
                          )
                        }
                        renderInput={(params) => (
                          <MUITextField
                            {...params}
                            error={
                              touched.adresse_syndic && !!errors.adresse_syndic
                            }
                            helperText={
                              touched.adresse_syndic && errors.adresse_syndic
                            }
                            label="Adresse du syndic de copropriété"
                            placeholder={formValues.adresse_syndic}
                            size="small"
                          />
                        )}
                        renderOption={(option) => {
                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.properties.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                    </Box>
                  </Fragment>
                )}

              {/* demande les informations du bailleur si le siege est au domicile du dirigeant qui est locataire  */}

              {values.siege_social_chez === 'local' &&
                values.proprietaire === 'Locataire' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field
                        component={TextField}
                        type="string"
                        label="Prénom ou forme de la société du bailleur "
                        name="prenom_bailleur"
                        className={classes.nameTextField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Nom ou raison sociale de la société du bailleur"
                        name="nom_bailleur"
                        className={classes.nameTextField}
                        autoComplete="off"
                      />
                      <Field
                        component={Autocomplete}
                        // type="string"
                        // label="Adresse du bailleur"
                        // placeholder="123 rue Bidon 57560 Paris"
                        name="adresse_bailleur"
                        className={classes.nameTextField}
                        value={addressValue}
                        loading={loadingAddressList}
                        options={addressList}
                        filterOptions={(x) => x}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option && option.properties && option.properties.label
                        }
                        onInputChange={(event, newInputValue) =>
                          handleFetchAddress(newInputValue)
                        }
                        onChange={(event, newValue) =>
                          handleSelectAddress(
                            newValue,
                            setFieldValue,
                            'adresse_bailleur'
                          )
                        }
                        renderInput={(params) => (
                          <MUITextField
                            {...params}
                            error={
                              touched.adresse_bailleur &&
                              !!errors.adresse_bailleur
                            }
                            helperText={
                              touched.adresse_bailleur &&
                              errors.adresse_bailleur
                            }
                            label="Adresse du bailleur"
                            placeholder={formValues.adresse_bailleur}
                            size="small"
                          />
                        )}
                        renderOption={(option) => {
                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.properties.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date du bail"
                        placeholder="01/01/1990"
                        className={classes.birthdayField}
                        format="dd/MM/yyyy"
                        name="date_bail"
                        autoComplete="off"
                      />
                    </Box>
                  </Fragment>
                )}

              {values.siege_social_chez === 'chez_gerant' &&
                values.proprietaire === 'Locataire' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field
                        component={TextField}
                        type="string"
                        label="Prénom ou forme de la société du bailleur "
                        name="prenom_bailleur"
                        className={classes.nameTextField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Nom ou raison sociale de la société du bailleur"
                        name="nom_bailleur"
                        className={classes.nameTextField}
                        autoComplete="off"
                      />
                      <Field
                        component={Autocomplete}
                        // type="string"
                        // label="Adresse du bailleur"
                        // placeholder="123 rue Bidon 57560 Paris"
                        name="adresse_bailleur"
                        className={classes.nameTextField}
                        value={addressValue}
                        loading={loadingAddressList}
                        options={addressList}
                        filterOptions={(x) => x}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option && option.properties && option.properties.label
                        }
                        onInputChange={(event, newInputValue) =>
                          handleFetchAddress(newInputValue)
                        }
                        onChange={(event, newValue) =>
                          handleSelectAddress(
                            newValue,
                            setFieldValue,
                            'adresse_bailleur'
                          )
                        }
                        renderInput={(params) => (
                          <MUITextField
                            {...params}
                            error={
                              touched.adresse_bailleur &&
                              !!errors.adresse_bailleur
                            }
                            helperText={
                              touched.adresse_bailleur &&
                              errors.adresse_bailleur
                            }
                            label="Adresse du bailleur"
                            placeholder={formValues.adresse_bailleur}
                            size="small"
                          />
                        )}
                        renderOption={(option) => {
                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.properties.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date du bail"
                        placeholder="01/01/1990"
                        className={classes.birthdayField}
                        format="dd/MM/yyyy"
                        name="date_bail"
                        autoComplete="off"
                      />
                    </Box>
                  </Fragment>
                )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoSociete2.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoSociete2;
