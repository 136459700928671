import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useSelector } from 'react-redux';
import {
  KIT_3_SCI,
  KIT_3_SOC,
  KIT_4_SCI,
  KIT_4_SOC,
  KIT_BASIQUE_SCI,
  KIT_BASIQUE_SOC,
} from 'assets/data/kitConstants';

const steps = [
  {
    kits: [KIT_4_SOC, KIT_4_SCI],
    steps: [
      'Valider les documents générés',
      'Déposer les documents demandés',
      'Virer votre apport chez le notaire partenaire de BBF',
      'Signer les documents',
      "C'est fini, Récupérer votre Kbis",
    ],
  },
  {
    kits: [KIT_3_SOC, KIT_3_SCI],
    steps: [
      'Valider les documents générés',
      'Déposer les documents demandés',
      'Signer les documents',
      "C'est fini, Récupérer votre Kbis",
    ],
  },
  {
    kits: [KIT_BASIQUE_SOC, KIT_BASIQUE_SCI],
    steps: [],
  },
];

const horizontalStepper = () => {
  const {
    company: {
      kit,
      stateOfClientFolder: { stepper },
    },
  } = useSelector((state) => state.monCompte);

  const getSteps = () => {
    let stepsToReturn = null;
    steps.forEach((step) => {
      if (step.kits.includes(kit)) {
        stepsToReturn = step.steps;
      }
    });
    return stepsToReturn;
  };

  return (
    <Stepper activeStep={stepper} alternativeLabel style={{ margin: '0 auto' }}>
      {getSteps().map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default horizontalStepper;
