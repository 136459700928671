import { NO } from 'constants/usersContants';

const prepareData = (values) => {
  const form = { ...values };

  if (form.preexisting_contract === NO) {
    form.number_of_preexisting_contract = 0;
  }
  form.number_of_preexisting_contract = parseInt(
    form.number_of_preexisting_contract,
    10
  );
  return form;
};

export default prepareData;
