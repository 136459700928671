import { YES } from 'constants/usersContants';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object().shape({
    partner_liberation_partielle: Yup.string().required('Champs requis'),
    partner_montant_liberation_partielle: Yup.number().when(
      'partner_liberation_partielle',
      {
        is: (partner_liberation_partielle) =>
          partner_liberation_partielle === YES,
        then: Yup.number().required('REQUIRED'),
      }
    ),
  });
};

//  eslint-disable-next-line
export { validationSchema };
