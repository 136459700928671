import React, { useState, createRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import { Refresh, Check } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import store from 'store/monCompteStore';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import queryStringHelper from 'utils/queryStringHelper';

import { fetchUserCompanies, selectCompany } from 'actions/loggedUserActions';

const MyCompanies = () => {
  const tableRef = createRef();
  const persistor = persistStore(store);

  const [pageSize, setPageSize] = useState(10);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const renderRefreshIcon = () => <Refresh />;

  useEffect(async () => {
    const result = await dispatch(fetchUserCompanies());
    if (result && result.docs && result.docs.length === 1) {
      dispatch(selectCompany(result.docs[0]._id));
      history.push('/compte-creation');
    }
  }, []);

  const renderActionIcons = (id) => (
    <Check
      disabled={refresh}
      onClick={() => {
        dispatch(selectCompany(id));
        history.push({
          pathname: `/compte-creation`,
        });
      }}
    />
  );

  const formatData = (data) =>
    data.map(({ _id: id, name, address }) => ({
      id,
      name,
      address,
    }));

  const columns = [
    {
      title: 'id',
      field: 'id',
      sorting: false,
    },
    {
      title: 'Name',
      field: 'name',
      sorting: false,
    },
    {
      title: 'Address',
      field: 'address',
      sorting: false,
    },
    {
      title: ' ',
      field: 'id',
      sorting: false,
      render: ({ id }) => renderActionIcons(id),
    },
  ];

  return (
    <PersistGate loading={null} persistor={persistor}>
      <MaterialTable
        title="My Companies"
        tableRef={tableRef}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            dispatch(fetchUserCompanies(queryStringHelper(query)))
              .then((res) => {
                resolve({
                  data: formatData(res.docs),
                  page: res.page - 1,
                  totalCount: res.totalDocs,
                });
              })
              .catch((err) => {
                reject(err);
              });
          })
        }
        onChangeRowsPerPage={(ps) => {
          setPageSize(ps);
        }}
        options={{
          filtering: false,
          pageSize,
          search: true,
          showTitle: false,
        }}
        actions={[
          {
            icon: () => renderRefreshIcon(),
            isFreeAction: true,
            onClick: () => setRefresh(true),
          },
        ]}
        onOrderChange={() => {}}
      />
    </PersistGate>
  );
};

export default MyCompanies;
