import React from 'react';
import store from 'store/monCompteStore';
import MonCompte from 'containers/MonCompte';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

const MonCompteView = () => {
  const persistor = persistStore(store);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <MonCompte />
    </PersistGate>
  );
};

MonCompte.propTypes = {};
export default MonCompteView;
