import { Popover } from '@material-ui/core';
import { helpContents } from 'constants/helpContents';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { closeHelpPopup } from 'reducers/appReducer';

function HelpPopup({ formulaireComplet }) {
  const { eurl_sasu } = formulaireComplet;
  const { showHelpPopup, helpName } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const id = showHelpPopup ? 'simple-popover' : undefined;

  // TO DO: get content off help icon by helpName and eurl_sasu
  const getHelpContent = () => {
    const name = `${helpName}-${eurl_sasu}`;
    const helpMessage = helpContents.filter((content) =>
      content.name.includes(name)
    )[0];
    if (helpMessage) {
      return helpMessage.content;
    }
    return `Nous restons à votre disposition par tchat ou par mail à cette adresse : <a href='mailto:juridique@bebusinessfocus.com'>juridique@bebusinessfocus.com</a>.`;
  };

  return (
    <>
      {helpName && (
        <Popover
          id={id}
          open={showHelpPopup}
          anchorReference={'none'}
          onClose={() => dispatch(closeHelpPopup())}
          style={{
            position: 'absolute',
            padding: '1%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: getHelpContent() }}
            style={{ maxWidth: '450px', padding: '5%', textAlign: 'justify' }}
          />
        </Popover>
      )}
    </>
  );
}

export default HelpPopup;
