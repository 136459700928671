import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import PreviewScreen from 'components/monCompte/previewScreen';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PreviewFileDialog from 'components/monCompte/previewFileDialog';

const useStyles = makeStyles(() => ({
  pageHeading: {
    textAlign: 'center',
  },
}));

const KitPreview = () => {
  const classes = useStyles();
  const { company } = useSelector((state) => state.monCompte);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [openingDocument, setOpeningDocument] = useState(null);

  return (
    <>
      <PreviewFileDialog
        openingDocument={openingDocument}
        dialogVisible={dialogVisible}
        setDialogVisible={setDialogVisible}
        setOpeningDocument={setOpeningDocument}
        isViewInBrowser
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            className={classes.pageHeading}
            component="h1"
            variant="h4"
          >
            Mon Compte
          </Typography>
        </Grid>
        <PreviewScreen
          kit={company.kit}
          companyId={company.companyId}
          documents={company.documents}
          setDialogVisible={setDialogVisible}
          setOpeningDocument={setOpeningDocument}
        />
      </Grid>
    </>
  );
};

export default KitPreview;
