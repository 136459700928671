import React from 'react';
import CreateCompanyContainer from 'containers/V2/CreateCompanyContainer';
import { FormProvider } from '../contexts/partners.context';

const CreationEntrepriseV2 = () => {
  return (
    <FormProvider>
      <CreateCompanyContainer />
    </FormProvider>
  );
};

export default CreationEntrepriseV2;
