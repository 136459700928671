import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Box, Typography, FormControlLabel, Radio } from '@material-ui/core';

import { TextField, RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
// import KeyboardDatePicker from 'components/KeyboardDatePicker';

// import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import Paper from '../surface/Paper';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '50ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '80ch',
  },
  radio: {},
}));

// const SignupSchema = Yup.object().shape({

//   siege_social_chez: Yup.string().required('un choix est necessaire'),
//   proprietaire: Yup.string().required('un choix est necessaire'),
//   maison_individuelle: Yup.string().required('un choix est necessaire'),

//   prenom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   nom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   adresse_syndic: Yup.string().required('Required'),
//   prenom_bailleur: Yup.string().required('Required'),
//   nom_bailleur: Yup.string().required('Required'),
//   adresse_bailleur: Yup.string().required('Required'),
//   date_bail: Yup.date().required('Required'),
// });
// const { values, setFormValue } = useFormikContext();

const OrganeDirection = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     siege_social_chez: Yup.string().required('un choix est necessaire'),
      //     proprietaire: Yup.string().required('un choix est necessaire'),
      //     maison_individuelle: Yup.string().required('un choix est necessaire'),

      //     prenom_syndic: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
      //       (siege_social_chez, proprietaire, maison_individuelle) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Proprietaire' &&
      //           maison_individuelle === 'non'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     nom_syndic: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
      //       (siege_social_chez, proprietaire, maison_individuelle) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Proprietaire' &&
      //           maison_individuelle === 'non'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     adresse_syndic: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
      //       (siege_social_chez, proprietaire, maison_individuelle) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Proprietaire' &&
      //           maison_individuelle === 'non'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     prenom_bailleur: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     nom_bailleur: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     adresse_bailleur: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     date_bail: Yup.date().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.date().required('Required');
      //         }
      //       }
      //     ),
      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  {' '}
                  Organe de direction de l&apos;association{' '}
                </Typography>
                <Typography variant="h6">
                  {' '}
                  Nom de l&apos;organe de direction
                </Typography>
              </Box>

              <Box className={classes.root}>
                <Field component={RadioGroup} name="nom_organe_direction">
                  <Box>
                    {/* <Typography variant="subtitle1">
                      {' '}
                      Nom de l&apos;organe de direction
                    </Typography>{' '} */}
                    <FormControlLabel
                      value="conseil_administration"
                      control={<Radio />}
                      label="Le conseil d'administration"
                    />
                    <FormControlLabel
                      value="autre"
                      control={<Radio />}
                      label="Autre dénomination"
                    />
                  </Box>
                </Field>
              </Box>

              {values.nom_organe_direction === 'autre' && (
                <Box className={classes.root}>
                  <Box alignSelf="center">
                    <Field
                      component={TextField}
                      type="string"
                      label="Nom de l'organe de direction"
                      name="autre_nom_organe_direction"
                      className={classes.nameTextField}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              )}

              <Box className={classes.root}>
                <Box>
                  <Field
                    component={TextField}
                    type="string"
                    label="Nombre de membres du conseil d'administration :"
                    name="nombre_membres_conseil_administration"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Field
                    component={TextField}
                    type="string"
                    label="Durée du mandat en années :"
                    name="duree_mandat_conseil_administration"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />
                </Box>
              </Box>
              <Box className={classes.root}>
                <Box alignSelf="center">
                  <Field
                    component={TextField}
                    type="string"
                    label="Nombre de mandats successifs possibles :"
                    name="nombres_mandat_successifs_conseil_administration"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />
                </Box>
              </Box>

              <Box className={classes.root}>
                <Field
                  component={RadioGroup}
                  name="nomination_premiers_membres_conseil_administration"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Les premiers membres du conseil d&apos;administration sont
                      :
                    </Typography>{' '}
                    <FormControlLabel
                      value="statuts"
                      control={<Radio />}
                      label="Fixés par les statuts"
                    />
                    <FormControlLabel
                      value="premiere_assemblee_generale"
                      control={<Radio />}
                      label="Nommés par la première assemblée générale"
                    />
                  </Box>
                </Field>
              </Box>

              <Box className={classes.root}>
                <Field component={RadioGroup} name="bureau">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Un bureau est-il mis en place ?
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>

              {values.bureau === 'oui' && (
                <Box className={classes.root}>
                  <Box>
                    <Field
                      component={TextField}
                      type="string"
                      label="Nombre de mandats successifs des membres du bureau"
                      name="nombre_mandat_successif_membres_bureau"
                      className={classes.nameTextField}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Field
                      component={TextField}
                      type="string"
                      label="Durée du mandat des membres du bureau en années :"
                      name="duree_mandat_membres_bureau"
                      className={classes.nameTextField}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

OrganeDirection.propTypes = {
  nextForm: PropTypes.func,
};
export default OrganeDirection;
