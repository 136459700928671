import { TIERS } from 'constants/usersContants';
import React from 'react';
import BusinessIcon from '@material-ui/icons/Business';
import DGCard from '../commons/DGCard';
import GenericCard from '../commons/genericCard';
import PartnerCard from '../commons/partnerCard';
import useStyles from '../style';

function Recap({
  goToPartnerInfo,
  goToStart,
  goToAdministrativeInfo,
  formulaireComplet,
}) {
  const classes = useStyles();
  const { partners, dg, director_type, company_name } = formulaireComplet;

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>récapitulatif</h1>
      <div className={classes.root}>
        <h2 className={classes.subTitle}>Informations de base</h2>
        <GenericCard
          icon={<BusinessIcon />}
          title={company_name}
          subTitle={`Type de société, nom de la société, adresse du siège et dates de la société.`}
          handleClick={goToStart}
        />
        <h2 className={classes.subTitle}>Associé(e)s</h2>
        {partners.map((partner) => (
          <PartnerCard
            key={partner.id}
            partner={partner}
            handleClick={() => goToPartnerInfo(partner.id)}
            canEdit={true}
          />
        ))}

        {TIERS === director_type && (
          <>
            {dg &&
              dg.map((directorGeneral, idx) => (
                <DGCard
                  key={idx}
                  dg={directorGeneral}
                  // handleClick={goToDGInfo}
                />
              ))}
          </>
        )}
        <h2 className={classes.subTitle}>Informations administratives</h2>
        <GenericCard
          icon={<BusinessIcon />}
          title={company_name}
          subTitle={`Siège social, gérant(e)s/président(e)s, capital, actes juridiques, CAC...`}
          handleClick={goToAdministrativeInfo}
        />
      </div>
    </div>
  );
}

export default Recap;
