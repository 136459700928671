import { FormControlLabel, Radio } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';

function CustomRadio({ classes, title, options, value, name }) {
  const { errors } = useFormikContext();
  return (
    <>
      <h2 className={classes.radioWrapperTitle}>{title}</h2>
      {options.map((option) => (
        <FormControlLabel
          className={`
            ${
              value === option
                ? `${classes.radioLabel} ${classes.selected}`
                : classes.radioLabel
            }
              ${errors[name] ? classes.radioError : ''}
              `}
          key={option}
          value={option}
          control={<Radio className={classes.radio} />}
          label={option.toUpperCase()}
        />
      ))}
    </>
  );
}

export default CustomRadio;
