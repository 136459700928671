import React, { useState } from 'react';
import {
  TYPE_OF_COMPANY,
  COMPANY_DATE,
  BASIC_INFO_OF_COMPANY,
  HEAD_OFFICE_INFO,
  USER_INFO,
  COMPANY_CONTRIBUTION,
  PARTNER_ADD_AND_REVIEW,
  PARTNER_CONTRIBUTION,
  RELEASE_OF_CASH_BY_PARTNER,
  SELECT_CEO,
  SELECT_DG,
  CAPITAL_AND_FOUND_DEPOSIT,
  LEGAL_ACTS,
  RECAP,
  CAC,
  SELECT_REGIME_VAT,
} from 'constants/pages';
import {
  ADD_IN_HISTORY,
  EDIT_COMPANY,
  GO_BACK,
  SET_CURRENT_FORM,
  SET_PARTNER_ID,
} from 'constants/actions';
import { canAddInHistory, getNextForm } from 'helpers/automateHelper';
import CompanyTypeContainer from 'components/entreprise/V2/typeOfCompany/CompanyTypeContainer';
import CompanyDateContainer from 'components/entreprise/V2/companyDate/CompanyDateContainer';
import HeadOfficeInfoContainer from 'components/entreprise/V2/headOfficeInfo/HeadOfficeInfoContainer';
import UserInfoContainer from 'components/entreprise/V2/userInfo/UserInfoContainer';
import BasicInfoCompanyContainer from 'components/entreprise/V2/basicInfoOfCompany/BasicInfoCompanyContainer';
import { makeStyles } from '@material-ui/core';
import PartnerAddAndReviewContainer from 'components/entreprise/V2/partnerAddAndReview/PartnerAddAndReviewContainer';
import PartnerContributionContainer from 'components/entreprise/V2/partnerContribution/PartnerContributionContainer';
import PartnerReleaseOfCashContainer from 'components/entreprise/V2/partnerReleaseOfCash/PartnerContributionContainer';
import SelectCEOContainer from 'components/entreprise/V2/selectCEO/SelectCEOContainer';
import SelectDGContainer from 'components/entreprise/V2/selectDG/SelectDGContainer';
import CapitalAndFoundDepositContainer from 'components/entreprise/V2/capitalAndFoundDeposit/CapitalAndFoundDepositContainer';
import LegalActsContainer from 'components/entreprise/V2/legalActs/LegalActsContainer';
import RecapContainer from 'components/entreprise/V2/recap/RecapContainer';
import CACContainer from 'components/entreprise/V2/CAC/CACContainer';
import CompanyContributionContainer from 'components/entreprise/V2/companyContribution/companyContributionContainer';
import HelpPopup from 'components/commons/HelpPopup';
import { SCI } from 'constants/companies';
import SelectRegimeVATContainer from 'components/entreprise/V2/selectRegimeVAT/SelectRegimeVATContainer';

const useStyles = makeStyles((theme) => ({
  animatedItem: {
    animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemBack: {
    animation: `$goBack 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemApearAfterGoBack: {
    animation: `$myEffectEnterAfterBack 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemExiting: {
    animation: `$myEffectExit 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
      transform: 'translateX(30%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes goBack': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(30%)',
    },
  },
  '@keyframes myEffectEnterAfterBack': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-30%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(-30%)',
    },
  },
}));
const CreateCompanySCIContainer = ({ formulaireComplet, dispatch }) => {
  const classes = useStyles();
  const form = { ...formulaireComplet };
  const { currentForm, eurl_sasu } = form;
  const [animation, setAnimation] = useState(classes.animatedItem);

  const goPreviewForm = () => {
    setAnimation(classes.animatedItemBack);
    setTimeout(() => {
      dispatch({ type: GO_BACK });
      setAnimation(classes.animatedItemApearAfterGoBack);
    }, 250);
  };

  const goToStart = () => {
    setAnimation(classes.animatedItemExiting);
    setTimeout(() => {
      dispatch({
        type: SET_CURRENT_FORM,
        payload: eurl_sasu === SCI ? BASIC_INFO_OF_COMPANY : TYPE_OF_COMPANY,
      });
      setAnimation(classes.animatedItem);
    }, 250);
  };

  const goToAdministrativeInfo = () => {
    setAnimation(classes.animatedItemExiting);
    setTimeout(() => {
      dispatch({ type: SET_CURRENT_FORM, payload: SELECT_CEO });
      setAnimation(classes.animatedItem);
    }, 250);
  };

  const goToPartnerInfo = (partnerId) => {
    if (partnerId !== null) {
      dispatch({ type: SET_PARTNER_ID, payload: partnerId });
    }
    setAnimation(classes.animatedItemExiting);
    setTimeout(() => {
      dispatch({ type: SET_CURRENT_FORM, payload: USER_INFO });
      setAnimation(classes.animatedItem);
    }, 250);
  };

  const saveAndNext = (data) => {
    dispatch({
      type: EDIT_COMPANY,
      payload: data,
    });
    if (canAddInHistory(data)) {
      dispatch({ type: ADD_IN_HISTORY, payload: currentForm });
    }
    setAnimation(classes.animatedItemExiting);
    setTimeout(() => {
      dispatch({ type: SET_CURRENT_FORM, payload: getNextForm(data) });
      setAnimation(classes.animatedItem);
    }, 250);
  };

  const renderForm = () => {
    switch (currentForm) {
      case TYPE_OF_COMPANY:
        return (
          <CompanyTypeContainer
            saveAndNext={saveAndNext}
            goBack={goPreviewForm}
            goToStart={goToStart}
            formulaireComplet={formulaireComplet}
            dispatch={dispatch}
          />
        );
      case BASIC_INFO_OF_COMPANY:
        return (
          <BasicInfoCompanyContainer
            saveAndNext={saveAndNext}
            goBack={goPreviewForm}
            formulaireComplet={formulaireComplet}
            dispatch={dispatch}
          />
        );
      case COMPANY_DATE:
        return (
          <CompanyDateContainer
            saveAndNext={saveAndNext}
            goBack={goPreviewForm}
            formulaireComplet={formulaireComplet}
          />
        );
      case HEAD_OFFICE_INFO:
        return (
          <HeadOfficeInfoContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case USER_INFO:
        return (
          <UserInfoContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case PARTNER_CONTRIBUTION:
        return (
          <PartnerContributionContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case COMPANY_CONTRIBUTION:
        return (
          <CompanyContributionContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case RELEASE_OF_CASH_BY_PARTNER:
        return (
          <PartnerReleaseOfCashContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case PARTNER_ADD_AND_REVIEW:
        return (
          <PartnerAddAndReviewContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            goToPartnerInfo={goToPartnerInfo}
            formulaireComplet={formulaireComplet}
            dispatch={dispatch}
          />
        );
      case SELECT_CEO:
        return (
          <SelectCEOContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case SELECT_DG:
        return (
          <SelectDGContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case CAPITAL_AND_FOUND_DEPOSIT:
        return (
          <CapitalAndFoundDepositContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
            dispatch={dispatch}
          />
        );
      case LEGAL_ACTS:
        return (
          <LegalActsContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case CAC:
        return (
          <CACContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case SELECT_REGIME_VAT:
        return (
          <SelectRegimeVATContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            formulaireComplet={formulaireComplet}
          />
        );
      case RECAP:
        return (
          <RecapContainer
            goBack={goPreviewForm}
            saveAndNext={saveAndNext}
            goToPartnerInfo={goToPartnerInfo}
            goToStart={goToStart}
            goToAdministrativeInfo={goToAdministrativeInfo}
            formulaireComplet={formulaireComplet}
            dispatch={dispatch}
          />
        );

      default:
        return (
          <CompanyTypeContainer
            saveAndNext={saveAndNext}
            goBack={goPreviewForm}
            formulaireComplet={formulaireComplet}
            dispatch={dispatch}
          />
        );
    }
  };

  return (
    <div className="text-slate-500">
      {/* <Stepper /> */}
      <div className={animation}>{renderForm()}</div>
      <HelpPopup dispatch={dispatch} formulaireComplet={formulaireComplet} />
    </div>
  );
};

export default CreateCompanySCIContainer;
