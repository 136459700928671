import React, { Fragment, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Field, Formik, Form } from 'formik';
import {
  Popover,
  IconButton,
  FormControlLabel,
  Radio,
  // MenuItem,
  Box,
  Typography,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { RadioGroup } from 'formik-material-ui';

// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';

import { makeStyles } from '@material-ui/core/styles';

import theme from 'assets/jss/theme';

import Paper from 'components/surface/Paper';
import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { ADD_PARTNER, EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

const SignupSchema = Yup.object().shape({
  personne_physique_morale: Yup.string().required('un choix est necessaire'),
});

const ChoixNombrePartenairesOuTypePartenaire = ({
  partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  useEffect(() => {
    setActiveStep(1);
  }, []);

  let associeOuActionnaireLabel = 'Associé'; // ca c'est ce qu'on affiche de base      let partnerNameLabel= "Nom de l'associé";
  // let eurlOuSarlValue = 'eurl';
  // let eurlOuSarlLabel = 'EURL';

  // let sasuouSasValue = 'sasu';
  // let sasuouSasLabel = 'SASU';

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    associeOuActionnaireLabel = 'Actionnaire';
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let popoverTextTypeAssocie = '';

  if (formValues.eurl_sasu === 'eurl') {
    popoverTextTypeAssocie = (
      <div className={classes.popOverDiv}>
        <Typography>
          Bien qu’il existe certaines règles, toute personne peut devenir
          associé unique d’une entreprise unipersonnelle à responsabilité
          limitée (EURL).
          <h4>
            L’associé unique d’une EURL peut être une personne physique ou
            morale
          </h4>
          Toute personne au sens juridique du terme peut avoir la qualité
          d’associé unique d’EURL. Il peut donc s’agir aussi bien d’une personne
          physique, que d’une personne morale, qu’il s’agisse d’une autre
          société ou d’une association.
          <h4>
            L’associé unique d’EURL peut avoir la nationalité françaiseou
            étrangère
          </h4>
          Tout personne de nationalité française ou étrangère peut devenir
          associée d’une EURL. Pour les personnes ressortissantes d’un pays
          membre de l’Union Européenne, elles peuvent être associées d’une EURL
          comme toute personne de nationalité française.
          <h4>
            Une personne peut être associée unique de plusieurs EURL différentes
          </h4>
          Les personnes physiques comme les personnes morales peuvent être
          associées d’une ou de plusieurs EURL sans limitation. Cela permet
          souvent de cloisonner des activités dans des entités juridiques
          spécifiques et autonomes.
          <h4>
            Aucune incapacité particulière n’interdit de devenir associé d’une
            EURL
          </h4>
          Aucune incompatibilité ou interdiction n’interdit d’être associé
          unique d’une EURL, dès lors que l’associé n’est pas le gérant. Dans ce
          cadre, peuvent donc être associés uniques d’EURL : <br />
          &nbsp;&nbsp;• les majeurs capables ou incapables (sous curatelle,
          tutelle ou sauvegarde de justice) ; <br />
          &nbsp;&nbsp;• les mineurs émancipés (assimilés à des majeurs) ; <br />
          &nbsp;&nbsp;• les mineurs non émancipés dès lors qu’ils agissent au
          travers d’un représentant légal, et avec des autorisations préalables
          pour la souscription de contrats. <br />
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sasu') {
    popoverTextTypeAssocie = (
      <div className={classes.popOverDiv}>
        <Typography>
          Même si quelques règles doivent être respectées, devenir associé
          unique d’une société par actions simplifiée unipersonnelle (SASU) ne
          requiert, en principe, aucune capacité particulière ni condition de
          nationalité.
          <h4>L’associé d’une SASU est une personne physique ou morale</h4>
          Toute personne physique ou morales peut être à l’origine de la
          création et devenir associée unique d’une SASU.
          <h4>L’associé d’une SASU n’est soumis à aucune limite de cumul</h4>
          Vous pouvez être associé d’un nombre illimité de SASU. Aucune règle ne
          vient limiter le nombre de SASU dans lesquelles vous pouvez être
          associé.
          <h4>L’associé d’une SASU est Français, Européen ou Étranger</h4>
          Les associés de SASU peuvent être : <br />
          &nbsp;&nbsp;• toutes les personnes de nationalité Française ; <br />
          &nbsp;&nbsp;• toutes les personnes ressortisantes de l’Union
          Européenne ; <br />
          &nbsp;&nbsp;• toutes les autres personnes titulaires d’une carte de
          résident ou d’une carte de séjour temporaire. <br />
          <h4> La capacité de l’associé et du dirigeant d’une SASU</h4>
          Ni la loi ni les règlements n’imposent d’être titulaire d’une capacité
          pour devenir associé de SASU. Ce n’est pas le cas du dirigeant qui ne
          doit pas être frappé d’une interdiction ou d’une incompatibilité à
          exercer. Il n’existe pas d’interdictions ou d’incompatibilités
          empêchant d’être associé d’une SASU. La qualité d’associé peut donc
          être reconnue à un majeur déclaré « incapable » c’est-à-dire placé
          sous tutelle, sous curatelle ou sous sauvegarde de justice ou même à
          un mineur non émancipé dès lors que leurs engagements sont pris par
          leur représentant légal et que la procédure d’autorisation est
          respectée.
          <h4>
            La qualité d’associé ne peut être reconnue au conjoint de l’associé
            de la SASU{' '}
          </h4>
          Le conjoint de l’associé apporteur ne peut pas revendiquer la qualité
          d’associé pour la moitié des actions souscrites au moyen de biens
          communs et même si les biens apportés sont des biens communs. Seul
          l’apporteur se verra accorder la qualité d’associé. Il n’a pas à en
          informer son conjoint ni même à obtenir son autorisation, sauf si leur
          contrat de mariage le prévoit. Néanmoins, même si un seul des deux
          époux a la qualité d’associé, les actions de la SASU souscrites au
          moyen de biens communs tombent dans la communauté et devront être
          partagées entre les deux époux en cas de liquidation de la communauté
          (séparation, décès).
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sas') {
    popoverTextTypeAssocie = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>L’associé d’une SAS est une personne physique ou morale</h4>
          Toutes les personnes physiques ou les personnes morales (dont les
          associations) peuvent devenir associées d’une société par actions
          simplifiée.
          <h4>L’associé d’une SAS est français, européen ou étranger</h4>
          Les associés de SAS peuvent être : <br />
          &nbsp;&nbsp;• toutes les personnes de nationalité Française ; <br />
          &nbsp;&nbsp;• toutes les personnes ressortisantes de l’Union
          Européenne ; <br />
          &nbsp;&nbsp;• toutes les autres personnes titulaires d’une carte de
          résident ou d’une carte de séjour temporaire.
          <h4>L’associé d’une SAS n’est soumis à aucune limite de cumul</h4>
          Aucune règle limitative ne fixe de limite en la matière. Vous pouvez
          donc librement être associé sans limite au sein de multiples sociétés.
          <h4>L’associé d’une SAS ne peut être frappé d’une interdiction</h4>
          Ni la loi ni les règlements n’imposent d’être titulaire d’une capacité
          pour devenir associé de SAS, ce qui n’est pas le cas pour en être le
          dirigeant. Il n’existe pas d’interdictions ou d’incompatibilités
          empêchant d’être associé d’une SAS. La qualité d’associé peut donc
          être reconnue à un majeur déclaré « incapable » c’est-à-dire placé
          sous tutelle, sous curatelle ou sous sauvegarde de justice ou à un
          mineur non émancipé dès lors que leurs engagements sont pris par leur
          représentant légal et que la procédure d’autorisation est respectée.
          <h4>
            La qualité d’associé ne peut être reconnue au conjoint de l’associé
            de la SAS
          </h4>
          Le conjoint de l’apporteur ne peut pas revendiquer la qualité
          d’associé pour la moitié des actions souscrites, même si les biens
          apportés sont des biens communs. Seul l’apporteur se verra accorder la
          qualité d’associé. Il n’a pas à en informer son conjoint ni même à
          obtenir son autorisation, sauf si leur contrat de mariage le prévoit.
          Néanmoins, même si un seul des deux époux a la qualité d’associé, les
          actions de la SAS souscrites au moyen de biens communs tombent dans la
          communauté et devront être partagées entre les deux époux en cas de
          liquidation de la communauté (séparation, décès).
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sarl') {
    popoverTextTypeAssocie = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>
            Les associés d’une SARL peuvent être une personne physique ou morale
          </h4>
          Toute personne au sens juridique du terme peut avoir la qualité
          d’associé de SARL. Il peut donc s’agir aussi bien d’une personne
          physique que d’une personne morale qu’il s’agisse d’une autre société
          ou d’une association.
          <h4>Une personne peut être associée de plusieurs SARL différentes</h4>
          Les personnes physiques comme les personnes morales peuvent être
          associées d’une ou de plusieurs SARL sans limitation. Cela permet
          souvent de cloisonner des activités dans des entités juridiques
          spécifiques autonomes.
          <h4>
            L’associé de SARL peut avoir la nationalité Française ou étrangère
          </h4>
          Tout personne de nationalité française, européenne, non européenne
          peut devenir associée d’une SARL.
          <h4>
            Aucune incapacité particulière n’interdit de devenir associé de SARL
          </h4>
          Aucune incompatibilité ou interdiction n’interdit d’être associé d’une
          SARL, dès lors que l’associé n’est pas le gérant. Peuvent donc être
          associés de SARL : <br />
          &nbsp;&nbsp;• les majeurs capables ou incapables (sous curatelle,
          tutelle ou sauvegarde de justice) ;<br />
          &nbsp;&nbsp;• les mineurs émancipés (assimilés à des majeurs) ;<br />
          &nbsp;&nbsp;• les mineurs non émancipés dès lors qu’ils agissent dans
          les conditions légales à respecter.
        </Typography>
      </div>
    );
  }

  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        const type = values.id ? EDIT_PARTNER : ADD_PARTNER;

        dispatch({
          type,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        if (values.personne_physique_morale === 'personne_physique') {
          Automate.nextTypePartenaireVersInfoPartenaire();
          setAutomateState(Automate.state);
        }
        if (values.personne_physique_morale === 'personne_morale') {
          Automate.nextTypePartenaireVersInfoPersonneMorale();
          setAutomateState(Automate.state);
        }

        // Automate.demandeInfoDeLaPersonneMorale();
        // setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              {/* {formValues.alone === 'seul' && ( */}
              <Fragment>
                <Field component={RadioGroup} name="personne_physique_morale">
                  <Typography variant="subtitle1">
                    {associeOuActionnaireLabel} personne physique ou morale
                  </Typography>

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClick}
                  >
                    <HelpOutlineOutlined />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    // marginThreshold="55"
                    onClose={handleClose}
                    // anchorOrigin={{
                    //   vertical: 'center',
                    //   horizontal: 'center',
                    // }}
                    anchorReference={'none'}
                    className={classes.popOver}
                  >
                    {popoverTextTypeAssocie}
                  </Popover>
                  <FormControlLabel
                    value="personne_physique"
                    control={<Radio />}
                    label="Personne physique"
                  />
                  <FormControlLabel
                    value="personne_morale"
                    control={<Radio />}
                    label="Personne morale"
                  />
                </Field>

                {/* {values.personne_physique_morale === 'personne_physique' &&
                  formValues.eurl_sasu === 'eurl' && (
                    <Fragment>
                      <Box
                        className={classes.root}
                        marginTop="20px"
                        size="fullwidth"
                      >
                        <Field component={RadioGroup} name="choix_is">
                          <Typography variant="subtitle1">
                            {' '}
                            Souhaitez vous être soumis à l&apos;impot sur les
                            sociétés ?
                          </Typography>{' '}
                          <FormControlLabel
                            value="oui"
                            control={<Radio />}
                            label="Oui"
                          />
                          <FormControlLabel
                            value="non"
                            control={<Radio />}
                            label="Non"
                          />
                        </Field>
                      </Box>
                    </Fragment>
                  )} */}
              </Fragment>
              {/* )} */}

              {/* 

            {formValues.alone === 'a_plusieurs' && (
              <Fragment>
                <Typography variant="h6">
                  Combien de personnes physiques et de personnes morales y-a
                  t-il ?
                </Typography>

                <Box>
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="pacs">
                      Choisir le nombre exact de personnes physiques :
                    </InputLabel>

                    <Field
                      component={Select}
                      name="nombre_de_personnes_physiques"
                      inputProps={{
                        id: 'nombre_de_personnes_physiques',
                      }}
                    >
                      <MenuItem value={0}>0</MenuItem>

                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="pacs">
                      Choisir le nombre exact de personnes morales :
                    </InputLabel>
                    <Field
                      component={Select}
                      name="nombre_de_personnes_morales"
                      inputProps={{
                        id: 'nombre_de_personnes_morales',
                      }}
                    >
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                    </Field>
                  </FormControl>
                </Box>
              </Fragment>
            )} */}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};
ChoixNombrePartenairesOuTypePartenaire.propTypes = {
  nextForm: PropTypes.func,
  handleNext: PropTypes.func,
};
export default ChoixNombrePartenairesOuTypePartenaire;
