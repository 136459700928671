import React, { useState } from 'react';

export const DocumentContext = React.createContext();

export const DocumentProvider = (props) => {
  const [documents, setDocuments] = useState({});

  const dispatchDocumentEvent = (payload) => {
    if (Object.entries(documents).length === 0) {
      setDocuments(payload);
    }
  };

  // const documents = { documents: [] };
  return (
    <DocumentContext.Provider value={{ documents, dispatchDocumentEvent }}>
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </DocumentContext.Provider>
  );
};
