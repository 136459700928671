import React, { useState } from 'react';
import { IconButton, Popover, Typography } from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons';

function QuestionMark({ classes }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openHelpIcon = Boolean(anchorEl);

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClick}
      >
        <HelpOutlineOutlined />
      </IconButton>
      <Popover
        open={openHelpIcon}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div className={classes.helpPopover}>
          <Typography>
            <b>Salarié</b>
            {`: statut pouvant être choisi par le conjoint qui
        exerce une activité professionnelle régulière dans l’entreprise en
        percevant un salaire. Comme pour tout salarié, une déclaration
        préalable à l’embauche doit avoir été effectuée auprès de
        l’organisme social compétent pour l’entreprise`}
          </Typography>
          <br />
          <Typography>
            <b>Associé (si société)</b>
            {`: statut pouvant être choisi par le
          conjoint du dirigeant d'une société qui exerce une activité
          professionnelle régulière dans l'entreprise et détient des parts
          sociales dans la société. Les formalités ont été réalisées au
          niveau de la société`}
          </Typography>
          <br />
          <Typography>
            <b>Collaborateur</b>
            {` : statut pouvant être choisi par le conjoint d’un entrepreneur individuel, du 
          gérant associé unique d’une SARL unipersonnelle (EURL), ou du gérant associé majoritaire d’une 
          SARL ou SELARL, qui exerce une activité professionnelle régulière dans l’entreprise sans être 
          rémunéré, et sans être associé. Le conjoint collaborateur ne perçoit pas de rémunération et n’a 
          pas de contrat de travail. Ce statut est limité à une durée totale de 5 ans.`}
          </Typography>
        </div>
      </Popover>
    </>
  );
}

export default QuestionMark;
