import React from 'react';
// import CreationEntrepriseContainer from '../containers/CreationEntrepriseContainer';
import CreationAssociationContainer from '../containers/CreationAssociationContainer';
// import { FormProvider } from '../contexts/sci-partners.context';
import { FormProvider } from '../contexts/association-partners.context';

const CreationEntreprise = () => {
  const query = new URLSearchParams(window.location.search);

  const kit = query.get('kit') || '';
  return (
    <FormProvider kit={kit}>
      {/* <CreationEntrepriseContainer /> */}
      <CreationAssociationContainer />
    </FormProvider>
  );
};

export default CreationEntreprise;
