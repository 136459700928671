import React from 'react';
import { Formik } from 'formik';
import initialValues from './initValues';
import validationSchema from './validation';
import SelectRegimeVAT from './SelectRegimeVAT';
import FormActions from '../FormActions';

function SelectRegimeVATContainer({ goBack, saveAndNext, formulaireComplet }) {
  const form = { ...formulaireComplet };
  const { currentForm } = form;

  return (
    <Formik
      initialValues={initialValues(form)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const updatedForm = { ...form, ...values };
        setSubmitting(false);
        saveAndNext(updatedForm);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <SelectRegimeVAT formulaireComplet={formulaireComplet} />
          <FormActions goBack={goBack} currentForm={currentForm} />
        </form>
      )}
    </Formik>
  );
}

export default SelectRegimeVATContainer;
