import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { Container, Box, Typography } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Auth0Provider } from 'auth/react-auth0-spa';

import { Provider } from 'react-redux';

import config from 'auth/Config';

import store from 'store/monCompteStore';

import CreationEntreprise from 'views/CreationEntreprise';
import ChoixTypeEntreprise from 'components/nom-propre/ChoixTypeEntreprise';
import CreationSci from 'views/CreationSci';
import CreationAssociation from 'views/CreationAssociation';
import ChoixQuelleModification from 'components/modification/ChoixQuelleModification';
import ChoixDemarrage from 'views/ChoixDemarrage';
import Autoentrepreneur from 'views/Autoentrepreneur';
import MonCompte from 'views/MonCompte';
import KitPreview from 'views/KitPreview';
import CallBackPaymentView from 'views/CallBackPaymentView';
import TestKit from 'components/testKit';
import Mario from 'components/Mario';
import PrivateRoute from 'components/PrivateRoute';
import Login from 'views/Login';
import Logout from 'views/Logout';
import MyCompanies from 'views/MyCompanies';
import { DocumentProvider } from 'contexts/documents.context';

import Header from 'components/Header';
import Footer from 'components/Footer';
import CreationEntrepriseV2 from 'views/CreationEntrepriseV2';

import CreationSciV2 from 'views/CreationSciV2';
import './App.css';

import theme from './assets/jss/theme';

const renderEntreprise = () => (
  <Container maxWidth="md" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1} border={1}>
      <Typography variant="h4" component="h1" gutterBottom>
        <CreationEntreprise />
      </Typography>
    </Box>
  </Container>
);
const renderEntrepriseV2 = () => (
  <Container
    maxWidth="sm"
    style={{ minHeight: 'calc(100vh - 340px)', marginTop: '3%' }}
  >
    <CreationEntrepriseV2 />
  </Container>
);

const renderNomPropre = () => (
  <Container maxWidth="md" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1} border={1}>
      <Typography variant="h4" component="h1" gutterBottom>
        <ChoixTypeEntreprise />
      </Typography>
    </Box>
  </Container>
);

const renderAutoentrepreneur = () => (
  <Container maxWidth="md" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1} border={1}>
      <Autoentrepreneur />
    </Box>
  </Container>
);

const renderSci = () => (
  <Container
    maxWidth="sm"
    style={{ minHeight: 'calc(100vh - 340px)', marginTop: '3%' }}
  >
    <CreationSciV2 />
  </Container>
);
const renderSciOLD = () => (
  <Container maxWidth="md" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1} border={1}>
      <CreationSci />
    </Box>
  </Container>
);
const renderAssociation = () => (
  <Container maxWidth="md" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1} border={1}>
      <CreationAssociation />
    </Box>
  </Container>
);

const renderModification = () => (
  <Container maxWidth="md" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1} border={1}>
      <ChoixQuelleModification />
    </Box>
  </Container>
);

const testKit = () => (
  <Container maxWidth="md">
    <TestKit />
  </Container>
);

const renderMario = () => (
  <Container maxWidth="md">
    <Mario />
  </Container>
);
const renderApp = () => {
  const query = new URLSearchParams(window.location.search);

  const kit = query.get('kit') || '';

  return (
    <Container maxWidth="md" style={{ minHeight: 'calc(100vh - 340px)' }}>
      <Box my={4} width={1} border={1}>
        <ChoixDemarrage kit={kit} />
      </Box>
    </Container>
  );
};

const renderMonCompte = () => (
  <Container maxWidth="lg" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1}>
      <MonCompte />
    </Box>
  </Container>
);

const renderKitPreview = () => (
  <Container maxWidth="lg" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1}>
      <KitPreview />
    </Box>
  </Container>
);

const renderCallBackPayment = () => (
  <Container maxWidth="lg" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1}>
      <CallBackPaymentView />
    </Box>
  </Container>
);

const renderLogin = () => (
  <Container maxWidth="lg" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1}>
      <Login />
    </Box>
  </Container>
);

const renderLogout = () => (
  <Container maxWidth="lg" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1}>
      <Logout />
    </Box>
  </Container>
);

const renderMyCompanies = () => (
  <Container maxWidth="lg" style={{ minHeight: 'calc(100vh - 340px)' }}>
    <Box my={4} width={1}>
      <MyCompanies />
    </Box>
  </Container>
);

const onRedirectCallback = () => {
  // eslint-disable-next-line no-unused-expressions
  window.location = `/my-companies`;
};

const App = () => {
  return (
    <div
      style={{
        flex: 1,
        margin: '0 auto',
      }}
    >
      <DocumentProvider>
        <Auth0Provider
          domain={config.domain}
          client_id={config.clientId}
          audience={config.audience}
          cacheLocation="localstorage"
          redirect_uri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
        >
          <Provider store={store}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <Router>
                  <Header />
                  <Switch>
                    <Route path="/kit">{renderKitPreview()}</Route>
                    <PrivateRoute path="/my-companies">
                      {renderMyCompanies()}
                    </PrivateRoute>
                    <Route path="/callBack/payment">
                      {renderCallBackPayment()}
                    </Route>
                    <Route path="/compte-creation">{renderMonCompte()}</Route>
                    <Route path="/entrepriseOLD">{renderEntreprise()}</Route>
                    <Route path="/entreprise">{renderEntrepriseV2()}</Route>
                    <Route path="/TEST-KIT">{testKit()}</Route>
                    <Route path="/autoentrepreneur">
                      {renderAutoentrepreneur()}
                    </Route>
                    <Route path="/nom-propre">{renderNomPropre()}</Route>
                    <Route path="/sciOLD">{renderSciOLD()}</Route>
                    <Route path="/sci">{renderSci()}</Route>
                    <Route path="/association">{renderAssociation()}</Route>
                    <Route path="/modification"> {renderModification()}</Route>
                    <Route path="/mario"> {renderMario()}</Route>
                    <PrivateRoute path="/logout">{renderLogout()}</PrivateRoute>
                    <Route path="/dev"> {renderApp()}</Route>
                    <Route path="/"> {renderLogin()}</Route>
                  </Switch>
                  <Footer />
                </Router>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </Provider>
        </Auth0Provider>
      </DocumentProvider>
    </div>
  );
};

export default App;
