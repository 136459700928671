import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Field, Formik, Form } from 'formik';
import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

import { RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';
import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles({
  root: {
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  title: {
    justifyContent: 'center',
  },
});

const SignupSchema = Yup.object().shape({
  eurl_sasu: Yup.string().required('un choix est necessaire'),
});

const ChoixTypeSociete = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  // let associeOuActionnaireLabel = 'Associé'; // ca c'est ce qu'on affiche de base      let partnerNameLabel= "Nom de l'associé";
  let eurlOuSarlValue = 'eurl';
  let eurlOuSarlLabel = 'EURL';

  let sasuouSasValue = 'sasu';
  let sasuouSasLabel = 'SASU';

  // if (values.eurl_sasu === 'sasu' || values.eurl_sasu === 'sas') {
  //   associeOuActionnaireLabel = 'Actionnaire';
  // }

  if (formValues.nom_propre === 'nom_propre') {
    sasuouSasValue = 'independant';
    sasuouSasLabel = 'Indépendant, en nom propre';

    eurlOuSarlValue = 'eirl';
    eurlOuSarlLabel = 'EIRL';
  }

  if (formValues.alone === 'a_plusieurs') {
    eurlOuSarlValue = 'sarl';
    eurlOuSarlLabel = 'SARL';

    sasuouSasValue = 'sas';
    sasuouSasLabel = 'SAS';
  }

  useEffect(() => {}, []);
  return (
    <Formik
      initialValues={formValues}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        Automate.nextTypeSocieteVersInfoSociete();
        setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.title}
            >
              <Typography variant="h6">
                C&apos;est la dernière question avant de rentrer dans le vif du
                sujet
              </Typography>
            </Box>

            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.title}
            >
              <Typography variant="h6">
                Je crée ma société
                {/* Je crée mon entreprise en{' '} */}
                {/* {formValues.nom_propre === 'nom_propre'
                  ? 'nom propre'
                  : 'société'}{' '} */}{' '}
                et{' '}
                {formValues.alone === 'seul'
                  ? 'je suis seul'
                  : 'nous sommes plusieurs'}
              </Typography>
            </Box>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Field component={RadioGroup} name="eurl_sasu">
                <Typography variant="subtitle1">
                  {' '}
                  Je crée en {eurlOuSarlLabel} ou en {sasuouSasLabel}{' '}
                </Typography>
                <FormControlLabel
                  value={eurlOuSarlValue}
                  control={<Radio />}
                  label={eurlOuSarlLabel}
                />
                <FormControlLabel
                  value={sasuouSasValue}
                  control={<Radio />}
                  label={sasuouSasLabel}
                />
              </Field>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixTypeSociete.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixTypeSociete;
