import {
  BANK,
  NO,
  NOTARIE,
  taxregime,
  YES,
  yesNo,
  BBF_NOTARIE,
} from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import React, { useEffect, useState } from 'react';
import {
  KIT_3_SCI,
  KIT_3_SOC,
  KIT_4_SCI,
  KIT_4_SOC,
  KIT_BASIQUE_SCI,
  KIT_BASIQUE_SOC,
} from 'assets/data/kitConstants';
import { SCI } from 'constants/companies';
import HelpIcon from 'components/commons/HelpIcon';
import Adresse from '../commons/adress';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';
import RadioWithIcon from '../commons/radioWithIcon';
// import { clearFoundDepositValues } from './capitalAndFoundDepositHelpher';
import ReleaseOfCash from './releaseOfCash';
import SwitchKit from '../switchKit/SwitchKit';

function CapitalAndFoundDeposit({ formulaireComplet }) {
  const classes = useStyles();
  const { company_name, eurl_sasu } = formulaireComplet;
  const {
    values: {
      connait_depot_des_fonds,
      capitalChiffres,
      depot_capital_chez,
      choix_is,
      liberationPartielle,
      pourcentOfRelease,
      kit,
    },
    setFieldValue,
  } = useFormikContext();
  const [showSwitchKit, setShowSwitchKit] = useState(false);
  // useEffect(() => {
  //   if (NO === connait_depot_des_fonds) {
  //     clearFoundDepositValues(setFieldValue);
  //   }
  // }, [connait_depot_des_fonds]);

  const getTitle = () => {
    if (kit === KIT_4_SOC || kit === KIT_4_SCI) {
      return 'Capital';
    }
    return 'Capital et dépôt des fonds';
  };

  const isSCI = () => {
    return kit.includes('sci');
  };

  useEffect(() => {
    if (
      isSCI() &&
      liberationPartielle === YES &&
      connait_depot_des_fonds === NO
    ) {
      setShowSwitchKit(true);
    }
    if (!isSCI() && connait_depot_des_fonds === NO) {
      setShowSwitchKit(true);
    }
  }, [connait_depot_des_fonds]);

  useEffect(() => {
    // if (isSCI() && liberationPartielle === YES) {
    //   setFieldValue('connait_depot_des_fonds', '');
    // }
    if (isSCI() && liberationPartielle === NO) {
      setFieldValue('connait_depot_des_fonds', NO);
    }
  }, [liberationPartielle]);

  const confirOrSwitchKit = (updatedKit) => {
    setFieldValue('kit', updatedKit);
    if (updatedKit === KIT_4_SOC || updatedKit === KIT_4_SCI) {
      setFieldValue('depot_capital_chez', BBF_NOTARIE);
      setFieldValue('connait_depot_des_fonds', YES);
    }
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{getTitle()}</h1>
      <div className={classes.root}>
        <div className={classes.inputWithHelpIconContainer}>
          <h2
            className={classes.subTitle}
          >{`Le capital de la société ${company_name.toUpperCase()}
                    est de ${capitalChiffres} euros`}</h2>
          <div style={{ alignSelf: 'end' }}>
            <HelpIcon helpName="capital" />
          </div>
        </div>
        <Field
          component={TextField}
          type="number"
          label={`Valeur unitaire d’une ${
            eurl_sasu === SCI ? 'part sociale' : 'action'
          } *`}
          name="share_value"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
        />
        <ReleaseOfCash formulaireComplet={formulaireComplet} />
        {(kit === KIT_3_SOC ||
          kit === KIT_BASIQUE_SOC ||
          (kit === KIT_3_SCI &&
            liberationPartielle === YES &&
            pourcentOfRelease > 0) ||
          (kit === KIT_BASIQUE_SCI &&
            liberationPartielle === YES &&
            pourcentOfRelease > 0)) && (
          <>
            <SmallRadio
              classes={classes}
              title="Savez-vous où vous effectuerez le dépôt des fonds ?"
              options={yesNo}
              value={connait_depot_des_fonds}
              name="connait_depot_des_fonds"
              helpName={'connaitDepotDesFonds'}
            />
            {connait_depot_des_fonds === YES && !kit.includes('kit4') && (
              <>
                <h2 className={classes.subTitle}>Dépôt du capital chez :</h2>
                <Field
                  component={RadioGroup}
                  name="depot_capital_chez"
                  className={classes.radioContainer}
                  style={{ justifyContent: 'space-evenly' }}
                >
                  <RadioWithIcon
                    icon={<EuroSymbolIcon style={{ fontSize: '3em' }} />}
                    title="BANQUE"
                    option={BANK}
                    value={depot_capital_chez}
                    classes={classes}
                    name="depot_capital_chez"
                  />
                  <RadioWithIcon
                    icon={<AccountBalanceIcon style={{ fontSize: '3em' }} />}
                    title="NOTAIRE"
                    option={NOTARIE}
                    value={depot_capital_chez}
                    classes={classes}
                    name="depot_capital_chez"
                  />
                </Field>
                <h2 className={classes.subTitle}>
                  Information de la Banque ou du notaire :
                </h2>
                <Field
                  component={TextField}
                  type="string"
                  label="Nom de l'agence ou de l'étude *"
                  name="banking_corporation_name"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
                <Adresse
                  classes={classes}
                  title="Adresse de l'agence ou de l'étude *"
                  address="adresse_agence_bancaire"
                  city="ville_agence_bancaire"
                  zipCode="code_postal_agence_bancaire"
                  additionalAddress="additional_address_agence_bancaire"
                />
              </>
            )}
          </>
        )}
        {/* {((eurl_sasu === EURL && partners[0].personne_physique_morale === PP) ||
          eurl_sasu === SARL ||
          eurl_sasu === SCI) && ( */}
        <>
          <h2 className={classes.subTitle}>Régime d’imposition :</h2>
          <SmallRadio
            classes={classes}
            title="A quel régime d’imposition souhaitez-vous opter pour votre société ?"
            options={taxregime}
            value={choix_is}
            name="choix_is"
            helpName="regimeImpot"
          />
        </>
        {/* )} */}
      </div>
      <SwitchKit
        open={showSwitchKit}
        handleClose={() => {
          setShowSwitchKit(false);
        }}
        confirmKit={confirOrSwitchKit}
        isRecap={false}
        formulaireComplet={formulaireComplet}
      />
    </div>
  );
}

export default CapitalAndFoundDeposit;
