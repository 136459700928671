export const DIRECTORS = 'DIRECTORS';
export const PARTNERS = 'PARTNERS';
export const NAMED = 'NAMED';
export const BBF = 'BBF';
export const CLIENT = 'CLIENT';
export const KIT_BASIQUE_SOC = 'basiquesoc';
export const KIT_3_SOC = 'kit3soc';
export const KIT_4_SOC = 'kit4soc';
export const KIT_BASIQUE_SCI = 'basiquesci';
export const KIT_3_SCI = 'kit3sci';
export const KIT_4_SCI = 'kit4sci';

export const kitTarifs = [
  {
    kit: KIT_BASIQUE_SOC,
    title: 'Kit statuts et dossier juridique',
    prixHT: 90.0,
    tva: 20,
    prixTVA: 18,
    prixTTC: 108.0,
  },
  {
    kit: KIT_3_SOC,
    title: 'Kit statuts et immatriculation',
    prixHT: 290.0,
    tva: 20,
    prixTVA: 58,
    prixTTC: 348.0,
  },
  {
    kit: KIT_4_SOC,
    title: 'Kit on s’occupe de tout',
    prixHT: 390.0,
    tva: 20,
    prixTVA: 78,
    prixTTC: 468.0,
  },
  {
    kit: KIT_BASIQUE_SCI,
    title: 'Kit statuts et dossier juridique',
    prixHT: 90.0,
    tva: 20,
    prixTVA: 18,
    prixTTC: 108.0,
  },
  {
    kit: KIT_3_SCI,
    title: 'Kit statuts et immatriculation',
    prixHT: 290.0,
    tva: 20,
    prixTVA: 58,
    prixTTC: 348.0,
  },
  {
    kit: KIT_4_SCI,
    title: 'Kit on s’occupe de tout',
    prixHT: 390.0,
    tva: 20,
    prixTVA: 78,
    prixTTC: 468.0,
  },
];
