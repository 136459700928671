import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import { Box, Typography } from '@material-ui/core';

import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import theme from 'assets/jss/theme';

// import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '70ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const InfoMontantDesApports = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  isRecap,
  // setIsRecap,
  // cash_funds_numbers = 1000,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  // let apport_numeraire_total = 0;
  useEffect(() => {
    setActiveStep(2);
  }, []);

  // formValues.partners.forEach((partner) => {
  //   if (partner.partner_cash_funds_numbers) {
  //     apport_numeraire_total += parseInt(
  //       partner.partner_cash_funds_numbers,
  //       10
  //     );
  //   }
  // });

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     payments_amount: Yup.number()

      //       .required('un choix est necessaire')
      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (eurl_sasu && eurl_sasu === 'sasu') {
      //           return Yup.number()
      //             .min(formValues.cash_funds_numbers % 2)
      //             .max(formValues.cash_funds_numbers % 2);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (eurl_sasu && eurl_sasu === 'sas') {
      //           return Yup.number()
      //             .min(formValues.cash_funds_numbers % 2)
      //             .max(formValues.cash_funds_numbers % 2);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (eurl_sasu && eurl_sasu === 'sarl') {
      //           return Yup.number()
      //             .min(formValues.cash_funds_numbers % 5)
      //             .max(formValues.cash_funds_numbers % 5);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (eurl_sasu && eurl_sasu === 'eurl') {
      //           return Yup.number()
      //             .min(formValues.cash_funds_numbers % 5)
      //             .max(formValues.cash_funds_numbers % 5);
      //         }
      //       }),

      //   });
      // }}

      // schema de validation avec le multiassocié

      //    validationSchema={() => {
      //   return Yup.object().shape({
      //     payments_amount: Yup.number()

      //       .required('un choix est necessaire')
      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (formValues.eurl_sasu && formValues.eurl_sasu === 'sasu') {
      //           return Yup.number()
      //             .min(apport_numeraire_total % 2)
      //             .max(apport_numeraire_total % 2);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (formValues.eurl_sasu && formValues.eurl_sasu === 'sas') {
      //           return Yup.number()
      //             .min(apport_numeraire_total % 2)
      //             .max(apport_numeraire_total % 2);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (formValues.eurl_sasu && formValues.eurl_sasu === 'sarl') {
      //           return Yup.number()
      //             .min(apport_numeraire_total % 5)
      //             .max(apport_numeraire_total % 5);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (formValues.eurl_sasu && formValues.eurl_sasu === 'eurl') {
      //           return Yup.number()
      //             .min(apport_numeraire_total % 5)
      //             .max(apport_numeraire_total % 5);
      //         }
      //       }),

      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });

        setSubmitting(false);

        if (isRecap === true) {
          SciAutomate.goRecap();
          setAutomateState(SciAutomate.state);
          return;
        }

        setActiveStep(activeStep + 1);
        SciAutomate.next();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                Le montant des apports en numéraire libéré
              </Typography>
              <Typography variant="subtitle1">
                {/* Le montant minimum à libérer est de{' '}
                {formValues.eurl_sasu === 'sasu' ||
                formValues.eurl_sasu === 'sas'
                  ? apport_numeraire_total / 2
                  : apport_numeraire_total / 5}{' '}
                € */}
                Il n&apos;y a pas de montant minimum à déposer
              </Typography>

              <Box marginBottom={theme.spacing(0.5)}>
                <Field
                  component={TextField}
                  type="string"
                  label={`Combien voulez-vous libérer ? `}
                  name="payments_amount"
                  className={classes.textField}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoMontantDesApports.propTypes = {
  nextForm: PropTypes.func,
  cash_funds_numbers: PropTypes.number,
  eurl_sasu: PropTypes.string,
};
export default InfoMontantDesApports;
