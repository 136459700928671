// expense form link
export const GOOGLE_DIRECTION_BASE_URL =
  'https://www.google.com/maps/dir/?api=1&';

// Comapny ID request header
export const X_COMPANY_ID = 'X-Company-Id';

// export const COMPANY_ID = 'companyId';

// Aut0
export const ID_TOKEN = 'id_token';
export const ACCESS_TOKEN = 'access_token';
export const EXPIRES_AT = 'expires_at';
export const STATE = 'state';
export const NONCE = 'nonce';

export const SOCKET_URL =
  process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001';
// export const LAST_UPDATED = 'last_updated';
