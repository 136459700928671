import { REQUIRED } from 'constants/usersContants';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object().shape({
    regimeVat: Yup.string().required(REQUIRED),
  });
};

export default validationSchema;
