import { PP } from 'constants/usersContants';
import { capitalize } from 'lodash';

const prepareData = (values) => {
  const form = { ...values };
  form.partner_name = capitalize(form.partner_name);
  form.partner_firstname = capitalize(form.partner_firstname);
  form.partner_father_name = capitalize(form.partner_father_name);
  form.partner_father_firstname = capitalize(form.partner_father_firstname);
  form.partner_mother_name = capitalize(form.partner_mother_name);
  form.partner_mother_firstname = capitalize(form.partner_mother_firstname);
  if (form.partner_spouse_name !== '') {
    form.partner_spouse_name = capitalize(form.partner_spouse_name);
    form.partner_spouse_firstname = capitalize(form.partner_spouse_firstname);
  }
  if (form.pacs_spouse_firstname !== '') {
    form.pacs_spouse_name = capitalize(form.pacs_spouse_name);
    form.pacs_spouse_firstname = capitalize(form.pacs_spouse_firstname);
  }
  if (form.personne_physique_morale === PP) {
    delete form.forme_juridique_personne_morale;
  }
  if (form.partner_wedding_prenup_officer_office_address !== '') {
    form.partner_wedding_prenup_officer_office_place = `${form.partner_wedding_prenup_officer_office_address} ${form.partner_wedding_prenup_officer_office_city} ${form.partner_wedding_prenup_officer_office_zipcode}`;
  }
  return form;
};

export default prepareData;
