const defaultForm = (kit = '') => {
  return {
    // Auto-entrepreneur form

    // MonProjetAutoEntreprise
    auto_entrepreneur_à_titre_exclusif: '',
    serez_vous_simultanément: '',
    activity: '',
    je_ne_trouve_pas_mon_activité_dans_cette_liste: '',
    décrivez_votre_activité_le_plus_précisément_possible_puis_indiquez_sa_nature:
      '',
    date_of_Birth: '',
    vous_déjà_exercé_une_activité_non_salariée_en_France: '',
    rappelez_votre_numéro_unique: '',
    votre_activité_est_exercée_à_titre: '',
    souhaitez_vous_exercer_une_ou_plusieurs_activités_secondaires: '',
    activités: '',
    nom_commercial: '',

    // InfoPersonnelles
    nom_de_naissance: '',
    téléphone_fixe: '',
    nom_d_usage: '',
    téléphone_mobile: '',
    nationalité: '',
    date_de_naissance: '',
    genre: '',
    lieu_de_naissance: '',
    commune_ou_code_postal_de_naissance: '',
    mon_adresse_personnelle: '',
    adresse_à_l_étranger1: false,
    numéro_de_voie1: '',
    type_de_voie1: '',
    libellé_de_voie_ou_de_lieu_dit1: '',
    complément1: '',
    commune_ou_code_postal1: '',
    bureau_distributeur1: '',
    distribution_spéciale1: '',
    adresse_professionnelle: '',
    numéro_de_voie2: '',
    type_de_voie2: '',
    libellé_de_voie_ou_de_lieu_dit2: '',
    complément2: '',
    commune_ou_code_postal2: '',
    bureau_distributeur2: '',
    distribution_spéciale2: '',
    dénomination_du_domiciliataire: '',
    SIREN_du_domiciliataire: '',
    adresse_de_correspondance: '',
    adresse_à_l_étranger2: false,
    numéro_de_voie3: '',
    type_de_voie3: '',
    libellé_de_voie_ou_de_lieu_dit3: '',
    complément3: '',
    commune_ou_code_postal3: '',
    distribution_spéciale3: '',
    dénomination_du_destinataire: '',
    ville1: '',
    pays1: '',
    ville2: '',
    pays2: '',
    prénom1: '',
    bureau_distributeur3: '',

    // MesVoletsSocialEtFiscal
    numéro_de_sécurité_sociale: '',
    numéro_ne_m_a_jamais_été_attribué: '',
    ma_situation_matrimoniale: '',
    quel_est_son_statut: '',
    nom_de_naissance2: '',
    date_de_naissance2: '',
    nom_d_usage2: '',
    lieu_de_naissance2: '',
    prénom: '',
    commune_ou_code_postal_de_naissance2: '',
    radio4: '',
    numéro_de_sécurité_sociale_de_votre_conjoint: '',
    ce_numéro_ne_m_a_jamais_été_attribué1: '',
    ce_numéro_ne_m_a_jamais_été_attribué2: '',
    versement_de_mes_cotisations: '',
    radio6: '',
    radio7: '',
    radio8: '',
    checkbox2: '',
    dénomination: '',
    objet_de_l_activité_professionnelle: '',
    lieu_du_registre_du_dépôt: '',
    SIREN_du_précédent_entrepreneur: '',

    // FinalDeMaDeclaration
    cette_demande_a_été_effectuée_par_un_mandataire: '',
    nom_Prénom_ou_Dénomination: '',
    numéroz_de_voie4: '',
    type_de_voie4: '',
    libellé_de_voie_ou_de_lieu_dit4: '',
    complément4: '',
    commune_ou_code_postal4: '',
    bureau_distributeur4: '',
    distribution_spéciale4: '',
    radio5: '',
    observations: '',

    // ListeDesPiecesJointes
    numéroz_de_voie5: '',
    uploadFile: '',
    kit,
  };
};

export default defaultForm;
