import React, { Fragment, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Field, Formik, Form } from 'formik';
import {
  FormControlLabel,
  Radio,
  // MenuItem,
  Box,
  Typography,
} from '@material-ui/core';

import { RadioGroup } from 'formik-material-ui';

// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';

import { makeStyles } from '@material-ui/core/styles';

import theme from 'assets/jss/theme';

import Paper from 'components/surface/Paper';
import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { ADD_PARTNER, EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const SignupSchema = Yup.object().shape({
  personne_physique_morale: Yup.string().required('un choix est necessaire'),
});

const ChoixNombrePartenairesOuTypePartenaire = ({
  partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();
  useEffect(() => {
    setActiveStep(1);
  }, []);

  let associeOuActionnaireLabel = 'Associé'; // ca c'est ce qu'on affiche de base      let partnerNameLabel= "Nom de l'associé";
  // let eurlOuSarlValue = 'eurl';
  // let eurlOuSarlLabel = 'EURL';

  // let sasuouSasValue = 'sasu';
  // let sasuouSasLabel = 'SASU';

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    associeOuActionnaireLabel = 'Actionnaire';
  }

  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        const type = values.id ? EDIT_PARTNER : ADD_PARTNER;

        dispatch({
          type,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        if (values.personne_physique_morale === 'personne_physique') {
          SciAutomate.nextTypePartenaireVersInfoPartenaire();
          setAutomateState(SciAutomate.state);
        }
        if (values.personne_physique_morale === 'personne_morale') {
          SciAutomate.nextTypePartenaireVersInfoPersonneMorale();
          setAutomateState(SciAutomate.state);
        }

        // Automate.demandeInfoDeLaPersonneMorale();
        // setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              {/* {formValues.alone === 'seul' && ( */}
              <Fragment>
                <Field component={RadioGroup} name="personne_physique_morale">
                  <Typography variant="subtitle1">
                    {associeOuActionnaireLabel} personne physique ou morale
                  </Typography>

                  <FormControlLabel
                    value="personne_physique"
                    control={<Radio />}
                    label="Personne physique"
                  />
                  <FormControlLabel
                    value="personne_morale"
                    control={<Radio />}
                    label="Personne morale"
                  />
                </Field>
              </Fragment>

              {/* )} */}

              {/* 

            {formValues.alone === 'a_plusieurs' && (
              <Fragment>
                <Typography variant="h6">
                  Combien de personnes physiques et de personnes morales y-a
                  t-il ?
                </Typography>

                <Box>
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="pacs">
                      Choisir le nombre exact de personnes physiques :
                    </InputLabel>

                    <Field
                      component={Select}
                      name="nombre_de_personnes_physiques"
                      inputProps={{
                        id: 'nombre_de_personnes_physiques',
                      }}
                    >
                      <MenuItem value={0}>0</MenuItem>

                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                    </Field>
                  </FormControl>

                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="pacs">
                      Choisir le nombre exact de personnes morales :
                    </InputLabel>
                    <Field
                      component={Select}
                      name="nombre_de_personnes_morales"
                      inputProps={{
                        id: 'nombre_de_personnes_morales',
                      }}
                    >
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                    </Field>
                  </FormControl>
                </Box>
              </Fragment>
            )} */}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};
ChoixNombrePartenairesOuTypePartenaire.propTypes = {
  nextForm: PropTypes.func,
  handleNext: PropTypes.func,
};
export default ChoixNombrePartenairesOuTypePartenaire;
