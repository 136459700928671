import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Card,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCreationCompany,
  generateDocs,
  saveCompanyData,
  sendEmailPaiementCancled,
} from 'actions/monCompteActions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios';
import _ from 'lodash';

import useSocket from 'hooks/useSocket';
import { SOCKET_URL } from 'config/Constants';

const useStyles = makeStyles(() => ({
  cardSubHeading: {
    color: '#8D8D8D',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 40,
  },

  successIcon: {
    color: '#26B887',
    height: '4em',
    width: '4em',
  },

  CancelIcon: {
    color: '#C54343',
    height: '4em',
    width: '4em',
  },

  card: {
    padding: 30,
  },

  cardFooter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
}));

const PaymentScreen = () => {
  const { socket } = useSocket({
    socketUrl: SOCKET_URL,
  });
  const query = new URLSearchParams(window.location.search);
  const success = query.get('success');
  const canceled = query.get('canceled');
  const classes = useStyles();
  const history = useHistory();
  const { companyId } = useSelector((state) => state.app);
  const { company, loading } = useSelector((state) => state.monCompte);
  const { alternativeId, kit } = company;
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCreationCompany(companyId));
  }, []);

  useEffect(async () => {
    // eslint-disable-next-line
    if (company.hasOwnProperty('stateOfClientFolder')) {
      if (success && !emailSent) {
        setEmailSent(true);
        const docs = await dispatch(
          generateDocs(company, alternativeId, companyId)
        );
        const newCompany = _.cloneDeep(company);
        newCompany.stateOfClientFolder.paid = true;
        newCompany.documents = docs;
        dispatch(saveCompanyData(newCompany, company.companyId));
        console.log('[Socket] emit notification:get', companyId);
        socket.current.emit('notification:get');
      }
      if (canceled && !emailSent) {
        setEmailSent(true);
        sendEmailPaiementCancled(company);
      }
    }
  }, [company]);

  const generateDocsAndRedirect = async () => {
    history.push('/compte-creation');
  };

  const paie = async () => {
    const result = await axios.get(process.env.REACT_APP_CHECKOUT_URL, {
      kit,
      companyId,
    });
    window.location.href = result.data.url;
  };

  return (
    <>
      <Grid item xs={6}>
        <Card className={classes.card}>
          {success && <CheckCircleIcon className={classes.successIcon} />}
          {canceled && <CancelIcon className={classes.CancelIcon} />}
          <Typography
            color="primary"
            component="h3"
            variant="h4"
            className={classes.cardSubHeading}
          >
            {success && `Paiement réussi !`}
            {canceled && `Paiement échoué !`}
          </Typography>

          <div className={classes.cardFooter}>
            {!loading && success && (
              <Button
                variant="contained"
                color="primary"
                onClick={generateDocsAndRedirect}
              >
                Accéder à votre compte
              </Button>
            )}
            {!loading && canceled && (
              <Button variant="contained" color="primary" onClick={paie}>
                Recommencer le paiement
              </Button>
            )}
            {loading && (
              <div>
                <Typography color="primary" component="p">
                  Géneration des documents
                </Typography>
                <CircularProgress size={24} />
              </div>
            )}
          </div>
        </Card>
      </Grid>
    </>
  );
};

export default PaymentScreen;
