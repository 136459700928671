import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import {
  FormControlLabel,
  MenuItem,
  Radio,
  Box,
  Typography,
  // IconButton,
} from '@material-ui/core';

import { Select, RadioGroup } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
// import KeyboardDatePicker from 'components/KeyboardDatePicker';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';

// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

// import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const ChoixCommissaireAuxComptes = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const { presents } = formValues;
  // const [rows] = useState([]);
  useEffect(() => {}, []);

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ errors, values, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                Les personnes présentes ou représentées à l’assemblée générale
                constitutive
              </Typography>

              {/* <Typography variant="subtitle1">Présents :</Typography>

              <Box alignContent="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Nom"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="present_nom"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Prénom"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="present_prenom"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Adresse"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="present_adresse"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Code Postal"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="present_code_postal"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Ville"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="present_ville"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de naissance"
                  placeholder="01/01/1990"
                  className={classes.birthdayField}
                  format="dd/MM/yyyy"
                  name="present_date_naissance"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Nationalité"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="present_nationalite"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Profession"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="present_profession"
                  className={classes.textField}
                  autoComplete="off"
                />
                <FormControl>
                  <Typography variant="subtitle2">Vote des status :</Typography>
                  <Field
                    component={Select}
                    name="present_vote"
                    inputProps={{
                      id: 'present_vote',
                    }}
                  >
                    <MenuItem value={3}>Pour</MenuItem>
                    <MenuItem value={6}>Contre</MenuItem>
                  </Field>
                </FormControl>
              </Box>
              <Box alignContent="left" display="flex">
                <IconButton aria-label="delete">
                  <AddCircleOutlineIcon />
                </IconButton>
              </Box>

              <Typography variant="subtitle1">Représentés :</Typography>

              <Box alignContent="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Nom"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="represente_nom"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Prénom"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="represente_prenom"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Adresse"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="represente_adresse"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Code Postal"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="represente_code_postal"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Ville"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="represente_ville"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de naissance"
                  placeholder="01/01/1990"
                  className={classes.birthdayField}
                  format="dd/MM/yyyy"
                  name="represente_date_naissance"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Nationalité"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="represente_nationalite"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Profession"
                  // placeholder="123 rue Bidon 57560 Paris"
                  name="represente_profession"
                  className={classes.textField}
                  autoComplete="off"
                />
                <FormControl>
                  <Typography variant="subtitle2">
                    Vote des status :{''}
                  </Typography>
                  <Field
                    className={classes.TextField}
                    component={Select}
                    name="represente_vote"
                    inputProps={{
                      id: 'represente_vote',
                    }}
                  >
                    <MenuItem value={3}>Pour</MenuItem>
                    <MenuItem value={6}>Contre</MenuItem>
                  </Field>
                </FormControl>
                <FormControl>
                  <Typography variant="subtitle2">
                    {' '}
                    Représenté par :{' '}
                  </Typography>
                  <Field
                    className={classes.TextField}
                    component={Select}
                    name="represente_representant"
                    inputProps={{
                      id: 'represente_representant',
                    }}
                  >
                    <MenuItem value={3}>3 exercices</MenuItem>
                    <MenuItem value={6}>6 exercices</MenuItem>
                  </Field>
                </FormControl>
              </Box>
              <Box alignContent="left" display="flex">
                <IconButton aria-label="delete">
                  <AddCircleOutlineIcon />
                </IconButton>
              </Box> */}

              <Box>
                {/* <Typography variant="subtitle1">
                  Désignation du président de séance :
                </Typography>
                <Field
                  component={TextField}
                  type="string"
                  label="Profession"
                  name="president_nom"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Profession"
                  name="president_prenom"
                  className={classes.textField}
                  autoComplete="off"
                /> */}
                <FormControl>
                  <Typography variant="subtitle2">
                    {' '}
                    Désignation du président de séance :
                  </Typography>
                  <Field
                    component={Select}
                    name="president_seance"
                    inputProps={{
                      id: 'president_seance',
                    }}
                  >
                    {presents.map((present) => (
                      <MenuItem key={present.id} value={present}>
                        {present.present_titre} {present.present_prenom}{' '}
                        {present.present_nom}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Box>
              <Box>
                {/* <Typography variant="subtitle1">
                  Désignation du secrétaire de séance :
                </Typography>
                <Field
                  component={TextField}
                  type="string"
                  label="Profession"
                  name="secretaire_nom"
                  className={classes.textField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Profession"
                  name="secretaire_prenom"
                  className={classes.textField}
                  autoComplete="off"
                /> */}
                <FormControl>
                  <Typography variant="subtitle2">
                    {' '}
                    Désignation du secrétaire de séance :
                  </Typography>
                  <Field
                    component={Select}
                    name="secretaire_seance"
                    inputProps={{
                      id: 'secretaire_seance',
                    }}
                  >
                    {presents.map((present) => (
                      <MenuItem key={present.id} value={present}>
                        {' '}
                        {present.present_titre} {present.present_prenom}{' '}
                        {present.present_nom}{' '}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Box>

              <Box>
                <Typography variant="h6">
                  Les statuts ont-ils été adoptés sans modifications :
                </Typography>
                <Field component={RadioGroup} name="adoption_sans_modification">
                  <FormControlLabel
                    // onChange={() => {
                    //   ctx[0].preexisting_contract = 'oui';
                    // }}
                    value="oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    // onChange={() => {
                    //   ctx[0].preexisting_contract = 'non';
                    // }}
                    value="non"
                    control={<Radio />}
                    label="Non"
                  />
                </Field>
              </Box>

              <Box>
                <Typography variant="h6">
                  Les statuts ont-ils été adoptés à l’unanimité :
                </Typography>
                <Field component={RadioGroup} name="adoption_unanimite">
                  <FormControlLabel
                    // onChange={() => {
                    //   ctx[0].preexisting_contract = 'oui';
                    // }}
                    value="oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    // onChange={() => {
                    //   ctx[0].preexisting_contract = 'non';
                    // }}
                    value="non"
                    control={<Radio />}
                    label="Non"
                  />
                </Field>
              </Box>

              {/* {values.adoption_unanimite === 'non' && (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell align="right">Calories</TableCell>
                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                        <TableCell align="right">Protein&nbsp;(g)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.calories}</TableCell>
                          <TableCell align="right">{row.fat}</TableCell>
                          <TableCell align="right">{row.carbs}</TableCell>
                          <TableCell align="right">{row.protein}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )} */}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixCommissaireAuxComptes.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixCommissaireAuxComptes;
