import { helpContents } from 'constants/helpContents';

// eslint-disable-next-line
export const getTitle = (pCompanyType) => {
  return `Démarrez la création de votre société`;
};

// TO DO: get content off help icon by helpName
export const getHelpContent = (helpName) => {
  if (helpName) {
    const helpMessage = helpContents.filter((contentOfName) =>
      contentOfName.name.includes(helpName)
    )[0];
    if (helpMessage) {
      return helpMessage.content;
    }
  }
  return `Nous restons à votre disposition par tchat ou par mail à cette adresse : <a href='mailto:juridique@bebusinessfocus.com'>juridique@bebusinessfocus.com</a>.`;
};
