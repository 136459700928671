import React from 'react';
import { regimeVATOptions } from 'constants/usersContants';
import { useFormikContext } from 'formik';
import useStyles from '../style';
import RadioButton from '../commons/RadioButton';

const SelectRegimeVAT = () => {
  const {
    values: { regimeVat },
  } = useFormikContext();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Régime de TVA</h1>
      <RadioButton
        classes={classes}
        title="Quel régime de TVA souhaitez-vous adopter ?"
        options={regimeVATOptions}
        value={regimeVat}
        name="regimeVat"
        style={{ marginBottom: '10%' }}
        helpName={'regime-vat'}
        errorText={'Veuillez répondre à la question'}
      />
    </div>
  );
};
export default SelectRegimeVAT;
