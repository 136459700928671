import { POSITION_PARTNER, positionOptions } from 'constants/usersContants';

const getUserInfoPositionOptions = (company_type) => {
  const eurlSasus = ['eurl', 'sasu'];
  const positions = [...positionOptions];
  if (eurlSasus.includes(company_type)) {
    const positionDisableIdx = positionOptions.findIndex(
      (item) => item.value === POSITION_PARTNER
    );
    positions[positionDisableIdx] = {
      ...positions[positionDisableIdx],
      disabled: true,
    };
  }
  return positions;
};
export default getUserInfoPositionOptions;
