/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import * as Yup from 'yup';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '60ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const SignupSchema = Yup.object().shape({
  partner_wedding_prenup_date: Yup.date().required('un choix est necessaire'),
  partner_wedding_prenup_place: Yup.string().required(
    'un choix est necessaire'
  ),
  partner_wedding_prenup_officer_office_name: Yup.string().required(
    'un choix est necessaire'
  ),
  partner_wedding_prenup_officer_office_place: Yup.string().required(
    'un choix est necessaire'
  ),
});

const InfoContratDeMariage = ({
  partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue(
        'partner_wedding_prenup_officer_office_place',
        newValue.properties.label
      );
    } else {
      setFieldValue('partner_wedding_prenup_officer_office_place', '');
      setAddressValue(newValue);
    }
  };

  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_PARTNER,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        // if (formValues.alone === 'a_plusieurs') {
        // SciAutomate.demandeInfoApportsPartenaire();
        // setAutomateState(SciAutomate.state);

        // if (isRecap) {
        //   SciAutomate.recapInfoContratMariageVersListeFin();
        //   setAutomateState(SciAutomate.state);
        //   return;
        // }

        SciAutomate.nextInfoContratMariageVersInfoApportsPartenaire();
        setAutomateState(SciAutomate.state);

        setActiveStep(activeStep + 1);

        //   return;
        // }

        // setActiveStep(activeStep + 1);
        // SciAutomate.next();
        // setAutomateState(SciAutomate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">Contrat de mariage</Typography>

              <Field
                component={KeyboardDatePicker}
                clearable
                label="Date de signature du contrat de mariage"
                placeholder="01/01/1990"
                format="dd/MM/yyyy"
                // name={`partners[${partnerIndex}].partner_wedding_prenup_date`}
                name="partner_wedding_prenup_date"
                className={classes.textField}
                autoComplete="off"
              />

              <Field
                component={TextField}
                type="string"
                label="Lieu de signature du contrat de mariage "
                // name={`partners[${partnerIndex}].partner_wedding_prenup_place`}
                name="partner_wedding_prenup_place"
                className={classes.textField}
                autoComplete="off"
              />
              <Field
                component={TextField}
                type="string"
                label="Nom de l'office notarial"
                // name={`partners[${partnerIndex}].partner_wedding_prenup_officer_office_name`}
                name="partner_wedding_prenup_officer_office_name"
                className={classes.textField}
                autoComplete="off"
              />
              <Field
                component={Autocomplete}
                // type="string"
                // label="Adresse de l'office notarial"
                // placeholder="123 rue Bidon 57560 Paris"
                // name={`partners[${partnerIndex}].partner_wedding_prenup_officer_office_place`}
                name="partner_wedding_prenup_officer_office_place"
                className={classes.textField}
                value={addressValue}
                loading={loadingAddressList}
                options={addressList}
                filterOptions={(x) => x}
                autoComplete
                includeInputInList
                filterSelectedOptions
                getOptionLabel={(option) =>
                  option && option.properties && option.properties.label
                }
                onInputChange={(event, newInputValue) =>
                  handleFetchAddress(newInputValue)
                }
                onChange={(event, newValue) =>
                  handleSelectAddress(newValue, setFieldValue)
                }
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    error={
                      touched.partner_wedding_prenup_officer_office_place &&
                      !!errors.partner_wedding_prenup_officer_office_place
                    }
                    helperText={
                      touched.partner_wedding_prenup_officer_office_place &&
                      errors.partner_wedding_prenup_officer_office_place
                    }
                    label="Adresse de l'office notarial"
                    placeholder={
                      formValues.partner_wedding_prenup_officer_office_place
                    }
                    size="small"
                  />
                )}
                renderOption={(option) => {
                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" color="textSecondary">
                          {option.properties.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoContratDeMariage.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoContratDeMariage;
