import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

// import * as Yup from 'yup';

import { RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '../surface/Paper';

import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles({
  root: {
    justifyContent: 'space-around',
    flexWrap: 'no-wrap',
    flexdirection: 'row',
  },
  title: {
    justifyContent: 'center',
  },
  radio: {
    flexdirection: 'row',
  },
});

// const SignupSchema = Yup.object().shape({
//   nom_propre: Yup.string().required('Choix obligatoire'),
// });
const ChoixNomPropreOuSociete = ({
  // partner,
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  // isRecap,
  // setIsRecap,
  presentIndex,
  setPresentIndex,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  // // console.log('lancement page !!!');

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        // if (isRecap && values.partenaire_supplementaire === 'non') {
        //   AssociationAutomate.goRecap();
        //   setAutomateState(AssociationAutomate.state);
        //   return;
        // }

        // if (values.partenaire_supplementaire === 'oui') {
        //   // dispatch({
        //   //   type: ADD_PARTNER,
        //   //   payload: values,
        //   // });

        //   setPartnerIndex(partnerIndex + 1);

        //   AssociationAutomate.boucle();
        //   setAutomateState(AssociationAutomate.state);

        //   return;
        // }

        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);

        if (values.partenaire_supplementaire === 'oui') {
          setPresentIndex(presentIndex + 1);
          AssociationAutomate.bouclePresence1();
          setAutomateState(AssociationAutomate.state);
          return;
        }

        AssociationAutomate.nextPresence2();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              size="fullwidth"
              className={classes.title}
            >
              <Typography variant="h6">
                Y a t-il une autre personne présente
              </Typography>
            </Box>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              size="fullwidth"
              className={classes.root}
            >
              <Field
                component={RadioGroup}
                name="partenaire_supplementaire"
                className={classes.radio}
              >
                <FormControlLabel value="oui" control={<Radio />} label="Oui" />

                <FormControlLabel value="non" control={<Radio />} label="Non" />
              </Field>
            </Box>
          </Paper>

          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixNomPropreOuSociete.propTypes = {
  nextForm: PropTypes.func,
  handleNext: PropTypes.func,
};
export default ChoixNomPropreOuSociete;
