import React from 'react';
import useStyles from 'components/entreprise/V2/style';
import HelpIcon from './HelpIcon';

function HelpWithTitle({ title, helpName }) {
  const classes = useStyles();

  return (
    <div
      className={classes.inputWithHelpIconContainer}
      style={{ justifyContent: 'end' }}
    >
      <h2 className={classes.helpTitle}>{title}</h2>
      <HelpIcon helpName={helpName} />
    </div>
  );
}

export default HelpWithTitle;
