import React from 'react';
import { ErrorMessage } from 'formik';
import useStyles from '../style';
import PartnerCard from '../commons/partnerCard';
import CardButton from '../commons/cardButton';

const PartnerAddAndReview = ({ goToPartnerInfo, formulaireComplet }) => {
  const classes = useStyles();
  const { partners } = formulaireComplet;

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Associé(e)s</h1>
      {partners.map((partner) => (
        <PartnerCard
          key={partner.id}
          partner={partner}
          handleClick={goToPartnerInfo}
          canEdit={true}
        />
      ))}
      <CardButton
        handleClick={() => goToPartnerInfo(null)}
        title="Ajouter un(e) Associé(e)"
      />
      <ErrorMessage name="partners">
        {(msg) => <div style={{ color: 'red', textAlign: 'left' }}>{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default PartnerAddAndReview;
