import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  // MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Formik, Field, Form } from 'formik';
import { RadioGroup, CheckboxWithLabel, TextField } from 'formik-material-ui';
import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Radio,
  // FormLabel,
  MenuItem,
  Grid,
} from '@material-ui/core';
import ButtonBox from '../entreprise/ButtonBox';
import DebugFormik from '../DebugFormik';
import { DispatchContext } from '../../contexts/autoentrepreneur.context';
import { EDIT_COMPANY } from '../../constants/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    justifyContent: 'center',
    width: '100%',
  },
}));

const InfoPersonnelles = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formValues}
      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        setActiveStep(activeStep + 1);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Box
            p={1}
            bgcolor="background.paper"
            size="fullwidth"
            className={classes.root}
          >
            <Typography variant="h3">
              2. Mes informations personnelles
            </Typography>
            <Typography variant="h5">Mon identité</Typography>

            {/* <Grid container spacing={3}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}></Grid>
            </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Nom de naissance</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="nom_de_naissance"
                      type="text"
                      label="Nom de naissance"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Nom d&apos;usage</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="nom_d_usage"
                      type="text"
                      label="Nom d'usage"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Prénom(s)</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="prénom1"
                      type="text"
                      label="Prénom(s)"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" color="primary">
                      ✚ Ajouter un prénom
                    </Button>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Genre*</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={RadioGroup}
                      name="genre"
                      className={classes.radio}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="masculin"
                            control={<Radio />}
                            label="Masculin"
                          />
                        </Grid>
                        <FormControlLabel
                          value="féminin"
                          control={<Radio />}
                          label="Féminin"
                        />
                      </Grid>
                    </Field>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Téléphone fixe</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="téléphone_fixe"
                      type="text"
                      label="Téléphone fixe"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Téléphone mobile</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="téléphone_mobile"
                      type="text"
                      label="Téléphone mobile"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Nationalité</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      fullWidth
                      component={TextField}
                      select
                      margin="normal"
                      name="nationalité"
                    >
                      <MenuItem value="99319">ACORES, MADERE</MenuItem>
                      <MenuItem value="99212">AFGHANE</MenuItem>
                      <MenuItem value="99125">ALBANAISE</MenuItem>
                      <MenuItem value="99352">ALGERIENNE</MenuItem>
                      <MenuItem value="99109">ALLEMANDE</MenuItem>
                      <MenuItem value="99404">AMERICAINE</MenuItem>
                      <MenuItem value="99130">ANDORRANE</MenuItem>
                      <MenuItem value="99395">ANGOLAISE</MenuItem>
                      <MenuItem value="99425">ANGUILLA</MenuItem>
                      <MenuItem value="99441">ANTIGUA-ET-BARBUDA</MenuItem>
                      <MenuItem value="99431">ANTILLES NEERLANDAISES</MenuItem>
                      <MenuItem value="99415">ARGENTINE</MenuItem>
                      <MenuItem value="99252">ARMENIENNE</MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="h6">Date de naissance</Typography>
                  </Grid>
                  <Grid item xs={6} maxwidth>
                    <Field
                      className={classes.field}
                      component={KeyboardDatePicker}
                      clearable={true}
                      name="date_de_naissance"
                      format="dd/MM/yyyy"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Lieu de naissance</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={RadioGroup}
                      name="lieu_de_naissance"
                      className={classes.radio}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <FormControlLabel
                            value="france"
                            control={<Radio />}
                            label="France"
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <FormControlLabel
                            value="autre pays"
                            control={<Radio />}
                            label="Autre pays"
                          />
                        </Grid>
                      </Grid>
                    </Field>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Commune ou code postal de naissance
                    </Typography>
                  </Grid>
                  <Grid item xs={6} maxwidth>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="commune_ou_code_postal_de_naissance"
                      type="text"
                      label="Example"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <br />

            <Typography variant="h5">Mon adresse personnelle</Typography>

            <Field
              component={RadioGroup}
              name="mon_adresse_personnelle"
              className={classes.radio}
            >
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <FormControlLabel
                    value="ambulant"
                    control={<Radio />}
                    label="Ambulant"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="ambulant ressortissant UE hors France"
                    control={<Radio />}
                    label="Ambulant ressortissant UE hors France"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    value="non concerné"
                    control={<Radio />}
                    label="Non concerné"
                  />
                </Grid>
              </Grid>
            </Field>

            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="adresse_à_l_étranger1"
              Label={{
                label: 'Adresse à létranger',
              }}
            />

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={6}>
                <Typography variant="h6">Numéro de voie</Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  fullWidth
                  component={TextField}
                  select
                  margin="normal"
                  name="numéro_de_voie1"
                  label="Numéro de voie"
                >
                  <MenuItem value selected="true"></MenuItem>
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B (Bis)</MenuItem>
                  <MenuItem value="C">C (Quinquies)</MenuItem>
                  <MenuItem value="D">D</MenuItem>
                  <MenuItem value="E">E</MenuItem>
                  <MenuItem value="F">F</MenuItem>
                  <MenuItem value="G">G</MenuItem>
                  <MenuItem value="H">H</MenuItem>
                  <MenuItem value="I">I</MenuItem>
                  <MenuItem value="J">J</MenuItem>
                  <MenuItem value="K">K</MenuItem>
                  <MenuItem value="L">L</MenuItem>
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="N">N</MenuItem>
                  <MenuItem value="O">O</MenuItem>
                  <MenuItem value="P">P</MenuItem>
                  <MenuItem value="Q">Q (Quater)</MenuItem>
                  <MenuItem value="R">R</MenuItem>
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="T">T (Ter)</MenuItem>
                  <MenuItem value="U">U</MenuItem>
                  <MenuItem value="V">V</MenuItem>
                  <MenuItem value="W">W</MenuItem>
                  <MenuItem value="X">X</MenuItem>
                  <MenuItem value="Y">Y</MenuItem>
                  <MenuItem value="Z">Z</MenuItem>
                </Field>
              </Grid>
            </Grid>

            {values.adresse_à_l_étranger1 === false && (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="flex-start"
                alignItems="flex-end"
              >
                <Grid item xs={6}>
                  <Typography variant="h6">Type de voie</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    className={classes.field}
                    fullWidth
                    component={TextField}
                    select
                    margin="normal"
                    name="type_de_voie1"
                    label="Type de voie"
                  >
                    <MenuItem value selected="true"></MenuItem>
                    <MenuItem value="ALL">Allée</MenuItem>
                    <MenuItem value="AV">Avenue</MenuItem>
                    <MenuItem value="BD">Boulevard</MenuItem>
                    <MenuItem value="CAR">Carrefour</MenuItem>
                    <MenuItem value="CHE">Chemin</MenuItem>
                    <MenuItem value="COR">Corniche</MenuItem>
                    <MenuItem value="CRS">Cours</MenuItem>
                    <MenuItem value="ECA">Ecart</MenuItem>
                    <MenuItem value="GR">Grand Rue</MenuItem>
                    <MenuItem value="HAM">Hameau</MenuItem>
                    <MenuItem value="IMP">Impasse</MenuItem>
                    <MenuItem value="JTE">Jetée</MenuItem>
                    <MenuItem value="MTE">Montée</MenuItem>
                    <MenuItem value="PAS">Pas (sage)</MenuItem>
                    <MenuItem value="PL">Place</MenuItem>
                    <MenuItem value="PRO">Promenade</MenuItem>
                    <MenuItem value="QUAI">Quai</MenuItem>
                    <MenuItem value="RTE">Route</MenuItem>
                    <MenuItem value="RUE">Rue</MenuItem>
                    <MenuItem value="SQ">Square</MenuItem>
                    <MenuItem value="TPL">Terre-Plein</MenuItem>
                    <MenuItem value="TRT">Tertre</MenuItem>
                    <MenuItem value="TRA">Traverse</MenuItem>
                    <MenuItem value="VLA">Villa</MenuItem>
                  </Field>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={6}>
                <Typography variant="h6">
                  Libellé de voie ou de lieu-dit
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="libellé_de_voie_ou_de_lieu_dit1"
                  type="text"
                  label="Libellé de voie ou de lieu-dit"
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={6}>
                <Typography variant="h6">Complément</Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  className={classes.field}
                  component={TextField}
                  fullWidth
                  name="complément1"
                  type="text"
                  label="Complément"
                />
              </Grid>
            </Grid>

            {values.adresse_à_l_étranger1 === true && (
              <div>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Ville*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="ville1"
                      type="text"
                      label="Ville*"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Pays*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      fullWidth
                      component={TextField}
                      select
                      margin="normal"
                      name="pays1"
                      label="Pays*"
                    >
                      <MenuItem value selected="true"></MenuItem>
                      <MenuItem value="99319">ACORES, MADERE</MenuItem>
                      <MenuItem value="99212">AFGHANE</MenuItem>
                      <MenuItem value="99125">ALBANAISE</MenuItem>
                      <MenuItem value="99352">ALGERIENNE</MenuItem>
                      <MenuItem value="99109">ALLEMANDE</MenuItem>
                      <MenuItem value="99404">AMERICAINE</MenuItem>
                      <MenuItem value="99130">ANDORRANE</MenuItem>
                      <MenuItem value="99395">ANGOLAISE</MenuItem>
                      <MenuItem value="99425">ANGUILLA</MenuItem>
                      <MenuItem value="99441">ANTIGUA-ET-BARBUDA</MenuItem>
                      <MenuItem value="99431">ANTILLES NEERLANDAISES</MenuItem>
                      <MenuItem value="99415">ARGENTINE</MenuItem>
                      <MenuItem value="99252">ARMENIENNE</MenuItem>
                    </Field>
                  </Grid>
                </Grid>
              </div>
            )}

            {values.adresse_à_l_étranger1 === false && (
              <div>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Commune ou code postal</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="commune_ou_code_postal1"
                      type="text"
                      label="Commune ou code postal"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Bureau distributeur</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="bureau_distributeur1"
                      type="text"
                      label="Bureau distributeur"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Distribution spéciale</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="distribution_spéciale1"
                      type="text"
                      label="Distribution spéciale"
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            <br />

            <Typography variant="h5">
              Cette adresse personnelle constitue également votre
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Adresse professionnelle</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={RadioGroup}
                  name="adresse_professionnelle"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            {/* If non adresse professionnelle */}
            {values.adresse_professionnelle === 'false' && (
              <div>
                <Typography variant="h5">
                  Mon adresse professionnelle
                </Typography>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Numéro de voie</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      fullWidth
                      component={TextField}
                      select
                      margin="normal"
                      name="numéro_de_voie2"
                      label="Numéro de voie"
                    >
                      <MenuItem value selected="true"></MenuItem>
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B (Bis)</MenuItem>
                      <MenuItem value="C">C (Quinquies)</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                      <MenuItem value="E">E</MenuItem>
                      <MenuItem value="F">F</MenuItem>
                      <MenuItem value="G">G</MenuItem>
                      <MenuItem value="H">H</MenuItem>
                      <MenuItem value="I">I</MenuItem>
                      <MenuItem value="J">J</MenuItem>
                      <MenuItem value="K">K</MenuItem>
                      <MenuItem value="L">L</MenuItem>
                      <MenuItem value="M">M</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                      <MenuItem value="O">O</MenuItem>
                      <MenuItem value="P">P</MenuItem>
                      <MenuItem value="Q">Q (Quater)</MenuItem>
                      <MenuItem value="R">R</MenuItem>
                      <MenuItem value="S">S</MenuItem>
                      <MenuItem value="T">T (Ter)</MenuItem>
                      <MenuItem value="U">U</MenuItem>
                      <MenuItem value="V">V</MenuItem>
                      <MenuItem value="W">W</MenuItem>
                      <MenuItem value="X">X</MenuItem>
                      <MenuItem value="Y">Y</MenuItem>
                      <MenuItem value="Z">Z</MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                {values.adresse_à_l_étranger1 === false && (
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                  >
                    <Grid item xs={6}>
                      <Typography variant="h6">Type de voie</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        className={classes.field}
                        fullWidth
                        component={TextField}
                        select
                        margin="normal"
                        name="type_de_voie2"
                        label="Type de voie"
                      >
                        <MenuItem value selected="true"></MenuItem>
                        <MenuItem value="ALL">Allée</MenuItem>
                        <MenuItem value="AV">Avenue</MenuItem>
                        <MenuItem value="BD">Boulevard</MenuItem>
                        <MenuItem value="CAR">Carrefour</MenuItem>
                        <MenuItem value="CHE">Chemin</MenuItem>
                        <MenuItem value="COR">Corniche</MenuItem>
                        <MenuItem value="CRS">Cours</MenuItem>
                        <MenuItem value="ECA">Ecart</MenuItem>
                        <MenuItem value="GR">Grand Rue</MenuItem>
                        <MenuItem value="HAM">Hameau</MenuItem>
                        <MenuItem value="IMP">Impasse</MenuItem>
                        <MenuItem value="JTE">Jetée</MenuItem>
                        <MenuItem value="MTE">Montée</MenuItem>
                        <MenuItem value="PAS">Pas (sage)</MenuItem>
                        <MenuItem value="PL">Place</MenuItem>
                        <MenuItem value="PRO">Promenade</MenuItem>
                        <MenuItem value="QUAI">Quai</MenuItem>
                        <MenuItem value="RTE">Route</MenuItem>
                        <MenuItem value="RUE">Rue</MenuItem>
                        <MenuItem value="SQ">Square</MenuItem>
                        <MenuItem value="TPL">Terre-Plein</MenuItem>
                        <MenuItem value="TRT">Tertre</MenuItem>
                        <MenuItem value="TRA">Traverse</MenuItem>
                        <MenuItem value="VLA">Villa</MenuItem>
                      </Field>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Libellé de voie ou de lieu-dit
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="libellé_de_voie_ou_de_lieu_dit2"
                      type="text"
                      label="Libellé de voie ou de lieu-dit"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Complément</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="complément2"
                      type="text"
                      label="Complément"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Commune ou code postal</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="commune_ou_code_postal2"
                      type="text"
                      label="Commune ou code postal"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Bureau distributeur</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="bureau_distributeur2"
                      type="text"
                      label="Bureau distributeur"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Distribution spéciale</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="distribution_spéciale2"
                      type="text"
                      label="Distribution spéciale"
                    />
                  </Grid>
                </Grid>
                <br />

                <Typography variant="h5">Domiciliataire</Typography>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Dénomination du domiciliataire
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="dénomination_du_domiciliataire"
                      type="text"
                      label="Dénomination du domiciliataire"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      SIREN du domiciliataire
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="SIREN_du_domiciliataire"
                      type="text"
                      label="SIREN du domiciliataire"
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            {/* End */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">Adresse de correspondance</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={RadioGroup}
                  name="adresse_de_correspondance"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>

            {/* If non Adresse de correspondance */}
            {values.adresse_de_correspondance === 'false' && (
              <div>
                <Typography variant="h5">Mon adresse correspondance</Typography>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox2"
                  name="adresse_à_l_étranger2"
                  Label={{ label: 'Adresse à létranger' }}
                />

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Numéro de voie</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      fullWidth
                      component={TextField}
                      select
                      margin="normal"
                      name="numéro_de_voie3"
                      label="Numéro de voie"
                    >
                      <MenuItem value selected="true"></MenuItem>
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B (Bis)</MenuItem>
                      <MenuItem value="C">C (Quinquies)</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                      <MenuItem value="E">E</MenuItem>
                      <MenuItem value="F">F</MenuItem>
                      <MenuItem value="G">G</MenuItem>
                      <MenuItem value="H">H</MenuItem>
                      <MenuItem value="I">I</MenuItem>
                      <MenuItem value="J">J</MenuItem>
                      <MenuItem value="K">K</MenuItem>
                      <MenuItem value="L">L</MenuItem>
                      <MenuItem value="M">M</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                      <MenuItem value="O">O</MenuItem>
                      <MenuItem value="P">P</MenuItem>
                      <MenuItem value="Q">Q (Quater)</MenuItem>
                      <MenuItem value="R">R</MenuItem>
                      <MenuItem value="S">S</MenuItem>
                      <MenuItem value="T">T (Ter)</MenuItem>
                      <MenuItem value="U">U</MenuItem>
                      <MenuItem value="V">V</MenuItem>
                      <MenuItem value="W">W</MenuItem>
                      <MenuItem value="X">X</MenuItem>
                      <MenuItem value="Y">Y</MenuItem>
                      <MenuItem value="Z">Z</MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                {values.adresse_à_l_étranger2 === false && (
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                  >
                    <Grid item xs={6}>
                      <Typography variant="h6">Type de voie</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        className={classes.field}
                        fullWidth
                        component={TextField}
                        select
                        margin="normal"
                        name="type_de_voie3"
                        label="Type de voie"
                      >
                        <MenuItem value selected="true"></MenuItem>
                        <MenuItem value="ALL">Allée</MenuItem>
                        <MenuItem value="AV">Avenue</MenuItem>
                        <MenuItem value="BD">Boulevard</MenuItem>
                        <MenuItem value="CAR">Carrefour</MenuItem>
                        <MenuItem value="CHE">Chemin</MenuItem>
                        <MenuItem value="COR">Corniche</MenuItem>
                        <MenuItem value="CRS">Cours</MenuItem>
                        <MenuItem value="ECA">Ecart</MenuItem>
                        <MenuItem value="GR">Grand Rue</MenuItem>
                        <MenuItem value="HAM">Hameau</MenuItem>
                        <MenuItem value="IMP">Impasse</MenuItem>
                        <MenuItem value="JTE">Jetée</MenuItem>
                        <MenuItem value="MTE">Montée</MenuItem>
                        <MenuItem value="PAS">Pas (sage)</MenuItem>
                        <MenuItem value="PL">Place</MenuItem>
                        <MenuItem value="PRO">Promenade</MenuItem>
                        <MenuItem value="QUAI">Quai</MenuItem>
                        <MenuItem value="RTE">Route</MenuItem>
                        <MenuItem value="RUE">Rue</MenuItem>
                        <MenuItem value="SQ">Square</MenuItem>
                        <MenuItem value="TPL">Terre-Plein</MenuItem>
                        <MenuItem value="TRT">Tertre</MenuItem>
                        <MenuItem value="TRA">Traverse</MenuItem>
                        <MenuItem value="VLA">Villa</MenuItem>
                      </Field>
                    </Grid>
                  </Grid>
                )}

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Libellé de voie ou de lieu-dit
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="libellé_de_voie_ou_de_lieu_dit3"
                      type="text"
                      label="Libellé de voie ou de lieu-dit"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Complément</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="complément1"
                      type="text"
                      label="Complément"
                    />
                  </Grid>
                </Grid>

                {values.adresse_à_l_étranger2 === true && (
                  <div>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6">Ville*</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          className={classes.field}
                          component={TextField}
                          fullWidth
                          name="ville3"
                          type="text"
                          label="Ville*"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6">Pays*</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          className={classes.field}
                          fullWidth
                          component={TextField}
                          select
                          margin="normal"
                          name="pays3"
                          label="Pays*"
                        >
                          <MenuItem value selected="true"></MenuItem>
                          <MenuItem value="99319">ACORES, MADERE</MenuItem>
                          <MenuItem value="99212">AFGHANE</MenuItem>
                          <MenuItem value="99125">ALBANAISE</MenuItem>
                          <MenuItem value="99352">ALGERIENNE</MenuItem>
                          <MenuItem value="99109">ALLEMANDE</MenuItem>
                          <MenuItem value="99404">AMERICAINE</MenuItem>
                          <MenuItem value="99130">ANDORRANE</MenuItem>
                          <MenuItem value="99395">ANGOLAISE</MenuItem>
                          <MenuItem value="99425">ANGUILLA</MenuItem>
                          <MenuItem value="99441">ANTIGUA-ET-BARBUDA</MenuItem>
                          <MenuItem value="99431">
                            ANTILLES NEERLANDAISES
                          </MenuItem>
                          <MenuItem value="99415">ARGENTINE</MenuItem>
                          <MenuItem value="99252">ARMENIENNE</MenuItem>
                        </Field>
                      </Grid>
                    </Grid>
                  </div>
                )}

                {values.adresse_à_l_étranger2 === false && (
                  <div>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6">
                          Commune ou code postal
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          className={classes.field}
                          component={TextField}
                          fullWidth
                          name="commune_ou_code_postal3"
                          type="text"
                          label="Commune ou code postal"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6">
                          Bureau distributeur
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          className={classes.field}
                          component={TextField}
                          fullWidth
                          name="bureau_distributeur3"
                          type="text"
                          label="Bureau distributeur"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item xs={6}>
                        <Typography variant="h6">
                          Distribution spéciale
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          className={classes.field}
                          component={TextField}
                          fullWidth
                          name="distribution_spéciale3"
                          type="text"
                          label="Distribution spéciale"
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Dénomination du destinataire
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="dénomination_du_destinataire"
                      type="text"
                      label="Dénomination du destinataire"
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {/* End */}
          </Box>

          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

export default InfoPersonnelles;
