import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Field, Formik, Form } from 'formik';

import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

import * as Yup from 'yup';

import { RadioGroup } from 'formik-material-ui';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import dataTest from 'assets/__data__/sarl';
import { makeStyles } from '@material-ui/core/styles';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    flexWrap: 'no-wrap',
  },
  title: {
    justifyContent: 'center',
  },
  radio: {
    flexdirection: 'row',
    display: 'flex',
  },
  btn_primary: {
    color: '#fff',
    backgroundColor: '#3d319d',
  },
});

const SignupSchema = Yup.object().shape({
  alone: Yup.string().required('Choix obligatoire'),
});

const ChoixSeulOuAPlusieurs = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  handleReset,
  // automateState,
  setAutomateState,
  isRecap,
  // setIsRecap,
  // demandeSiReset,
  // setDemandeSiReset
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  localStorage.removeItem('persist:root');
  const item = localStorage.getItem('form');
  const monLocalStorage = item ? JSON.parse(item) : {};
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickBoutonReprendre = () => {
    Automate.seulPlusieurVersCapital();
    setAutomateState(Automate.state);
    handleClose();
  };

  const handleClickBoutonNouvelle = () => {
    handleReset();
    handleClose();
    window.location.reload();
  };

  const handleClickSave = () => {
    handleReset();
    setOpenAlert(false);
    window.location.reload();
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const renderAlertPopup = () => {
    return (
      <div>
        {/* Alert popup */}
        <Dialog
          open={openAlert}
          // onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Voulez vous faire une nouvelle saisie ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickSave} className={classes.btn_primary}>
              Oui
            </Button>
            <Button onClick={handleCloseAlert}>Non</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  useEffect(() => {
    setActiveStep(0);
    setAutomateState('choix_seul_ou_a_plusieurs');
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const redirect = query.get('mario') || '';
    // console.log('redirect', redirect);

    if (redirect) {
      Automate.xcv();
      setAutomateState(Automate.state);
    }

    if (monLocalStorage.company_name) {
      setOpenAlert(true);
    }
  }, []);

  // if (monLocalStorage.company_name) {
  //   useEffect(() => {
  //     // eslint-disable-next-line no-alert
  //     const resultat = window.confirm(
  //       'Voulez vous faire une nouvelle saisie ?'
  //     );
  //     if (resultat) {
  //       handleReset();
  //       window.location.reload();
  //       // setDemandeSiReset(false);
  //     }
  //   }, []);
  // }

  return (
    <div>
      <Formik
        initialValues={formValues}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch({
            type: EDIT_COMPANY,
            payload: values,
          });
          setSubmitting(false);
          // setActiveStep(activeStep + 1);

          // if (isRecap) {
          //   Automate.goRecap();
          //   setAutomateState(Automate.state);
          // }

          Automate.nextSeulPlusieursVersTypeSociete();
          // setAutomateState(Automate.state);
        }}
      >
        {({ values, errors, touched, isSubmitting, submitForm }) => (
          <Form fullwidth="true">
            <Paper>
              <Box>
                {monLocalStorage.capital_in_numbers && isRecap !== true && (
                  <Box display="flex">
                    {/* <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    Cliquez ici
                  </Button> */}
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {'Continuer le remplissage du formulaire'}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Certains champs du formulaire sont déja remplis,
                          souhaitez vous reprendre la session précédente ou
                          effectuer une nouvelle saisie ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          // onClick={() => {
                          //   // console.log('test fct fantome');
                          //   handleClose();
                          // }}
                          onClick={handleClickBoutonReprendre}
                          color="primary"
                        >
                          Reprendre la session précédente
                        </Button>
                        <Button
                          onClick={handleClickBoutonNouvelle}
                          color="primary"
                          autoFocus
                        >
                          Effectuer une nouvelle saisie
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                )}

                <Box
                  display="flex"
                  p={1}
                  bgcolor="background.paper"
                  className={classes.title}
                >
                  <Typography variant="h6">
                    Pour ne pas commettre d&apos;erreur sur votre situation et
                    votre besoin, merci de répondre de nouveau à cette question
                    et à la suivante :
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  p={1}
                  bgcolor="background.paper"
                  size="fullwidth"
                  className={classes.title}
                >
                  <Typography variant="h6">Je crée ma société</Typography>
                </Box>
                <Box
                  display="flex"
                  p={1}
                  bgcolor="background.paper"
                  className={classes.root}
                >
                  <Field
                    component={RadioGroup}
                    name="alone"
                    className={classes.radio}
                  >
                    <FormControlLabel
                      value="seul"
                      control={<Radio />}
                      label="Seul"
                    />
                    <FormControlLabel
                      value="a_plusieurs"
                      control={<Radio />}
                      label="A plusieurs"
                    />
                  </Field>
                </Box>
              </Box>
            </Paper>
            <ButtonBox
              activeStep={activeStep}
              isLastStep={isLastStep}
              handleBack={handleBack}
              isSubmitting={isSubmitting}
              submitForm={submitForm}
            />
            <span
              style={{
                color: 'white',
                fontSize: '5px',
                margin: '0 50%',
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch({
                  type: EDIT_COMPANY,
                  payload: dataTest,
                });
                Automate.xcv();
                setAutomateState(Automate.state);
              }}
            >
              go to the end
            </span>
            <DebugFormik values={values} touched={touched} errors={errors} />
          </Form>
        )}
      </Formik>

      {renderAlertPopup()}
    </div>
  );
};

ChoixSeulOuAPlusieurs.propTypes = {
  nextForm: PropTypes.func,
  ctx: PropTypes.object,
};
export default ChoixSeulOuAPlusieurs;
