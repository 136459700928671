import React, { useContext, useState, useRef, useEffect } from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Popover,
  IconButton,
  Box,
  Typography,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField } from 'formik-material-ui';

import * as Yup from 'yup';
import axios from 'axios';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '20ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  radio: {},
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
  popoverTextDiv2: {
    // textAlign: 'justify',
  },
}));

const SignupSchema = Yup.object().shape({
  company_name: Yup.string().required('Required'),

  // business_name: Yup.string().required('Required'),

  code_ape: Yup.string().required('Required'),

  address: Yup.string().required('Required'),

  rcs_ville: Yup.string().required('Required'),

  zipcode: Yup.string().required('Required'),

  city: Yup.string().required('Required'),
});

const InfoSociete = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchRCS = async (values, setFieldValue) => {
    const { zipcode, city } = values;
    const { data: res } = await axios.get(
      `${process.env.REACT_APP_URL_RCS}?zip=${zipcode}&city=${city}`
    );
    if (res && res.rcs) {
      setFieldValue('rcs_ville', res.rcs);
    } else {
      setFieldValue('rcs_ville', '');
    }
  };

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue('address', newValue.properties.name);
      setFieldValue('zipcode', newValue.properties.postcode);
      setFieldValue('city', newValue.properties.city);
      handleFetchRCS(
        {
          address: newValue.properties.name,
          city: newValue.properties.city,
          zipcode: newValue.properties.postcode,
        },
        setFieldValue
      );
    } else {
      setFieldValue('address', '');
      setFieldValue('zipcode', '');
      setFieldValue('city', '');
      setAddressValue(newValue);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popover2' : undefined;

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? 'simple-popover3' : undefined;

  let popoverTextDenominationSociale = '';
  let popoverTextCodeApe = '';

  if (formValues.eurl_sasu === 'eurl') {
    popoverTextDenominationSociale = (
      <div className={classes.popOverDiv}>
        <Typography>
          {'     '}
          Toute EURL, en tant que personne morale autonome, doit avoir une
          dénominationsociale. Il s’agit du nom sous lequel elle est
          «officiellement» reconnue, notamment par les administrations. Cette
          dénomination qui est unique - peut faire l’objet d’une protection
          juridique -devra figurer sur l’ensemble des documents émis par la
          société. Il devra être précédée de la mention «Entreprise
          Unipersonnelle à Responsabilité Limitée» (EURL) ou «Société A
          Responsabilité Limitée Unipersonnelle» (SARL unipersonnelle).
          <br />
          <br />
          La dénomination sociale (qui figure dans les statuts) est une notion
          différente :<br />
          <br />
          &nbsp; &nbsp; • du nom commercial, qui peut-être celui sous lequelle
          la société est connue de ses clients;
          <br /> &nbsp; &nbsp; • de l’enseigne (signe visible identifiant un
          établissement où la société exerceson activité).{' '}
          <h4> Choisir la dénomination sociale d’une EURL </h4>
          La dénomination sociale d’une EURL doit être licite, et est librement
          déterminée à la discrétion de l’associé créateur de l’EURL. Attention
          : certaines activités reglementées nécessite que la dénomination
          sociale soit validée par les organismes de surveillance ou de
          régulation de ces professions. La seule vraie contrainte est de
          s’assurer que la dénomination sociale est disponible, qu’elle ne fait
          pas l’objet d’une protection juridique auprès de l’INPI.
          <h4> Déclaration de la dénomination sociale d’une EURL </h4>
          La dénomination sociale figurera dans les statuts constitutifs de
          l’EURL, et sur le MO de création, et sur l’extrait Kbis de la société.{' '}
          <br />
          <b>
            {' '}
            Attention : aucune vérification particulière n’est réalisée lors de
            l’immatriculation. Il vous appartient de vous assurer que la
            dénomination sociale retenue est libre d’utilisation.{' '}
          </b>{' '}
          <h4>Modification de la dénomination sociale d’une EURL </h4>
          L’associé unique a la faculté de modifier la dénomination sociale de
          son EURL. Ce type de modification n’entraîne pas la création d’une
          personne morale nouvelle, mais nécessite une modification des statuts
          auprès du greffe du tribunal de commerce/RCS.
        </Typography>
      </div>
    );

    popoverTextCodeApe = (
      <div className={classes.popOverDiv}>
        <Typography>
          <div className={classes.popoverTextDiv2}>
            L’objet social est la nature de l’activité que l’EURL va exercer.
            L’objet social tel qu’il figure dans les statuts d’une EURL est
            essentiel car : <br />
            <br />
            &nbsp; &nbsp; • il conditionne la conformité des activités exercées
            par la société, et engage laresponsabilité du gérant en cas de non
            respect du champ d’activités ; <br />
            &nbsp; &nbsp; • détermine le code APE attribué par l’INSEE à l’EURL
            ;<br />
            &nbsp; &nbsp; • détermine la convention collective appliquée en
            matière sociale ;
            <br />
            &nbsp; &nbsp; • son extinction ou la non réalisation de l’objet
            social est une cause de dissolution de l’EURL.
            <h4>Choisir l’objet social d’une EURL</h4>
            Le choix de l’objet social d’une EURL est fait par son associé
            unique, et est libre, tant qu’il est licite, et qu’il peut être
            réalisé. L’objet social est doit être décrit précisément dans les
            statuts lors de la constitution de l’EURL. En général, au-delà de
            l’activité principale dite, coeur de métier, des précisions sur les
            activités connexes sont mentionnées dans les statuts. Cela permet de
            prévoir la réalisation de toutes les opérations qui se rattachent
            directement ou indirectement à ou aux activités principales.{' '}
            <b>
              Attention: certaines activités ne peuvent être exercées en EURL.
              C’est le cas notamment des activités d’assurance, d’épargne et de
              prévoyance, de débit de tabac ou de perception et de répartition
              de droits d’auteurs.
            </b>
            <h4>Déclarer l’objet social d’une EURL au RCS</h4>
            L’objet social de l’EURL figure dans les statuts, il est mentionné
            sur le MO lors de l’immatriculation, et figurera sur le kbis de la
            société.
            <h4>Modifier l’objet social d’une EURL</h4>
            L’associé unique peut, à tout moment, modifier l’objet social de
            l’EURL. Il s’agira d’une modification statutaire à effectuer en
            respectant les règles qui y sont relatives et de procéder aux
            chnagements auprès du greffe du tribunal de commerce. Material-UI:
          </div>
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sasu') {
    popoverTextDenominationSociale = (
      <div className={classes.popOverDiv}>
        <Typography>
          Toute SASU doit avoir une dénomination sociale. Celle-ci est fixée
          dans les statuts. Il s’agit du nom de la société qui figurera sur son
          kbis et sur tous les documents qu’elle sera amenée à émettre
          directement après la forme juridique : SASU + « dénomination sociale
          de la SASU ». La dénomination sociale permet d’identifier et
          d’individualiser la société en tant que personne morale et de la
          distinguer des autres entités existantes. <br />
          <br />
          Attention la dénomination sociale est différente :
          <br />
          &nbsp; &nbsp; • du nom commercial qui correspond au nom que l’on donne
          au fonds de commerce de la société, et avec lequel la communication
          est réalisée ;
          <br />
          &nbsp; &nbsp; • du sigle qui correspond à l’acronyme ou à un élément
          distinctif permettant d’identifier la société.
          <h4>Choisir la dénomination</h4>
          La dénomination sociale d’une SASU doit être licite, et est librement
          déterminée à la discrétion de l’associé unique créateur de la SASU.
          Attention : certaines activités reglementées nécessite que la
          dénomination sociale soit validée par les organismes de surveillance
          ou de régulation de ces professions. La seule vraie contrainte est de
          s’assurer que la dénomination sociale est disponible et qu’elle ne
          fait pas l’objet d’une protection juridique auprès de l’INPI.
          <h4>Modifier la dénomination sociale d’une SASU</h4>
          La dénomination sociale initialement choisie lors de la constitution
          peut être complétée, modifiée ou changée en cours de vie sociale. La
          procédure d’approbation de cette modification est fixée par les
          statuts. Ensuite d’un point de vue administratif il s’agira d’une
          procédure de modification statutaire à réaliser auprès du greffe du
          tribunal de commerce ou du INPI avec le guichet unique.
        </Typography>
      </div>
    );

    popoverTextCodeApe = (
      <div className={classes.popOverDiv}>
        <Typography>
          Lors de la constitution d’une SASU, l’associé unique doit déterminer
          l’activité qu’il souhaite exercer. Il s’agit de l’objet social qui
          doit respecter certaines règles et figurer dans les statuts de la
          société. Il doit être suffisamment détaillé pour ne pas être limitatif
          tout en étant suffisamment explicite pour refléter l’activité de
          l’entreprise.
          <br />
          <br />
          L’objet social est essentiel car il : <br />
          &nbsp;&nbsp;• Encadre les pouvoirs du Président et peut conduite à
          mettre en jeu sa responsabilité lorsque ses actes dépassent l’objet
          social ;<br />
          &nbsp;&nbsp;• Peut conduire à la dissolution de la société en cas de
          non réalisation lorsque les statuts le prévoient ;<br />
          &nbsp;&nbsp;• Détermine le code NAF de la société ainsi que la
          convention collective applicable aux salariés.
          <h4>Comment choisir l’objet social d’une SASU ?</h4>
          L’objet social d’une SASU est librement fixé dans les statuts.
          L’associé peut donc le choisir à sa guise, tout en veillant à ce qu’il
          soit : <br />
          &nbsp;&nbsp;• autorisé car non interdit d’exercice en SAS (comme les
          activités d’assurance, <br />
          &nbsp;&nbsp;• de prévoyance) ;<br />
          &nbsp;&nbsp;• possible à réaliser ;<br />
          &nbsp;&nbsp;• licite c’est-à-dire conforme à l’ordre public et aux
          bonnes moeurs.
          <br />
          <br />
          Pour des raisons de développement et de non limitation de l’activité,
          les statuts comportent une clause d’activités connexes prévoyant la
          possibilité pour la SASU d’effectuer toutes les opérations se
          rattachant directement ou indirectement à l’objet social.
          <h4>Peut-on modifier l’objet social d’une SASU ?</h4>
          L’objet social d’une SASU peut évoluer ou être modifié totalement,
          étendu ou réduit s’il s’agit d’une décison de l’associé unique. La
          procédure de modification de l’objet social est fixée par les statuts.
          D’un point de vue administratif, une fois la décision approuvée, il
          s’agira de suivre la procédure de modification statutaire auprès du
          greffe du tribunal de commerce.
          <br />
          <br />
          Pour des raisons de développement et de non limitation de l’activité,
          les statuts comportent une clause d’activités connexes prévoyant la
          possibilité pour la SASU d’effectuer toutes les opérations se
          rattachant directement ou indirectement à l’objet social.
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sas') {
    popoverTextDenominationSociale = (
      <div className={classes.popOverDiv}>
        <Typography>
          Toute SAS doit avoir une dénomination sociale. Celle-ci est fixée dans
          les statuts. Il s’agit du nom de la société qui figurera sur son kbis,
          et sur tous les documents qu’elle sera amenée à émettre directement
          avec la mention suivante : SAS + « dénomination sociale de la SAS ».
          La dénomination sociale permet d’identifier, et d’individualiser la
          société en tant que personne morale, et de la distinguer des autres
          entités existantes. <br />
          <br />
          Attention la dénomination sociale est différente : <br />
          &nbsp;&nbsp;• du nom commercial qui correspond au nom que l’on donne
          au fonds de commerce de la société, et avec lequel la communication
          est réalisée ; <br />
          &nbsp;&nbsp;• du sigle qui correspond à l’acronyme ou à un élément
          distinctif permettant d’identifier la société.
          <h4>Choisir la dénomination</h4>
          Même si le choix de la dénomination sociale est libre, mais elle doit
          toute-fois être : <br />
          &nbsp;&nbsp;• licite (conforme à l’ordre public et aux bonnes moeurs)
          ; <br />
          &nbsp;&nbsp;• libre de tout usage (non protégée, vérification à
          effectuer auprès de l’INPI).
          <br />
          <br />
          Attention certaines professions notamment règlementées valident les
          dénominations sociales préalablement à l’immatriculation des sociétés.
          <br />
          <br />
          Attention certaines professions, notamment règlementées, valident les
          dénomintions sociales préalablement à l’immatriculation des sociétés.
          <h4>Modifier la dénomination sociale d’une SAS</h4>
          La dénomination sociale initialement choisie lors de la constitution
          peut être complétée, modifiée ou changée en cours de vie sociale. La
          procédure d’approbation de cette modification est fixée par les
          statuts. Ensuite d’un point de vue administratif il s’agira d’une
          procédure de modification statutaire à réaliser auprès du greffe du
          tribunal de commerce.
        </Typography>
      </div>
    );
    popoverTextCodeApe = (
      <div className={classes.popOverDiv}>
        <Typography>
          Lors de la constitution d’une SAS, les associés fondateurs doivent
          déterminer l’activité qu’ils souhaitent exercer. Il s’agit de l’objet
          social qui doit respecter certaines règles, et figurer dans les
          statuts de la société. L’objet social figure dans les statuts, et doit
          être suffisamment détaillé pour ne pas être limitatif. L’objet doit
          être suffisamment explicite pour refléter l’activité de l’entreprise.
          L’objet social est essentiel car il : <br />
          &nbsp;&nbsp;• encadre les pouvoirs du Président, et peut conduire à
          mettre en jeu sa responsabilité, lorsque ses actes dépassent l’objet
          social ;<br />
          &nbsp;&nbsp;• peut conduire à la dissolution de la société en cas de
          non réalisation lorsque les statuts le prévoient ;<br />
          &nbsp;&nbsp;• détermine le code NAF de la société, ainsi que la
          convention collective applicable aux salariés.
          <h4>Comment choisir l’objet social d’une SAS ?</h4>
          L’objet social d’une SAS est librement fixé dans les statuts. Les
          associés peuvent donc choisir l’objet social à leur guise. Toutefois,
          la loi impose que ce dernier soit :<br />
          &nbsp;&nbsp;• autorisé car non-interdit d’exercice en SAS (assurance,
          prévoyance…) ;<br />
          &nbsp;&nbsp;• réalisable ;<br />
          &nbsp;&nbsp;• licite, c’est-à-dire conforme à l’ordre public et aux
          bonnes moeurs.
          <br />
          <br />
          Pour des raisons de développement, les statuts comportent une clause
          d’activités connexes. Elles prévoient la possibilité d’effectuer
          toutes les opérations se rattachant directement ou indirectement à
          l’objet social.
          <h4>Peut-on modifier l’objet social d’une SAS ?</h4>
          L’objet social d’une SAS peut évoluer au cours de la vie sociale. Les
          associés ont la possibilité de le modifier tout au long de la vie
          sociale. Ils peuvent le modifier totalement, l’étendre ou au contraire
          le réduire. La procédure de modification de l’objet social est fixée
          par les statuts. D’un point de vue administratif, une fois la décision
          approuvée, il s’agira de suivre la procédure de modification
          statutaire.
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sarl') {
    popoverTextDenominationSociale = (
      <div className={classes.popOverDiv}>
        <Typography>
          Toute SARL, en tant que personne morale autonome doit avoir une
          dénomination sociale qui est le nom sous lequel elle est
          «officiellement» reconnue notamment par les administrations. Cette
          dénomination qui est unique - peut faire l’objet d’une protection
          juridique - devra figurer sur l’ensemble des documents émis par la
          société, précédée ou suivie de la mention Société A Responsabilité
          Limitée (SARL). <br />
          <br />
          Cette dénomination sociale qui figure dans les statuts diffère :<br />
          &nbsp;&nbsp;• du nom commercial qui peut-être celui sous lequelle la
          société est connue de ses clients ;<br />
          &nbsp;&nbsp;• de l’enseigne.
          <h4>Choisir la dénomination sociale d’une SARL</h4>
          La dénomination sociale d’une SARL doit être licite et est librement
          déterminée à la discrétion des associés. Attention : certaines
          activités réglementées nécessitent que la dénomination sociale soit
          validée par les organismes de surveillance ou de régulation de ces
          professions. La seule vraie contrainte est de s’assurer que la
          dénomination sociale est disponible qu’elle ne fait pas l’objet d’une
          protection juridique auprès de l’INPI.
          <h4>Déclaration de la dénomination sociale d’une SARL</h4>
          La dénomination sociale figurera dans les statuts constitutifs de la
          SARL et sur le MO de création ainsi que sur l’extrait Kbis de la
          société. Attention : aucune vérification particulière n’est réalisée
          lors de l’immatriculation. Il vous appartient de vous assurer que la
          dénomination sociale retenue est libre d’utilisation.
          <h4>Modification de la dénomination sociale d’une SARL</h4>
          Les associés ont la faculté de modifier la dénomination sociale de la
          SARL. Cela nécessite une modification des statuts auprès du greffe du
          tribunal de commerce/ RCS.
        </Typography>
      </div>
    );
    popoverTextCodeApe = (
      <div className={classes.popOverDiv}>
        <Typography>
          L’objet social est la nature des activités que la SARL va exercer.
          L’objet social figure dans les statuts de la SARL. Il est essentiel
          car : <br />
          &nbsp;&nbsp;• il conditionne la conformité des activités exercées par
          la société et engage la responsabilité du gérant en cas de non respect
          du champ d’activités ; <br />
          &nbsp;&nbsp;• il détermine le code APE attribué par l’INSEE à la SARL
          ;<br />
          &nbsp;&nbsp;• il détermine la convention collective appliquable en
          matière sociale ;<br />
          <br />
          La non réalisation de l’objet social est une cause de dissolution de
          la SARL.
          <h4>Choisir l’objet social d’une SARL</h4>
          Le choix de l’objet social d’une SARL est libre tant qu’il est licite
          et qu’il peut être réalisé. L’objet social doit être décrit
          précisément dans les statuts lors de la constitution de la SARL. En
          général, au-delà de l’activité coeur de métier, des précisions sur les
          activités connexes sont mentionnées dans les statuts afin de prévoir
          la réalisation de toutes les opérations qui se rattachent directement
          ou indirectement à ou aux activités principales. Attention : certaines
          activités ne peuvent être exercées en SARL. C’est le cas notamment des
          activités d’assurance, d’épargne et de prévoyance, de débit de tabac
          ou de perception et de répartition de droits d’auteurs.
          <h4>Déclarer l’objet social d’une SARL au RCS</h4>
          L’objet social de la SARL figure dans les statuts, il est mentionné
          sur le MO lors de l’immatriculation, et figurera sur le kbis de la
          société.
          <h4>Modifier l’objet social d’une SARL</h4>
          Les associés, à tout moment, peuvent modifier l’objet social de la
          SARL. Il s’agira d’une modification statutaire à effectuer en
          respectant les règles qui y sont relatives.
        </Typography>
      </div>
    );
  }

  const popoverTextAdresse = (
    <div className={classes.popOverDiv}>
      <Typography>
        Si votre adresse n’apparait pas dans le menu déroulant merci de la
        préciser avec le nom de la ville
      </Typography>
    </div>
  );

  return (
    <Formik
      initialValues={formValues}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        // if (values.rcs_ville) {
        Automate.nextInfoSocieteVersInfoSociete2();
        setAutomateState(Automate.state);
        // }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6"> La société en création </Typography>
              </Box>

              <Box className={classes.root}>
                <Box>
                  <Field
                    component={TextField}
                    type="string"
                    label="Dénomination sociale"
                    name="company_name"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClick}
                  >
                    <HelpOutlineOutlined />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    // marginThreshold="55"
                    onClose={handleClose}
                    // anchorOrigin={{
                    //   vertical: 'center',
                    //   horizontal: 'center',
                    // }}
                    anchorReference={'none'}
                    className={classes.popOver}
                  >
                    {popoverTextDenominationSociale}
                  </Popover>
                </Box>

                <Field
                  component={TextField}
                  type="string"
                  label="Nom commercial"
                  name="business_name"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box alignSelf="center" marginTop="10px">
                <Box>
                  <Field
                    component={TextField}
                    type="string"
                    label="Objet social"
                    name="code_ape"
                    className={classes.textField}
                    placeholder="Inscrire la nature de votre activité, par exemple : conseils, formation,
                     travaux de peinture, rénovation de bâtiment, second œuvre, gros-œuvre…"
                    fullwidth="true"
                    multiline
                    rows="4"
                    autoComplete="off"
                  />

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClick2}
                  >
                    <HelpOutlineOutlined />
                  </IconButton>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorReference={'none'}
                    className={classes.popOver}
                  >
                    {popoverTextCodeApe}
                  </Popover>
                </Box>
              </Box>

              <Box className={classes.root}>
                <Field
                  name="address"
                  component={Autocomplete}
                  className={classes.nameTextField}
                  value={addressValue}
                  loading={loadingAddressList}
                  options={addressList}
                  filterOptions={(x) => x}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    option && option.properties && option.properties.name
                  }
                  onChange={(event, newValue) =>
                    handleSelectAddress(newValue, setFieldValue)
                  }
                  onInputChange={(event, newInputValue) =>
                    handleFetchAddress(newInputValue)
                  }
                  renderInput={(params) => (
                    <MUITextField
                      {...params}
                      error={touched.address && !!errors.address}
                      helperText={touched.address && errors.address}
                      label="Adresse du siège social"
                      placeholder={formValues.address}
                      size="small"
                    />
                  )}
                  renderOption={(option) => {
                    return (
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2" color="textSecondary">
                            {option.properties.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClick3}
                >
                  <HelpOutlineOutlined />
                </IconButton>
                <Popover
                  id={id3}
                  open={open3}
                  anchorEl={anchorEl3}
                  // marginThreshold="55"
                  onClose={handleClose3}
                  // anchorOrigin={{
                  //   vertical: 'center',
                  //   horizontal: 'center',
                  // }}
                  anchorReference={'none'}
                  className={classes.popOver}
                >
                  {popoverTextAdresse}
                </Popover>
              </Box>

              <Box className={classes.root}>
                <Field
                  component={TextField}
                  type="string"
                  label="Rue du siège social"
                  name="address"
                  className={classes.nameTextField}
                  autoComplete="off"
                  disabled
                />
              </Box>

              <Box className={classes.root}>
                <Field
                  component={TextField}
                  type="int"
                  label="Code postal du siège social"
                  name="zipcode"
                  className={classes.nameTextField}
                  autoComplete="off"
                  onBlur={() => handleFetchRCS(values, setFieldValue)}
                  disabled
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Ville du siège social"
                  name="city"
                  className={classes.nameTextField}
                  autoComplete="off"
                  onBlur={() => handleFetchRCS(values, setFieldValue)}
                  disabled
                />
              </Box>
              {/* <Field
                component={TextField}
                type="string"
                disabled
                defaultValue={values.rcs_ville}
                label={formValues.rcs_ville}
                name="rcs_ville"
                className={classes.nameTextField}
              /> */}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoSociete.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoSociete;
