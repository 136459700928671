import axios from 'axios';

const createStatus = async (values) =>
  axios.post(process.env.REACT_APP_URL_CREATE_STATUS, { ...values });

const createForm = async (values, companyId, alternativeId) =>
  axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/crea`,
    {
      ...values,
      alternativeId,
      // stateOfClientFolder: {
      //   stepper: 0,
      //   generateDocsValidate: false,
      //   allClientDocsProvided: false,
      // },
    },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const createEurl = async (values, alternativeId, companyId) =>
  axios.post(
    process.env.REACT_APP_URL_EURL,
    { ...values, alternativeId },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const createSci = async (values, alternativeId, companyId) =>
  axios.post(
    process.env.REACT_APP_URL_SCI,
    { ...values, alternativeId },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const createSasu = async (values, alternativeId, companyId) =>
  axios.post(
    process.env.REACT_APP_URL_SASU,
    { ...values, alternativeId },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const createSas = async (values, alternativeId, companyId) =>
  axios.post(
    process.env.REACT_APP_URL_SAS,
    // process.env.REACT_APP_URL_CREATE_STATUS,
    { ...values, alternativeId },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const createSarl = async (values, alternativeId, companyId) =>
  axios.post(
    process.env.REACT_APP_URL_SARL,
    { ...values, alternativeId },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const genarateDocs = async (values, alternativeId, companyId) =>
  axios.put(
    process.env.REACT_APP_URL_GENERATE_DOCS,
    { ...values, alternativeId, companyId },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

const updateCompanyData = async (data, companyId, objectId) =>
  axios.put(`${process.env.REACT_APP_URL_UPDATE_COMPANY}/${objectId}`, data, {
    headers: {
      'X-Company-Id': companyId,
    },
  });

const fetchCreationCompany = (companyId) =>
  axios.get(`${process.env.REACT_APP_URL_UPDATE_COMPANY}/${companyId}`, {
    headers: {
      'X-Company-Id': companyId,
    },
  });

const sendEmail = async (emailInfo, companyId) =>
  axios.post(
    process.env.REACT_APP_URL_SEND_EMAIL,
    { ...emailInfo },
    {
      headers: {
        'X-Company-Id': companyId,
      },
    }
  );

export default {
  createForm,
  createEurl,
  createSasu,
  createSas,
  createSarl,
  createStatus,
  createSci,
  genarateDocs,
  updateCompanyData,
  fetchCreationCompany,
  sendEmail,
};
