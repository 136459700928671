import { EURL, SAS, SASU, SCI } from 'constants/companies';
import {
  BANK,
  NO,
  NOTARIE,
  BBF_NOTARIE,
  REQUIRED,
  YES,
  MULTI_PARTNER,
} from 'constants/usersContants';
import * as Yup from 'yup';

// change validation here
const validationSchema = () => {
  return Yup.object().shape({
    share_value: Yup.number()
      .required(REQUIRED)
      .min(1, "La valeur minimale d'une action est de 1 euro")
      .integer('Merci de saisir un nombre entier.')
      .when(
        ['alone', 'cash_funds_numbers', 'capitalChiffres'],
        (alone, cashFundsNumbers, capitalChiffres, schema) => {
          const minshareValue =
            alone === MULTI_PARTNER ? capitalChiffres : cashFundsNumbers;
          return minshareValue > 0
            ? schema.max(
                minshareValue,
                "La valeur unitaire d'une action doit être inférieure au capital de votre société."
              )
            : schema;
        }
      ),
    connait_depot_des_fonds: Yup.string().required(REQUIRED),
    depot_capital_chez: Yup.string().when(
      'connait_depot_des_fonds',
      (connait_depot_des_fonds) => {
        if (connait_depot_des_fonds === YES) {
          return Yup.string()
            .required('requis')
            .oneOf([NOTARIE, BANK, BBF_NOTARIE]);
        }
        return Yup.string();
      }
    ),
    banking_corporation_name: Yup.string().when(
      ['depot_capital_chez', 'connait_depot_des_fonds'],
      (depot_capital_chez, connait_depot_des_fonds) => {
        if (depot_capital_chez !== 'be_business_focus') {
          if (connait_depot_des_fonds === 'oui') {
            return Yup.string().required(REQUIRED);
          }
        }
        return Yup.string();
      }
    ),

    adresse_agence_bancaire: Yup.string().when(
      ['depot_capital_chez', 'connait_depot_des_fonds'],
      (depot_capital_chez, connait_depot_des_fonds) => {
        if (depot_capital_chez !== 'be_business_focus') {
          if (connait_depot_des_fonds === 'oui') {
            return Yup.string().required(REQUIRED);
          }
        }
        return Yup.string();
      }
    ),
    ville_agence_bancaire: Yup.string().when(
      ['depot_capital_chez', 'connait_depot_des_fonds'],
      (depot_capital_chez, connait_depot_des_fonds) => {
        if (depot_capital_chez !== 'be_business_focus') {
          if (connait_depot_des_fonds === 'oui') {
            return Yup.string().required(REQUIRED);
          }
        }
        return Yup.string();
      }
    ),
    code_postal_agence_bancaire: Yup.string().when(
      ['depot_capital_chez', 'connait_depot_des_fonds'],
      (depot_capital_chez, connait_depot_des_fonds) => {
        if (depot_capital_chez !== 'be_business_focus') {
          if (connait_depot_des_fonds === 'oui') {
            return Yup.string().required(REQUIRED);
          }
        }
        return Yup.string();
      }
    ),
    choix_is: Yup.string().when('eurl_sasu', {
      is: (eurl_sasu) => eurl_sasu === EURL,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string(),
    }),
    liberationPartielle: Yup.string().required(REQUIRED),
    pourcentOfRelease: Yup.number().when(['liberationPartielle', 'eurl_sasu'], {
      is: (liberationPartielle, eurl_sasu) =>
        liberationPartielle === YES && eurl_sasu !== EURL && eurl_sasu !== SASU,
      then: Yup.number()
        .required(REQUIRED)
        .max(100, 'Vous pouvez libérer au maximum 100%')
        .test({
          name: 'min',
          exclusive: false,
          params: {},
          // eslint-disable-next-line no-template-curly-in-string
          message: 'Merci de respecter le pourcentage minimum',
          // eslint-disable-next-line
          test: function (value) {
            if (this.parent.eurl_sasu === SCI) {
              return value >= 1;
            }
            if (
              this.parent.eurl_sasu === SAS ||
              this.parent.eurl_sasu === SASU
            ) {
              return value >= 50;
            }
            console.log('here 2');
            return value >= 20;
          },
        }),
    }),
    cash_funds_onetime_payment: Yup.string().required(REQUIRED),
    payments_amount: Yup.number().when('cash_funds_onetime_payment', {
      is: (cash_funds_onetime_payment) => cash_funds_onetime_payment === NO,
      then: Yup.number()
        .required(REQUIRED)
        .test({
          name: 'min',
          exclusive: false,
          params: {},
          // eslint-disable-next-line no-template-curly-in-string
          message: 'Merci de respecter le montant minimum',
          // eslint-disable-next-line
          test: function (value) {
            if (
              this.parent.eurl_sasu === SAS ||
              this.parent.eurl_sasu === SASU
            ) {
              return value >= this.parent.capitalChiffres / 2;
            }
            return value >= this.parent.capitalChiffres / 5;
          },
        }),
    }),
  });
};

//  eslint-disable-next-line
export { validationSchema };
