import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { saveCompanyDataAndPassToNextState } from 'actions/monCompteActions';
import { KIT_BASIQUE_SCI, KIT_BASIQUE_SOC } from 'assets/data/kitConstants';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DocGenerateActions = () => {
  const [acceptBeforeValidate, setAcceptBeforeValidate] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();

  const { company } = useSelector((state) => state.monCompte);
  const { companyId, stateOfClientFolder, kit } = company;

  const validateDocs = () => {
    if (!acceptBeforeValidate) {
      setShowError(true);
      return;
    }
    const newState = {
      ...stateOfClientFolder,
      generateDocsValidate: true,
      stepper: 1,
    };
    dispatch(
      saveCompanyDataAndPassToNextState(
        { ...company, stateOfClientFolder: newState },
        companyId
      )
    );
  };

  return (
    <>
      {stateOfClientFolder.stepper === 0 &&
        kit !== KIT_BASIQUE_SCI &&
        kit !== KIT_BASIQUE_SOC && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptBeforeValidate}
                  onChange={() => {
                    setShowError(false);
                    setAcceptBeforeValidate(!acceptBeforeValidate);
                  }}
                  name="acceptTerm2"
                  color="primary"
                />
              }
              label="Je confirme que les documents sont conformes à ma situation et à mes attentes. Ils ne sont plus modifiables"
            />
            {showError && (
              <span style={{ color: 'red', display: 'block' }}>
                Vous devez accepter que les fichiers sont conformes et ne seront
                plus modifiables
              </span>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={validateDocs}
              style={{ width: '100%' }}
            >
              Valider les documents
            </Button>
          </>
        )}
    </>
  );
};

export default DocGenerateActions;
