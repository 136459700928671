import { useReducer, useEffect } from 'react';

function useLocalStorageReducer(key, reducer, defaultValue) {
  const { kit } = defaultValue;
  const currentValue = window.localStorage.getItem(key);

  const [state, dispatch] = useReducer(reducer, defaultValue, () => {
    let value;
    try {
      value = JSON.parse({ ...currentValue, kit } || String(defaultValue));
    } catch (e) {
      value = defaultValue;
    }
    return value;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, dispatch];
}

export default useLocalStorageReducer;
