import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  MenuItem,
} from '@material-ui/core';

import { TextField, RadioGroup, Select } from 'formik-material-ui';
// import { Select } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
// import KeyboardDatePicker from 'components/KeyboardDatePicker';

import FormControl from '@material-ui/core/FormControl';

// import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import Paper from '../surface/Paper';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '90ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '80ch',
  },
  radio: {},
}));

const DecisionsAssembleeGenerale = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  {' '}
                  Les decisions de l&apos;assemblée générale{' '}
                </Typography>
              </Box>

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Nombre minimum de réunions de l'assemblée générale par an"
                  name="nombre_reunions_assemblee_generale"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Nombre maximum de procurations dont dispose chaque membre de l'association"
                  name="nombre_maximum_procuration_membre_association"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box className={classes.root}>
                <Field component={RadioGroup} name="condition_quorum_assemblee">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      L&apos;assemblée peut déliberer sans condition de quorum
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>

              {values.condition_quorum_assemblee === 'non' && (
                <Box className={classes.root}>
                  <FormControl>
                    <Typography variant="subtitle1">
                      Le quorum est de
                    </Typography>
                    {'   '}
                    <Field
                      component={Select}
                      label=""
                      name="quorum_deliberation_assemblee"
                      inputProps={{
                        id: 'quorum_deliberation_assemblee',
                      }}
                    >
                      <MenuItem value={'1/4'}>
                        1/4 de ses membres présents ou représentés
                      </MenuItem>
                      <MenuItem value={'1/3'}>
                        1/3 de ses membres présents ou représentés
                      </MenuItem>
                      <MenuItem value={'1/2'}>
                        1/2 de ses membres présents ou représentés
                      </MenuItem>
                      <MenuItem value={'3/4'}>
                        3/4 de ses membres présents ou représentés{' '}
                      </MenuItem>
                    </Field>
                  </FormControl>
                </Box>
              )}

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Nombre de jours préalables à la réunion de l'assemblée pour l'envoi des convocations"
                  name="jours_prealables_convocation"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

DecisionsAssembleeGenerale.propTypes = {
  nextForm: PropTypes.func,
};
export default DecisionsAssembleeGenerale;
