import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { fetchDocument, getUrlOfDocument } from 'helpers/AwsDocumentsHelper';
import ListOfDocs from 'components/monCompte/listOfDocs';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cloneDeep, isEmpty, groupBy } from 'lodash';
import {
  fetchCreationCompany,
  generateDocs,
  saveCompanyData,
} from 'actions/monCompteActions';
import Stepper from 'components/Stepper';
import InfoUser from 'components/InfoUser';
import DocGenerateActions from './clientActions/DocGenerateActions';
import DocUploadedActions from './clientActions/DocUploadedActions';

const useStyles = makeStyles(() => ({
  cardContainer: {
    justifyContent: 'center',
    marginBottom: '2em',
  },
  card: {
    padding: 30,
    height: '100%',
  },
  cardHeading: {
    fontWeight: '500',
    textAlign: 'center',
  },
  '@media (max-width: 960px)': {
    mainContainer: {
      display: 'flex',
      gap: '1em',
      flexDirection: 'column',
    },
    card: {
      padding: 5,
    },
  },
  '@media (max-width: 600px)': {
    stepper: {
      display: 'none',
    },
  },
}));

const ReviewScreen = ({
  documents: docs,
  companyId,
  setDialogVisible,
  setOpeningDocument,
  alternativeId,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const query = new URLSearchParams(window.location.search);
  const devMode = query.get('devMode') || false;
  const { company } = useSelector((state) => state.monCompte);
  const dispatch = useDispatch();
  let stepper = null;
  if (!isEmpty(company) && company.stateOfClientFolder) {
    const { stateOfClientFolder } = company;
    stepper = stateOfClientFolder.stepper;
  }
  const [generatedDocsWithNames, setGeneratedDocsWithNames] = useState([]);
  const [uploadByBBFDocsWithNames, setUploadByBBFDocsWithNames] = useState([]);
  const [uploadByClientDocsWithNames, setUploadByClientDocsWithNames] =
    useState([]);

  const [isDocumentToProvideValid, setDocumentToProvideValid] = useState(false);

  useEffect(() => {
    dispatch(fetchCreationCompany(companyId));
  }, []);

  useEffect(() => {
    if (
      // eslint-disable-next-line
      company.hasOwnProperty('stateOfClientFolder') &&
      !company.stateOfClientFolder.paid
    ) {
      history.push('/kit');
    }
  }, [company]);

  const getLatestDocuments = (documents) => {
    const groupedDocuments = groupBy([...documents], 'title');
    const documentsLastVersion = Object.keys(groupedDocuments).map((key) => {
      const lastIndex = groupedDocuments[key].length - 1;
      return groupedDocuments[key][lastIndex];
    });
    return documentsLastVersion;
  };

  useEffect(() => {
    if (docs && docs.length > 0) {
      const latestDocuments = getLatestDocuments(docs);

      setGeneratedDocsWithNames(
        latestDocuments.filter((doc) => doc.source === null)
      );
      setUploadByBBFDocsWithNames(
        latestDocuments.filter((doc) => doc.source === 'BBF')
      );
      setUploadByClientDocsWithNames(
        latestDocuments.filter((doc) => doc.source === 'CLIENT')
      );
    }
  }, [docs]);

  const downloadFile = async (file) => {
    const fileURL = await (
      await getUrlOfDocument(file.key, companyId)
    ).data.url;
    const content = await fetchDocument(fileURL);
    if (content) {
      const fileURLDownload = URL.createObjectURL(content);
      const link = document.createElement('a');
      link.href = fileURLDownload;
      link.setAttribute('download', `${file.title}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const setKeyToTheDocument = (key, name) => {
    let newDocuments = cloneDeep(docs);
    newDocuments = newDocuments.map((doc) => {
      if (doc.title === name) {
        // eslint-disable-next-line
        doc.key = key;
      }
      return doc;
    });
    return newDocuments;
  };

  const deleteKeyToTheDocument = (key) => {
    let newDocuments = cloneDeep(docs);
    newDocuments = newDocuments.map((doc) => {
      if (doc.key === key) {
        // eslint-disable-next-line
        doc.key = null;
      }
      return doc;
    });
    return newDocuments;
  };

  const saveKeyInDocument = (key, name) => {
    const documents = setKeyToTheDocument(key, name);
    dispatch(saveCompanyData({ ...company, documents }, companyId));
  };

  const removeKeyInDocument = (key) => {
    const documents = deleteKeyToTheDocument(key);
    return dispatch(saveCompanyData({ ...company, documents }, companyId));
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const regenerateDocs = async () => {
    await dispatch(
      generateDocs(company, company.alternativeId, company.companyId)
    );
  };

  return (
    <>
      {!isEmpty(company) && company.stateOfClientFolder && (
        <>
          {devMode && (
            <div>
              <div>DEV MODE</div>
              <Button
                variant="contained"
                color="primary"
                onClick={regenerateDocs}
              >
                Re-générer les documents
              </Button>
            </div>
          )}
          <Grid container className={(classes.cardContainer, classes.stepper)}>
            <Stepper />
          </Grid>
          <Grid container className={classes.cardContainer}>
            <InfoUser />
          </Grid>
          <Grid container className={classes.cardContainer}>
            <Grid item xs={12} md={8}>
              <Accordion
                expanded={
                  stepper === 0 || (stepper > 0 && expanded === 'panel1')
                }
                onChange={handleChange('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    sx={{ width: '33%', flexShrink: 0 }}
                    color="primary"
                    component="h1"
                    variant="h4"
                    className={classes.cardHeading}
                  >
                    Documents générés
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item xs={12}>
                    <ListOfDocs
                      documents={generatedDocsWithNames}
                      companyId={companyId}
                      setDialogVisible={setDialogVisible}
                      setOpeningDocument={setOpeningDocument}
                      downloadFile={downloadFile}
                      getUrlOfDocument={getUrlOfDocument}
                      alternativeId={alternativeId}
                      saveKeyInDocument={saveKeyInDocument}
                    />
                    <DocGenerateActions />
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {stepper > 0 && (
                <Accordion
                  expanded={stepper === 1 || expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                  disabled={stepper === 0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography
                      color="primary"
                      component="h1"
                      variant="h4"
                      className={classes.cardHeading}
                    >
                      Documents à fournir
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <ListOfDocs
                        documents={uploadByClientDocsWithNames}
                        companyId={companyId}
                        setDialogVisible={setDialogVisible}
                        setOpeningDocument={setOpeningDocument}
                        downloadFile={downloadFile}
                        getUrlOfDocument={getUrlOfDocument}
                        alternativeId={alternativeId}
                        saveKeyInDocument={saveKeyInDocument}
                        removeKeyInDocument={removeKeyInDocument}
                        fileValidation={true}
                        onFileValidationReturn={(isValid) => {
                          setDocumentToProvideValid(isValid);
                        }}
                      />
                      <DocUploadedActions
                        validation={isDocumentToProvideValid}
                        validationMessage={
                          'Merci de fournir tous les documents avant de valider'
                        }
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {stepper > 1 && (
                <Accordion
                  expanded={
                    stepper >= 2 || (stepper > 1 && expanded === 'panel3')
                  }
                  onChange={handleChange('panel3')}
                  disabled={stepper < 2}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography
                      color="primary"
                      component="h1"
                      variant="h4"
                      className={classes.cardHeading}
                    >
                      Documents fournis par BeBusinessFocus
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <ListOfDocs
                        documents={uploadByBBFDocsWithNames}
                        companyId={companyId}
                        setDialogVisible={setDialogVisible}
                        setOpeningDocument={setOpeningDocument}
                        downloadFile={downloadFile}
                        getUrlOfDocument={getUrlOfDocument}
                        alternativeId={alternativeId}
                        saveKeyInDocument={saveKeyInDocument}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ReviewScreen;
