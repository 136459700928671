import React from 'react';
import { RECAP, TYPE_OF_COMPANY } from 'constants/pages';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    textAlign: 'center',
    marginTop: '10%',
    marginBottom: '10%',
  },
});

function FormActions({ goBack, currentForm, disabled }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        type="button"
        value="BACK"
        onClick={goBack}
        variant="contained"
        style={{ color: 'darkgray' }}
        disabled={currentForm === TYPE_OF_COMPANY}
      >
        précédent
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={disabled}
      >
        {currentForm === RECAP ? 'Créer les documents' : 'Suivant'}
      </Button>
    </div>
  );
}

export default FormActions;
