import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  // MenuItem,
} from '@material-ui/core';

import { TextField, RadioGroup } from 'formik-material-ui';
// import { Select } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
import KeyboardDatePicker from 'components/KeyboardDatePicker';

// import FormControl from '@material-ui/core/FormControl';

// import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import Paper from '../surface/Paper';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '90ch',
  },
  nameTextFieldPetit: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '40ch',
  },
  radio: {},
}));

const DecisionsAssembleeGenerale = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  {' '}
                  Assemblée générale constitutive{' '}
                </Typography>
              </Box>
              <Box className={classes.root}>
                <Box className={classes.root}>
                  <Field
                    component={KeyboardDatePicker}
                    clearable
                    label="Date de l'assemblée générale constitutive"
                    placeholder="01/01/1990"
                    className={classes.birthdayField}
                    format="dd/MM/yyyy"
                    name="date_assemblee_generale_constitutive"
                    autoComplete="off"
                  />
                </Box>

                <Box alignSelf="center">
                  <Field
                    component={TextField}
                    type="string"
                    label="Heure de l'assemblée générale constitutive"
                    name="heure_assemblee_generale_constitutive"
                    placeholder="15h36"
                    className={classes.nameTextFieldPetit}
                    autoComplete="off"
                  />
                </Box>
              </Box>

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Adresse de la réunion de l'assemble générale constitutive"
                  name="adresse_assemblee_generale_constitutive"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Code postal de la réunion de l'assemble générale constitutive"
                  name="code_postal_assemblee_generale_constitutive"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Ville de la réunion de l'assemble générale constitutive"
                  name="ville_assemblee_generale_constitutive"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box className={classes.root}>
                <Field component={RadioGroup} name="feuille_presence">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Une feuille de présence à-t-elle été signée ?
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

DecisionsAssembleeGenerale.propTypes = {
  nextForm: PropTypes.func,
};
export default DecisionsAssembleeGenerale;
