import React, { Fragment, useContext } from 'react';

import { Box, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import { TextField } from 'formik-material-ui';
// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '50ch',
  },
}));

const InfoActesPrealable = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     contract1_date: Yup.date().required('un choix est necessaire'),
      //     contract1_type: Yup.string().required('un choix est necessaire'),
      //     contract1_amount: Yup.number().required('un choix est necessaire'),
      //     contract1_person_who_paid: Yup.string().required(
      //       'un choix est necessaire'
      //     ),
      //     contract2_date: Yup.date().required('un choix est necessaire'),
      //     contract2_type: Yup.string().required('un choix est necessaire'),
      //     contract2_amount: Yup.number().required('un choix est necessaire'),
      //     contract2_person_who_paid: Yup.string().required(
      //       'un choix est necessaire'
      //     ),
      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        // SciAutomate.next();
        // setAutomateState(SciAutomate.state);

        SciAutomate.nextInfoActesPrealablesVersChoixCommissaireAuxComptes();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                Deux actes préalablement à la création de la société{' '}
              </Typography>

              <Field
                component={KeyboardDatePicker}
                clearable
                label="Date du 1er acte"
                placeholder="01/01/1990"
                format="dd/MM/yyyy"
                name="contract1_date"
                className={classes.textField}
                autoComplete="off"
              />

              <Field
                component={TextField}
                type="string"
                label="Nature de l'acte n°1 "
                name="contract1_type"
                className={classes.textField}
                autoComplete="off"
              />
              <Field
                component={TextField}
                type="string"
                label="Montant"
                name="contract1_amount"
                className={classes.textField}
                autoComplete="off"
              />
              <Field
                component={TextField}
                type="string"
                label="Payé par"
                name="contract1_person_who_paid"
                className={classes.textField}
                autoComplete="off"
              />

              {formValues.number_of_preexisting_contract > 1 && (
                <Fragment>
                  <Field
                    component={KeyboardDatePicker}
                    clearable
                    label="Date du 2nd acte"
                    placeholder="01/01/1990"
                    format="dd/MM/yyyy"
                    name="contract2_date"
                    className={classes.textField}
                    autoComplete="off"
                  />

                  <Field
                    component={TextField}
                    type="string"
                    label="Nature de l'acte n°2 "
                    name="contract2_type"
                    className={classes.textField}
                    autoComplete="off"
                  />

                  <Field
                    component={TextField}
                    type="string"
                    label="Montant"
                    name="contract2_amount"
                    className={classes.textField}
                    autoComplete="off"
                  />
                  <Field
                    component={TextField}
                    type="string"
                    label="Payé par"
                    name="contract2_person_who_paid"
                    className={classes.textField}
                    autoComplete="off"
                  />
                </Fragment>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoActesPrealable.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoActesPrealable;
