export const EDIT_COMPANY = 'EDIT_COMPANY';
export const ADD_PARTNER = 'ADD_PARTNER';
export const ADD_PRESENT = 'ADD_PRESENT';
export const ADD_REPRESENTE = 'ADD_REPRESENTE';
export const REMOVE_PARTNER = 'REMOVE_PARTNER';
export const EDIT_PARTNER = 'EDIT_PARTNER';
export const DELETE_PM_VALUES = 'DELETE_PM_VALUES';
export const DELETE_PP_VALUES = 'DELETE_PP_VALUES';
export const EDIT_PRESENT = 'EDIT_PRESENT';
export const EDIT_REPRESENTE = 'EDIT_REPRESENTE';
export const PARTNER_INDEX = 'PARTNER_INDEX';
export const PRESENT_INDEX = 'PRESENT_INDEX';
export const REPRESENTE_INDEX = 'REPRESENTE_INDEX';
export const SET_SIGNUPCOMPANY = 'SET_SIGNUPCOMPANY';
export const CREATE_PROJECT_STATUS_FAILED = 'CREATE_PROJECT_STATUS_FAILED';
export const EDIT_COMPANY_PROCESSING = 'EDIT_COMPANY_PROCESSING';
export const RESET_FORM = 'RESET_FORM';

export const GO_BACK = 'GO_BACK';
export const ADD_IN_HISTORY = 'ADD_IN_HISTORY';
export const SET_CURRENT_FORM = 'SET_CURRENT_FORM';
export const SET_PARTNER_ID = 'SET_PARTNER_ID';
