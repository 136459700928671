import { NO } from 'constants/usersContants';

const initialValues = (values) => {
  return {
    dgPartnerIDs: values.dgPartnerIDs || [],
    presence_dg: values.presence_dg || NO,
    partners: values.partners || NO,
    dg_type: values.dg_type || '',
    dg_title: values.dg_title || '',
    dg_name: values.dg_name || '',
    dg_firstname: values.dg_firstname || '',
    dg_email: values.dg_email || '',
    dg_date_of_birth: values.dg_date_of_birth || null,
    dg_place_of_birth: values.dg_place_of_birth || '',
    dg_place_of_birth_zipCode: values.dg_place_of_birth_zipCode || '',
    dg_state_of_birth: values.dg_state_of_birth || '',
    dg_country_of_birth: values.dg_country_of_birth || '',
    dg_nationality: values.dg_nationality || '',
    dg_place_of_living: values.dg_place_of_living || '',
    dg_city: values.dg_city || '',
    dg_zipcode: values.dg_zipcode || '',
    dg_father_name: values.dg_father_name || '',
    dg_father_firstname: values.dg_father_firstname || '',
    dg_mother_name: values.dg_mother_name || '',
    dg_mother_firstname: values.dg_mother_firstname || '',
    dg_secu_number: values.dg_secu_number || '',

    // info spouce of director
    dg_family_situation: values.dg_family_situation || '',

    // info wedding
    dg_wedding_prenup_type: values.dg_wedding_prenup_type || '',
    dg_wedding_prenup: values.dg_wedding_prenup || '',
    // dg_wedding_prenup = yes
    dg_wedding_prenup_date: values.dg_wedding_prenup_date || '',
    dg_wedding_prenup_place: values.dg_wedding_prenup_place || '',
    dg_wedding_prenup_officer_office_name:
      values.dg_wedding_prenup_officer_office_name || '',
    dg_wedding_prenup_officer_office_address:
      values.dg_wedding_prenup_officer_office_address || '',
    dg_wedding_prenup_officer_office_city:
      values.dg_wedding_prenup_officer_office_city || '',
    dg_wedding_prenup_officer_office_zipcode:
      values.dg_wedding_prenup_officer_office_zipcode || '',
    dg_wedding_prenup_officer_office_additional_address:
      values.dg_wedding_prenup_officer_office_additional_address || '',
    // info spouce
    dg_spouce_title: values.dg_spouce_title || '',
    dg_spouce_name: values.dg_spouce_name || '',
    dg_spouce_firstname: values.dg_spouce_firstname || '',
    dg_spouce_email: values.dg_spouce_email || '',
    dg_spouce_secu_number: values.dg_spouce_secu_number || '',
    dg_spouce_birthday: values.dg_spouce_birthday || null,
    dg_spouce_place_of_birth: values.dg_spouce_place_of_birth || '',
    dg_spouce_adress: values.dg_spouce_adress || '',
    dg_spouce_city: values.dg_spouce_city || '',
    dg_spouce_zipcode: values.dg_spouce_zipcode || '',
    dg_spouce_additional_adress: values.dg_spouce_additional_adress || '',
    dg_spouce_effective_date: values.dg_spouce_effective_date || null,
    dg_spouce_position: values.dg_spouce_position || '',

    // info pacse
    dg_pacs_type: values.dg_pacs_type || '',
    dg_pacse_title: values.dg_pacse_title || '',
    dg_pacse_name: values.dg_pacse_name || '',
    dg_pacse_firstname: values.dg_pacse_firstname || '',
    dg_pacse_email: values.dg_pacse_email || '',
    dg_pacse_secu_number: values.dg_pacse_secu_number || '',
    dg_pacse_birthday: values.dg_pacse_birthday || null,
    dg_pacse_place_of_birth: values.dg_pacse_place_of_birth || '',
    dg_pacse_adress: values.dg_pacse_adress || '',
    dg_pacse_city: values.dg_pacse_city || '',
    dg_pacse_zipcode: values.dg_pacse_zipcode || '',
    dg_pacse_additional_adress: values.dg_pacse_additional_adress || '',
    dg_pacse_effective_date: values.dg_pacse_effective_date || null,
    dg_pacse_position: values.dg_pacse_position || '',

    // info cohabitant
    dg_cohabitant_title: values.dg_cohabitant_title || '',
    dg_cohabitant_name: values.dg_cohabitant_name || '',
    dg_cohabitant_firstname: values.dg_cohabitant_firstname || '',
    dg_cohabitant_email: values.dg_cohabitant_email || '',
    dg_cohabitant_secu_number: values.dg_cohabitant_secu_number || '',
    dg_cohabitant_birthday: values.dg_cohabitant_birthday || null,
    dg_cohabitant_place_of_birth: values.dg_cohabitant_place_of_birth || '',
    dg_cohabitant_adress: values.dg_cohabitant_adress || '',
    dg_cohabitant_city: values.dg_cohabitant_city || '',
    dg_cohabitant_zipcode: values.dg_cohabitant_zipcode || '',
    dg_cohabitant_additional_adress:
      values.dg_cohabitant_additional_adress || '',
    dg_cohabitant_effective_date: values.dg_cohabitant_effective_date || null,
    dg_cohabitant_position: values.dg_cohabitant_position || '',
  };
};

// eslint-disable-next-line
export { initialValues };
