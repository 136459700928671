import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { Field, Formik, Form } from 'formik';
import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

import { RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';
import Paper from 'components/surface/Paper';
import ButtonBox from '../entreprise/ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles({
  root: {
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  title: {
    justifyContent: 'center',
  },
});

const SignupSchema = Yup.object().shape({
  eurl_sasu: Yup.string().required('un choix est necessaire'),
});

const ChoixQuelleModification = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  //   let eurlOuSarlValue = 'eurl';
  //   let eurlOuSarlLabel = 'EURL';

  //   let sasuouSasValue = 'sasu';
  //   let sasuouSasLabel = 'SASU';

  //   if (formValues.nom_propre === 'nom_propre') {
  //     sasuouSasValue = 'independant';
  //     sasuouSasLabel = 'Indépendant, en nom propre';

  //     eurlOuSarlValue = 'eirl';
  //     eurlOuSarlLabel = 'EIRL';
  //   }

  //   if (formValues.alone === 'a_plusieurs') {
  //     eurlOuSarlValue = 'sarl';
  //     eurlOuSarlLabel = 'SARL';

  //     sasuouSasValue = 'sas';
  //     sasuouSasLabel = 'SAS';
  //   }

  return (
    <Formik
      initialValues={formValues}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);

        Automate.next();
        setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.title}
            >
              <Typography variant="h6">
                Modifier ma situation ou mes statuts
              </Typography>
            </Box>
            {/* 
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            > */}

            <Field component={RadioGroup} name="modification">
              <Box display="flex" alignSelf="center" flexDirection="row">
                <Box display="flex" flexDirection="column">
                  <FormControlLabel
                    value="gerant"
                    control={<Radio />}
                    label="Changement de Gérant"
                  />

                  <FormControlLabel
                    value="president"
                    control={<Radio />}
                    label="Changement de Président"
                  />

                  <FormControlLabel
                    value="president"
                    control={<Radio />}
                    label="Changement de siège social"
                  />

                  <FormControlLabel
                    value="objet_social"
                    control={<Radio />}
                    label="Changement d'objet social"
                  />

                  <FormControlLabel
                    value="denomination_sociale"
                    control={<Radio />}
                    label="Changement de dénomination sociale"
                  />
                </Box>

                <Box display="flex" flexDirection="column">
                  <FormControlLabel
                    value="augmentationCapital"
                    control={<Radio />}
                    label="Augmentation de capital"
                  />

                  <FormControlLabel
                    value="reductionCapital"
                    control={<Radio />}
                    label="Réduction de capital"
                  />

                  <FormControlLabel
                    value="transformationSarlSas"
                    control={<Radio />}
                    label="Transformation de SARL en SAS"
                  />

                  <FormControlLabel
                    value="transformationSasSarl"
                    control={<Radio />}
                    label="Transformation de SAS en SARL"
                  />
                </Box>
              </Box>
            </Field>
            {/* </Box> */}
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixQuelleModification.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixQuelleModification;
