import { configureStore } from '@reduxjs/toolkit';
import reducer from 'reducers/monCompteReducer';
import appReducer from 'reducers/appReducer';
import userReducer from 'reducers/loggedUserReducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';

// export default configureStore({
//   reducer: {
//     monCompte: counterReducer,
//   },
// });

const reducers = combineReducers({
  monCompte: reducer,
  app: appReducer,
  loggedUser: userReducer,
});

const persistConfig = {
  timeout: 200,
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  // blacklist: ['monCompte'],
  whitelist: ['app', 'monCompte', 'loggedUser'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;
