const prepareData = (values) => {
  const form = { ...values };
  form.partner_supplies_funds_numbers =
    form.partner_valeur_apport_fonds_de_commerce +
    form.partner_valeur_apport_titres_societe +
    form.partner_valeur_apport_nature_divers;
  return form;
};

export default prepareData;
