import React, { useEffect } from 'react';
import {
  headOfficeLocations,
  headOfficeLocationsPresident,
  HEAD_OFFICE_AT_SEO,
  HEAD_OFFICE_IN_LOCAL,
  NO,
  OWNER,
  proprietaireLocataire,
  TENANT,
  yesNo,
  HEAD_OFFICE_IN_DOMICILIATION,
} from 'constants/usersContants';
import { useFormikContext } from 'formik';
import HelpWithTitle from 'components/commons/HelpWithTitle';
import { SAS, SASU, SCI } from 'constants/companies';
import useStyles from '../style';
import SmallRadio from '../commons/smallRadio';
import InfoSyndic from './InfoSyndic';
import InfoBailleur from './InfoBailleur';
import { cleanInfoBailleur } from './HeadOfficeHelper';
import PartnerCard from '../commons/partnerCard';

function HeadOfficeInfo() {
  const classes = useStyles();
  const {
    values: {
      eurl_sasu,
      siege_social_chez,
      proprietaire,
      maison_individuelle,
      partners,
      associe_gerant_multiassocie,
      idGerantForDomiciliation,
    },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (siege_social_chez === HEAD_OFFICE_IN_DOMICILIATION) {
      cleanInfoBailleur(setFieldValue);
    }
  }, [siege_social_chez]);
  const isOwner = (partner) => {
    if (partner) {
      setFieldValue('email_bailleur', partner.partner_email);
      setFieldValue('prenom_bailleur', partner.partner_firstname);
      setFieldValue('nom_bailleur', partner.partner_name);
      setFieldValue(
        'adresse_bailleur',
        `${partner.partner_place_of_living} ${partner.partner_zipcode} ${partner.partner_city}`
      );
      setFieldValue('adresse_bailleur', partner.partner_place_of_living);
      setFieldValue('bailleur_city', partner.partner_city);
      setFieldValue('bailleur_zipcode', partner.partner_zipcode);
      setFieldValue('idGerantForDomiciliation', partner.id);
    }
  };

  const getSubtitle = () => {
    switch (eurl_sasu) {
      case SASU:
      case SAS:
        return 'président';
      case SCI:
        return 'associé';
      default:
        return 'cogérant';
    }
  };

  const showPartnerList = () => {
    if (
      siege_social_chez === HEAD_OFFICE_AT_SEO &&
      associe_gerant_multiassocie.length > 1 &&
      proprietaire === OWNER
    )
      return true;
    if (
      siege_social_chez === HEAD_OFFICE_IN_LOCAL &&
      partners.length > 1 &&
      proprietaire === OWNER
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      idGerantForDomiciliation &&
      !associe_gerant_multiassocie.includes(idGerantForDomiciliation)
    ) {
      setFieldValue('idGerantForDomiciliation', associe_gerant_multiassocie[0]);
      const partner = partners.find(
        (item) => item.id === associe_gerant_multiassocie[0]
      );
      isOwner(partner);
    }
  }, [associe_gerant_multiassocie]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{`Le siège de l'entreprise`}</h1>
      <div className={classes.root}>
        <HelpWithTitle
          title="Aide sur Le siège de l'entreprise."
          helpName="siege_social_chez"
        />
        <SmallRadio
          classes={classes}
          title="Le siège social se situe :"
          options={
            eurl_sasu === SASU || eurl_sasu === SAS
              ? headOfficeLocationsPresident
              : headOfficeLocations
          }
          value={siege_social_chez}
          name="siege_social_chez"
          style={{ marginBottom: '5%' }}
        />
        {(siege_social_chez === HEAD_OFFICE_AT_SEO ||
          siege_social_chez === HEAD_OFFICE_IN_LOCAL) && (
          <>
            <SmallRadio
              classes={classes}
              title="Vous êtes :"
              options={proprietaireLocataire}
              value={proprietaire}
              name="proprietaire"
              style={{ marginBottom: '5%' }}
            />
            {proprietaire === OWNER &&
              siege_social_chez === HEAD_OFFICE_AT_SEO && (
                <>
                  <SmallRadio
                    classes={classes}
                    title="Le siège social est-il dans une maison individuelle :"
                    options={yesNo}
                    value={maison_individuelle}
                    name="maison_individuelle"
                    style={{ marginBottom: '5%' }}
                  />
                  {maison_individuelle === NO && (
                    <>
                      <h2 className={classes.subTitle}>
                        Information sur le syndic de copropriété
                      </h2>
                      <InfoSyndic />
                    </>
                  )}
                </>
              )}
            {proprietaire === TENANT &&
              (siege_social_chez === HEAD_OFFICE_AT_SEO ||
                siege_social_chez === HEAD_OFFICE_IN_LOCAL) && (
                <>
                  <h2 className={classes.subTitle}>Information du bailleur</h2>
                  <InfoBailleur />
                </>
              )}
            {showPartnerList() && (
              <>
                <h2 className={classes.subTitle}>
                  Sélectionner chez quel {getSubtitle()} est domiciliée la
                  société.
                </h2>
                {partners.map((partner) => {
                  if (
                    associe_gerant_multiassocie.includes(partner.id) ||
                    siege_social_chez === HEAD_OFFICE_IN_LOCAL
                  ) {
                    return (
                      <PartnerCard
                        key={partner.id}
                        partner={partner}
                        handleClick={() => isOwner(partner)}
                        canEdit={false}
                        isSelected={partner.id === idGerantForDomiciliation}
                      />
                    );
                  }
                  return null;
                })}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default HeadOfficeInfo;
