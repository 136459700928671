import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ReviewScreen from 'components/monCompte/reviewScreen';
import { useSelector } from 'react-redux';
import PreviewFileDialog from 'components/monCompte/previewFileDialog';
import SnackBarV2 from 'components/commons/SnackBarV2';

const MonCompte = () => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [openingDocument, setOpeningDocument] = useState(null);
  const { company, snackbarMessage } = useSelector((state) => state.monCompte);
  const { companyId, alternativeId } = useSelector((state) => state.app);
  const { documents } = company;

  useEffect(() => {
    if (snackbarMessage.message) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [snackbarMessage]);

  return (
    <>
      <PreviewFileDialog
        openingDocument={openingDocument}
        dialogVisible={dialogVisible}
        setDialogVisible={setDialogVisible}
        setOpeningDocument={setOpeningDocument}
      />
      <Grid container spacing={3}>
        <ReviewScreen
          documents={documents}
          companyId={companyId}
          setDialogVisible={setDialogVisible}
          setOpeningDocument={setOpeningDocument}
          alternativeId={alternativeId}
        />
      </Grid>
      <SnackBarV2 />
    </>
  );
};

MonCompte.propTypes = {};
export default MonCompte;
