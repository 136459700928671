import React from 'react';
import { YES, yesNo } from 'constants/usersContants';
import { TextField } from 'formik-material-ui';
import { Field, useFormikContext } from 'formik';
// import KeyboardDatePicker from 'components/KeyboardDatePicker';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import useStyles from '../style';
import SmallRadio from '../commons/smallRadio';

const CompanyContribution = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const {
    apport_nature,
    apport_fonds_de_commerce,
    apport_titres_societe,
    apport_nature_divers,
    apport_industrie,
  } = values;

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        Les apports à la société en création par l’associé(e)
      </h1>
      <div className={classes.root}>
        <Field
          width="100%"
          component={TextField}
          type="number"
          label="Apports en numéraire - montant en chiffres "
          name="cash_funds_numbers"
          className={classes.nameTextField}
          autoComplete="off"
          variant="outlined"
          size="small"
        />

        <SmallRadio
          classes={classes}
          title="Y a-t-il un apport en nature ?"
          options={yesNo}
          value={apport_nature}
          name="apport_nature"
          style={{ marginBottom: '4%' }}
        />
        {apport_nature === YES && (
          <div className={classes.subCategories}>
            <SmallRadio
              classes={classes}
              title="Y a-t-il un apport de fonds de commerce ?"
              options={yesNo}
              value={apport_fonds_de_commerce}
              name="apport_fonds_de_commerce"
              style={{ marginBottom: '4%' }}
            />
            {apport_fonds_de_commerce === YES && (
              <>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de l'apport"
                  placeholder="01/01/1990"
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  name="date_apport_fonds_de_commerce"
                  className={classes.nameTextField}
                  inputVariant="outlined"
                  size="small"
                />

                <Field
                  component={TextField}
                  type="number"
                  label="Evaluation de l'apport - montant en chiffres"
                  name="valeur_apport_fonds_de_commerce"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
              </>
            )}
            <SmallRadio
              classes={classes}
              title="Un apport de titres de société ?"
              options={yesNo}
              value={apport_titres_societe}
              name="apport_titres_societe"
              style={{ marginBottom: '4%' }}
            />
            {apport_titres_societe === YES && (
              <>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de l'apport"
                  // placeholder="01/01/1990"
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  name="date_apport_titres_societe"
                  className={classes.nameTextField}
                  inputVariant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Evaluation de l'apport - montant en chiffres"
                  name="valeur_apport_titres_societe"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Raison sociale de la société apportée"
                  name="raison_sociale_societe_apport_titres_societe"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Nombre de titres apportés"
                  name="nombre_titres_apportes_apport_titres_societe"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Nombre de titres total composant le capital de la société apportée"
                  name="nombre_titres_total_apport_titres_societe"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
              </>
            )}
            <SmallRadio
              classes={classes}
              title="Y a-t-il des apports en nature de véhicule, matériel informatique, site internet etc...?"
              options={yesNo}
              value={apport_nature_divers}
              name="apport_nature_divers"
              style={{ marginBottom: '4%' }}
            />
            {apport_nature_divers === YES && (
              <>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de l'apport"
                  placeholder="01/01/1990"
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  name="date_apport_nature_divers"
                  className={classes.nameTextField}
                  inputVariant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Evaluation de l'apport en nature divers - montant en chiffres"
                  name="valeur_apport_nature_divers"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Veuillez décrire les biens apportés"
                  name="description_biens_apport_nature_divers"
                  className={classes.nameTextField}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  multiline
                  rows="4"
                />
              </>
            )}
          </div>
        )}
        <SmallRadio
          classes={classes}
          title="Y a-t-il des apports en industrie ?"
          options={yesNo}
          value={apport_industrie}
          name="apport_industrie"
          style={{ marginBottom: '4%' }}
        />
        {apport_industrie === YES && (
          <>
            <Field
              component={TextField}
              type="number"
              label="Apports en industrie - montant en chiffres "
              name="expertise_funds_numbers"
              className={classes.nameTextField}
              variant="outlined"
              size="small"
              autoComplete="off"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyContribution;
