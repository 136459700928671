import React, { useContext } from 'react';

// import defaultForm from 'models/initial.context';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import MonProjetAutoEntreprise from 'components/autoentrepreneur/MonProjetAutoEntreprise';
import InfoPersonnelles from 'components/autoentrepreneur/InfoPersonnelles';
import MesVoletsSocialEtFiscal from 'components/autoentrepreneur/MesVoletsSocialEtFiscal';
import FinalDeMaDeclaration from 'components/autoentrepreneur/FinalDeMaDeclaration';
import ListeDesPiecesJointes from 'components/autoentrepreneur/ListeDesPiecesJointes';
import { Button, Typography } from '@material-ui/core';

import { FormContext } from '../contexts/autoentrepreneur.context';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    justifyContent: 'center',
    width: '100%',
  },
}));

//    CVC
const getSteps = () => {
  return [
    "Mon projet d'auto-entreprise",
    'Mes informations personnelles',
    'Mes volets social et fiscal',
    'Finalisation de ma déclaration',
    'Récapitulatif de ma déclaration',
    'Liste des pièces jointes',
    'Validation de ma déclaration',
  ];
};

const getStepContent = (
  stepIndex,
  setActiveStep,
  state,
  isLastStep,
  handleBack
) => {
  switch (stepIndex) {
    case 0:
      return (
        <MonProjetAutoEntreprise
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
        />
      );
    case 1:
      return (
        <InfoPersonnelles
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
        />
      );
    case 2:
      return (
        <MesVoletsSocialEtFiscal
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
        />
      );
    case 3:
      return (
        <FinalDeMaDeclaration
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
        />
      );
    case 4:
      return 'Récapitulatif de ma déclaration';
    case 5:
      return (
        <ListeDesPiecesJointes
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
        />
      );
    case 6:
      return 'Validation de ma déclaration';
    default:
      return 'Unknown stepIndex';
  }
};

const AutoentrepreneurForm = () => {
  // const dispatch = useContext(DispatchContext);
  const formContext = useContext(FormContext);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const isLastStep = activeStep === steps.length - 1;

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {getStepContent(
              activeStep,
              setActiveStep,
              formContext,
              isLastStep,
              handleBack
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default AutoentrepreneurForm;
