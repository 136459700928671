import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
// import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import { Formik, Form } from 'formik';
// import axios from 'axios';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from 'store/monCompteStore';
import defaultForm from 'models/initial.context';
import { FormContext, DispatchContext } from '../contexts/partners.context';

import ChoixNomPropreOuSociete from '../components/entreprise/ChoixNomPropreOuSociete';
import ChoixSeulOuAPlusieurs from '../components/entreprise/ChoixSeulOuAPlusieurs';

import ChoixNombrePartenairesOuTypePartenaire from '../components/entreprise/ChoixNombrePartenairesOuTypePartenaire';
import ChoixTypeSociete from '../components/entreprise/ChoixTypeSociete';
import InfoSociete from '../components/entreprise/InfoSociete';
import InfoSociete2 from '../components/entreprise/InfoSociete2';
import InfoSociete3 from '../components/entreprise/InfoSociete3';
import InfoPersonneMorale from '../components/entreprise/InfoPersonneMorale';
import InfoPartenaire from '../components/entreprise/InfoPartenaire';
import ChoixSituationFamiliale from '../components/entreprise/ChoixSituationFamiliale';
import InfoMariage from '../components/entreprise/InfoMariage';
import ChoixContratDeMariage from '../components/entreprise/ChoixContratDeMariage';
import InfoContratDeMariage from '../components/entreprise/InfoContratDeMariage';
import InfoPacs from '../components/entreprise/InfoPacs'; /** box 11 and 12 are for the "pacsé" path */
import ChoixTypeDePacs from '../components/entreprise/ChoixTypeDePacs';
import InfoApportsPartenaire from '../components/entreprise/InfoApportsPartenaire';
import InfoLiberationPartenaire from '../components/entreprise/InfoLiberationPartenaire';
import ChoixPartenaireSupplementaire from '../components/entreprise/ChoixPartenaireSupplementaire';
import InfoCapitalDeSociete from '../components/entreprise/InfoCapitalDeSociete';
import InfoNotaireBbf from '../components/entreprise/InfoNotaireBbf';
import InfoApportsDeSociete from '../components/entreprise/InfoApportsDeSociete';
import ChoixLiberationIntegraleOuPartielle from '../components/entreprise/ChoixLiberationIntegraleOuPartielle';
import InfoMontantDesApports from '../components/entreprise/InfoMontantDesApports'; /** here is the path for 'liberer la totalité des apports en numéraire: non' */
import InfoGerant from '../components/entreprise/InfoGerant'; /** here is the path if "le gerant de la société est un tiers" */
import ChoixDuGerant from '../components/entreprise/ChoixDuGerant';
import ChoixDuDG from '../components/entreprise/ChoixDuDG';
import ChoixNombreActesPrealables from '../components/entreprise/ChoixNombreActesPrealables';
import InfoActesPrealable from '../components/entreprise/InfoActesPrealable';
import ChoixCommissaireAuxComptes from '../components/entreprise/ChoixCommissaireAuxComptes';
import InfoCommissaireAuxComptes from '../components/entreprise/InfoCommissaireAuxComptes';
import ChoixProcurationBBF from '../components/entreprise/ChoixProcurationBBF';
import ListeFin from '../components/entreprise/ListeFin';
import MonCompte from '../components/monCompte/MonCompte';

import naturalPersonModel from '../models/physical_person';
import companyModel from '../models/company';

import Automate from '../helpers/Automate';

import { RESET_FORM } from '../constants/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    justifyContent: 'center',
    width: '100%',
  },
}));

//    CVC

const getSteps = () => {
  return [
    'Informations de base',
    'Informations partenaires',
    'Informations financières',
    'Informations administratives',
    'Le résumé',
  ];
};

function getStepContent(
  stepIndex,
  setActiveStep,
  state,
  isLastStep,
  handleBack,
  handleReset,
  automateState,
  setAutomateState,
  partnerIndex,
  setPartnerIndex,
  isRecap,
  setIsRecap,
  checkInfosBase,
  setCheckInfosBase,
  checkInfosPartners,
  setCheckInfosPartners,
  checkInfoFinancieres,
  setCheckInfoFinancieres,
  checkInfosAdministratives,
  setCheckInfosAdministratives,
  partnerGerant,
  setPartnerGerant,
  demandeSiReset,
  setDemandeSiReset
) {
  switch (Automate.state) {
    case 'choix_nom_propre_ou_societe':
      return (
        <ChoixNomPropreOuSociete
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    case 'choix_seul_ou_a_plusieurs':
      return (
        <ChoixSeulOuAPlusieurs
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          handleReset={handleReset}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
          demandeSiReset={demandeSiReset}
          setDemandeSiReset={setDemandeSiReset}
        />
      );

    case 'choix_type_societe':
      return (
        <ChoixTypeSociete
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_societe':
      return (
        <InfoSociete
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_societe2':
      return (
        <InfoSociete2
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_societe3':
      return (
        <InfoSociete3
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_nombre_partenaires_ou_type_partenaire': {
      const { partners } = state;
      const partner = partners[partnerIndex] || companyModel;
      return (
        <ChoixNombrePartenairesOuTypePartenaire
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'info_personne_morale': {
      const { partners } = state;
      const partner = partners[partnerIndex] || companyModel;
      return (
        <InfoPersonneMorale
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'info_partenaire': {
      const { partners } = state;
      const partner = partners[partnerIndex] || naturalPersonModel;
      return (
        <InfoPartenaire
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'choix_situation_familiale': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ChoixSituationFamiliale
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }
    case 'info_mariage': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <InfoMariage
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'choix_contrat_de_mariage': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ChoixContratDeMariage
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'info_contrat_de_mariage': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <InfoContratDeMariage
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }
    case 'info_pacs': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <InfoPacs
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }
    case 'choix_type_de_pacs': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ChoixTypeDePacs
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }
    case 'info_apports_partenaire': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <InfoApportsPartenaire
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }
    case 'info_liberation_partenaire': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <InfoLiberationPartenaire
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'choix_partenaire_supplementaire':
      return (
        <ChoixPartenaireSupplementaire
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_capital_de_societe':
      return (
        <InfoCapitalDeSociete
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_notaire_bbf':
      return (
        <InfoNotaireBbf
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_apports_de_societe':
      return (
        <InfoApportsDeSociete
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_liberation_integrale_ou_partielle':
      return (
        <ChoixLiberationIntegraleOuPartielle
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_montant_des_apports':
      return (
        <InfoMontantDesApports
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_du_gerant': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ChoixDuGerant
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
          partnerGerant={partnerGerant}
          setPartnerGerant={setPartnerGerant}
        />
      );
    }
    case 'choix_du_dg': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ChoixDuDG
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
          partnerGerant={partnerGerant}
          setPartnerGerant={setPartnerGerant}
        />
      );
    }

    case 'info_gerant':
      return (
        <InfoGerant
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_nombre_actes_prealables':
      return (
        <ChoixNombreActesPrealables
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
          partnerGerant={partnerGerant}
          setPartnerGerant={setPartnerGerant}
        />
      );

    case 'info_actes_prealable':
      return (
        <InfoActesPrealable
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_commissaire_aux_comptes':
      return (
        <ChoixCommissaireAuxComptes
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_commissaire_aux_comptes':
      return (
        <InfoCommissaireAuxComptes
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_procuration_bbf':
      return (
        <ChoixProcurationBBF
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'liste_fin': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      const persistor = persistStore(store);
      return (
        <PersistGate loading={null} persistor={persistor}>
          <ListeFin
            formValues={state}
            partner={partner}
            activeStep={stepIndex}
            isLastStep={isLastStep}
            handleBack={handleBack}
            setActiveStep={setActiveStep}
            automateState={automateState}
            setAutomateState={setAutomateState}
            partnerIndex={partnerIndex}
            setPartnerIndex={setPartnerIndex}
            isRecap={isRecap}
            setIsRecap={setIsRecap}
            checkInfosBase={checkInfosBase}
            setCheckInfosBase={setCheckInfosBase}
            checkInfosPartners={checkInfosPartners}
            setCheckInfosPartners={setCheckInfosPartners}
            checkInfoFinancieres={checkInfoFinancieres}
            setCheckInfoFinancieres={setCheckInfoFinancieres}
            checkInfosAdministratives={checkInfosAdministratives}
            setCheckInfosAdministratives={setCheckInfosAdministratives}
          />
        </PersistGate>
      );
    }

    case 'mon_compte':
      return (
        <MonCompte
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          handleReset={handleReset}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    default:
      return 'Unknown stepIndex';
  }
}

const CreationEntrepriseContainer = () => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const formulaireComplet = useContext(FormContext);

  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const [partnerIndex, setPartnerIndex] = React.useState(0);
  const [automateState, setAutomateState] = React.useState(Automate.state);

  const [isRecap, setIsRecap] = React.useState(false);

  const [checkInfosBase, setCheckInfosBase] = React.useState(false);
  const [checkInfosPartners, setCheckInfosPartners] = React.useState(0);
  const [checkInfoFinancieres, setCheckInfoFinancieres] = React.useState(false);
  const [checkInfosAdministratives, setCheckInfosAdministratives] =
    React.useState(false);

  const [partnerGerant, setPartnerGerant] = React.useState({});

  const [demandeSiReset, setDemandeSiReset] = React.useState(true);

  const handleBack = () => {
    if (Automate.state === 'choix_type_societe') {
      Automate.backChoixTypeSocieteVersChoixSeulPlusieurs();
      // Automate.prev();

      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_societe') {
      Automate.backInfoSocieteVersChoixTypeSociete();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_societe2') {
      Automate.backInfoSociete2VersInfoSociete();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_societe3') {
      Automate.backInfoSociete3VersInfoSociete2();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_partenaires_ou_type_partenaire'
      //  && formulaireComplet.partenaire_supplementaire === 'non'
    ) {
      Automate.backChoixTypePartenaireVersInfoSociete3();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_personne_morale') {
      Automate.backInfoPersonneMoraleVersChoixTypePartenaire();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_partenaire' &&
      formulaireComplet.personne_physique_morale === 'personne_morale'
    ) {
      Automate.backInfoPartenaireVersInfoPersonneMorale();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_partenaire' &&
      formulaireComplet.personne_physique_morale === 'personne_physique'
    ) {
      Automate.backInfoPartenaireVersChoixTypePartenaire();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'choix_situation_familiale') {
      Automate.backChoixSituationFamilialeVersInfoPartenaire();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_gerant' &&
      formulaireComplet.personne_physique_morale === 'personne_morale' &&
      formulaireComplet.alone === 'seul'
    ) {
      Automate.backChoixGerantVersChoixInfoPartenaire();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_gerant' &&
      formulaireComplet.personne_physique_morale === 'personne_morale'
    ) {
      Automate.backChoixGerantVersInfoPartenaire();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_apports_partenaire' &&
      formulaireComplet.personne_physique_morale === 'personne_morale'
    ) {
      Automate.backInfoApportsPartenaireVersInfoPartenaire();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_mariage') {
      Automate.backInfoMariageVersChoixSituationFamiliale();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_pacs') {
      Automate.backInfoPacsVersChoixSituationFamiliale();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_gerant' &&
      formulaireComplet.personne_physique_morale === 'personne_physique'
    ) {
      Automate.backChoixGerantVersChoixSituationFamiliale();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_apports_partenaire' &&
      formulaireComplet.personne_physique_morale === 'personne_physique' &&
      formulaireComplet.partner_family_situation !== 'marié(e)' &&
      formulaireComplet.partner_family_situation !== 'pacsé(e)'
    ) {
      Automate.backInfoApportsPartenaireVersChoixSituationFamiliale();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'choix_contrat_de_mariage') {
      Automate.backChoixContratMariageVersInfoMariage();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_contrat_de_mariage') {
      Automate.backInfoContratMariageVersChoixContratMariage();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_gerant' &&
      formulaireComplet.personne_physique_morale === 'personne_physique' &&
      formulaireComplet.partner_family_situation === 'marié(e)' &&
      formulaireComplet.partner_wedding_prenup === 'non'
    ) {
      Automate.backChoixGerantVersChoixContratMariage();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_apports_partenaire' &&
      formulaireComplet.personne_physique_morale === 'personne_physique' &&
      formulaireComplet.partner_family_situation === 'marié(e)' &&
      formulaireComplet.partner_wedding_prenup === 'non'
    ) {
      Automate.backInfoApportsPartenaireVersChoixContratMariage();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_gerant' &&
      formulaireComplet.personne_physique_morale === 'personne_physique' &&
      formulaireComplet.partner_family_situation === 'marié(e)' &&
      formulaireComplet.partner_wedding_prenup === 'oui'
    ) {
      Automate.backChoixGerantVersInfoContratMariage();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_apports_partenaire' &&
      formulaireComplet.personne_physique_morale === 'personne_physique' &&
      formulaireComplet.partner_family_situation === 'marié(e)' &&
      formulaireComplet.partner_wedding_prenup === 'oui'
    ) {
      Automate.backInfoApportsPartenaireVersInfoContratMariage();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'choix_type_de_pacs') {
      Automate.backChoixTypePacsVersInfoPacs();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_apports_partenaire' &&
      formulaireComplet.alone === 'a_plusieurs' &&
      formulaireComplet.partner_family_situation === 'pacsé(e)'
    ) {
      Automate.backInfoApportsPartenaireVersChoixTypePacs();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_gerant' &&
      formulaireComplet.alone === 'seul' &&
      formulaireComplet.partner_family_situation === 'pacsé(e)'
    ) {
      Automate.backChoixGerantVersChoixTypePacs();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_liberation_partenaire') {
      Automate.backInfoLiberationPartenaireVersInfoApportsPartenaire();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'choix_partenaire_supplementaire') {
      Automate.backChoixPartenaireSupplementaireVersInfoLiberationPartenaire();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_gerant' &&
      formulaireComplet.alone === 'a_plusieurs' &&
      formulaireComplet.partenaire_supplementaire === 'non'
    ) {
      Automate.backChoixGerantVersChoixPartenaireSupplementaire();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_partenaires_ou_type_partenaire' &&
      formulaireComplet.alone === 'a_plusieurs' &&
      formulaireComplet.partenaire_supplementaire === 'oui'
    ) {
      Automate.backChoixTypePartenaireVersChoixPartenaireSupplementaire();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_apports_de_societe' &&
      formulaireComplet.eurl_sasu === 'eurl' &&
      formulaireComplet.director_type === "l_associe_de_l'EURL"
    ) {
      Automate.backInfoApportsSocieteVersChoixGerant();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_apports_de_societe' &&
      formulaireComplet.eurl_sasu === 'eurl' &&
      formulaireComplet.director_type === 'un_tiers'
    ) {
      Automate.backInfoApportsSocieteVersInfoGerant();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_dg' &&
      formulaireComplet.eurl_sasu === 'sasu' &&
      formulaireComplet.director_type === "l_associe_de_l'EURL"
    ) {
      Automate.backChoixDgVersChoixGerant();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_dg' &&
      formulaireComplet.eurl_sasu === 'sasu' &&
      formulaireComplet.director_type === 'un_tiers'
    ) {
      Automate.backChoixDgVersInfoGerant();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_dg' &&
      formulaireComplet.eurl_sasu === 'sas' &&
      formulaireComplet.director_type === "l_associe_de_l'EURL"
    ) {
      Automate.backChoixDgVersChoixGerant();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_du_dg' &&
      formulaireComplet.eurl_sasu === 'sas' &&
      formulaireComplet.director_type === 'un_tiers'
    ) {
      Automate.backChoixDgVersInfoGerant();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_capital_de_societe' &&
      formulaireComplet.eurl_sasu === 'sarl' &&
      formulaireComplet.director_type === "l_associe_de_l'EURL"
    ) {
      Automate.backInfoCapitalSocieteVersChoixGerant();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_capital_de_societe' &&
      formulaireComplet.eurl_sasu === 'sarl' &&
      formulaireComplet.director_type === 'un_tiers'
    ) {
      Automate.backInfoCapitalSocieteVersInfoGerant();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_gerant') {
      Automate.backInfoGerantVersChoixGerant();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_apports_de_societe' &&
      formulaireComplet.eurl_sasu === 'sasu'
    ) {
      Automate.backInfoApportsSocieteVersChoixDg();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_capital_de_societe' &&
      formulaireComplet.eurl_sasu === 'sas'
    ) {
      Automate.backInfoCapitalSocieteVersChoixDg();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_capital_de_societe' &&
      formulaireComplet.eurl_sasu === 'eurl'
    ) {
      Automate.backInfoCapitalSocieteVersInfoApportsSociete();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_capital_de_societe' &&
      formulaireComplet.eurl_sasu === 'sasu'
    ) {
      Automate.backInfoCapitalSocieteVersInfoApportsSociete();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_notaire_bbf') {
      Automate.backInfoNotaireBbfVersInfoCapitalSociete();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_liberation_integrale_ou_partielle' &&
      formulaireComplet.eurl_sasu === 'eurl'
    ) {
      Automate.backChoixLiberationIntegralePartielleVersInfoCapitalSociete();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_liberation_integrale_ou_partielle' &&
      formulaireComplet.eurl_sasu === 'sasu'
    ) {
      Automate.backChoixLiberationIntegralePartielleVersInfoCapitalSociete();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_actes_prealables' &&
      formulaireComplet.eurl_sasu === 'sarl'
    ) {
      Automate.backChoixNombreActesPrealablesVersInfoCapitalSociete();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_actes_prealables' &&
      formulaireComplet.eurl_sasu === 'sas'
    ) {
      Automate.backChoixNombreActesPrealablesVersInfoCapitalSociete();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_liberation_integrale_ou_partielle' &&
      formulaireComplet.eurl_sasu === 'eurl'
    ) {
      Automate.backChoixLiberationIntegralePartielleVersInfoNotaireBbf();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_liberation_integrale_ou_partielle' &&
      formulaireComplet.eurl_sasu === 'sasu'
    ) {
      Automate.backChoixLiberationIntegralePartielleVersInfoNotaireBbf();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_actes_prealables' &&
      formulaireComplet.eurl_sasu === 'sarl'
    ) {
      Automate.backChoixNombreActesPrealablesVersInfoNotaireBbf();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_actes_prealables' &&
      formulaireComplet.eurl_sasu === 'sas'
    ) {
      Automate.backChoixNombreActesPrealablesVersInfoNotaireBbf();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_montant_des_apports') {
      Automate.backInfoMontantDesApportsVersChoixLiberationIntegraleOuPartielle();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_actes_prealables' &&
      formulaireComplet.cash_funds_onetime_payment === 'oui' &&
      formulaireComplet.eurl_sasu === 'eurl'
    ) {
      Automate.backChoixNombreActesPrealablesVersChoixLiberationIntegraleOuPartielle();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_actes_prealables' &&
      formulaireComplet.cash_funds_onetime_payment === 'oui' &&
      formulaireComplet.eurl_sasu === 'sasu'
    ) {
      Automate.backChoixNombreActesPrealablesVersChoixLiberationIntegraleOuPartielle();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_actes_prealables' &&
      formulaireComplet.cash_funds_onetime_payment === 'non' &&
      formulaireComplet.eurl_sasu === 'eurl'
    ) {
      Automate.backChoixNombreActesPrealablesVersInfoMontantDesApports();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_nombre_actes_prealables' &&
      formulaireComplet.cash_funds_onetime_payment === 'non' &&
      formulaireComplet.eurl_sasu === 'sasu'
    ) {
      Automate.backChoixNombreActesPrealablesVersInfoMontantDesApports();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'info_actes_prealable' &&
      formulaireComplet.number_of_preexisting_contract > 0
    ) {
      Automate.backInfoActesPrealablesVersInfoActesPrealables();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_commissaire_aux_comptes' &&
      formulaireComplet.number_of_preexisting_contract === 0
    ) {
      Automate.backChoixCommissaireAuxComptesVersChoixNombreActesPrealables();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_commissaire_aux_comptes' &&
      formulaireComplet.number_of_preexisting_contract > 0
    ) {
      Automate.backChoixCommissaireAuxComptesVersInfoActesPrealables();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'info_commissaire_aux_comptes') {
      Automate.backInfoCommisaireAuxComptesVersChoixCommissaireAuxComptes();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_procuration_bbf' &&
      formulaireComplet.kit === 'kit3soc'
    ) {
      Automate.backChoixProcurationBbfVersChoixCommissaireAuxComptes();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'choix_procuration_bbf' &&
      formulaireComplet.kit === 'kit4soc'
    ) {
      Automate.backChoixProcurationBbfVersChoixCommissaireAuxComptes();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'choix_procuration_bbf') {
      Automate.backChoixProcurationBbfVersInfoCommissaireAuxComptes();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'liste_fin' &&
      formulaireComplet.kit === 'basiquesoc'
    ) {
      Automate.backListeFinVersChoixCommissaireAuxComptes();
      setAutomateState(Automate.state);
    } else if (
      Automate.state === 'liste_fin' &&
      formulaireComplet.kit === 'kit1soc'
    ) {
      Automate.backListeFinVersChoixInfoCommissaireAuxComptes();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'liste_fin') {
      Automate.backListeFinVersChoixProcurationBbf();
      setAutomateState(Automate.state);
    } else if (Automate.state === 'recap') {
      Automate.backRecapVersListeFin();
      setAutomateState(Automate.state);
    }
  };

  const handleReset = () => {
    Automate.reset();
    setAutomateState(Automate.state);
    setActiveStep(0);
    setIsRecap(false);

    const form = defaultForm();
    form.partners = [];
    dispatch({
      type: RESET_FORM,
      payload: defaultForm(),
    });
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {getStepContent(
              activeStep,
              setActiveStep,
              formulaireComplet,
              isLastStep,
              handleBack,
              handleReset,
              automateState,
              setAutomateState,
              partnerIndex,
              setPartnerIndex,
              isRecap,
              setIsRecap,
              checkInfosBase,
              setCheckInfosBase,
              checkInfosPartners,
              setCheckInfosPartners,
              checkInfoFinancieres,
              setCheckInfoFinancieres,
              checkInfosAdministratives,
              setCheckInfosAdministratives,
              partnerGerant,
              setPartnerGerant,
              demandeSiReset,
              setDemandeSiReset
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default CreationEntrepriseContainer;
