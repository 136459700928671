const prepareData = (values) => {
  const form = { ...values };
  form.duree_exercice_commissaire_aux_comptes = parseInt(
    form.duree_exercice_commissaire_aux_comptes,
    10
  );
  return form;
};

export default prepareData;
