import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import { Box, Typography } from '@material-ui/core';

import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import theme from 'assets/jss/theme';

// import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  champ1: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const InfoMontantDesApports = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  isRecap,
  // setIsRecap,
  // cash_funds_numbers = 1000,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  let apport_numeraire_total = 0;

  useEffect(() => {
    setActiveStep(2);
  }, []);

  if (formValues.alone !== 'seul') {
    formValues.partners.forEach((partner) => {
      if (partner.partner_cash_funds_numbers) {
        apport_numeraire_total += parseInt(
          partner.partner_cash_funds_numbers,
          10
        );
      }
    });
  }

  if (formValues.alone === 'seul') {
    apport_numeraire_total = formValues.cash_funds_numbers;
  }

  let messagePlaceholder = `Combien voulez-vous libérer ? `;

  if (formValues.depot_capital_chez === 'be_business_focus') {
    messagePlaceholder =
      'Il s’agit du montant que vous virez chez le notaire au titre de la création';
  }
  return (
    <Formik
      initialValues={formValues}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     payments_amount: Yup.number()

      //       .required('un choix est necessaire')
      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (eurl_sasu && eurl_sasu === 'sasu') {
      //           return Yup.number()
      //             .min(formValues.cash_funds_numbers % 2)
      //             .max(formValues.cash_funds_numbers % 2);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (eurl_sasu && eurl_sasu === 'sas') {
      //           return Yup.number()
      //             .min(formValues.cash_funds_numbers % 2)
      //             .max(formValues.cash_funds_numbers % 2);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (eurl_sasu && eurl_sasu === 'sarl') {
      //           return Yup.number()
      //             .min(formValues.cash_funds_numbers % 5)
      //             .max(formValues.cash_funds_numbers % 5);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (eurl_sasu && eurl_sasu === 'eurl') {
      //           return Yup.number()
      //             .min(formValues.cash_funds_numbers % 5)
      //             .max(formValues.cash_funds_numbers % 5);
      //         }
      //       }),

      //   });
      // }}

      // schema de validation avec le multiassocié

      //    validationSchema={() => {
      //   return Yup.object().shape({
      //     payments_amount: Yup.number()

      //       .required('un choix est necessaire')
      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (formValues.eurl_sasu && formValues.eurl_sasu === 'sasu') {
      //           return Yup.number()
      //             .min(apport_numeraire_total % 2)
      //             .max(apport_numeraire_total % 2);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (formValues.eurl_sasu && formValues.eurl_sasu === 'sas') {
      //           return Yup.number()
      //             .min(apport_numeraire_total % 2)
      //             .max(apport_numeraire_total % 2);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (formValues.eurl_sasu && formValues.eurl_sasu === 'sarl') {
      //           return Yup.number()
      //             .min(apport_numeraire_total % 5)
      //             .max(apport_numeraire_total % 5);
      //         }
      //       })

      //       .when('eurl_sasu', (eurl_sasu) => {
      //         if (formValues.eurl_sasu && formValues.eurl_sasu === 'eurl') {
      //           return Yup.number()
      //             .min(apport_numeraire_total % 5)
      //             .max(apport_numeraire_total % 5);
      //         }
      //       }),

      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });

        setSubmitting(false);

        if (isRecap) {
          Automate.goRecap();
          setAutomateState(Automate.state);
          return;
        }

        Automate.nextInfoMontantApportsVersChoixNombreActesPrealables();
        setAutomateState(Automate.state);

        setActiveStep(activeStep + 1);
        // Automate.next();
        // setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                Le montant des apports en numéraire libéré
              </Typography>
              <Typography variant="subtitle1">
                Le montant minimum à libérer est de{' '}
                {formValues.eurl_sasu === 'sasu' ||
                formValues.eurl_sasu === 'sas'
                  ? apport_numeraire_total / 2
                  : apport_numeraire_total / 5}{' '}
                €
              </Typography>

              <Box className={classes.champ1} marginBottom={theme.spacing(0.5)}>
                <Field
                  component={TextField}
                  type="string"
                  label={messagePlaceholder}
                  name="payments_amount"
                  className={classes.textField}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoMontantDesApports.propTypes = {
  nextForm: PropTypes.func,
  cash_funds_numbers: PropTypes.number,
  eurl_sasu: PropTypes.string,
};
export default InfoMontantDesApports;
