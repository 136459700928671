import { YES, yesNo, NO, PM } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { SAS, SARL, EURL, SASU, SCI } from 'constants/companies';
import { TextField } from 'formik-material-ui';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';

function ReleaseOfCash({ formulaireComplet }) {
  const classes = useStyles();
  const { eurl_sasu, partners, kit } = formulaireComplet;
  const {
    values: {
      liberationPartielle,
      pourcentOfRelease,
      capitalChiffres,
      cash_funds_onetime_payment,
    },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (liberationPartielle === NO) {
      setFieldValue('pourcentOfRelease', 0);
    }
  }, [liberationPartielle]);

  const calculateMinAmount = () => {
    if (eurl_sasu === SAS || eurl_sasu === SASU) {
      return capitalChiffres / 2;
    }
    return capitalChiffres / 5;
  };

  const calculReleaseOfCash = (partnerCash) => {
    return (partnerCash * pourcentOfRelease) / 100;
  };

  const getHelpMessage = () => {
    if (eurl_sasu === SCI) {
      return `En SCI, vous pouvez libérer entre 1% et 100%`;
    }
    let pourcentage = 20; // for SARL or EURL
    if (eurl_sasu === SAS || eurl_sasu === SASU) {
      pourcentage = 50;
    }
    return `En ${eurl_sasu.toUpperCase()}, la libération partielle doit être au minimum de ${pourcentage}%, soit ${calculateMinAmount()}€`;
  };

  const getName = (partner) => {
    if (partner.personne_physique_morale === PM) {
      return partner.raison_sociale_actionnaire_personne_morale_sasu;
    }
    return `${partner.partner_title} ${partner.partner_name} ${partner.partner_firstname}`;
  };

  return (
    <>
      {(eurl_sasu === EURL || eurl_sasu === SASU) && (
        <>
          <SmallRadio
            classes={classes}
            title="Voulez-vous libérer la totalité de vos apports en numéraire ?"
            options={yesNo}
            value={cash_funds_onetime_payment}
            name="cash_funds_onetime_payment"
            style={{ marginBottom: '4%' }}
          />
          {NO === cash_funds_onetime_payment && (
            <Field
              width="100%"
              component={TextField}
              type="number"
              label={`Montant à libérer en chiffre (le montant minimum à libérer est de ${calculateMinAmount()}€ )`}
              name="payments_amount"
              className={classes.nameTextField}
              autoComplete="off"
              variant="outlined"
              size="small"
            />
          )}
        </>
      )}
      {(eurl_sasu === SARL || eurl_sasu === SAS || eurl_sasu === SCI) && (
        <>
          {eurl_sasu === SCI && kit !== 'kit4sci' && (
            <h2 className={classes.subTitle}>
              En SCI il n’y a pas d’obligation à libérer l’apport lors de la
              constitution
            </h2>
          )}
          {eurl_sasu === SCI && kit === 'kit4sci' && (
            <h2 className={classes.subTitle}>
              Vous avez choisi de déposer les fonds représentatifs du capital de
              votre SCI chez notre notaire partenaire. Il est donc impératif de
              nous indiquer le montant déposé.
            </h2>
          )}
          {kit !== 'kit4sci' && kit !== 'kit4soc' && (
            <SmallRadio
              classes={classes}
              title={
                eurl_sasu === SCI
                  ? 'Y-a-t-il libération de l’apport en numéraire ?'
                  : 'Y a-t-il une libération partielle de l’apport en numéraire ?'
              }
              options={yesNo}
              value={liberationPartielle}
              name="liberationPartielle"
              style={{ marginBottom: '4%' }}
            />
          )}
          {YES === liberationPartielle && (
            <>
              <h2 className={classes.subTitle}>Rappel:</h2>
              {partners.map((partner, idx) => (
                <div
                  key={idx}
                  className={classes.textInfo}
                  style={{ paddingLeft: '5%', marginBottom: '1%' }}
                  dangerouslySetInnerHTML={{
                    __html: `L'apport en numéraire de <b>${getName(
                      partner
                    )}</b> est de <b>${
                      partner.partner_cash_funds_numbers
                    }</b> euros.`,
                  }}
                ></div>
              ))}
              <h2 className={classes.subTitle}>{getHelpMessage()}</h2>
              <Field
                width="100%"
                component={TextField}
                type="number"
                label={`Pourcentage de la libération`}
                name="pourcentOfRelease"
                className={classes.nameTextField}
                autoComplete="off"
                variant="outlined"
                size="small"
              />
            </>
          )}
          {pourcentOfRelease !== null && pourcentOfRelease > 0 && (
            <>
              <h2 className={classes.subTitle}>Libération par associé:</h2>
              {partners.map((partner, idx) => (
                <div
                  key={idx}
                  className={classes.textInfo}
                  style={{ paddingLeft: '5%', marginBottom: '1%' }}
                  dangerouslySetInnerHTML={{
                    __html: `<b>${calculReleaseOfCash(
                      partner.partner_cash_funds_numbers
                    )}€</b> pour <b>${getName(partner)}</b>.`,
                  }}
                ></div>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ReleaseOfCash;
