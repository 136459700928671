import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { EDIT_COMPANY } from 'constants/actions';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  createForm,
  createStatutsProject,
  saveCompanyData,
  singUp,
} from 'actions/monCompteActions';
import useSocket from 'hooks/useSocket';
import { SOCKET_URL } from 'config/Constants';
import { BBF_NOTARIE, YES } from 'constants/usersContants';
import lodash from 'lodash';
import FormActions from '../FormActions';
import prepareData from './prepareData';
import Recap from './Recap';
import SwitchKit from '../switchKit/SwitchKit';

function RecapContainer({
  goBack,
  goToPartnerInfo,
  goToAdministrativeInfo,
  goToStart,
  formulaireComplet,
  dispatch,
}) {
  const { socket } = useSocket({
    socketUrl: SOCKET_URL,
  });
  const form = { ...formulaireComplet };
  const { currentForm } = form;
  const history = useHistory();
  const { company, loading } = useSelector((state) => state.monCompte);
  const dispatchRedux = useDispatch();
  const [showSwitchKit, setShowSwitchKit] = useState(false);
  const [formData, setFormData] = useState(null);

  const setReviewMode = () => {
    const updatedForm = { ...form, isReview: true };
    dispatch({
      type: EDIT_COMPANY,
      payload: updatedForm,
    });
  };

  useEffect(() => {
    socket.current.on('notification:get', async () => {
      console.log('notification:get');
    });

    setReviewMode();
  }, []);

  const {
    companyId: exsistingId,
    alternativeId: exsistingAlternatId,
    creaformOID: exsistingCreaformOID,
    _id,
  } = company;

  const signUpAndPay = async (formValues) => {
    let companyId = null;
    let alternativeId = null;
    // SING UP
    if (!exsistingId && !exsistingAlternatId) {
      console.info('[signUpAndPay] sing Up ');
      const result = await dispatchRedux(singUp(formValues));
      companyId = result && result.companyId;
      alternativeId = result && result.alternativeId;
    } else {
      companyId = exsistingId;
      alternativeId = exsistingAlternatId;
    }

    // GENERATION DE DOC
    const res = await dispatchRedux(createStatutsProject(formValues));
    const { documents } = res.data;
    const genarateUpdateValueCompany = (newValueCompany) => {
      // value company except creaformOID
      const { creaformOID, ...valuesCompany } = company;
      // create value company to update
      return lodash.assign(valuesCompany, {
        ...newValueCompany,
        _id: exsistingCreaformOID || _id,
        companyId: exsistingId,
        alternativeId: exsistingAlternatId,
      });
    };

    const updateCompanyForm = async () => {
      const valueUpdateCompany = genarateUpdateValueCompany(formData);
      await dispatchRedux(
        saveCompanyData({ ...valueUpdateCompany, documents }, companyId)
      );
      history.push('/kit');
    };

    const createCompanyForm = async () => {
      const newCompany = { ...formValues, documents };
      await dispatchRedux(createForm(newCompany, companyId, alternativeId));
      history.push('/kit');
    };

    // SAUVEGARDE DES DONNEE EN BASE
    if (companyId && alternativeId && documents) {
      socket.current.emit('notification:get');
      if (!exsistingCreaformOID && !_id) {
        createCompanyForm();
      } else {
        updateCompanyForm();
      }
    }
  };

  const updateKitAndCreateCompany = (selectedKit) => {
    const { kit } = formData;
    if (kit !== selectedKit) {
      formData.kit = selectedKit;
      if (selectedKit.includes('kit4')) {
        formData.depot_capital_chez = BBF_NOTARIE;
        formData.liberationPartielle = YES;
      }
    }
    signUpAndPay(formData);
  };
  return (
    <>
      <Formik
        initialValues={form}
        onSubmit={(values) => {
          const data = prepareData(values);
          if (data.kit.includes('kit4')) {
            signUpAndPay(data);
          } else {
            setFormData(data);
            setShowSwitchKit(true);
          }
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Recap
              goToPartnerInfo={goToPartnerInfo}
              goToStart={goToStart}
              goToAdministrativeInfo={goToAdministrativeInfo}
              formulaireComplet={formulaireComplet}
            />
            {loading && <CircularProgress size={24} />}
            {!loading && (
              <FormActions goBack={goBack} currentForm={currentForm} />
            )}
          </form>
        )}
      </Formik>
      <SwitchKit
        open={showSwitchKit}
        handleClose={() => {
          setShowSwitchKit(false);
        }}
        confirmKit={updateKitAndCreateCompany}
        formulaireComplet={formulaireComplet}
      />
    </>
  );
}

export default RecapContainer;
