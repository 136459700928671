import FormActions from 'components/entreprise/V2/FormActions';
import prepareData from 'components/entreprise/V2/basicInfoOfCompany/prepareData';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import BasicInfoOfCompany from 'components/entreprise/V2/basicInfoOfCompany';
import { SCI } from 'constants/companies';
import ResetDialog from 'components/commons/ResetDialog';
import defaultForm from 'models/sci-initial.context';
import { validationSchema } from './validation';
import { initialValues } from './initValues';

function BasicInfoCompanyContainer({
  goBack,
  saveAndNext,
  formulaireComplet,
  dispatch,
}) {
  localStorage.removeItem('persist:root');
  const [openAlert, setOpenAlert] = useState(false);
  const form = { ...formulaireComplet };
  const { currentForm, company_name, eurl_sasu } = form;
  const query = new URLSearchParams(window.location.search);
  const planId = query.get('planId') || '';

  useEffect(() => {
    if (company_name !== '' && SCI === eurl_sasu) {
      setOpenAlert(true);
    }
  }, []);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues(form)}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const data = await prepareData(values);
          const updatedForm = { ...form, ...data };
          setSubmitting(false);
          saveAndNext(updatedForm);
        }}
      >
        {({ handleSubmit, values: { rcs_ville } }) => (
          <form onSubmit={handleSubmit}>
            <BasicInfoOfCompany
              formulaireComplet={formulaireComplet}
              saveAndNext={saveAndNext}
            />
            {
              <FormActions
                goBack={goBack}
                currentForm={currentForm}
                disabled={rcs_ville === ''}
              />
            }
          </form>
        )}
      </Formik>
      {!planId && (
        <ResetDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          defaultForm={defaultForm}
          dispatch={dispatch}
        />
      )}
    </>
  );
}

export default BasicInfoCompanyContainer;
