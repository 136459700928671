import { NO, YES } from 'constants/usersContants';

const prepareData = (values) => {
  const form = { ...values };
  if (
    form.type_depart_activite === 'immediatement' ||
    form.type_depart_activite === 'date_externe'
  ) {
    form.presence_date_debut_activité = YES;
  } else {
    form.presence_date_debut_activité = NO;
  }

  if (form.type_depart_activite !== 'date_externe') {
    form.date_debut_activite = '';
  }
  return form;
};

export default prepareData;
