import { genders } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import React from 'react';
import getUserInfoPositionOptions from 'helpers/userInfo';
import SmallRadio from '../commons/smallRadio';
import Adresse from '../commons/adress';
import QuestionMark from './components/QuestionMark';

function InfoCohabitant({ classes }) {
  const { values } = useFormikContext();
  const { partner_cohabitant_title, cohabitant_position, eurl_sasu } = values;
  return (
    <>
      <h2 className={classes.subTitle}>Informations sur votre conjoint.</h2>
      <SmallRadio
        classes={classes}
        options={genders}
        value={partner_cohabitant_title}
        name="partner_cohabitant_title"
        style={{ marginBottom: '2%' }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom du conjoint *"
        name="partner_cohabitant_name"
        className={classes.nameTextField}
        // autoComplete="off"
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom du conjoint *"
        name="partner_cohabitant_firstname"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Adresse courriel *"
        name="partner_cohabitant_email"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="N° sécurité sociale *"
        name="cohabitant_secu_number"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de naissance du conjoint *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="partner_cohabitant_birthday"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Lieu de naissance du conjoint *"
        name="partner_cohabitant_place_of_birth"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        title="Adresse *"
        address="partner_cohabitant_adress"
        city="partner_cohabitant_city"
        zipCode="partner_cohabitant_zipcode"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        disabledZipCode={true}
        disabledCity={true}
        additionalAddress="partner_cohabitant_additional_adress"
      />
      <h2 className={classes.subTitle}>
        {`Statut au sein de l'entreprise *`}
        <QuestionMark classes={classes} />
      </h2>

      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date d’effet *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="cohabitant_effective_date"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <SmallRadio
        classes={classes}
        options={getUserInfoPositionOptions(eurl_sasu)}
        value={cohabitant_position}
        name="cohabitant_position"
        style={{ marginBottom: '2%' }}
      />
    </>
  );
}

export default InfoCohabitant;
