import { Box } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  KIT_3_SCI,
  KIT_3_SOC,
  KIT_4_SCI,
  KIT_4_SOC,
  KIT_BASIQUE_SCI,
  KIT_BASIQUE_SOC,
} from 'assets/data/kitConstants';

const InfoUser = () => {
  const {
    company: {
      companyId,
      kit,
      stateOfClientFolder: { stepper },
    },
  } = useSelector((state) => state.monCompte);

  const steps = [
    {
      kits: [KIT_4_SOC, KIT_4_SCI],
      steps: [
        `Merci de prendre connaissance des documents produits par Be Business Focus. Vous pouvez directement les ouvrir, les télécharger. Si vous constatez des erreurs ou si vous souhaitez des compléments d’informations, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>.
    Une fois les documents consultés, si vous en êtes satisfait, validez-les en cliquant sur le bouton : Valider les documents créés `,

        `Pour que les équipes de Be Business Focus puissent déposer votre dossier auprès du greffe du tribunal de commerce du ressort de votre société en création, il est nécessaire que vous déposiez les documents demandés ci-dessous. Pour cela cliquez sur la flèche devant chaque intitulé de document demandé et téléchargez les pièces justificatives. Une fois les pièces téléchargées, validez la complétude du téléchargement en cliquant sur le bouton : Valider le téléchargement des documents <br/> Pour toute question, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>`,

        `Vous avez choisi de déposer le capital de votre société en formation en ayant recours au notaire partenaire de Be Business Focus. Nous vous en remercions. Pour cela nous vous invitons à procéder au virement du montant de votre apport si vous êtes associé unique ou des apports de chacun des associés si vous êtes plusieurs associés. Le virement est à réaliser à l’aide du <a href="https://bebusinessfocus.com/wp-content/uploads/2022/11/RIB_Notaire_depot-des-fonds.pdf">RIB du notaire</a>.
    Sur votre virement il est obligatoire de mentionner : SO.PA.CO.DI apport de (Nom de l’apporteur) – Société (Nom de la société créée) <br/> Pour toute question, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>`,

        `Vous, l’ensemble des associés, les dirigeants non associés, le directeur général en fonction du type de société créée, allez recevoir des mails de notre partenaire DocuSign pour signer électroniquement les statuts et les documents constitutifs du dossier de création. Les documents vont circuler automatiquement entre les différents actionnaires. <br/> Pour toute question, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>`,

        `Une fois le virement reçu par le notaire et les documents signés, nous procédons à la publication de l’annonce légale et au dépôt de votre dossier d’immatriculation au greffe du tribunal de commerce. Nous mettons maintenant les pièces de votre dossier à votre disposition. Vous pouvez les visualiser et les télécharger. A réception de votre kbis nous le déposerons et vous recevrez un mail d’information. <br/> Pour toute question, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>`,

        `Félicitation votre entreprise est créée. Récupérer votre Kbis. Comme vous avez fait appel à notre notaire partenaire, il vous appartient maintenant d’ouvrir un compte auprès d’un établissement bancaire ou auprès de Be Business Focus pour faire virer votre apport par le notaire après lui avoir fourni le RIB de votre société et
    l’attestation de dépôt des fonds afin que ce dernier puisse vous virer le montant de votre apport.
     Si vous optez pour le compte BBF, nous vous offrons 3 mois de gratuité sur votre abonnement. <br/> Pour toute question, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>`,
      ],
    },
    {
      kits: [KIT_3_SOC, KIT_3_SCI],
      steps: [
        `Merci de prendre connaissance des documents produits par Be Business Focus. Vous pouvez directement les ouvrir, les télécharger. Si vous constatez des erreurs ou si vous souhaitez des compléments d’informations, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>.
        Une fois les documents consultés, si vous en êtes satisfait, validez-les en cliquant sur le bouton : Valider les documents créés. <br/> Pour toute question, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>`,
        `Pour que les équipes de Be Business Focus puissent déposer votre dossier auprès du greffe du tribunal de commerce du ressort de votre société en création, il est nécessaire que vous déposiez les documents demandés ci-dessous. Pour cela cliquez sur la flèche devant chaque intitulé de document demandé et téléchargez les pièces justificatives. Une fois les pièces téléchargées, validez la complétude du téléchargement en cliquant sur le bouton : Valider le téléchargement des documents.
        Vous avez choisi de vous occuper du dépôt du capital de votre société auprès de votre banque ou de votre notaire. Pour poursuivre votre immatriculation il nous faut l’attestation de dépôt des fonds. Pour cela, vous devez imprimer les statuts que nous avons réalisés et les présenter au banquier ou au notaire de votre choix et déposer les fonds d’apport pour procéder à la création d’un compte de société en création. Votre partenaire vous remettra une attestation de dépôt des fonds qu’il faudra nous transmettre en la déposant sur votre espace : documents à déposer .
  Si vous souhaitez des compléments d’informations, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>`,
        `Vous, l’ensemble des associés, les dirigeants non associés, le directeur général en fonction du type de société créée, allez recevoir des mails de notre partenaire DocuSign pour signer électroniquement les statuts et les documents constitutifs du dossier de création. Les documents vont circuler automatiquement entre les différents actionnaires. <br/> Pour toute question, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>`,
        `Récupérez votre Kbis. Vous devez présenter ce Kbis auprès de votre banquier ou de votre notaire pour débloquer les fonds apportés.
        Nous espérons que notre service vous a donné satisfaction. Un compte vous a été créé sur Be Business Focus pour piloter votre société, n’hésitez pas à tester notre solution en cliquant sur le lien suivant : Solution de pilotage BBF <br/> Pour toute question, contactez-nous à l’adresse suivante : <a href='mailto:juridique@bebusinessfocus.com?subject=[${companyId}] Aide creation entreprise'>juridique@bebusinessfocus.com</a>`,
      ],
    },
    {
      kits: [KIT_BASIQUE_SOC, KIT_BASIQUE_SCI],
      steps: [
        `Vous pouvez directement les télécharger vos documents.Nous espérons que notre service vous a donné satisfaction. Un compte vous a été créé sur Be Business Focus pour piloter votre société, n’hésitez pas à tester notre solution en cliquant sur le lien suivant : Solution de pilotage BBF `,
      ],
    },
  ];

  const getSteps = () => {
    let stepsToReturn = null;
    steps.forEach((step) => {
      if (step.kits.includes(kit)) {
        stepsToReturn = step.steps;
      }
    });
    return stepsToReturn;
  };

  return (
    <Box
      sx={{ width: '100%' }}
      style={{
        width: '100%',
        fontSize: '1.2em',
        borderRadius: '1em',
        padding: '2em',
        backgroundColor: '#EFDAB2',
        fontWeight: 'bold',
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: getSteps()[stepper] }} />
    </Box>
  );
};

export default InfoUser;
