import * as Yup from 'yup';

// Is a string
// Is not only numbers or whitespace
// Only contain specific characters: '&' and '-'
const companyNameRegex = new RegExp(
  /(?!^(\s|\d)+$)^[^!@#$%^*()_+=\\[\]{};':"\\|,.<>\\/?]+$/
);
const validationSchema = () => {
  return Yup.object().shape({
    company_name: Yup.string()
      .required('Champs requis')
      .matches(companyNameRegex, {
        message: 'Format de la Dénomination sociale erroné ',
      }),
    business_name: Yup.string(),
    code_ape: Yup.string().required('Champs requis'),
    address: Yup.string().required('Champs requis'),
    city: Yup.string().required('Champs requis'),
    zipcode: Yup.string().required('Champs requis'),
    rcs_ville: Yup.string().required('Champs requis'),
    additional_address: Yup.string(),
  });
};

//  eslint-disable-next-line
export { validationSchema };
