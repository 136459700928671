import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import store from 'store/monCompteStore';
import CallBackPayment from 'containers/CallBackPaymentContainer';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

const CallBackPaymentView = () => {
  const persistor = persistStore(store);

  const TRACKING_ID = process.env.REACT_GA_TRACKING_ID; // OUR_TRACKING_ID

  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <CallBackPayment />
    </PersistGate>
  );
};

export default CallBackPaymentView;
