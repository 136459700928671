import {
  juridicForms,
  cacTypes,
  CAC_PP,
  CAC_PM,
  YES,
  yesNo,
} from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import Adresse from '../commons/adress';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';
import InfoCACSupleantPM from './InfoCACSupleantPM';
import InfoCACSupleantPP from './InfoCACSupleantPP';

function InfoPM() {
  const classes = useStyles();
  const {
    values: { forme_juridique_cabinet_CAC, cac_suppleant, type_CAC_suppleant },
  } = useFormikContext();

  return (
    <>
      <SmallRadio
        classes={classes}
        title="Forme de la société du commissaire aux comptes"
        options={juridicForms}
        value={forme_juridique_cabinet_CAC}
        name="forme_juridique_cabinet_CAC"
        style={{ marginBottom: '3%' }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom du cabinet du commissaire aux comptes *"
        name="nom_cabinet_commissaire_aux_comptes"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        title="Adresse du cabinet du commissaire aux comptes *"
        address="adresse_cabinet_commissaire_aux_comptes"
        city="city_cabinet_commissaire_aux_comptes"
        zipCode="zipCode_cabinet_commissaire_aux_comptes"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        additionalAddress="additional_address_cabinet_commissaire_aux_comptes"
      />
      {/* <Field
        component={TextField}
        type="string"
        label="Adresse du cabinet du commissaire aux comptes *"
        name="adresse_cabinet_commissaire_aux_comptes"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      /> */}
      <SmallRadio
        classes={classes}
        title="Y a t-il un Commissaire aux Comptes suppléant ?"
        options={yesNo}
        value={cac_suppleant}
        name="cac_suppleant"
        style={{ marginBottom: '3%' }}
      />
      {cac_suppleant === YES && (
        <>
          <h2 className={classes.subTitle}>
            Informations du Commissaire aux Comptes suppléant.
          </h2>
          <SmallRadio
            classes={classes}
            options={cacTypes}
            value={type_CAC_suppleant}
            name="type_CAC_suppleant"
            style={{ marginBottom: '5%' }}
          />
          {type_CAC_suppleant === CAC_PP && <InfoCACSupleantPP />}
          {type_CAC_suppleant === CAC_PM && <InfoCACSupleantPM />}
        </>
      )}
    </>
  );
}

export default InfoPM;
