/* eslint-disable no-console */
const StateMachine = require('javascript-state-machine');

// trying to instantiate a state machine with a non existing initial state across transitions will throw error
const SciAutomate = new StateMachine({
  init: 'info_societe',
  // init: 'choix_nombre_partenaires_ou_type_partenaire',
  // init: 'choix_partenaire_supplementaire',
  // init: 'info_apports_de_societe',
  // init: 'recap',
  // init: 'info_capital_de_societe',
  // init: 'choix_du_gerant',
  // init: 'choix_type_societe',

  // init: 'info_partenaire',
  // init: 'info_commissaire_aux_comptes',
  // init: 'choix_procuration_bbf',

  transitions: [
    // *******************************************************************************************************
    // refactoring automate:

    // <next>

    {
      name: 'nextInfoSocieteVersInfoSociete2',
      from: 'info_societe',
      to: 'info_societe2',
    },
    {
      name: 'nextInfoSociete2VersInfoSociete3',
      from: 'info_societe2',
      to: 'info_societe3',
    },
    {
      name: 'nextInfoSociete3VersTypePartenaire',
      from: 'info_societe3',
      to: 'choix_nombre_partenaires_ou_type_partenaire',
    },
    {
      name: 'nextTypePartenaireVersInfoPersonneMorale',
      from: 'choix_nombre_partenaires_ou_type_partenaire',
      to: 'info_personne_morale',
    },
    {
      name: 'nextInfoPersonneMoraleVersInfoPartenaire',
      from: 'info_personne_morale',
      to: 'info_partenaire',
    },
    {
      name: 'nextTypePartenaireVersInfoPartenaire',
      from: 'choix_nombre_partenaires_ou_type_partenaire',
      to: 'info_partenaire',
    },

    {
      name: 'nextInfoPartenaireVersChoixSituationFamiliale',
      from: 'info_partenaire',
      to: 'choix_situation_familiale',
    },

    {
      name: 'nextInfoPartenaireVersInfoApportsPartenaire',
      from: 'info_partenaire',
      to: 'info_apports_partenaire',
    },

    {
      name: 'nextChoixSituationFamilialeVersInfoMariage',
      from: 'choix_situation_familiale',
      to: 'info_mariage',
    },

    {
      name: 'nextInfoMariageVersChoixContratMariage',
      from: 'info_mariage',
      to: 'choix_contrat_de_mariage',
    },

    {
      name: 'nextChoixContratMariageVersInfoApportsPartenaire',
      from: 'choix_contrat_de_mariage',
      to: 'info_apports_partenaire',
    },

    {
      name: 'nextChoixContratMariageVersInfoContratMariage',
      from: 'choix_contrat_de_mariage',
      to: 'info_contrat_de_mariage',
    },

    {
      name: 'nextInfoContratMariageVersInfoApportsPartenaire',
      from: 'info_contrat_de_mariage',
      to: 'info_apports_partenaire',
    },

    {
      name: 'nextChoixSituationFamilialeVersInfoPacs',
      from: 'choix_situation_familiale',
      to: 'info_pacs',
    },

    {
      name: 'nextInfoPacsversChoixTypePacs',
      from: 'info_pacs',
      to: 'choix_type_de_pacs',
    },

    {
      name: 'nextChoixTypePacsVersInfoApportsPartenaire',
      from: 'choix_type_de_pacs',
      to: 'info_apports_partenaire',
    },
    {
      name: 'nextChoixSituationFamilialeVersInfoApportsPartenaire',
      from: 'choix_situation_familiale',
      to: 'info_apports_partenaire',
    },
    {
      name: 'nextInfoApportsPartenaireVersInfoLiberationPartenaire',
      from: 'info_apports_partenaire',
      to: 'info_liberation_partenaire',
    },
    {
      name: 'nextInfoLiberationPartenaireVersChoixPartenaireSupplementaire',
      from: 'info_liberation_partenaire',
      to: 'choix_partenaire_supplementaire',
    },
    {
      name: 'nextChoixPartenaireSupplementaireVersTypePartenaire',
      from: 'choix_partenaire_supplementaire',
      to: 'choix_nombre_partenaires_ou_type_partenaire',
    },
    {
      name: 'nextChoixPartenaireSupplementaireVersChoixGerant',
      from: 'choix_partenaire_supplementaire',
      to: 'choix_du_gerant',
    },
    {
      name: 'nextChoixGerantVersInfoGerant',
      from: 'choix_du_gerant',
      to: 'info_gerant',
    },
    {
      name: 'nextChoixGerantVersInfoCapitalSociete',
      from: 'choix_du_gerant',
      to: 'info_capital_de_societe',
    },
    {
      name: 'nextInfoGerantVersInfoCapitalSociete',
      from: 'info_gerant',
      to: 'info_capital_de_societe',
    },
    {
      name: 'nextInfoCapitalSocieteVersInfoNotaire',
      from: 'info_capital_de_societe',
      to: 'info_notaire_bbf',
    },

    // {
    //   name: 'nextInfoCapitalSocieteVersChoixLiberationIntegraleOuPartielle',
    //   from: 'info_capital_de_societe',
    //   to: 'choix_liberation_integrale_ou_partielle',
    // },
    // {
    //   name: 'nextInfoNotaireVersChoixLiberationIntegraleOuPartielle',
    //   from: 'info_notaire_bbf',
    //   to: 'choix_liberation_integrale_ou_partielle',
    // },
    {
      name: 'nextInfoNotaireVersChoixNombreActesPrealables',
      from: 'info_notaire_bbf',
      to: 'choix_nombre_actes_prealables',
    },
    // {
    //   name: 'nextChoixLiberationIntegraleOuPartielleVersInfoMontantDesApports',
    //   from: 'choix_liberation_integrale_ou_partielle',
    //   to: 'info_montant_des_apports',
    // },

    // {
    //   name: 'nextInfoMontantApportsVersChoixNombreActesPrealables',
    //   from: 'info_montant_des_apports',
    //   to: 'choix_nombre_actes_prealables',
    // },

    // {
    //   name: 'nextChoixLiberationIntegraleOuPartielleVersChoixNombreActesPrealables',
    //   from: 'choix_liberation_integrale_ou_partielle',
    //   to: 'choix_nombre_actes_prealables',
    // },
    {
      name: 'nextInfoCapitalSocieteVersChoixNombreActesPrealables',
      from: 'info_capital_de_societe',
      to: 'choix_nombre_actes_prealables',
    },
    {
      name: 'nextChoixNombreActesPrealablesVersInfoActesPrealables',
      from: 'choix_nombre_actes_prealables',
      to: 'info_actes_prealable',
    },

    {
      name: 'nextInfoActesPrealablesVersChoixCommissaireAuxComptes',
      from: 'info_actes_prealable',
      to: 'choix_commissaire_aux_comptes',
    },
    {
      name: 'nextChoixNombreActesPrealablesVersChoixCommissaireAuxComptes',
      from: 'choix_nombre_actes_prealables',
      to: 'choix_commissaire_aux_comptes',
    },
    {
      name: 'nextChoixCommissaireAuxComptesVersInfoCommissaireAuxComptes',
      from: 'choix_commissaire_aux_comptes',
      to: 'info_commissaire_aux_comptes',
    },
    {
      name: 'nextInfoCommissaireAuxComptesVersChoixProcurationBbf',
      from: 'info_commissaire_aux_comptes',
      to: 'choix_procuration_bbf',
    },
    {
      name: 'nextChoixCommissaireAuxComptesVersChoixProcurationBbf',
      from: 'choix_commissaire_aux_comptes',
      to: 'choix_procuration_bbf',
    },
    {
      name: 'nextChoixCommissaireAuxComptesVersListeFin',
      from: 'choix_commissaire_aux_comptes',
      to: 'liste_fin',
    },

    {
      name: 'nextChoixProcurationBbfVersListeFin',
      from: 'choix_procuration_bbf',
      to: 'liste_fin',
    },

    {
      name: 'nextListeFinVersRecap',
      from: 'liste_fin',
      to: 'recap',
    },
    // </next>

    // <back>:

    {
      name: 'backRecapVersListeFin',
      from: 'recap',
      to: 'liste_fin',
    },

    {
      name: 'backListeFinVersChoixProcurationBbf',
      from: 'liste_fin',
      to: 'choix_procuration_bbf',
    },
    {
      name: 'backListeFinVersChoixCommissaireAuxComptes',
      from: 'liste_fin',
      to: 'choix_commissaire_aux_comptes',
    },
    {
      name: 'backChoixProcurationBbfVersInfoCommissaireAuxComptes',
      from: 'choix_procuration_bbf',
      to: 'info_commissaire_aux_comptes',
    },
    {
      name: 'backChoixProcurationBbfVersChoixCommissaireAuxComptes',
      from: 'choix_procuration_bbf',
      to: 'choix_commissaire_aux_comptes',
    },

    {
      name: 'backInfoCommisaireAuxComptesVersChoixCommissaireAuxComptes',
      from: 'info_commissaire_aux_comptes',
      to: 'choix_commissaire_aux_comptes',
    },

    {
      name: 'backChoixCommissaireAuxComptesVersInfoActesPrealables',
      from: 'choix_commissaire_aux_comptes',
      to: 'info_actes_prealable',
    },
    {
      name: 'backChoixCommissaireAuxComptesVersChoixNombreActesPrealables',
      from: 'choix_commissaire_aux_comptes',
      to: 'choix_nombre_actes_prealables',
    },

    {
      name: 'backInfoActesPrealablesVersInfoActesPrealables',
      from: 'info_actes_prealable',
      to: 'choix_nombre_actes_prealables',
    },

    {
      name: 'backChoixCommissaireAuxComptesVersChoixNombreActesPrealables',
      from: 'choix_commissaire_aux_comptes',
      to: 'choix_nombre_actes_prealables',
    },

    {
      name: 'backChoixNombreActesPrealablesVersInfoNotaireBbf',
      from: 'choix_nombre_actes_prealables',
      to: 'info_notaire_bbf',
    },
    // {
    //   name: 'backChoixLiberationIntegralePartielleVersInfoNotaireBbf',
    //   from: 'choix_liberation_integrale_ou_partielle',
    //   to: 'info_notaire_bbf',
    // },
    {
      name: 'backInfoNotaireBbfVersInfoCapitalSociete',
      from: 'info_notaire_bbf',
      to: 'info_capital_de_societe',
    },
    // {
    //   name: 'backInfoMontantDesApportsVersChoixLiberationIntegraleOuPartielle',
    //   from: 'info_montant_des_apports',
    //   to: 'choix_liberation_integrale_ou_partielle',
    // },
    // {
    //   name: 'backChoixNombreActesPrealablesVersInfoMontantDesApports',
    //   from: 'choix_nombre_actes_prealables',
    //   to: 'info_montant_des_apports',
    // },
    // {
    //   name: 'backChoixNombreActesPrealablesVersChoixLiberationIntegraleOuPartielle',
    //   from: 'choix_nombre_actes_prealables',
    //   to: 'choix_liberation_integrale_ou_partielle',
    // },
    // {
    //   name: 'backChoixLiberationIntegralePartielleVersInfoCapitalSociete',
    //   from: 'choix_liberation_integrale_ou_partielle',
    //   to: 'info_capital_de_societe',
    // },

    {
      name: 'backChoixNombreActesPrealablesVersInfoCapitalSociete',
      from: 'choix_nombre_actes_prealables',
      to: 'info_capital_de_societe',
    },

    // {
    //   name: 'backInfoCapitalSocieteVersChoixDg',
    //   from: 'info_capital_de_societe',
    //   to: 'choix_du_dg',
    // },

    // {
    //   name: 'backInfoApportsSocieteVersChoixDg',
    //   from: 'info_apports_de_societe',
    //   to: 'choix_du_dg',
    // },

    // {
    //   name: 'backChoixDgVersInfoGerant',
    //   from: 'choix_du_dg',
    //   to: 'info_gerant',
    // },

    // {
    //   name: 'backChoixDgVersChoixGerant',
    //   from: 'choix_du_dg',
    //   to: 'choix_du_gerant',
    // },

    {
      name: 'backInfoCapitalSocieteVersInfoGerant',
      from: 'info_capital_de_societe',
      to: 'info_gerant',
    },

    {
      name: 'backInfoCapitalSocieteVersChoixGerant',
      from: 'info_capital_de_societe',
      to: 'choix_du_gerant',
    },
    {
      name: 'backInfoGerantVersChoixGerant',
      from: 'info_gerant',
      to: 'choix_du_gerant',
    },

    {
      name: 'backChoixGerantVersChoixPartenaireSupplementaire',
      from: 'choix_du_gerant',
      to: 'choix_partenaire_supplementaire',
    },
    {
      name: 'backChoixTypePartenaireVersChoixPartenaireSupplementaire',
      from: 'choix_nombre_partenaires_ou_type_partenaire',
      to: 'choix_partenaire_supplementaire',
    },

    {
      name: 'backChoixPartenaireSupplementaireVersInfoLiberationPartenaire',
      from: 'choix_partenaire_supplementaire',
      to: 'info_liberation_partenaire',
    },

    {
      name: 'backInfoLiberationPartenaireVersInfoApportsPartenaire',
      from: 'info_liberation_partenaire',
      to: 'info_apports_partenaire',
    },

    {
      name: 'backInfoApportsPartenaireVersInfoContratMariage',
      from: 'info_apports_partenaire',
      to: 'info_contrat_de_mariage',
    },

    {
      name: 'backInfoApportsPartenaireVersChoixContratMariage',
      from: 'info_apports_partenaire',
      to: 'choix_contrat_de_mariage',
    },

    {
      name: 'backInfoApportsPartenaireVersChoixTypePacs',
      from: 'info_apports_partenaire',
      to: 'choix_type_de_pacs',
    },

    {
      name: 'backInfoApportsPartenaireVersChoixSituationFamiliale',
      from: 'info_apports_partenaire',
      to: 'choix_situation_familiale',
    },

    {
      name: 'backInfoApportsPartenaireVersInfoPartenaire',
      from: 'info_apports_partenaire',
      to: 'info_partenaire',
    },
    {
      name: 'backInfoContratMariageVersChoixContratMariage',
      from: 'info_contrat_de_mariage',
      to: 'choix_contrat_de_mariage',
    },

    {
      name: 'backChoixContratMariageVersInfoMariage',
      from: 'choix_contrat_de_mariage',
      to: 'info_mariage',
    },

    {
      name: 'backInfoMariageVersChoixSituationFamiliale',
      from: 'info_mariage',
      to: 'choix_situation_familiale',
    },

    {
      name: 'backChoixTypePacsVersInfoPacs',
      from: 'choix_type_de_pacs',
      to: 'info_pacs',
    },

    {
      name: 'backInfoPacsVersChoixSituationFamiliale',
      from: 'info_pacs',
      to: 'choix_situation_familiale',
    },

    {
      name: 'backChoixSituationFamilialeVersInfoPartenaire',
      from: 'choix_situation_familiale',
      to: 'info_partenaire',
    },

    {
      name: 'backInfoPartenaireVersInfoPersonneMorale',
      from: 'info_partenaire',
      to: 'info_personne_morale',
    },
    {
      name: 'backInfoPersonneMoraleVersChoixTypePartenaire',
      from: 'info_personne_morale',
      to: 'choix_nombre_partenaires_ou_type_partenaire',
    },
    {
      name: 'backInfoPartenaireVersChoixTypePartenaire',
      from: 'info_partenaire',
      to: 'choix_nombre_partenaires_ou_type_partenaire',
    },

    {
      name: 'backChoixTypePartenaireVersInfoSociete3',
      from: 'choix_nombre_partenaires_ou_type_partenaire',
      to: 'info_societe3',
    },

    {
      name: 'backInfoSociete3VersInfoSociete2',
      from: 'info_societe3',
      to: 'info_societe2',
    },

    {
      name: 'backInfoSociete2VersInfoSociete',
      from: 'info_societe2',
      to: 'info_societe',
    },

    // </back>
    // <retour>

    {
      name: 'retourListeFinVersChoixGerant',
      from: 'liste_fin',
      to: 'choix_du_gerant',
    },

    {
      name: 'retourListeFinVersInfoPartenaire',
      from: 'liste_fin',
      to: 'info_partenaire',
    },

    {
      name: 'retourListeFinVersInfoPersonneMorale',
      from: 'liste_fin',
      to: 'info_personne_morale',
    },

    {
      name: 'retourListeFinVersInfoSociete',
      from: 'liste_fin',
      to: 'info_societe',
    },
    // </retour>

    // <Recap>

    {
      name: 'recapInfoSociete3VersListeFin',
      from: 'info_societe3',
      to: 'liste_fin',
    },

    {
      name: 'recapInfoLiberationPartenaireVersListeFin',
      from: 'info_liberation_partenaire',
      to: 'liste_fin',
    },

    {
      name: 'recapInfoContratMariageVersListeFin',
      from: 'info_contrat_de_mariage',
      to: 'liste_fin',
    },

    {
      name: 'recapChoixContratMariageVersListeFin',
      from: 'choix_contrat_de_mariage',
      to: 'liste_fin',
    },

    {
      name: 'recapChoixTypePacsVersListeFin',
      from: 'choix_type_de_pacs',
      to: 'liste_fin',
    },

    {
      name: 'recapChoixSituationFamilialeVersListeFin',
      from: 'choix_situation_familiale',
      to: 'liste_fin',
    },

    {
      name: 'recapInfoPartenaireVersListeFin',
      from: 'info_partenaire',
      to: 'liste_fin',
    },

    // TODO DELETE ONLY FOR TEST
    {
      name: 'xcv',
      from: 'info_societe',
      to: 'liste_fin',
    },

    // </Recap>

    // *******************************************************************************************************

    // {
    //   name: 'next',
    //   from: 'choix_nom_propre_ou_societe',
    //   to: 'choix_seul_ou_a_plusieurs',
    // },

    // {
    //   name: 'nomPropreVersTypeSociete',
    //   from: 'choix_nom_propre_ou_societe',
    //   to: 'choix_seul_ou_a_plusieurs',
    // },
    // {
    //   name: 'next',
    //   from: 'choix_seul_ou_a_plusieurs',
    //   to: 'choix_type_societe',
    // },
    {
      // name: 'demande_choisir_nombre_de_partenaires_ou_type_de_partenaire',
      name: 'seulPlusieurVersCapital',
      from: 'choix_seul_ou_a_plusieurs',
      to: 'info_capital_de_societe',
    },

    // {
    //   name: 'next',
    //   from: 'choix_type_societe',
    //   to: 'info_societe',
    // },

    // creation etape d'info société en 3 etape au lieu de 1
    {
      name: 'next',
      from: 'info_societe',
      to: 'info_societe2',
    },
    {
      name: 'next',
      from: 'info_societe2',
      to: 'info_societe3',
    },
    {
      // name: 'demande_choisir_nombre_de_partenaires_ou_type_de_partenaire',
      name: 'next',
      from: 'info_societe3',
      to: 'choix_nombre_partenaires_ou_type_partenaire',
    },

    // fin des 2 etapes supplémentaire pour les infos de société

    {
      name: 'demande_info_de_la_personne_morale',
      from: [
        'choix_nombre_partenaires_ou_type_partenaire',
        'info_contrat_de_mariage',
        'demande_choisir_si_contrat_de_mariage',
        'choix_situation_familiale',
        'info_pacs',
      ],
      to: 'info_personne_morale',
    },
    {
      name: 'demande_info_du_partenaire',
      // name: 'next',
      from: [
        'info_personne_morale',
        'choix_nombre_partenaires_ou_type_partenaire',
        'info_contrat_de_mariage',
        'choix_si_contrat_de_mariage',
        'choix_situation_familiale',
        'info-pacs',
      ],
      to: 'info_partenaire',
    },
    {
      name: 'demande_choisir_situation_familiale',
      from: 'info_partenaire',
      to: 'choix_situation_familiale',
    },
    {
      name: 'demande_info_du_mariage',
      from: 'choix_situation_familiale',
      to: 'info_mariage',
    },
    {
      name: 'demande_choisir_si_contrat_de_mariage',
      from: 'info_mariage',
      to: 'choix_contrat_de_mariage',
    },
    {
      name: 'demande_info_du_contrat_de_mariage',
      from: 'choix_contrat_de_mariage',
      to: 'info_contrat_de_mariage',
    },
    {
      name: 'demande_info_du_pacs',
      from: 'choix_situation_familiale',
      to: 'info_pacs',
    },
    {
      name: 'demande_choisir_le_type_de_pacs',
      from: 'info_pacs',
      to: 'choix_type_de_pacs',
    },

    {
      name: 'demande_info_apports_partenaire',
      from: [
        'info_partenaire',
        'choix_situation_familiale',
        'choix_contrat_de_mariage',
        'choix_type_de_pacs',
        'info_contrat_de_mariage',
      ],
      to: 'info_apports_partenaire',
    },

    {
      name: 'demande_info_liberation_partenaire',
      from: 'info_apports_partenaire',
      to: 'info_liberation_partenaire',
    },
    {
      name: 'demande_choisir_si_partenaire_supplementaire',
      from: 'info_liberation_partenaire',
      to: 'choix_partenaire_supplementaire',
    },

    {
      name: 'boucle',
      from: 'choix_partenaire_supplementaire',
      to: 'choix_nombre_partenaires_ou_type_partenaire',
    },

    {
      // name: 'demande_info_sur_le_capital_de_la_societe',
      name: 'next',
      from: [
        'info_partenaire',
        'choix_situation_familiale',
        'choix_contrat_de_mariage',
        'choix_type_de_pacs',
        'info_contrat_de_mariage',
      ],
      to: 'choix_du_gerant',
    },

    {
      // name: 'demande_info_sur_le_capital_de_la_societe',
      name: 'next',
      from: 'choix_partenaire_supplementaire',
      to: 'choix_du_gerant',
    },

    {
      // name: 'demande_info_sur_les_apports_de_la_societe',
      name: 'next',
      from: 'info_apports_de_societe',
      to: 'info_capital_de_societe',
    },
    {
      // name: 'demande_choisir_liberation_integrale_ou_partielle',
      name: 'next',
      from: 'info_capital_de_societe',
      to: 'choix_liberation_integrale_ou_partielle',
    },
    {
      // name: 'demande_choisir_liberation_integrale_ou_partielle',
      name: 'montreInfoNotaireBbf',
      from: 'info_capital_de_societe',
      to: 'info_notaire_bbf',
    },

    {
      // name: 'demande_choisir_liberation_integrale_ou_partielle',
      name: 'next',
      from: 'info_notaire_bbf',
      to: 'choix_liberation_integrale_ou_partielle',
    },
    {
      name: 'demande_info_sur_le_montant_des_apports',
      from: 'choix_liberation_integrale_ou_partielle',
      to: 'info_montant_des_apports',
    },
    {
      name: 'nextCapitalVersActes',
      from: 'info_capital_de_societe',
      to: 'choix_nombre_actes_prealables',
    },
    {
      // name: 'demande_choisir_le_gerant',
      name: 'next',
      from: [
        'choix_liberation_integrale_ou_partielle',
        'info_montant_des_apports',
      ],
      to: 'choix_nombre_actes_prealables',
    },
    {
      name: 'demande_info_sur_le_gerant',
      from: 'choix_du_gerant',
      to: 'info_gerant',
    },
    // {
    //   name: 'next',
    //   from: ['choix_du_gerant', 'info_gerant'],
    //   to: 'choix_nombre_actes_prealables',
    // },
    {
      name: 'demande_info_sur_les_actes_prealables',
      from: 'choix_nombre_actes_prealables',
      to: 'info_actes_prealable',
    },
    {
      name: 'next',
      // from: ['choix_nombre_actes_prealables', 'info_actes_prealable'],
      from: ['choix_du_gerant', 'info_gerant'],

      to: 'info_capital_de_societe',
    },
    {
      name: 'next',
      from: ['choix_nombre_actes_prealables', 'info_actes_prealable'],

      to: 'choix_commissaire_aux_comptes',
    },
    {
      name: 'next',
      from: 'choix_commissaire_aux_comptes',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'next',
      from: 'info_commissaire_aux_comptes',
      to: 'choix_procuration_bbf',
    },

    {
      name: 'next',
      from: 'choix_procuration_bbf',
      to: 'liste_fin',
    },

    {
      name: 'next',
      from: 'liste_fin',
      to: 'recap',
    },

    {
      name: 'reset',
      from: 'recap',
      to: 'info_societe',
    },

    {
      name: 'reset',
      from: 'info_societe',
      to: 'info_societe',
    },

    // {
    //   name: 'retourChoixNomPropreOuSociete',
    //   from: 'liste_fin',
    //   to: 'choix_nom_propre_ou_societe',
    // },

    // {
    //   name: 'retourChoixSeulOuAPlusieurs',
    //   from: 'liste_fin',
    //   to: 'choix_seul_ou_a_plusieurs',
    // },
    // {
    //   name: 'retourChoixTypeSociete',
    //   from: 'liste_fin',
    //   to: 'choix_type_societe',
    // },
    {
      name: 'retourInfoSociete',
      from: 'liste_fin',
      to: 'info_societe',
    },
    {
      name: 'retourInfoSociete2',
      from: 'liste_fin',
      to: 'info_societe2',
    },
    {
      name: 'retourInfoSociete3',
      from: 'liste_fin',
      to: 'info_societe3',
    },
    {
      name: 'retourChoixNombrePartenairesOuTypePartenaire',
      from: 'liste_fin',
      to: 'choix_nombre_partenaires_ou_type_partenaire',
    },
    {
      name: 'retourInfoPersonneMorale',
      from: 'liste_fin',
      to: 'info_personne_morale',
    },
    {
      name: 'retourInfoPartenaire',
      from: 'liste_fin',
      to: 'info_partenaire',
    },
    {
      name: 'retourChoixSituationFamiliale',
      from: 'liste_fin',
      to: 'choix_situation_familiale',
    },
    {
      name: 'retourInfoMariage',
      from: 'liste_fin',
      to: 'info_mariage',
    },

    {
      name: 'retourChoixContratDeMariage',
      from: 'liste_fin',
      to: 'choix_contrat_de_mariage',
    },
    {
      name: 'retourInfoContratDeMariage',
      from: 'liste_fin',
      to: 'info_contrat_de_mariage',
    },
    {
      name: 'retourInfoPacs',
      from: 'liste_fin',
      to: 'info_pacs',
    },
    {
      name: 'retourChoixTypeDePacs',
      from: 'liste_fin',
      to: 'choix_type_de_pacs',
    },
    {
      name: 'retourInfoApportsPartenaire',
      from: 'liste_fin',
      to: 'info_apports_partenaire',
    },
    {
      name: 'retourChoixPartenaireSupplementaire',
      from: 'liste_fin',
      to: 'choix_partenaire_supplementaire',
    },
    {
      name: 'retourChoixNombrePartenairesOuTypePartenaire',
      from: 'liste_fin',
      to: 'choix_nombre_partenaires_ou_type_partenaire',
    },
    {
      name: 'retourInfoApportsDeSociete',
      from: 'liste_fin',
      to: 'info_apports_de_societe',
    },
    {
      name: 'retourInfoCapitalDeSociete',
      from: 'liste_fin',
      to: 'info_capital_de_societe',
    },
    {
      name: 'retourChoixLiberationIntegraleOuPartielle',
      from: 'liste_fin',
      to: 'choix_liberation_integrale_ou_partielle',
    },
    {
      name: 'retourInfoMontantDesApports',
      from: 'liste_fin',
      to: 'info_montant_des_apports',
    },
    {
      name: 'retourChoixDuGerant',
      from: 'liste_fin',
      to: 'choix_du_gerant',
    },
    {
      name: 'retourInfoGerant',
      from: 'liste_fin',
      to: 'info_gerant',
    },
    {
      name: 'retourChoixNombreActesPrealables',
      from: 'liste_fin',
      to: 'choix_nombre_actes_prealables',
    },
    {
      name: 'retourInfoActesPrealable',
      from: 'liste_fin',
      to: 'info_actes_prealable',
    },
    {
      name: 'retourChoixCommissaireAuxComptes',
      from: 'liste_fin',
      to: 'choix_commissaire_aux_comptes',
    },

    {
      name: 'retourInfoCommissaireAuxComptes',
      from: 'liste_fin',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'choix_nom_propre_ou_societe',
      to: 'info_commissaire_aux_comptes',
    },
    {
      name: 'goRecap',
      from: 'choix_seul_ou_a_plusieurs',
      // to: 'liste_fin',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'choix_type_societe',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'info_societe',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'info_societe2',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'info_societe3',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'choix_nombre_partenaires_ou_type_partenaire',
      to: 'liste_fin',
    },

    {
      name: 'goRecap',
      from: 'info_personne_morale',
      to: 'liste_fin',
    },

    {
      name: 'goRecap',
      from: 'info_partenaire',
      to: 'liste_fin',
    },

    {
      name: 'goRecap',
      from: 'choix_situation_familiale',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'info_mariage',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'choix_contrat_de_mariage',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'info_pacs',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'choix_type_de_pacs',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'info_apports_partenaire',
      to: 'liste_fin',
    },

    {
      name: 'goRecap',
      from: 'choix_partenaire_supplementaire',
      to: 'liste_fin',
    },

    {
      name: 'goRecap',
      from: 'info_apports_de_societe',
      to: 'liste_fin',
    },

    {
      name: 'goRecap',
      from: 'info_capital_de_societe',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'choix_liberation_integrale_ou_partielle',
      to: 'liste_fin',
    },

    {
      name: 'goRecap',
      from: 'info_montant_des_apports',
      to: 'liste_fin',
    },

    {
      name: 'goRecap',
      from: 'choix_du_gerant',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'info_gerant',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'choix_nombre_actes_prealables',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'info_actes_prealable',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'choix_commissaire_aux_comptes',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'goRecap',
      from: 'info_commissaire_aux_comptes',
      to: 'info_commissaire_aux_comptes',
    },

    // demarage des conditions prev pour le bouton back

    // {
    //   name: 'prev',
    //   from: 'choix_seul_ou_a_plusieurs',
    //   to: 'choix_nom_propre_ou_societe',
    // },
    // {
    //   name: 'prev',
    //   from: 'choix_nombre_partenaires_ou_type_partenaire',
    //   to: 'choix_seul_ou_a_plusieurs',
    // },
    // {
    //   name: 'prev',
    //   from: 'choix_type_societe',
    //   to: 'choix_seul_ou_a_plusieurs',
    //   // to: 'choix_nombre_partenaires_ou_type_partenaire',
    // },
    // {
    //   name: 'prev',
    //   from: 'info_societe',
    //   to: 'choix_type_societe',
    // },
    {
      name: 'prev',
      from: 'info_societe2',
      to: 'info_societe',
    },
    {
      name: 'prev',
      from: 'info_societe3',
      to: 'info_societe2',
    },
    {
      name: 'prev',
      from: 'choix_nombre_partenaires_ou_type_partenaire',
      to: 'info_societe3',
    },

    {
      name: 'prev',
      from: 'info_capital_de_societe',
      to: 'info_gerant',
    },
    {
      name: 'prevCapitalVersChoixDuGerant',
      from: 'info_capital_de_societe',
      to: 'choix_du_gerant',
    },
    {
      name: 'prev',
      from: 'choix_liberation_integrale_ou_partielle',
      to: 'info_capital_de_societe',
    },
    {
      name: 'prevLiberationVersCapital',
      from: 'choix_liberation_integrale_ou_partielle',
      to: 'info_capital_de_societe',
    },
    {
      name: 'prev',
      from: 'info_notaire_bbf',
      to: 'info_capital_de_societe',
    },
    {
      name: 'prev',
      from: 'info_montant_des_apports',
      to: 'choix_liberation_integrale_ou_partielle',
    },
    {
      name: 'prev',
      from: 'choix_liberation_integrale_ou_partielle',
      to: 'info_notaire_bbf',
    },
    {
      name: 'prevLiberationVersCapital',
      from: 'choix_liberation_integrale_ou_partielle',
      to: 'info_capital_de_societe',
    },
    {
      name: 'prev',
      from: 'choix_du_gerant',
      to: 'info_montant_des_apports',
    },
    {
      name: 'prevGerantChoixLiberation',
      from: 'choix_du_gerant',
      to: 'choix_liberation_integrale_ou_partielle',
    },
    {
      name: 'prev',
      from: 'info_gerant',
      to: 'choix_du_gerant',
    },
    {
      name: 'prev',
      from: 'choix_nombre_actes_prealables',
      to: 'info_montant_des_apports',
    },
    {
      name: 'prevActesVersChoixLiberation',
      from: 'choix_nombre_actes_prealables',
      to: 'choix_liberation_integrale_ou_partielle',
    },
    {
      name: 'prevActesVersCapital',
      from: 'choix_nombre_actes_prealables',
      to: 'info_capital_de_societe',
    },
    {
      name: 'prevNbActesPrealablesVersChoixGerant',
      from: 'choix_nombre_actes_prealables',
      to: 'choix_du_gerant',
    },
    {
      name: 'prev',
      from: 'info_actes_prealable',
      to: 'choix_nombre_actes_prealables',
    },
    {
      name: 'prev',
      from: 'choix_commissaire_aux_comptes',
      to: 'info_gerant',
    },
    {
      name: 'prevChoixCACVersInfoGerant',
      from: 'choix_commissaire_aux_comptes',
      to: 'info_gerant',
    },
    {
      name: 'prev',
      from: 'choix_commissaire_aux_comptes',
      to: 'info_actes_prealable',
    },
    {
      name: 'prevChoixCACVersChoixActesPrealables',
      from: 'choix_commissaire_aux_comptes',
      to: 'choix_nombre_actes_prealables',
    },
    {
      name: 'prev',
      from: 'info_commissaire_aux_comptes',
      to: 'choix_commissaire_aux_comptes',
    },
    {
      name: 'prev',
      from: 'choix_procuration_bbf',
      to: 'info_commissaire_aux_comptes',
    },

    {
      name: 'prev',
      from: 'liste_fin',
      to: 'choix_procuration_bbf',
    },
    {
      name: 'prev',
      from: 'recap',
      to: 'liste_fin',
    },
    // fin des conditions prev pour le bouton back
  ],
  methods: {
    onDemandeSiSeulOuAPlusieurs: () => {
      // return Choix_seul_ou_a_plusieurs;
    },
    onDemandeChoisirNombreDePartenairesOuTypeDePartenaire: () => {
      // return Choix_nombre_partenaires_ou_type_partenaire;
    },
    onDemandeChoisirTypeDeSociete: () => {
      // return Choix_type_societe;
    },
    onDemandeInfoDeLaSociete: () => {
      // return Info_societe;
    },
    onDemandeInfoDeLaPersonneMorale: () => {
      // return Info_personne_morale;
    },
    onDemandeInfoDuPartenaire: () => {
      // return Info_partenaire;
    },
    onDemandeChoisirSituationFamiliale: () => {
      // return Choix_situation_familiale;
    },
    onDemandeInfoDuMariage: () => {
      // return Info_mariage;
    },
    onDemandeChoisirSiContratDeMariage: () => {
      // return Choix_contrat_de_mariage;
    },
    onDemandeInfoDuContratDeMariage: () => {
      // return Info_contrat_de_mariage;
    },
    onDemandeInfoDuPacs: () => {
      // return Info_pacs;
    },
    onDemandeChoisirLeTypeDePacs: () => {
      // return Choix_type_de_pacs;
    },

    onDemandeInfoApportsPartenaire: () => {
      // return ;
    },

    onDemandeChoisirSiPartenaireSupplementaire: () => {
      // return ChoixPartenaireSupplementaire;
    },

    onDemandeInfoSurLeCapitalDeLaSociete: () => {
      // return Info_capital_de_societe;
    },
    onDemandeInfoSurLesApportsDeLaSociete: () => {
      // return Info_apports_de_societe;
    },
    onDemandeChoisirLiberationIntegraleOuPartielle: () => {
      // return Choix_liberation_integrale_ou_partielle;
    },
    onDemandeInfoSurLeMontantDesApports: () => {
      // return Info_montant_des_apports;
    },
    onDemandeChoisirLeGerant: () => {
      // return Choix_du_gerant;
    },
    onDemandeInfoSurLeGerant: () => {
      // return Info_gerant;
    },
    onDemandeChoisirLeNombreActesPrealable: () => {
      // return Choix_nombre_actes_prealables;
    },
    onDemandeInfoSurLesActesPrealables: () => {
      // return Info_actes_prealable;
    },
    onDemandeChoisirSiCommissaireAuxComptes: () => {
      // return Choix_commissaire_aux_comptes;
    },
    onDemandeInfoSurLeCommissaireAuxComptes: () => {
      // console.log('demande infos sur le commissaire aux comptes');
      // return Info_commissaire_aux_comptes;
    },
    onDemandeListeFin: () => {
      // console.log('montre la liste de fin');
    },
    // les retour depuis le listing de fin

    // onDemandeRetour: () => {
    //   // console.log('montre la premiere page');
    // },
  },
});

export default SciAutomate;
