import person from './physical_person';

const partner = {
  forme_juridique_personne_morale: '',
  capital_personne_morale: '',
  raison_sociale_actionnaire_personne_morale_sasu: '',
  adresse_actionnaire_personne_morale_sasu: '',
  code_postal_actionnaire_personne_morale_sasu: '',
  ville_actionnaire_personne_morale_sasu: '',
  rcs_actionnaire_personne_morale_sasu: '',
  ville_rcs_actionnaire_personne_morale_sasu: '',

  titre_dirigeant_de_personne_morale_sasu: '',
  nom_dirigeant_de_personne_morale_sasu: '',
  prenom_dirigeant_de_personne_morale_sasu: '',
  adresse_dirigeant_de_personne_morale_sasu: '',
  code_postal_dirigeant_de_personne_morale_sasu: '',
  ville_dirigeant_de_personne_morale_sasu: '',
  ...person,
};

export default partner;
