const represente = {
  represente_nom: '',
  represente_prenom: '',
  represente_adresse: '',
  represente_code_postal: '',
  represente_ville: '',
  represente_date_naissance: '',
  represente_nationalite: '',
  represente_profession: '',
  represente_vote: 'pour',
  represente_representant: '',
};

export default represente;
