import React, { createContext } from 'react';

import useLocalStorageReducer from '../hooks/useLocalStorageReducer';
// import formReducer from '../reducers/form.reducer';
import formReducer from '../reducers/form.reducer';
// import defaultForm from '../models/association-initial.context';
import defaultForm from '../models/association-initial.context';
// const defaultPartners = [
//   {
//     partner_title: 'M.',
//     partner_name: '',
//     partner_firstname: '',
//     partner_father_name: '',
//     partner_father_firstname: '',
//     partner_mother_name: '',
//     partner_mother_firstname: '',
//     partner_date_of_birth: null,
//     partner_place_of_birth: '',
//     partner_state_of_birth: '',
//     partner_country_of_birth: '',
//     partner_nationality: '',
//     partner_place_of_living: '',
//     partner_city: '',
//     partner_zipcode: '',
//     partner_family_situation: '',
//     partner_wedding_prenup_type: '',
//     partner_spouse_name: '',
//     partner_spouse_firstname: '',
//     partner_spouse_date_naissance: '',
//     partner_spouse_lieu_naissance: '',
//     partner_spouse_departement_naissance: '',
//     partner_wedding_date: null,
//     partner_wedding_place: '',
//     partner_wedding_prenup: '',
//     partner_wedding_prenup_date: '',
//     partner_wedding_prenup_place: '',
//     partner_wedding_prenup_officer_office_name: '',
//     partner_wedding_prenup_officer_office_place: '',
//     pacs_spouse_name: '',
//     pacs_spouse_firstname: '',
//     pacs_spouse_date_naissance: null,
//     pacs_spouse_lieu_naissance: '',
//     pacs_spouse_departement_naissance: '',
//     pacs_date: null,
//     pacs_place: '',
//     pacs_type: '',
//   },

//   { id: '1', name: 'Pierre Pas', spouse: true, spouseName: 'Jeanne' },
//   { id: '2', name: 'Catherine Aussi', spouse: true, spouseName: 'Marc' },
// ];

export const DispatchContext = createContext();

export const FormContext = createContext();

// export { PartnersConsumer };
export const FormProvider = (props) => {
  const { kit } = props;
  const [form, dispatch] = useLocalStorageReducer(
    'association-form',
    formReducer,
    defaultForm(kit)
  );
  //   const partners = defaultPartners;
  //   const dispatch = (val) => // console.log('dispatch', val);

  return (
    <FormContext.Provider value={form}>
      <DispatchContext.Provider value={dispatch}>
        {/* eslint-disable-next-line react/prop-types */}
        {props.children}
      </DispatchContext.Provider>
    </FormContext.Provider>
  );
};
