import {
  basiquesocDocs,
  kit1socDocs,
  kit2socDocs,
  kit3socDocs,
  kit4socDocs,
} from './kitSociete';
import {
  basiquesciDocs,
  kit1sciDocs,
  kit2sciDocs,
  kit3sciDocs,
  kit4sciDocs,
} from './kitSci';

const listOfKit = [
  {
    name: 'Société - Kit Statut- Votre dossier juridique',
    kit: 'basiquesoc',
    docs: basiquesocDocs,
  },
  {
    name: 'Société - Kit "on s\'occupe de tout" - 149€',
    kit: 'kit1soc',
    docs: kit1socDocs,
  },
  {
    name: 'Société - Kit "on s\'occupe de tout" - 279€',
    kit: 'kit2soc',
    docs: kit2socDocs,
  },
  {
    name: 'Société - Kit "on s\'occupe de tout" - 299€',
    kit: 'kit3soc',
    docs: kit3socDocs,
  },
  {
    name: 'Société - Kit "on s\'occupe de tout" - 399€',
    kit: 'kit4soc',
    docs: kit4socDocs,
  },
  // {
  //   name: 'Auto - Kit accompagnement - Immatriculation',
  //   kit: 'auto',
  //   docs: basiquesocDocs,
  // },
  // {
  //   name: 'EI - Kit accompagnement EI - Immatriculation',
  //   kit: 'ei',
  //   docs: basiquesocDocs,
  // },
  // {
  //   name: 'EI - Kit accompagnement EIRL - Immatriculation',
  //   kit: 'eirl',
  //   docs: basiquesocDocs,
  // },
  {
    name: 'SCI - Kit statuts votre dossier juridique',
    kit: 'basiquesci',
    docs: basiquesciDocs,
  },
  {
    name: 'SCI - Kit "on s\'occupe de tout" - 149€',
    kit: 'kit1sci',
    docs: kit1sciDocs,
  },
  {
    name: 'SCI - Kit "on s\'occupe de tout" - 279€',
    kit: 'kit2sci',
    docs: kit2sciDocs,
  },
  {
    name: 'SCI - Kit "on s\'occupe de tout" - 299€',
    kit: 'kit3sci',
    docs: kit3sciDocs,
  },
  {
    name: 'SCI - Kit "on s\'occupe de tout" - 399€',
    kit: 'kit4sci',
    docs: kit4sciDocs,
  },
];

export default listOfKit;
