import { SCI } from 'constants/companies';
import { getYear } from 'date-fns';
import { getLastDayOfYear } from './companyDateHelper';

const initialValues = (values) => {
  const currentYear = getYear(new Date());
  return {
    eurl_sasu: values.eurl_sasu,
    type_depart_activite: values.type_depart_activite || '',
    closing_date_with_year:
      values.eurl_sasu === SCI
        ? getLastDayOfYear(currentYear)
        : values.closing_date_with_year || null,
    date_debut_activite: values.date_debut_activite || null,
  };
};
export default initialValues;
