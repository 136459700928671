import React from 'react';
import PropTypes from 'prop-types';

import { Paper as PaperMUI } from '@material-ui/core';

const Paper = ({ elevation = 3, children, ...rest }) => (
  <PaperMUI elevation={elevation} {...rest}>
    {children}
  </PaperMUI>
);

Paper.propTypes = { elevation: PropTypes.number, children: PropTypes.node };
export default Paper;
