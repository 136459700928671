import userService from 'services/userService';
import companyService from 'services/companyService';

import { setUserId, setCompanyId } from 'reducers/appReducer';
import {
  fetchingLoggedUser,
  fetchLoggedUserSuccess,
  fetchLoggedUserFailed,
  fetchingUserCompanies,
  fetchUserCompaniesSuccess,
  fetchUserCompaniesFailed,
} from 'reducers/loggedUserReducer';

export const fetchLoggedUser = () => async (dispatch) => {
  dispatch(fetchingLoggedUser());

  try {
    const { data } = await userService.getAuthUser();

    if (data) {
      const { id: userOId } = data;

      dispatch(fetchLoggedUserSuccess({ ...data }));
      dispatch(setUserId(userOId));
    } else {
      dispatch(fetchLoggedUserFailed());
    }
  } catch (error) {
    dispatch(fetchLoggedUserFailed(error));
  }
};

export const fetchUserCompanies = (query) => async (dispatch) => {
  let result;
  dispatch(fetchingUserCompanies());

  try {
    const { data } = await companyService.fetchUserCompanies(query);

    if (data) {
      dispatch(fetchUserCompaniesSuccess({ ...data }));
      result = data;
    } else {
      dispatch(fetchUserCompaniesFailed());
    }
  } catch (error) {
    dispatch(fetchUserCompaniesFailed(error));
  }

  return result;
};

export const selectCompany = (companyId) => (dispatch) => {
  dispatch(setCompanyId({ companyId }));
};
