import { genders, cacTypes, CAC_PM, CAC_PP } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import Adresse from '../commons/adress';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';
import InfoCACSupleantPM from './InfoCACSupleantPM';
import InfoCACSupleantPP from './InfoCACSupleantPP';

function InfoPP() {
  const classes = useStyles();
  const {
    values: { titre_commissaire_aux_comptes, type_CAC_suppleant },
  } = useFormikContext();

  return (
    <>
      <SmallRadio
        classes={classes}
        options={genders}
        value={titre_commissaire_aux_comptes}
        name="titre_commissaire_aux_comptes"
        style={{ marginBottom: '3%' }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom du commissaire aux comptes titulaire *"
        name="nom_commissaire_aux_comptes"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom du commissaire aux comptes titulaire *"
        name="prenom_commissaire_aux_comptes"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        title="Adresse du commissaire aux comptes titulaire *"
        address="adresse_cabinet_commissaire_aux_comptes"
        city="city_cabinet_commissaire_aux_comptes"
        zipCode="zipCode_cabinet_commissaire_aux_comptes"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        additionalAddress="additional_address_cabinet_commissaire_aux_comptes"
      />
      {/* <Field
        component={TextField}
        type="string"
        label="Adresse du commissaire aux comptes titulaire *"
        name="adresse_cabinet_commissaire_aux_comptes"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      /> */}
      <h2 className={classes.subTitle}>
        Informations du Commissaire aux Comptes suppléant.
      </h2>
      <SmallRadio
        classes={classes}
        title="Type du commissaire aux comptes suppléant."
        options={cacTypes}
        value={type_CAC_suppleant}
        name="type_CAC_suppleant"
        style={{ marginBottom: '5%' }}
      />
      {type_CAC_suppleant === CAC_PP && <InfoCACSupleantPP />}
      {type_CAC_suppleant === CAC_PM && <InfoCACSupleantPM />}
    </>
  );
}

export default InfoPP;
