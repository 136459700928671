export const cleanInfoBailleur = (setFieldValue) => {
  setFieldValue('prenom_bailleur', '');
  setFieldValue('nom_bailleur', '');
  setFieldValue('adresse_bailleur', '');
  setFieldValue('date_bail', null);
  setFieldValue('email_bailleur', '');
};

export const cleanInfoSyndic = (setFieldValue) => {
  setFieldValue('prenom_syndic', '');
  setFieldValue('nom_syndic', '');
  setFieldValue('adresse_syndic', '');
};
