import { YES, yesNo, numberOfContract } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React, { useEffect } from 'react';
import SmallRadio from '../commons/smallRadio';
import useStyles from '../style';

function LegalActs() {
  const classes = useStyles();
  const {
    values: { preexisting_contract, number_of_preexisting_contract },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    setFieldValue('number_of_preexisting_contract', 0);
  }, [preexisting_contract]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Actes juridiques</h1>
      <div className={classes.root}>
        <SmallRadio
          classes={classes}
          title={`Avez-vous accompli des actes juridiques pour le compte de
          votre société préalablement à la signature des statuts ?`}
          options={yesNo}
          value={preexisting_contract}
          name="preexisting_contract"
          style={{ marginBottom: '5%' }}
          helpName="legalActe"
        />
        <p className={classes.textInfo}>
          {`Les actes concernés doivent avoir été effectués au nom et pour le
          compte de la société en formation. Exemple : signature d'une promesse
          de bail commercial, signature de propositions commerciales pour la
          création d'un site web, achats de matériels...`}
        </p>
        {YES === preexisting_contract && (
          <>
            <SmallRadio
              classes={classes}
              title={`Avez-vous accompli des actes juridiques pour le compte de
           votre société préalablement à la signature des statuts ?`}
              options={numberOfContract}
              value={number_of_preexisting_contract}
              name="number_of_preexisting_contract"
              style={{ marginBottom: '5%' }}
            />
            {parseInt(number_of_preexisting_contract, 10) > 0 && (
              <>
                <h2 className={classes.subTitle}>
                  {`Information sur l'acte préalablement à la création de la
                  société.`}
                </h2>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date du 1er acte *"
                  placeholder="01/01/1990"
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  name="contract1_date"
                  className={classes.nameTextField}
                  inputVariant="outlined"
                  size="small"
                />
                <Field
                  component={TextField}
                  type="text"
                  label="Nature de l'acte n°1"
                  name="contract1_type"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Montant"
                  name="contract1_amount"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
                <Field
                  component={TextField}
                  type="text"
                  label="Payé par"
                  name="contract1_person_who_paid"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
              </>
            )}
            {parseInt(number_of_preexisting_contract, 10) > 1 && (
              <>
                <h2 className={classes.subTitle}>
                  Information sur le deuxième acte préalablement à la création
                  de la société.
                </h2>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date du 2éme acte *"
                  placeholder="01/01/1990"
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  name="contract2_date"
                  className={classes.nameTextField}
                  inputVariant="outlined"
                  size="small"
                />
                <Field
                  component={TextField}
                  type="text"
                  label="Nature de l'acte n°2"
                  name="contract2_type"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
                <Field
                  component={TextField}
                  type="number"
                  label="Montant"
                  name="contract2_amount"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
                <Field
                  component={TextField}
                  type="text"
                  label="Payé par"
                  name="contract2_person_who_paid"
                  className={classes.nameTextField}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default LegalActs;
