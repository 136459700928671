import { createSlice } from '@reduxjs/toolkit';

export const loggedUserReducer = createSlice({
  name: 'loggedUser',
  initialState: {
    user: null,
    userCompanies: null,
    isFetchingUser: false,
    isFetchingUserCompanies: false,
    errorFetchingUser: null,
    errorFetchingUserCompanies: null,
  },
  reducers: {
    fetchingLoggedUser: (state) => ({
      ...state,
      isFetchingUser: true,
      user: null,
      errorFetchingUser: null,
    }),

    fetchLoggedUserSuccess: (state, action) => ({
      ...state,
      isFetchingUser: false,
      user: action.payload,
    }),

    fetchLoggedUserFailed: (state, action) => ({
      ...state,
      user: null,
      isFetchingUser: false,
      errorFetchingUser: action.payload,
    }),

    fetchingUserCompanies: (state) => ({
      ...state,
      isFetchingUserCompanies: true,
      userCompanies: null,
      errorFetchingUserCompanies: null,
    }),

    fetchUserCompaniesSuccess: (state, action) => ({
      ...state,
      isFetchingUserCompanies: false,
      userCompanies: action.payload,
    }),

    fetchUserCompaniesFailed: (state, action) => ({
      ...state,
      userCompanies: null,
      isFetchingUserCompanies: false,
      errorFetchingUser: action.payload,
    }),
  },
});

export const {
  fetchingLoggedUser,
  fetchLoggedUserSuccess,
  fetchLoggedUserFailed,
  fetchingUserCompanies,
  fetchUserCompaniesSuccess,
  fetchUserCompaniesFailed,
} = loggedUserReducer.actions;

export default loggedUserReducer.reducer;
