import {
  YES,
  REQUIRED,
  TIERS,
  PARTNER,
  MARIED,
  PACTE,
  CONCUBIN,
} from 'constants/usersContants';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object().shape({
    presence_dg: Yup.string().required('Champs requis'),
    dg_type: Yup.string().when('presence_dg', {
      is: (presence_dg) => presence_dg === YES,
      then: Yup.string().required(REQUIRED),
    }),
    dgPartnerIDs: Yup.array()
      .of(Yup.string())
      .when(['presence_dg', 'dg_type'], {
        is: (presence_dg, dg_type) =>
          presence_dg === YES && dg_type === PARTNER,
        then: Yup.array()
          .of(Yup.string())
          .min(
            1,
            'Veuillez ajouter un Directeur général, soit parmi les associé(e)s ou veuillez renseigner les informations du tiers.'
          ),
        otherwise: Yup.array().of(Yup.string()).min(0),
      }),
    dg_title: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_name: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_firstname: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_email: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_date_of_birth: Yup.date()
      .when(['presence_dg', 'dg_type'], {
        is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
        then: Yup.date().required(REQUIRED),
      })
      .nullable()
      .typeError('Format incorrect'),
    dg_place_of_birth: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_state_of_birth: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_country_of_birth: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_nationality: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_place_of_living: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_city: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_zipcode: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_father_name: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_father_firstname: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_mother_name: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_mother_firstname: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_secu_number: Yup.string().when(['presence_dg', 'dg_type'], {
      is: (presence_dg, dg_type) => presence_dg === YES && dg_type === TIERS,
      then: Yup.string()
        .required(REQUIRED)
        .nullable(true)
        .matches(
          /^[0-9]{15}$/,
          'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
        ),
    }),

    // validation for "Situation familiale"
    dg_family_situation: Yup.string().when('dg_type', {
      is: (dg_type) => dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    // if has married
    dg_wedding_prenup_type: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === MARIED && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    dg_spouce_title: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === MARIED && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_spouce_name: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === MARIED && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_spouce_firstname: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === MARIED && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_spouce_email: Yup.string()
      .email(`Saisissez une adresse e-mail valide.`)
      .when(['dg_family_situation', 'dg_type'], {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === MARIED && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }),
    dg_spouce_birthday: Yup.date()
      .nullable()
      .when(['dg_family_situation', 'dg_type'], {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === MARIED && dg_type === TIERS,
        then: Yup.date().required(REQUIRED),
      })
      .nullable()
      .typeError('Date est obligatoire'),

    dg_spouce_place_of_birth: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === MARIED && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    dg_spouce_adress: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === MARIED && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_spouce_city: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === MARIED && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_spouce_zipcode: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === MARIED && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_spouce_effective_date: Yup.date()
      .nullable()
      .when(['dg_family_situation', 'dg_type'], {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === MARIED && dg_type === TIERS,
        then: Yup.date().required(REQUIRED),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    dg_spouce_position: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === MARIED && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_spouce_secu_number: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === MARIED && dg_type === TIERS,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      }
    ),
    dg_wedding_prenup: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === MARIED && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),

    // if has pacse
    dg_pacs_type: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === PACTE && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_pacse_title: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === PACTE && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_pacse_name: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === PACTE && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_pacse_firstname: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === PACTE && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_pacse_email: Yup.string()
      .email(`Saisissez une adresse e-mail valide.`)
      .when(['dg_family_situation', 'dg_type'], {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === PACTE && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }),
    dg_pacse_birthday: Yup.date()
      .nullable()
      .when(['dg_family_situation', 'dg_type'], {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === PACTE && dg_type === TIERS,
        then: Yup.date().required(REQUIRED),
      })
      .nullable()
      .typeError('Date est obligatoire'),

    dg_pacse_place_of_birth: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === PACTE && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    dg_pacse_adress: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === PACTE && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_pacse_city: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === PACTE && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_pacse_zipcode: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === PACTE && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_pacse_effective_date: Yup.date()
      .nullable()
      .when(['dg_family_situation', 'dg_type'], {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === PACTE && dg_type === TIERS,
        then: Yup.date().required(REQUIRED),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    dg_pacse_position: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === PACTE && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_pacse_secu_number: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === PACTE && dg_type === TIERS,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      }
    ),
    // if has concubin
    dg_cohabitant_title: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === CONCUBIN && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_cohabitant_name: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === CONCUBIN && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_cohabitant_firstname: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === CONCUBIN && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    dg_cohabitant_email: Yup.string()
      .email(`Saisissez une adresse e-mail valide.`)
      .when(['dg_family_situation', 'dg_type'], {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === CONCUBIN && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }),
    dg_cohabitant_birthday: Yup.date()
      .nullable()
      .when(['dg_family_situation', 'dg_type'], {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === CONCUBIN && dg_type === TIERS,
        then: Yup.date().required(REQUIRED),
      })
      .nullable()
      .typeError('Date est obligatoire'),

    dg_cohabitant_place_of_birth: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === CONCUBIN && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    dg_cohabitant_adress: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === CONCUBIN && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    dg_cohabitant_city: Yup.string().when(['dg_family_situation', 'dg_type'], {
      is: (dg_family_situation, dg_type) =>
        dg_family_situation === CONCUBIN && dg_type === TIERS,
      then: Yup.string().required(REQUIRED),
    }),
    dg_cohabitant_zipcode: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === CONCUBIN && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    dg_cohabitant_effective_date: Yup.date()
      .nullable()
      .when(['dg_family_situation', 'dg_type'], {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === CONCUBIN && dg_type === TIERS,
        then: Yup.date().required(REQUIRED),
      })
      .nullable()
      .typeError('Date est obligatoire'),
    dg_cohabitant_position: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === CONCUBIN && dg_type === TIERS,
        then: Yup.string().required(REQUIRED),
      }
    ),
    dg_cohabitant_secu_number: Yup.string().when(
      ['dg_family_situation', 'dg_type'],
      {
        is: (dg_family_situation, dg_type) =>
          dg_family_situation === CONCUBIN && dg_type === TIERS,
        then: Yup.string()
          .required(REQUIRED)
          .nullable(true)
          .matches(
            /^[0-9]{15}$/,
            'Le numéro de sécurité sociale doit contenir 15 chiffres incluant la clé.'
          ),
      }
    ),
  });
};

//  eslint-disable-next-line
export { validationSchema };
