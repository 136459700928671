import React from 'react';
// import { Form, Field, Formik } from 'formik';
import AutoentrepreneurForm from 'containers/AutoentrepreneurForm';
import { FormProvider } from '../contexts/autoentrepreneur.context';

const Autoentrepreneur = () => {
  const query = new URLSearchParams(window.location.search);

  const kit = query.get('kit') || '';

  return (
    <FormProvider kit={kit}>
      <AutoentrepreneurForm />
    </FormProvider>
  );
};
export default Autoentrepreneur;
