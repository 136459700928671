import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import { RadioGroup, TextField } from 'formik-material-ui';
import {
  // Button,
  Box,
  Typography,
  FormControlLabel,
  Radio,
  MenuItem,
  Grid,
  // Checkbox,
} from '@material-ui/core';
import DebugFormik from '../DebugFormik';
import ButtonBox from '../entreprise/ButtonBox';
import { DispatchContext } from '../../contexts/autoentrepreneur.context';
import { EDIT_COMPANY } from '../../constants/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    justifyContent: 'center',
    width: '100%',
  },
}));

const FinalDeMaDeclaration = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  // setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        setActiveStep(activeStep + 1);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Box
            p={1}
            bgcolor="background.paper"
            size="fullwidth"
            className={classes.root}
          >
            <Typography variant="h3">
              4. Finalisation de ma déclaration
            </Typography>

            <Typography variant="h5">Points complémentaires</Typography>

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Cette demande a été effectuée par un mandataire ?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={RadioGroup}
                  name="cette_demande_a_été_effectuée_par_un_mandataire"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>
            <br />

            {/* If Oui */}
            {values.cette_demande_a_été_effectuée_par_un_mandataire ===
              'true' && (
              <div>
                <Typography variant="h5">Points complémentaires</Typography>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Nom, Prénom(s) ou Dénomination
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="nom_Prénom_ou_Dénomination"
                      type="text"
                      label="Nom, Prénom(s) ou Dénomination"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Numéro de voie</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      fullWidth
                      component={TextField}
                      select
                      margin="normal"
                      name="numéroz_de_voie4"
                      label="Numéro de voie"
                    >
                      <MenuItem value selected="true"></MenuItem>
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B (Bis)</MenuItem>
                      <MenuItem value="C">C (Quinquies)</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                      <MenuItem value="E">E</MenuItem>
                      <MenuItem value="F">F</MenuItem>
                      <MenuItem value="G">G</MenuItem>
                      <MenuItem value="H">H</MenuItem>
                      <MenuItem value="I">I</MenuItem>
                      <MenuItem value="J">J</MenuItem>
                      <MenuItem value="K">K</MenuItem>
                      <MenuItem value="L">L</MenuItem>
                      <MenuItem value="M">M</MenuItem>
                      <MenuItem value="N">N</MenuItem>
                      <MenuItem value="O">O</MenuItem>
                      <MenuItem value="P">P</MenuItem>
                      <MenuItem value="Q">Q (Quater)</MenuItem>
                      <MenuItem value="R">R</MenuItem>
                      <MenuItem value="S">S</MenuItem>
                      <MenuItem value="T">T (Ter)</MenuItem>
                      <MenuItem value="U">U</MenuItem>
                      <MenuItem value="V">V</MenuItem>
                      <MenuItem value="W">W</MenuItem>
                      <MenuItem value="X">X</MenuItem>
                      <MenuItem value="Y">Y</MenuItem>
                      <MenuItem value="Z">Z</MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Type de voie</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      fullWidth
                      component={TextField}
                      select
                      margin="normal"
                      name="type_de_voie4"
                      label="Type de voie"
                    >
                      <MenuItem value selected="true"></MenuItem>
                      <MenuItem value="ALL">Allée</MenuItem>
                      <MenuItem value="AV">Avenue</MenuItem>
                      <MenuItem value="BD">Boulevard</MenuItem>
                      <MenuItem value="CAR">Carrefour</MenuItem>
                      <MenuItem value="CHE">Chemin</MenuItem>
                      <MenuItem value="COR">Corniche</MenuItem>
                      <MenuItem value="CRS">Cours</MenuItem>
                      <MenuItem value="ECA">Ecart</MenuItem>
                      <MenuItem value="GR">Grand Rue</MenuItem>
                      <MenuItem value="HAM">Hameau</MenuItem>
                      <MenuItem value="IMP">Impasse</MenuItem>
                      <MenuItem value="JTE">Jetée</MenuItem>
                      <MenuItem value="MTE">Montée</MenuItem>
                      <MenuItem value="PAS">Pas (sage)</MenuItem>
                      <MenuItem value="PL">Place</MenuItem>
                      <MenuItem value="PRO">Promenade</MenuItem>
                      <MenuItem value="QUAI">Quai</MenuItem>
                      <MenuItem value="RTE">Route</MenuItem>
                      <MenuItem value="RUE">Rue</MenuItem>
                      <MenuItem value="SQ">Square</MenuItem>
                      <MenuItem value="TPL">Terre-Plein</MenuItem>
                      <MenuItem value="TRT">Tertre</MenuItem>
                      <MenuItem value="TRA">Traverse</MenuItem>
                      <MenuItem value="VLA">Villa</MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Libellé de voie ou de lieu-dit
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="libellé_de_voie_ou_de_lieu_dit4"
                      type="text"
                      label="Libellé de voie ou de lieu-dit"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Complément</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="complément4"
                      type="text"
                      label="Complément"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Commune ou code postal</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="commune_ou_code_postal4"
                      type="text"
                      label="Commune ou code postal"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Bureau distributeur</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="bureau_distributeur4"
                      type="text"
                      label="Bureau distributeur"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Distribution spéciale</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      className={classes.field}
                      component={TextField}
                      fullWidth
                      name="distribution_spéciale4"
                      type="text"
                      label="Distribution spéciale"
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {/* End */}

            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  J&apos;autorise les informations enregistrées dans le
                  répertoire SIRENE à être consultables sur le site INSEE et
                  utilisables par des tiers
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={RadioGroup}
                  name="radio5"
                  className={classes.radio}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="oui"
                        control={<Radio />}
                        label="Oui"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="non"
                        control={<Radio />}
                        label="Non"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>
            <br />
            <Typography variant="h6">Observations</Typography>
            <Field
              className={classes.field}
              component={TextField}
              fullWidth
              name="observations"
              type="text"
              label="Observations"
            />
          </Box>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

export default FinalDeMaDeclaration;
