import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  // MenuItem,
} from '@material-ui/core';

import { RadioGroup } from 'formik-material-ui';
// import { Select } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
// import KeyboardDatePicker from 'components/KeyboardDatePicker';

// import FormControl from '@material-ui/core/FormControl';

// import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import Paper from '../surface/Paper';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '90ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '80ch',
  },
  radio: {},
}));

const DureeAssociation = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  {' '}
                  Membres de l&apos;association{' '}
                </Typography>
              </Box>
              <Box className={classes.root}>
                <Field component={RadioGroup} name="membres_agrees">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Les membres doivent-ils être agréés par l&apos;organe de
                      direction ?
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>
              <Box className={classes.root}>
                <Field component={RadioGroup} name="refus_agrement_motive">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Le refus d&apos;agrément doit-il être motivé?
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>
              <Box className={classes.root}>
                <Typography variant="h6">
                  {' '}
                  Les catégories de membres dans l&apos;association :
                </Typography>{' '}
              </Box>

              <Box className={classes.root}>
                <Field component={RadioGroup} name="fondateurs">
                  <Box>
                    <Typography variant="subtitle1"> Fondateurs :</Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>
              <Box className={classes.root}>
                <Field component={RadioGroup} name="adherents">
                  <Box>
                    <Typography variant="subtitle1"> Adhérents :</Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>
              <Box className={classes.root}>
                <Field component={RadioGroup} name="bienfaiteurs">
                  <Box>
                    <Typography variant="subtitle1"> Bienfaiteurs :</Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>
              <Box className={classes.root}>
                <Field component={RadioGroup} name="membres_de_droit">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Membres de droit :
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

DureeAssociation.propTypes = {
  nextForm: PropTypes.func,
};
export default DureeAssociation;
