import { CAC_PP, CAC_PM, REQUIRED, YES } from 'constants/usersContants';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object().shape({
    presence_commissaire_aux_comptes: Yup.string().required('Champs requis'),
    duree_exercice_commissaire_aux_comptes: Yup.number().when(
      'presence_commissaire_aux_comptes',
      {
        is: (presence_commissaire_aux_comptes) =>
          presence_commissaire_aux_comptes === YES,
        then: Yup.number().required(REQUIRED),
      }
    ),
    type_CAC: Yup.string().when('presence_commissaire_aux_comptes', {
      is: (presence_commissaire_aux_comptes) =>
        presence_commissaire_aux_comptes === YES,
      then: Yup.string().required(REQUIRED),
    }),
    titre_commissaire_aux_comptes: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC) =>
          presence_commissaire_aux_comptes === YES && type_CAC === CAC_PP,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    nom_commissaire_aux_comptes: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC) =>
          presence_commissaire_aux_comptes === YES && type_CAC === CAC_PP,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    prenom_commissaire_aux_comptes: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC) =>
          presence_commissaire_aux_comptes === YES && type_CAC === CAC_PP,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    adresse_cabinet_commissaire_aux_comptes: Yup.string().when(
      'presence_commissaire_aux_comptes',
      {
        is: (presence_commissaire_aux_comptes) =>
          presence_commissaire_aux_comptes === YES,
        then: Yup.string().required(REQUIRED),
      }
    ),

    // If CAC is CAC_PM
    forme_juridique_cabinet_CAC: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC) =>
          presence_commissaire_aux_comptes === YES && type_CAC === CAC_PM,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    nom_cabinet_commissaire_aux_comptes: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC) =>
          presence_commissaire_aux_comptes === YES && type_CAC === CAC_PM,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    cac_suppleant: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC) =>
          presence_commissaire_aux_comptes === YES && type_CAC === CAC_PM,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),

    type_CAC_suppleant: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC) =>
          presence_commissaire_aux_comptes === YES && type_CAC === CAC_PP,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),

    // If suppleant is PP
    titre_suppleant: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC', 'type_CAC_suppleant'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC, type_CAC_suppleant) =>
          presence_commissaire_aux_comptes === YES &&
          type_CAC === CAC_PP &&
          type_CAC_suppleant === CAC_PP,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    nom_suppleant: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC', 'type_CAC_suppleant'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC, type_CAC_suppleant) =>
          presence_commissaire_aux_comptes === YES &&
          (type_CAC === CAC_PP || type_CAC === CAC_PM) &&
          (type_CAC_suppleant === CAC_PP || type_CAC_suppleant === CAC_PM),
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    prenom_suppleant: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC', 'type_CAC_suppleant'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC, type_CAC_suppleant) =>
          presence_commissaire_aux_comptes === YES &&
          (type_CAC === CAC_PP || type_CAC === CAC_PM) &&
          type_CAC_suppleant === CAC_PP,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    adresse_cabinet_suppleant: Yup.string().when(
      ['presence_commissaire_aux_comptes', 'type_CAC', 'type_CAC_suppleant'],
      {
        is: (presence_commissaire_aux_comptes, type_CAC, type_CAC_suppleant) =>
          presence_commissaire_aux_comptes === YES &&
          (type_CAC === CAC_PP || type_CAC === CAC_PM) &&
          (type_CAC_suppleant === CAC_PP || type_CAC_suppleant === CAC_PM),
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
  });
};

//  eslint-disable-next-line
export { validationSchema };
