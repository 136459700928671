import { PARTNER } from 'constants/usersContants';

const initialValues = (values) => {
  return {
    gerantOuPresident: values.gerantOuPresident,
    geranceoupresidence: values.geranceoupresidence,
    associe_gerant_multiassocie: values.associe_gerant_multiassocie || [],
    director_type: values.director_type || PARTNER,
    director_title: values.director_title || '',
    director_name: values.director_name || '',
    director_firstname: values.director_firstname || '',
    director_father_name: values.director_father_name || '',
    director_father_firstname: values.director_father_firstname || '',
    director_mother_name: values.director_mother_name || '',
    director_mother_firstname: values.director_mother_firstname || '',
    director_date_of_birth: values.director_date_of_birth || null,
    director_place_of_birth: values.director_place_of_birth || '',
    director_place_of_birth_zipCode:
      values.director_place_of_birth_zipCode || '',
    director_state_of_birth: values.director_state_of_birth || '',
    director_country_of_birth: values.director_country_of_birth || '',
    director_nationality: values.director_nationality || '',
    director_place_of_living: values.director_place_of_living || '',
    director_zipcode: values.director_zipcode || '',
    director_city: values.director_city || '',
    director_email: values.director_email || '',
    director_secu_number: values.director_secu_number || '',
    duree_gerance_determinee: values.duree_gerance_determinee || '',
    duree_de_gerance: values.duree_de_gerance || 0,

    // info spouce of director
    director_family_situation: values.director_family_situation || '',

    // info wedding
    director_wedding_prenup_type: values.director_wedding_prenup_type || '',
    director_wedding_prenup: values.director_wedding_prenup || '',
    // director_wedding_prenup = yes
    director_wedding_prenup_date: values.director_wedding_prenup_date || '',
    director_wedding_prenup_place: values.director_wedding_prenup_place || '',
    director_wedding_prenup_officer_office_name:
      values.director_wedding_prenup_officer_office_name || '',
    director_wedding_prenup_officer_office_address:
      values.director_wedding_prenup_officer_office_address || '',
    director_wedding_prenup_officer_office_city:
      values.director_wedding_prenup_officer_office_city || '',
    director_wedding_prenup_officer_office_zipcode:
      values.director_wedding_prenup_officer_office_zipcode || '',
    director_wedding_prenup_officer_office_additional_address:
      values.director_wedding_prenup_officer_office_additional_address || '',
    // info spouce
    director_spouce_title: values.director_spouce_title || '',
    director_spouce_name: values.director_spouce_name || '',
    director_spouce_firstname: values.director_spouce_firstname || '',
    director_spouce_email: values.director_spouce_email || '',
    director_spouce_secu_number: values.director_spouce_secu_number || '',
    director_spouce_birthday: values.director_spouce_birthday || null,
    director_spouce_place_of_birth: values.director_spouce_place_of_birth || '',
    director_spouce_adress: values.director_spouce_adress || '',
    director_spouce_city: values.director_spouce_city || '',
    director_spouce_zipcode: values.director_spouce_zipcode || '',
    director_spouce_additional_adress:
      values.director_spouce_additional_adress || '',
    director_spouce_effective_date:
      values.director_spouce_effective_date || null,
    director_spouce_position: values.director_spouce_position || '',

    // info pacse
    director_pacs_type: values.director_pacs_type || '',
    director_pacse_title: values.director_pacse_title || '',
    director_pacse_name: values.director_pacse_name || '',
    director_pacse_firstname: values.director_pacse_firstname || '',
    director_pacse_email: values.director_pacse_email || '',
    director_pacse_secu_number: values.director_pacse_secu_number || '',
    director_pacse_birthday: values.director_pacse_birthday || null,
    director_pacse_place_of_birth: values.director_pacse_place_of_birth || '',
    director_pacse_adress: values.director_pacse_adress || '',
    director_pacse_city: values.director_pacse_city || '',
    director_pacse_zipcode: values.director_pacse_zipcode || '',
    director_pacse_additional_adress:
      values.director_pacse_additional_adress || '',
    director_pacse_effective_date: values.director_pacse_effective_date || null,
    director_pacse_position: values.director_pacse_position || '',

    // info cohabitant
    director_cohabitant_title: values.director_cohabitant_title || '',
    director_cohabitant_name: values.director_cohabitant_name || '',
    director_cohabitant_firstname: values.director_cohabitant_firstname || '',
    director_cohabitant_email: values.director_cohabitant_email || '',
    director_cohabitant_secu_number:
      values.director_cohabitant_secu_number || '',
    director_cohabitant_birthday: values.director_cohabitant_birthday || null,
    director_cohabitant_place_of_birth:
      values.director_cohabitant_place_of_birth || '',
    director_cohabitant_adress: values.director_cohabitant_adress || '',
    director_cohabitant_city: values.director_cohabitant_city || '',
    director_cohabitant_zipcode: values.director_cohabitant_zipcode || '',
    director_cohabitant_additional_adress:
      values.director_cohabitant_additional_adress || '',
    director_cohabitant_effective_date:
      values.director_cohabitant_effective_date || null,
    director_cohabitant_position: values.director_cohabitant_position || '',
  };
};

// eslint-disable-next-line
export { initialValues };
