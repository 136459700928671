import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Button,
  Box,
  Typography,
  MenuItem,
  // Select,
  // Checkbox,
} from '@material-ui/core';
import DebugFormik from '../DebugFormik';
import ButtonBox from '../entreprise/ButtonBox';
import { DispatchContext } from '../../contexts/autoentrepreneur.context';
import { EDIT_COMPANY } from '../../constants/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    justifyContent: 'center',
    width: '100%',
  },
}));

const ListeDesPiecesJointes = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  // setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        setActiveStep(activeStep + 1);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Box
            p={1}
            bgcolor="background.paper"
            size="fullwidth"
            className={classes.root}
          >
            <Typography variant="h3">6. Liste des pièces jointes</Typography>
            <Typography variant="h6">
              Votre déclaration a bien été enregistrée. Pour finaliser votre
              demande, veuillez joindre une copie de votre pièce
              d&apos;identité, accompagnée de la mention manuscrite
              &quot;J&apos;atteste sur l&apos;honneur que cette pièce
              d&apos;identité est conforme à l&apos;original. Fait à ... le
              ...&quot; et de votre signature.
            </Typography>

            <Typography variant="h6">
              <b>Important :</b>vous disposez d&apos;un délai de 30 jours, avant
              que votre demande ne soit automatiquement supprimée.
            </Typography>

            <Typography variant="h5">
              <b>Pièces jointes</b>
            </Typography>

            {/* <Select labelId="demo-simple-select-label" id="demo-simple-select">
              <MenuItem value={1}>Autre(s) justificatif(s)</MenuItem>
              <MenuItem value={2}>
                Déclaration d&apos;affectation de patrimoine
              </MenuItem>
              <MenuItem value={'A'}>Pièce d&apos;identité</MenuItem>
            </Select> */}
            <Field
              className={classes.field}
              fullWidth
              component={TextField}
              select
              margin="normal"
              name="numéroz_de_voie5"
              label="Numéro de voie"
            >
              <MenuItem value selected="true"></MenuItem>
              <MenuItem value="A">Autre(s) justificatif(s)</MenuItem>
              <MenuItem value="B">
                Déclaration d&apos;affectation de patrimoine
              </MenuItem>
              <MenuItem value="C">Pièce d&apos;identité</MenuItem>
            </Field>

            <input
              type="file"
              accept="application/pdf"
              id="myFile"
              name="uploadFile"
            ></input>

            <Button variant="contained" color="primary">
              Joindre le fichier
            </Button>
          </Box>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

export default ListeDesPiecesJointes;
