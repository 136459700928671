import React, {
  useContext,
  Fragment,
  useState,
  useEffect,
  useRef,
} from 'react';
import _, { findIndex, debounce } from 'lodash';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';

import {
  FormControlLabel,
  Radio,
  Box,
  Typography,
  MenuItem,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';
// TST

import { Select, TextField, RadioGroup } from 'formik-material-ui';

import FormControl from '@material-ui/core/FormControl';

// /TST

import { makeStyles } from '@material-ui/core/styles';

import theme from 'assets/jss/theme';
import KeyboardDatePicker from 'components/KeyboardDatePicker';

import * as Yup from 'yup';
import Paper from 'components/surface/Paper';

import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const ChoixDuDg = ({
  partner,
  formValues,
  activeStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
  // partnerGerant,
  // setPartnerGerant,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const { partners } = formValues;
  // const { partnersDeep } = formValues.partners;

  // let gerantOupresidentLabel = 'gérant';
  // let associeOuactionnairePhraseLabel = ", associé de l'EURL en création";

  let associeOuMultiAssocie = "Un associé de l'EURL en création";

  // let associeOuActionnaireLabel = 'associée';

  if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sasu' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    // gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel =      ", dirigeant de l'actionnaire de la SASU en création";
    associeOuMultiAssocie = 'Un actionnaire de la SASU en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'eurl' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    // gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =      ", dirigeant de l'associé de l'EURL en création";
    associeOuMultiAssocie = "Un associé de l'EURL en création";
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sasu'
  ) {
    // gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel = ', actionnaire de la SASU en création';
    associeOuMultiAssocie = 'Un actionnaire de la SASU en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sarl' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    // gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =      ", dirigeant de l'associée de la SARL en création";
    associeOuMultiAssocie = 'Un associé de la SARL en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sarl'
  ) {
    // gerantOupresidentLabel = 'gérant';
    // associeOuactionnairePhraseLabel = ', associé de la SARL en création';
    associeOuMultiAssocie = 'Un associé de la SARL en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sas' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    // gerantOupresidentLabel = 'Président';

    // associeOuactionnairePhraseLabel =      ", dirigeant de l'actionnaire de la SAS en création";
    associeOuMultiAssocie = 'Un actionnaire de la SAS en création';
  } else if (
    formValues.alone === 'a_plusieurs' &&
    formValues.eurl_sasu === 'sas'
  ) {
    // gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel = ', actionnaire de la SAS';
    associeOuMultiAssocie = 'Un actionnaire de la SAS en création';
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'sasu' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    // gerantOupresidentLabel = 'Président';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'actionnaire de la SASU en création";
    associeOuMultiAssocie = `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'actionnaire de la SASU en création`;

    // ", dirigeant de l'actionnaire de la SASU en création";

    // associeOuactionnairePhraseLabel =   {` ${partner.partner_title}   ${partner.partner_firstname} ${partner.partner_name}${associeOuactionnairePhraseLabelV}  `}
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'eurl' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    // gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'associé de l'EURL en création";

    associeOuMultiAssocie =
      //  ", dirigeant de l'associé de l'EURL en création";
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'associé de l'EURL en création`;
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'eurl' &&
    formValues.personne_physique_morale === 'personne_physique'
  ) {
    // gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'associé de l'EURL en création";

    associeOuMultiAssocie =
      //  ", dirigeant de l'associé de l'EURL en création";
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name}, associé de l'EURL en création`;
  } else if (formValues.alone === 'seul' && formValues.eurl_sasu === 'sasu') {
    // gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel = ', actionnaire de la SASU en création';

    associeOuMultiAssocie =
      // {` ${partner.partner_title}   ${partner.partner_firstname} ${partner.partner_name}', actionnaire de la SASU en création'`};
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , actionnaire de la SASU en création`;
    // ', actionnaire de la SASU en création';
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'sarl' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    // gerantOupresidentLabel = 'gérant';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'associée de la SARL en création";

    associeOuMultiAssocie =
      //  ", dirigeant de l'associée de la SARL en création";
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'associée de la SARL en création`;
  } else if (formValues.alone === 'seul' && formValues.eurl_sasu === 'sarl') {
    // gerantOupresidentLabel = 'gérant';
    // associeOuactionnairePhraseLabel = ', associé de la SARL en création';

    associeOuMultiAssocie =
      //  ', associé de la SARL en création';
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , associé de la SARL en création`;
  } else if (
    formValues.alone === 'seul' &&
    formValues.eurl_sasu === 'sas' &&
    formValues.personne_physique_morale === 'personne_morale'
  ) {
    // gerantOupresidentLabel = 'Président';

    // associeOuactionnairePhraseLabel =
    //   ", dirigeant de l'actionnaire de la SAS en création";

    associeOuMultiAssocie =
      // ", dirigeant de l'actionnaire de la SAS en création";
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'actionnaire de la SAS en création`;
  } else if (formValues.alone === 'seul' && formValues.eurl_sasu === 'sas') {
    // gerantOupresidentLabel = 'Président';
    // associeOuactionnairePhraseLabel = ', actionnaire de la SAS';

    associeOuMultiAssocie =
      // ', actionnaire de la SAS';
      `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , actionnaire de la SAS`;
  }

  // setAutomateState(Automate.state);
  // useEffect(() => {
  //   // console.log('state automate demarage choixDG', Automate.state);
  // }, []);

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const addressDebounce = useRef(
    debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    addressDebounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue('dg_address', newValue.properties.name);
      if (newValue.properties.postcode && newValue.properties.city) {
        setFieldValue('dg_zipcode', newValue.properties.postcode);
        setFieldValue('dg_city', newValue.properties.city);
      }
    } else {
      setFieldValue('dg_address', '');
      setFieldValue('dg_zipcode', '');
      setFieldValue('dg_city', '');
      setAddressValue(newValue);
    }
  };
  // console.log('state automate dans choix_du_dg = ', Automate.state);

  const [cityList, setCityList] = useState([]);
  const [cityValue, setcityValue] = useState(null);
  const [cityInput, setCityInput] = useState(null);
  const [loadingCityList, setLoadingCityList] = useState(false);

  const cityInputDebounce = useRef(
    _.debounce((newValue) => setCityInput(newValue), 500)
  );

  useEffect(() => {
    if (cityInput) {
      setLoadingCityList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: cityInput, type: 'municipality', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setCityList(data.features);
          } else {
            setCityList([]);
          }
          setLoadingCityList(false);
        });
    } else {
      setCityList([]);
      setLoadingCityList(false);
    }
  }, [cityInput]);

  const handleFetchCity = async (newInputValue) => {
    setLoadingCityList(true);
    setCityInput(null);
    cityInputDebounce.current(newInputValue);
  };

  const handleSelectCity = async (newValue, setFieldValue) => {
    if (newValue) {
      setcityValue(newValue);
      setFieldValue('dg_place_of_birth', newValue.properties.name);
      if (newValue.properties.context) {
        const { context } = newValue.properties;
        const contextArr = context.split(', ');
        const state = contextArr[contextArr.length - 2];
        setFieldValue('dg_state_of_birth', state);
      }
    } else {
      setFieldValue('dg_place_of_birth', '');
      setFieldValue('dg_state_of_birth', '');
      setcityValue(newValue);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          director_type: Yup.string().required('un choix est necessaire'),
          // director_email: Yup.string().required('un mail est requis'),
          // director_email: Yup.string().when(
          //   'director_type',
          //   (director_type) => {
          //     if (director_type === "l_associe_de_l'EURL") {
          //       return Yup.string().required('un mail est requis');
          //     }
          //     return Yup.string();
          //   }
          // ),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        // // console.log('partnerGerant :', partnerGerant);
        // // console.log('values.asso :', values.associe_gerant_multiassocie);
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // if (values.director_type === "l_associe_de_l'EURL") {
        //   dispatch({
        //     type: EDIT_PARTNER,
        //     payload: values.director_email,
        //   });
        // }

        if (formValues.eurl_sasu === 'sasu') {
          Automate.nextChoixDgVersInfoApportsSociete();
          setAutomateState(Automate.state);
        }

        if (formValues.eurl_sasu === 'sas') {
          Automate.nextChoixDgVersInfoCapitalSociete();
          setAutomateState(Automate.state);
        }

        // Automate.next();
        // setAutomateState(Automate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        handleChange,
        setFieldValue,
      }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                Souhaitez-vous nommer dans les statuts un Directeur Général
                (DG)?
              </Typography>

              <Box className={classes.root} marginTop="20px" size="fullwidth">
                <Field component={RadioGroup} name="presence_dg">
                  <FormControlLabel
                    value="oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="non"
                    control={<Radio />}
                    label="Non"
                  />
                </Field>
              </Box>

              {values.presence_dg === 'oui' && (
                <Box marginTop={theme.spacing(0.25)}>
                  <Field component={RadioGroup} name="dg_type">
                    <FormControlLabel
                      value="l_associe_de_l'EURL"
                      control={<Radio />}
                      // label={` ${partner.partner_title}   ${partner.partner_firstname} ${partner.partner_name}${associeOuactionnairePhraseLabel}  `}
                      label={associeOuMultiAssocie}
                    />

                    {formValues.alone === 'a_plusieurs' &&
                      values.presence_dg === 'oui' &&
                      formValues.eurl_sasu === 'sas' && (
                        <Fragment>
                          <Box className={classes.root}>
                            <FormControl>
                              <Field
                                component={Select}
                                name="dg"
                                inputProps={{
                                  id: 'dg',
                                }}
                                onChange={(e) => {
                                  handleChange(e);
                                  const index = findIndex(
                                    partners,
                                    e.target.value
                                  );

                                  setFieldValue(
                                    'email_dg',
                                    partners[index].dg_email
                                  );
                                }}
                              >
                                {partners.map((associe) => {
                                  if (
                                    associe.id !==
                                    formValues.associe_gerant_multiassocie.id
                                  ) {
                                    return (
                                      <MenuItem
                                        key={associe.id}
                                        value={associe}
                                      >
                                        {
                                          associe.raison_sociale_actionnaire_personne_morale_sasu
                                        }{' '}
                                        {
                                          associe.forme_juridique_personne_morale
                                        }{' '}
                                        {associe.partner_title}{' '}
                                        {associe.partner_firstname}{' '}
                                        {associe.partner_name}
                                      </MenuItem>
                                    );
                                  }
                                  return null;
                                })}
                              </Field>
                            </FormControl>
                          </Box>
                        </Fragment>
                      )}

                    {/* /TST */}

                    <FormControlLabel
                      value="un_tiers"
                      control={<Radio />}
                      label="Une personne physique tierce"
                    />
                  </Field>
                </Box>
              )}

              {values.dg_type === 'un_tiers' && values.presence_dg === 'oui' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Field component={RadioGroup} name="dg_title">
                      <FormControlLabel
                        value="M."
                        control={<Radio />}
                        label="M."
                      />
                      <FormControlLabel
                        value="Mme"
                        control={<Radio />}
                        label="Mme"
                      />
                    </Field>
                    <Box>
                      <Field
                        component={TextField}
                        type="string"
                        // label={`Nom du ${gerantOupresidentLabel}`}
                        label={`Nom du Directeur Général`}
                        name="dg_name"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        // label={`Prénom du ${gerantOupresidentLabel}`}
                        label={`Prénom du Directeur Général`}
                        name="dg_firstname"
                        className={classes.textField}
                        autoComplete="off"
                      />
                    </Box>

                    <Box>
                      <Field
                        component={TextField}
                        type="string"
                        label={`Nom du père du Directeur Général`}
                        name="dg_father_name"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label={`Prénom du père du Directeur Général`}
                        name="dg_father_firstname"
                        className={classes.textField}
                        autoComplete="off"
                      />
                    </Box>

                    <Box>
                      <Field
                        component={TextField}
                        type="string"
                        label={`Nom de naissance de la mère du Directeur Général`}
                        name="dg_mother_name"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label={`Prénom de la mère du Directeur Général`}
                        name="dg_mother_firstname"
                        className={classes.textField}
                        autoComplete="off"
                      />
                    </Box>

                    <Box>
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label={`Date de naissance du Directeur Général`}
                        // value={selectedDate}
                        placeholder="01/01/1990"
                        maxDate={new Date()}
                        format="dd/MM/yyyy"
                        name="dg_date_of_birth"
                        className={classes.textField}
                        autoComplete="off"
                      />

                      {/* <Field
                        component={TextField}
                        type="string"
                        label={`Ville de naissance du Directeur Général`}
                        name="dg_place_of_birth"
                        className={classes.textField}
                        autoComplete="off"
                      /> */}

                      <Field
                        component={Autocomplete}
                        name="dg_place_of_birth"
                        className={classes.textField}
                        value={cityValue}
                        loading={loadingCityList}
                        options={cityList}
                        filterOptions={(x) => x}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option && option.properties && option.properties.name
                        }
                        onInputChange={(event, newInputValue) =>
                          handleFetchCity(newInputValue)
                        }
                        onChange={(event, newValue) =>
                          handleSelectCity(newValue, setFieldValue)
                        }
                        renderInput={(params) => (
                          <MUITextField
                            {...params}
                            error={
                              touched.dg_place_of_birth &&
                              !!errors.dg_place_of_birth
                            }
                            helperText={
                              touched.dg_place_of_birth &&
                              errors.dg_place_of_birth
                            }
                            label={`Ville de naissance du Directeur Général`}
                            // placeholder={dg_place_of_birth}
                            size="small"
                          />
                        )}
                        renderOption={(option) => {
                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.properties.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                    </Box>
                    <Box>
                      <Field
                        component={TextField}
                        type="string"
                        label={`Département de naissance du Directeur Général`}
                        placeholder="ex: Paris 11eme arrondissement"
                        name="dg_state_of_birth"
                        className={classes.textField}
                        autoComplete="off"
                      />
                    </Box>

                    <Box>
                      <Field
                        component={TextField}
                        type="string"
                        label={`Pays de naissance du Directeur Général`}
                        name="dg_country_of_birth"
                        className={classes.textField}
                        apport_fonds_de_commerce
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label={`Nationalité du Directeur Général`}
                        name="dg_nationality"
                        className={classes.textField}
                        autoComplete="off"
                      />
                    </Box>
                    <Box>
                      <Field
                        // component={TextField}
                        type="string"
                        component={Autocomplete}
                        // type="string                        // label={`Adresse du ${gerantOupresidentLabel}`}
                        label={`Adresse du Directeur Général`}
                        name="dg_place_of_living"
                        className={classes.nameTextField}
                        value={addressValue}
                        loading={loadingAddressList}
                        options={addressList}
                        filterOptions={(x) => x}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        getOptionLabel={(option) =>
                          option && option.properties && option.properties.name
                        }
                        onInputChange={(event, newInputValue) =>
                          handleFetchAddress(newInputValue)
                        }
                        onChange={(event, newValue) =>
                          handleSelectAddress(newValue, setFieldValue)
                        }
                        renderInput={(params) => (
                          <MUITextField
                            {...params}
                            error={touched.dg_address && !!errors.dg_address}
                            helperText={touched.dg_address && errors.dg_address}
                            label={`Adresse du Directeur Général`}
                            placeholder={formValues.dg_place_of_living}
                            size="small"
                          />
                        )}
                        renderOption={(option) => {
                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon />
                              </Grid>
                              <Grid item xs>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.properties.label}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                    </Box>
                    <Box>
                      <Field
                        component={TextField}
                        type="string"
                        // label={`Code postal du ${gerantOupresidentLabel}`}
                        label={`Code postal du Directeur Général`}
                        name="dg_zipcode"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        // label={`Ville du ${gerantOupresidentLabel}`}
                        label={`Ville du Directeur Général`}
                        name="dg_city"
                        className={classes.textField}
                        autoComplete="off"
                      />
                    </Box>

                    <Field
                      component={TextField}
                      type="string"
                      placeholder="xyz@gmail.com"
                      // label={`Adresse mail du ${gerantOupresidentLabel}`}
                      label={`Adresse mail du Directeur Général`}
                      name="dg_email"
                      className={classes.nameTextField}
                      autoComplete="off"
                    />
                  </Box>
                </Fragment>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixDuDg.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixDuDg;
