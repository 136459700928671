import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import Adresse from '../commons/adress';
import useStyles from '../style';

function InfoCACSupleantPM() {
  const classes = useStyles();

  return (
    <>
      <Field
        component={TextField}
        type="string"
        label="Raison sociale du suppléant *"
        name="nom_suppleant"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        title="Adresse du cabinet du suppléant *"
        address="adresse_cabinet_suppleant"
        city="city_cabinet_suppleant"
        zipCode="zipCode_cabinet_suppleant"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        additionalAddress="additional_address_cabinet_suppleant"
      />
    </>
  );
}

export default InfoCACSupleantPM;
