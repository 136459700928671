import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  pdfWapper: {
    height: '100%',
    padding: '60px 20px 10px 20px',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '20px',
  },
}));

export default useStyles;
