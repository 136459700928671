import { NO, YES } from 'constants/usersContants';

const initialValues = (values) => {
  return {
    eurl_sasu: values.eurl_sasu,
    partnerTotalApport: values.partnerTotalApport || 0,
    partner_apport_numeraire: values.partner_apport_numeraire || YES,
    partner_cash_funds_numbers: values.partner_cash_funds_numbers || 0,

    // apport en nature
    partner_apport_nature: values.partner_apport_nature || NO,
    partner_apport_fonds_de_commerce:
      values.partner_apport_fonds_de_commerce || NO,
    partner_date_apport_fonds_de_commerce:
      values.partner_date_apport_fonds_de_commerce || null,
    partner_valeur_apport_fonds_de_commerce:
      values.partner_valeur_apport_fonds_de_commerce || 0,

    // apport de titres de société
    partner_apport_titres_societe: values.partner_apport_titres_societe || NO,
    partner_date_apport_titres_societe:
      values.partner_date_apport_titres_societe || null,
    partner_valeur_apport_titres_societe:
      values.partner_valeur_apport_titres_societe || 0,
    partner_raison_sociale_societe_apport_titres_societe:
      values.partner_raison_sociale_societe_apport_titres_societe || NO,
    partner_nombre_titres_apportes_apport_titres_societe:
      values.partner_nombre_titres_apportes_apport_titres_societe || 0,
    partner_nombre_titres_total_apport_titres_societe:
      values.partner_nombre_titres_total_apport_titres_societe || 0,

    // apport en nature
    partner_apport_nature_divers: values.partner_apport_nature_divers || NO,
    partner_date_apport_nature_divers:
      values.partner_date_apport_nature_divers || null,
    partner_valeur_apport_nature_divers:
      values.partner_valeur_apport_nature_divers || 0,
    partner_description_biens_apport_nature_divers:
      values.partner_description_biens_apport_nature_divers || '',

    // Industry contribution
    partner_apport_industrie: values.partner_apport_industrie || NO,
    partner_expertise_funds_numbers:
      values.partner_expertise_funds_numbers || 0,
  };
};

// eslint-disable-next-line
export { initialValues };
