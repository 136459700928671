import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import HelpIcon from './HelpIcon';

function InputWithHelpIcon({ type, label, name, classes }) {
  return (
    <div className={classes.inputWithHelpIconContainer}>
      <Field
        component={TextField}
        type={type}
        label={label}
        name={name}
        className={classes.nameTextFieldFull}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <HelpIcon helpName={name} />
    </div>
  );
}

export default InputWithHelpIcon;
