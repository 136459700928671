import { EURL, SARL, SAS, SASU, SCI } from 'constants/companies';
import { PARTNER } from 'constants/usersContants';

const prepareData = (values) => {
  const form = { ...values };
  const {
    associe_gerant_multiassocie,
    partners,
    dgPartnerIDs,
    eurl_sasu,
    adresse_syndic,
    syndic_city,
    syndic_zipcode,
    adresse_bailleur,
    bailleur_city,
    bailleur_zipcode,
    adresse_cabinet_commissaire_aux_comptes,
    city_cabinet_commissaire_aux_comptes,
    zipCode_cabinet_commissaire_aux_comptes,
    adresse_cabinet_suppleant,
    city_cabinet_suppleant,
    zipCode_cabinet_suppleant,
    director_type,
  } = form;
  if (
    associe_gerant_multiassocie.length > 0 &&
    (eurl_sasu === SARL || eurl_sasu === SCI)
  ) {
    const newAssocie_gerant_multiassocie = [];
    partners.forEach((partner) => {
      if (associe_gerant_multiassocie.includes(partner.id)) {
        newAssocie_gerant_multiassocie.push(partner);
      }
    });
    form.associe_gerant_multiassocie = newAssocie_gerant_multiassocie;
  } else if (associe_gerant_multiassocie.length > 0 && eurl_sasu === EURL) {
    const newAssocie_gerant_multiassocie = partners.find(
      (partner) => partner.id === associe_gerant_multiassocie[0]
    );
    form.director_family_situation = director_type;
    form.director_title = newAssocie_gerant_multiassocie.partner_title;
    form.director_name = newAssocie_gerant_multiassocie.partner_name;
    form.director_firstname = newAssocie_gerant_multiassocie.partner_firstname;
    form.director_email = newAssocie_gerant_multiassocie.partner_email;
    form.director_place_of_living =
      newAssocie_gerant_multiassocie.partner_place_of_living;
    form.director_city = newAssocie_gerant_multiassocie.partner_city;
    form.director_zipcode = newAssocie_gerant_multiassocie.partner_zipcode;
    form.associe_gerant_multiassocie = [];
  } else if (eurl_sasu === SASU && director_type === PARTNER) {
    form.director_family_situation = director_type;
    form.director_title = partners[0].partner_title;
    form.director_name = partners[0].partner_name;
    form.director_firstname = partners[0].partner_firstname;
    form.director_email = partners[0].partner_email;
    form.director_place_of_living = partners[0].partner_place_of_living;
    form.director_city = partners[0].partner_city;
    form.director_zipcode = partners[0].partner_zipcode;
    form.associe_gerant_multiassocie = [];
  }
  if (eurl_sasu === SAS && director_type === PARTNER) {
    const director = partners.find((partner) => {
      return partner.id === associe_gerant_multiassocie[0];
    });
    form.associe_gerant_multiassocie = director;
  }
  form.dg = partners.filter(
    (partner) => dgPartnerIDs.indexOf(partner.id) !== -1
  );

  if (adresse_syndic !== '') {
    form.adresse_syndic = `${adresse_syndic} ${syndic_city} ${syndic_zipcode}`;
  }
  if (adresse_bailleur !== '') {
    form.bailleur_zipcode = bailleur_zipcode;
    form.bailleur_city = bailleur_city;
    form.bailleur_address = adresse_bailleur;
    if (form.adresse_bailleur_updated) {
      form.adresse_bailleur = `${adresse_bailleur}`;
    } else {
      form.adresse_bailleur = `${adresse_bailleur} ${bailleur_city} ${bailleur_zipcode}`;
    }
    delete form.adresse_bailleur_updated;
  }
  if (adresse_cabinet_commissaire_aux_comptes !== '') {
    form.adresse_cabinet_commissaire_aux_comptes = `${adresse_cabinet_commissaire_aux_comptes} ${city_cabinet_commissaire_aux_comptes} ${zipCode_cabinet_commissaire_aux_comptes}`;
  }
  if (adresse_cabinet_suppleant !== '') {
    form.adresse_cabinet_suppleant = `${adresse_cabinet_suppleant} ${city_cabinet_suppleant} ${zipCode_cabinet_suppleant}`;
  }
  form.companyId = null;
  form.alternativeId = null;
  return form;
};

export default prepareData;
