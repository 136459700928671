import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

const RedirectPopup = ({ kit }) => {
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (!kit) {
      setOpenAlert(true);
    }
  }, []);

  return (
    <Dialog
      open={openAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Alert'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Kit in the URL is missing, do you want to be redirect ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            window.location = 'https://juridique.bebusinessfocus.com/';
          }}
          style={{
            color: '#fff',
            backgroundColor: '#3d319d',
          }}
        >
          yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RedirectPopup;
