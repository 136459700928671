import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import axios from 'axios';
import { Field, Formik, Form } from 'formik';
import {
  Popover,
  IconButton,
  Box,
  FormControlLabel,
  Radio,
  Typography,
  FormControl,
  MenuItem,
  TextField as MUITextField,
  Grid,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { RadioGroup, TextField, Select } from 'formik-material-ui';

import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { makeStyles } from '@material-ui/core/styles';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import * as Yup from 'yup';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '60ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '20ch',
  },
  radio: {},
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

const InfoCapitalDeSociete = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(2);
  }, []);

  // let partsOuActionsLabel = 'de parts';
  let unePartOuUneActionLabel = "d'une part";

  let capitalChiffres = 0;
  // let partOuActionLabel = "d'une part";

  // calcul du capital
  const { partners } = formValues;
  let apportsNumeraire = 0;
  let apportsNature = 0;

  if (formValues.alone === 'a_plusieurs') {
    partners.forEach((partner) => {
      apportsNumeraire =
        Math.abs(apportsNumeraire) +
        Math.abs(partner.partner_cash_funds_numbers);
      apportsNature =
        Math.abs(apportsNature) +
        Math.abs(partner.partner_supplies_funds_numbers);
    });

    capitalChiffres = apportsNumeraire + apportsNature;
  }

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    // partsOuActionsLabel = "d'actions";
    unePartOuUneActionLabel = "d'une action";
  }

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    // partOuActionLabel = "d'une action";
  }

  // pour avoir le capital calculé déja avant d'arriver au server.js
  if (formValues.alone === 'seul') {
    capitalChiffres =
      parseInt(formValues.cash_funds_numbers, 10) +
      parseInt(formValues.supplies_funds_numbers, 10);
  }

  const [addressList, setAddressList] = useState([]);
  const [addressValue, setAddressValue] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [loadingAddressList, setLoadingAddressList] = useState(false);

  const debounce = useRef(
    _.debounce((newValue) => setAddressInput(newValue), 500)
  );

  useEffect(() => {
    if (addressInput) {
      setLoadingAddressList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: addressInput, type: 'housenumber', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setAddressList(data.features);
          } else {
            setAddressList([]);
          }
          setLoadingAddressList(false);
        });
    } else {
      setAddressList([]);
      setLoadingAddressList(false);
    }
  }, [addressInput]);

  const handleFetchAddress = async (newInputValue) => {
    setLoadingAddressList(true);
    setAddressInput(null);
    debounce.current(newInputValue);
  };

  const handleSelectAddress = async (newValue, setFieldValue) => {
    if (newValue) {
      setAddressValue(newValue);
      setFieldValue('adresse_agence_bancaire', newValue.properties.name);
      if (newValue.properties.postcode && newValue.properties.city) {
        setFieldValue(
          'code_postal_agence_bancaire',
          newValue.properties.postcode
        );
        setFieldValue('ville_agence_bancaire', newValue.properties.city);
      }
    } else {
      setFieldValue('adresse_agence_bancaire', '');
      setFieldValue('code_postal_agence_bancaire', '');
      setFieldValue('ville_agence_bancaire', '');
      setAddressValue(newValue);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popover2' : undefined;

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? 'simple-popover3' : undefined;

  const [anchorEl4, setAnchorEl4] = React.useState(null);

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const open4 = Boolean(anchorEl4);
  const id4 = open4 ? 'simple-popover4' : undefined;

  const [anchorEl5, setAnchorEl5] = React.useState(null);

  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };

  const handleClose5 = () => {
    setAnchorEl5(null);
  };

  const open5 = Boolean(anchorEl5);
  const id5 = open5 ? 'simple-popover5' : undefined;

  let popoverTextChoixIs = '';
  let popoverTextCapital = '';
  let popoverTextDepotFonds = '';
  const popoverTextConnaitDepotDesFonds = (
    <div className={classes.popOverDiv}>
      <Typography>
        Il vous appartiendra d’imprimer le projet de statut généré par l’outil
        BBF et de vous rendre auprès d’une banque ou d’un notaire pour déposer
        les fonds et obtenir une attestation de dépôt des fonds que vous devrez
        déposer sur votre espace BBF.
      </Typography>
    </div>
  );

  popoverTextChoixIs = (
    <div className={classes.popOverDiv}>
      <Typography>
        Les bénéfices de la SCI sont imposables de 2 manières différentes :{' '}
        <br />
        <br />
        &nbsp;&nbsp;• soit directement entre les mains de l’associé unique à
        l’impôt sur le revenu (IR) ; <br />
        &nbsp;&nbsp;• soit au niveau de la société à l’impôt sur les sociétés
        (IS). <br />
        <br />
        Lorsque l’associé unique de la SCI est une personne physique, le régime
        de l’impôt sur le revenu est le régime fiscal de droit de la SCI.
        Toutefois, il est possible d’opter à l’impôt sur les sociétés. Lorsque
        l’associé unique de la SCI est une personne morale, le régime de l’impôt
        sur les sociétés est le régime de droit.
        <h4> La fiscalisation des bénéfices de la SCI à l’IR </h4>
        Dès lors que la SCI est redevable au régime de l’impôt sur les revenus
        (IR), les revenus de la société se confondent avec ceux du gérant
        associé et : <br />
        <br />
        &nbsp;&nbsp;• sont déterminés selon le régime des BNC (bénéfices non
        commerciaux) si vous exercez une activité libérale (en générale de
        services) et celui des BIC (bénéfices industriels et commerciaux) si
        vous exercez une activité artisanale ou commerciale ; <br />
        &nbsp;&nbsp;• la totalité du résultat est donc cotisable au régime des
        TNS (travailleurs non-salariés), et sera également fiscalisé au barème
        progressif de l’IRPP. <br />
        <br />
        Si la société est administrée par un gérant salarié non associé: <br />{' '}
        <br />
        &nbsp;&nbsp;• son salaire sera déductible du résultat de la société.{' '}
        <br />
        &nbsp;&nbsp;• le bénéfice résiduel sera attribué en totalité à l’associé
        unique et sera imposé à l’IRPP. <br /> <br />
        Attention : la notion de dividendes n’existent pas en cas de
        fiscalisation des résultats de la SCI à l’IRPP.
      </Typography>
    </div>
  );

  popoverTextCapital = (
    <div className={classes.popOverDiv}>
      <Typography>
        <h4>Quels apports contribue à la formation du capital ? </h4>
        Les fonds en numéraire contribueront à former le capital de la société,
        et ils donneront droit, en échange, à l’attribution de parts sociales.{' '}
        <br />
        L’apport en nature forme également le capital social de la SCI, et il
        ouvre droit à l’attribution de parts sociales.
        <br />
        <br />
        Attention :
        <br />
        <br />
        L’apport en industrie ne concourt pas à la formation du capital social,
        mais l’associé unique recevra tout de même des parts sociales en
        contrepartie de cet apport.
      </Typography>
    </div>
  );
  popoverTextDepotFonds = (
    <div className={classes.popOverDiv}>
      <Typography>
        Dans les 8 jours de leur réception, et, avant la signature des statuts,
        les sommes doivent être déposées pour le compte de la société en
        formation : <br />
        &nbsp;&nbsp;• soit sur un compte bloqué chez Be Business Focus ; <br />
        &nbsp;&nbsp;• soit auprès d’une banque, sur un compte de la société en
        formation ;
        <br />
        &nbsp;&nbsp;• soit à la Caisse des dépôts et consignations ;<br />
        &nbsp;&nbsp;• soit chez un notaire.
        <br />
        <br />
        La mention des conditions du dépôt des fonds doit figurer dans les
        statuts.
      </Typography>
    </div>
  );

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          // capital_in_numbers: Yup.number().required().positive().integer(),
          share_value: Yup.number().required().positive().integer(),

          // depot_capital_chez: Yup.string().required('required'),

          depot_capital_chez: Yup.string().when(
            'connait_depot_des_fonds',
            (connait_depot_des_fonds) => {
              if (connait_depot_des_fonds === 'oui') {
                return Yup.string().required('requis');
              }
              return Yup.string();
            }
          ),

          // date_depot_des_fonds: Yup.date().when(
          //   'depot_capital_chez',
          //   (depot_capital_chez) => {
          //     if (
          //       depot_capital_chez !== 'be_business_focus' &&
          //       depot_capital_chez !== 'capital_non_depose'
          //     ) {
          //       return Yup.date
          //         .max(
          //           new Date().toISOString(),
          //           'le depot doit avoir deja eu lieu'
          //         )
          //         .required('une date est requise');
          //     }
          //     // console.log('icii');
          //     return Yup.date();
          //   }
          // ),

          banking_corporation_name: Yup.string().when(
            ['depot_capital_chez', 'connait_depot_des_fonds'],
            (depot_capital_chez, connait_depot_des_fonds) => {
              if (depot_capital_chez !== 'be_business_focus') {
                if (connait_depot_des_fonds === 'oui') {
                  return Yup.string().required('required');
                }
              }
              return Yup.string();
            }
          ),

          adresse_agence_bancaire: Yup.string().when(
            ['depot_capital_chez', 'connait_depot_des_fonds'],
            (depot_capital_chez, connait_depot_des_fonds) => {
              if (depot_capital_chez !== 'be_business_focus') {
                if (connait_depot_des_fonds === 'oui') {
                  return Yup.string().required('required');
                }
              }
              return Yup.string();
            }
          ),
          ville_agence_bancaire: Yup.string().when(
            ['depot_capital_chez', 'connait_depot_des_fonds'],
            (depot_capital_chez, connait_depot_des_fonds) => {
              if (depot_capital_chez !== 'be_business_focus') {
                if (connait_depot_des_fonds === 'oui') {
                  return Yup.string().required('required');
                }
              }
              return Yup.string();
            }
          ),
          code_postal_agence_bancaire: Yup.string().when(
            ['depot_capital_chez', 'connait_depot_des_fonds'],
            (depot_capital_chez, connait_depot_des_fonds) => {
              if (depot_capital_chez !== 'be_business_focus') {
                if (connait_depot_des_fonds === 'oui') {
                  return Yup.string().required('required');
                }
              }
              return Yup.string();
            }
          ),

          // adresse_agence_bancaire: Yup.string().when(
          //   'depot_capital_chez',
          //   (depot_capital_chez) => {
          //     if (depot_capital_chez !== 'be_business_focus') {
          //       return Yup.string().required('Required');
          //     }
          //     return Yup.string();
          //   }
          // ),
          // ville_agence_bancaire: Yup.string().when(
          //   'depot_capital_chez',
          //   (depot_capital_chez) => {
          //     if (depot_capital_chez !== 'be_business_focus') {
          //       return Yup.string().required('Required');
          //     }
          //     return Yup.string();
          //   }
          // ),
          // code_postal_agence_bancaire: Yup.string().when(
          //   'depot_capital_chez',
          //   (depot_capital_chez) => {
          //     if (depot_capital_chez !== 'be_business_focus') {
          //       return Yup.string().required('Required');
          //     }
          //     return Yup.string();
          //   }
          // ),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        // if (values.depot_capital_chez === 'be_business_focus') {
        //   SciAutomate.montreInfoNotaireBbf();
        //   setAutomateState(SciAutomate.state);
        //   return;
        // }
        // if (values.depot_capital_chez === 'capital_non_depose') {
        //   // console.log('tstst');
        //   SciAutomate.nextCapitalVersActes();
        //   setAutomateState(SciAutomate.state);
        //   return;
        // }

        // SciAutomate.next();
        // setAutomateState(SciAutomate.state);

        if (values.depot_capital_chez === 'be_business_focus') {
          // console.log('tst cdtion depot bbf');
          SciAutomate.nextInfoCapitalSocieteVersInfoNotaire();
          setAutomateState(SciAutomate.state);
          return;
        }

        if (values.depot_capital_chez !== 'be_business_focus') {
          SciAutomate.nextInfoCapitalSocieteVersChoixNombreActesPrealables();
          setAutomateState(SciAutomate.state);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => {
        if (
          values.connait_depot_des_fonds === 'oui' &&
          values.depot_capital_chez === 'banque'
        ) {
          popoverTextDepotFonds = (
            <div className={classes.popOverDiv}>
              <Typography>
                Il vous appartiendra d’imprimer le projet de statut généré par
                l’outil BBF et de vous rendre chez votre banquier pour déposer
                les fonds et obtenir une attestation de dépôt des fonds que vous
                devrez déposer sur votre espace BBF
              </Typography>
            </div>
          );
        }
        if (
          values.connait_depot_des_fonds === 'oui' &&
          values.depot_capital_chez === 'notaire'
        ) {
          popoverTextDepotFonds = (
            <div className={classes.popOverDiv}>
              <Typography>
                Il vous appartiendra d’imprimer le projet de statut généré par
                l’outil BBF et de vous rendre chez votre notaire pour déposer
                les fonds et obtenir une attestation de dépôt des fonds que vous
                devrez déposer sur votre espace BBF
              </Typography>
            </div>
          );
        }

        const popoverTextConnaitDepotDepotDesFondsNon = (
          <div className={classes.popOverDiv}>
            <Typography>
              Si vous ne savez pas encore ou vous rendre, choisissez non et
              faites votre choix. Une fois la décision prise, il vous
              appartiendra d’imprimer le projet de statut généré par l’outil BBF
              et de vous rendre chez le banquier ou le notaire de votre choix
              pour déposer les fonds et obtenir une attestation de dépôt des
              fonds que vous devrez déposer sur votre espace BBF. Un conseiller
              BBF se chargera ensuite de compléter les informations une fois
              l’attestation en notre possession.
            </Typography>
          </div>
        );
        return (
          <Form fullwidth="true">
            <Paper>
              <Box
                display="flex"
                p={1}
                bgcolor="background.paper"
                className={classes.root}
                flexDirection="column"
              >
                <Box className={classes.root}>
                  <Typography variant="h5">
                    {' '}
                    Capital et dépôt des fonds{' '}
                  </Typography>{' '}
                </Box>

                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClick2}
                >
                  <HelpOutlineOutlined />
                </IconButton>
                <Popover
                  id={id2}
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={handleClose2}
                  anchorReference={'none'}
                  className={classes.popOver}
                >
                  {popoverTextCapital}
                </Popover>
                <Box className={classes.root}>
                  <Typography variant="subtitle1">
                    {' '}
                    Rappel, le capital de la société {
                      formValues.company_name
                    }{' '}
                    est de {capitalChiffres} euros
                  </Typography>{' '}
                </Box>
                <Box>
                  {/* <Box className={classes.root}>
                  <Field
                    component={TextField}
                    id="capital_in_numbers"
                    placeholder={capitalChiffres}
                    label="Capital en chiffres"
                    name="capital_in_numbers"
                    className={classes.nameTextField}
                    autoComplete="off"
                  />
                </Box> */}

                  <Box className={classes.root}>
                    <Field
                      component={TextField}
                      type="int"
                      label={`Valeur unitaire ${unePartOuUneActionLabel}`}
                      name="share_value"
                      className={classes.nameTextField}
                      autoComplete="off"
                    />
                  </Box>

                  {formValues.kit === 'basiquesci' && (
                    <Box className={classes.root}>
                      <Field
                        component={RadioGroup}
                        name="connait_depot_des_fonds"
                      >
                        <Typography variant="subtitle1">
                          {' '}
                          Savez-vous où vous effectuerez le dépôt des fonds ?
                        </Typography>{' '}
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="non"
                          control={<Radio />}
                          label="Non"
                        />
                      </Field>
                    </Box>
                  )}
                  {formValues.kit === 'kit3sci' && (
                    <Box className={classes.root}>
                      <Field
                        component={RadioGroup}
                        name="connait_depot_des_fonds"
                      >
                        <Typography variant="subtitle1">
                          {' '}
                          Savez-vous où vous effectuerez le dépôt des fonds ?
                        </Typography>{' '}
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <Box>
                          <FormControlLabel
                            value="non"
                            control={<Radio />}
                            label="Non"
                          />
                          {values.connait_depot_des_fonds === 'non' && (
                            <>
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={handleClick4}
                              >
                                <HelpOutlineOutlined />
                              </IconButton>
                              <Popover
                                id={id4}
                                open={open4}
                                anchorEl={anchorEl4}
                                onClose={handleClose4}
                                anchorReference={'none'}
                                className={classes.popOver}
                              >
                                {popoverTextConnaitDepotDepotDesFondsNon}
                              </Popover>
                            </>
                          )}
                        </Box>
                      </Field>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleClick5}
                      >
                        <HelpOutlineOutlined />
                      </IconButton>
                      <Popover
                        id={id5}
                        open={open5}
                        anchorEl={anchorEl5}
                        onClose={handleClose5}
                        anchorReference={'none'}
                        className={classes.popOver}
                      >
                        {popoverTextConnaitDepotDesFonds}
                      </Popover>
                    </Box>
                  )}

                  {values.connait_depot_des_fonds === 'oui' &&
                    formValues.kit !== 'kit4sci' && (
                      <Box>
                        <Box className={classes.root}>
                          <FormControl marginTop="0.25">
                            <Typography marginRight="50pt" variant="subtitle1">
                              Dépôt du capital chez{'     '}
                            </Typography>

                            {'   '}
                            <Field
                              component={Select}
                              label="Dépôt du capital chez "
                              name="depot_capital_chez"
                              inputProps={{
                                id: 'depot_capital_chez',
                              }}
                            >
                              {formValues.kit === 'basiquesci' && (
                                <MenuItem value={'banque'}>Banque</MenuItem>
                              )}
                              {formValues.kit === 'kit1sci' && (
                                <MenuItem value={'banque'}>Banque</MenuItem>
                              )}
                              {formValues.kit === 'kit3sci' && (
                                <MenuItem value={'banque'}>Banque</MenuItem>
                              )}

                              {formValues.kit === 'basiquesci' && (
                                <MenuItem value={'notaire'}>Notaire</MenuItem>
                              )}

                              {formValues.kit === 'kit1sci' && (
                                <MenuItem value={'notaire'}>Notaire</MenuItem>
                              )}
                              {formValues.kit === 'kit3sci' && (
                                <MenuItem value={'notaire'}>Notaire</MenuItem>
                              )}

                              {formValues.kit === 'kit2sci' && (
                                <MenuItem value={'be_business_focus'}>
                                  Be Business Focus
                                </MenuItem>
                              )}
                              {formValues.kit === 'kit4sci' && (
                                <MenuItem value={'be_business_focus'}>
                                  Be Business Focus
                                </MenuItem>
                              )}
                              {formValues.kit !== 'kit4sci' && (
                                <MenuItem value={'capital_non_depose'}>
                                  Capital non déposé
                                </MenuItem>
                              )}
                            </Field>
                          </FormControl>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={handleClick3}
                          >
                            <HelpOutlineOutlined />
                          </IconButton>
                          <Popover
                            id={id3}
                            open={open3}
                            anchorEl={anchorEl3}
                            onClose={handleClose3}
                            anchorReference={'none'}
                            className={classes.popOver}
                          >
                            {popoverTextDepotFonds}
                          </Popover>
                        </Box>

                        {/* {values.depot_capital_chez !== 'be_business_focus' && (
                        <Field
                          component={KeyboardDatePicker}
                          clearable
                          label="Date du depot des fonds"
                          placeholder="01/01/1990"
                          className={classes.birthdayField}
                          format="dd/MM/yyyy"
                          name="date_depot_des_fonds"
                          autoComplete="off"
                        />
                      )} */}
                        {values.depot_capital_chez !== 'be_business_focus' &&
                          values.depot_capital_chez !==
                            'capital_non_depose' && (
                            <Fragment>
                              <Box
                                className={classes.root}
                                marginTop="20px"
                                size="fullwidth"
                              >
                                <Box className={classes.root}>
                                  <Field
                                    component={TextField}
                                    type="string"
                                    label="Nom de l'agence ou de l'étude 1"
                                    name="banking_corporation_name"
                                    className={classes.nameTextField}
                                    autoComplete="off"
                                  />
                                  <Field
                                    component={Autocomplete}
                                    // label="Adresse de l'agence ou de l'étude"
                                    name="adresse_agence_bancaire"
                                    className={classes.nameTextField}
                                    value={addressValue}
                                    loading={loadingAddressList}
                                    options={addressList}
                                    filterOptions={(x) => x}
                                    autoComplete
                                    includeInputInList
                                    filterSelectedOptions
                                    getOptionLabel={(option) =>
                                      option &&
                                      option.properties &&
                                      option.properties.name
                                    }
                                    onInputChange={(event, newInputValue) =>
                                      handleFetchAddress(newInputValue)
                                    }
                                    onChange={(event, newValue) =>
                                      handleSelectAddress(
                                        newValue,
                                        setFieldValue
                                      )
                                    }
                                    renderInput={(params) => (
                                      <MUITextField
                                        {...params}
                                        error={
                                          touched.adresse_agence_bancaire &&
                                          !!errors.adresse_agence_bancaire
                                        }
                                        helperText={
                                          touched.adresse_agence_bancaire &&
                                          errors.adresse_agence_bancaire
                                        }
                                        label="Adresse de l'agence ou de l'étude"
                                        placeholder={
                                          formValues.adresse_agence_bancaire
                                        }
                                        size="small"
                                      />
                                    )}
                                    renderOption={(option) => {
                                      return (
                                        <Grid container alignItems="center">
                                          <Grid item>
                                            <LocationOnIcon />
                                          </Grid>
                                          <Grid item xs>
                                            <Typography
                                              variant="body2"
                                              color="textSecondary"
                                            >
                                              {option.properties.label}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      );
                                    }}
                                  />
                                </Box>

                                <Box className={classes.root}>
                                  <Field
                                    component={TextField}
                                    type="string"
                                    label="Ville de l'agence ou de l'étude"
                                    name="ville_agence_bancaire"
                                    className={classes.nameTextField}
                                    autoComplete="off"
                                  />

                                  <Field
                                    component={TextField}
                                    type="string"
                                    label="Code postal de l'agence ou de l'étude"
                                    name="code_postal_agence_bancaire"
                                    className={classes.nameTextField}
                                    autoComplete="off"
                                  />
                                </Box>
                              </Box>
                            </Fragment>
                          )}
                      </Box>
                    )}

                  {formValues.kit === 'kit4sci' && (
                    <Box className={classes.root}>
                      <FormControl marginTop="0.25">
                        <Typography marginRight="50pt" variant="subtitle1">
                          Dépôt du capital chez{'     '}
                        </Typography>

                        {'   '}
                        <Field
                          component={Select}
                          label="Dépôt du capital chez "
                          name="depot_capital_chez"
                          inputProps={{
                            id: 'depot_capital_chez',
                          }}
                        >
                          {formValues.kit === 'basiquesci' && (
                            <MenuItem value={'banque'}>Banque</MenuItem>
                          )}
                          {formValues.kit === 'kit1sci' && (
                            <MenuItem value={'banque'}>Banque</MenuItem>
                          )}
                          {formValues.kit === 'kit3sci' && (
                            <MenuItem value={'banque'}>Banque</MenuItem>
                          )}

                          {formValues.kit === 'basiquesci' && (
                            <MenuItem value={'notaire'}>Notaire</MenuItem>
                          )}

                          {formValues.kit === 'kit1sci' && (
                            <MenuItem value={'notaire'}>Notaire</MenuItem>
                          )}
                          {formValues.kit === 'kit3sci' && (
                            <MenuItem value={'notaire'}>Notaire</MenuItem>
                          )}

                          {formValues.kit === 'kit2sci' && (
                            <MenuItem value={'be_business_focus'}>
                              Be Business Focus
                            </MenuItem>
                          )}
                          {formValues.kit === 'kit4sci' && (
                            <MenuItem value={'be_business_focus'}>
                              Be Business Focus
                            </MenuItem>
                          )}
                        </Field>
                      </FormControl>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleClick3}
                      >
                        <HelpOutlineOutlined />
                      </IconButton>
                      <Popover
                        id={id3}
                        open={open3}
                        anchorEl={anchorEl3}
                        onClose={handleClose3}
                        anchorReference={'none'}
                        className={classes.popOver}
                      >
                        {popoverTextDepotFonds}
                      </Popover>
                      {values.depot_capital_chez !== 'be_business_focus' &&
                        values.depot_capital_chez !== 'capital_non_depose' &&
                        formValues.kit !== 'kit4sci' && (
                          <Field
                            component={KeyboardDatePicker}
                            clearable
                            label="Date du depot des fonds"
                            placeholder="01/01/1990"
                            className={classes.birthdayField}
                            format="dd/MM/yyyy"
                            name="date_depot_des_fonds"
                            autoComplete="off"
                          />
                        )}
                      {values.depot_capital_chez !== 'be_business_focus' &&
                        values.depot_capital_chez !== 'capital_non_depose' &&
                        formValues.kit !== 'kit4sci' && (
                          <Fragment>
                            <Box
                              className={classes.root}
                              marginTop="20px"
                              size="fullwidth"
                            >
                              <Box className={classes.root}>
                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Nom de l'agence ou de l'étude"
                                  name="banking_corporation_name"
                                  className={classes.nameTextField}
                                  autoComplete="off"
                                />
                                <Field
                                  component={Autocomplete}
                                  // label="Adresse de l'agence ou de l'étude"
                                  name="adresse_agence_bancaire"
                                  className={classes.nameTextField}
                                  value={addressValue}
                                  loading={loadingAddressList}
                                  options={addressList}
                                  filterOptions={(x) => x}
                                  autoComplete
                                  includeInputInList
                                  filterSelectedOptions
                                  getOptionLabel={(option) =>
                                    option &&
                                    option.properties &&
                                    option.properties.name
                                  }
                                  onInputChange={(event, newInputValue) =>
                                    handleFetchAddress(newInputValue)
                                  }
                                  onChange={(event, newValue) =>
                                    handleSelectAddress(newValue, setFieldValue)
                                  }
                                  renderInput={(params) => (
                                    <MUITextField
                                      {...params}
                                      error={
                                        touched.adresse_agence_bancaire &&
                                        !!errors.adresse_agence_bancaire
                                      }
                                      helperText={
                                        touched.adresse_agence_bancaire &&
                                        errors.adresse_agence_bancaire
                                      }
                                      label="Adresse de l'agence ou de l'étude"
                                      placeholder={
                                        formValues.adresse_agence_bancaire
                                      }
                                      size="small"
                                    />
                                  )}
                                  renderOption={(option) => {
                                    return (
                                      <Grid container alignItems="center">
                                        <Grid item>
                                          <LocationOnIcon />
                                        </Grid>
                                        <Grid item xs>
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                          >
                                            {option.properties.label}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    );
                                  }}
                                />
                              </Box>

                              <Box className={classes.root}>
                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Ville de l'agence ou de l'étude"
                                  name="ville_agence_bancaire"
                                  className={classes.nameTextField}
                                  autoComplete="off"
                                />

                                <Field
                                  component={TextField}
                                  type="string"
                                  label="Code postal de l'agence ou de l'étude"
                                  name="code_postal_agence_bancaire"
                                  className={classes.nameTextField}
                                  autoComplete="off"
                                />
                              </Box>
                            </Box>
                          </Fragment>
                        )}
                    </Box>
                  )}

                  <Box
                    className={classes.root}
                    marginTop="20px"
                    size="fullwidth"
                  >
                    <Field component={RadioGroup} name="choix_is">
                      <Box>
                        <Typography variant="subtitle1">
                          {' '}
                          A quel régime d’imposition souhaitez-vous opter pour
                          votre société ?
                        </Typography>{' '}
                        <FormControlLabel
                          value="is"
                          control={<Radio />}
                          label="IS"
                        />
                        <FormControlLabel
                          value="ir"
                          control={<Radio />}
                          label="IR"
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={handleClick}
                        >
                          <HelpOutlineOutlined />
                        </IconButton>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          // marginThreshold="55"
                          onClose={handleClose}
                          // anchorOrigin={{
                          //   vertical: 'center',
                          //   horizontal: 'center',
                          // }}
                          anchorReference={'none'}
                          className={classes.popOver}
                        >
                          {popoverTextChoixIs}
                        </Popover>
                      </Box>
                    </Field>
                  </Box>
                </Box>
              </Box>
            </Paper>
            <ButtonBox
              activeStep={activeStep}
              isLastStep={isLastStep}
              handleBack={handleBack}
              isSubmitting={isSubmitting}
              submitForm={submitForm}
            />
            <DebugFormik values={values} touched={touched} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
InfoCapitalDeSociete.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoCapitalDeSociete;
