import { Box, Button, Modal, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { kits, NO } from 'constants/usersContants';
import { KIT_4_SCI, KIT_4_SOC } from 'assets/data/kitConstants';
import { SCI } from 'constants/companies';
import Kit from './Kit';

const useStyles = makeStyles({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    backgroundColor: '#F6F6F6',
    borderRadius: 10,
    boxShadow: '20px',
    padding: 30,
    maxWidth: '95vw',
    maxHeight: '95vh',
    overflow: 'auto',
  },
  kit: {
    display: 'flex',
    gap: 20,
  },
  title: {
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'end',
    gap: 15,
  },
  info: {
    marginTop: '3%',
    padding: '15px',
    borderRadius: '15px',
    backgroundColor: '#EFDAB2',
  },
  errMsg: {
    color: 'red',
  },
});

function SwitchKit({
  open,
  handleClose,
  confirmKit,
  isRecap = true,
  formulaireComplet,
}) {
  const classes = useStyles();
  const { kit, companyType, liberationPartielle } = formulaireComplet;

  const [selectedKit, setSelectedKit] = useState(kit);
  const [errorMessage, setErrorMessage] = useState(null);

  const getKitName = (actuelKit) => {
    return kits.filter((k) => k.kitId === actuelKit)[0];
  };

  const getKitsToShow = (pKits) => {
    if (isRecap && liberationPartielle === NO && companyType === SCI) {
      pKits.pop();
      return pKits;
    }
    if (kit.includes('basique')) return pKits;
    if (kit === '') return pKits;
    return pKits.slice(1);
  };

  const getKits = () => {
    const corespondingKit = kits.filter((k) => k.kitId.includes(companyType));
    return getKitsToShow(corespondingKit);
  };

  const showMessage = () => {
    if (selectedKit === KIT_4_SCI || selectedKit === KIT_4_SOC) {
      return (
        <div className={classes.info}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            VOUS AVEZ CHOISI NOTRE KIT CRÉA PLUS
          </Typography>
          <Typography id="modal-modal-description">
            Votre dépôt de capital doit donc être effectué chez notre notaire
            partenaire. Vous accèderez aux informations nécessaires à cette
            démarche lors de la validation de vos documents. Pour tout
            renseignement, contactez-nous à l’adresse :{' '}
            <a href="mailto:juridique@bebusinessfocus.com">
              juridique@bebusinessfocus.com
            </a>
          </Typography>
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modal}>
        <div className={classes.title}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isRecap
              ? `Il est encore temps pour changer de kit, après il sera trop tard!`
              : ``}
            {!isRecap && kit === '' && `Choisissez votre kit.`}
          </Typography>
          <Typography id="modal-modal-description">
            {isRecap &&
              `Actuellement vous avez choisi le kit ${getKitName(kit).title}.
            Êtes-vous sûr que ce kit répond à tous vos besoins `}
            {!isRecap &&
              kit !== '' &&
              `Vous avez indiqué ne pas savoir où déposer vos fonds et souhaitez peut-être un peu de temps pour bien choisir votre banque?
              Découvrez notre KIT PREMIUM : on s’occupe de tout!
              Vous déposez les fonds par l’intermédiaire de notre notaire partenaire le temps de votre immatriculation. 
              Une fois votre Kbis obtenu, ils vous sont reversés sur le compte ouvert au nom de votre entreprise.`}
          </Typography>
        </div>
        <div className={classes.kit}>
          {getKits().map((k, idx) => (
            <Kit
              kit={k}
              key={idx}
              selectedKit={selectedKit}
              setSelectedKit={setSelectedKit}
              formulaireComplet={formulaireComplet}
            />
          ))}
        </div>
        {showMessage()}
        <div className={classes.actions}>
          {selectedKit !== kit && kit !== '' && (
            <Button variant="contained" onClick={() => setSelectedKit(kit)}>
              annuler
            </Button>
          )}
          {errorMessage && (
            <span className={classes.errMsg}>{errorMessage}</span>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (selectedKit === '') {
                setErrorMessage('Vous devez choisir un kit.');
                return;
              }
              confirmKit(selectedKit);
              handleClose();
            }}
          >
            continuez
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default SwitchKit;
