import { YES, NO } from 'constants/usersContants';

const prepareData = (values) => {
  const form = { ...values };
  const { liberationPartielle, pourcentOfRelease, partners } = form;
  if (liberationPartielle === YES && pourcentOfRelease > 0) {
    partners.forEach((partner) => {
      // eslint-disable-next-line no-param-reassign
      partner.partner_liberation_partielle = YES;
      // eslint-disable-next-line no-param-reassign
      partner.partner_montant_liberation_partielle =
        (partner.partner_cash_funds_numbers * pourcentOfRelease) / 100;
    });
  } else {
    partners.forEach((partner) => {
      // eslint-disable-next-line no-param-reassign
      partner.partner_liberation_partielle = NO;
    });
  }
  return form;
};

export default prepareData;
