import React, { Fragment, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Box, Typography, FormControlLabel, Radio } from '@material-ui/core';

import { TextField, RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';

import * as Yup from 'yup';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

// import { DispatchContext } from '../../contexts/sci-partners.context';
// import { DispatchContext } from '../../contexts/association-partners.context';
// import { EDIT_COMPANY } from '../../constants/actions';

import { DispatchContext } from '../../contexts/association-partners.context';

import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '50ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '80ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  radio: {},
}));

const SignupSchema = Yup.object().shape({
  company_name: Yup.string().required('Required'),
  // business_name: Yup.string().required('Required'),
  // code_ape: Yup.string().required('Required'),
  // address: Yup.string().required('Required'),
  // zipcode: Yup.string().required('Required'),
  // city: Yup.string().required('Required'),
});

const InfoSociete = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();
  useEffect(() => {
    setActiveStep(0);
  }, []);
  return (
    <Formik
      initialValues={formValues}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        // onSubmit={() => {
        // // console.log('dispatch', dispatch);
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ errors, values, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper className={classes.root}>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  Dénomination et activités de l&apos;association{' '}
                </Typography>
              </Box>

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Dénomination de l'association"
                  name="company_name"
                  className={classes.nameTextField}
                  autoComplete="off"
                />

                <Field
                  component={TextField}
                  type="string"
                  label="Sigle de l'association"
                  name="business_name"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box alignSelf="center" marginTop="10px">
                <Field
                  component={TextField}
                  type="string"
                  label="Objet de l'association"
                  name="code_ape"
                  className={classes.textField}
                  placeholder=" L’association a pour objet l’animation, la promotion, la coordination….."
                  multiline
                  rows="4"
                  autoComplete="off"
                />
              </Box>

              <Box className={classes.root}>
                <Field component={RadioGroup} name="activites_lucratives">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      L&apos;association aura-t-elle des activités lucratives ?
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>

              {values.activites_lucratives === 'oui' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Field component={RadioGroup} name="vente_produits">
                      <Box>
                        <Typography variant="subtitle1">
                          {' '}
                          L&apos;association va-t-elle vendre des produits ?
                        </Typography>{' '}
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="non"
                          control={<Radio />}
                          label="Non"
                        />
                      </Box>
                    </Field>
                  </Box>
                  {values.vente_produits === 'oui' && (
                    <Box className={classes.root}>
                      <Field
                        component={TextField}
                        type="string"
                        label="Liste des produits que l'association va vendre"
                        name="liste_produits_vendus"
                        className={classes.textField}
                        multiline
                        rows="4"
                        autoComplete="off"
                      />
                    </Box>
                  )}
                  <Box className={classes.root}>
                    <Field component={RadioGroup} name="fourniture_services">
                      <Box>
                        <Typography variant="subtitle1">
                          {' '}
                          L&apos;association va-t-elle fournir des services ?
                        </Typography>{' '}
                        <FormControlLabel
                          value="oui"
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value="non"
                          control={<Radio />}
                          label="Non"
                        />
                      </Box>
                    </Field>
                    {values.fourniture_services === 'oui' && (
                      <Box className={classes.root}>
                        <Field
                          component={TextField}
                          type="string"
                          label="Liste des services que l'association va fournir"
                          name="liste_services_fournis"
                          className={classes.textField}
                          multiline
                          rows="4"
                          autoComplete="off"
                        />
                      </Box>
                    )}
                  </Box>
                </Fragment>
              )}

              <Box className={classes.root}>
                <Field component={RadioGroup} name="utilite_publique">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      L&apos;association sera-t-elle reconnue d&apos;utilité
                      publique ?
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>

              {/* <Box className={classes.root}>
                <Field
                  component={TextField}
                  type="string"
                  label="Adresse du siège social"
                  name="address"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box className={classes.root}>
                <Field
                  component={TextField}
                  type="int"
                  label="Code postal du siège social"
                  name="zipcode"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
                <Field
                  component={TextField}
                  type="string"
                  label="Ville du siège social"
                  name="city"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box> */}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoSociete.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoSociete;
