import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  IconButton,
  Popover,
  Box,
  Typography,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { makeStyles } from '@material-ui/core/styles';

import { RadioGroup } from 'formik-material-ui';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import { format, addYears, endOfYear } from 'date-fns';
import * as Yup from 'yup';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '20px',
  },

  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '20ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '60ch',
  },
  radio: {
    display: 'flex',
    justifyContent: 'center',
  },
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

const InfoSociete3 = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  useEffect(() => {
    setActiveStep(0);
  }, []);

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? 'simple-popover3' : undefined;

  const popoverTextDate = (
    <div className={classes.popOverDiv}>
      <Typography>
        Si vous ne démarrez pas votre activité immédiatement ou dans le mois de
        la signature des statuts, votre société est immatriculée sans activité.
        La création de votre société engendrera un surcout de l’ordre 120 €
        (frais de greffe) et la mise en activité entrainera un traitement
        administratif et des frais supplémentaires ultérieur de l’ordre de 350 €
        (frais de greffe et nos honoraires).
      </Typography>
    </div>
  );

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          // date_debut_activite: Yup.date()

          //   .min(
          //     format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
          //     "la date de début d'activité doit être postérieure à la date de signature des statuts"
          //   )
          //   .required('un choix est necessaire'),

          closing_date_with_year: Yup.date().when(
            'date_debut_activite',
            (date_debut_activite) => {
              if (date_debut_activite !== '') {
                return Yup.date()
                  .required('Required')
                  .min(
                    format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
                    'la date de cloture du premier exercice doit être postérieure à la date de signature des statuts'
                  )
                  .max(
                    format(
                      new Date(endOfYear(new Date(addYears(new Date(), 1)))),
                      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                    ),
                    "date doit etre avant le 31/12 de l'année prochaine"
                  );
              }
              return Yup.date();
              // Yup.date().required('tsttttt');
            }
          ),

          // closing_date_with_year: Yup.date()
          // .when(
          //   'date_debut_activite',
          //   (date_debut_activite) => {
          //     if (date_debut_activite) {

          //       return Yup.date()
          //       .max(
          //         Moment(
          //           "la cloture doit avoir lieu avant le 31/12 de l'année suivante"
          //         )
          //       .required('une date est requise')      ERREUR DE PARSING AVEC UN } ou )
          //         }
          //   }
          // ),

          // .max(
          //   Moment(
          //     values.date_debut_activite.add(1, 'y').endOf('year'),
          //     "la cloture doit avoir lieu avant le 31/12 de l'année suivante"
          //   )
          //   .required('une date est requise')
          // ),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        setActiveStep(1);

        if (isRecap) {
          SciAutomate.recapInfoSociete3VersListeFin();
          setAutomateState(SciAutomate.state);
        }
        if (!isRecap) {
          SciAutomate.nextInfoSociete3VersTypePartenaire();
          setAutomateState(SciAutomate.state);
        }
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6"> Les dates de la société</Typography>
              </Box>

              {/* <Box className={classes.root} marginTop="20px" size="fullwidth">
                <Field component={RadioGroup} name="option_is">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Souhaitez vous être soumis à l&apos;impot sur les sociétés
                      ?{' '}
                    </Typography>{' '}
                    <Box className={classes.radio}>
                      <FormControlLabel
                        value="oui"
                        control={<Radio />}
                        label="Oui"
                      />
                      <FormControlLabel
                        value="non"
                        control={<Radio />}
                        label="Non"
                      />
                    </Box>
                  </Box>
                </Field>
              </Box> */}

              {/* <Box className={classes.root}>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de début d'activité"
                  placeholder="01/01/1990"
                  className={classes.birthdayField}
                  format="dd/MM/yyyy"
                  name="date_debut_activite"
                  autoComplete="off"
                />
              </Box> */}

              {/* {values.option_is === 'oui' && ( */}
              <Box className={classes.root}>
                <Field
                  component={KeyboardDatePicker}
                  clearable
                  label="Date de clôture du 1er exercice"
                  placeholder="01/01/1990"
                  className={classes.birthdayField}
                  format="dd/MM/yyyy"
                  name="closing_date_with_year"
                  autoComplete="off"
                />
              </Box>
              <Box className={classes.root}>
                <Field
                  component={RadioGroup}
                  name="presence_date_debut_activité"
                >
                  <Typography variant="subtitle1">
                    {' '}
                    Démarrez-vous votre activité dans le mois qui va suivre la
                    signature des statuts ?
                  </Typography>{' '}
                  <FormControlLabel
                    value="oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  {values.presence_date_debut_activité === 'oui' && (
                    <Box marginLeft="5%">
                      <Field component={RadioGroup} name="type_depart_activite">
                        {/* <Typography variant="subtitle1">
                          {' '}
                          Démarrez-vous votre activité dans le mois qui va
                          suivre la signature des statuts ?
                        </Typography>{' '} */}
                        <FormControlLabel
                          value="immediatement"
                          control={<Radio />}
                          label="Immédiatement en date de signature des statuts"
                        />
                        <FormControlLabel
                          value="date_externe"
                          control={<Radio />}
                          label="Choix d’une date (Maximum 30 jours après la signature des statuts) "
                        />
                        {values.type_depart_activite === 'date_externe' && (
                          <Box className={classes.root}>
                            <Field
                              component={KeyboardDatePicker}
                              clearable
                              label="Date de début d'activité"
                              placeholder="01/01/1990"
                              className={classes.birthdayField}
                              format="dd/MM/yyyy"
                              name="date_debut_activite"
                              autoComplete="off"
                            />
                          </Box>
                        )}
                      </Field>
                    </Box>
                  )}
                  <Box>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                    {values.presence_date_debut_activité === 'non' && (
                      <>
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={handleClick3}
                        >
                          <HelpOutlineOutlined />
                        </IconButton>
                        <Popover
                          id={id3}
                          open={open3}
                          anchorEl={anchorEl3}
                          onClose={handleClose3}
                          anchorReference={'none'}
                          className={classes.popOver}
                        >
                          {popoverTextDate}
                        </Popover>
                      </>
                    )}
                  </Box>
                </Field>
              </Box>
              {/* )} */}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoSociete3.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoSociete3;
