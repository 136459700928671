import FormActions from 'components/entreprise/V2/FormActions';
import prepareData from 'components/entreprise/V2/typeOfCompany/prepareData';
import { Formik } from 'formik';
import TypeOfCompany from 'components/entreprise/V2/typeOfCompany';
import React, { useEffect, useState } from 'react';
import defaultForm from 'models/initial.context';
import ResetDialog from 'components/commons/ResetDialog';
import { validationSchema } from './validation';

function CompanyTypeContainer({
  goBack,
  saveAndNext,
  formulaireComplet,
  dispatch,
}) {
  localStorage.removeItem('persist:root');
  const [openAlert, setOpenAlert] = useState(false);
  const form = { ...formulaireComplet };
  const { currentForm, eurl_sasu } = form;
  const query = new URLSearchParams(window.location.search);
  const planId = query.get('planId') || '';

  useEffect(() => {
    if (eurl_sasu !== '') {
      setOpenAlert(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes('sci')) {
      localStorage.removeItem('sci-form');
      localStorage.removeItem('form');
    }
  }, []);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <Formik
        initialValues={form}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const data = prepareData(values);
          setSubmitting(false);
          saveAndNext(data);
        }}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <TypeOfCompany
              values={values}
              formulaireComplet={formulaireComplet}
              saveAndNext={saveAndNext}
            />
            {!planId && (
              <FormActions goBack={goBack} currentForm={currentForm} />
            )}
          </form>
        )}
      </Formik>
      <ResetDialog
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        defaultForm={defaultForm}
        dispatch={dispatch}
      />
    </>
  );
}

export default CompanyTypeContainer;
