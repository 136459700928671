import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import Adresse from '../commons/adress';
import useStyles from '../style';

function InfoSyndic() {
  const classes = useStyles();
  return (
    <>
      <Field
        component={TextField}
        type="string"
        label="Prénom ou forme de la société *"
        name="prenom_syndic"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom ou raison sociale de la société *"
        name="nom_syndic"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Adresse
        title={`Adresse`}
        classes={classes}
        address="adresse_syndic"
        city="syndic_city"
        zipCode="syndic_zipcode"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        additionalAddress="additional_address_syndic"
      />
    </>
  );
}

export default InfoSyndic;
