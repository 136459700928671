import axios from 'axios';

const signUp = (values) => {
  const {
    company_name: name,
    business_name,
    director_email,
    associe_gerant_multiassocie,
  } = values;

  const signUpData = {
    name,
    business_name,
    email:
      director_email ||
      associe_gerant_multiassocie.partner_email ||
      associe_gerant_multiassocie[0].partner_email,
    rcsType: 'RCS',
    prestation: false,
    return_on_debit: false,
    periodic_VAT_returns: 'monthly',
    vente: false,
    bookkeeping: 'cash',
    creation: true,
  };

  return axios
    .post(`${process.env.REACT_APP_SIGNUP_URL}/company/simple`, {
      ...signUpData,
    })
    .then((res) => res && res.data)
    .catch((err) => {
      throw err;
    });
};

// eslint-disable-next-line
export { signUp };
