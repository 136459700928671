import {
  OWNER,
  REQUIRED,
  NO,
  TENANT,
  HEAD_OFFICE_AT_SEO,
  HEAD_OFFICE_IN_LOCAL,
} from 'constants/usersContants';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object().shape({
    siege_social_chez: Yup.string().required(REQUIRED),
    maison_individuelle: Yup.string().required(REQUIRED),
    proprietaire: Yup.string().required(REQUIRED),
    nom_syndic: Yup.string().when(
      ['siege_social_chez', 'maison_individuelle', 'proprietaire'],
      {
        is: (siege_social_chez, maison_individuelle, proprietaire) =>
          siege_social_chez === HEAD_OFFICE_AT_SEO &&
          maison_individuelle === NO &&
          proprietaire === OWNER,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    prenom_syndic: Yup.string().when(
      ['siege_social_chez', 'maison_individuelle', 'proprietaire'],
      {
        is: (siege_social_chez, maison_individuelle, proprietaire) =>
          siege_social_chez === HEAD_OFFICE_AT_SEO &&
          maison_individuelle === NO &&
          proprietaire === OWNER,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    adresse_syndic: Yup.string().when(
      ['siege_social_chez', 'maison_individuelle', 'proprietaire'],
      {
        is: (siege_social_chez, maison_individuelle, proprietaire) =>
          siege_social_chez === HEAD_OFFICE_AT_SEO &&
          maison_individuelle === NO &&
          proprietaire === OWNER,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string(),
      }
    ),
    nom_bailleur: Yup.string().when(['siege_social_chez', 'proprietaire'], {
      is: (siege_social_chez, proprietaire) =>
        (siege_social_chez === HEAD_OFFICE_AT_SEO ||
          siege_social_chez === HEAD_OFFICE_IN_LOCAL) &&
        proprietaire === TENANT,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string(),
    }),
    prenom_bailleur: Yup.string().when(['siege_social_chez', 'proprietaire'], {
      is: (siege_social_chez, proprietaire) =>
        (siege_social_chez === HEAD_OFFICE_AT_SEO ||
          siege_social_chez === HEAD_OFFICE_IN_LOCAL) &&
        proprietaire === TENANT,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string(),
    }),
    adresse_bailleur: Yup.string().when(['siege_social_chez', 'proprietaire'], {
      is: (siege_social_chez, proprietaire) =>
        (siege_social_chez === HEAD_OFFICE_AT_SEO ||
          siege_social_chez === HEAD_OFFICE_IN_LOCAL) &&
        proprietaire === TENANT,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string(),
    }),
    email_bailleur: Yup.string().when(['siege_social_chez', 'proprietaire'], {
      is: (siege_social_chez, proprietaire) =>
        (siege_social_chez === HEAD_OFFICE_AT_SEO ||
          siege_social_chez === HEAD_OFFICE_IN_LOCAL) &&
        proprietaire === TENANT,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string(),
    }),
    date_bail: Yup.date().when(['siege_social_chez', 'proprietaire'], {
      is: (siege_social_chez, proprietaire) =>
        (siege_social_chez === HEAD_OFFICE_AT_SEO ||
          siege_social_chez === HEAD_OFFICE_IN_LOCAL) &&
        proprietaire === TENANT,
      then: Yup.date().required(REQUIRED).nullable().typeError(REQUIRED),
      otherwise: Yup.date().nullable(),
    }),
  });
};

//  eslint-disable-next-line
export default validationSchema;
