const clearBaseInfoTiers = (setFieldValue) => {
  setFieldValue('director_title', '');
  setFieldValue('director_name', '');
  setFieldValue('director_firstname', '');
  setFieldValue('director_father_name', '');
  setFieldValue('director_father_firstname', '');
  setFieldValue('director_mother_name', '');
  setFieldValue('director_mother_firstname', '');
  setFieldValue('director_date_of_birth', null);
  setFieldValue('director_place_of_birth', '');
  setFieldValue('director_place_of_birth_zipCode', '');
  setFieldValue('director_state_of_birth', '');
  setFieldValue('director_country_of_birth', '');
  setFieldValue('director_nationality', '');
  setFieldValue('director_place_of_living', '');
  setFieldValue('director_zipcode', '');
  setFieldValue('director_city', '');
  setFieldValue('director_email', '');
  setFieldValue('duree_gerance_determinee', '');
  setFieldValue('duree_de_gerance', 0);
  setFieldValue('director_family_situation', '');
  setFieldValue('director_secu_number', '');
};

const clearInfoWeddingTiers = (setFieldValue) => {
  setFieldValue('director_spouce_title', '');
  setFieldValue('director_spouce_name', '');
  setFieldValue('director_spouce_firstname', '');
  setFieldValue('director_spouce_email', '');
  setFieldValue('director_spouce_secu_number', '');
  setFieldValue('director_spouce_birthday', null);
  setFieldValue('director_spouce_place_of_birth', '');
  setFieldValue('director_spouce_adress', '');
  setFieldValue('director_spouce_city', '');
  setFieldValue('director_spouce_zipcode', '');
  setFieldValue('director_spouce_additional_adress', '');
  setFieldValue('director_spouce_effective_date', null);
  setFieldValue('director_spouce_position', '');
  setFieldValue('director_wedding_prenup_type', '');
};

const clearInfoPacseTiers = (setFieldValue) => {
  setFieldValue('director_pacs_type', '');
  setFieldValue('director_pacse_title', '');
  setFieldValue('director_pacse_name', '');
  setFieldValue('director_pacse_firstname', '');
  setFieldValue('director_pacse_email', '');
  setFieldValue('director_pacse_secu_number', '');
  setFieldValue('director_pacse_birthday', null);
  setFieldValue('director_pacse_place_of_birth', '');
  setFieldValue('director_pacse_adress', '');
  setFieldValue('director_pacse_city', '');
  setFieldValue('director_pacse_zipcode', '');
  setFieldValue('director_pacse_additional_adress', '');
  setFieldValue('director_pacse_effective_date', null);
  setFieldValue('director_pacse_position', '');
};

const clearInfoCohabitantTiers = (setFieldValue) => {
  setFieldValue('director_cohabitant_title', '');
  setFieldValue('director_cohabitant_name', '');
  setFieldValue('director_cohabitant_firstname', '');
  setFieldValue('director_cohabitant_email', '');
  setFieldValue('director_cohabitant_secu_number', '');
  setFieldValue('director_cohabitant_birthday', null);
  setFieldValue('director_cohabitant_place_of_birth', '');
  setFieldValue('director_cohabitant_adress', '');
  setFieldValue('director_cohabitant_city', '');
  setFieldValue('director_cohabitant_zipcode', '');
  setFieldValue('director_cohabitant_additional_adress', '');
  setFieldValue('director_cohabitant_effective_date', null);
  setFieldValue('director_cohabitant_position', '');
};

const clearInfoFamilySituationTiers = (setFieldValue) => {
  clearInfoWeddingTiers(setFieldValue);
  clearInfoPacseTiers(setFieldValue);
  clearInfoCohabitantTiers(setFieldValue);
};

const cleanInfoTiers = (setFieldValue) => {
  clearBaseInfoTiers(setFieldValue);
  clearInfoFamilySituationTiers(setFieldValue);
};
export default cleanInfoTiers;
