import React from 'react';
import RedirectPopup from 'components/commons/redirectPopup';
// import CreationEntrepriseContainer from '../containers/CreationEntrepriseContainer';
import CreationSciContainer from '../containers/CreationSciContainer';
import { FormProvider } from '../contexts/sci-partners.context';

const CreationEntreprise = () => {
  const query = new URLSearchParams(window.location.search);

  const kit = query.get('kit') || '';

  return (
    <>
      <RedirectPopup kit={kit} />
      <FormProvider kit={kit}>
        {/* <CreationEntrepriseContainer /> */}
        <CreationSciContainer />
      </FormProvider>
    </>
  );
};

export default CreationEntreprise;
