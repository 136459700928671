const sarl1 = {
  nom_propre: 'societe',
  alone: 'a_plusieurs',
  personne_physique_morale: 'personne_physique',
  choix_is: 'non',
  nombre_de_personnes_physiques: '',
  nombre_de_personnes_morales: '',
  eurl_sasu: 'sarl',
  sci: '',
  company_name: 'formDenoSociale',
  business_name: 'formNomCommercial',
  code_ape: 'formObjetSocial',
  address: 'Traverse du T',
  zipcode: '06600',
  city: 'Antibes',
  siege_social_chez: 'chez_gerant',
  proprietaire: 'Proprietaire',
  maison_individuelle: 'oui',
  prenom_syndic: '',
  nom_syndic: '',
  adresse_syndic: '',
  prenom_bailleur: '',
  nom_bailleur: '',
  adresse_bailleur: '',
  date_bail: null,
  date_debut_activite: '2021-11-25',
  closing_date_with_year: '2021-11-26',
  partners: [
    {
      id: '9d6bde2d-8011-4e99-a78f-e41112de2e1e',
      partner_title: 'M.',
      partner_name: 'formNomPP1',
      partner_firstname: 'formPrenomPP1',
      partner_father_name: 'formNomPerePP1',
      partner_father_firstname: 'formPrenomPerePP1',
      partner_mother_name: 'formNomMerePP1',
      partner_mother_firstname: 'formPrenomMerePP1',
      partner_date_of_birth: '2021-11-01',
      partner_place_of_birth: 'Hyds',
      partner_place_of_living: 'U Centru',
      partner_city: 'Porto-Vecchio',
      partner_zipcode: '20137',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'formPaysNaissancePP1',
      partner_nationality: 'formNationalitePP1',
      partner_family_situation: 'marie9(e)',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: '2021-11-02',
      partner_wedding_prenup_place: 'Forest-Montiers',
      partner_wedding_prenup_officer_office_name: 'formNomOfficierMariage',
      partner_wedding_prenup_officer_office_place:
        'Chemin Ar C\u2019Hourt 29470 Plougastel-Daoulas',
      partner_spouse_name: 'formNomConjointPP1',
      partner_spouse_firstname: 'formPrenomConjointPP1',
      partner_wedding_date: '2021-11-01',
      partner_wedding_place: 'Saint-Geniez-f4-Merle',
      partner_spouse_date_naissance: '2021-11-02',
      partner_spouse_lieu_naissance: 'Boissy-le-Che2tel',
      partner_spouse_departement_naissance: 'cele-de-France',
      pacs_spouse_date_naissance: null,
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: 'oui',
      partner_cash_funds_numbers: '10000',
      partner_cash_funds_letters: '',
      partner_apport_nature: 'oui',
      partner_supplies_funds_numbers: '5000',
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: 'oui',
      partner_valeur_apport_fonds_de_commerce: '1700',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: 'oui',
      partner_date_apport_titres_societe: '2021-11-01',
      partner_valeur_apport_titres_societe: '1600',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe:
        'formRSSoiceteApporte',
      partner_nombre_titres_apportes_apport_titres_societe: '1500',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '20000',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: 'oui',
      partner_valeur_apport_nature_divers: '2100',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: '2021-10-15',
      partner_description_biens_apport_nature_divers:
        'formDescriptionApportsNatureDivers',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'oui',
      partner_montant_liberation_partielle: '2500',
      partner_email: 'shooter.xav@hotmail.fr',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Auvergne-Rhf4ne-Alpes',
      partner_date_apport_fonds_de_commerce: '2021-11-02',
    },
    {
      id: '0bdc6bb3-adad-4daa-a948-354854e2a9c4',
      forme_juridique_personne_morale: 'SCS',
      capital_personne_morale: 80000,
      raison_sociale_actionnaire_personne_morale_sasu: 'formRSPM1',
      adresse_actionnaire_personne_morale_sasu: 'Impasse Durocher 1',
      code_postal_actionnaire_personne_morale_sasu: '97232',
      ville_actionnaire_personne_morale_sasu: 'Le Lamentin',
      rcs_actionnaire_personne_morale_sasu: '465487971',
      ville_rcs_actionnaire_personne_morale_sasu: 'Fort-de-France',
      titre_dirigeant_de_personne_morale_sasu: '',
      nom_dirigeant_de_personne_morale_sasu: '',
      prenom_dirigeant_de_personne_morale_sasu: '',
      adresse_dirigeant_de_personne_morale_sasu: '',
      code_postal_dirigeant_de_personne_morale_sasu: '',
      ville_dirigeant_de_personne_morale_sasu: '',
      partner_title: 'M.',
      partner_name: 'formNomDirigeantPM1',
      partner_firstname: 'formPrenomDirigeantPM1',
      partner_father_name: 'formNomPereDirigeantPM1',
      partner_father_firstname: 'formPrenomPereDIrigeantPM1',
      partner_mother_name: 'formNomMEreDirigeantPM1',
      partner_mother_firstname: 'formPrenomMereDirigeantPM1',
      partner_date_of_birth: '2021-10-04',
      partner_place_of_birth: 'Jaignes',
      partner_place_of_living: 'U Centru',
      partner_city: 'Porto-Vecchio',
      partner_zipcode: '20137',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'formPaysNaissanceDirigeantPM1',
      partner_nationality: 'formNationaliteDirigeantPM1',
      partner_family_situation: 'marie9(e)',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: null,
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: 'oui',
      partner_cash_funds_numbers: '7500',
      partner_cash_funds_letters: '',
      partner_apport_nature: '',
      partner_supplies_funds_numbers: 0,
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce: '',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: '',
      partner_date_apport_titres_societe: null,
      partner_valeur_apport_titres_societe: '',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe: '',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: '',
      partner_valeur_apport_nature_divers: '',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: null,
      partner_description_biens_apport_nature_divers: '',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: 'shooter.xav@hotmail.fr',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'cele-de-France',
    },
    {
      id: 'f192dacd-e809-424b-a81a-9a9c73b9a276',
      partner_title: 'M.',
      partner_name: 'formNomPP2',
      partner_firstname: 'formPrenomPP2',
      partner_father_name: 'formNomPerePP2',
      partner_father_firstname: 'formPrenomPerePP2',
      partner_mother_name: 'formNomMerePP2',
      partner_mother_firstname: 'formPrenomMerePP2',
      partner_date_of_birth: '2021-11-02',
      partner_place_of_birth: 'Boissy-le-Che2tel',
      partner_place_of_living: 'Kergre9ac\u2019H',
      partner_city: 'Plabennec',
      partner_zipcode: '29860',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'iouiuh',
      partner_nationality: 'iuhiuh',
      partner_family_situation: 'pacse9(e)',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: null,
      partner_wedding_prenup_place: '',
      partner_wedding_prenup_officer_office_name: '',
      partner_wedding_prenup_officer_office_place: '',
      partner_spouse_name: '',
      partner_spouse_firstname: '',
      partner_wedding_date: null,
      partner_wedding_place: '',
      partner_spouse_date_naissance: null,
      partner_spouse_lieu_naissance: '',
      partner_spouse_departement_naissance: '',
      pacs_spouse_date_naissance: '2021-11-02',
      pacs_date: '2021-10-12',
      pacs_place: 'formLieuUnionPP2',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: 'non',
      partner_cash_funds_numbers: 0,
      partner_cash_funds_letters: '',
      partner_apport_nature: 'oui',
      partner_supplies_funds_numbers: '8000',
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: 'oui',
      partner_valeur_apport_fonds_de_commerce: '3600',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: 'oui',
      partner_date_apport_titres_societe: '2021-10-22',
      partner_valeur_apport_titres_societe: '1400',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe:
        'formRSSocieteApportee',
      partner_nombre_titres_apportes_apport_titres_societe: '50',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '10000',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: 'oui',
      partner_valeur_apport_nature_divers: '3000',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: '2021-11-02',
      partner_description_biens_apport_nature_divers:
        'formDescriptionBienApporte',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'non',
      partner_montant_liberation_partielle: '',
      partner_email: 'shooter.xav@hotmail.fr',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'cele-de-France',
      pacs_spouse_title: 'Mme',
      pacs_spouse_name: 'formNomConjointPP2',
      pacs_spouse_firstname: 'formPrenomConjointPP2',
      pacs_spouse_lieu_naissance: 'Chare9zier',
      pacs_spouse_departement_naissance: 'Bourgogne-Franche-Comte9',
      partner_date_apport_fonds_de_commerce: '2021-10-20',
    },
  ],
  capital_in_numbers: '30500',
  share_value: '100',
  depot_capital_chez: 'be_business_focus',
  date_depot_des_fonds: null,
  banking_corporation_name: '',
  adresse_agence_bancaire: '',
  ville_agence_bancaire: '',
  code_postal_agence_bancaire: '',
  cash_funds_numbers: '',
  apport_nature: 'non',
  supplies_funds_numbers: '0',
  apport_fonds_de_commerce: 'non',
  date_apport_fonds_de_commerce: null,
  valeur_apport_fonds_de_commerce: '',
  apport_titres_societe: 'non',
  date_apport_titres_societe: null,
  valeur_apport_titres_societe: '',
  raison_sociale_societe_apport_titres_societe: '',
  nombre_titres_apportes_apport_titres_societe: '',
  nombre_titres_total_apport_titres_societe: '',
  apport_nature_divers: 'non',
  date_apport_nature_divers: null,
  valeur_apport_nature_divers: '',
  description_biens_apport_nature_divers: '',
  apport_industrie: 'non',
  expertise_funds_numbers: '',
  cash_funds_onetime_payment: 'oui',
  payments_amount: '',
  director_type: "l_associe_de_l'EURL",
  director_title: 'M.',
  director_name: '',
  director_firstname: '',
  director_father_name: '',
  director_father_firstname: '',
  director_mother_name: '',
  director_mother_firstname: '',
  director_date_of_birth: null,
  director_place_of_birth: '',
  director_state_of_birth: '',
  director_country_of_birth: '',
  director_nationality: '',
  director_place_of_living: '',
  director_email: '',
  associe_gerant_multiassocie: [
    {
      id: '9d6bde2d-8011-4e99-a78f-e41112de2e1e',
      partner_title: 'M.',
      partner_name: 'formNomPP1',
      partner_firstname: 'formPrenomPP1',
      partner_father_name: 'formNomPerePP1',
      partner_father_firstname: 'formPrenomPerePP1',
      partner_mother_name: 'formNomMerePP1',
      partner_mother_firstname: 'formPrenomMerePP1',
      partner_date_of_birth: '2021-11-01',
      partner_place_of_birth: 'Hyds',
      partner_place_of_living: 'U Centru',
      partner_city: 'Porto-Vecchio',
      partner_zipcode: '20137',
      partner_country: {
        id: '250',
        name_fr: 'France',
        UE: 'True',
        alpha2: 'FR',
        alpha3: 'FRA',
      },
      partner_country_of_birth: 'formPaysNaissancePP1',
      partner_nationality: 'formNationalitePP1',
      partner_family_situation: 'marie9(e)',
      partner_wedding_prenup: 'oui',
      partner_wedding_prenup_type: 'separation_de_biens',
      partner_wedding_prenup_date: '2021-11-02',
      partner_wedding_prenup_place: 'Forest-Montiers',
      partner_wedding_prenup_officer_office_name: 'formNomOfficierMariage',
      partner_wedding_prenup_officer_office_place:
        'Chemin Ar C\u2019Hourt 29470 Plougastel-Daoulas',
      partner_spouse_name: 'formNomConjointPP1',
      partner_spouse_firstname: 'formPrenomConjointPP1',
      partner_wedding_date: '2021-11-01',
      partner_wedding_place: 'Saint-Geniez-f4-Merle',
      partner_spouse_date_naissance: '2021-11-02',
      partner_spouse_lieu_naissance: 'Boissy-le-Che2tel',
      partner_spouse_departement_naissance: 'cele-de-France',
      pacs_spouse_date_naissance: null,
      pacs_date: null,
      pacs_place: '',
      pacs_type: 'de_la_separation_des_patrimoines',
      partner_apport_numeraire: 'oui',
      partner_cash_funds_numbers: '10000',
      partner_cash_funds_letters: '',
      partner_apport_nature: 'oui',
      partner_supplies_funds_numbers: '5000',
      partner_supplies_funds_letters: '',
      partner_apport_fonds_de_commerce: 'oui',
      partner_valeur_apport_fonds_de_commerce: '1700',
      partner_valeur_apport_fonds_de_commerce_lettres: '',
      partner_apport_titres_societe: 'oui',
      partner_date_apport_titres_societe: '2021-11-01',
      partner_valeur_apport_titres_societe: '1600',
      partner_valeur_apport_titres_societe_lettres: '',
      partner_raison_sociale_societe_apport_titres_societe:
        'formRSSoiceteApporte',
      partner_nombre_titres_apportes_apport_titres_societe: '1500',
      partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
      partner_nombre_titres_total_apport_titres_societe: '20000',
      partner_nombre_titres_total_apport_titres_societe_lettres: '',
      partner_apport_nature_divers: 'oui',
      partner_valeur_apport_nature_divers: '2100',
      partner_valeur_apport_nature_divers_lettres: '',
      partner_date_apport_nature_divers: '2021-10-15',
      partner_description_biens_apport_nature_divers:
        'formDescriptionApportsNatureDivers',
      partner_nombre_parts_representatives: '',
      partner_numero_part_representative_debut: '',
      partner_numero_part_representative_fin: '',
      partner_apport_industrie: '',
      partner_expertise_funds_numbers: '',
      partner_expertise_funds_numbers_lettres: '',
      partner_nombre_parts_non_representatives: '',
      partner_numero_part_non_representative_debut: '',
      partner_numero_part_non_representative_fin: '',
      partner_liberation_partielle: 'oui',
      partner_montant_liberation_partielle: '2500',
      partner_email: 'shooter.xav@hotmail.fr',
      director_email: '',
      dg_email: '',
      partner_state_of_birth: 'Auvergne-Rhf4ne-Alpes',
      partner_date_apport_fonds_de_commerce: '2021-11-02',
    },
  ],
  presence_dg: '',
  dg: [],
  dg_type: "l_associe_de_l'EURL",
  dg_title: 'M.',
  dg_name: '',
  dg_firstname: '',
  dg_address: '',
  dg_zipcode: '',
  dg_city: '',
  dg_email: '',
  duree_gerance_determinee: 'non',
  duree_de_gerance: '0',
  preexisting_contract: 'oui',
  number_of_preexisting_contract: 2,
  contract1_date: '2021-10-14',
  contract1_type: 'formNatureActePrealable1',
  contract1_amount: '832',
  contract1_person_who_paid: 'formPayeurActePrealable1',
  contract2_date: '2021-11-02',
  contract2_type: 'formNatureActePrealable2',
  contract2_amount: '168',
  contract2_person_who_paid: 'formPayeurActePrealable2',
  presence_commissaire_aux_comptes: 'oui',
  duree_exercice_commissaire_aux_comptes: 3,
  titre_commissaire_aux_comptes: 'M.',
  nom_commissaire_aux_comptes: '',
  prenom_commissaire_aux_comptes: '',
  nom_cabinet_commissaire_aux_comptes: 'formNomCabinetCAC',
  adresse_cabinet_commissaire_aux_comptes:
    'Impasse Durocher 1 97232 Le Lamentin',
  nom_suppleant: '',
  prenom_suppleant: '',
  adresse_cabinet_suppleant: '',
  procuration: 'oui',
  nom_cabinet_procuration: 'formNomCabinetProcuration',
  qualite_cabinet_procuration: 'formQualiteCabinetProcuration',
  adresse_cabinet_procuration: 'Avenue du Che2teau d\u2019O',
  ville_cabinet_procuration: 'Montpellier',
  code_postal_cabinet_procuration: '34090',
  procu_bbf_dossier_constitution: 'non',
  procu_bbf_attestation_beneficiaires: 'non',
  procu_bbf_annonce_legale: 'non',
  procu_bbf_attestation_depot_fonds: 'non',
  procu_bbf_depot_greffe_dossier_constitution: 'non',
  rcs_ville: 'Fort-de-France',
  kit: 'kit4soc',
  companyAlreadyCreated: null,
  email: '',
  documents: [],
  cac_suppleant: 'oui',
  partenaire_supplementaire: 'non',
  email_dg: '',
  type_CAC: 'morale',
  nom_propre_CAC: 'nom_propre',
  type_CAC_suppleant: 'physique',
  director_zipcode: '29860',
  director_city: 'Plabennec',
  dg_father_name: 'formNomPereDG',
  dg_father_firstname: 'formPRenomPereDG',
  dg_mother_name: 'fonrNomMEreDG',
  dg_mother_firstname: 'formPrenomMereDG',
  dg_date_of_birth: '2021-11-02',
  dg_place_of_birth: 'formVilleNaissanceDG',
  dg_state_of_birth: 'formDepartementNaissanceDG',
  dg_country_of_birth: 'formPaysNaissanceDG',
  dg_nationality: 'formNationaliteDG',
  forme_juridique_cabinet_CAC: 'SC',
  alternativeId: 'mg62ki7',
};

export default sarl1;
