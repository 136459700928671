import React, { useContext, useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import {
  Popover,
  IconButton,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  Grid,
  TextField as MUITextField,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { TextField, RadioGroup } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import * as Yup from 'yup';

import { format } from 'date-fns';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  margintTop: {
    marginRight: theme.spacing(0.5),
  },
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

const SignupSchema = Yup.object().shape({
  pacs_spouse_name: Yup.string().required('un choix est necessaire'),
  pacs_spouse_firstname: Yup.string().required('un choix est necessaire'),
  // pacs_spouse_date_naissance: Yup.date().required('un choix est necessaire'),
  pacs_spouse_date_naissance: Yup.date()
    .max(
      format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      "la date de naissance doit être antérieure a la date d'aujourd'hui"
    )
    .required('un choix est necessaire'),
  pacs_spouse_lieu_naissance: Yup.string().required('un choix est necessaire'),
  pacs_spouse_departement_naissance: Yup.string().required(
    'un choix est necessaire'
  ),
  pacs_date: Yup.date().required('un choix est necessaire'),
  pacs_place: Yup.string().required('un choix est necessaire'),
});

const InfoPacs = ({
  partner,
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // partnerIndex,
  // setPartnerIndex,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  let partnerOrShareholderLabel = "de l'associé";

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    partnerOrShareholderLabel = "de l'actionnaire";
  }

  const [cityList, setCityList] = useState([]);
  const [cityValue, setcityValue] = useState(null);
  const [cityInput, setCityInput] = useState(null);
  const [loadingCityList, setLoadingCityList] = useState(false);

  const cityInputDebounce = useRef(
    _.debounce((newValue) => setCityInput(newValue), 500)
  );

  useEffect(() => {
    if (cityInput) {
      setLoadingCityList(true);
      axios
        .get(`${process.env.REACT_APP_URL_GEO}`, {
          params: { q: cityInput, type: 'municipality', autocomplete: 1 },
        })
        .then((res) => {
          const { data } = res;
          if (data && data.features) {
            setCityList(data.features);
          } else {
            setCityList([]);
          }
          setLoadingCityList(false);
        });
    } else {
      setCityList([]);
      setLoadingCityList(false);
    }
  }, [cityInput]);

  const handleFetchCity = async (newInputValue) => {
    setLoadingCityList(true);
    setCityInput(null);
    cityInputDebounce.current(newInputValue);
  };

  const handleSelectCity = async (newValue, setFieldValue) => {
    if (newValue) {
      setcityValue(newValue);
      setFieldValue('pacs_spouse_lieu_naissance', newValue.properties.name);
      if (newValue.properties.context) {
        const { context } = newValue.properties;
        const contextArr = context.split(', ');
        const state = contextArr[contextArr.length - 2];
        setFieldValue('pacs_spouse_departement_naissance', state);
      }
    } else {
      setFieldValue('pacs_spouse_lieu_naissance', '');
      setFieldValue('pacs_spouse_departement_naissance', '');
      setcityValue(newValue);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let popoverTextPacs = '';

  if (formValues.eurl_sasu === 'eurl') {
    popoverTextPacs = (
      <div className={classes.popOverDiv}>
        <Typography>
          S’agissant des partenaires liés par un PACS, ils peuvent en principe
          souscrire librement des parts sociales d’une EURL en toute
          indépendance, sauf clause contraire prévue dans la convention de PACS.{' '}
          <br />
          Aucun formalisme n’est nécessaire lorsque les parts sont financées par
          des apports personnels ne faisant pas partie de la communauté, et quel
          que soit le régime matrimonial.
        </Typography>
      </div>
    );
  }
  return (
    <Formik
      initialValues={partner}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_PARTNER,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }

        Automate.nextInfoPacsversChoixTypePacs();
        setAutomateState(Automate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Box>
                <Typography variant="h6">Pacsé(e)</Typography>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClick}
                >
                  <HelpOutlineOutlined />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  // marginThreshold="55"
                  onClose={handleClose}
                  // anchorOrigin={{
                  //   vertical: 'center',
                  //   horizontal: 'center',
                  // }}
                  anchorReference={'none'}
                  className={classes.popOver}
                >
                  {popoverTextPacs}
                </Popover>
              </Box>
              {/* MBM */}
              <Typography variant="subtitle2">
                {' '}
                informations sur le conjoint {partnerOrShareholderLabel}{' '}
              </Typography>
              <Field component={RadioGroup} name="pacs_spouse_title">
                <FormControlLabel value="M." control={<Radio />} label="M." />
                <FormControlLabel value="Mme" control={<Radio />} label="Mme" />
              </Field>

              {/* /MBM */}
              <Field
                component={TextField}
                type="string"
                label={`Nom du conjoint ${partnerOrShareholderLabel}`}
                name="pacs_spouse_name"
                className={classes.textField}
                autoComplete="off"
              />
              <Field
                component={TextField}
                type="string"
                label={`Prénom du conjoint ${partnerOrShareholderLabel}`}
                name="pacs_spouse_firstname"
                className={classes.textField}
                autoComplete="off"
              />
              <Field
                component={KeyboardDatePicker}
                clearable
                label={`date de naissance du conjoint ${partnerOrShareholderLabel}`}
                maxDate={new Date()}
                placeholder="01/01/1990"
                format="dd/MM/yyyy"
                name="pacs_spouse_date_naissance"
                className={classes.textField}
                autoComplete="off"
              />
              <Field
                component={Autocomplete}
                // type="string"
                // label={`Lieu de naissance du conjoint ${partnerOrShareholderLabel}`}
                name="pacs_spouse_lieu_naissance"
                className={classes.textField}
                value={cityValue}
                loading={loadingCityList}
                options={cityList}
                filterOptions={(x) => x}
                autoComplete
                includeInputInList
                filterSelectedOptions
                getOptionLabel={(option) =>
                  option && option.properties && option.properties.name
                }
                onInputChange={(event, newInputValue) =>
                  handleFetchCity(newInputValue)
                }
                onChange={(event, newValue) =>
                  handleSelectCity(newValue, setFieldValue)
                }
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    error={
                      touched.pacs_spouse_lieu_naissance &&
                      !!errors.pacs_spouse_lieu_naissance
                    }
                    helperText={
                      touched.pacs_spouse_lieu_naissance &&
                      errors.pacs_spouse_lieu_naissance
                    }
                    label={`Lieu de naissance du conjoint ${partnerOrShareholderLabel}`}
                    placeholder={partner.pacs_spouse_lieu_naissance}
                    size="small"
                  />
                )}
                renderOption={(option) => {
                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" color="textSecondary">
                          {option.properties.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
              />
              <Field
                component={TextField}
                type="string"
                label={`Département de naissance du conjoint ${partnerOrShareholderLabel}`}
                name="pacs_spouse_departement_naissance"
                className={classes.textField}
                placeholder="ex: Paris 11eme arrondissement"
                autoComplete="off"
                value={
                  !values.pacs_spouse_departement_naissance
                    ? ''
                    : values.pacs_spouse_departement_naissance
                }
              />

              <Field
                component={KeyboardDatePicker}
                clearable
                label="Date de l'union"
                placeholder="01/01/1990"
                format="dd/MM/yyyy"
                name="pacs_date"
                className={classes.textField}
                autoComplete="off"
              />

              <Field
                component={TextField}
                type="string"
                label="Lieu de l'union"
                // name={`partners[${partnerIndex}].pacs_place`}
                name="pacs_place"
                className={classes.textField}
                autoComplete="off"
              />
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoPacs.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoPacs;
