import { Avatar } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React from 'react';
import useStyles from '../style';

function CardButton({ handleClick, title }) {
  const classes = useStyles();

  return (
    <div className={classes.addPartnerCard} onClick={handleClick}>
      <Avatar className={classes.avatar}>
        <Add />
      </Avatar>
      {title}
    </div>
  );
}

export default CardButton;
