import React, { Fragment, useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import {
  Popover,
  IconButton,
  MenuItem,
  FormControlLabel,
  Radio,
  Box,
  Typography,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { RadioGroup, Select } from 'formik-material-ui';

import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import theme from 'assets/jss/theme';
import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

const ChoixNombreActesPrealables = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
  // partnerGerant,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  // console.log('ICICICICICCpartnergerant', partnerGerant);
  // console.log('state au lanbcement choixNombreActes prealable', Automate.state);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let popoverTextActesPrealables = '';

  if (formValues.eurl_sasu === 'eurl') {
    popoverTextActesPrealables = (
      <div className={classes.popOverDiv}>
        <Typography>
          Dès lors que vous avez accompli des actes préalablement à la
          constitution de votre société vous pouvez les faire reprendre à la
          création de façon à en informer les autres associés et les créanciers
          de l’entreprise. Cela permettra également de vous les faire rembourser
          et de les comptabiliser dans les comptes de votre société.
        </Typography>
      </div>
    );
  }
  if (formValues.eurl_sasu === 'sasu') {
    popoverTextActesPrealables = (
      <div className={classes.popOverDiv}>
        <Typography>
          Dès lors que vous avez accompli des actes préalablement à la
          constitution de votre société vous pouvez les faire reprendre à la
          création de façon à en informer les autres associés et les créanciers
          de l’entreprise. Cela permettra également de vous les faire rembourser
          et de les comptabiliser dans les comptes de votre société.
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sas') {
    popoverTextActesPrealables = (
      <div className={classes.popOverDiv}>
        <Typography>
          Dès lors que vous avez accompli des actes préalablement à la
          constitution de votre société vous pouvez les faire reprendre à la
          création de façon à en informer les autres associés et les créanciers
          de l’entreprise. Cela permettra également de vous les faire rembourser
          et de les comptabiliser dans les comptes de votre société.
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sarl') {
    popoverTextActesPrealables = (
      <div className={classes.popOverDiv}>
        <Typography>
          Dès lors que vous avez accompli des actes préalablement à la
          constitution de votre société vous pouvez les faire reprendre à la
          création de façon à en informer les autres associés et les créanciers
          de l’entreprise. Cela permettra également de vous les faire rembourser
          et de les comptabiliser dans les comptes de votre société.
        </Typography>
      </div>
    );
  }

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          preexisting_contract: Yup.string().required(
            'un choix est necessaire'
          ),

          number_of_preexisting_contract: Yup.number().when(
            'preexisting_contract',
            (preexisting_contract) => {
              if (preexisting_contract === 'oui') {
                return Yup.number().required('Required');
              }
              return Yup.number();
            }
          ),

          // number_of_preexisting_contract: Yup.number().required(
          //   'un choix est necessaire'
          // ),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        //   return;
        // }
        if (
          automateState === 'choix_nombre_actes_prealables' &&
          values.number_of_preexisting_contract > 0
        ) {
          Automate.nextChoixNombreActesPrealablesVersInfoActesPrealables();
          setAutomateState(Automate.state);
          return;
        }

        if (
          automateState === 'choix_nombre_actes_prealables' &&
          values.number_of_preexisting_contract === 0
        ) {
          Automate.nextChoixNombreActesPrealablesVersChoixCommissaireAuxComptes();
          setAutomateState(Automate.state);
        }

        // Automate.next();
        // setAutomateState(Automate.state);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        submitForm,
      }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Box width="75%" textAlign="center">
                <Typography variant="h6">
                  {/* Reprise d&apos;actes préalablement à la création de la société{' '} */}
                  Avez-vous accompli des actes juridiques pour le compte de
                  votre société préalablement à la signature des statuts ?
                </Typography>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClick}
                >
                  <HelpOutlineOutlined />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  // marginThreshold="55"
                  onClose={handleClose}
                  // anchorOrigin={{
                  //   vertical: 'center',
                  //   horizontal: 'center',
                  // }}
                  anchorReference={'none'}
                  className={classes.popOver}
                >
                  {popoverTextActesPrealables}
                </Popover>
              </Box>

              <Box
                width="75%"
                textAlign="center"
                marginTop={theme.spacing(0.25)}
                marginBottom={theme.spacing(0.25)}
              >
                <Typography variant="subtitle1">
                  {' '}
                  Les actes concernés doivent avoir été effectués au nom et pour
                  le compte de la société {formValues.company_name} en
                  formation. <br></br>
                  Exemple : signature d&apos;une promesse de bail commercial,
                  signature de propositions commerciales pour la création
                  d&apos;un site web, achats de matériels...
                  {/* Associé personne physique{' '} */}
                </Typography>

                <Box marginLeft="47%" textAlign="center" width="10%">
                  <Field component={RadioGroup} name="preexisting_contract">
                    <FormControlLabel
                      value="non"
                      // onClick={() => {
                      //   setFieldValue('number_of_preexisting_contract', 0);
                      // }}
                      onClick={() => {
                        // formValues.number_of_preexisting_contract = 0;
                        setFieldValue('number_of_preexisting_contract', 0);
                      }}
                      control={<Radio />}
                      label="Non"
                      //   " Cliquez ici, si vous n'avez pas accompli d'acte préalablement à la
                      // signature des statuts"
                      // onChange={() => {
                      //   formValues.preexisting_contract = 'non';
                      // }}
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                      //   "Cliquez ici, si vous avez accompli des actes préalablement à la
                      // signature des statuts"
                      // onChange={() => {
                      //   formValues.preexisting_contract = 'oui';
                      // }}
                    />
                  </Field>
                </Box>
              </Box>
              {values.preexisting_contract === 'oui' && (
                <Fragment>
                  <Typography variant="subtitle2">
                    veuillez indiquer ci-dessous, le nombre d&apos;actes
                    accomplis{' '}
                  </Typography>
                  <Box alignItems="center" marginBottom={theme.spacing(0.5)}>
                    <FormControl>
                      <Field
                        component={Select}
                        name="number_of_preexisting_contract"
                        inputProps={{
                          id: 'number_of_preexisting_contract',
                        }}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                      </Field>
                    </FormControl>
                  </Box>
                </Fragment>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixNombreActesPrealables.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixNombreActesPrealables;
