import axios from 'axios';

export const getUrlOfDocument = async (docKey, companyId) => {
  return axios.get(`${process.env.REACT_APP_GET_URL}?key=${docKey}`, {
    headers: {
      'X-Company-Id': companyId,
    },
  });
};

const getUploadUrl = async (alternativeId, file) => {
  return axios.get(
    `${process.env.REACT_APP_GET_UPLOAD_URL}?name=${file.name}&alternativeId=${alternativeId}&type=${file.type}`
  );
};

export const uploadDocument = async (alternativeId, file) => {
  try {
    const { data: presignedPostUrl } = await getUploadUrl(alternativeId, file);
    const formData = new FormData();
    const { key } = presignedPostUrl.fields;
    formData.append('Content-Type', file.type);
    Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('file', file); // The file has be the last element

    await axios.post(presignedPostUrl.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return { key };
  } catch (e) {
    const { response: errResponse } = e;

    if (errResponse) {
      const { data: errData } = errResponse;

      if (errData) {
        if (errData.includes('EntityTooLarge')) {
          return { errCode: 'EntityTooLarge' };
        }
      }
    }

    return null;
  }
};

export const fetchDocument = async (url) => {
  const data = await axios
    .get(url, {
      responseType: 'blob',
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
  return data;
};
