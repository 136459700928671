import React, { useEffect } from 'react';

import { useAuth0 } from 'auth/react-auth0-spa';

const LogoutView = () => {
  const { logout, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      logout({
        returnTo: window.location.origin,
      });
    }
  }, [isAuthenticated]);

  return <div>logging out</div>;
};

LogoutView.propTypes = {};

export default LogoutView;
