import { createSlice } from '@reduxjs/toolkit';

export const appRecuder = createSlice({
  name: 'app',
  initialState: {
    userId: null,
    companyId: null,
    alternativeId: null,
    showHelpPopup: false,
    helpName: null,
  },
  reducers: {
    setCompanyId: (state, action) => ({
      ...state,
      companyId: action.payload.companyId,
      alternativeId: action.payload.alternativeId
        ? action.payload.alternativeId
        : state.alternativeId,
    }),
    setUserId: (state, action) => ({ ...state, userId: action.payload }),
    closeHelpPopup: (state) => ({
      ...state,
      showHelpPopup: false,
      helpName: null,
    }),
    showHelpPopup: (state, action) => ({
      ...state,
      showHelpPopup: true,
      helpName: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setCompanyId, setUserId, closeHelpPopup, showHelpPopup } =
  appRecuder.actions;

export default appRecuder.reducer;
