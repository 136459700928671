import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { SUCCESS } from 'assets/data/severity';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBarMessage } from 'reducers/monCompteReducer';

const SnackBarV2 = () => {
  const dispatch = useDispatch();
  const {
    snackbarMessage: { message, severity },
  } = useSelector((state) => state.monCompte);

  const handleClose = () => {
    dispatch(
      setSnackBarMessage({
        message: null,
        severity: SUCCESS,
      })
    );
  };

  return (
    <Snackbar open={message} onClose={handleClose}>
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default SnackBarV2;
