import React, { useContext, Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import {
  FormControlLabel,
  Radio,
  Box,
  Typography,
  MenuItem,
} from '@material-ui/core';

// TST

import FormControl from '@material-ui/core/FormControl';

// /TST

import { RadioGroup, Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import theme from 'assets/jss/theme';

import * as Yup from 'yup';
import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const ChoixDuGerant = ({
  // partner,
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(3);
  }, []);

  const { partners } = formValues;

  const gerantOupresidentLabel = 'gérant';

  const associeOuMultiAssocie = 'Un associé de la SCI en création';

  // if (
  //   formValues.alone === 'a_plusieurs' &&
  //   formValues.eurl_sasu === 'sasu' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   gerantOupresidentLabel = 'Président';
  //   associeOuMultiAssocie = 'Un actionnaire de la SASU en création';
  // } else if (
  //   formValues.alone === 'a_plusieurs' &&
  //   formValues.eurl_sasu === 'eurl' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   gerantOupresidentLabel = 'gérant';

  //   associeOuMultiAssocie = "Un associé de l'EURL en création";
  // } else if (
  //   formValues.alone === 'a_plusieurs' &&
  //   formValues.eurl_sasu === 'sasu'
  // ) {
  //   gerantOupresidentLabel = 'Président';
  //   associeOuMultiAssocie = 'Un actionnaire de la SASU en création';
  // } else if (
  //   formValues.alone === 'a_plusieurs' &&
  //   formValues.eurl_sasu === 'sarl' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   gerantOupresidentLabel = 'gérant';

  //   associeOuMultiAssocie = 'Un associé de la SARL en création';
  // } else if (
  //   formValues.alone === 'a_plusieurs' &&
  //   formValues.eurl_sasu === 'sarl'
  // ) {
  //   gerantOupresidentLabel = 'gérant';
  //   associeOuMultiAssocie = 'Un associé de la SARL en création';
  // } else if (
  //   formValues.alone === 'a_plusieurs' &&
  //   formValues.eurl_sasu === 'sas' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   gerantOupresidentLabel = 'Président';

  //   associeOuMultiAssocie = 'Un actionnaire de la SAS en création';
  // } else if (
  //   formValues.alone === 'a_plusieurs' &&
  //   formValues.eurl_sasu === 'sas'
  // ) {
  //   gerantOupresidentLabel = 'Président';
  //   associeOuMultiAssocie = 'Un actionnaire de la SAS en création';
  // } else if (
  //   formValues.alone === 'seul' &&
  //   formValues.eurl_sasu === 'sasu' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   gerantOupresidentLabel = 'Président';

  //   associeOuMultiAssocie = `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'actionnaire de la SASU en création`;

  // } else if (
  //   formValues.alone === 'seul' &&
  //   formValues.eurl_sasu === 'eurl' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   gerantOupresidentLabel = 'gérant';

  //   associeOuMultiAssocie =
  //     `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'associé de l'EURL en création`;
  // } else if (
  //   formValues.alone === 'seul' &&
  //   formValues.eurl_sasu === 'eurl' &&
  //   formValues.personne_physique_morale === 'personne_physique'
  // ) {
  //   gerantOupresidentLabel = 'gérant';

  //   associeOuMultiAssocie =
  //     `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name}, associé de l'EURL en création`;
  // } else if (formValues.alone === 'seul' && formValues.eurl_sasu === 'sasu') {
  //   gerantOupresidentLabel = 'Président';

  //   associeOuMultiAssocie =
  //     `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , actionnaire de la SASU en création`;
  // } else if (
  //   formValues.alone === 'seul' &&
  //   formValues.eurl_sasu === 'sarl' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   gerantOupresidentLabel = 'gérant';

  //   associeOuMultiAssocie =
  //     `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'associée de la SARL en création`;
  // } else if (formValues.alone === 'seul' && formValues.eurl_sasu === 'sarl') {
  //   gerantOupresidentLabel = 'gérant';

  //   associeOuMultiAssocie =
  //     `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , associé de la SARL en création`;
  // } else if (
  //   formValues.alone === 'seul' &&
  //   formValues.eurl_sasu === 'sas' &&
  //   formValues.personne_physique_morale === 'personne_morale'
  // ) {
  //   gerantOupresidentLabel = 'Président';

  //   associeOuMultiAssocie =
  //     `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , dirigeant de l'actionnaire de la SAS en création`;
  // } else if (formValues.alone === 'seul' && formValues.eurl_sasu === 'sas') {
  //   gerantOupresidentLabel = 'Président';

  //   associeOuMultiAssocie =
  //     `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} , actionnaire de la SAS`;
  // }

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          director_type: Yup.string().required('un choix est necessaire'),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        // if (values.director_type === 'un_tiers') {
        //   SciAutomate.demandeInfoSurLeGerant();
        //   setAutomateState(SciAutomate.state);
        //   return;
        // }

        // SciAutomate.next();
        // setAutomateState(SciAutomate.state);

        if (values.director_type === 'un_tiers') {
          SciAutomate.nextChoixGerantVersInfoGerant();
          setAutomateState(SciAutomate.state);
          return;
        }

        if (values.director_type === "l_associe_de_l'EURL") {
          SciAutomate.nextChoixGerantVersInfoCapitalSociete();
          setAutomateState(SciAutomate.state);
        }
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                Le choix du {gerantOupresidentLabel}
              </Typography>

              <Box marginTop={theme.spacing(0.25)}>
                <Field component={RadioGroup} name="director_type">
                  <FormControlLabel
                    value="l_associe_de_l'EURL"
                    control={<Radio />}
                    // label={` ${partner.partner_title}   ${partner.partner_firstname} ${partner.partner_name}${associeOuactionnairePhraseLabel}  `}
                    label={associeOuMultiAssocie}
                  />

                  {/* TST */}

                  {formValues.alone === 'a_plusieurs' &&
                    values.director_type === "l_associe_de_l'EURL" && (
                      <Fragment>
                        <Box className={classes.root}>
                          <FormControl>
                            <Field
                              component={Select}
                              name="associe_gerant_multiassocie"
                              inputProps={{
                                id: 'associe_gerant_multiassocie',
                              }}
                              multiple={true}
                              // value={[]}
                            >
                              {partners.map((associe) => (
                                // {associe.raison_sociale_actionnaire_personne_morale_sasu && (

                                // )}

                                <MenuItem key={associe} value={associe}>
                                  {
                                    associe.raison_sociale_actionnaire_personne_morale_sasu
                                  }{' '}
                                  {associe.forme_juridique_personne_morale}{' '}
                                  {associe.partner_title}{' '}
                                  {associe.partner_firstname}{' '}
                                  {associe.partner_name}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Box>
                      </Fragment>
                    )}

                  {/* /TST */}

                  <FormControlLabel
                    value="un_tiers"
                    control={<Radio />}
                    label="Une personne physique tierce"
                  />
                </Field>
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixDuGerant.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixDuGerant;
