const partner = {
  personne_physique_morale: 'personne_physique',
  partner_title: 'M.',
  partner_name: '',
  partner_firstname: '',
  partner_father_name: '',
  partner_father_firstname: '',
  partner_mother_name: '',
  partner_mother_firstname: '',
  partner_date_of_birth: null,
  partner_place_of_birth: '',
  partner_place_of_living: '',
  partner_city: '',
  partner_zipcode: '',
  partner_country: {
    id: '250',
    name_fr: 'France',
    UE: 'True',
    alpha2: 'FR',
    alpha3: 'FRA',
  },
  partner_country_of_birth: '',
  partner_nationality: '',
  partner_family_situation: 'marié(e)',

  partner_wedding_prenup: 'oui',
  partner_wedding_prenup_type: 'separation_de_biens',
  partner_wedding_prenup_date: null,
  partner_wedding_prenup_place: '',
  partner_wedding_prenup_officer_office_name: '',
  partner_wedding_prenup_officer_office_place: '',

  partner_spouse_title: 'M.',
  partner_spouse_name: '',
  partner_spouse_firstname: '',
  partner_wedding_date: null,
  partner_wedding_place: '',
  partner_spouse_date_naissance: null,
  partner_spouse_lieu_naissance: '',
  partner_spouse_departement_naissance: '',
  pacs_spouse_date_naissance: null,
  pacs_spouse_title: 'M.',
  pacs_date: null,
  pacs_place: '',
  pacs_type: 'de_la_separation_des_patrimoines',
  pacs_spouse_name: '',
  pacs_spouse_firstname: '',
  pacs_spouse_departement_naissance: '',
  pacs_spouse_lieu_naissance: '',

  partner_apport_numeraire: '',
  partner_cash_funds_numbers: 0,
  partner_cash_funds_letters: '',

  partner_apport_nature: '',
  partner_supplies_funds_numbers: 0,
  partner_supplies_funds_letters: '',

  partner_apport_fonds_de_commerce: '',
  partner_valeur_apport_fonds_de_commerce: 0,
  partner_valeur_apport_fonds_de_commerce_lettres: '',

  partner_apport_titres_societe: '',
  partner_date_apport_titres_societe: null,
  partner_valeur_apport_titres_societe: 0,
  partner_valeur_apport_titres_societe_lettres: '',
  partner_raison_sociale_societe_apport_titres_societe: '',
  partner_nombre_titres_apportes_apport_titres_societe: '',
  partner_nombre_titres_apportes_apport_titres_societe_lettres: '',
  partner_nombre_titres_total_apport_titres_societe: '',
  partner_nombre_titres_total_apport_titres_societe_lettres: '',

  partner_apport_nature_divers: '',
  partner_valeur_apport_nature_divers: 0,
  partner_valeur_apport_nature_divers_lettres: '',
  partner_date_apport_nature_divers: null,
  partner_description_biens_apport_nature_divers: '',
  partner_nombre_parts_representatives: '',
  partner_numero_part_representative_debut: '',
  partner_numero_part_representative_fin: '',

  partner_apport_industrie: '',
  partner_expertise_funds_numbers: '',
  partner_expertise_funds_numbers_lettres: '',
  partner_nombre_parts_non_representatives: '',
  partner_numero_part_non_representative_debut: '',
  partner_numero_part_non_representative_fin: '',

  partner_liberation_partielle: 'non',
  partner_montant_liberation_partielle: '',
  partner_email: '',
  director_email: '',
  dg_email: '',
};

export default partner;
