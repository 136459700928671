import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3d319d',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: '#02cbcd',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    danger: {
      // light: will be calculated from palette.primary.main,
      main: '#E94134',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    background: {
      default: '#ffffff',
    },
    // error: will use the default color
  },
  typography: {
    useNextVariants: true,
    fontSize: 12,
    // display2: {
    //   color: "#fff",
    // },
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#34223B',
      },
    },
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    MuiCardActions: {
      root: {
        display: 'block',
        textAlign: 'right',
      },
    },
    MuiRadio: {
      root: {
        padding: '8px !important',
      },
    },
  },
});

export default theme;
