import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

import * as Yup from 'yup';

import { RadioGroup } from 'formik-material-ui';
import Paper from 'components/surface/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {},
  radio: {},
});

const SignupSchema = Yup.object().shape({
  cash_funds_onetime_payment: Yup.string().required('un choix est necessaire'),
});

const ChoixLiberationIntegraleOuPartielle = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  automateState,
  setAutomateState,
  isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  useEffect(() => {
    setActiveStep(2);
  }, []);

  return (
    <Formik
      initialValues={formValues}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);

        if (isRecap === true && values.cash_funds_onetime_payment === 'oui') {
          SciAutomate.goRecap();
          setAutomateState(SciAutomate.state);
          return;
        }

        if (
          automateState === 'choix_liberation_integrale_ou_partielle' &&
          values.cash_funds_onetime_payment === 'non'
        ) {
          SciAutomate.demandeInfoSurLeMontantDesApports();
          setAutomateState(SciAutomate.state);
          return;
        }

        // setActiveStep(activeStep + 1);
        SciAutomate.next();
        setAutomateState(SciAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Box>
                <Typography variant="h6">
                  Libération intégrale ou partielle{' '}
                </Typography>
              </Box>

              <Box>
                <Typography variant="h6">
                  Voulez-vous libérer la totalité de vos apports en numéraire ?
                </Typography>
              </Box>

              <Box>
                <Field component={RadioGroup} name="cash_funds_onetime_payment">
                  <Box>
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixLiberationIntegraleOuPartielle.propTypes = {
  nextForm: PropTypes.func,
};

export default ChoixLiberationIntegraleOuPartielle;
