import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    boxShadow: '20px',
    backgroundColor: 'white',
    width: '350px',
    position: 'relative',

    '&:hover': {
      transform: 'scale(1.1)',
      transition: 'transform .2s',
      cursor: 'pointer',
    },
  },
  actuelKit: {
    position: 'absolute',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    top: -15,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    right: -5,
    textTransform: 'uppercase',
    backgroundColor: '#3D319D',
    transform: 'rotate(25deg)',
    fontSize: '10px',
  },
  title: {
    padding: 10,
    color: 'white',
    backgroundColor: '#02CBCD',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: '12px',
    minHeight: '12px',
  },
  descriptions: {
    listStyle: 'none',
    marginBottom: '100px',
    margin: 0,
    padding: 10,
  },
  description: {
    paddingTop: 8,
  },
  price: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    color: '#02CBCD',
    fontWeight: 'bold',
    fontSize: '20px',
    alignSelf: 'end',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    padding: 5,
    marginBottom: 10,
  },
  footer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
});

function Kit({
  kit: { title, descriptions, price, subtitle, kitId },
  selectedKit,
  setSelectedKit,
  formulaireComplet,
}) {
  const classes = useStyles();
  // const formulaireComplet = useContext(FormContext);
  const { kit } = formulaireComplet;

  const changeKit = (e) => {
    const { id } = e.currentTarget;
    setSelectedKit(id);
  };

  return (
    <div className={classes.root}>
      {kit === kitId && (
        <span className={classes.actuelKit}>kit &nbsp;&nbsp;actuel</span>
      )}
      {selectedKit !== kit && selectedKit === kitId && (
        <span className={classes.actuelKit}>kit choisi</span>
      )}
      <div className={classes.title}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="p"
          component="p"
          className={classes.subtitle}
        >
          {subtitle}
        </Typography>
      </div>
      <ul className={classes.descriptions}>
        {descriptions.map((description, idx) => (
          <li key={idx} className={classes.description}>
            <span
              style={
                description.includes('dépôt des fonds') ? { color: 'red' } : {}
              }
              dangerouslySetInnerHTML={{ __html: description }}
            ></span>
          </li>
        ))}
      </ul>
      <div className={classes.footer}>
        <div className={classes.price}>{price}€ TTC</div>
        <div className={classes.actions}>
          {kit !== kitId && (
            <Button
              variant="contained"
              color="secondary"
              style={{ color: 'white' }}
              id={kitId}
              onClick={changeKit}
            >
              Choisir ce kit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Kit;
