import {
  setCompanyData,
  singUpCompany,
  singUpCompanySuccess,
  singUpCompanyFailed,
  createFormProcessing,
  createFormSuccess,
  createFormFailed,
  generateDocsProcessing,
  generateDocsSuccess,
  generateDocsFailed,
  setSnackBarMessage,
  createStatutsProjectProcessing,
  createStatutsProjectSuccess,
  createStatutsProjectFailed,
} from 'reducers/monCompteReducer';
import { EDIT_COMPANY } from 'constants/actions';
import service from 'services/createCompanyService';
import NotificationService from 'services/notificationService';
import { signUp } from 'helpers/signUpHelper';
import { ERROR, SUCCESS } from 'assets/data/severity';
import { setCompanyId } from 'reducers/appReducer';

const createProjectStatus = (values) => async (dispatch) => {
  try {
    const res = await service.createStatus(values);
    const { documents } = res.data;
    dispatch({
      type: EDIT_COMPANY,
      payload: { ...values, documents },
    });
    return true;
  } catch (err) {
    return false;
  }
};

const saveCompanyData = (values, companyId) => async (dispatch) => {
  const { data } = await service.updateCompanyData(
    values,
    companyId,
    values.creaformOID || values._id
  );
  dispatch(setCompanyData(data));
};

const saveCompanyDataAndPassToNextState =
  (values, companyId, isCreateNoti = false) =>
  async (dispatch) => {
    try {
      const { data } = await service.updateCompanyData(
        values,
        companyId,
        values.creaformOID || values._id
      );
      if (isCreateNoti) {
        const { companyId: clientCompanyId, company_name } = data;
        const notification = {
          message: `Company <b>${company_name}</b> provides documents`,
          url: `/creationcompany/${clientCompanyId}`,
        };

        await NotificationService.createNotification({
          notification,
        });
      }

      const { stateOfClientFolder } = data;

      dispatch(setCompanyData(data));
      dispatch(
        setSnackBarMessage({
          message: `${
            stateOfClientFolder.stepper === 2
              ? 'Validation effectuée, nos équipes vont traiter votre dossier, vous recevrez un mail une fois votre société enregistrée auprès du greffe.'
              : "Validation effectuée, vous pouvez passer à l'étape suivante"
          }`,
          severity: SUCCESS,
        })
      );
    } catch (e) {
      dispatch(
        setSnackBarMessage({
          message: `Une erreur s'est produite, si le problème persiste merci de contacter le support`,
          severity: ERROR,
        })
      );
    }
  };

const createForm = (company, companyId, alternativeId) => async (dispatch) => {
  dispatch(createFormProcessing());
  try {
    const res = await service.createForm(company, companyId, alternativeId);
    const { _id: creaformOID, ...rest } = res.data.result;
    dispatch(createFormSuccess({ company: rest, creaformOID }));
    return creaformOID;
  } catch (e) {
    dispatch(
      createFormFailed('Erreur lors de la sauvegarde de la compagnie', e)
    );
    return null;
  }
};

const createStatutsProject = (data) => async (dispatch) => {
  dispatch(createStatutsProjectProcessing());
  try {
    const res = await service.createStatus(data);
    dispatch(createStatutsProjectSuccess());
    return res;
  } catch (e) {
    dispatch(
      createStatutsProjectFailed(
        'Erreur lors de la sauvegarde de la compagnie',
        e
      )
    );
    return null;
  }
};

const singUp = (company) => async (dispatch) => {
  dispatch(singUpCompany());
  try {
    const signUpRes = await signUp(company);
    const { _id: companyId, alternativeId } = signUpRes;
    dispatch(singUpCompanySuccess({ companyId, alternativeId }));
    dispatch(setCompanyId({ companyId, alternativeId }));
    return { companyId, alternativeId };
  } catch (e) {
    console.error('Error lors du singUp : ', e);
    dispatch(singUpCompanyFailed('Erreur lors du signup de la company'));
    return null;
  }
};

const generateDocs =
  (company, alternativeId, companyId) => async (dispatch) => {
    dispatch(generateDocsProcessing());
    try {
      const res = await service.genarateDocs(company, alternativeId, companyId);
      dispatch(generateDocsSuccess({ documents: res.data }));
      return res.data;
    } catch (e) {
      dispatch(
        generateDocsFailed('Erreur lors de la génération des documents')
      );
      return null;
    }
  };

const fetchCreationCompany = (companyId) => async (dispatch) => {
  try {
    const res = await service.fetchCreationCompany(companyId);
    dispatch(setCompanyData({ ...res.data }));
  } catch (e) {
    dispatch(
      generateDocsFailed(
        'Un problème est survenu, si le problème persiste merci de contacter le support.'
      )
    );
  }
};

const sendEmailPaiementCancled = (company) => {
  const { companyId, director_email, associe_gerant_multiassocie } = company;
  const email =
    director_email ||
    associe_gerant_multiassocie.partner_email ||
    associe_gerant_multiassocie[0].partner_email;
  const message = `
  <div style="padding-top:5%; padding-bottom:5%;display:flex;justify-content: center; color: #8D8D8D;">
    <div>
      <p>Les équipes Be Business Focus vous remercient de votre confiance</p>
      <h1 style="margin-top: 20px;text-align: center;font-weight: bold;margin-bottom: 40px; color: #C54343;">
        Paiement refusé !
      </h1>
    </div>
  </div>`;
  const emailInfo = {
    email,
    subject: 'Kit juridique paiement refusé.',
    message,
  };
  service.sendEmail(emailInfo, companyId);
};

export {
  saveCompanyData,
  singUp,
  createForm,
  generateDocs,
  createProjectStatus,
  fetchCreationCompany,
  saveCompanyDataAndPassToNextState,
  sendEmailPaiementCancled,
  createStatutsProject,
};
