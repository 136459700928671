import { genders } from 'constants/usersContants';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import KeyboardDatePicker from 'components/KeyboardDatePicker';
import React from 'react';
import getUserInfoPositionOptions from 'helpers/userInfo';
import SmallRadio from '../commons/smallRadio';
import Adresse from '../commons/adress';
import QuestionMark from './components/QuestionMark';

function InfoSpouce({ classes }) {
  const { values } = useFormikContext();
  const { partner_spouse_title, spouse_position, eurl_sasu } = values;
  return (
    <>
      <h2 className={classes.subTitle}>Informations sur votre conjoint.</h2>
      <SmallRadio
        classes={classes}
        options={genders}
        value={partner_spouse_title}
        name="partner_spouse_title"
        style={{ marginBottom: '2%' }}
      />
      <Field
        component={TextField}
        type="string"
        label="Nom du conjoint *"
        name="partner_spouse_name"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Prénom du conjoint *"
        name="partner_spouse_firstname"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
        inputProps={{ style: { textTransform: 'capitalize' } }}
      />
      <Field
        component={TextField}
        type="string"
        label="Adresse courriel *"
        name="partner_spouse_email"
        className={classes.nameTextField}
        variant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="N° sécurité sociale *"
        name="spouse_secu_number"
        className={classes.nameTextField}
        autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de naissance du conjoint *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="partner_spouse_date_naissance"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Lieu de naissance du conjoint *"
        name="partner_spouse_lieu_naissance"
        className={classes.nameTextField}
        // autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date de l'union *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="partner_wedding_date"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <Field
        component={TextField}
        type="string"
        label="Lieu de l'union *"
        name="partner_wedding_place"
        className={classes.nameTextField}
        // autoComplete="off"
        variant="outlined"
        size="small"
      />
      <Adresse
        classes={classes}
        title="Adresse *"
        address="partner_spouse_adress"
        city="partner_spouse_city"
        zipCode="partner_spouse_zipcode"
        department={null}
        country={null}
        cityLabel="Ville *"
        nationality={null}
        disabledZipCode={true}
        disabledCity={true}
        additionalAddress="partner_spouse_additional_adress"
      />
      <h2 className={classes.subTitle}>
        {`Statut au sein de l'entreprise *`}
        <QuestionMark classes={classes} />
      </h2>
      <Field
        component={KeyboardDatePicker}
        clearable
        label="Date d’effet *"
        placeholder="01/01/1990"
        format="dd/MM/yyyy"
        maxDate={new Date()}
        name="spouse_effective_date"
        className={classes.nameTextField}
        inputVariant="outlined"
        size="small"
      />
      <SmallRadio
        classes={classes}
        options={getUserInfoPositionOptions(eurl_sasu)}
        value={spouse_position}
        name="spouse_position"
        style={{ marginBottom: '2%' }}
      />
    </>
  );
}

export default InfoSpouce;
