import React from 'react';
import store from 'store/monCompteStore';
import KitPreview from 'containers/kitPreview';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

const KitPreviewView = () => {
  const persistor = persistStore(store);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <KitPreview />
    </PersistGate>
  );
};

export default KitPreviewView;
