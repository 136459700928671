import { goBack } from 'helpers/automateHelper';
import { v4 as uuidv4 } from 'uuid';

import {
  EDIT_COMPANY,
  ADD_PARTNER,
  ADD_PRESENT,
  ADD_REPRESENTE,
  // REMOVE_PARTNER,
  EDIT_PARTNER,
  DELETE_PM_VALUES,
  DELETE_PP_VALUES,
  EDIT_PRESENT,
  EDIT_REPRESENTE,
  RESET_FORM,
  PARTNER_INDEX,
  PRESENT_INDEX,
  REPRESENTE_INDEX,
  SET_SIGNUPCOMPANY,
  CREATE_PROJECT_STATUS_FAILED,
  EDIT_COMPANY_PROCESSING,
  GO_BACK,
  ADD_IN_HISTORY,
  SET_CURRENT_FORM,
  SET_PARTNER_ID,
} from '../constants/actions';

const reducer = (state, action) => {
  const { type = '', payload = {} } = action;

  switch (type) {
    case EDIT_COMPANY_PROCESSING:
      return { ...state, error: null, loading: true };
    case EDIT_COMPANY:
      return { ...state, ...payload, error: null, loading: false };

    case RESET_FORM: {
      return { ...state, ...payload };
    }
    case PARTNER_INDEX:
      return { ...state, index: payload };

    case PRESENT_INDEX:
      return { ...state, index: payload };
    case REPRESENTE_INDEX:
      return { ...state, index: payload };

    case ADD_PARTNER: {
      const { partners } = state;
      partners.push({ id: uuidv4(), ...payload });
      return {
        ...state,
        partners,
      };
    }
    case ADD_PRESENT: {
      const { presents } = state;
      presents.push({ id: uuidv4(), ...payload });
      return {
        ...state,
        presents,
      };
    }
    case ADD_REPRESENTE: {
      const { representes } = state;
      representes.push({ id: uuidv4(), ...payload });
      return {
        ...state,
        representes,
      };
    }
    case EDIT_PARTNER: {
      const { partners } = state;
      return {
        ...state,
        partners: partners.map((partner) =>
          partner.id === payload.id ? { ...partner, ...payload } : partner
        ),
      };
    }

    case DELETE_PM_VALUES: {
      const { partners } = state;

      return {
        ...state,
        partners: partners.map((partner) => {
          if (partner.id === payload.id) {
            // eslint-disable-next-line
            delete partner.raison_sociale_actionnaire_personne_morale_sasu;
            // eslint-disable-next-line
            delete partner.forme_juridique_personne_morale;
            // eslint-disable-next-line
            delete partner.capital_personne_morale;
            // eslint-disable-next-line
            delete partner.adresse_actionnaire_personne_morale_sasu;
            // eslint-disable-next-line
            partner.code_postal_actionnaire_personne_morale_sasu = '';
            // eslint-disable-next-line
            partner.ville_actionnaire_personne_morale_sasu = '';
            // eslint-disable-next-line
            partner.rcs_actionnaire_personne_morale_sasu = '';
            // eslint-disable-next-line
            partner.ville_rcs_actionnaire_personne_morale_sasu = '';

            // eslint-disable-next-line
            delete partner.titre_dirigeant_de_personne_morale_sasu;
            // eslint-disable-next-line
            delete partner.nom_dirigeant_de_personne_morale_sasu;
            // eslint-disable-next-line
            delete partner.prenom_dirigeant_de_personne_morale_sasu;
            // eslint-disable-next-line
            delete partner.adresse_dirigeant_de_personne_morale_sasu;
            // eslint-disable-next-line
            delete partner.code_postal_dirigeant_de_personne_morale_sasu;
            // eslint-disable-next-line
            delete partner.ville_dirigeant_de_personne_morale_sasu;
          }
          return partner;
        }),
      };
    }
    case DELETE_PP_VALUES: {
      const { partners } = state;
      return {
        ...state,
        partners: partners.map((partner) => {
          if (partner.id === payload.id) {
            // eslint-disable-next-line
            partner.partner_family_situation = 'marié(e)';
            // eslint-disable-next-line
            partner.partner_wedding_prenup = 'oui';
            // eslint-disable-next-line
            partner.partner_wedding_prenup_type = 'separation_de_biens';
            // eslint-disable-next-line
            partner.partner_wedding_prenup_date = null;
            // eslint-disable-next-line
            partner.partner_wedding_prenup_place = '';
            // eslint-disable-next-line
            partner.partner_wedding_prenup_officer_office_name = '';
            // eslint-disable-next-line
            partner.partner_wedding_prenup_officer_office_place = '';
            // eslint-disable-next-line
            partner.partner_spouse_title = 'M.';
            // eslint-disable-next-line
            partner.partner_spouse_name = '';
            // eslint-disable-next-line
            partner.partner_spouse_firstname = '';
            // eslint-disable-next-line
            partner.partner_wedding_date = null;
            // eslint-disable-next-line
            partner.partner_wedding_place = '';
            // eslint-disable-next-line
            partner.partner_spouse_date_naissance = null;
            // eslint-disable-next-line
            partner.partner_spouse_lieu_naissance = '';
            // eslint-disable-next-line
            partner.partner_spouse_departement_naissance = '';
            // eslint-disable-next-line
            partner.pacs_spouse_date_naissance = null;
            // eslint-disable-next-line
            partner.pacs_spouse_title = 'M.';
            // eslint-disable-next-line
            partner.pacs_date = null;
            // eslint-disable-next-line
            partner.pacs_place = '';
            // eslint-disable-next-line
            partner.pacs_type = 'de_la_separation_des_patrimoines';
          }
          return partner;
        }),
      };
    }

    case EDIT_PRESENT: {
      const { presents } = state;
      return {
        ...state,
        presents: presents.map((present) =>
          present.id === payload.id ? { ...present, ...payload } : present
        ),
      };
    }

    case EDIT_REPRESENTE: {
      const { representes } = state;
      return {
        ...state,
        representes: representes.map((represente) =>
          represente.id === payload.id
            ? { ...represente, ...payload }
            : represente
        ),
      };
    }

    case SET_SIGNUPCOMPANY: {
      // console.log('REDUCERr', payload);
      return { ...state, companyAlreadyCreated: payload };
    }

    case CREATE_PROJECT_STATUS_FAILED: {
      return {
        ...state,
        error: 'Erreur lors de la génération du projet de statut.',
        loading: false,
      };
    }

    case GO_BACK: {
      const { currentForm, formStack } = goBack(state);
      return { ...state, currentForm, formStack };
    }

    case ADD_IN_HISTORY:
      return { ...state, formStack: [...state.formStack, action.payload] };

    case SET_CURRENT_FORM:
      return {
        ...state,
        currentForm: action.payload,
      };
    case SET_PARTNER_ID:
      return {
        ...state,
        partnerId: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
