import React from 'react';
import CreateSCIContainer from 'containers/V2/CreateSCIContainer';
import { FormProvider } from '../contexts/sci-partners.context';

const CreationSciV2 = () => {
  return (
    <>
      <FormProvider>
        <CreateSCIContainer />
      </FormProvider>
    </>
  );
};

export default CreationSciV2;
