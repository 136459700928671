import { NO } from 'constants/usersContants';

const initialValues = (values) => {
  return {
    preexisting_contract: values.preexisting_contract || NO,
    number_of_preexisting_contract: values.number_of_preexisting_contract || 0,
    contract1_date: values.contract1_date || null,
    contract1_type: values.contract1_type || '',
    contract1_amount: values.contract1_amount || '',
    contract1_person_who_paid: values.contract1_person_who_paid || '',
    contract2_date: values.contract2_date || null,
    contract2_type: values.contract2_type || '',
    contract2_amount: values.contract2_amount || '',
    contract2_person_who_paid: values.contract2_person_who_paid || '',
  };
};

// eslint-disable-next-line
export { initialValues };
