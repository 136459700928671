import React, { Fragment, useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Box, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

// import KeyboardDatePicker from 'components/KeyboardDatePicker';

// import * as Yup from 'yup';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  text1: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  text2: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  text3: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '20ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '20ch',
  },
  radio: {},
}));

const InfoNotaireBbf = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  setActiveStep(2);

  // let partsOuActionsLabel = 'de parts';
  // let unePartOuUneActionLabel = "d'une part";

  let capitalChiffres = 0;
  // let partOuActionLabel = "d'une part";

  // calcul du capital
  const { partners } = formValues;
  const apportsNumeraire = 0;
  const apportsNature = 0;
  let mention =
    'Merci de bien faire figurer le nom de la société pour laquelle vous réalisez les apports par virement';
  let nomsPartners = '';

  if (formValues.alone === 'seul' && formValues.director_type === 'un_tiers') {
    nomsPartners = `SOPACODI – Apport de ${formValues.director_title}
  ${formValues.director_firstname} ${formValues.director_name} -
  Société ${formValues.company_name}`;
  }
  if (
    formValues.alone === 'seul' &&
    formValues.director_type === "l_associe_de_l'EURL"
  ) {
    nomsPartners = `SOPACODI – Apport de ${formValues.partners[0].partner_title}
    ${formValues.partners[0].partner_firstname} ${formValues.partners[0].partner_name} -
    Société ${formValues.company_name}`;
  }

  if (formValues.alone === 'a_plusieurs') {
    mention =
      'Comme vous êtes plusieurs associés – merci de bien faire figurer votre nom ainsi la raison sociale de la société pour laquelle vous réalisez les apports sur le virement ';

    partners.forEach((partner) => {
      nomsPartners = `${nomsPartners} 
        SOPACODI – Apport de ${partner.partner_title}
        ${partner.partner_firstname} ${partner.partner_name} -
        Société ${formValues.company_name}`;

      // `${partner.partner_title} ${partner.partner_firstname} ${partner.partner_name} `;
    });

    capitalChiffres = apportsNumeraire + apportsNature;
  }

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    // partsOuActionsLabel = "d'actions";
    // unePartOuUneActionLabel = "d'une action";
  }

  if (formValues.eurl_sasu === 'sasu' || formValues.eurl_sasu === 'sas') {
    // partOuActionLabel = "d'une action";
  }

  // pour avoir le capital calculé déja avant d'arriver au server.js
  if (formValues.alone === 'seul') {
    capitalChiffres =
      parseInt(formValues.cash_funds_numbers, 10) +
      parseInt(formValues.supplies_funds_numbers, 10);
  }

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }

        if (formValues.eurl_sasu === 'eurl') {
          Automate.nextInfoNotaireVersChoixLiberationIntegraleOuPartielle();
          setAutomateState(Automate.state);
          return;
        }
        if (formValues.eurl_sasu === 'sasu') {
          Automate.nextInfoNotaireVersChoixLiberationIntegraleOuPartielle();
          setAutomateState(Automate.state);
          return;
        }
        if (formValues.eurl_sasu === 'sarl') {
          Automate.nextInfoNotaireVersChoixNombreActesPrealables();
          setAutomateState(Automate.state);
          return;
        }
        if (formValues.eurl_sasu === 'sas') {
          Automate.nextInfoNotaireVersChoixNombreActesPrealables();
          setAutomateState(Automate.state);
          return;
        }

        Automate.next();
        setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              {formValues.kit === 'kit4soc' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Typography variant="h5">
                      {' '}
                      Capital et dépôt des fonds{' '}
                    </Typography>{' '}
                  </Box>
                  <Box className={classes.root}>
                    <Typography variant="subtitle1">
                      {' '}
                      Rappel, le capital de la société {
                        formValues.company_name
                      }{' '}
                      est de {capitalChiffres} euros
                    </Typography>{' '}
                  </Box>
                  <Box className={classes.root}>
                    <Typography variant="h6">
                      {' '}
                      Votre dépôt de capital va être effectué auprès de notre
                      notaire partenaire
                    </Typography>{' '}
                    <Box className={classes.text2}>
                      <Typography variant="h6">
                        {' '}
                        {/* Merci de bien faire figurer le nom de la société pour laquelle
                  vous réalisez les apports par virement */}
                        {mention}
                      </Typography>{' '}
                    </Box>
                    <Box className={classes.text1}>
                      <Typography variant="h6">
                        {' '}
                        Le RIB du Notaire : IBAN & BIC
                      </Typography>{' '}
                    </Box>
                    <Box className={classes.text2}>
                      <Typography variant="h6">
                        {' '}
                        Mention à faire figurer sur votre virement :
                      </Typography>{' '}
                    </Box>
                    <Box className={classes.text2}>
                      <Typography variant="h6">
                        {' '}
                        {/* SOPACODI – Apport de {formValues.director_title}{' '}
                    {formValues.director_firstname} {formValues.director_name} -
                    Société {formValues.company_name} */}
                        {nomsPartners}
                      </Typography>{' '}
                    </Box>
                  </Box>
                </Fragment>
              )}

              {formValues.kit !== 'kit4soc' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Typography variant="h5">
                      {' '}
                      Capital et dépôt des fonds{' '}
                    </Typography>{' '}
                  </Box>
                  <Box className={classes.text2}>
                    <Typography variant="h6">
                      Vous ne savez pas encore ou vous rendre pour déposer le
                      capital de votre société.
                    </Typography>{' '}
                  </Box>
                  <Box className={classes.root}>
                    <Box className={classes.text2}>
                      <Typography variant="h6">
                        Il vous faudra choisir entre un notaire ou une banque.{' '}
                      </Typography>{' '}
                    </Box>
                    <Box className={classes.text3}>
                      <Typography variant="h6">
                        Une fois la décision prise, il vous appartiendra
                        d’imprimer le projet de statut généré par l’outil BBF et{' '}
                        <br></br>
                        de vous rendre chez le banquier ou le notaire de votre
                        choix pour déposer les fonds et obtenir une <br></br>{' '}
                        attestation de dépôt des fonds que vous devrez déposer
                        sur votre espace BBF.{' '}
                      </Typography>{' '}
                    </Box>
                    <Box className={classes.text3}>
                      <Typography variant="h6">
                        Un conseiller BBF se chargera ensuite de compléter les
                        informations une fois l’attestation en notre possession.
                      </Typography>{' '}
                    </Box>
                  </Box>
                </Fragment>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};
InfoNotaireBbf.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoNotaireBbf;
