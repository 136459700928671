import React, { createContext } from 'react';

import useLocalStorageReducer from '../hooks/useLocalStorageReducer';
import formReducer from '../reducers/form.reducer';
import defaultForm from '../models/autoentrepreneur_initial.context';

export const DispatchContext = createContext();

export const FormContext = createContext();

// export { PartnersConsumer };

export const FormProvider = (props) => {
  const { kit } = props;
  const [form, dispatch] = useLocalStorageReducer(
    'form',
    formReducer,
    defaultForm(kit)
  );
  //   const partners = defaultPartners;
  //   const dispatch = (val) => // console.log('dispatch', val);

  return (
    <FormContext.Provider value={form}>
      <DispatchContext.Provider value={dispatch}>
        {/* eslint-disable-next-line react/prop-types */}
        {props.children}
      </DispatchContext.Provider>
    </FormContext.Provider>
  );
};
