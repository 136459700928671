import axios from 'axios';

import { getAuthCachedToken } from 'utils/storage';

let signal = axios.CancelToken.source();

/**
 * returns if error is being caused by axios cancel
 * @function
 * @returns {Boolean} - true if the error caused by canceling the request
 */
const areRequestsCanceled = (err) => err && axios.isCancel(err);

/**
 * cancels every request
 * @function
 */
const cancelRequests = () => {
  signal.cancel({ isCanceled: true, message: 'Requests canceled' });
  signal = axios.CancelToken.source();
};

/**
 *
 * parse error response
 */
function parseError(error) {
  if (error.response) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
}

/**
 * axios instances
 */
const simpleInstance = axios.create({});
const authInstance = axios.create({});

// response parse
simpleInstance.interceptors.response.use((response) => response, parseError);
// request parse
simpleInstance.interceptors.request.use((request) => {
  request.cancelToken = signal.token;
  return request;
}, parseError);

// response parse
authInstance.interceptors.response.use((response) => response, parseError);
// request parse
authInstance.interceptors.request.use((request) => {
  const tokenBearer = getAuthCachedToken();
  request.cancelToken = signal.token;
  request.headers.Authorization = `Bearer ${tokenBearer}`;
  return request;
}, parseError);

export { simpleInstance, authInstance, areRequestsCanceled, cancelRequests };
