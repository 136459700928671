import React, { useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons';

function QuestionMark({ classes, content }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openHelpIcon = Boolean(anchorEl);

  return (
    <>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={handleClick}
      >
        <HelpOutlineOutlined />
      </IconButton>
      <Popover
        open={openHelpIcon}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div
          className={classes.helpPopover}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Popover>
    </>
  );
}

export default QuestionMark;
