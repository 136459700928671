import { TYPE_OF_COMPANY } from 'constants/pages';
import { NO } from 'constants/usersContants';

const defaultForm = () => {
  return {
    formStack: [],
    currentForm: TYPE_OF_COMPANY,
    dgPartnerIDs: [],
    partnerId: null,
    isReview: false,
    nom_propre: 'societe',
    alone: 'a_plusieurs',
    personne_physique_morale: 'personne_physique',
    choix_is: null,
    nombre_de_personnes_physiques: '',
    nombre_de_personnes_morales: '',
    eurl_sasu: '',
    sci: '',
    companyType: 'soc',
    company_name: '',
    business_name: '',
    code_ape: '',
    address: '',
    zipcode: '',
    city: '',
    siege_social_chez: 'chez_gerant',
    proprietaire: 'Proprietaire',
    maison_individuelle: 'oui',
    prenom_syndic: '',
    nom_syndic: '',
    adresse_syndic: '',
    prenom_bailleur: '',
    nom_bailleur: '',
    adresse_bailleur: '',
    date_bail: null,
    date_debut_activite: '',
    closing_date_with_year: '',
    partners: [],
    capital_in_numbers: '',
    share_value: 1,
    connait_depot_des_fonds: '',
    depot_capital_chez: 'unknown',
    date_depot_des_fonds: null,
    banking_corporation_name: '',
    adresse_agence_bancaire: '',
    ville_agence_bancaire: '',
    code_postal_agence_bancaire: '',
    cash_funds_numbers: '',
    apport_nature: 'non',
    supplies_funds_numbers: 0,
    apport_fonds_de_commerce: 'non',
    date_apport_fonds_de_commerce: null,
    valeur_apport_fonds_de_commerce: 0,
    apport_titres_societe: 'non',
    date_apport_titres_societe: null,
    valeur_apport_titres_societe: 0,
    raison_sociale_societe_apport_titres_societe: '',
    nombre_titres_apportes_apport_titres_societe: '',
    nombre_titres_total_apport_titres_societe: '',
    apport_nature_divers: 'non',
    date_apport_nature_divers: null,
    valeur_apport_nature_divers: 0,
    description_biens_apport_nature_divers: '',
    apport_industrie: 'non',
    expertise_funds_numbers: '',
    cash_funds_onetime_payment: 'oui',
    payments_amount: '',
    director_type: "l_associe_de_l'EURL",
    director_title: 'M.',
    director_name: '',
    director_firstname: '',
    director_father_name: '',
    director_father_firstname: '',
    director_mother_name: '',
    director_mother_firstname: '',
    director_date_of_birth: null,
    director_place_of_birth: '',
    director_place_of_birth_zipCode: '',
    director_state_of_birth: '',
    director_country_of_birth: '',
    director_nationality: '',
    director_place_of_living: '',
    director_city: '',
    director_zipcode: '',
    director_email: '',

    associe_gerant_multiassocie: [],

    presence_dg: '',
    dg: [],
    dg_type: "l_associe_de_l'EURL",
    dg_title: 'M.',
    dg_name: '',
    dg_firstname: '',
    dg_address: '',
    dg_zipcode: '',
    dg_city: '',
    dg_email: '',
    duree_gerance_determinee: 'non',
    duree_de_gerance: '0',
    preexisting_contract: 'non',
    number_of_preexisting_contract: 0,
    contract1_date: null,
    contract1_type: '',
    contract1_amount: '',
    contract1_person_who_paid: '',
    contract2_date: null,
    contract2_type: '',
    contract2_amount: '',
    contract2_person_who_paid: '',
    presence_commissaire_aux_comptes: 'non',
    duree_exercice_commissaire_aux_comptes: 3,
    titre_commissaire_aux_comptes: 'M.',
    nom_commissaire_aux_comptes: '',
    prenom_commissaire_aux_comptes: '',
    nom_cabinet_commissaire_aux_comptes: '',
    adresse_cabinet_commissaire_aux_comptes: '',
    cac_suppleant: 'oui',
    titre_suppleant: 'M.',
    nom_suppleant: '',
    prenom_suppleant: '',
    adresse_cabinet_suppleant: '',
    procuration: 'non',
    nom_cabinet_procuration: '',
    qualite_cabinet_procuration: '',
    adresse_cabinet_procuration: '',
    ville_cabinet_procuration: '',
    code_postal_cabinet_procuration: '',
    procu_bbf_dossier_constitution: 'oui',
    procu_bbf_attestation_beneficiaires: 'oui',
    procu_bbf_annonce_legale: 'oui',
    procu_bbf_attestation_depot_fonds: 'oui',
    procu_bbf_depot_greffe_dossier_constitution: 'oui',
    rcs_ville: '',
    presence_date_debut_activité: 'oui',
    type_depart_activite: 'immediatement',
    kit: '',
    companyAlreadyCreated: null,
    pourcentOfRelease: null,
    liberationPartielle: NO,
    email_bailleur: '',
    bailleur_city: '',
    bailleur_zipcode: '',
    idGerantForDomiciliation: null,
  };
};

export default defaultForm;
