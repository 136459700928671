import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import { Box, MenuItem, Typography } from '@material-ui/core';

import { Select } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
// import KeyboardDatePicker from 'components/KeyboardDatePicker';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';

// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

// import * as Yup from 'yup';

import Paper from 'components/surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const ChoixCommissaireAuxComptes = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  // const [rows] = useState([]);
  const { presents } = formValues;

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        // AssociationAutomate.next();
        // setAutomateState(AssociationAutomate.state);
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">
                Les personnes présentes ou représentées à l’assemblée générale
                constitutive
              </Typography>

              <Box className={classes.root}>
                <FormControl>
                  <Typography variant="subtitle1">Président :</Typography>

                  <Field
                    component={Select}
                    name="president"
                    // value={[]}
                    inputProps={{
                      id: 'president',
                    }}
                  >
                    {presents.map((present) => (
                      <MenuItem key={present.id} value={present}>
                        {present.present_titre} {present.present_prenom}{' '}
                        {present.present_nom}
                      </MenuItem>
                    ))}
                    {/* {partners.map((associe) => (
                      <MenuItem key={associe.id} value={associe}>
                        {
                          associe.raison_sociale_actionnaire_personne_morale_sasu
                        }{' '}
                        {associe.forme_juridique_personne_morale}{' '}
                        {associe.partner_title} {associe.partner_firstname}{' '}
                        {associe.partner_name}
                      </MenuItem>
                    ))} */}
                  </Field>
                </FormControl>
              </Box>

              <Box className={classes.root}>
                <FormControl>
                  <Typography variant="subtitle1">Vice-Président :</Typography>

                  <Field
                    component={Select}
                    name="vice_president"
                    // value={[]}
                    inputProps={{
                      id: 'vice_president',
                    }}
                  >
                    {presents.map((present) => (
                      <MenuItem key={present.id} value={present}>
                        {present.present_titre} {present.present_prenom}{' '}
                        {present.present_nom}
                      </MenuItem>
                    ))}
                    {/* {partners.map((associe) => (
                      <MenuItem key={associe.id} value={associe}>
                        {
                          associe.raison_sociale_actionnaire_personne_morale_sasu
                        }{' '}
                        {associe.forme_juridique_personne_morale}{' '}
                        {associe.partner_title} {associe.partner_firstname}{' '}
                        {associe.partner_name}
                      </MenuItem>
                    ))} */}
                  </Field>
                </FormControl>
              </Box>
              <Box className={classes.root}>
                <FormControl>
                  <Typography variant="subtitle1">Trésorier :</Typography>

                  <Field
                    component={Select}
                    name="tresorier"
                    // value={[]}
                    inputProps={{
                      id: 'tresorier',
                    }}
                  >
                    {presents.map((present) => (
                      <MenuItem key={present.id} value={present}>
                        {present.present_titre} {present.present_prenom}{' '}
                        {present.present_nom}
                      </MenuItem>
                    ))}
                    {/* {partners.map((associe) => (
                      <MenuItem key={associe.id} value={associe}>
                        {
                          associe.raison_sociale_actionnaire_personne_morale_sasu
                        }{' '}
                        {associe.forme_juridique_personne_morale}{' '}
                        {associe.partner_title} {associe.partner_firstname}{' '}
                        {associe.partner_name}
                      </MenuItem>
                    ))} */}
                  </Field>
                </FormControl>
              </Box>
              <Box className={classes.root}>
                <FormControl>
                  <Typography variant="subtitle1">Secrétaire :</Typography>

                  <Field
                    component={Select}
                    name="secretaire"
                    // value={[]}
                    inputProps={{
                      id: 'secretaire',
                    }}
                  >
                    {presents.map((present) => (
                      <MenuItem key={present.id} value={present}>
                        {present.present_titre} {present.present_prenom}{' '}
                        {present.present_nom}
                      </MenuItem>
                    ))}
                    {/* {partners.map((associe) => (
                      <MenuItem key={associe.id} value={associe}>
                        {
                          associe.raison_sociale_actionnaire_personne_morale_sasu
                        }{' '}
                        {associe.forme_juridique_personne_morale}{' '}
                        {associe.partner_title} {associe.partner_firstname}{' '}
                        {associe.partner_name}
                      </MenuItem>
                    ))} */}
                  </Field>
                </FormControl>
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixCommissaireAuxComptes.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixCommissaireAuxComptes;
