import { PARTNERS, DIRECTORS, NAMED, CLIENT } from 'assets/data/kitConstants';
import _ from 'lodash';
import listOfKit from 'assets/data/kitMatrix';
import { lettre_bailleur, lettre_syndic } from 'assets/data/complementaryDocs';

export const setrecipients = (docsWithNames, associes) => {
  const docs = _.cloneDeep(docsWithNames);
  return docs.map((doc) => {
    if (doc) {
      if (doc.signBy === PARTNERS) {
        // eslint-disable-next-line
        doc.recipients = [...associes];
      }
      if (doc.signBy === DIRECTORS) {
        // eslint-disable-next-line
        doc.recipients = associes.filter((associate) => associate.isGerant);
      }
      return doc;
    }
    return {};
  });
};

export const addKbisIfHasPersonneMorale = (docs, data) => {
  data.forEach((associe) => {
    // eslint-disable-next-line
    if (associe.hasOwnProperty('forme_juridique_personne_morale')) {
      docs.push({
        title: `Kbis de ${associe.raison_sociale_actionnaire_personne_morale_sasu}`,
        source: CLIENT,
        signBy: null,
        recipients: [],
      });
    }
  });
  return docs;
};

export const createAssociateDocs = (docs, data) => {
  let docsWithNames = _.cloneDeep(docs);
  docsWithNames = addKbisIfHasPersonneMorale(docsWithNames, data);
  const idCard = docsWithNames.filter((doc) =>
    doc.title.includes("Pièce d'identité de %s")
  )[0];
  const AttestationNonCondamnation = docsWithNames.filter((doc) =>
    doc.title.includes('Attestation de non condamnation de %s')
  )[0];
  data.forEach((associate) => {
    const newIdCard = _.cloneDeep(idCard);
    const newAttestationNonCondamnation = _.cloneDeep(
      AttestationNonCondamnation
    );
    if (!_.isEmpty(newIdCard)) {
      newIdCard.title = newIdCard.title.replace('%s', associate.partner_name);
    }
    if (associate.isGerant && !_.isEmpty(newAttestationNonCondamnation)) {
      if (newAttestationNonCondamnation.signBy === NAMED) {
        newAttestationNonCondamnation.recipients.push(associate);
      }
      newAttestationNonCondamnation.title =
        newAttestationNonCondamnation.title.replace(
          '%s',
          associate.partner_name
        );
      if (newAttestationNonCondamnation) {
        docsWithNames.push(newAttestationNonCondamnation);
      }
    }
    if (newIdCard) {
      docsWithNames.push(newIdCard);
    }
  });
  docsWithNames = docsWithNames.filter((doc) => !doc.title.includes('%s'));
  return docsWithNames;
};

export const addIsGerantBooleanToPartners = (data) => {
  const {
    partners,
    associe_gerant_multiassocie,
    director_type,
    director_email,
    director_name,
    director_firstname,
    director_title,
  } = data;

  let newPartners = _.cloneDeep(partners);
  if (director_type === 'un_tiers') {
    // eslint-disable-next-line
    newPartners = partners.map((partner) => {
      const { partner_email, partner_name, partner_firstname, partner_title } =
        partner;
      const parnerToAdd = {
        partner_email,
        partner_name,
        partner_firstname,
        partner_title,
        isGerant: false,
      };
      return parnerToAdd;
    });
    newPartners.push({
      partner_email: director_email,
      partner_name: director_name,
      partner_firstname: director_firstname,
      partner_title: director_title,
      isGerant: true,
    });
    return newPartners;
  }
  return newPartners.map((partner) => {
    const { partner_email, partner_name, partner_firstname, partner_title } =
      partner;
    const parnerToAdd = {
      partner_email,
      partner_name,
      partner_firstname,
      partner_title,
    };
    const isGerant = associe_gerant_multiassocie.find(
      (associe) => associe.id === partner.id
    );
    if (isGerant) {
      parnerToAdd.isGerant = true;
    } else {
      parnerToAdd.isGerant = false;
    }
    return parnerToAdd;
  });
};

export const checkSiegeSocial = (rawData) => {
  const { siege_social_chez, proprietaire, maison_individuelle } = rawData;
  if (siege_social_chez === 'chez_gerant' && proprietaire === 'Locataire') {
    return lettre_bailleur;
  }
  if (
    siege_social_chez === 'chez_gerant' &&
    proprietaire === 'Proprietaire' &&
    maison_individuelle === 'non'
  ) {
    return lettre_syndic;
  }
  return {};
};

export const getListOfDocs = (rawData) => {
  const { kit } = rawData;

  const partnersAndGerants = addIsGerantBooleanToPartners(rawData);

  const kitSelected = listOfKit.find((k) => k.kit === kit);
  const { docs } = kitSelected;
  let docsWithNames = createAssociateDocs(docs, partnersAndGerants);
  docsWithNames = setrecipients(docsWithNames, partnersAndGerants);
  docsWithNames.push(checkSiegeSocial(rawData));
  return docsWithNames;
};

export const getListOfDocsByKit = (rawData, kit) => {
  const partnersAndGerants = addIsGerantBooleanToPartners(rawData);

  const kitSelected = listOfKit.find((k) => k.name === kit);
  const { docs } = kitSelected;
  let docsWithNames = createAssociateDocs(docs, partnersAndGerants);
  docsWithNames = setrecipients(docsWithNames, partnersAndGerants);
  docsWithNames.push(checkSiegeSocial(rawData));

  return docsWithNames;
};
