import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const PdfPreviewInBrowser = (props) => {
  const { file } = props;

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  return (
    <div className={classes.pdfViewContaier}>
      <iframe
        name="nhutesting"
        className={classes.pdfPreview}
        src={file}
        loading="lazy"
        onLoadCapture={() => setLoading(false)}
      />
      {loading && (
        <Typography
          className={classes.pdfLoading}
          color="primary"
          component={'h6'}
          variant="h6"
        >
          Loading PDF....
        </Typography>
      )}
    </div>
  );
};

PdfPreviewInBrowser.propTypes = {
  file: PropTypes.string,
};

export default PdfPreviewInBrowser;
