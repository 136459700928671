import React, { useState } from 'react';
import {
  Grid,
  Card,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  FormGroup,
  // FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import KitPreview from 'components/monCompte/KitPreview';
import SnackBar from 'components/commons/SnackBar';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setSnackBarMessage } from 'reducers/monCompteReducer';
import { ERROR } from 'assets/data/severity';
import SnackBarV2 from 'components/commons/SnackBarV2';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  cardHeading: {
    fontWeight: '500',
    textAlign: 'center',
  },

  cardSubHeading: {
    color: '#8D8D8D',
    textAlign: 'center',
    marginTop: 20,
  },

  card: {
    padding: 30,
    position: 'relative',
  },

  price: {
    fontWeight: '500',
    textAlign: 'center',
  },

  termPart: {
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },

  cardFooter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  paddingDialog: {
    padding: '10px 50px',
  },
  dialogTitle: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  dialogContent: {
    padding: 0,
  },
  dialogAction: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'space-between',
  },
  groupAction: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const PreviewScreen = ({
  kit,
  documents,
  companyId,
  setDialogVisible,
  setOpeningDocument,
}) => {
  const classes = useStyles();
  const [acceptTerm1, setAcceptTerm1] = useState(false);
  const [acceptTerm2, setAcceptTerm2] = useState(false);
  const [acceptTerm3, setAcceptTerm3] = useState(false);
  const [acceptTerm4, setAcceptTerm4] = useState(false);
  const [acceptTerm5, setAcceptTerm5] = useState(false);
  const [localLoading, setlocalLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDocumentPreview, setDocumentPreview] = useState(false);
  const history = useHistory();
  let acceptTermsList = [];

  const { loading, error } = useSelector((state) => state.monCompte);
  const dispatch = useDispatch();

  const disablePayerBtn = () => {
    if (acceptTermsList && acceptTermsList.length > 0) {
      const isAcceptAllTerms = acceptTermsList.every((item) => item === true);
      if (isAcceptAllTerms) return false;
    }

    return true;
  };

  const launchStripeCheckout = async () => {
    try {
      const result = await axios.get(process.env.REACT_APP_CHECKOUT_URL, {
        params: { kit, companyId },
      });
      setlocalLoading(false);
      window.location.href = result.data.url;
    } catch (e) {
      setlocalLoading(false);
      dispatch(
        setSnackBarMessage({
          message: `Une erreur s'est produite, si le problème persiste merci de contacter le support`,
          severity: ERROR,
        })
      );
    }
  };

  const goBack = () => {
    history.push('/entreprise');
  };
  const handleCancelDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setDocumentPreview(true);
  };

  const renderConfirmDialog = () => {
    return (
      <Dialog
        open={openConfirmDialog}
        PaperProps={{ className: classes.paddingDialog }}
      >
        <DialogTitle id="confirm-dialog-title" className={classes.dialogTitle}>
          <Typography
            color="primary"
            component="h1"
            variant="h4"
            className={classes.cardHeading}
          >
            ATTENTION
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            <Typography color="primary" paragraph>
              Toutes les informations que vous nous communiquez via cette
              interface doivent être conformes. Nous vous invitons fortement à
              prendre quelques instants pour les vérifier. Dans le cas
              contraire, toute correction à réaliser suite à une erreur de
              saisie de votre part sera facturée <b>100€ HT</b>.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={handleCancelDialog}
            color="primary"
            variant="contained"
            autoFocus
          >
            Corriger ma saisie
          </Button>
          <Button
            onClick={handleConfirmDialog}
            color="primary"
            variant="outlined"
          >
            Valider ma saisie
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderKitPreview = () => {
    console.log('kit', kit);
    console.log('KitPreview[kit]', KitPreview[kit]);

    const { heading, description, services, price } = KitPreview[kit];
    return (
      <Card className={classes.card}>
        <Typography
          color="primary"
          component="h1"
          variant="h4"
          className={classes.cardHeading}
        >
          Récapitulatif de la formule choisie
        </Typography>
        {heading.map((head, idx) => (
          <Typography
            component="h1"
            variant="h4"
            className={classes.cardHeading}
            key={idx}
          >
            {head}
          </Typography>
        ))}

        <Typography className={classes.cardSubHeading}>
          {description}
        </Typography>
        <List>
          {services.map((service, idx) => (
            <ListItem key={idx} style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemText primary={service} />
            </ListItem>
          ))}
        </List>
        <Typography component="h1" variant="h5" className={classes.price}>
          {price}
        </Typography>
        <div className={classes.cardFooter}>
          <div className={classes.groupAction}>
            <Button variant="contained" color="secondary" onClick={goBack}>
              modifier mes Informations
            </Button>
            <Button
              onClick={launchStripeCheckout}
              color="primary"
              variant="contained"
              autoFocus
            >
              Payer
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  const renderTermsByKit = (kitValue) => {
    const terms = [
      {
        name: 'acceptTerm1',
        label: 'Les CGV de Be Business Focus',
        isChecked: acceptTerm1,
        setAcceptTerm: setAcceptTerm1,
        href: 'https://bebusinessfocus.com/wp-content/uploads/2022/05/CGV-CGU_BBF.pdf',
      },
      {
        name: 'acceptTerm2',
        label: 'La réalisation de l’annonce légale par BBF',
        isChecked: acceptTerm2,
        setAcceptTerm: setAcceptTerm2,
      },
      {
        name: 'acceptTerm3',
        label: 'La réalisation du dépôt des fonds par BBF',
        isChecked: acceptTerm3,
        setAcceptTerm: setAcceptTerm3,
      },
      {
        name: 'acceptTerm4',
        label:
          'La réalisation des formalités par Be Business Focus auprès du greffe du TC',
        isChecked: acceptTerm4,
        setAcceptTerm: setAcceptTerm4,
      },
      {
        name: 'acceptTerm5',
        label:
          'Les obligations de lutte antiblanchiment et financement du terrorisme de BBF',
        isChecked: acceptTerm5,
        setAcceptTerm: setAcceptTerm5,
        href: 'https://bebusinessfocus.com/wp-content/uploads/2022/05/Document-BBF_-Proce%CC%81dure-de-lutte-contre-le-blanchiement-et-de-financement-du-terrorisme-LCBFT.pdf',
      },
    ];

    const termsByKit = {
      basiquesci: ['acceptTerm1', 'acceptTerm5'],
      basiquesoc: ['acceptTerm1', 'acceptTerm5'],
      kit3soc: ['acceptTerm1', 'acceptTerm2', 'acceptTerm4', 'acceptTerm5'],
      kit3sci: ['acceptTerm1', 'acceptTerm2', 'acceptTerm4', 'acceptTerm5'],
      default: [
        'acceptTerm1',
        'acceptTerm2',
        'acceptTerm3',
        'acceptTerm4',
        'acceptTerm5',
      ],
    };

    const actualTermsByKit = termsByKit[kitValue] || termsByKit.default;

    const actualTermsList = terms.filter((term) =>
      actualTermsByKit.includes(term.name)
    );

    acceptTermsList = actualTermsList.map((term) => term.isChecked);

    return (
      <FormGroup>
        {actualTermsList.map((term, idx) => (
          <div style={{ display: 'flex', alignItems: 'center' }} key={idx}>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={term.isChecked}
                  onChange={() => {
                    term.setAcceptTerm(!term.isChecked);
                  }}
                  name={term.name}
                  color="primary"
                />
              }
              label={term.label}
            /> */}
            <Checkbox
              checked={term.isChecked}
              onChange={() => {
                term.setAcceptTerm(!term.isChecked);
              }}
              name={term.name}
              id={term.name}
              color="primary"
            />
            <label htmlFor={term.name}>{term.label}</label>
            {term.href && (
              <a href={term.href} style={{ flex: 1, paddingLeft: '1%' }}>
                Cliquez ici
              </a>
            )}
          </div>
        ))}
      </FormGroup>
    );
  };

  const renderDocumentPreview = () => {
    const file = documents[0];
    return (
      <Card className={classes.card}>
        <Typography
          color="primary"
          component="h1"
          variant="h4"
          className={classes.cardHeading}
        >
          Vérifiez attentivement vos statuts
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary={file.title} />
            <ListItemSecondaryAction
              onClick={async () => {
                const f = `${
                  process.env.REACT_APP_API_BASE_URL
                }/${file.path.replace('./', '')}`;
                console.log('f', f);
                if (f) {
                  console.log('in if');
                  setDialogVisible(true);
                  setOpeningDocument(f);
                } else {
                  console.log('show error');
                }
              }}
            >
              <IconButton edge="end" aria-label="view">
                <VisibilityIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <div className={classes.termPart}>
          <Typography>J’ai pris connaissance et j’accepte </Typography>
        </div>
        {kit && renderTermsByKit(kit)}

        <div className={classes.cardFooter}>
          {!loading && !localLoading && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Button variant="contained" color="secondary" onClick={goBack}>
                modifier mes Informations
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={disablePayerBtn()}
                onClick={() => setOpenConfirmDialog(true)}
              >
                Continuer
              </Button>
            </div>
          )}
          {loading && localLoading && <CircularProgress size={24} />}
        </div>
      </Card>
    );
  };

  return (
    <Grid container justify="center">
      {renderConfirmDialog()}

      {confirmDocumentPreview ? (
        <Grid item xs={6}>
          {kit && renderKitPreview(kit)}
        </Grid>
      ) : (
        <Grid item xs={6}>
          {_.isEmpty(documents) ? <></> : renderDocumentPreview()}
        </Grid>
      )}
      <SnackBar show={error} message={error} />
      <SnackBarV2 />
    </Grid>
  );
};

export default PreviewScreen;
