import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  Typography,
  MenuItem,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';

import { Select, TextField, RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

// import KeyboardDatePicker from 'components/KeyboardDatePicker';

// import * as Yup from 'yup';
import ButtonBox from './ButtonBox';

import Paper from '../surface/Paper';

import { DispatchContext } from '../../contexts/association-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import AssociationAutomate from '../../helpers/AssociationAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.25),

    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '80ch',
  },
  radio: {},
}));

// const SignupSchema = Yup.object().shape({

//   siege_social_chez: Yup.string().required('un choix est necessaire'),
//   proprietaire: Yup.string().required('un choix est necessaire'),
//   maison_individuelle: Yup.string().required('un choix est necessaire'),

//   prenom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   nom_syndic: Yup.string()
//     .min(2, 'Too Short!')
//     .max(50, 'Too Long!')
//     .required('Required'),

//   adresse_syndic: Yup.string().required('Required'),
//   prenom_bailleur: Yup.string().required('Required'),
//   nom_bailleur: Yup.string().required('Required'),
//   adresse_bailleur: Yup.string().required('Required'),
//   date_bail: Yup.date().required('Required'),
// });
// const { values, setFormValue } = useFormikContext();

const InfoSociete2 = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     siege_social_chez: Yup.string().required('un choix est necessaire'),
      //     proprietaire: Yup.string().required('un choix est necessaire'),
      //     maison_individuelle: Yup.string().required('un choix est necessaire'),

      //     prenom_syndic: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
      //       (siege_social_chez, proprietaire, maison_individuelle) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Proprietaire' &&
      //           maison_individuelle === 'non'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     nom_syndic: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
      //       (siege_social_chez, proprietaire, maison_individuelle) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Proprietaire' &&
      //           maison_individuelle === 'non'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     adresse_syndic: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire', 'maison_individuelle'],
      //       (siege_social_chez, proprietaire, maison_individuelle) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Proprietaire' &&
      //           maison_individuelle === 'non'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     prenom_bailleur: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     nom_bailleur: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     adresse_bailleur: Yup.string().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     date_bail: Yup.date().when(
      //       ['siege_social_chez', 'proprietaire'],
      //       (siege_social_chez, proprietaire) => {
      //         if (
      //           siege_social_chez === 'chez_gerant' &&
      //           proprietaire === 'Locataire'
      //         ) {
      //           return Yup.date().required('Required');
      //         }
      //       }
      //     ),
      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        AssociationAutomate.next();
        setAutomateState(AssociationAutomate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              flexDirection="column"
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  {' '}
                  Le siège de l&apos;association{' '}
                </Typography>
              </Box>

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Adresse du siège de l'association"
                  name="address"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box alignSelf="center">
                <Field
                  component={TextField}
                  type="string"
                  label="Code postal du siège de l'association"
                  name="zipcode"
                  className={classes.nameTextField}
                  autoComplete="off"
                />

                <Field
                  component={TextField}
                  type="string"
                  label="ville du siège de l'association"
                  name="city"
                  className={classes.nameTextField}
                  autoComplete="off"
                />
              </Box>

              <Box className={classes.root}>
                <FormControl>
                  <Box alignSelf="center">
                    <Typography>Transfert du siège</Typography>
                  </Box>

                  <Box className={classes.root}>
                    <Field
                      component={Select}
                      name="modalite_transfert_siege"
                      inputProps={{
                        id: 'modalite_transfert_siege',
                      }}
                    >
                      <MenuItem value={'decision_organe'}>
                        En tout autre lieu par décision de l’organe de direction{' '}
                      </MenuItem>
                      <MenuItem value={'decision_organe_ratification'}>
                        En tout autre lieu par décision de l’organe de direction
                        avec ratification de l’assemblée générale{' '}
                      </MenuItem>
                      <MenuItem value={'decision_organe_ratification_majorite'}>
                        En tout autre lieu par décision de l’organe de direction
                        avec ratification de l’assemblée générale statuant à la
                        majorité des membres présents ou représentés.
                      </MenuItem>
                    </Field>
                  </Box>
                </FormControl>
              </Box>
              <Box className={classes.root}>
                <Field component={RadioGroup} name="geographiquement_limite">
                  <Box>
                    <Typography variant="subtitle1">
                      {' '}
                      Geographiquement limité par décision de l&apos;organe de
                      direction :
                    </Typography>{' '}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Box>
                </Field>
              </Box>

              {values.geographiquement_limite === 'oui' && (
                <Box className={classes.root}>
                  <Field
                    component={Select}
                    name="transfert_siege_limite"
                    inputProps={{
                      id: 'transfert_siege_limite',
                    }}
                  >
                    <MenuItem value={'ville'}>Ville </MenuItem>
                    <MenuItem value={'departement'}>Departement</MenuItem>
                    <MenuItem value={'region'}>Region</MenuItem>
                  </Field>
                </Box>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

InfoSociete2.propTypes = {
  nextForm: PropTypes.func,
};
export default InfoSociete2;
