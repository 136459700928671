import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { Field, Formik, Form } from 'formik';
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  Popover,
  IconButton,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { TextField, RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  rootPopOver: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '30ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
  radioPop: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  radio: {
    display: 'flex',

    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'center',
  },
  pop: {
    display: 'flex',
    // margin: '20px',
  },
}));

const InfoApportsPartenaire = ({
  partner,
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
  // capital_in_numbers = 5000,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popover2' : undefined;

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? 'simple-popover3' : undefined;

  const [anchorEl4, setAnchorEl4] = React.useState(null);

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const open4 = Boolean(anchorEl4);
  const id4 = open4 ? 'simple-popover4' : undefined;

  let popoverTextToutApports = '';
  let popoverTextApportsNumeraire = '';
  let popoverTextApportsNature = '';
  let popoverTextApportsIndustrie = '';

  if (formValues.eurl_sasu === 'sas') {
    popoverTextToutApports = (
      <div className={classes.popOverDiv}>
        <Typography>
          L’associé de la SAS ont la possibilité d’effectuer les trois types
          d’apports : <br />
          &nbsp;&nbsp;• des apports en numéraire ;<br />
          &nbsp;&nbsp;• des apports en nature ;<br />
          &nbsp;&nbsp;• des apports en industrie. <br />
          <br />
          Toutefois, seuls les apports en numéraire et en nature concourent à la
          formation du capital social. Aucun capital social minimum n’est exigé
          pour constituer une SAS. Il doit être au moins représenté par deux
        </Typography>
      </div>
    );
    popoverTextApportsNumeraire = (
      <div className={classes.popOverDiv}>
        <Typography>
          L’apport en numéraire représente une somme d’argent que les associés
          déposent lors de la constitution sur le compte bancaire de la SAS en
          création. Ces fonds contribueront à la constitution du capital de la
          société et donneront droit en échange à l’attribution d’actions aux
          associées proportionnellement au montant de leurs apports. La
          contrepartie comptable d’un apport en numéraire sera donc un actif
          financier (généralement un compte bancaire ou un compte postal).
          <h4> Libération des apports en numéraire en SAS </h4>
          Les associés d’une SAS disposent de plusieurs options pour à la
          libération des apports en numéraire qu’ils ont consentis. Ils peuvent
          : <br />
          &nbsp;&nbsp;• verser immédiatement (libérer intégralement) la totalité
          de l’apport en numéraire ;<br />
          &nbsp;&nbsp;• ne verser qu’une partie (1/2) dans un premier temps puis
          verser le solde (1/2) dans les 5 ans suivant l’immatriculation de la
          société (libération partielle). Lorsqu’une libération partielle est
          envisagée:
          <br />
          &nbsp;&nbsp;• La société ne pourra pas bénéficier du taux réduit de
          l’impôt sur les sociétés de 15% ;<br />
          &nbsp;&nbsp;• Les intérêts qui résultent de la rémunération d’apports
          en comptes courants consentis par les associés ne seront pas
          déductibles fiscalement.
          <h4>
            Faut-il déposer les apports en numéraire sur un compte bloqué au
          </h4>
          nom de la SAS ? Dans les 8 jours de leur versement, les associés
          doivent déposer leurs apports en numéraire pour le compte de la
          société en formation : <br />
          &nbsp;&nbsp;• soit sur un compte bancaire bloqué ouvert au nom de la
          société ;
          <br />
          &nbsp;&nbsp;• soit à la caisse des dépôts et consignation ;<br />
          &nbsp;&nbsp;• soit chez un notaire.
          <br /> <br />
          Une liste des souscripteurs doit également être communiquée en même
          temps que le dépôt des fonds. Cette liste indique les nom, prénom et
          domicile des apporteurs en numéraire, et les sommes versées par chacun
          d’eux. Le dépositaire des fonds est tenu de communiquer la liste des
          souscripteurs à chaque apporteur en numéraire.
          <h4>
            Comment débloquer les fonds apportés en numéraire au capital de la
            SAS ?
          </h4>
          Les fonds seront ensuite libérés sur présentation, par le Président de
          la SAS, de l’extrait Kbis, afin de prouver que la société est
          immatriculée au registre du commerce et des sociétés. Au cas où la
          société ne serait pas constituée dans un délai de 6 mois suivant le
          dépôt des fonds, les associés ont la possibilité de demander en
          justice la récupération des apports qu’ils ont effectués.
          <h4>
            Existe-t-il des avantages fiscaux profitant aux associés apporteurs
            en numéraire ?
          </h4>
          Sous certaines conditions, les associés de SAS qui effectuent des
          apports en numéraire peuvent être éligibles à une réduction d’impôts
          sur le revenu à hauteur de 25% des montants apportés.
        </Typography>
      </div>
    );
    popoverTextApportsNature = (
      <div className={classes.popOverDiv}>
        <Typography>
          L’apport en nature est une contribution à la formation le capital
          social de la SAS et ouvre droit à l’attribution d’actions. Le capital
          social de la SAS pourrait être unique-ment constitué par des apports
          en nature.
          <h4>Qu’est-ce qu’un apport en nature en SAS ?</h4>
          Un apport en nature constitue un bien, un actif non financier.
          L’apport en nature correspond à un bien mobilier matériel (matériel,
          mobilier…) ou immatériel (logiciel, brevet, marque…) ou ou mobilier ou
          à des titres financiers. Il concourt, tout comme les apports en
          numéraire, à la formation du capital social. En contrepartie, et en
          proportion des apports effectués chaque associé, recevra donc des
          actions. Tous les apports en nature réalisés sont mentionnés dans les
          statuts de la SAS, avec la description, l’évaluation de chacun de ces
          biens, l’identité des associés
          <h4>
            De quelles garanties une SAS bénéficie-t-elle quant aux apports en
            nature ?
          </h4>
          L’associé unique s’engage à l’égard de sa SAS à ce que les apports en
          nature soient : <br />
          &nbsp;&nbsp;• garantis contre vices cachés : La SAS doit pouvoir
          utiliser les biens apportés de façon normale ;<br />
          &nbsp;&nbsp;• garantis contre l’éviction : la SAS doit pouvoir
          disposer de manière paisible des biens apportés.
          <h4>
            Le recours à un commissaire aux apports est-il obligatoire pour les
            apports en nature en SAS ?
          </h4>
          En principe, lorsque le capital d’une société se compose d’apports en
          nature, l’intervention d’un commissaire aux apports est éxigée. Sa
          mission consiste à vérifier les valeurs attribuées aux apports. Il est
          nommé par les associés via une décision unanime. A défaut, c’est le
          Président du tribunal de commerce qui s’en charge, à la demande d’un
          ou plusieurs associés. Par exception, les associés peuvent prendre la
          décision, à l’unanimité, de se dispenser du recours au commissaire aux
          apports si : <br />
          &nbsp;&nbsp;• aucun apport en nature ne vaut plus de 30 000 € ;<br />
          &nbsp;&nbsp;• la valeur de tous les apports en nature n’excède pas 50%
          du capital.
          <br />
          <br />
          Si les associés de la SAS décident, malgré l’évaluation faite par le
          commissaire aux apports, de retenir un montant différent, ils en
          demeurent solidairement responsables durant 5 ans. Attention, la
          surévaluation d’un apport en nature est un délit passible de 5 ans
          d’emprisonnement et de 9 000 € d’amende.
          <h4>
            Quelles sont les conditions de libération des apports en nature en
            SAS ?
          </h4>
          Contrairement aux apports en numéraire, les apports en nature doivent
          faire l’objet d’une libération intégrale dès leur souscription.
          <h4>Réalisation de l’apport en nature en SAS</h4>
          L’associé apporteur en nature doit conserver, en l’état, de l’apport
          et ce jusqu’à création de la société. A compter de son immatriculation
          au registre du commerce et des sociétés, la société devient
          propriétaire des actifs en nature apportés.
        </Typography>
      </div>
    );
    popoverTextApportsIndustrie = (
      <div className={classes.popOverDiv}>
        <Typography>
          Un apport en industrie constitue un apport immatériel non financier.
          <h4>Qu’est-ce qu’un apport en industrie en SAS ?</h4>
          L’apport en industrie se traduit par l’apport de connaissances
          techniques, de travaux réalisés ou de services rendus. L’apport en
          industrie ne concourt pas à la formation du capital social mais
          l’associé unique recevra tout de même des actions en contrepartie de
          cet apport. Les actions reçues en contrepartie d’un apport en
          industrie ne peuvent jamais être cédées ou transmises. Elles lui
          permettront d’avoir droit aux dividendes, au partage de l’actif net et
          de prendre certaines décisions.
          <h4>
            Quelles sont les règles à respecter pour évaluer un apport en
            industrie en SAS ?
          </h4>
          Un apport en industrie doit faire l’objet d’une évaluation selon les
          règles généralement utilisés pour valoriser des apports immatériels,
          intellectuels sans substance physique. En général, il s’agit d’estimer
          le coût potentiel du décaissement si la SAS avait eu recours à un
          prestataire extérieure pour réaliser la prestation apportée.
          <h4>
            Quelles sont les obligations de l’apporteur en industrie en SAS ?
          </h4>
          L’associé qui a réalisé des apports en industrie doit : <br />
          &nbsp;&nbsp;• assurer les prestations prévues sur la durée convenue ;
          <br />
          &nbsp;&nbsp;• ne doit pas porter préjudice à la société et ont
          généralement pour obligation de ne pas exercer une activité
          concurrente ;<br />
          &nbsp;&nbsp;• il s’engage à ne pas encaisser à titre personnel les
          revenus que pourrait générer l’exercice de l’activité apportée en
          industrie à la société.
          <h4>
            Quelles sont les clauses statutaires relatives à l’apport en
            industrie ?{' '}
          </h4>
          Les statuts de la SAS doivent préciser : <br />
          &nbsp;&nbsp;• les conditions de souscription et de répartition des
          actions résultant d’apports en industrie ;<br />
          &nbsp;&nbsp;• la description de cet apport (nature, durée…) ;<br />
          &nbsp;&nbsp;• le nombre de titres reçus en échange ;<br />
          &nbsp;&nbsp;• les droits attachés aux actions ;<br />
          &nbsp;&nbsp;• les obligations en matière de contribution aux pertes et
          d’exclusivité (non-concurrence).
          <br />
          <br />
          Si les statuts ne prévoient rien de particulier, l’associé apporteur
          en industrie en SAS dispose des mêmes droits et obligations aux pertes
          que celui qui a effectué le plus petit apport en numéraire (ou en
          nature).
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sarl') {
    popoverTextToutApports = (
      <div className={classes.popOverDiv}>
        <Typography>
          Les associés de la SARL ont la possibilité d’effectuer les trois types
          d’apports : <br />
          &nbsp;&nbsp;• des apports en numéraire ;<br />
          &nbsp;&nbsp;• des apports en nature ;<br />
          &nbsp;&nbsp;• des apports en industrie.
          <br />
          <br />
          Toutefois, seuls les apports en numéraire et en nature concourent à la
          formation du capital social. Aucun capital social minimum n’est exigé
          pour constituer une SARL. Il doit être au moins représenté par deux
          actions au moins pour une SARL, une pour chacun des deux actionnaires.
        </Typography>
      </div>
    );
    popoverTextApportsNumeraire = (
      <div className={classes.popOverDiv}>
        <Typography>
          L’apport en numéraire représente une somme d’argent que les associés
          déposent lors de la constitution sur le compte bancaire de la SARL en
          création. Ces fonds contribueront à la constitution du capital de la
          société et donneront droit en échange à l’attribution de parts
          sociales aux associés proportionnellement au montant de leurs apports.
          La contrepartie comptable d’un apport en numéraire sera donc un actif
          financier (généralement un compte bancaire).
          <h4>Libération des apports en numéraire en SARL</h4>
          Les associés d’une SARL disposent donc de plusieurs options pour la
          libération des apports en numéraire qu’ils ont consentis. Ils peuvent
          : <br />
          &nbsp;&nbsp;• verser immédiatement (libérer intégralement) la totalité
          de l’apport en numéraire ;<br />
          &nbsp;&nbsp;• ne verser qu’une partie (20%) dans un premier temps puis
          verser le solde (80%) dans les 5 ans suivant l’immatriculation de la
          société (libération partielle).
          <br />
          <br />
          Attention : Lorsqu’une libération partielle est réalisée : <br />
          1. La société ne pourra pas bénéficier du taux réduit de l’impôt sur
          les sociétés de 15% ;<br />
          2. Les intérêts qui résultent de la rémunération d’apports en comptes
          courants consentis par les associés ne seront pas déductibles
          fiscalement.
          <h4>
            Où faut-il déposer les apports en numéraire de la SARL en création ?
          </h4>
          Les associés doivent déposer leurs apports en numéraire pour le compte
          de la société en formation : <br />
          &nbsp;&nbsp;• soit sur un compte bancaire bloqué ouvert au nom de la
          société ;
          <br />
          &nbsp;&nbsp;• soit à la caisse des dépôts et consignation ;<br />
          &nbsp;&nbsp;• soit chez un notaire.
          <h4>
            Comment débloquer les fonds apportés en numéraire au capital de la
            SARL ?
          </h4>
          Les fonds seront ensuite libérés sur présentation, par le gérant de la
          SARL, de l’extrait Kbis, afin de prouver que la société est
          immatriculée au registre du commerce et des sociétés. Si la société
          n’est pas constituée dans un délai de 6 mois suivant le dépôt des
          fonds, les associés ont la possibilité de demander en justice la
          récupération des apports qu’ils ont effectués.
          <h4>
            Existe-t-il des avantages fiscaux profitant aux associés apporteurs
            en numéraire ?
          </h4>
          Sous certaines conditions, les associés de SARL qui effectuent des
          apports en numéraire, peuvent être éligible une réduction d’impôts sur
          le revenu à hauteur de 25% des montants apportés.
        </Typography>
      </div>
    );
    popoverTextApportsNature = (
      <div className={classes.popOverDiv}>
        <Typography>
          Les associés peuvent faire des apports en nature et le capital social
          de la SARL pourrait être uniquement constitué par des apports en
          nature.
          <h4>Qu’est-ce qu’un apport en nature en SARL ?</h4>
          L’apport en nature correspond à un bien mobilier matériel (matériel,
          mobilier…) ou immatériel (logiciel, brevet, marque…) ou mobilier ou à
          des titres financiers. Il concourt, tout comme les apports en
          numéraire, à la formation du capital social. En contrepartie, et en
          proportion des apports effectués chaque associé, recevra donc des
          parts sociales. Tous les apports en nature réalisés sont mentionnés
          dans les statuts de la SARL, avec la description, l’évaluation de
          chacun de ces biens, l’identité des associés concernés par ces
          apports, et le nombre d’actions qui a été remis en échange.
          <h4>
            De quelles garanties une SARL bénéficie-t-elle quant aux apports en
            nature ?
          </h4>
          Les associés s’engagent à l’égard de la SARL à ce que les apports en
          nature soient : <br />
          &nbsp;&nbsp;• garantis contre vices cachés : La SARL doit pouvoir
          utiliser les biens apportés de façon normale ;<br />
          &nbsp;&nbsp;• garantis contre l’éviction : la SARL doit pouvoir
          disposer de manière paisible des biens apportés.
          <br />
          <br />
          En principe, lorsque le capital d’une société se compose d’apports en
          nature, l’intervention d’un commissaire aux apports est éxigée. Sa
          mission consiste à vérifier les valeurs attribuées aux apports. Il est
          nommé par les associés via une décision unanime. <br />
          A défaut, c’est le président du tribunal de commerce qui se charge de
          le désigner, à la demande d’un ou plusieurs associés. Par exception,
          les associés peuvent prendre la décision, à l’unanimité, de se
          dispenser du recours au commissaire aux apports si : <br />
          &nbsp;&nbsp;• aucun apport en nature ne vaut plus de 30 000 € ;<br />
          &nbsp;&nbsp;• la valeur de tous les apports en nature n’excède pas 50%
          du capital.
          <br />
          <br />
          Si les associés de la SARL décident, malgré l’évaluation faite par le
          commissaire aux apports, de retenir un montant différent, ils en
          demeurent solidairement responsables durant 5 ans. Attention, la
          surévaluation d’un apport en nature est un délit passible de 5 ans
          d’emprisonnement et de 9 000 € d’amende.
          <h4>
            Quelles sont les conditions de libération des apports en nature en
            SARL ?
          </h4>
          Contrairement aux apports en numéraire, les apports en nature doivent
          faire l’objet d’une libération intégrale dès leur souscription.
          <h4>Réalisation de l’apport en nature en SARL</h4>
          L’associé apporteur en nature doit conserver, en l’état, de l’apport
          et ce jusqu’à création de la société. A compter de son immatriculation
          au registre du commerce et des sociétés, la société dispose de la
          personnalité juridique, et devient donc propriétaire des actifs en
          nature apportés.
        </Typography>
      </div>
    );
    popoverTextApportsIndustrie = (
      <div className={classes.popOverDiv}>
        <Typography>
          Un apport en industrie constitue un apport immatériel non financier.
          <h4>Qu’est-ce qu’un apport en industrie en SARL ?</h4>
          L’apport en industrie se traduit par l’apport de connaissances
          techniques, de travail réalisés ou de services. L’apport en industrie
          ne concourt pas à la formation du capital social mais les associés
          recevront tout de même des actions en contrepartie de leur apport. Les
          actions reçues en contrepartie d’un apport en industrie ne peuvent
          jamais être cédées ou transmises. Elles lui permettront d’avoir droit
          aux dividendes, au partage de l’actif net et de participer aux
          décisions prises en assemblée générale.
        </Typography>
      </div>
    );
  }

  return (
    <Formik
      initialValues={partner}
      // validationSchema={() => {
      //   return Yup.object().shape({
      //     cash_funds_numbers: Yup.number().when(
      //       'supplies_funds_numbers',
      //       (supplies_funds_numbers) => {
      //         if (supplies_funds_numbers && capital_in_numbers) {
      //           return Yup.number()
      //             .min(capital_in_numbers - supplies_funds_numbers)
      //             .max(capital_in_numbers - supplies_funds_numbers)
      //             .required('Required');
      //         }
      //       }
      //     ),

      //     apport_nature: Yup.string().required('un choix est necessaire'),

      //     apport_fonds_de_commerce: Yup.string().required(
      //       'un choix est necessaire'
      //     ),

      //     date_apport_fonds_de_commerce: Yup.date().when(
      //       'apport_fonds_de_commerce',
      //       (apport_fonds_de_commerce) => {
      //         if (apport_fonds_de_commerce === 'oui') {
      //           return Yup.date().required('Required');
      //         }
      //       }
      //     ),

      //     apport_titres_societe: Yup.string().required(
      //       'un choix est necessaire'
      //     ),

      //     date_apport_titres_societe: Yup.date().when(
      //       'apport_titres_societe',
      //       (apport_titres_societe) => {
      //         if (apport_titres_societe === 'oui') {
      //           return Yup.date().required('Required');
      //         }
      //       }
      //     ),

      //     raison_sociale_societe_apport_titres_societe: Yup.string().when(
      //       'apport_titres_societe',
      //       (apport_titres_societe) => {
      //         if (apport_titres_societe === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     nombre_titres_apportes_apport_titres_societe: Yup.number().when(
      //       'apport_titres_societe',
      //       (apport_titres_societe) => {
      //         if (apport_titres_societe === 'oui') {
      //           return Yup.number().required('Required');
      //         }
      //       }
      //     ),

      //     nombre_titres_total_apport_titres_societe: Yup.number().when(
      //       'apport_titres_societe',
      //       (apport_titres_societe) => {
      //         if (apport_titres_societe === 'oui') {
      //           return Yup.number().required('Required');
      //         }
      //       }
      //     ),

      //     apport_nature_divers: Yup.string().required(
      //       'un choix est necessaire'
      //     ),

      //     date_apport_nature_divers: Yup.date().when(
      //       'apport_nature_divers',
      //       (apport_nature_divers) => {
      //         if (apport_nature_divers === 'oui') {
      //           return Yup.date().required('Required');
      //         }
      //       }
      //     ),

      //     description_biens_apport_nature_divers: Yup.string().when(
      //       'apport_nature_divers',
      //       (apport_nature_divers) => {
      //         if (apport_nature_divers === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     apport_industrie: Yup.string().required('un choix est necessaire'),

      //     expertise_funds_numbers: Yup.number().when(
      //       'apport_industrie',
      //       (apport_industrie) => {
      //         if (apport_industrie === 'oui') {
      //           return Yup.number().required('Required');
      //         }
      //       }
      //     ),
      //   });
      // }}
      onSubmit={(values, { setSubmitting }) => {
        // eslint-disable-next-line
        values.partner_supplies_funds_numbers =
          parseInt(values.partner_valeur_apport_fonds_de_commerce, 10) +
          parseInt(values.partner_valeur_apport_titres_societe, 10) +
          parseInt(values.partner_valeur_apport_nature_divers, 10);

        dispatch({
          type: EDIT_PARTNER,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        Automate.nextInfoApportsPartenaireVersInfoLiberationPartenaire();
        setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              width="100%"
            >
              <Box className={classes.rootPopOver} width="100%">
                <Box className={classes.root}>
                  <Typography variant="h6">
                    Les apports à la société en création par l associé
                  </Typography>
                </Box>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={handleClick}
                >
                  <HelpOutlineOutlined />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorReference={'none'}
                  className={classes.popOver}
                >
                  {popoverTextToutApports}
                </Popover>
              </Box>

              <Field
                component={RadioGroup}
                defaultValue="non"
                name="partner_apport_numeraire"
              >
                <Box classesName={classes.rootPopOver}>
                  <Typography variant="subtitle1">
                    Y a-t-il un apport en numéraire ?
                  </Typography>

                  <FormControlLabel
                    value="non"
                    control={<Radio />}
                    label="non"
                  />
                  <FormControlLabel
                    value="oui"
                    control={<Radio />}
                    label="oui"
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClick2}
                  >
                    <HelpOutlineOutlined />
                  </IconButton>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorReference={'none'}
                    className={classes.popOver}
                  >
                    {popoverTextApportsNumeraire}
                  </Popover>
                </Box>
              </Field>

              {values.partner_apport_numeraire === 'oui' && (
                <Fragment>
                  <Box className={classes.root} width="100%">
                    <Field
                      width="100%"
                      component={TextField}
                      type="string"
                      label="Apports en numéraire - montant en chiffres "
                      name="partner_cash_funds_numbers"
                      className={classes.textField}
                    />
                  </Box>

                  {/* 
                  <Box className={classes.root} width="100%">
                    <Field
                      width="100%"
                      component={TextField}
                      type="string"
                      label="Nom de la banque ou le dépôt a été fait"
                      name="partner_nom_banque_cash_funds"
                      className={classes.textField}
                    />
                  </Box>


                  <Box className={classes.root} width="100%">
                    <Field
                      width="100%"
                      component={TextField}
                      type="string"
                      label="Nom de la banque ou le dépôt a été fait"
                      name="partner_adresse_banque_cash_funds"
                      className={classes.textField}
                    />
                  </Box> */}
                </Fragment>
              )}

              <Box className={classes.root} width="100%">
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_nature"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Y a-t-il un apport en nature ?
                    </Typography>

                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={handleClick3}
                    >
                      <HelpOutlineOutlined />
                    </IconButton>
                    <Popover
                      id={id3}
                      open={open3}
                      anchorEl={anchorEl3}
                      onClose={handleClose3}
                      anchorReference={'none'}
                      className={classes.popOver}
                    >
                      {popoverTextApportsNature}
                    </Popover>
                  </Box>
                </Field>

                {/* {values.partner_apport_nature === 'oui' && (
                  <Fragment>
                    <Box className={classes.root} width="100%">
                      <Field
                        component={TextField}
                        type="string"
                        label="Apports en nature total - montant en chiffres "
                        name="partner_supplies_funds_numbers"
                        className={classes.textField}
                      />
                    </Box>
                  </Fragment>
                )} */}
              </Box>

              <Box className={classes.root}>
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_fonds_de_commerce"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Un apport de fonds de commerce ?
                    </Typography>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                  </Box>
                </Field>

                {values.partner_apport_fonds_de_commerce === 'oui' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date de l'apport"
                        placeholder="01/01/1990"
                        format="dd/MM/yyyy"
                        maxDate={new Date()}
                        name="partner_date_apport_fonds_de_commerce"
                        className={classes.birthdayField}
                      />

                      <Field
                        component={TextField}
                        type="string"
                        label="Evaluation de l'apport - montant en chiffres"
                        name="partner_valeur_apport_fonds_de_commerce"
                        className={classes.textField}
                      />
                    </Box>
                  </Fragment>
                )}
              </Box>

              <Box className={classes.root} width="100%">
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_titres_societe"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Un apport de titres de société ?
                    </Typography>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                  </Box>
                </Field>

                {values.partner_apport_titres_societe === 'oui' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date de l'apport"
                        placeholder="01/01/1990"
                        format="dd/MM/yyyy"
                        maxDate={new Date()}
                        name="partner_date_apport_titres_societe"
                        className={classes.birthdayField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Evaluation de l'apport - montant en chiffres"
                        name="partner_valeur_apport_titres_societe"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Raison sociale de la société apportée"
                        name="partner_raison_sociale_societe_apport_titres_societe"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Nombre de titres apportés"
                        name="partner_nombre_titres_apportes_apport_titres_societe"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Nombre de titres total composant le capital de la société apportée"
                        name="partner_nombre_titres_total_apport_titres_societe"
                        className={classes.textField}
                        autoComplete="off"
                      />
                    </Box>
                  </Fragment>
                )}
              </Box>
              <Box className={classes.root} width="100%">
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_nature_divers"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Des apports en nature divers ? ( matériel informatique,
                      véhicule, site internet etc... ){' '}
                    </Typography>
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                  </Box>
                </Field>

                {values.partner_apport_nature_divers === 'oui' && (
                  <Fragment>
                    <Box marginRight="30px" className={classes.root}>
                      <Field
                        component={KeyboardDatePicker}
                        clearable
                        label="Date de l'apport"
                        placeholder="01/01/1990"
                        format="dd/MM/yyyy"
                        maxDate={new Date()}
                        name="partner_date_apport_nature_divers"
                        className={classes.birthdayField}
                      />
                      <Field
                        component={TextField}
                        type="string"
                        label="Evaluation de l'apport en nature divers - montant en chiffres"
                        name="partner_valeur_apport_nature_divers"
                        className={classes.textField}
                        autoComplete="off"
                      />
                      <Box alignSelf="center" marginTop="10px">
                        <Field
                          component={TextField}
                          type="string"
                          label="Veuillez décrire les biens apportés"
                          name="partner_description_biens_apport_nature_divers"
                          className={classes.textField}
                          fullwidth="true"
                          multiline
                          rows="4"
                          autoComplete="off"
                        />
                      </Box>
                    </Box>
                  </Fragment>
                )}
              </Box>

              <Box className={classes.root}>
                <Field
                  component={RadioGroup}
                  defaultValue="non"
                  name="partner_apport_industrie"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Des apports en industrie ?{' '}
                    </Typography>

                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="non"
                    />
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="oui"
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={handleClick4}
                    >
                      <HelpOutlineOutlined />
                    </IconButton>
                    <Popover
                      id={id4}
                      open={open4}
                      anchorEl={anchorEl4}
                      onClose={handleClose4}
                      anchorReference={'none'}
                      className={classes.popOver}
                    >
                      {popoverTextApportsIndustrie}
                    </Popover>
                  </Box>
                </Field>
              </Box>

              {values.partner_apport_industrie === 'oui' && (
                <Fragment>
                  <Box className={classes.root}>
                    <Field
                      component={TextField}
                      type="string"
                      label="Apports en industrie - montant en chiffres "
                      name="partner_expertise_funds_numbers"
                      className={classes.textField}
                      autoComplete="off"
                    />
                  </Box>
                </Fragment>
              )}
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};
InfoApportsPartenaire.propTypes = {
  nextForm: PropTypes.func,
  capital_in_numbers: PropTypes.number,
};
export default InfoApportsPartenaire;
