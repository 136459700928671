import React from 'react';
import { Grid } from '@material-ui/core';
import PaymentScreen from 'components/monCompte/paymentScreen';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

const CallBackPayment = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.center}>
      <PaymentScreen />
    </Grid>
  );
};

export default CallBackPayment;
