import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
  },
  title: {
    color: '#3D319D',
    marginBottom: '10%',
    textTransform: 'uppercase',
  },
  textInfo: {
    color: '#8E8FA1',
    textAlign: 'left',
  },
  helpUserInfo: {
    color: '#3D319D',
    padding: 0,
    margin: 0,
    textAlign: 'left',
    marginTop: '3%',
  },
  helpPopover: {
    padding: '20px',
    maxWidth: '450px',
    textAlign: 'justify',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    textAlign: 'center',
  },
  subTitle: {
    marginTop: '5%',
    fontSize: '1.1em',
    color: '#8E8FA1',
    textAlign: 'left',
  },
  helpTitle: {
    fontSize: '.9em',
    color: '#8E8FA1',
    textAlign: 'left',
  },

  inputWithHelpIconContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  // RADIO TYPE COMPANY
  radioContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '1rem',
  },
  radioWrapper: {
    flex: 1,
    padding: '2%',
    display: 'flex',
    height: '200px',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '15px',
    border: '1px solid #8E8FA1',
    position: 'relative',
  },
  radioWrapperTitle: {
    position: 'absolute',
    fontSize: '1em',
    textTransform: 'uppercase',
    top: -20,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: 'white',
    color: '#8E8FA1',
    zIndex: 10,
  },
  radioLabel: {
    margin: 0,
    padding: '5%',
    height: '100px',
    width: '100px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    color: '#3D319D',
    fontWeight: 'bold',
    border: '2px solid #3D319D',
    '&:hover': {
      backgroundColor: '#3D319D',
      color: 'white',
    },
  },
  // RADIO WITH ICON

  // OTHER RADIO
  radioTitle: {
    marginTop: '3%',
    fontSize: '1em',
    color: '#8E8FA1',
    textAlign: 'left',
  },
  smallRadioContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  smallRadioLabel: {
    margin: 0,
    padding: '3% 1%',
    whiteSpace: 'nowrap',
    height: '1em',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    color: '#3D319D',
    fontWeight: 'bold',
    borderRight: '2px solid #3D319D',
    borderTop: '2px solid #3D319D',
    borderBottom: '2px solid #3D319D',
    '&:first-child': {
      borderLeft: '2px solid #3D319D',
      borderRadius: '10px 0% 0% 10px',
    },
    '&:last-child': {
      borderRadius: '0px 10px 10px 0px',
    },
    '&:hover': {
      backgroundColor: '#3D319D',
      color: 'white',
    },
  },
  selected: {
    backgroundColor: '#3D319D',
    color: 'white',
  },
  radioDisabled: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'no-drop !important',
    },
  },
  radioError: {
    borderColor: 'red !important',
    color: 'red',
  },
  radio: {
    display: 'none',
  },
  nameTextField: {
    margin: '1%',
  },
  nameTextFieldFull: {
    margin: '1%',
    width: '100%',
  },
  townAndZipCode: {
    display: 'flex',
  },
  town: {
    flex: 1,
  },
  zipCode: {
    flex: 0.5,
  },

  subCategories: {
    paddingLeft: '10%',
    display: 'flex',
    flexDirection: 'column',
  },

  // Input type number
  // inputStyle: {
  //   '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
  //     '-webkit-appearance': 'none',
  //     display: 'none',
  //     backgroundColor: 'red',
  //   },
  // },

  // PARTNER CARD
  partnerCard: {
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'left',
    padding: '10px 20px',
    border: '2px solid #3D319D',
    marginBottom: '2%',
    color: '#3D319D',
    borderRadius: '15px',
    display: 'flex',
    '&:hover': {
      color: 'white',
      backgroundColor: '#3D319D',
      '& $avatar': {
        color: '#3D319D',
        backgroundColor: 'white',
      },
    },
  },
  partnerCardSelected: {
    color: 'white',
    backgroundColor: '#3D319D',
    '& $avatar': {
      color: '#3D319D !important',
      backgroundColor: 'white !important',
    },
  },
  partnerCardDisable: {
    color: 'gray',
    '&:hover': {
      color: 'gray',
      cursor: 'not-allowed',
      backgroundColor: 'transparent',
      '& $avatar': {
        color: 'white',
        backgroundColor: 'gray',
      },
    },
    borderColor: 'gray',
  },
  editIconContainer: {
    position: 'absolute',
    right: '2%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  editIcon: {
    fontSize: '2em',
  },
  avatar: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#3D319D',
    marginRight: '3%',
    textTransform: 'uppercase',
  },
  avatarDisable: {
    backgroundColor: 'gray',
  },

  addPartnerCard: {
    textAlign: 'left',
    padding: '10px 20px',
    border: '2px solid white',
    marginTop: '8%',
    marginBottom: '5%',
    color: '#3D319D',
    fontWeight: 'bold',
    fontSize: '1.2em',
    borderRadius: '15px',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
      backgroundColor: '#3D319D',
      '& $avatar': {
        color: '#3D319D !important',
        backgroundColor: 'white !important',
      },
    },
  },
  parnterName: {
    fontWeight: 'bold',
    fontSize: '1.2em',
  },

  radioButtonWithHelpIconContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  radioButtonLabel: {
    margin: 0,
    whiteSpace: 'nowrap',
    height: '1em',
    flex: 1,
    display: 'flex',
    justifyContent: 'left',
    border: 'none',
  },
  radioButtonError: {
    color: 'red',
    textAlign: 'left',
  },
});

export default useStyles;
