import React, { useContext, Fragment } from 'react';

import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';

import { FormControlLabel, Radio, Box, Typography } from '@material-ui/core';

import { RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const ChoixCommissaireAuxComptes = ({
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const { associe_gerant_multiassocie } = formValues;

  let nom_gerant_gerants = '';
  // let mention = '';

  if (formValues.alone === 'seul' && formValues.director_type === 'un_tiers') {
    nom_gerant_gerants = `Je soussigné – ${formValues.director_title} ${formValues.director_firstname} ${formValues.director_name}
     Gérant(e) ou Président(e) de la société en création ${formValues.company_name} sise ${formValues.address} ${formValues.zipcode}${formValues.city}
     donne pouvoir à Be Business Focus pour réaliser : `;
  }
  if (
    formValues.alone === 'seul' &&
    formValues.director_type === "l_associe_de_l'EURL"
  ) {
    nom_gerant_gerants = `
    Je soussigné – ${formValues.partners[0].partner_title} ${formValues.partners[0].partner_firstname} ${formValues.partners[0].partner_name}
     Gérant(e) ou Président(e) de la société en création ${formValues.company_name} sise ${formValues.address} ${formValues.zipcode}${formValues.city}
     donne pouvoir à Be Business Focus pour réaliser : 
     `;
  }

  if (
    formValues.alone === 'a_plusieurs' &&
    formValues.director_type === 'un_tiers'
  ) {
    // mention = 'test iojioezjoijfoizje';

    nom_gerant_gerants = `Je soussigné – ${formValues.director_title} ${formValues.director_firstname} ${formValues.director_name}
     Gérant(e) ou Président(e) de la société en création ${formValues.company_name} sise ${formValues.address} ${formValues.zipcode}${formValues.city}
     donne pouvoir à Be Business Focus pour réaliser : `;
  }

  if (
    formValues.alone === 'a_plusieurs' &&
    formValues.director_type !== 'un_tiers'
  ) {
    associe_gerant_multiassocie.forEach((associe) => {
      nom_gerant_gerants = `${nom_gerant_gerants} 
        SOPACODI – Apport de ${associe.partner_title}
        ${associe.partner_firstname} ${associe.partner_name} -
        Société ${formValues.company_name}`;
    });

    nom_gerant_gerants = ` ${nom_gerant_gerants} co-gérants de la société en création ${formValues.company_name} sise ${formValues.address} ${formValues.zipcode}${formValues.city}
    donnent pouvoir à Be Business Focus pour réaliser : `;
  }

  if (
    formValues.alone === 'a_plusieurs' &&
    formValues.director_type !== 'un_tiers' &&
    associe_gerant_multiassocie.length === 1
  ) {
    associe_gerant_multiassocie.forEach((associe) => {
      nom_gerant_gerants = `${nom_gerant_gerants} 
       ${associe.partner_title}
        ${associe.partner_firstname} ${associe.partner_name}`;
    });

    nom_gerant_gerants = ` Gérant(e) ou Président(e) de la société en création ${formValues.company_name} sise ${formValues.address} ${formValues.zipcode}${formValues.city}
    donne pouvoir à Be Business Focus pour réaliser : `;
  }

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);

        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        // }
        // SciAutomate.next();
        // setAutomateState(SciAutomate.state);
        if (
          values.procu_bbf_dossier_constitution === 'oui' ||
          values.procu_bbf_annonce_legale === 'oui' ||
          values.procu_bbf_attestation_beneficiaires === 'oui' ||
          values.procu_bbf_attestation_depot_fonds === 'oui' ||
          values.procu_bbf_depot_greffe_dossier_constitution === 'oui'
        ) {
          SciAutomate.nextChoixProcurationBbfVersListeFin();
          setAutomateState(SciAutomate.state);
        }
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              <Typography variant="h6">Procuration BBF</Typography>

              <Typography variant="subtitle1">
                {/* {' '}
                {formValues.director_title} {formValues.director_firstname}{' '}
                {formValues.name} donne pouvoir à BBF pour : */}
                {/* {mention} */}
                {nom_gerant_gerants}
              </Typography>

              <Typography variant="subtitle1">
                Le dossier de constitution de ma société
              </Typography>
              <Field
                component={RadioGroup}
                name="procu_bbf_dossier_constitution"
              >
                {/* FAIRE LES DIFFERENTS RADIO POUR CHAQUE POUVOIR DONNE A BBF */}
                <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="non" control={<Radio />} label="Non" />
              </Field>

              <Typography variant="subtitle1">
                L’attestation des bénéficiaires effectifs
              </Typography>
              <Field
                component={RadioGroup}
                name="procu_bbf_attestation_beneficiaires"
              >
                {/* FAIRE LES DIFFERENTS RADIO POUR CHAQUE POUVOIR DONNE A BBF */}
                <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="non" control={<Radio />} label="Non" />
              </Field>

              {formValues.kit !== 'kit1sci' && formValues.kit !== 'kit2sci' && (
                <Fragment>
                  <Typography variant="subtitle1">
                    L&apos;annonce légale
                  </Typography>
                  <Field component={RadioGroup} name="procu_bbf_annonce_legale">
                    {/* FAIRE LES DIFFERENTS RADIO POUR CHAQUE POUVOIR DONNE A BBF */}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Field>
                </Fragment>
              )}

              {formValues.kit !== 'kit1sci' && formValues.kit !== 'kit3sci' && (
                <Fragment>
                  <Typography variant="subtitle1">
                    L’attestation de dépôt des fonds
                  </Typography>
                  <Field
                    component={RadioGroup}
                    name="procu_bbf_attestation_depot_fonds"
                  >
                    {/* FAIRE LES DIFFERENTS RADIO POUR CHAQUE POUVOIR DONNE A BBF */}
                    <FormControlLabel
                      value="oui"
                      control={<Radio />}
                      label="Oui"
                    />
                    <FormControlLabel
                      value="non"
                      control={<Radio />}
                      label="Non"
                    />
                  </Field>
                </Fragment>
              )}

              <Typography variant="subtitle1">
                Le dépôt au greffe de mon dossier de constitution
              </Typography>
              <Field
                component={RadioGroup}
                name="procu_bbf_depot_greffe_dossier_constitution"
              >
                {/* FAIRE LES DIFFERENTS RADIO POUR CHAQUE POUVOIR DONNE A BBF */}
                <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="non" control={<Radio />} label="Non" />
              </Field>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

ChoixCommissaireAuxComptes.propTypes = {
  nextForm: PropTypes.func,
};
export default ChoixCommissaireAuxComptes;
