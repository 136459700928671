import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
// import { Formik, Field, Form } from 'formik';
// import { RadioGroup } from 'formik-material-ui';

import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

// import { format, addYears, endOfYear } from 'date-fns';
// import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

// import sarl from '../assets/__data__/sarl';
// import Paper from './surface/Paper';
// import ButtonBox from './entreprise/ButtonBox';

// import { DispatchContext } from '../contexts/partners.context';
// import { EDIT_COMPANY } from '../constants/actions';
// import DebugFormik from './DebugFormik';

// import SciAutomate from '../helpers/SciAutomate';

import eurl1 from 'assets/__data__/EURL1';
import sasu1 from 'assets/__data__/SASU1';
import sarl1 from 'assets/__data__/SARL1';
import sas1 from 'assets/__data__/SAS1';
import sci from 'assets/__data__/sci';

const useStyles = makeStyles(() => ({
  boutton: {
    color: 'black',
    fontSize: '20px',
    width: '15%',
    marginLeft: '50%',
    marginTop: '5%',
    marginBottom: '5%',
    cursor: 'pointer',
  },
}));

const Mario = () => {
  // const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <Box flexDirection="column">
      <Box
        className={classes.boutton}
        onClick={() => {
          localStorage.setItem('form', JSON.stringify(eurl1, null, 2));
          history.push('/entreprise?kit="kit1soc"&mario="eurl');
        }}
      >
        EURL
      </Box>
      <Box
        className={classes.boutton}
        onClick={() => {
          localStorage.setItem('form', JSON.stringify(sasu1, null, 2));

          history.push('/entreprise?kit="kit1soc"&mario="sasu');
        }}
      >
        SASU
      </Box>
      <Box
        className={classes.boutton}
        onClick={() => {
          localStorage.setItem('form', JSON.stringify(sarl1, null, 2));

          history.push('/entreprise?kit="kit1soc"&mario="sarl');
        }}
      >
        SARL
      </Box>
      <Box
        className={classes.boutton}
        onClick={() => {
          localStorage.setItem('form', JSON.stringify(sas1, null, 2));

          history.push('/entreprise?kit="kit1soc"&mario="sas');
        }}
      >
        SAS
      </Box>
      <Box
        className={classes.boutton}
        onClick={() => {
          localStorage.setItem('sci-form', JSON.stringify(sci, null, 2));

          history.push('/sci?kit="kit1sci"&mario="sci');
        }}
      >
        SCI
      </Box>
      {/* <Box
        className={classes.boutton}
        onClick={() => {
          localStorage.setItem('form', JSON.stringify(eurl1, null, 2));

          history.push('/association?kit="kit1soc"&mario="sci');
        }}
      >
        ASSOCIATION
      </Box> */}
    </Box>
  );
};

Mario.propTypes = {
  nextForm: PropTypes.func,
};
export default Mario;
