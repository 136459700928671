const defaultForm = (kit = '') => {
  return {
    company_name: '',
    business_name: '',
    code_ape: '',
    activites_lucratives: 'non',
    vente_produits: 'non',
    liste_produits_vendus: '',
    fourniture_services: 'non',
    liste_services_fournis: '',
    utilite_publique: 'non',
    address: '',
    zipcode: '',
    city: '',
    modalite_transfert_siege: 'decision_organe',
    geographiquement_limite: 'non',
    transfert_siege_limite: 'ville',
    siege_social_chez: 'chez_gerant',
    proprietaire: 'Proprietaire',
    duree_limitee: 'non',
    duree_limitee_par_evenement: 'non',
    evenement_limite: '',
    duree_limitee_par_date: 'non',
    date_limite: '',
    nom_organe_direction: 'conseil_administration',
    autre_nom_organe_direction: '',
    nombre_membres_conseil_administration: '',
    duree_mandat_conseil_administration: '',
    nombres_mandat_successifs_conseil_administration: '',
    nomination_premiers_membres_conseil_administration: 'statuts',
    bureau: 'non',
    nombre_mandat_successif_membres_bureau: '',
    duree_mandat_membres_bureau: '',
    nombre_reunions_organe_direction: '',
    nombre_maximum_procuration: '',
    condition_quorum: 'non',
    quorum_deliberation_organe_direction: '1/4',
    premiers_membres_organe_direction_elus_duree: '',
    membres_organe_direction_elus_duree: '',
    membres_agrees: 'non',
    refus_agrement_motive: 'non',
    fondateurs: 'non',
    adherents: 'non',
    bienfaiteurs: 'non',
    membres_de_droit: 'non',
    montant_cotisation_revu_chaque_année: 'non',
    cotisation_fondateurs: '',
    cotisation_adherents: '',
    cotisation_bienfaiteurs: '',
    cotisation_membres_de_droit: '',
    date_appel_cotisation_annuelle: '',
    date_limite_paiement_cotisation_annuelle: '',
    droit_entree: 'non',
    droit_entree_adherents: '',
    droit_entree_bienfaiteurs: '',
    nombre_reunions_assemblee_generale: '',
    nombre_maximum_procuration_membre_association: '',
    condition_quorum_assemblee: 'non',
    quorum_deliberation_assemblee: '1/4',
    jours_prealables_convocation: '',
    presence_commissaire_aux_comptes: 'non',
    duree_exercice_commissaire_aux_comptes: 3,
    type_CAC: 'physique',
    forme_juridique_cabinet_CAC: 'EURL',
    nom_cabinet_commissaire_aux_comptes: '',
    adresse_cabinet_commissaire_aux_comptes: '',
    nom_propre_CAC: 'nom_propre',
    titre_commissaire_aux_comptes: 'M.',
    nom_commissaire_aux_comptes: '',
    prenom_commissaire_aux_comptes: '',
    titre_suppleant: 'M.',
    nom_suppleant: '',
    prenom_suppleant: '',
    adresse_cabinet_suppleant: '',
    preexisting_contract: 'non',
    number_of_preexisting_contract: '',
    contract1_date: '',
    contract1_type: '',
    contract1_amount: '',
    contract1_person_who_paid: '',
    contract2_date: '',
    contract2_type: '',
    contract2_amount: '',
    contract2_person_who_paid: '',
    date_assemblee_generale_constitutive: '',
    heure_assemblee_generale_constitutive: '',
    adresse_assemblee_generale_constitutive: '',
    code_postal_assemblee_generale_constitutive: '',
    ville_assemblee_generale_constitutive: '',
    feuille_presence: 'non',
    presents: [],

    // present_nom: '',
    // present_prenom: '',
    // present_adresse: '',
    // present_code_postal: '',
    // present_ville: '',
    // present_date_naissance: '',
    // present_nationalite: '',
    // present_profession: '',
    // present_vote: 'pour',
    partenaire_supplementaire: 'non',
    representes: [],

    // represente_nom: '',
    // represente_prenom: '',
    // represente_adresse: '',
    // represente_code_postal: '',
    // represente_ville: '',
    // represente_date_naissance: '',
    // represente_nationalite: '',
    // represente_profession: '',
    // represente_vote: 'pour',
    // represente_representant: '',
    president_nom: '',
    president_prenom: '',
    secretaire_nom: '',
    secretaire_prenom: '',
    adoption_sans_modification: 'non',
    adoption_unanimite: 'non',
    president: '',
    vice_president: '',
    tresorier: '',
    secretaire: '',
    procu_bbf_dossier_constitution: 'non',
    procu_bbf_pv_assemblee_generale_constitutive: 'non',
    procu_bbf_annonce_legale: 'non',
    procu_bbf_depot_prefecture_dossier_constitution: 'non',
    kit,
  };
};

export default defaultForm;
