/* eslint-disable */
import React, { useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FilePreview from '../filePreview';
import KitPreview from './KitPreview';
import { DocumentContext } from 'contexts/documents.context';

const useStyles = makeStyles(() => ({
  pageHeading: {
    textAlign: 'center',
  },

  cardHeading: {
    fontWeight: '500',
    textAlign: 'center',
  },

  cardSubHeading: {
    color: '#8D8D8D',
    textAlign: 'center',
    marginTop: 20,
  },

  card: {
    padding: 30,
  },

  price: {
    fontWeight: '500',
    textAlign: 'center',
  },

  termPart: {
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },

  cardFooter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
}));

const MonCompte = () => {
  const classes = useStyles();
  const item = localStorage.getItem('form');
  const { kit } = item ? JSON.parse(item) : {};
  const { documents } = useContext(DocumentContext);

  const [acceptTerm1, setAcceptTerm1] = useState(false);
  const [acceptTerm2, setAcceptTerm2] = useState(false);
  const [acceptTerm3, setAcceptTerm3] = useState(false);
  const [acceptTerm4, setAcceptTerm4] = useState(false);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [openingDocument, setOpeningDocument] = useState(null);

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleCloseDialog = () => {
    setDialogVisible(false);
    setOpeningDocument(null);
  };

  const renderPreviewFileDialog = () => {
    return (
      <Fragment>
        <Dialog
          onClose={handleCloseDialog}
          aria-labelledby="file-preview-dialog-title"
          open={dialogVisible}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle id="file-preview-dialog-title">File Preview</DialogTitle>
          {openingDocument && (
            <FilePreview
              content={{
                type:
                  openingDocument.fileType === 'application/pdf'
                    ? 'application/pdf'
                    : 'application/pdf',
                value: openingDocument.key,
              }}
            />
          )}
        </Dialog>
      </Fragment>
    );
  };

  const renderKitPreview = (kit = '') => {
    const { heading, description, services, price } = KitPreview[kit];
    return (
      <Card className={classes.card}>
        <Typography
          color="primary"
          component="h1"
          variant="h4"
          className={classes.cardHeading}
        >
          Récapitulatif de la formule choisie
        </Typography>
        {heading.map((heading) => (
          <Typography
            component="h1"
            variant="h4"
            className={classes.cardHeading}
          >
            {heading}
          </Typography>
        ))}

        <Typography className={classes.cardSubHeading}>
          {description}
        </Typography>
        <List>
          {services.map((service) => (
            <ListItem>
              <ListItemText primary={service} />
            </ListItem>
          ))}
        </List>
        <Typography component="h1" variant="h5" className={classes.price}>
          {price}
        </Typography>
      </Card>
    );
  };

  const renderDocumentPreview = () => {
    const { documents: files } = documents;

    return (
      <Card className={classes.card}>
        <Typography
          color="primary"
          component="h1"
          variant="h4"
          className={classes.cardHeading}
        >
          Documents générés
        </Typography>
        <List>
          {files.map((file) => (
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={file.title} />
              <ListItemSecondaryAction
                onClick={() => {
                  setDialogVisible(true);
                  setOpeningDocument(file);
                }}
              >
                <IconButton edge="end" aria-label="view">
                  <VisibilityIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <div className={classes.termPart}>
          <Typography>Je peux prendre connaissance et j’accepte </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTerm1}
                  onChange={() => {
                    setAcceptTerm1(!acceptTerm1);
                  }}
                  name="acceptTerm1"
                  color="primary"
                />
              }
              label="Les CGV de Be Business Focus"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTerm2}
                  onChange={() => {
                    setAcceptTerm2(!acceptTerm2);
                  }}
                  name="acceptTerm2"
                  color="primary"
                />
              }
              label="La réalisation de l’annonce légale par BBF"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTerm3}
                  onChange={() => {
                    setAcceptTerm3(!acceptTerm3);
                  }}
                  name="acceptTerm3"
                  color="primary"
                />
              }
              label="La réalisation du dépôt des fonds par BBF"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTerm4}
                  onChange={() => {
                    setAcceptTerm4(!acceptTerm4);
                  }}
                  name="acceptTerm4"
                  color="primary"
                />
              }
              label="La réalisation des formalités par BBF"
            />
          </FormGroup>
        </div>
        <div className={classes.cardFooter}>
          <Button variant="contained" color="primary">
            PAYER
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <>
      {renderPreviewFileDialog()}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            className={classes.pageHeading}
            component="h1"
            variant="h4"
          >
            Mon Compte
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {kit && renderKitPreview(kit)}
        </Grid>
        <Grid item xs={6}>
          {isEmpty(documents) ? <></> : renderDocumentPreview()}
        </Grid>
      </Grid>
    </>
  );
};

MonCompte.propTypes = {};
export default MonCompte;
