import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { Field, Formik, Form } from 'formik';
import {
  Popover,
  IconButton,
  Box,
  Typography,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { TextField, RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

// import KeyboardDatePicker from 'components/KeyboardDatePicker';

import * as Yup from 'yup';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/sci-partners.context';
import { EDIT_PARTNER } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import SciAutomate from '../../helpers/SciAutomate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '30ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
}));

const InfoLiberationPartenaire = ({
  partner,
  // formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  isRecap,
  // setIsRecap,
  // capital_in_numbers = 5000,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const popoverTextLiberationPartiellePartner = (
    <div className={classes.popOverDiv}>
      <Typography>
        Attention, tous les associé-e-s doivent libérer la même quote-part (le
        même pourcentage) d’apport en numéraire. Exemple : Associé 1 : apport
        1200, libération de 600 ; Associé 2 : apport de 3000, libération de
        1500, soit une libération de 50% chacun dans cet exemple.
      </Typography>
    </div>
  );

  return (
    <Formik
      initialValues={partner}
      validationSchema={() => {
        return Yup.object().shape({
          partner_email: Yup.string().required('un choix est necessaire'),

          // director_email: Yup.string().when(
          //   ['director_type', 'eurl_sasu'],
          //   (director_type, eurl_sasu) => {
          //     if (
          //       director_type === "l_associe_de_l'EURL" &&
          //       eurl_sasu === 'eurl'
          //     ) {
          //       return Yup.string().required('Required');
          //     }
          //     if (
          //       director_type === "l_associe_de_l'EURL" &&
          //       eurl_sasu === 'sasu'
          //     ) {
          //       return Yup.string().required('Required');
          //     }
          //     return Yup.string();
          //   }
          // ),
        });
      }}
      onSubmit={(values, { setSubmitting }) => {
        dispatch({
          type: EDIT_PARTNER,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   SciAutomate.goRecap();
        //   setAutomateState(SciAutomate.state);
        // }

        // SciAutomate.demandeChoisirSiPartenaireSupplementaire();
        // setAutomateState(SciAutomate.state);

        if (isRecap) {
          SciAutomate.recapInfoLiberationPartenaireVersListeFin();
          setAutomateState(SciAutomate.state);
        }
        if (!isRecap) {
          SciAutomate.nextInfoLiberationPartenaireVersChoixPartenaireSupplementaire();
          setAutomateState(SciAutomate.state);
        }
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form fullwidth="true">
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
              width="100%"
            >
              <Box className={classes.root} width="100%">
                <Typography variant="h6">
                  Libération de l&apos;apport en numéraire par
                  {partner.partner_title} {partner.partner_firstname} &nbsp;
                  {partner.partner_name} et coordonnées
                </Typography>
              </Box>

              <Field
                component={RadioGroup}
                defaultValue="non"
                name="partner_liberation_partielle"
              >
                <Box>
                  <Typography variant="subtitle1">
                    Y a-t-il une libération partielle de l&apos;apport en
                    numéraire ?
                  </Typography>
                  <FormControlLabel
                    value="non"
                    control={<Radio />}
                    label="non"
                  />
                  <FormControlLabel
                    value="oui"
                    control={<Radio />}
                    label="oui"
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClick}
                  >
                    <HelpOutlineOutlined />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    // marginThreshold="55"
                    onClose={handleClose}
                    // anchorOrigin={{
                    //   vertical: 'center',
                    //   horizontal: 'center',
                    // }}
                    anchorReference={'none'}
                    className={classes.popOver}
                  >
                    {popoverTextLiberationPartiellePartner}
                  </Popover>
                </Box>
              </Field>

              {values.partner_liberation_partielle === 'oui' && (
                <Fragment>
                  <Box className={classes.root} width="100%">
                    <Field
                      width="100%"
                      component={TextField}
                      type="string"
                      label="Montant à libérer en chiffre"
                      name="partner_montant_liberation_partielle"
                      className={classes.textField}
                    />
                  </Box>
                </Fragment>
              )}

              <Box className={classes.root} width="100%">
                <Field
                  width="100%"
                  component={TextField}
                  type="string"
                  placeholder="xyz@gmail.com"
                  label="Email de l'associé"
                  name="partner_email"
                  className={classes.textField}
                />
              </Box>
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};
InfoLiberationPartenaire.propTypes = {
  nextForm: PropTypes.func,
  capital_in_numbers: PropTypes.number,
};
export default InfoLiberationPartenaire;
