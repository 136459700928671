import React from 'react';

// import { useFormikContext } from 'formik';
import { Box, Paper } from '@material-ui/core';

import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

// import { DispatchContext } from '../contexts/partners.context';

import theme from 'assets/jss/theme';

import ButtonBox from 'components/association/ButtonBox';
import DebugFormik from 'components/DebugFormik';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '20ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  group: {
    flexWrap: 'nowrap',
    width: '100%',
  },
  /* table style pour le tableau */
  table: {
    minWidth: 650,
  },
}));

// function createData(name, value) {
//   return { name, value };
// }

const BoxRecap = ({
  // partner,
  formValues,
  activeStep,
  // setActiveStep,
  isLastStep,
  handleBack,
  handleReset,
  // automateState,
  // automateState,
  // setAutomateState,
}) => {
  // const { values } = useFormikContext();
  // const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  return (
    <Formik
      initialValues={formValues}
      onSubmit={async (values, actions) => {
        try {
          let res;

          if (formValues.company_name) {
            // // console.log('url', process.env.REACT_APP_URL_EURL);
            res = await axios.post(
              process.env.REACT_APP_URL_ASSOCIATION,
              values,
              {
                responseType: 'blob',
              }
            );
          }
          // // console.log(res);
          const data = new Blob([res.data]);
          if (typeof window.navigator.msSaveBlob === 'function') {
            // If it is IE that support download blob directly.
            window.navigator.msSaveBlob(data, 'defaultFilename.zip');
          } else {
            const blob = data;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            // link.download = 'documents-création-entreprise.zip';
            document.body.appendChild(link);
            link.click(); // create an <a> element and simulate the click operation.
          }
        } catch (err) {
          // console.error(err);
        } finally {
          // // console.log(actions);
          actions.setSubmitting(false);
        }
      }}

      //       /CRO
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => (
        <Form>
          <Paper>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className={classes.root}
            >
              {/* <PartnersContext.Consumer value={values}>
    {({}) => (   

    )}
    </PartnersContext.Consumer> */}
              {/* rows.map
        <pre>{JSON.stringify(values, null, 2)}</pre> */}
              Merci, vous pouvez créer les documents
            </Box>
          </Paper>
          <ButtonBox
            activeStep={activeStep}
            isLastStep={isLastStep}
            handleBack={handleBack}
            handleReset={handleReset}
            isSubmitting={isSubmitting}
            submitForm={submitForm}
          />{' '}
          <DebugFormik values={values} touched={touched} errors={errors} />
        </Form>
      )}
    </Formik>
  );
};

BoxRecap.propTypes = {
  nextForm: PropTypes.func,
};
export default BoxRecap;
