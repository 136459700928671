import React, { Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Field, Formik, Form } from 'formik';
import {
  Popover,
  IconButton,
  Box,
  Typography,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons/';

import { TextField, RadioGroup } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
// import { KeyboardDatePicker } from 'formik-material-ui-pickers';

import KeyboardDatePicker from 'components/KeyboardDatePicker';
import * as Yup from 'yup';
// import Moment from 'moment';

import Paper from '../surface/Paper';
import ButtonBox from './ButtonBox';

import { DispatchContext } from '../../contexts/partners.context';
import { EDIT_COMPANY } from '../../constants/actions';
import DebugFormik from '../DebugFormik';

import Automate from '../../helpers/Automate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  nameTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '30ch',
  },
  birthdayField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
    width: '30ch',
  },
  popOver: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33%',
    marginLeft: '33.5%',
  },
  popOverDiv: {
    margin: '20px',
    // width: '50',
  },
}));

// const SignupSchema = Yup.object().shape({
//   cash_funds_numbers: Yup.number()
//     .required('Required')
//     .min(capital_in_numbers - values.supplies_funds_numbers)
//     .max(capital_in_numbers - values.supplies_funds_numbers),
//   supplies_funds_numbers: Yup.number()
//     .required('Required')
//     .min(capital_in_numbers - values.cash_funds_numbers)
//     .max(capital_in_numbers - values.cash_funds_numbers),

//   apport_nature: Yup.string().required('un choix est necessaire'),
//   supplies_funds_numbers: Yup.number()
//     .required('required')
//     .min(values.capital_in_numbers - cash_funds_numbers)
//     .max(values.capital_in_numbers - cash_funds_numbers),

//   apport_fonds_de_commerce: Yup.string().required('un choix est necessaire'),
//   date_apport_fonds_de_commerce: Yup.string().required(
//     'un choix est necessaire'
//   ),
//   valeur_apport_fonds_de_commerce: Yup.number()
//     .required('Required')
//     .min(
//       values.supplies_funds_numbers -
//         values.valeur_apport_titres_societe -
//         values.valeur_apport_nature_divers
//     )
//     .max(
//       values.supplies_funds_numbers -
//         values.valeur_apport_titres_societe -
//         values.valeur_apport_nature_divers
//     ),
//   apport_titres_societe: Yup.string().required('un choix est necessaire'),
//   date_apport_titres_societe: Yup.string().required('un choix est necessaire'),
//   valeur_apport_titres_societe: Yup.number()
//     .required('Required')
//     .min(
//       values.supplies_funds_numbers -
//         values.valeur_apport_fonds_de_commerce -
//         values.valeur_apport_nature_divers
//     )
//     .max(
//       values.supplies_funds_numbers -
//         values.valeur_apport_fonds_de_commerce -
//         values.valeur_apport_nature_divers
//     ),
//   raison_sociale_societe_apport_titres_societe: Yup.string().required(
//     'un choix est necessaire'
//   ),
//   nombre_titres_apportes_apport_titres_societe: Yup.number().required(
//     'un choix est necessaire'
//   ),
//   nombre_titres_total_apport_titres_societe: Yup.number().required(
//     'un choix est necessaire'
//   ),
//   apport_nature_divers: Yup.string().required('un choix est necessaire'),
//   date_apport_nature_divers: Yup.string().required('un choix est necessaire'),
//   valeur_apport_nature_divers: Yup.number()
//     .required('Required')
//     .min(
//       values.supplies_funds_numbers -
//         values.valeur_apport_fonds_de_commerce -
//         values.valeur_apport_titres_societe
//     )
//     .max(
//       values.supplies_funds_numbers -
//         values.valeur_apport_fonds_de_commerce -
//         values.valeur_apport_titres_societe
//     ),
//   description_biens_apport_nature_divers: Yup.string().required(
//     'un choix est necessaire'
//   ),
//   apport_industrie: Yup.string().required('un choix est necessaire'),
//   expertise_funds_numbers: Yup.number().required('un choix est necessaire'),
// });

const InfoApportsDeSociete = ({
  formValues,
  activeStep,
  setActiveStep,
  isLastStep,
  handleBack,
  // automateState,
  setAutomateState,
  // isRecap,
  // setIsRecap,
  // capital_in_numbers = 5000,
}) => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(2);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open ? 'simple-popover2' : undefined;

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open3 = Boolean(anchorEl3);
  const id3 = open ? 'simple-popover3' : undefined;

  const [anchorEl4, setAnchorEl4] = React.useState(null);

  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };

  const open4 = Boolean(anchorEl4);
  const id4 = open ? 'simple-popover4' : undefined;

  let popoverTextToutApports = '';
  let popoverTextApportsNumeraire = '';
  let popoverTextApportsNature = '';
  let popoverTextApportsIndustrie = '';

  if (formValues.eurl_sasu === 'eurl') {
    popoverTextToutApports = (
      <div className={classes.popOverDiv}>
        <Typography>
          L’associé unique d’une EURL est autorisé à effectuer trois formes
          d’apport au capital de sa société : <br />
          <br />
          &nbsp;&nbsp;• des apports en numéraire ;<br />
          &nbsp;&nbsp;• des apports en nature ;<br />
          &nbsp;&nbsp;• des apports en industrie.
        </Typography>
      </div>
    );
    popoverTextApportsNumeraire = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4> Caractéristiques d’un apport en numéraire</h4>
          L’apport en numéraire représente une somme d’argent que l’associé
          unique dépose lors de la constitution, sur le compte bancaire de son
          EURL. Ces fonds contribueront à former le capital de la société, et
          ils donneront droit, en échange, à l’attribution de parts sociales.
          <h4>
            Dépôt des fonds représentatifs des apports en numéraire en EURL
          </h4>
          Dans les 8 jours de leur réception, et, avant la signature des
          statuts, les sommes doivent être déposées pour le compte de la société
          en formation : <br />
          &nbsp;&nbsp;• soit auprès d’une banque, sur un compte de la société en
          formation ;
          <br />
          &nbsp;&nbsp;• soit à la Caisse des dépôts et consignations ;<br />
          &nbsp;&nbsp;• soit chez un notaire.
          <br /> <br />
          La mention des conditions du dépôt des fonds doit figurer dans les
          statuts.
          <h4>Retrait des fonds apportés en numéraire</h4>
          Les fonds sont indisponibles jusqu’à l’immatriculation de l’EURL au
          RCS. Ils sont alors libérés sur présentation par le gérant de
          l’extrait K-bis, auprès du dépositaire (banque, notaire, CDC). Si
          l’EURL n’est pas constituée dans un délai de 6 mois, à compter du
          dépôt des fonds, l’apporteur associé unique peut demander en justice
          l’autorisation de retirer le montant de ses apports ou choisir un
          mandataire, qui demandera directement au dépositaire le retrait des
          fonds.
          <h4>Règles à respecter en cas d’apport en numéraire en EURL</h4>
          En cas d’apport en numéraire, le capital souscrit par l’associé unique
          doit être OBLIGATOIREMENT libéré d’au moins 20%, lors de la
          constitution de la société. Les 80% restants doivent être libérés en
          une ou plusieurs fois dans le délai de 5 ans à compter de
          l’immatriculation de l’EURL au RCS. Les fonds doivent être déposés
          dans les 8 jours de leur réception, sur un compte spécial ouvert au
          nom de la société en formation (dans une banque, chez un notaire ou à
          la caisse des dépôts et consignation). Leur retrait ne sera possible
          que sur présentation de l’extrait K-bis de la société, une fois
          celle-ci immatriculée.
          <h4>
            Conséquences de la non libération des apports en numéraire en EURL
          </h4>
          La non libération du capital à la clôture de l’exercice a pour effet
          de ne pas permettre : <br />
          &nbsp;&nbsp;• la réalisation d’augmentation de capital ;<br />
          &nbsp;&nbsp;• la déductibilité des intérêts rémunérant des sommes
          mises à disposition par l’associé unique à l’EURL (apport en compte
          courant).
          <br />
          <br />
          Lorsque l’EURL est soumise à l’impôt sur les sociétés, la non
          libération du capital à la clôture de l’exercice, ne permet pas à la
          société de bénéficier du taux réduit d’IS de 15%.
          <h4>
            Avantages fiscaux conférés par les apports en numéraire en EURL
          </h4>
          Sous certaines conditions, un associé unique d’EURL, qui effectue des
          apports en numéraire, peut être éligible à une réduction d’impôts sur
          le revenu.
        </Typography>
      </div>
    );
    popoverTextApportsNature = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>Caractéristiques d’un apport en nature</h4>
          Comme l’apport en numéraire, l’apport en nature forme le capital
          social de l’EURL, et il ouvre droit à l’attribution de parts sociales.
          Les apports en nature sont divers. Ils peuvent être constitués de
          biens meubles (matériel informatique, industriel, de transport) ou
          immeubles (bâtiments, terrains, etc.), corporels ou incorporels (fonds
          de commerce, dépôt de marques, brevets, logiciels,...).
          <h4>Libération des apports en nature en EURL</h4>
          Les apports en nature doivent être intégralement libérés dès la
          constitution de l’EURL. L’EURL deviendra propriétaire des biens
          apportés lors de son immatriculation au registre du commerce et des
          sociétés (RCS). Durant le processus d’immatriculation, l’associé
          unique doit assurer la conservation des biens apportés, tels qu’ils
          figurent au contrat d’apport et aux statuts.
          <h4>Règles à respecter en cas d’apport en nature en EURL</h4>
          L’associé unique doit évaluer individuellement chacun de ses apports
          en nature. Cette évaluation doit être vérifiée par un commissaire aux
          apports, sauf si l’une des deux situations suivantes se présente :{' '}
          <br />
          <br />
          &nbsp;&nbsp;• si aucun apport en nature n’a une valeur supérieure à 30
          000 €, et la valeur totale de l’ensemble des apports en nature
          n’excède pas la moitié du capital social ; <br />
          <br />
          Ou <br />
          <br />
          &nbsp;&nbsp;• si l’associé unique exerçait son activité en nom propre
          (EIRL ou entreprise individuelle) avant la création de son EURL, et
          que l’élément apporté figurait au bilan de son dernier exercice
          comptable (cette dispense s’applique depuis le 11 décembre 2016).{' '}
          <br />
          <br />
          En cas de dispense d’intervention d’un commissaire aux apports ou si
          l’associé unique surévalue son apport, il demeure responsable pendant
          5 ans à l’égard des tiers de la valeur attribuée.
          <h4>
            Garanties données par l’associé unique sur ses apports en nature
          </h4>
          L’associé unique est tenu d’apporter les garanties suivantes à son
          EURL en ce qui concerne les apports en nature qu’il réalise : <br />
          <br />
          &nbsp;&nbsp;• garantis contre vices cachés : l’EURL doit pouvoir
          utiliser les biens apportés de façon normale ;<br />
          &nbsp;&nbsp;• garantis contre l’éviction : l’EURL doit pouvoir
          disposer de manière paisible des biens apportés.
        </Typography>
      </div>
    );
    popoverTextApportsIndustrie = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>Caractéristiques d’un apport en industrie</h4>
          L’apport en industrie se traduit pas par l’apport d’une somme d’argent
          (apport en numéraire) ou d’un bien (apport en nature), mais par
          l’apport de connaissances techniques, de travail réalisés ou de
          services. L’apport en industrie ne concourt pas à la formation du
          capital social, mais l’associé unique recevra tout de même des parts
          sociales en contrepartie de cet apport. Les parts sociales reçues en
          contrepartie d’un apport en industrie ne peuvent jamais être cédées ou
          transmises. Elles lui permettront d’avoir droit aux dividendes, au
          partage de l’actif net, et de prendre des décisions.
          <h4>Evaluation de l’apport en industrie en EURL</h4>
          Un apport en industrie doit faire l’objet d’une évaluation selon les
          règles généralement utilisés pour valoriser des apports immatériels,
          intellectuels sans substance physique. En général, il s’agit d’estimer
          le coût qu’aurait eu à décaisser l’EURL si elle avait eu recours à un
          prestataire extérieur pour fournir le bien ou le service apporté.
          <h4>Obligations de l’apporteur en industrie d’EURL</h4>
          L’associé unique qui aurait effectué un apport en industrie au capital
          de son EURL doit : <br />
          <br />
          exercer son activité pendant toute la durée prévue (qui peut être
          moindre que la durée de la société) ;<br />
          s’abstenir d’exercer une activité concurrente ;<br />
          effectuer les prestations promises et apporter les connaissances
          techniques prévues ;<br />
          ne pas percevoir personnellement les gains générés par l’exercice de
          l’activité apportée en industrie ;<br />
          <br />
          Lorsque l’associé n’est plus en mesure d’assurer son activité, son
          apport en industrie devient caduc.
        </Typography>
      </div>
    );
  }

  if (formValues.eurl_sasu === 'sasu') {
    popoverTextToutApports = (
      <div className={classes.popOverDiv}>
        <Typography>
          Les associés de la SASU ont la possibilité d’effectuer les trois types
          d’apports : <br />
          &nbsp;&nbsp;• des apports en numéraire ;<br />
          &nbsp;&nbsp;• des apports en nature ;<br />
          &nbsp;&nbsp;• des apports en industrie. <br />
          <br />
          Toutefois, seuls les apports en numéraire et en nature concourent à la
          formation du capital social. Aucun capital social minimum n’est exigé
          pour constituer une SARL. Il doit être au moins représenté par deux
          actions au moins pour une SARL, une pour chacun des deux actionnaires.
        </Typography>
      </div>
    );
    popoverTextApportsNumeraire = (
      <div className={classes.popOverDiv}>
        <Typography>
          <h4>Les apports en numéraire en SASU</h4>
          L’apport en numéraire représente une somme d’argent que l’associé
          unique dépose lors de la constitution sur le compte bancaire de la
          SASU en création. Ces fonds contribueront à la constitution du capital
          de la société et donneront droit en échange à l’attribution d’actions.
          <h4>Libération des apports en numéraire en SASU</h4>
          La moitié au moins des apports en numéraire doit être libérée à la
          constitution, le reste pouvant être libéré en une ou plusieurs fois
          durant les 5 années suivantes.
          <br />
          Lorsqu’une libération partielle est envisagée, il faut avoir à
          l’esprit deux règles importantes
          <br />
          &nbsp;&nbsp;• La société ne pourra pas bénéficier du taux réduit de
          l’impôt sur les sociétés de 15% ;
          <br />
          &nbsp;&nbsp;• Les intérêts qui résultent de la rémunération d’apports
          en comptes courants consentis par les associés ne seront pas
          déductibles fiscalement.
          <h4>
            Faut-il déposer les apports en numéraire sur un compte bloqué au nom
            de la SASU ?
          </h4>
          Dans les 8 jours de leur réception, et, avant la signature des
          statuts, les sommes doivent être déposées pour le compte de la société
          en formation :<br />
          &nbsp;&nbsp;• soit sur un compte bancaire bloqué ouvert au nom de la
          société ;
          <br />
          &nbsp;&nbsp;• soit à la caisse des dépôts et consignation ;<br />
          &nbsp;&nbsp;• soit chez un notaire.
          <h4>
            Comment débloquer les fonds apportés en numéraire au capital de la
            SASU ?
          </h4>
          Les fonds sont indisponibles jusqu’à l’immatriculation de la SASU au
          RCS. Ils sont alors libérés sur présentation par le Président de
          l’extrait K-bis auprès du dépositaire (banque, notaire, CDC). Si la
          SASU n’est pas constituée dans un délai de 6 mois à compter du dépôt
          des fonds, l’apporteur associé unique peut demander en justice
          l’autorisation de retirer le montant de ses apports ou choisir un
          mandataire qui demandera directement au dépositaire le retrait des
          fonds.
          <h4>
            Existe-t-il des avantages fiscaux profitant aux associés apporteurs
            en numéraire ?
          </h4>
          Sous certaines conditions, un associé unique de la SASU qui effectue
          des apports en numéraire peut être éligible à une réduction d’impôts
          sur le revenu à hauteur de 25% des montants apportés.
        </Typography>
      </div>
    );
    popoverTextApportsNature = (
      <div className={classes.popOverDiv}>
        <Typography>
          Comme l’apport en numéraire, l’apport en nature forme le capital
          social de la SASU et ouvre droit à l’attribution d’actions. Le capital
          social de la SASU pourrait être uniquement constitué par des apports
          en nature.
          <h4>Caractéritiques d’un apport en nature en SASU ?</h4>
          Les apports en nature sont divers. Ils peuvent être constitués de
          biens meubles (matériel informatique, industriel, de transport) ou
          immeubles (bâtiments, terrains, etc.), corporelles ou incorporels
          (fonds de commerce, dépôt de marques, brevets, logiciels,...).
          <h4>
            De quelles garanties une SASU bénéficie-t-elle quant aux apports en
            nature ?
          </h4>
          L’associé unique s’engage à l’égard de sa SASU à ce que les apports en
          nature soient : <br />
          &nbsp;&nbsp;• garantis contre vices cachés : La SASU doit pouvoir
          utiliser les biens apportés de façon normale ;<br />
          &nbsp;&nbsp;• garantis contre l’éviction : la SASU doit pouvoir
          disposer de manière paisible des biens apportés.
          <h4> Règles à respecter en cas d’apport en nature en SASU ?</h4>
          L’associé unique doit évaluer individuellement chacun de ses apports
          en nature. Cette évaluation doit être vérifiée par un commissaire aux
          apports sauf : <br />
          &nbsp;&nbsp;• si aucun apport en nature n’a une valeur supérieure à 30
          000 € et la valeur totale de l’ensemble des apports en nature n’excède
          pas la moitié du capital social ;
          <br />
          &nbsp;&nbsp;• si l’associé unique exerçait son activité en nom propre
          (EIRL ou entreprise individuelle) avant la création de son SASU et que
          l’élément apporté figurait au bilan de son dernier exercice comptable.
          <br />
          <br />
          En cas de dispense d’intervention d’un commissaire aux apports ou si
          l’associé unique surévalue son apport, il est responsable pendant 5
          ans à l’égard des tiers de la valeur attribuée.
          <h4>
            Quelles sont les conditions de libération des apports en nature en
            SASU ?
          </h4>
          Les apports en nature doivent faire l’objet d’une libération intégrale
          des leur souscription.
          <h4>Réalisation de l’apport en nature en SASU </h4>
          L’associé apporteur en nature doit conserver en l’état les apports en
          nature et ce jusqu’à la création de la société. A compter de son
          immatriculation au registre du commerce et des sociétés, la société
          devient propriétaire des actifs apportés.
        </Typography>
      </div>
    );
    popoverTextApportsIndustrie = (
      <div className={classes.popOverDiv}>
        <Typography>
          Un apport en industrie constitue un apport immatériel non financier.
          <h4>Qu’est-ce qu’un apport en industrie en SASU ?</h4>
          L’apport en industrie se traduit par l’apport de connaissances
          techniques, de travaux réalisés ou de services rendus. L’apport en
          industrie ne concourt pas à la formation du capital social mais
          l’associé unique recevra tout de même des actions en contrepartie de
          cet apport. Les actions reçues en contrepartie d’un apport en
          industrie ne peuvent jamais être cédées ou transmises. Elles lui
          permettront d’avoir droit aux dividendes, au partage de l’actif net et
          de prendre certaines décisions.
          <h4>
            Quelles sont les règles à respecter pour évaluer un apport en
            industrie en SASU ?
          </h4>
          Un apport en industrie doit faire l’objet d’une évaluation selon les
          règles généralement utilisés pour valoriser des apports immatériels,
          intellectuels sans substance physique. En général, il s’agit d’estimer
          le coût potentiel du décaissement si la SASU avait eu recours à un
          prestataire extérieure pour réaliser la prestation apportée.
          <h4>
            Quelles sont les obligations de l’apporteur en industrie en SASU
          </h4>
          L’associé qui a réalisé des apports en industrie doit : <br />
          &nbsp;&nbsp;• assurer les prestations prévues sur la durée convenue ;
          <br />
          &nbsp;&nbsp;• ne doit pas porter préjudice à la société et ont
          généralement pour obligation de ne pas exercer une activité
          concurrente ;<br />
          &nbsp;&nbsp;• il s’engage à ne pas encaisser à titre personnel les
          revenus que pourrait générer l’exercice de l’activité apportée en
          industrie à la société.
          <h4>
            Quelles sont les clauses statutaires relatives à l’apport en
            industrie ?
          </h4>
          Les statuts de la SASU doivent préciser : <br />
          &nbsp;&nbsp;• les conditions de souscription et de répartition des
          actions résultant d’apports en industrie ;<br />
          &nbsp;&nbsp;• la description de cet apport (nature, durée…) ;<br />
          &nbsp;&nbsp;• le nombre de titres reçus en échange ;<br />
          &nbsp;&nbsp;• les droits attachés aux actions ;<br />
          &nbsp;&nbsp;• les obligations en matière de contribution aux pertes et
          d’exclusivité (non-concurrence).
          <br />
          <br />
          Si les statuts ne prévoient rien de particulier, l’associé apporteur
          en industrie en SASU dispose des mêmes droits et obligations aux
          pertes que celui qui a effectué le plus petit apport en numéraire (ou
          en nature).
        </Typography>
      </div>
    );
  }

  return (
    <Formik
      initialValues={formValues}
      validationSchema={() => {
        return Yup.object().shape({
          cash_funds_numbers: Yup.number()
            .required('un choix est necessaire')
            .min(0),

          supplies_funds_numbers: Yup.string().when(
            'apport_nature',
            (apport_nature) => {
              if (apport_nature === 'oui') {
                return Yup.number().required('champs requis').min(0);
              }
              return Yup.number();
            }
          ),
          // date_apport_fonds_de_commerce: Yup.date().when(
          //   ['apport_nature', 'apport_fonds_de_commerce'],
          //   (apport_nature, apport_fonds_de_commerce) => {
          //     if (apport_nature === 'oui') {
          //       if (apport_fonds_de_commerce === 'oui') {
          //         return Yup.date()
          //           .required('TSTTTT')
          //           .max(
          //             Moment(),
          //             "la date d'apport doit être antérieure a la date d'aujourd'hui"
          //           );
          //       }
          //     }
          //     return Yup.date();
          //   }
          // ),

          valeur_apport_fonds_de_commerce: Yup.number().when(
            ['apport_nature', 'apport_fonds_de_commerce'],
            (apport_nature, apport_fonds_de_commerce) => {
              if (apport_nature === 'oui') {
                if (apport_fonds_de_commerce === 'oui') {
                  return Yup.number().required('champs requis').min(0);
                }
              }
              return Yup.number();
            }
          ),

          // date_apport_titres_societe: Yup.date().when(
          //   ['apport_nature', 'apport_titres_societe'],
          //   (apport_nature, apport_titres_societe) => {
          //     if (apport_nature === 'oui') {
          //       if (apport_titres_societe === 'oui') {
          //         return Yup.date()
          //           .required('Required')
          //           .max(
          //             Moment(),
          //             "la date d'apport doit être antérieure a la date d'aujourd'hui"
          //           );
          //       }
          //     }
          //     return Yup.date();
          //   }
          // ),

          // date_apport_nature_divers: Yup.date().when(
          //   ['apport_nature', 'apport_nature_divers'],
          //   (apport_nature, apport_nature_divers) => {
          //     if (apport_nature === 'oui') {
          //       if (apport_nature_divers === 'oui') {
          //         return Yup.date()
          //           .required('Required')
          //           .max(
          //             Moment(),
          //             "la date d'apport doit être antérieure a la date d'aujourd'hui"
          //           );
          //       }
          //     }
          //     return Yup.date();
          //   }
          // ),
        });
      }}
      //     cash_funds_numbers: Yup.number().when(
      //       'supplies_funds_numbers',
      //       (supplies_funds_numbers) => {
      //         if (supplies_funds_numbers && capital_in_numbers) {
      //           return Yup.number()
      //             .min(capital_in_numbers - supplies_funds_numbers)
      //             .max(capital_in_numbers - supplies_funds_numbers)
      //             .required('Required');
      //         }
      //       }
      //     ),

      //     apport_nature: Yup.string().required('un choix est necessaire'),

      //     apport_fonds_de_commerce: Yup.string().required(
      //       'un choix est necessaire'
      //     ),

      //     date_apport_fonds_de_commerce: Yup.date().when(
      //       'apport_fonds_de_commerce',
      //       (apport_fonds_de_commerce) => {
      //         if (apport_fonds_de_commerce === 'oui') {
      //           return Yup.date().required('Required');
      //         }
      //       }
      //     ),

      //    KIK

      // valeur_apport_fonds_de_commerce: Yup.number().when(
      //   [
      //     'supplies_funds_numbers',
      //     'apport_fonds_de_commerce',
      //     'valeur_apport_titres_societe',
      //     'valeur_apport_nature_divers',
      //   ],
      //   (supplies_funds_numbers, apport_fonds_de_commerce, valeur_apport_titres_societe, valeur_apport_nature_divers ) => {
      //     if (
      //       supplies_funds_numbers &&
      //       apport_fonds_de_commerce === 'oui' &&
      //       valeur_apport_titres_societe && valeur_apport_nature_divers
      //     ) {
      //       return Yup.number()
      //         .min(
      //           supplies_funds_numbers -
      //           valeur_apport_titres_societe -
      //           valeur_apport_nature_divers
      //         )
      //         .max(
      //           supplies_funds_numbers -
      //           valeur_apport_titres_societe -
      //           valeur_apport_nature_divers
      //         )
      //         .required('Required');
      //     }
      //   }
      // ),

      //    /KIK

      // .required('Required'),
      // .min(
      //   values.supplies_funds_numbers -
      //     values.valeur_apport_titres_societe -
      //     values.valeur_apport_nature_divers
      // )
      // .max(
      //   values.supplies_funds_numbers -
      //     values.valeur_apport_titres_societe -
      //     values.valeur_apport_nature_divers
      // ),
      // apport_titres_societe: Yup.string().required(
      //   'un choix est necessaire'
      // ),

      // date_apport_titres_societe: Yup.date().when(
      //   'apport_titres_societe',
      //   (apport_titres_societe) => {
      //     if (apport_titres_societe === 'oui') {
      //       return Yup.date().required('Required');
      //     }
      //   }
      // ),

      // KIK
      // valeur_apport_titres_societe: Yup.number()
      // .when(
      //   [
      //     'supplies_funds_numbers',
      //     'apport_titres_societe',
      //     'valeur_apport_fonds_de_commerce',
      //     'valeur_apport_nature_divers',
      //   ],
      //   (supplies_funds_numbers, valeur_apport_fonds_de_commerce, valeur_apport_nature_divers, apport_titres_societe) => {
      //     if (
      //       supplies_funds_numbers &&
      //       apport_titres_societe === 'oui' &&
      //       valeur_apport_fonds_de_commerce && valeur_apport_nature_divers
      //     ) {
      //       return Yup.number()
      //         .min(
      //           supplies_funds_numbers -
      //           valeur_apport_fonds_de_commerce -
      //           valeur_apport_nature_divers
      //         )
      //         .max(
      //           supplies_funds_numbers -
      //           valeur_apport_fonds_de_commerce -
      //           valeur_apport_nature_divers
      //         )
      //         .required('Required');
      //     }
      //   }
      // ),

      //  /KIK

      // .required('Required'),
      // .min(
      //   values.supplies_funds_numbers -
      //     values.valeur_apport_fonds_de_commerce -
      //     values.valeur_apport_nature_divers
      // )
      // .max(
      //   values.supplies_funds_numbers -
      //     values.valeur_apport_fonds_de_commerce -
      //     values.valeur_apport_nature_divers
      // ),

      // raison_sociale_societe_apport_titres_societe: Yup.string().when(
      //   'apport_titres_societe',
      //   (apport_titres_societe) => {
      //     if (apport_titres_societe === 'oui') {
      //       return Yup.string().required('Required');
      //     }
      //   }
      // ),

      // nombre_titres_apportes_apport_titres_societe: Yup.number().when(
      //   'apport_titres_societe',
      //   (apport_titres_societe) => {
      //     if (apport_titres_societe === 'oui') {
      //       return Yup.number().required('Required');
      //     }
      //   }
      // ),

      // nombre_titres_total_apport_titres_societe: Yup.number().when(
      //   'apport_titres_societe',
      //   (apport_titres_societe) => {
      //     if (apport_titres_societe === 'oui') {
      //       return Yup.number().required('Required');
      //     }
      //   }
      // ),

      // apport_nature_divers: Yup.string().required(
      //   'un choix est necessaire'
      // ),

      // date_apport_nature_divers: Yup.date().when(
      //   'apport_nature_divers',
      //   (apport_nature_divers) => {
      //     if (apport_nature_divers === 'oui') {
      //       return Yup.date().required('Required');
      //     }
      //   }
      // ),

      //      KIK

      // valeur_apport_nature_divers: Yup.number()
      // .when(
      //   [
      //     'supplies_funds_numbers',
      //     'apport_nature_divers',
      //     'valeur_apport_fonds_de_commerce',
      //     'valeur_apport_titres_societe',
      //   ],
      //   (supplies_funds_numbers, apport_nature_divers,  valeur_apport_fonds_de_commerce, valeur_apport_titres_societe) => {
      //     if (
      //       supplies_funds_numbers &&
      //       apport_nature_divers ===  'oui' &&
      //       valeur_apport_fonds_de_commerce && valeur_apport_titres_societe
      //     ) {
      //       return Yup.number()
      //         .min(
      //           supplies_funds_numbers -
      //           valeur_apport_fonds_de_commerce -
      //           valeur_apport_titres_societe
      //         )
      //         .max(
      //           supplies_funds_numbers -
      //           valeur_apport_fonds_de_commerce -
      //           valeur_apport_titres_societe
      //         )
      //         .required('Required');
      //     }
      //   }
      // ),

      //    /KIK

      // .required('Required'),
      // .min(
      //   values.supplies_funds_numbers -
      //     values.valeur_apport_fonds_de_commerce -
      //     values.valeur_apport_titres_societe
      // )
      // .max(
      //   values.supplies_funds_numbers -
      //     values.valeur_apport_fonds_de_commerce -
      //     values.valeur_apport_titres_societe
      // ),

      //     description_biens_apport_nature_divers: Yup.string().when(
      //       'apport_nature_divers',
      //       (apport_nature_divers) => {
      //         if (apport_nature_divers === 'oui') {
      //           return Yup.string().required('Required');
      //         }
      //       }
      //     ),

      //     apport_industrie: Yup.string().required('un choix est necessaire'),

      //     expertise_funds_numbers: Yup.number().when(
      //       'apport_industrie',
      //       (apport_industrie) => {
      //         if (apport_industrie === 'oui') {
      //           return Yup.number().required('Required');
      //         }
      //       }
      //     ),

      //   });
      // }}

      //    BNB
      onSubmit={(values, { setSubmitting }) => {
        // setFieldValue(
        //   'supplies_funds_numbers',
        //   values.valeur_apport_fonds_de_commerce +
        //     values.valeur_apport_titres_societe +
        //     values.valeur_apport_nature_divers
        // );
        // eslint-disable-next-line
        values.supplies_funds_numbers =
          parseInt(values.valeur_apport_fonds_de_commerce, 10) +
          parseInt(values.valeur_apport_titres_societe, 10) +
          parseInt(values.valeur_apport_nature_divers, 10);
        dispatch({
          type: EDIT_COMPANY,
          payload: values,
        });
        setSubmitting(false);
        // setActiveStep(activeStep + 1);
        // if (isRecap) {
        //   Automate.goRecap();
        //   setAutomateState(Automate.state);
        //   return;
        // }

        Automate.nextInfoApportsSocieteVersInfoCapitalSociete();
        setAutomateState(Automate.state);

        // Automate.next();
        // setAutomateState(Automate.state);
      }}
    >
      {({ values, errors, touched, isSubmitting, submitForm }) => {
        return (
          <Form fullwidth="true">
            <Paper>
              <Box
                display="flex"
                p={1}
                bgcolor="background.paper"
                className={classes.root}
                width="100%"
              >
                <Box className={classes.root} width="100%">
                  <Typography variant="h6">
                    Les apports à la société en création
                  </Typography>
                </Box>

                <Box>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClick}
                  >
                    <HelpOutlineOutlined />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorReference={'none'}
                    className={classes.popOver}
                  >
                    {popoverTextToutApports}
                  </Popover>
                </Box>
                {/* <Typography variant="subtitle1">
            Rappel, le capital de votre société : {values.capital_in_numbers}
          </Typography> */}
                <Box className={classes.root} width="100%">
                  <Field
                    width="100%"
                    component={TextField}
                    type="string"
                    label="Apports en numéraire - montant en chiffres "
                    name="cash_funds_numbers"
                    // onKeyUp={() => {
                    //   setFieldValue(
                    //     'cash_funds_letters',
                    //     NumberToLetter(values.cash_funds_numbers)
                    //   );

                    className={classes.textField}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={handleClick2}
                  >
                    <HelpOutlineOutlined />
                  </IconButton>
                  <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={handleClose2}
                    anchorReference={'none'}
                    className={classes.popOver}
                  >
                    {popoverTextApportsNumeraire}
                  </Popover>
                </Box>

                <Box className={classes.root} width="100%">
                  <Field
                    component={RadioGroup}
                    defaultValue="non"
                    name="apport_nature"
                  >
                    <Box>
                      <Typography variant="subtitle1">
                        Y a-t-il un apport en nature ?
                      </Typography>

                      <FormControlLabel
                        value="non"
                        control={<Radio />}
                        label="non"
                        // onChange={() => {
                        //   ctx[0].apport_nature = 'non';
                        // }}
                      />
                      <FormControlLabel
                        value="oui"
                        control={<Radio />}
                        label="oui"
                        // onChange={() => {
                        //   ctx[0].apport_nature = 'oui';
                        // }}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleClick3}
                      >
                        <HelpOutlineOutlined />
                      </IconButton>
                      <Popover
                        id={id3}
                        open={open3}
                        anchorEl={anchorEl3}
                        onClose={handleClose3}
                        anchorReference={'none'}
                        className={classes.popOver}
                      >
                        {popoverTextApportsNature}
                      </Popover>
                    </Box>
                  </Field>

                  {/* {values.apport_nature === 'oui' && (
                    <Fragment>
                      <Box className={classes.root} width="100%">
                        <Field
                          component={TextField}
                          type="string"
                          label="Apports en nature total - montant en chiffres "
                          name="supplies_funds_numbers"
                          className={classes.textField}
                        />
                      </Box>
                    </Fragment>
                  )} */}
                </Box>

                {values.apport_nature === 'oui' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field
                        component={RadioGroup}
                        defaultValue="non"
                        name="apport_fonds_de_commerce"
                      >
                        <Box>
                          <Typography variant="subtitle1">
                            Un apport de fonds de commerce ?
                          </Typography>
                          <FormControlLabel
                            value="non"
                            control={<Radio />}
                            label="non"
                            // onChange={() => {
                            //   ctx[0].apport_fonds_de_commerce = 'non';
                            // }}
                          />
                          <FormControlLabel
                            value="oui"
                            control={<Radio />}
                            label="oui"
                            // onChange={() => {
                            //   ctx[0].apport_fonds_de_commerce = 'oui';
                            // }}
                          />
                        </Box>
                      </Field>

                      {values.apport_fonds_de_commerce === 'oui' && (
                        <Fragment>
                          <Box className={classes.root}>
                            <Field
                              component={KeyboardDatePicker}
                              clearable
                              label="Date de l'apport"
                              placeholder="01/01/1990"
                              format="dd/MM/yyyy"
                              maxDate={new Date()}
                              name="date_apport_fonds_de_commerce"
                              className={classes.birthdayField}
                            />

                            <Field
                              component={TextField}
                              type="string"
                              label="Evaluation de l'apport - montant en chiffres"
                              name="valeur_apport_fonds_de_commerce"
                              className={classes.textField}
                            />
                          </Box>
                        </Fragment>
                      )}
                    </Box>

                    <Box className={classes.root} width="100%">
                      <Field
                        component={RadioGroup}
                        defaultValue="non"
                        name="apport_titres_societe"
                      >
                        <Box>
                          <Typography variant="subtitle1">
                            Un apport de titres de société ?
                          </Typography>
                          <FormControlLabel
                            value="non"
                            control={<Radio />}
                            label="non"
                            // onChange={() => {
                            //   ctx[0].apport_titres_societe = 'non';
                            // }}
                          />
                          <FormControlLabel
                            value="oui"
                            control={<Radio />}
                            label="oui"
                            // onChange={() => {
                            //   ctx[0].apport_titres_societe = 'oui';
                            // }}
                          />
                        </Box>
                      </Field>

                      {values.apport_titres_societe === 'oui' && (
                        <Fragment>
                          <Box className={classes.root}>
                            <Field
                              component={KeyboardDatePicker}
                              clearable
                              label="Date de l'apport"
                              placeholder="01/01/1990"
                              format="dd/MM/yyyy"
                              maxDate={new Date()}
                              name="date_apport_titres_societe"
                              className={classes.birthdayField}
                              autoComplete="off"
                            />
                            <Field
                              component={TextField}
                              type="string"
                              label="Evaluation de l'apport - montant en chiffres"
                              name="valeur_apport_titres_societe"
                              className={classes.textField}
                              autoComplete="off"
                            />
                            <Field
                              component={TextField}
                              type="string"
                              label="Raison sociale de la société apportée"
                              name="raison_sociale_societe_apport_titres_societe"
                              className={classes.textField}
                              autoComplete="off"
                            />
                            <Field
                              component={TextField}
                              type="string"
                              label="Nombre de titres apportés"
                              name="nombre_titres_apportes_apport_titres_societe"
                              className={classes.textField}
                              autoComplete="off"
                            />
                            <Field
                              component={TextField}
                              type="string"
                              label="Nombre de titres total composant le capital de la société apportée"
                              name="nombre_titres_total_apport_titres_societe"
                              className={classes.textField}
                              autoComplete="off"
                            />
                          </Box>
                        </Fragment>
                      )}
                    </Box>
                    <Box className={classes.root} width="100%">
                      <Field
                        component={RadioGroup}
                        defaultValue="non"
                        name="apport_nature_divers"
                      >
                        <Box>
                          <Typography variant="subtitle1">
                            Des apports en nature divers ? ( matériel
                            informatique, véhicule, site internet etc... ){' '}
                          </Typography>
                          <FormControlLabel
                            value="non"
                            control={<Radio />}
                            label="non"
                            // onChange={() => {
                            //   ctx[0].apport_nature_divers = 'non';
                            // }}
                          />
                          <FormControlLabel
                            value="oui"
                            control={<Radio />}
                            label="oui"
                            // onChange={() => {
                            //   ctx[0].apport_nature_divers = 'oui';
                            // }}
                          />
                        </Box>
                      </Field>

                      {values.apport_nature_divers === 'oui' && (
                        <Fragment>
                          <Box marginRight="30px" className={classes.root}>
                            <Field
                              component={KeyboardDatePicker}
                              clearable
                              label="Date de l'apport"
                              placeholder="01/01/1990"
                              maxDate={new Date()}
                              format="dd/MM/yyyy"
                              name="date_apport_nature_divers"
                              className={classes.birthdayField}
                            />
                            <Field
                              component={TextField}
                              type="string"
                              label="Evaluation de l'apport en nature divers - montant en chiffres"
                              name="valeur_apport_nature_divers"
                              className={classes.textField}
                              autoComplete="off"
                            />
                            <Box alignSelf="center" marginTop="10px">
                              <Field
                                component={TextField}
                                type="string"
                                label="Veuillez décrire les biens apportés"
                                name="description_biens_apport_nature_divers"
                                className={classes.textField}
                                fullwidth="true"
                                multiline
                                rows="4"
                                autoComplete="off"
                              />
                            </Box>
                          </Box>
                        </Fragment>
                      )}
                    </Box>
                  </Fragment>
                )}

                <Box className={classes.root}>
                  <Field
                    component={RadioGroup}
                    defaultValue="non"
                    name="apport_industrie"
                  >
                    <Box>
                      <Typography variant="subtitle1">
                        Y a t-il des apports en industrie ?{' '}
                      </Typography>

                      <FormControlLabel
                        value="non"
                        control={<Radio />}
                        label="non"
                        // onChange={() => {
                        //   ctx[0].apport_industrie = 'non';
                        // }}
                      />
                      <FormControlLabel
                        value="oui"
                        control={<Radio />}
                        label="oui"
                        // onChange={() => {
                        //   ctx[0].apport_industrie = 'oui';
                        // }}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleClick4}
                      >
                        <HelpOutlineOutlined />
                      </IconButton>
                      <Popover
                        id={id4}
                        open={open4}
                        anchorEl={anchorEl4}
                        onClose={handleClose4}
                        anchorReference={'none'}
                        className={classes.popOver}
                      >
                        {popoverTextApportsIndustrie}
                      </Popover>
                    </Box>
                  </Field>
                </Box>

                {values.apport_industrie === 'oui' && (
                  <Fragment>
                    <Box className={classes.root}>
                      <Field
                        component={TextField}
                        type="string"
                        label="Apports en industrie - montant en chiffres "
                        name="expertise_funds_numbers"
                        className={classes.textField}
                        autoComplete="off"
                      />
                    </Box>
                  </Fragment>
                )}
              </Box>
            </Paper>
            <ButtonBox
              activeStep={activeStep}
              isLastStep={isLastStep}
              handleBack={handleBack}
              isSubmitting={isSubmitting}
              submitForm={submitForm}
            />
            <DebugFormik values={values} touched={touched} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
InfoApportsDeSociete.propTypes = {
  nextForm: PropTypes.func,
  capital_in_numbers: PropTypes.number,
};
export default InfoApportsDeSociete;
