import FormActions from 'components/entreprise/V2/FormActions';
import prepareData from 'components/entreprise/V2/headOfficeInfo/prepareData';
import validationSchema from 'components/entreprise/V2/headOfficeInfo/validation';
import { Formik } from 'formik';
import React from 'react';
import HeadOfficeInfo from 'components/entreprise/V2/headOfficeInfo';
import { initialValues } from './initValues';

function HeadOfficeInfoContainer({ goBack, saveAndNext, formulaireComplet }) {
  const form = { ...formulaireComplet };
  const { currentForm } = form;

  return (
    <Formik
      initialValues={initialValues(form)}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const data = prepareData(values);
        const updatedForm = { ...form, ...data };
        setSubmitting(false);
        saveAndNext(updatedForm);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <HeadOfficeInfo values={values} setFieldValue={setFieldValue} />
          <FormActions goBack={goBack} currentForm={currentForm} />
        </form>
      )}
    </Formik>
  );
}

export default HeadOfficeInfoContainer;
