import { SARL, SAS, SCI } from 'constants/companies';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object().shape({
    partners: Yup.array().when('eurl_sasu', {
      is: (eurl_sasu) =>
        eurl_sasu === SCI || eurl_sasu === SARL || eurl_sasu === SAS,
      then: Yup.array().min(2, 'Il faut au minimum deux associés(es).'),
    }),
  });
};

//  eslint-disable-next-line
export { validationSchema };
