import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
// import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import { Formik, Form } from 'formik';
// import axios from 'axios';

import presentModel from 'models/present';
import representeModel from 'models/represente';

import defaultForm from 'models/association-initial.context';
// import {
//   FormContext,
//   DispatchContext,
// } from '../contexts/association-partners.context';

import {
  FormContext,
  DispatchContext,
} from '../contexts/association-partners.context';

// import ChoixNomPropreOuSociete from '../components/sci/ChoixNomPropreOuSociete';
// import ChoixSeulOuAPlusieurs from '../components/sci/ChoixSeulOuAPlusieurs';

import ChoixNombrePartenairesOuTypePartenaire from '../components/sci/ChoixNombrePartenairesOuTypePartenaire';
// import ChoixTypeSociete from '../components/sci/ChoixTypeSociete';
import InfoSociete from '../components/association/InfoSociete';
import InfoSociete2 from '../components/association/InfoSociete2';
import InfoSociete3 from '../components/association/InfoSociete3';
import DureeAssociation from '../components/association/DureeAssociation';
import OrganeDirection from '../components/association/OrganeDirection';
import DecisionsOrganeDirection from '../components/association/DecisionsOrganeDirection';
import MembresAssociation from '../components/association/MembresAssociation';
import Cotisations from '../components/association/Cotisations';
import DecisionsAssembleeGenerale from '../components/association/DecisionsAssembleeGenerale';
import ChoixCommissaireAuxComptes from '../components/association/ChoixCommissaireAuxComptes';
import ChoixNombreActesPrealables from '../components/association/ChoixNombreActesPrealables';
import InfoActesPrealable from '../components/association/InfoActesPrealable';
import AssembleeGeneraleConstitutive from '../components/association/AssembleeGeneraleConstitutive';
import PresenceAssembleeGeneraleConstitutive from '../components/association/PresenceAssembleeGeneraleConstitutive';
import PresenceAssembleeGeneraleConstitutive1 from '../components/association/PresenceAssembleeGeneraleConstitutive1';
import ChoixPresentSupplementaire from '../components/association/ChoixPresentSupplementaire';
import ChoixRepresenteSupplementaire from '../components/association/ChoixRepresenteSupplementaire';
import PresenceAssembleeGeneraleConstitutive2 from '../components/association/PresenceAssembleeGeneraleConstitutive2';
import PresenceAssembleeGeneraleConstitutive3 from '../components/association/PresenceAssembleeGeneraleConstitutive3';
import PremiersMembresOrganeDirection from '../components/association/PremiersMembresOrganeDirection';
import ChoixProcurationBBF from '../components/association/ChoixProcurationBBF';
import BoxRecap from '../components/association/BoxRecap';

import InfoPersonneMorale from '../components/sci/InfoPersonneMorale';
import InfoPartenaire from '../components/sci/InfoPartenaire';
import ChoixSituationFamiliale from '../components/sci/ChoixSituationFamiliale';
import InfoMariage from '../components/sci/InfoMariage';
import ChoixContratDeMariage from '../components/sci/ChoixContratDeMariage';
import InfoContratDeMariage from '../components/sci/InfoContratDeMariage';
import InfoPacs from '../components/sci/InfoPacs';
import ChoixTypeDePacs from '../components/sci/ChoixTypeDePacs';
import InfoApportsPartenaire from '../components/sci/InfoApportsPartenaire';
import InfoCapitalDeSociete from '../components/sci/InfoCapitalDeSociete';
// import InfoApportsDeSociete from '../components/sci/InfoApportsDeSociete';
import ChoixLiberationIntegraleOuPartielle from '../components/sci/ChoixLiberationIntegraleOuPartielle';
import InfoMontantDesApports from '../components/sci/InfoMontantDesApports';
import ChoixDuGerant from '../components/sci/ChoixDuGerant';
import InfoGerant from '../components/sci/InfoGerant';
import InfoCommissaireAuxComptes from '../components/sci/InfoCommissaireAuxComptes';
import ListeFin from '../components/sci/ListeFin';

import naturalPersonModel from '../models/physical_person';
import companyModel from '../models/company';

// import SciAutomate from '../helpers/SciAutomate';
import AssociationAutomate from '../helpers/AssociationAutomate';

import { RESET_FORM } from '../constants/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    justifyContent: 'center',
    width: '100%',
  },
}));

//    CVC

const getSteps = () => {
  return [
    'Informations de base',
    'Informations partenaires',
    'Informations financières',
    'Informations administratives',
    'Le résumé',
  ];
};

// const getSteps = () => {
//   return [
//     'nom propre / société',
//     'seul / a plusieurs',
//     "type d'associé",
//     'type de société',
//     'info société ',
//     'info siege société',
//     'info date société',
//     'info personne morale ',
//     'info partenaire',
//     'choix situation familiale ',
//     'info mariage',
//     'choix contrat de mariage',
//     'info pacs ',
//     'choix type pacs',
//     'info capital société',
//     'info apports de société',
//     'choix libération intégrale ou partielle',
//     'info montant des apports',
//     'choix du gerant',
//     'info gerant',
//     'choix nombre actes prealables',
//     'info actes prealables',
//     'choix commissaire aux comptes',
//     'info commissaire aux comptes ',
//     'recap',
//   ];
// };

//      /CVC

//    CVC

// const [state, setState] = React.useState(Automate.state);

// const getStepContent = (stepIndex, partnerIndex, nextForm, handleNext) => {
//   // console.log(`getStepContent with ${Automate.state}`);
//   switch (Automate.state) {
//     case 'choix_nom_propre_ou_societe':
//       return (
//         <ChoixNomPropreOuSociete nextForm={nextForm} handleNext={handleNext} />
//       );
//     case 'choix_seul_ou_a_plusieurs':
//       return <ChoixSeulOuAPlusieurs nextForm={nextForm} />;

//     case 'choix_nombre_partenaires_ou_type_partenaire':
//       return <ChoixNombrePartenairesOuTypePartenaire nextForm={nextForm} />;

//     case 'choix_type_societe':
//       return <ChoixTypeSociete nextForm={nextForm} />;

//     case 'info_societe':
//       return <InfoSociete nextForm={nextForm} />;
//     case 'info_societe2':
//       return <InfoSociete2 nextForm={nextForm} />;
//     case 'info_societe3':
//       return <InfoSociete3 nextForm={nextForm} />;

//     case 'info_personne_morale':
//       return (
//         <InfoPersonneMorale nextForm={nextForm} partnerIndex={partnerIndex} />
//       );

//     case 'info_partenaire':
//       return <InfoPartenaire nextForm={nextForm} partnerIndex={partnerIndex} />;

//     case 'choix_situation_familiale':
//       return (
//         <ChoixSituationFamiliale
//           nextForm={nextForm}
//           partnerIndex={partnerIndex}
//         />
//       );

//     case 'info_mariage':
//       return <InfoMariage nextForm={nextForm} partnerIndex={partnerIndex} />;
//     case 'choix_contrat_de_mariage':
//       return (
//         <ChoixContratDeMariage
//           nextForm={nextForm}
//           partnerIndex={partnerIndex}
//         />
//       );
//     case 'info_contrat_de_mariage':
//       return (
//         <InfoContratDeMariage nextForm={nextForm} partnerIndex={partnerIndex} />
//       );
//     case 'info_pacs':
//       return <InfoPacs nextForm={nextForm} partnerIndex={partnerIndex} />;
//     case 'choix_type_de_pacs':
//       return (
//         <ChoixTypeDePacs nextForm={nextForm} partnerIndex={partnerIndex} />
//       );

//     case 'info_capital_de_societe':
//       return (
//         <InfoCapitalDeSociete nextForm={nextForm} partnerIndex={partnerIndex} />
//       );
//     case 'info_apports_de_societe':
//       return (
//         <InfoApportsDeSociete nextForm={nextForm} partnerIndex={partnerIndex} />
//       );

//     case 'choix_liberation_integrale_ou_partielle':
//       return <ChoixLiberationIntegraleOuPartielle nextForm={nextForm} />;

//     case 'info_montant_des_apports':
//       return <InfoMontantDesApports nextForm={nextForm} />;

//     case 'choix_du_gerant':
//       return <ChoixDuGerant nextForm={nextForm} partnerIndex={partnerIndex} />;
//     case 'info_gerant':
//       return <InfoGerant nextForm={nextForm} />;
//     case 'choix_nombre_actes_prealables':
//       return <ChoixNombreActesPrealables nextForm={nextForm} />;
//     case 'info_actes_prealable':
//       return <InfoActesPrealable nextForm={nextForm} />;
//     case 'choix_commissaire_aux_comptes':
//       return <ChoixCommissaireAuxComptes nextForm={nextForm} />;
//     case 'info_commissaire_aux_comptes':
//       return <InfoCommissaireAuxComptes nextForm={nextForm} />;

//     case 'recap':
//       return <BoxRecap nextForm={nextForm} />;
//     case 26:
//       return <Box25 />;

//     default:
//       return 'Unknown stepIndex';
//   }
// };

function getStepContent(
  stepIndex,
  setActiveStep,
  state,
  isLastStep,
  handleBack,
  handleReset,
  automateState,
  setAutomateState,
  partnerIndex,
  setPartnerIndex,
  isRecap,
  setIsRecap,
  checkInfosBase,
  setCheckInfosBase,
  checkInfosPartners,
  setCheckInfosPartners,
  checkInfoFinancieres,
  setCheckInfoFinancieres,
  checkInfosAdministratives,
  setCheckInfosAdministratives,
  showNon,
  setShowNon,
  presentIndex,
  setPresentIndex,
  representeIndex,
  setRepresenteIndex
) {
  switch (AssociationAutomate.state) {
    // case 'choix_nom_propre_ou_societe':
    //   return (
    //     <ChoixNomPropreOuSociete
    //       formValues={state}
    //       activeStep={stepIndex}
    //       isLastStep={isLastStep}
    //       handleBack={handleBack}
    //       setActiveStep={setActiveStep}
    //       automateState={automateState}
    //       setAutomateState={setAutomateState}
    //       isRecap={isRecap}
    //       setIsRecap={setIsRecap}
    //     />
    //   );
    // case 'choix_seul_ou_a_plusieurs':
    //   return (
    //     <ChoixSeulOuAPlusieurs
    //       formValues={state}
    //       activeStep={stepIndex}
    //       isLastStep={isLastStep}
    //       handleBack={handleBack}
    //       handleReset={handleReset}
    //       setActiveStep={setActiveStep}
    //       automateState={automateState}
    //       setAutomateState={setAutomateState}
    //       isRecap={isRecap}
    //       setIsRecap={setIsRecap}
    //     />
    //   );

    // case 'choix_type_societe':
    //   return (
    //     <ChoixTypeSociete
    //       formValues={state}
    //       activeStep={stepIndex}
    //       isLastStep={isLastStep}
    //       handleBack={handleBack}
    //       setActiveStep={setActiveStep}
    //       automateState={automateState}
    //       setAutomateState={setAutomateState}
    //       isRecap={isRecap}
    //       setIsRecap={setIsRecap}
    //     />
    //   );

    case 'info_societe':
      // setActiveStep(0);
      return (
        <InfoSociete
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_societe2':
      return (
        <InfoSociete2
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_societe3':
      return (
        <InfoSociete3
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    case 'duree_association':
      return (
        <DureeAssociation
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    case 'organe_direction':
      return (
        <OrganeDirection
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    case 'decisions_organe_direction':
      return (
        <DecisionsOrganeDirection
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    case 'membres_association':
      return (
        <MembresAssociation
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    case 'cotisations':
      return (
        <Cotisations
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    case 'decisions_assemblee_generale':
      return (
        <DecisionsAssembleeGenerale
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_commissaire_aux_comptes':
      return (
        <ChoixCommissaireAuxComptes
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_nombre_actes_prealables':
      return (
        <ChoixNombreActesPrealables
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_actes_prealable':
      return (
        <InfoActesPrealable
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'assemblee_generale_constitutive':
      return (
        <AssembleeGeneraleConstitutive
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    case 'presence_assemblee_generale_constitutive':
      return (
        <PresenceAssembleeGeneraleConstitutive
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    case 'presence_assemblee_generale_constitutive1': {
      const { presents } = state;
      const present = presents[presentIndex] || presentModel;
      return (
        <PresenceAssembleeGeneraleConstitutive1
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
          presentIndex={presentIndex}
          setPresentIndex={setPresentIndex}
          present={present}
        />
      );
    }
    case 'presence_assemblee_generale_constitutive2': {
      const { representes } = state;
      const represente = representes[representeIndex] || representeModel;
      return (
        <PresenceAssembleeGeneraleConstitutive2
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
          representeIndex={representeIndex}
          setRepresenteIndex={setRepresenteIndex}
          represente={represente}
        />
      );
    }

    case 'choix_present_supplementaire':
      return (
        <ChoixPresentSupplementaire
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
          showNon={showNon}
          setShowNon={setShowNon}
          presentIndex={presentIndex}
          setPresentIndex={setPresentIndex}
        />
      );

    case 'choix_represente_supplementaire':
      return (
        <ChoixRepresenteSupplementaire
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
          showNon={showNon}
          setShowNon={setShowNon}
          representeIndex={representeIndex}
          setRepresenteIndex={setRepresenteIndex}
        />
      );

    case 'presence_assemblee_generale_constitutive3':
      return (
        <PresenceAssembleeGeneraleConstitutive3
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'premiers_membres_organe_direction':
      return (
        <PremiersMembresOrganeDirection
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_procuration_bbf':
      return (
        <ChoixProcurationBBF
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'recap':
      return (
        <BoxRecap
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          handleReset={handleReset}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_nombre_partenaires_ou_type_partenaire':
      return (
        <ChoixNombrePartenairesOuTypePartenaire
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_personne_morale': {
      const { partners } = state;
      const partner = partners[partnerIndex] || companyModel;
      return (
        <InfoPersonneMorale
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'info_partenaire': {
      const { partners } = state;
      const partner = partners[partnerIndex] || naturalPersonModel;
      return (
        <InfoPartenaire
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'choix_situation_familiale': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ChoixSituationFamiliale
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }
    case 'info_mariage': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <InfoMariage
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'choix_contrat_de_mariage': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ChoixContratDeMariage
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'info_contrat_de_mariage': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <InfoContratDeMariage
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }
    case 'info_pacs': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <InfoPacs
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }
    case 'choix_type_de_pacs': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ChoixTypeDePacs
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }
    case 'info_apports_partenaire': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <InfoApportsPartenaire
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'info_capital_de_societe':
      return (
        <InfoCapitalDeSociete
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    // case 'info_apports_de_societe':
    //   return (
    //     <InfoApportsDeSociete
    //       formValues={state}
    //       activeStep={stepIndex}
    //       isLastStep={isLastStep}
    //       handleBack={handleBack}
    //       setActiveStep={setActiveStep}
    //       automateState={automateState}
    //       setAutomateState={setAutomateState}
    //       isRecap={isRecap}
    //       setIsRecap={setIsRecap}
    //     />
    //   );

    case 'choix_liberation_integrale_ou_partielle':
      return (
        <ChoixLiberationIntegraleOuPartielle
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_montant_des_apports':
      return (
        <InfoMontantDesApports
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'choix_du_gerant': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ChoixDuGerant
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );
    }

    case 'info_gerant':
      return (
        <InfoGerant
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'info_commissaire_aux_comptes':
      return (
        <InfoCommissaireAuxComptes
          formValues={state}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
        />
      );

    case 'liste_fin': {
      const { partners } = state;
      const partner = partners[partnerIndex];
      return (
        <ListeFin
          formValues={state}
          partner={partner}
          activeStep={stepIndex}
          isLastStep={isLastStep}
          handleBack={handleBack}
          setActiveStep={setActiveStep}
          automateState={automateState}
          setAutomateState={setAutomateState}
          partnerIndex={partnerIndex}
          setPartnerIndex={setPartnerIndex}
          isRecap={isRecap}
          setIsRecap={setIsRecap}
          checkInfosBase={checkInfosBase}
          setCheckInfosBase={setCheckInfosBase}
          checkInfosPartners={checkInfosPartners}
          setCheckInfosPartners={setCheckInfosPartners}
          checkInfoFinancieres={checkInfoFinancieres}
          setCheckInfoFinancieres={setCheckInfoFinancieres}
          checkInfosAdministratives={checkInfosAdministratives}
          setCheckInfosAdministratives={setCheckInfosAdministratives}
        />
      );
    }

    default:
      return 'Unknown stepIndex';
  }
}

//    /CVC

// const [activeStep, setActiveStep] = React.useState(0);

/*
const aloneMariee = values.alone === 'marié(e)';
const alonePacsee = values.alone === 'pacsé(e)';
const prenup = values.prenup === 'non';
const cashFundsOnetimePayment = values.cash_funds_onetime_payment === 'oui';
const directorType = values.director_type === "l_associe_de_l'EURL";
const numberOfPreexistingContract1 =
  values.number_of_preexisting_contract === '1';
const numberOfPreexistingContract2 =
  values.number_of_preexisting_contract === '2';
const nonPresenceCommissaireAuxComptes =
  values.presence_commissaire_aux_comptes === 'non';

  */
// const pathSteps = ['choix_nom_propre_ou_societe'];

const CreationAssociationContainer = () => {
  const dispatch = useContext(DispatchContext);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const formulaireComplet = useContext(FormContext);

  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  // const [state, setState] = React.useState(Automate.state);

  // les 3 la a remettre

  //    CVC
  const [partnerIndex, setPartnerIndex] = React.useState(0);
  const [presentIndex, setPresentIndex] = React.useState(0);
  const [representeIndex, setRepresenteIndex] = React.useState(0);
  const [automateState, setAutomateState] = React.useState(
    AssociationAutomate.state
  );

  //    /CVC
  const [showNon, setShowNon] = React.useState('non');

  const [checkInfosBase, setCheckInfosBase] = React.useState(false);
  const [checkInfosPartners, setCheckInfosPartners] = React.useState(0);
  const [checkInfoFinancieres, setCheckInfoFinancieres] = React.useState(false);
  const [checkInfosAdministratives, setCheckInfosAdministratives] =
    React.useState(false);

  const [isRecap, setIsRecap] = useState(false);

  //      CVC

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleNext = (values, setFieldValue, submitForm) => {

  // const handleNext = (ctx) => {
  //   // console.log('handlenext values (le ctx) ', { ctx });
  //   // console.log({ state: Automate.state });

  //   pathSteps.push(Automate.state);

  //   if (
  //     automateState === 'choix_type_societe' &&
  //     ctx[0].alone === 'a_plusieurs'
  //   ) {
  //     const partners = [];
  //     for (let i = 0; i < ctx[0].nombre_de_personnes_physiques; i += 1) {
  //       partners.push(PhysicalPerson);
  //     }
  //     for (let i = 0; i < ctx[0].nombre_de_personnes_morales; i += 1) {
  //       partners.push(Company);
  //     }
  //     setFieldValue('partners', partners); a remettre
  //   }

  //   if (automateState === 'info_societe') {
  //     setActiveStep((prevActiveStep) => {
  //       return prevActiveStep + 1;
  //     });
  //   }
  //   if (
  //     automateState === 'info_societe3' &&
  //     ctx[0].alone === 'seul' &&
  //     ctx[0].personne_physique_morale === 'personne_physique'
  //   ) {
  //     setFieldValue('partners', [PhysicalPerson]);       a remettre
  //     setFieldValue('nombre_de_personnes_physiques', 1); a remettre
  //     setFieldValue('nombre_de_personnes_morales', 0);   a remettre
  //   }
  //   if (
  //     automateState === 'info_societe3' &&
  //     ctx[0].alone === 'seul' &&
  //     ctx[0].personne_physique_morale === 'personne_morale'
  //   ) {
  //     setFieldValue('partners', [Company]);      a remettre
  //     setFieldValue('nombre_de_personnes_physiques', 0); a remettre
  //     setFieldValue('nombre_de_personnes_morales', 1); a remettre
  //   }

  //   if (
  //     automateState === 'info_societe3' &&
  //     ctx[0].personne_physique_morale === 'personne_morale'
  //   ) {
  //     Automate.demandeInfoDeLaPersonneMorale();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'info_societe3' &&
  //     ctx[0].personne_physique_morale === 'personne_physique'
  //   ) {
  //     Automate.demandeInfoDuPartenaire();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (automateState === 'info_personne_morale') {
  //     Automate.demandeInfoDuPartenaire();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'info_partenaire' &&
  //     ctx[0].personne_physique_morale === 'personne_morale'
  //   ) {
  //     Automate.next();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'info_partenaire' &&
  //     ctx[0].personne_physique_morale === 'personne_physique'
  //   ) {
  //     Automate.demandeChoisirSituationFamiliale();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'choix_situation_familiale' &&
  //     ctx[0].partners[partnerIndex].partner_family_situation === 'marié(e)'
  //     ctx[0].partner_family_situation === 'marié(e)'
  //   ) {
  //     Automate.demandeInfoDuMariage();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (automateState === 'info_mariage') {
  //     Automate.demandeChoisirSiContratDeMariage();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'choix_contrat_de_mariage' &&
  //     ctx[0].partners[partnerIndex].partner_wedding_prenup === 'oui'
  //     ctx[0].partner_wedding_prenup === 'oui'
  //   ) {
  //     Automate.demandeInfoDuContratDeMariage();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'choix_situation_familiale' &&
  //     ctx[0].partner_family_situation === 'pacsé(e)'
  //   ) {
  //     Automate.demandeInfoDuPacs();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (automateState === 'info_pacs') {
  //     Automate.demandeChoisirLeTypeDePacs();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'choix_situation_familiale' &&
  //     partnerIndex === ctx[0].nombre_de_personnes_physiques &&
  //     ctx[0].nombre_de_personnes_morales > 1
  //   ) {
  //     setPartnerIndex(partnerIndex + 1);
  //     Automate.demandeInfoDeLaPersonneMorale();
  //     setAutomateState(Automate.state);
  //     return;
  //   }
  //   if (
  //     automateState === 'choix_situation_familiale' &&
  //     ctx[0].nombre_de_personnes_physiques > 1 &&
  //     partnerIndex < ctx[0].nombre_de_personnes_physiques
  //   ) {
  //     setPartnerIndex(partnerIndex + 1);
  //     Automate.demandeInfoDuPartenaire();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'choix_contrat_de_mariage' &&
  //     ctx[0].partners[partnerIndex].partner_wedding_prenup === 'non' &&
  //     ctx[0].partner_wedding_prenup === 'non' &&
  //     partnerIndex === ctx[0].nombre_de_personnes_physiques &&
  //     ctx[0].nombre_de_personnes_morales > 0
  //   ) {
  //     setPartnerIndex(partnerIndex + 1);
  //     Automate.demandeInf  // console.log('activeStep', stepIndex);

  //   if (
  //     automateState === 'choix_contrat_de_mariage' &&
  //     ctx[0].partners[partnerIndex].partner_wedding_prenup === 'non' &&
  //     ctx[0].partner_wedding_prenup === 'non' &&
  //     ctx[0].nombre_de_personnes_physiques > 1 &&
  //     partnerIndex < ctx[0].nombre_de_personnes_physiques
  //   ) {
  //     setPartnerIndex(partnerIndex + 1);
  //     Automate.demandeInfoDuPartenaire();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'choix_contrat_de_mariage' &&
  //     ctx[0].partners[partnerIndex].partner_wedding_prenup === 'non'
  //     ctx[0].partner_wedding_prenup === 'non'
  //   ) {
  //     Automate.next();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'info_contrat_de_mariage' &&
  //     partnerIndex === ctx[0].nombre_de_personnes_physiques &&
  //     ctx[0].nombre_de_personnes_morales > 0
  //   ) {
  //     setPartnerIndex(partnerIndex + 1);
  //     Automate.demandeInfoDeLaPersonneMorale();
  //     setAutomateState(Automate.state);
  //     return;
  //   }
  //   if (
  //     automateState === 'info_contrat_de_mariage' &&
  //     ctx[0].nombre_de_personnes_physiques > 1 &&
  //     partnerIndex < ctx[0].nombre_de_personnes_physiques
  //   ) {
  //     setPartnerIndex(partnerIndex + 1);
  //     Automate.demandeInfoDuPartenaire();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (automateState === 'info_capital_de_societe') {
  //     setActiveStep((prevActiveStep) => {
  //       return prevActiveStep + 1;
  //     });
  //   }

  //   if (
  //     automateState === 'choix_liberation_integrale_ou_partielle' &&
  //     ctx[0].cash_funds_onetime_payment === 'non'
  //   ) {
  //     Automate.demandeInfoSurLeMontantDesApports();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (automateState === 'choix_liberation_integrale_ou_partielle') {
  //     setActiveStep((prevActiveStep) => {
  //       return prevActiveStep + 1;
  //     });
  //   }
  //   if (automateState === 'info_montant_des_apports') {
  //     setActiveStep((prevActiveStep) => {
  //       return prevActiveStep + 1;
  //     });
  //   }

  //   if (
  //     automateState === 'choix_du_gerant' &&
  //     ctx[0].director_type === 'un_tiers'
  //   ) {
  //     Automate.demandeInfoSurLeGerant();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (
  //     automateState === 'choix_nombre_actes_prealables' &&
  //     ctx[0].number_of_preexisting_contract > 0
  //   ) {
  //     Automate.demandeInfoSurLesActesPrealables();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (automateState === 'choix_commissaire_aux_comptes') {
  //     // console.log('icicicicicicici');
  //     Automate.demandeInfoSurLeCommissaireAuxComptes();
  //     setAutomateState(Automate.state);
  //     return;
  //   }

  //   if (automateState === 'info_commissaire_aux_comptes') {
  //     setActiveStep((prevActiveStep) => {
  //       return prevActiveStep + 1;
  //     });
  //   }

  //   if (automateState === 'recap') {
  //     return;
  //   }

  //   Automate.next();
  //   setAutomateState(Automate.state);
  // };

  //      /CVC

  const handleBack = () => {
    // if (SciAutomate.state === 'info_capital_de_societe') {
    //   setAutomateState(SciAutomate.state);
    // } else if (
    //   formulaireComplet.cash_funds_onetime_payment === 'oui' &&
    //   SciAutomate.state === 'choix_du_gerant'
    // ) {
    //   SciAutomate.prevGerantChoixLiberation();
    //   setAutomateState(SciAutomate.state);
    // } else {
    //   SciAutomate.prev();
    //   setAutomateState(SciAutomate.state);
    // }
    AssociationAutomate.prev();
    setAutomateState(AssociationAutomate.state);
  };

  const handleReset = () => {
    AssociationAutomate.reset();
    setAutomateState(AssociationAutomate.state);
    setActiveStep(0);
    setIsRecap(false);

    const form = defaultForm;
    form.partners = [];
    dispatch({
      type: RESET_FORM,
      payload: form,
    });
  };

  // const nextForm = () => {
  //   // console.log('-----pass to an other form');

  //   handleNext();
  // };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {getStepContent(
              activeStep,
              setActiveStep,
              formulaireComplet,
              isLastStep,
              handleBack,
              handleReset,
              automateState,
              setAutomateState,
              partnerIndex,
              setPartnerIndex,
              isRecap,
              setIsRecap,
              checkInfosBase,
              setCheckInfosBase,
              checkInfosPartners,
              setCheckInfosPartners,
              checkInfoFinancieres,
              setCheckInfoFinancieres,
              checkInfosAdministratives,
              setCheckInfosAdministratives,
              showNon,
              setShowNon,
              presentIndex,
              setPresentIndex,
              representeIndex,
              setRepresenteIndex
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default CreationAssociationContainer;
