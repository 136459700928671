import React, { useState } from 'react';
import { Container, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import logo from 'assets/images/BBF-header-logo-white.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menu: {
    marginTop: '40px',
  },
}));

const Header = () => {
  const { user } = useSelector((state) => state.loggedUser);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    history.push('/logout');
  };
  const showMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <header className={classes.root}>
      <Container maxWidth="xl">
        <Toolbar className={classes.toolbar}>
          <img src={logo} alt="logo" width="100px" />
          {user && (
            <div stype={{ backgroundColor: 'red' }}>
              <Avatar
                className={classes.avatar}
                onClick={showMenu}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                {user.firstname &&
                  user.name &&
                  `${user.firstname.charAt(0)}${user.name.charAt(0)}`}
                {!user.firstname && !user.name && 'BBF'}
              </Avatar>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                className={classes.menu}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={logout}>
                  <Typography variant="button" display="block" gutterBottom>
                    Se déconnecter
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </Container>
    </header>
  );
};

export default Header;
