import React, { useEffect } from 'react';
// import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export default function SimpleSnackbar({ show, message }) {
  const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  useEffect(() => {
    if (show) {
      setOpen(true);
    }
  }, [show]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}

      <Snackbar
        open={open}
        onClose={handleClose}
        // message={message}
      >
        <Alert severity="error">{message}</Alert>
      </Snackbar>
    </div>
  );
}
