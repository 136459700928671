import FormActions from 'components/entreprise/V2/FormActions';
import prepareData from 'components/entreprise/V2/partnerAddAndReview/prepareData';
import { SET_PARTNER_ID } from 'constants/actions';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import PartnerAddAndReview from '.';
import { validationSchema } from './validation';

function PartnerAddAndReviewContainer({
  goBack,
  saveAndNext,
  goToPartnerInfo,
  formulaireComplet,
  dispatch,
}) {
  const form = { ...formulaireComplet };
  const { currentForm } = form;

  useEffect(() => {
    dispatch({ type: SET_PARTNER_ID, payload: null });
  }, []);

  return (
    <Formik
      initialValues={form}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const data = prepareData(values);
        setSubmitting(false);
        saveAndNext(data);
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <PartnerAddAndReview
            goToPartnerInfo={goToPartnerInfo}
            formulaireComplet={formulaireComplet}
          />
          <FormActions goBack={goBack} currentForm={currentForm} />
        </form>
      )}
    </Formik>
  );
}

export default PartnerAddAndReviewContainer;
